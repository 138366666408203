import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dashboard from '../components/Dashboard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, NativeSelect, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import LinkIcon from '@mui/icons-material/Link';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import API, { AXIOS } from '../environment/api';
import Avatar from '@mui/material/Avatar';
import LoadingScreen from './LoadingScreen';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingModal from "../components/LoadingModal"
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Stack from '@mui/material/Stack';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// Titulos de columna de la tabla
const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'anuncio', label: 'Anuncio', minWidth: 100 },
  {
    id: 'tipo',
    label: 'Tipo',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'link',
    label: 'Link de acción',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'empresa',
    label: 'Empresa',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'estado',
    label: 'Estado',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'acciones',
    label: 'Acciones',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

//Estilo de modales
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

//Función para escribir solo número en texto
const isInputNumber = (event) => {
  let ch = String.fromCharCode(event.which);
  if (!(/[0-9-,]/.test(ch))) {
    event.preventDefault();
  }
};



const AdvertisementPage = () => {
  //Inicialización de datos
  const [expanded, setExpanded] = React.useState('panel1');
  const [company, setCompany] = React.useState('');
  const [priority, setPriority] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [archivos, setArchivos] = React.useState(null);
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState(false);
  const [dataMessage, setMessage] = React.useState(false);
  const closeAlert = () => setAlert(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);
  const [open, setOpen] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState(false);

  //Funcion para abrir modal de editar
  const handleOpen = (row) => {
    setDataEdit(row);
    setOpen(true);
  };
  //Funcion para cerrar modal de editar
  const handleClose = () => setOpen(false);
  //Funcion para mostrar mensaje de exito
  const handleClick_success = () => {
    setOpenSuccess(true);
  };
  //funcion para refrescar pantalla
  const refresh = () => {
    openLoad();
    AXIOS.get(API.GET_ADS, {
    }).then((response) => {
      setcompanies(response.data);
      closeLoad();
    });
  };
  //Funcion para cerrar mensaje de exito
  const handleClose_success = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };
  //Funcion para cambiar el e stado del anuncio
  const ChangerStatus = (row) => {
    openLoad();
    AXIOS.put(API.CHANGE_STATUS_ADS + row.ad_id, {
    }).then((response) => {
      closeLoad(false);
      setMessage("Estado de anuncio actualizado con exito")
      handleClick_success();
      refresh();
    }).catch((error) => {
    });
  };


  //Pre-carga de apis
  React.useEffect(() => {
    //API get de anuncios
    AXIOS.get(API.GET_ADS, {
    }).then((response) => {
      setcompanies(response.data);
      setLoading(false);
    });
  }, []);

  //Inicializando datos que trae la api de get
  function createData(ad_id, tipo, imagen, link, fecha_vigencia, estado, visualizaciones_unicas,
    visualizaciones, created_by_user, instancia_id, nombre, imagen2, cantidad_interesados, configuraciones) {
    return {
      ad_id, tipo, imagen, link, fecha_vigencia, estado, visualizaciones_unicas,
      visualizaciones, created_by_user, instancia_id, nombre, imagen2, cantidad_interesados, configuraciones
    };
  }

  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.ad_id, item.tipo, item.imagen, item.link, item.fecha_vigencia, item.estado, item.visualizaciones_unicas,
        item.visualizaciones, item.created_by_user, item.instancia_id, item.nombre, item.imagen2, item.cantidad_interesados, item.configuraciones)
    )
  });

  //Funcion para capturar archivo de subida
  const subirArchivos = (e) => {
    setArchivos(e);
  };

  //Funcion para cambiar la cantidad de data que muestra la tabla
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //Funcion para la paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Funcion para editar el anuncion
  const submitEdit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    openLoad();
    //Condicional si elige otro tipo de anuncio que no sea noticia
    if (data.get('type-ads') != 6) {
      closeLoad();
      setAlert(true);
      seterrorsMessage("Por favor elegir el tipo de anuncio NOTICIAS");
    } else {
      //API para editar el anuncio
      AXIOS.post(API.CHANGE_STATUS_ADS + dataEdit.ad_id, {
        user_id: 22025,
        tipo: data.get('type-ads'),
        link: data.get('link'),
        instancia_id: 154,
      }).then((response) => {
        closeLoad();
        handleClose();
        setMessage("Anuncio actualizado con exito")
        handleClick_success();
        refresh();
        // setOpen_toast(true);
        console.log(response)
      });
    };
  };

  const handleCompany = (event) => {
    setCompany(event.target.value);
  };
  //Funcion de prioridad
  const handlePriority = (event) => {
    setPriority(event.target.value);
  };
  //Funcion para usar acordeon
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //Funcion para almacenar datos del formulario
  const textConst = (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //Funcion para validar el tipo de anuncio
    if (archivos == null) {
      setAlert(true);
      seterrorsMessage("Por favor subir una imagen");
    } else if (data.get('type-ads') != 6) {
      setAlert(true);
      seterrorsMessage("Por favor elegir el tipo de anuncio NOTICIAS");
    } else {
      openLoad();
      const f = new FormData();
      //Data para enviar al post api
      for (let index = 0; index < archivos.length; index++) {
        f.append("imagen", archivos[index]);
        f.append("link", data.get('link'));
        f.append("tipo", data.get('type-ads'));
        f.append("instancia_id", 154);
        f.append("user_id", localStorage.getItem("user_id"));
      }

      console.log("datos", f)
      //API get para cargar anuncio
      AXIOS.post(API.GET_ADS, f, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        closeLoad();
        setMessage("Anuncio creado con exito")
        handleClick_success();
        refresh();
        // setOpen_toast(true);
        console.log(response)
      });
    };
  };

  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> :
      <Dashboard>
        {/* Modal para editar anuncio */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} component="form" onSubmit={submitEdit}>
            <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
              <Typography><b>EDITAR ANUNCIO</b></Typography>
            </Stack>
            <Grid xs={4} style={{ marginTop: 20 }}>
              <TextField
                required
                fullWidth
                defaultValue={dataEdit.link}
                id="input-with-icon-textfield"
                label="Link de acción"
                name="link"
                style={{ width: '90%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Tipo de anuncio</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="type-ads"
                  required
                  defaultValue={dataEdit.tipo}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Popup" />
                  <FormControlLabel value="2" control={<Radio />} label="Banner Carga" />
                  <FormControlLabel value="3" control={<Radio />} label="Banner Interesados" />
                  <FormControlLabel value="4" control={<Radio />} label="Banner Beneficios" />
                  <FormControlLabel value="5" control={<Radio />} label="Banner Contratados" />
                  <FormControlLabel value="6" control={<Radio />} label="Banner Noticias" />
                  <FormControlLabel value="7" control={<Radio />} label="Banner Servicio" />
                  <FormControlLabel value="8" control={<Radio />} label="Tarjeta Servicio" />

                </RadioGroup>
              </FormControl>
            </Grid>
            <div style={{ display: "flex" }}>
              <Button
                onClick={handleClose}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1, background: "gray" }}
              >
                Cancelar
              </Button>
              <div style={{ margin: 18 }}></div>
              <Button type="submit" fullWidth variant="contained"
                sx={{ mt: 3, mb: 1, background: " #e35233" }}
              > Actualizar
              </Button>
            </div>
          </Box>
        </Modal>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        {/* Modal de mensaje con exito */}
        <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleClose_success}>
          <Alert onClose={handleClose_success} severity="success" sx={{ width: '100%' }}>
            {dataMessage}
          </Alert>
        </Snackbar>
        {/* Modal de mensaje error */}
        <Modal
          open={open_alert}
          onClose={closeAlert}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            p: 1,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
              {errorsMessage}
            </Typography>

          </Box>
        </Modal>

        {/* Formulario para agregar anuncio */}
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Agregar anuncio
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="form" onSubmit={textConst}>
              <Button fullWidth variant="contained" type="submit"
                sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}>PUBLICAR
              </Button>
              <input type="file" fullWidth variant="contained" id='13' referencia='1-2' name="files" onChange={(e) => subirArchivos(e.target.files)} sx={{ background: "white", color: "#FF5733", }} />

              <Grid container direction="row" xs={12} alignItems="center">

                <Grid xs={4} >
                  <TextField
                    id="input-with-icon-textfield"
                    onKeyPress={isInputNumber}
                    label="Horario (0-24)"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessAlarmsIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} alignItems="center" mt={3}>
                <Grid xs={4} >
                  <TextField
                    required
                    id="input-with-icon-textfield"
                    label="Link de acción"
                    name="link"
                    style={{ width: '90%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid xs={4} >
                  <TextField
                    id="input-with-icon-textfield"
                    label="Cantida de gente"
                    onKeyPress={isInputNumber}
                    style={{ width: '90%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid xs={4} >
                  <TextField
                    id="input-with-icon-textfield"
                    label="Cantidad de vistas"
                    onKeyPress={isInputNumber}
                    style={{ width: '90%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PeopleIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} alignItems="center" mt={3}>
                <Grid xs={3} alignItems="center" justifyContent="center" ml={2}>
                  <BusinessIcon sx={{ marginTop: 2, marginRight: 1, }} />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '80%' }}>
                    <InputLabel id="demo-simple-select-standard-label">Prioridad</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={priority}
                      onChange={handlePriority}
                      label="Age"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>1</MenuItem>
                      <MenuItem value={20}>2</MenuItem>
                      <MenuItem value={30}>3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={3} ml={2}>
                  <TextField autoComplete="given-name" name="upload_date" type="date"
                    fullWidth id="upload_date" label="Desde" autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={3} ml={2}>
                  <TextField autoComplete="given-name" name="upload_date" type="date"
                    fullWidth id="upload_date" label="Hasta" autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Tipo de anuncio</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-ads"
                    required
                    defaultValue={6}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Popup" />
                    <FormControlLabel value="2" control={<Radio />} label="Banner Carga" />
                    <FormControlLabel value="3" control={<Radio />} label="Banner Interesados" />
                    <FormControlLabel value="4" control={<Radio />} label="Banner Beneficios" />
                    <FormControlLabel value="5" control={<Radio />} label="Banner Contratados" />
                    <FormControlLabel value="6" control={<Radio />} label="Banner Noticias" />
                    <FormControlLabel value="7" control={<Radio />} label="Banner Servicio" />
                    <FormControlLabel value="8" control={<Radio />} label="Tarjeta Servicio" />

                  </RadioGroup>
                </FormControl>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, id) => (
                  <TableRow
                    key={row.id}
                    hover role="checkbox" tabIndex={-1}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{<>{(row.ad_id == null | row.ad_id == "") ? (<>{"-"}</>) :
                      (<>{row.ad_id}</>)}</>}</TableCell>
                    <TableCell align="center"><Avatar direction="row" align="center" alt={row.imagen} src={row.imagen} /></TableCell>

                    <TableCell align="center">{<>{(row.tipo == null | row.tipo == "") ? (<>{"-"}</>) :
                      (<>{"Noticia"}</>)}</>}</TableCell>
                    <TableCell align="center">{<>{(row.link == null | row.link == "") ? (<>{"-"}</>) :
                      (<>{row.link}</>)}</>}</TableCell>
                    <TableCell align="center">LOGICEM S.A.S</TableCell>
                    <TableCell align="center">{<>{(row.estado == 0) ? (<>{"Inactivo"}</>) :
                      (<>{"Activo"}</>)}</>}</TableCell>
                    <TableCell align="center">
                      <div size="small" style={{ display: "flex" }}>
                        {/* <Button size="small" style={{color:"grey",size:"small"}} onClick={()=>console.log('funciona')}><VisibilityOffIcon/></Button> */}
                        <Button title="Cambiar estado" onClick={() => ChangerStatus(row)}><ChangeCircleIcon /></Button>
                        <Button title="Editar" style={{ color: "grey" }} onClick={() => handleOpen(row)}><EditIcon /></Button>

                      </div> </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>

      </Dashboard>
    }</>);
};
export default AdvertisementPage 
