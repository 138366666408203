//Importacion de complementos
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../assets/img/logo.png';
import { Grid } from '@mui/material';

//Estilo de grid
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 120,
  borderRadius: 4,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};

const LoadingModal = React.forwardRef((props, ref) => (
  <div>
    {/* Gride de carga */}
    <Box sx={style}>
      <div style={{ marginTop: "20%", }}>
        <Grid
          container
          justify='center'
          direction='column'
          alignItems='center'
        >
          <div>
            <Grid itme xs={2}>
              <CircularProgress color="secondary" />
            </Grid>
          </div>
          <img style={{ width: 20, marginTop: -35 }} src={logo} alt="logo" />
          <h4 style={{ fontFamily: "arial" }}>Cargando...</h4>
        </Grid>
      </div>
    </Box>
  </div>
  )
);

export default LoadingModal;