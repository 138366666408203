//Importacion de complementos
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dashboard from '../components/Dashboard';
import { Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API, { AXIOS } from '../environment/api';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ShowTableColumn } from '../components/ShowTableColumn';
import { ButtonShowColumnTable } from '../components/ButtonShowColumnTable';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import LoadingScreen from './LoadingScreen';
import IosShareIcon from '@mui/icons-material/IosShare';
import { CopyDataToClipboard } from "../helpers/CopyTableToClipboard";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//Funcion de tab en paneles
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//Headers de tabla
const headers = [
  "Fecha de creación",
  "Ultima conexión",
  "Cédula",
  "Nombre Completo",
  "Celular",
  "Placa",
  "Viajes Realizados",
  "Documentos",
];

export default function DetailPublications() {
  //Funcion para capturar fecha actual
  var today = new Date();
  var day = today.getDate();
  var months = (1 + today.getMonth());
  if (months < 10) {
    var month = ("0" + months);
  } else {
    var month = months;
  }
  var year = today.getFullYear();
  //Funcion para inicializar los datos
  const [query, setQuery] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [dateBegins, setDateBegin] = React.useState("2019-03-01");
  const [dateEnds, setDateEnd] = React.useState(year + "-" + month + "-" + day);
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState(['ID', 'Fecha', 'Empresa', 'Origen', 'Destino', 'Cliente', 'Cédula', 'Placa', 'Usuario', 'Interesados', 'Contratados']);
  const [columChecked, setColumChecked] = React.useState(['ID', 'Fecha', 'Empresa', 'Origen', 'Destino', 'Cliente', 'Cédula', 'Placa', 'Usuario', 'Interesados', 'Contratados']);
  const componentRef = React.useRef()
  const [load, setLoad] = React.useState(false);
  const [dataMessage, setMessage] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('origen');

  //Datos de select para el tipo de busqueda
  const handleChangeSearchType = (event) => {
    setFilter('');
    setSearchType(event.target.value);
    setcompanies(query);
    if (event.target.value == 1) {
      setSearchTypes("origen");
    };
    if (event.target.value == 2) {
      setSearchTypes("destino");
    };
    if (event.target.value == 3) {
      setSearchTypes("cliente");
    };
    if (event.target.value == 4) {
      setSearchTypes("cedula");
    };
  };

  //Funcion para abrir & cerrar modal
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);

  //Funcion para capturar el valor del tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Funcion para cerrar notificacion
  const handleClose_success = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Funcion para usar filtro de inicio de fecha
  const dateBegin = (event) => {
    openLoad();
    setDateBegin(event.target.value);
    AXIOS.get(API.OFFICE + "begin=" + (event.target.value) + "&end=" + dateEnds, {
    }).then((response) => {
      if (response) {
        closeLoad();
      }
      setcompanies(response.data.user_services);
      setQuery(response.data.user_services);
    });
  };

  //Funcion para usar filtro de fin de fecha
  const dateEnd = (event) => {
    openLoad();
    setDateEnd(event.target.value);
    AXIOS.get(API.OFFICE + "begin=" + dateBegins + "&end=" + (event.target.value), {
    }).then((response) => {
      if (response) {
        closeLoad();
      }
      setcompanies(response.data.user_services);
      setQuery(response.data.user_services);
    });
  };

  //Funcion para recargar la pagina
  const refresh = (event) => {
    openLoad();
    AXIOS.get(API.OFFICE + "begin=" + "2019-03-01" + "&end=" + year + "-" + month + "-" + day, {
    }).then((response) => {
      if (response) {
        closeLoad();
        // setLoading(false);  
      }
      setcompanies(response.data.user_services);
      setQuery(response.data.user_services);
    });
  };

  //Pre-carga de apis
  React.useEffect(() => {
    //API carga de data
    AXIOS.get(API.OFFICE + "begin=" + "2019-03-01" + "&end=" + year + "-" + month + "-" + day, {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.user_services);
      setQuery(response.data.user_services);
    });
  }, []);

  //Funcion para capturar el input del filtro
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };

  //Inicializando datos que trae la api de get  
  function createData(id, fecha, empresa, origen, destino, cliente, cedula,
    placa, nombres, apellidos, interesados, contratados) {
    return {
      id, fecha, empresa, origen, destino, cliente, cedula,
      placa, nombres, apellidos, interesados, contratados
    };
  }

  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.id, item.fecha, item.empresa, item.origen, item.destino, item.cliente, item.cedula,
        item.placa, item.nombres, item.apellidos, item.interesados, item.contratados)
    )
  });

  //Funcion para impirmir PDF
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Publicaciones por usuario',
    onAfterPrint: () => alert('Print success')
  })

  //Funcion para copiar datos de la tabla  
  const handleClickCopyToClipboard = (checked, array, attributes) => {
    CopyDataToClipboard(checked, array, attributes, () => {
      setMessage("Tabla copiada");
      setOpenSuccess(true);
      setTimeout(() => {
        setOpenSuccess(false);
      }, 2000)
    });
  };

  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> : <>
        {/* Modal de notificaciones */}
        <Snackbar
          open={openSuccess}
          autoHideDuration={8000}
          onClose={handleClose_success}
        >
          <Alert
            onClose={handleClose_success}
            severity="success"
            sx={{ width: "100%" }}
          >
            {dataMessage}
          </Alert>
        </Snackbar>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        <Dashboard title='Publicaciones por usuarios'>
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Columnas de tabla para copiar */}
          <Paper sx={{ width: '100%', overflow: 'hidden' }} >
            <Grid size="small" style={{ margin: 10, float: 'left', display: "flex" }}>
              <ButtonShowColumnTable checked={checked} setChecked={setColumChecked} />
              <div style={{ marginLeft: 10 }}>
                <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={() =>
                    handleClickCopyToClipboard(checked, rows, [
                      "id",
                      "fecha",
                      "empresa",
                      "origen",
                      "destino",
                      "cliente",
                      "cedula",
                      "placa",
                      "nombres",
                      "interesados",
                      "contratados",
                    ])
                  }
                >
                  Copiar
                </Button>
              </div>
              {/* Botono para impirmir en PDF */}
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Button variant="outlined" onClick={handlePrint} startIcon={<PrintIcon />}>Imprimir</Button>
              </div>
              {/* Boton para exportar en EXCEl */}
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className='export-btn'
                table="publicaciones"
                filename="Publicación por usuario"
                sheet="tablexls"
                buttonText={<div style={{ display: "flex" }}><IosShareIcon /><div style={{ marginTop: 4, marginLeft: 6 }}>EXPORTAR EXCEL</div></div>} />
            </Grid>
            <Grid size="small" style={{ margin: 10, float: 'right', display: "flex" }}>
              {/* Boton para filtrar por fecha de inicio */}
              <div>
                <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                  fullWidth id="upload_date" label="Desde" autoFocus
                  onChange={dateBegin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* Botono para filtrar por fecha fin */}
              <div style={{ marginLeft: 10 }}>
                <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                  fullWidth id="upload_date" label="Hasta" autoFocus
                  onChange={dateEnd}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
          </Paper>
          {/* TAB de navegacion */}
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="VIGENTES" {...a11yProps(0)} />
                  <Tab label="COMPLETADOS" {...a11yProps(1)} />
                </Tabs>
              </Box>
              {/* 1er tab */}
              <TabPanel value={value} index={0}>
                {/* Input de filtro */}
                <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                  <TextField inputProps={{ style: { textTransform: 'lowercase' } }} id="filled-basic" label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} defaultValue={filter} startIcon={<SearchIcon />} />
                </div>
                {/* Select para el tipo de filtro */}
                <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                    <Select
                      sx={{ minWidth: 160 }}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={1}
                      value={searchType}
                      label="Tipo de busqueda"
                      onChange={handleChangeSearchType}
                    >
                      <MenuItem value={1}>Origen</MenuItem>
                      <MenuItem value={2}>Destino</MenuItem>
                      <MenuItem value={3}>Cliente</MenuItem>
                      <MenuItem value={4}>Cédula</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* Boton de resfrescar */}
                <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                  <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button>
                </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  {/* Tabla de datos */}
                  <Table stickyHeader aria-label="sticky table" id="publicaciones" ref={componentRef}>
                    <TableHead>
                      <TableRow>
                        <ShowTableColumn checked={columChecked}>
                          <TableCell align="center"><div id='ID'>ID</div></TableCell>
                          <TableCell align="center"><div id='Fecha'>Fecha</div></TableCell>
                          <TableCell align="center"><div id='Empresa'>Empresa</div></TableCell>
                          <TableCell align="center"><div id='Origen'>Origen</div></TableCell>
                          <TableCell align="center"><div id='Destino'>Destino</div></TableCell>
                          <TableCell align="center"><div id='Cliente'>Cliente</div></TableCell>
                          <TableCell align="center"><div id='Cédula'>Cédula</div></TableCell>
                          <TableCell align="center"><div id='Placa'>Placa</div></TableCell>
                          <TableCell align="center"><div id='Usuario'>Usuario</div></TableCell>
                          <TableCell align="center"><div id='Interesados'>Interesados</div></TableCell>
                          <TableCell align="center"><div id='Contratados'>Contratados</div></TableCell>
                        </ShowTableColumn>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {/* Filtro de origen */}
                        {<>{(searchTypes == "origen") ? (<>
                          {query.filter((row) =>
                            row.origen.toLowerCase().includes(filter)
                          ).map((row) => (<>

                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          </>)
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de destino */}
                        {<>{(searchTypes == "destino") ? (<>
                          {query.filter((row) =>
                            row.destino.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de clientes */}
                        {<>{(searchTypes == "cliente") ? (<>
                          {query.filter((row) =>
                            row.cliente.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de cedula */}
                        {<>{(searchTypes == "cedula") ? (<>
                          {query.filter((row) =>
                            row.cedula.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                      </>
                    </TableBody>
                    <TableBody>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Tabla cuando no trae data */}
                {<>{(query == "" | query == null) ?
                  <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
                {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
              </TabPanel>
              {/* 2do tab       */}
              <TabPanel value={value} index={1}>
                {/* Input de filtro */}
                <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                  <TextField id="filled-basic" label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} defaultValue={filter} startIcon={<SearchIcon />} />
                </div>
                {/* Select para el tipo de filtro */}
                <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                    <Select
                      sx={{ minWidth: 160 }}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={1}
                      value={searchType}
                      label="Tipo de busqueda"
                      onChange={handleChangeSearchType}
                    >
                      <MenuItem value={1}>Origen</MenuItem>
                      <MenuItem value={2}>Destino</MenuItem>
                      <MenuItem value={3}>Cliente</MenuItem>
                      <MenuItem value={4}>Cédula</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* Boton de resfrescar */}
                <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                  <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button>
                </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  {/* Tabla de datos */}
                  <Table stickyHeader aria-label="sticky table" id="publicaciones" ref={componentRef}>
                    <TableHead>
                      <TableRow>
                        <ShowTableColumn checked={columChecked}>
                          <TableCell align="center"><div id='ID'>ID</div></TableCell>
                          <TableCell align="center"><div id='Fecha'>Fecha</div></TableCell>
                          <TableCell align="center"><div id='Empresa'>Empresa</div></TableCell>
                          <TableCell align="center"><div id='Origen'>Origen</div></TableCell>
                          <TableCell align="center"><div id='Destino'>Destino</div></TableCell>
                          <TableCell align="center"><div id='Cliente'>Cliente</div></TableCell>
                          <TableCell align="center"><div id='Cédula'>Cédula</div></TableCell>
                          <TableCell align="center"><div id='Placa'>Placa</div></TableCell>
                          <TableCell align="center"><div id='Usuario'>Usuario</div></TableCell>
                          <TableCell align="center"><div id='Interesados'>Interesados</div></TableCell>
                          <TableCell align="center"><div id='Contratados'>Contratados</div></TableCell>
                        </ShowTableColumn>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Filtro de origen */}
                      <>
                        {<>{(searchTypes == "origen") ? (<>
                          {query.filter((row) =>
                            row.origen.toLowerCase().includes(filter)
                          ).map((row) => (<>

                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          </>)
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de destino */}
                        {<>{(searchTypes == "destino") ? (<>
                          {query.filter((row) =>
                            row.destino.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de clientes */}
                        {<>{(searchTypes == "cliente") ? (<>
                          {query.filter((row) =>
                            row.cliente.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                        {/* Filtro de cedula */}
                        {<>{(searchTypes == "cedula") ? (<>
                          {query.filter((row) =>
                            row.cedula.toLowerCase().includes(filter)
                          ).map((row) => (
                            <TableRow
                              key={row.id}
                              hover role="checkbox" tabIndex={-1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {/* Lista de columnas */}
                              <ShowTableColumn checked={columChecked}>
                                <TableCell align="center"><div id='ID'>{<>{(row.id == null | row.id == "") ? (<>{"-"}</>) :
                                  (<>{row.id}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Fecha'>{<>{(row.fecha == null | row.fecha == "") ? (<>{"-"}</>) :
                                  (<>{row.fecha}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                  (<>{row.empresa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                                  (<>{row.origen}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                                  (<>{row.destino}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cliente'>{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                  (<>{row.cliente}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Cédula'>{<>{(row.cedula == null | row.cedula == "") ? (<>{"-"}</>) :
                                  (<>{row.cedula}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Placa'>{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                  (<>{row.placa}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Usuario'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                                  (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Interesados'>{<>{(row.interesados == null | row.interesados == "") ? (<>{"-"}</>) :
                                  (<>{row.interesados}</>)}</>}</div>
                                </TableCell>
                                <TableCell align="center"><div id='Contratados'>{<>{(row.contratados == null | row.contratados == "") ? (<>{"-"}</>) :
                                  (<>{row.contratados}</>)}</>}</div>
                                </TableCell>
                              </ShowTableColumn>
                            </TableRow>
                          )
                          )}
                        </>) :
                          null}</>}
                      </>
                    </TableBody>
                    <TableBody>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Tabla cuando no trae data */}
                {<>{(query == "" | query == null) ?
                  <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
                {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
              </TabPanel>
            </Box>
          </Paper>
        </Dashboard>
      </>}</>);
};