import React, { Component } from 'react';
import { CSVLink } from "react-csv";

const headers = [
  { label: "Origen", key: "Origen" },
  { label: "Destino", key: "Destino" },
  { label: "Cantidad", key: "Cantidad" },
];

class AsyncCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
    this.csvLinkEl = React.createRef();
  }

  getUserList = () => {
    return fetch('https://jsonplaceholder.typicode.com/users')
      .then(res => res.json());
  }

  downloadReport = async () => {
    const data = await this.getUserList();
    this.setState({ data: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        <input type="button" value="Exportar CSV" onClick={this.downloadReport} />
        <CSVLink
          headers={headers}
          filename="table.csv"
          data={[]}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

export default AsyncCSV;