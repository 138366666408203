
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDqtH4c7XpKPVgjpeBYMCRNgB_ESg6Q3xw",
  authDomain: "logicem-e66e3.firebaseapp.com",
  databaseURL: "https://logicem-e66e3-default-rtdb.firebaseio.com",
  projectId: "logicem-e66e3",
  storageBucket: "logicem-e66e3.appspot.com",
  messagingSenderId: "932689102870",
  appId: "1:932689102870:web:3f741244432344ae574d37",
  measurementId: "G-R7B5ETY41K"
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;

