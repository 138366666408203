import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LoadingScreen from './LoadingScreen';
import API, { AXIOS } from '../environment/api';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import LoadingModal from "../components/LoadingModal"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';

export default function RolesPermissions() {
  // Funcion para inicializar los datos
  const [open_alert, setAlert] = React.useState(false);
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_update, setOpen_toast_update] = React.useState(false);
  const [open_toast_error, setOpen_toast_error] = React.useState(false);
  const [selectRol, setSelectRol] = React.useState([]);
  const [defaultSelectRol, setDefaultSelectRol] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [modalUpdate, setModalUpdate] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [dataChange, setDataChange] = React.useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Funcion para cerrar modal de alerta
  const closeAlert = () => setAlert(false);

  // Funcion para cerrar notificacion de creacion
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };

  // Funcion para cerrar notificacion de actualizacion
  const handleClose_toast_update = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_update(false);
  };

  // Funcion para cerrar notificacion de error
  const handleClose_toast_error = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_error(false);
  };

  const ConvertBoleanjson = (data) => {
    let updatedResponse = data.map(obj => {
      let newObj = { ...obj };
        for (let key in newObj) {
          if (key !== 'id' && key !== 'role_id') {
            if (newObj[key] === 1) newObj[key] = true;
            else if (newObj[key] === 0) newObj[key] = false;
          }
        }
      return newObj;
    });
    setRows(updatedResponse);
    setDefaultSelectRol(updatedResponse[0]);
    setSelectRol(updatedResponse[0]);
    setLoading(false); 
  }
    
  //Pre-carga de apis
  React.useEffect(() => {
    AXIOS.get(API.GET_PERMISOS).then((response) => {
      if (response) {
        ConvertBoleanjson(response.data);
      }
    });  
  }, []);

  // metodo para cambiar de rol
  function handleChangeRole(id) {
    rows.forEach((row) => {
      if (row.role_id === id) {
        setSelectRol(row);
        setDefaultSelectRol(row);
      }
    });
  }

  // Metodo para cambiar el estado del switch
  const handleSwitchChange = (event, vista) => {
    setSelectRol(prevState => ({
      ...prevState,
      [vista]: event.target.checked
    }));
  };
  
  const handlePreupdate = () => {
    if (JSON.stringify(selectRol) !== JSON.stringify(defaultSelectRol)) {
      setModalUpdate(true);
      const differentAttributes = Object.keys(selectRol).filter((key) => defaultSelectRol[key] !== selectRol[key]);
      let differentAttributesNames = [];
      for (const attribute of differentAttributes) {
        switch (attribute) {
          case 'kpi':
          differentAttributesNames.push('Key Performance Indicator');
          break;
            case 'carrier':
          differentAttributesNames.push('Estadísticas de usuarios registrados');
          break;
            case 'detail_publications':
          differentAttributesNames.push('Publicaciones por usuarios');
          break;
            case 'most_frequent_routes':
          differentAttributesNames.push('Cantidad de publicaciones por ciudad de origen y destino');
          break;
            case 'available':
          differentAttributesNames.push('Conductores Disponible');
          break;
            case 'available_trips':
          differentAttributesNames.push('Viajes Disponibles');
          break;
            case 'avaible_enturnado':
          differentAttributesNames.push('Transportistas enturnados');
          break;
            case 'services':
          differentAttributesNames.push('Viajes Publicados');
          break;
            case 'manifested':
          differentAttributesNames.push('Transportista en ruta');
          break;
            case 'fidelizada':
          differentAttributesNames.push('Grupos Fidelizados');
          break;
            case 'miflota':
          differentAttributesNames.push('Mi flota segura');
          break;
            case 'master':
          differentAttributesNames.push('Maestro Usuario');
          break;
            case 'drivers':
          differentAttributesNames.push('Usuarios Registrados');
          break;
            case 'owners':
          differentAttributesNames.push('Lista de propietarios');
          break;
            case 'forks':
          differentAttributesNames.push('Lista de tenedores');
          break;
            case 'benefits':
          differentAttributesNames.push('Publicados');
          break;
            case 'advertisement':
          differentAttributesNames.push('Anuncios');
          break;
            case 'static':
          differentAttributesNames.push('Estadísticas');
          break;
            case 'notice':
          differentAttributesNames.push('noticias');
          break;
            case 'pqrs':
          differentAttributesNames.push('PQRS');
          break;
            default:
          break;
          }
          setDataChange(differentAttributesNames);
      }
    } else {
      setOpen_toast_error(true);
      seterrorsMessage("No se han realizado cambios");
    }
  } 

  // Metodo para actualizar los datos
  const handleUpdate = () => {
    setLoad(true);
    const roles_usuario = JSON.parse(localStorage.getItem('roles_usuario'));
    selectRol.roles_usuario = roles_usuario.role_id;
    AXIOS.post(API.POST_UPDATE_PERMISOS, selectRol).then((response) => {
      if (response) {
        setModalUpdate(false);
        setOpen_toast_update(true);
      }
    }, (error) => {
      setLoad(false);
      setOpen_toast_error(true);
      seterrorsMessage(error.response.data.message);
    });
    AXIOS.get(API.GET_PERMISOS).then((response) => {
      if (response) {
        ConvertBoleanjson(response.data);
      }
    }).catch((error) => {
      setLoad(false);
      setOpen_toast_error(true);
      seterrorsMessage(error.response.data.message);
    });
    setLoad(false);
  }

  return (
    <>
      {(Loading === true) ? (
        Loading === "" ? null : <LoadingScreen />
      ) : (
        <Dashboard title='Permisos por Roles'>
          <div>
            {/* Notificacion de creacion con exito */}
            <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
              <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
                Creado con exito
              </Alert>
            </Snackbar>
            {/* Notificacion de actualizacion con exito */}
            <Snackbar open={open_update} autoHideDuration={8000} onClose={handleClose_toast_update}>
              <Alert onClose={handleClose_toast_update} severity="success" sx={{ width: '100%' }}>
                Actualizado con exito
              </Alert>
            </Snackbar>
            {/* Notificacion de error */}
            <Snackbar open={open_toast_error} autoHideDuration={18000} onClose={handleClose_toast_error}>
              <Alert onClose={handleClose_toast_error} severity="error" sx={{ width: '100%' }}>
                {errorsMessage}
              </Alert>
            </Snackbar>
          </div>
          {/* Modal de alerta */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 4,
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                {/* Por favor complete todos los campos obligatorios* */}
                {errorsMessage}
              </Typography>
            </Box>
          </Modal>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Modal de Actualizacion */}
          <Modal
            open={modalUpdate}
            onClose={() => setModalUpdate(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 4,
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' marginBottom={2}>
                ¿Está seguro de actualizar los siguientes permisos?
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' marginBottom={1}>
                {dataChange.map((data) => (
                  <Typography>Vista : {data}</Typography>
                ))}
              </Typography>
              <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                <Button type="submit" fullWidth variant="contained" onClick={() => handleUpdate()}
                  sx={{ background: "#FF5733", width: 300, margin:1 }}>Actualizar
                </Button>
                <Button type="submit" fullWidth variant="contained" onClick={() => setModalUpdate(false)}
                  sx={{ background: "#FF5733", width: 300, margin:1 }}>Cancelar
                </Button>
              </Stack>
            </Box>
          </Modal>
          <Grid item xs={12} md={8} lg={29} marginTop={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
              {/* Tipo de filtro de busqueda */}
              <div style={{ display: "flex", float: "letf", marginTop: 10}}>
                <div style={{ margin: 15 }}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                    <Select
                      size='large'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={1}
                      label="Tipo de busqueda"
                      onChange={onclick => handleChangeRole(onclick.target.value)}
                    >
                      <MenuItem value={1}>Operador Trafico</MenuItem>
                      <MenuItem value={19}>Operador Logistico</MenuItem>
                      <MenuItem value={13}>Operador Seguridad</MenuItem>
                      <MenuItem value={20}>Marketing & Servicio</MenuItem>
                      <MenuItem value={7}>Administrador de Logicem</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ margin: 15 }}>
                  <Button type="submit" fullWidth variant="contained" onClick={() => handlePreupdate()}
                    sx={{ background: "#FF5733", width: "auto" }}>Actualizar
                  </Button>
                </div>
              </div>
              {/* Contenido de tabla */}              
              <div style={{ margin: 15 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Dashboard
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: "#0000000a", marginLeft: 10, marginRight:10, flex:1 }}>
                    Key Performance Indicator
                    <Switch
                        checked={selectRol.kpi}
                        onChange={event => handleSwitchChange(event, 'kpi')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Estadísticas de usuarios registrados
                    <Switch
                        checked={selectRol.carrier}
                        onChange={event => handleSwitchChange(event, 'carrier')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Publicaciones por usuarios
                    <Switch
                        checked={selectRol.detail_publications}
                        onChange={event => handleSwitchChange(event, 'detail_publications')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Cantidad de publicaciones por ciudad de origen y destino
                    <Switch
                        checked={selectRol.most_frequent_routes}
                        onChange={event => handleSwitchChange(event, 'most_frequent_routes')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Tablero Carga
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Conductores Disponible
                    <Switch
                        checked={selectRol.available}
                        onChange={event => handleSwitchChange(event, 'available')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Viajes Disponibles
                    <Switch
                        checked={selectRol.available_trips}
                        onChange={event => handleSwitchChange(event, 'available_trips')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Disponible
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Transportistas enturnados
                    <Switch
                        checked={selectRol.avaible_enturnado}
                        onChange={event => handleSwitchChange(event, 'avaible_enturnado')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Viajes
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Viajes Publicados
                    <Switch
                        checked={selectRol.services}
                        onChange={event => handleSwitchChange(event, 'services')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Transportista en ruta
                    <Switch
                        checked={selectRol.manifested}
                        onChange={event => handleSwitchChange(event, 'manifested')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                  >
                    Flota
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Grupos Fidelizados
                    <Switch
                        checked={selectRol.fidelizada}
                        onChange={event => handleSwitchChange(event, 'fidelizada')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Mi flota segura
                    <Switch
                        checked={selectRol.miflota}
                        onChange={event => handleSwitchChange(event, 'miflota')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6-content"
                    id="panel6-header"
                  >
                    Mi empresa
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Maestro Usuario
                    <Switch
                        checked={selectRol.master}
                        onChange={event => handleSwitchChange(event, 'master')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7-content"
                    id="panel7-header"
                  >
                    Aplicacion Movil
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Usuarios Registrados
                    <Switch
                        checked={selectRol.drivers}
                        onChange={event => handleSwitchChange(event, 'drivers')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Lista de propietarios
                    <Switch
                        checked={selectRol.owners}
                        onChange={event => handleSwitchChange(event, 'owners')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Lista de tenedores
                    <Switch
                        checked={selectRol.forks}
                        onChange={event => handleSwitchChange(event, 'forks')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8-content"
                    id="panel8-header"
                  >
                    Beneficios
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Publicados
                    <Switch
                        checked={selectRol.benefits}
                        onChange={event => handleSwitchChange(event, 'benefits')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9-content"
                    id="panel9-header"
                  >
                    Publicidad
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    Anuncios
                    <Switch
                        checked={selectRol.advertisement}
                        onChange={event => handleSwitchChange(event, 'advertisement')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: 'white', margin: 10 }}>
                    Estadísticas
                    <Switch
                        checked={selectRol.static}
                        onChange={event => handleSwitchChange(event, 'static')}
                      />
                  </AccordionDetails>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    noticias
                    <Switch
                        checked={selectRol.pqrs}
                        onChange={event => handleSwitchChange(event, 'pqrs')}
                      />
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10-content"
                    id="panel10-header"
                  >
                    PQRS
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#0000000a', margin: 10 }}>
                    PQRS
                    <Switch
                        checked={selectRol.vista21}
                        onChange={event => handleSwitchChange(event, 'vista21')}
                      />
                  </AccordionDetails>
                </Accordion>
              </div>
              {<>{(rows === "") ?
                <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                  <Typography>NO HAY DATOS DISPONIBLES</Typography>
                </Stack> :
                ""}</>}
            </Paper>
          </Grid>
        </Dashboard>
      )}
    </>
  );
};