import React, { useState, useEffect } from 'react';
import API, { AXIOS } from '../../environment/api';
import { Stack, Divider, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Snackbar, Alert } from '@mui/material';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumPropietario({func_update, ...props}) {
  const [loading, setLoading] = useState(true);
  const [blockButton, set_blockButton] = useState(false);
  const [dataTypeDNI, array_typeDNI] = useState([]);
  const [dataBank, array_Bank] = useState([]);
  const [hv_titulCuenta_propietario, set_hv_titulCuenta_propietario] = useState('');
  const [hv_tipDNICuentaTitul_id_propietario, set_hv_tipDNICuentaTitul_id_propietario] = useState('');
  const [hv_DNICuentaTitul_propietario, set_hv_DNICuentaTitul_propietario] = useState('');
  const [hv_nombres_propietario, set_hv_nombres_propietario] = useState('');
  const [hv_apellidos_propietario, set_hv_apellidos_propietario] = useState('');
  const [hv_tipoDNI_propietario, set_hv_tipoDNI_propietario] = useState('');
  const [hv_DNI_propietario, set_hv_DNI_propietario] = useState('');
  const [severity, setSeverity] = useState("error");
  const [openSnackbar, setOpeSnackbar] = useState(false);
  const [messageResponse, setMessageResponse] = useState("");
  const [hv_cuentPropia, set_hv_cuentaPropia] = useState("");

  const handleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpeSnackbar(false);
  };

  useEffect(() => {
    set_hv_cuentaPropia(props.data.data.propietario_cuenta_propia)
    set_hv_nombres_propietario(props.data.data.propietario_nombre);
    set_hv_apellidos_propietario(props.data.data.propietario_apellido);
    set_hv_tipoDNI_propietario(props.data.data.propietario_tipoidentificacion_id);
    set_hv_DNI_propietario(props.data.data.propietario_identificacion);
    set_hv_titulCuenta_propietario(props.data.data.propietario_nombre_titular ? props.data.data.propietario_nombre_titular : '');
    set_hv_tipDNICuentaTitul_id_propietario(props.data.data.propietario_tipoidentificaciontitular_id ? props.data.data.propietario_tipoidentificaciontitular_id : '');
    set_hv_DNICuentaTitul_propietario(props.data.data.propietario_identificacion_titular ? props.data.data.propietario_identificacion_titular : '');
    //Lista tipo documento
    AXIOS.get(API.TIPO_DOCUMENTO, {
    }).then((response) => {
      array_typeDNI(Array.isArray(response.data.data) ? response.data.data : []);
    });
    //Lista banco
    AXIOS.get(API.GET_BANCO, {
    }).then((response) => {
      array_Bank(Array.isArray(response.data) ? response.data : []);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleSubmit = (event) => {
    set_blockButton(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    AXIOS.post(API.POST_UPDATE_HV_PROPIETARIO + Number(props.data.data.persona_id), {
      "U_Placa": props.data.data.placa,
      "U_PropietarioNombre": data.get('propietario_nombre'),
      "U_PropietarioApellido": data.get('propietario_apellido'),
      "U_PropietarioCelular": data.get('propietario_celular'),
      "U_PropietarioDireccion": data.get('propietario_direccion'),
      "U_PropietarioEmail": data.get('propietario_email'),
      "U_PropietarioLugarResidencia": data.get('ciudad_pro_send'),
      "U_Banco_Pro": data.get('propietario_bankcode'),      
      "U_PropietarioIdentificacion": data.get('propietario_identificacion'),
      "U_TipoCuenta_Pro": data.get('propietario_tipo_cuenta'),
      "U_NoCuentaBancaria_Pro": data.get('propietario_cuenta'),
      "U_CuentaPropia_Pro": data.get('propietario_cuenta_propia'),
      "U_Titular_Pro": data.get('propietario_nombre_titular'),
      "U_NoIdentTitular_Pro": data.get('propietario_identificacion_titular'), 
      "U_TipoIdentTitular_Pro": Number(data.get('propietario_tipoidentificaciontitular_id')),
      "U_TipoIdentificacion_Pro": Number(data.get('propietario_tipoidentificacion_id')),
    }).then((response) => {
      setSeverity("success");
      setMessageResponse("Datos de propietario actualizados correctamente.")
      func_update('put_propietario', true);
      setOpeSnackbar(true);
      set_blockButton(false);
    }).catch((error) => {
      setSeverity("error");
      setMessageResponse("Error al actualizar los datos, intente nuevamente.");
      setOpeSnackbar(true);
      set_blockButton(false);
    });
  };

  return (
    <>
      {loading ?
        <LoadingScreen />
        : (
          <>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
                <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
                    {messageResponse}
                </Alert>
            </Snackbar>
            <Grid component={"form"} onSubmit={handleSubmit}>
              <Grid container
                spacing={0}
                justifyContent="center"
                style={{ marginTop: 5, display: "center" }}>
                <h4>DATOS BASICOS PROPIETARIO</h4>
              </Grid>
              <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_nombre" label="Nombres" defaultValue={hv_nombres_propietario} />
                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_apellido" label="Apellidos" defaultValue={hv_apellidos_propietario} />
                <FormControl variant="standard" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                  <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                  <Select
                    required
                    disabled
                    defaultValue={props.data.data.propietario_tipoidentificacion_id}
                    labelId="demo-simple-select-filled-label"
                    id="propietario_tipoidentificacion_id"
                    name="propietario_tipoidentificacion_id"
                  >
                    {
                      dataTypeDNI.map(item => {
                        return (
                          <MenuItem value={item.id}>{item.nombre}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Stack>
                <input
                  type="hidden"
                  name="propietario_identificacion"
                  value={props.data.data.propietario_identificacion}
                />
              <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                <TextField disabled type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_identificacion_disabled" label="N° Documento" defaultValue={props.data.data.propietario_identificacion} />
                <TextField
                  type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="propietario_celular" label="Celular" defaultValue={props.data.data.propietario_celular} />
                <TextField
                  inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="propietario_direccion" label="Dirección" defaultValue={props.data.data.propietario_direccion} />
                <TextField
                  inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="propietario_email" label="Correo" defaultValue={props.data.data.propietario_email} />
              </Stack>
              <Grid container
                spacing={0}
                justifyContent="center"
                style={{ marginTop: 25, display: "center" }}>
                <h4>DATOS BANCARIOS PROPIETARIO</h4>
              </Grid>
              <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                <TextField type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="propietario_cuenta" label="N° cuenta bancaria" defaultValue={props.data.data.propietario_cuenta} />
                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                  <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                  <Select                    
                    required
                    labelId="demo-simple-select-filled-label"
                    id="propietario_tipo_cuenta"
                    name="propietario_tipo_cuenta"
                    defaultValue={props.data.data.propietario_tipo_cuenta}
                  >
                    <MenuItem value={1}>AHORRO</MenuItem>
                    <MenuItem value={2}>CORRIENTE</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                  <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                  <Select
                    required                    
                    defaultValue={props.data.data.propietario_bankcode}
                    labelId="demo-simple-select-filled-label"
                    id="propietario_bankcode"
                    name="propietario_bankcode"
                  >
                    {
                      dataBank.map(item => {
                        return (
                          <MenuItem value={item.BankCode}>{item.BankName}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Stack>
              <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                  <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-filled-label"
                    id="propietario_cuenta_propia"
                    name="propietario_cuenta_propia"
                    defaultValue={hv_cuentPropia}
                    onChange={(event) => {
                      set_hv_cuentaPropia(event.target.value)
                      if (event.target.value === "SI") {
                        set_hv_titulCuenta_propietario(hv_nombres_propietario + " " + hv_apellidos_propietario);
                        set_hv_tipDNICuentaTitul_id_propietario(hv_tipoDNI_propietario);
                        set_hv_DNICuentaTitul_propietario(hv_DNI_propietario);
                      } else {
                        set_hv_titulCuenta_propietario("");
                        set_hv_tipDNICuentaTitul_id_propietario("");
                        set_hv_DNICuentaTitul_propietario("");
                      };
                    }
                  }
                  >
                    <MenuItem value={"SI"}>SI</MenuItem>
                    <MenuItem value={"NO"}>NO</MenuItem>
                  </Select>
                </FormControl>
                <TextField 
                  disabled={hv_cuentPropia === 'SI'} 
                  inputProps={{ style: { textTransform: "uppercase" } }} 
                  sx={{ marginLeft: 2, width: '100%' }} 
                  required
                  variant={hv_cuentPropia === "SI" ? "standard" : "filled"}
                  id="standard-basic" 
                  name="propietario_nombre_titular" 
                  label=" Titular Cuenta" 
                  value={hv_titulCuenta_propietario}
                  onChange={(event) => { set_hv_titulCuenta_propietario(event.target.value) }}
                 />
                <FormControl variant={hv_cuentPropia === "SI" ? "standard" : "filled"} sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                  <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular</InputLabel>
                  <Select
                    required
                    disabled={hv_cuentPropia === 'SI'}
                    value={hv_tipDNICuentaTitul_id_propietario}
                    labelId="demo-simple-select-filled-label"
                    id="propietario_tipoidentificaciontitular_id"
                    name="propietario_tipoidentificaciontitular_id"
                    onChange={(event) => { set_hv_tipDNICuentaTitul_id_propietario(event.target.value) }}
                  >
                    {
                      dataTypeDNI.map(item => {
                        return (
                          <MenuItem value={item.id}>{item.nombre}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
                <TextField 
                  disabled={hv_cuentPropia === 'SI'} 
                  type='number' 
                  sx={{ marginLeft: 2, width: '100%' }} 
                  required
                  variant={hv_cuentPropia === "SI" ? "standard" : "filled"} 
                  id="standard-basic" 
                  name="propietario_identificacion_titular"
                  label="N° identificacion titular" 
                  value={hv_DNICuentaTitul_propietario}
                  onChange={(event) => {set_hv_DNICuentaTitul_propietario(event.target.value)}} 
                />
              </Stack>
              <div style={{ marginTop: 50 }}>
                <Stack justifyContent="center" style={{ display: "center" }} direction="row"
                  divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                </Stack>
              </div>
              <Grid container
                  spacing={1}
                  justifyContent="flex-end"
                  style={{ marginTop: 30, display: "center" }}>
                  {
                      (props.permiso !== 3 || props.rolSession === 13) ? 
                          <Button variant="contained" sx={{ borderRadius: 0, background: "#3498db", padding: "12" }} disabled={blockButton} type="submit">
                              GUARDAR CAMBIOS
                          </Button>
                      : <></>
                  }   
              </Grid>
            </Grid>
          </>
        )}
    </>
  );
}