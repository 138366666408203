//Importacion de complementos
import React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import API, { AXIOS } from '../environment/api'
const COLORS = ['#EF9A53', '#E14D2A']

export const GraphDoughnut = () => {
    //Inicializacion de datos
    const [fidelizadoH, setFidelizadoH] = React.useState([]);

    React.useEffect(() => {
        //Pre-carga de apis  
        AXIOS.get(API.REPORTS, {
        }).then((response) => {
            var verficados = response.data.user_total[0].verificados;
            var registrados = response.data.user_total[0].registrados;
            var sin_verificar = (registrados - verficados);
            setFidelizadoH([
                { name: 'Verificados', total: verficados },
                { name: 'Sin Verificar', total: sin_verificar }
            ])
        });
    }, []);

    return (
        <div style={{ width: '70%', marginTop: 20, backgroundColor: 'white', marginRight: 10 }}>
            {/* Vista de graficos */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 40 }}>
                <span><b>Vehiculos</b></span>
            </div>
            <ResponsiveContainer width='100%' aspect={1.2}>
                <PieChart>
                    <Pie
                        dataKey='total'
                        data={fidelizadoH}
                        innerRadius={10}
                        width={500}
                        height={500}
                    >
                        {fidelizadoH.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="top" />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
};