import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import logo from '../assets/img/logo.png'; 

export default function LoadingScreen() {
  return (
    <div style={{marginTop:"20%",}}>
      <Grid
        container
        justify='center'
        direction='column'
        alignItems='center'
        style={{minHeight: '100vh'}}>
        <div>
          <Grid item xs={3}>
            <CircularProgress  color="secondary"/>  
          </Grid>
        </div>
          <img  style={{width:20, marginTop:-33}} src={logo} alt="logo" />
          <h4 style={{fontFamily:"arial"}}>Cargando</h4>
      </Grid>
    </div>
  );
}