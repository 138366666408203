import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dashboard from '../components/Dashboard';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import API, { AXIOS } from '../environment/api';
import Button from '@mui/material/Button';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import WindowModal from '../components/WindowModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CurriTenedor() {
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState('');
  const [data, setData] = React.useState("");
  const [dataDocumento, setDocumento] = React.useState([]);
  const [dataCategoria, setCategoria] = React.useState([]);
  const [dataBanco, setBanco] = React.useState([]);
  const [dataEPS, setEPS] = React.useState([]);
  const [dataARL, setARL] = React.useState([]);
  const [dataAFP, setAFP] = React.useState([]);
  const [dataCity, setCity] = React.useState([]);
  const [tecnomecanica, setTecnomecanica] = React.useState('')
  const [soat, setSoat] = React.useState('')
  const [tpFontal, setTPFontal] = React.useState('')
  const [tpTrasera, setTPTrasera] = React.useState('')
  const [Loading, setLoading] = React.useState(true);
  const [hv_categLicencia_id, set_hv_categLicencia_id] = React.useState('');

  const handleChange_N = (event) => {
    setAge(event.target.value);
  };
  const subirImagen = (event) => {
    console.log("prueba2", event.target.value);
    console.log("prueba co");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    event.preventDefault();
    console.log("video", event.preventDefault)
  };
  // http://3.235.190.220/back/public/api/hoja_de_vida/personal/get/91521782

  React.useEffect(() => {
    AXIOS.get(API.GET_HOJAVIDA + localStorage.getItem("dni_hojaVida"), {
    }).then((response) => {
      setData(response.data.data);
      set_hv_categLicencia_id(response.data.data.licencia);
      setLoading(false);
    });
    const formData = new FormData();
    formData.append('type', 1);
    axios({
      method: "get",
      url: API.GET_HOJAVIDA_DOCUM + localStorage.getItem("dni_hojaVida"),
      // data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`, "Content-Type": "multipart/formData"
      }
    }).then((response) => {
      // setTimePhoto(response.data.documents);  
    });

    AXIOS.get(API.CITY, {
      search: "SOLEDAD"
    }).then((response) => {
      setCity(response.data);
    }).catch(function (error) {
      setCity([]);
    });

    AXIOS.get(API.TIPO_DOCUMENTO, {
    }).then((response) => {
      setDocumento(response.data.data);
    });

    AXIOS.get(API.CATEGORIA_LICENCIA, {
    }).then((response) => {
      setCategoria(response.data.data);
    });

    AXIOS.get(API.BANCO, {
    }).then((response) => {
      setBanco(response.data.data);
    });

    AXIOS.get(API.EPS, {
    }).then((response) => {
      setEPS(response.data.data);
    });

    AXIOS.get(API.ARL, {
    }).then((response) => {
      setARL(response.data.data);
    });

    AXIOS.get(API.AFP, {
    }).then((response) => {
      setAFP(response.data.data);
    });
  }, []);

  console.log("cartamna", data.nombres)

  const CityChange = (event) => {
    // setCity(event.target.value);  
  };
  const documentoChange = (event) => {
    // setCity(event.target.value);  
  };
  const categoriaChange = (event) => {
    set_hv_categLicencia_id(event.target.value);
  };
  const bancoChange = (event) => {
    // setCity(event.target.value);  
  };
  const epsChange = (event) => {
    // setCity(event.target.value);  
  };
  const arlChange = (event) => {
    // setCity(event.target.value);  
  };
  const afpChange = (event) => {
    // setCity(event.target.value);  
  };

  const saveImage = (image, id, setImagen) => {
    switch (id) {
      case '1':
        setSoat(image)
        break;
      case '2':
        setTecnomecanica(image)
        // debugger
        break;
      case '3':
        setTPFontal(image)
        break;
      case '4':
        setTPTrasera(image)
        break;
      default:
        break;
    }

  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  const submitEditProvider = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log("comprar", data)
  };
  return (
    <>
      {(Loading === true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard>
          <Button style={{ background: "white", marginBlockEnd: 20 }} onClick={() => linkTo('/owners')}><ArrowBackIcon /> ATRAS</Button>
          <br />
          <Paper>
            <div style={{ display: "flex" }}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 80, height: 80, margin: 4 }}
              />
              <div style={{ marginTop: 20 }}>
                <h2>{data.nombres + " "}{data.apellidos}</h2>
                <div style={{ marginTop: -28 }}>
                  <Typography style={{ color: "gray" }}><b>{data.identificacion}</b></Typography>
                </div>
                <div style={{ marginTop: 0 }}>
                  {(data.verificado == 0) ?
                    <Typography style={{ background: "gray", width: 120, textAlign: "center", color: "white" }}><b>No Verificado</b></Typography>
                    :
                    <Typography style={{ background: "green", width: 90, textAlign: "center", color: "white" }}><b>Verificado</b></Typography>
                  }
                </div>
              </div>
            </div>
            <div style={{ float: "right", marginTop: -40, marginRight: 40 }}>
              <Button type="submit" variant="contained" fullWidth sx={{ background: "green", color: "white", borderRadius: 0 }}>ACTUALIZAR</Button>
            </div>
            <Box sx={{ width: '100%' }} component="form" onSubmit={submitEditProvider}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="DATOS PERSONALES" {...a11yProps(0)} />
                  <Tab label="DOCM. PERSONALES" {...a11yProps(1)} />
                  <Tab label="VEHICULO" {...a11yProps(2)} />
                  <Tab label="DOCM. VEHICULO" {...a11yProps(3)} />
                  <Tab label="TENEDOR" {...a11yProps(4)} />
                  <Tab label="DOCM. TENEDOR" {...a11yProps(5)} />
                  <Tab label="PROPIETARIO" {...a11yProps(6)} />
                  <Tab label="DOCM. PROPIETARIO" {...a11yProps(7)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 5, display: "center" }}>
                  <h4>DATOS BASICOS</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="nombres" label="Nombres" defaultValue={data.nombres} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="apellidos" label="Apellidos" defaultValue={data.apellidos} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="fecha_expedicion_documento" type="date" focused defaultValue={data.fecha_expedicion_documento} label="Fecha Expedición Indentificación" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                    <Select
                      required
                      defaultValue={data.tipoidentificacion_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tipoidentificacion_id"
                      //  label="Profesión"
                      onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="identificacion" label="N° Documento" defaultValue={data.identificacion} />
                  <Autocomplete
                    disablePortal
                    required
                    id="combo-box-demo"
                    name="lugar_expedicion_documento"
                    options={dataCity}
                    defaultValue={data.lugar_expedicion_documento}
                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                    renderInput={(params) => <TextField {...params} label="Lugar expedición identificación" />}
                  />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="direccion" label="Dirección" defaultValue={data.direccion} />
                  <Autocomplete
                    disablePortal
                    required
                    id="combo-box-demo"
                    name="lugar_expedicion_documento"
                    options={dataCity}
                    defaultValue={data.lugar_expedicion_documento}
                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                    renderInput={(params) => <TextField {...params} label="Ciudad" />} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="Celular" label="Celular" defaultValue={data.celular} />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="email" label="Correo" defaultValue={data.email} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required focused type="date" variant="standard" id="standard-basic" name="fecha_nacimiento" label="Fecha nacimiento" defaultValue={data.fecha_nacimiento} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo Conductor</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tipoconductor_id"
                      // value={age}
                      defaultValue={data.tipoconductor_id}
                    // onChange={handleChange_N}
                    >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                      <MenuItem value={1}>INTERNO</MenuItem>
                      <MenuItem value={2}>EXTERNO</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="licencianumero" label="Licencia conducción" defaultValue={data.licencianumero} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Categoria licencia</InputLabel>
                    <Select
                      required
                      defaultValue={data.licencia_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="licencia_id"
                      //  label="Profesión"
                      onChange={categoriaChange}
                    >
                      {
                        dataCategoria && dataCategoria.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.licencia}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required type="date" variant="standard" id="standard-basic" focused name="licenciavence" label="Fecha vencimiento linc." defaultValue={data.licenciavence} />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required type="date" variant="standard" id="standard-basic" name="fecha_creacion" focused label="Fecha creación de usuario" defaultValue={"YYYY-MM-DD"} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required type="date" variant="standard" id="standard-basic" name="fecha_actualizacion" focused label="Fecha actualización usuario" defaultValue={data.fecha_actualizacion} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="usuario_creacion" label="Usuario creación" defaultValue={data.usuario_creacion} />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="usuario_actualizacion" label="Usuario actualizacion" defaultValue={data.usuario_actualizacion} />
                  <Autocomplete
                    required
                    disablePortal
                    id="combo-box-demo"
                    defaultValue={data.empresasatelital}
                    name="empresasatelital"
                    options={top100Films}
                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                    renderInput={(params) => <TextField {...params} label="Empresa GPS" />}
                  />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="id_gps" label="ID usuario GPS" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="usuario_gps" label="Usuario" />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="contraseña_gps" label="Contraseña" />
                </Stack>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 25, display: "center" }}>
                  <h4>EPS & ARL & AFP</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">EPS</InputLabel>
                    <Select
                      required
                      defaultValue={data.eps_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="eps_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataEPS.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.empresa}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required focused type="date" variant="standard" id="standard-basic" name="fecha_afiliacion" label="Fecha afiliación EPS" defaultValue={data.fecha_afiliacion} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">ARL</InputLabel>
                    <Select
                      required
                      defaultValue={data.arl_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="arl_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataARL.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.empresa}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">AFP</InputLabel>
                    <Select
                      required
                      defaultValue={data.afp_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="afp_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataAFP.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required focused type="date" variant="standard" id="standard-basic" name="fecha_pago" label="Fecha ultimo pago seguridad social" defaultValue={data.fecha_pago} />
                </Stack>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 25, display: "center" }}>
                  <h4>DATOS BANCARIOS</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="cuenta" label="N° cuenta bancaria" defaultValue={data.cuenta} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tipo_cuenta"
                      // value={age}
                      defaultValue={data.tipo_cuenta}
                    // onChange={handleChange_N}
                    >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                      <MenuItem value={1}>CORRIENTE</MenuItem>
                      <MenuItem value={2}>AHORRO</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                    <Select
                      required
                      defaultValue={data.bankcode}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="bankcode"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataBanco.map(item => {
                          return (
                            <MenuItem value={item.bankcode}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="cuenta_propia"
                      // value={age}
                      defaultValue={data.cuenta_propia}
                      onChange={handleChange_N}
                    >
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"NO"}>NO</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="nombre_titular" label="Titular Cuenta" defaultValue={data.nombre_titular} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular</InputLabel>
                    <Select
                      required
                      defaultValue={data.tipoidentificaciontitular_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tipoidentificaciontitular_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '33%' }} required variant="standard" id="standard-basic" name="identificacion_titular" label="N° indetificación titular" defaultValue={data.identificacion_titular} />
                </Stack>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 25, display: "center" }}>
                  <h4>REFERENCIAS</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="referencia_nombres" label="Nombres" defaultValue={data.referencia_nombres} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="referencia_apellidos" label="Apellidos" defaultValue={data.referencia_apellidos} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="referencia_celular" label="Celular" defaultValue={data.referencia_celular} />
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ display: "flex" }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CONDUCTOR' save={saveImage} image={soat} id='10' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='LICENCIA FRONTAL' save={saveImage} image={soat} id='11' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='LICENCIA TRASERA' save={saveImage} image={soat} id='12' />
                  </Stack>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CEDULA FRONTAL' save={saveImage} image={soat} id='13' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CEDULA TRASERA' save={saveImage} image={soat} id='14' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='SEGURIDAD SOCIAL' save={saveImage} image={soat} id='15' />
                  </Stack>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CERTIFICACION BANCARIA' save={saveImage} image={soat} id='16' />
                  </Stack>
                </div>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div style={{ display: "flex" }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CEDULA O CAMARA COMERCIO' save={saveImage} image={soat} id='20' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CERTIFICACION BANCARIA' save={saveImage} image={soat} id='21' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='RUT' save={saveImage} image={soat} id='22' />
                  </Stack>
                </div>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <div style={{ display: "flex" }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CEDULA O CAMARA COMERCIO' save={saveImage} image={soat} id='30' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='CERTIFICACION BANCARIA' save={saveImage} image={soat} id='31' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='RUT' save={saveImage} image={soat} id='32' />
                  </Stack>
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 5, display: "center" }}>
                  <h4>DATOS BASICOS TENEDOR</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_nombre" label="Nombres" defaultValue={data.tenedor_nombre} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_apellido" label="Apellidos" defaultValue={data.tenedor_apellido} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                    <Select
                      required
                      defaultValue={data.tenedor_tipoidentificacion_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tenedor_tipoidentificacion_id"
                      //  label="Profesión"
                      onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_identificacion" label="N° Documento" defaultValue={data.tenedor_identificacion} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_celular" label="Celular" defaultValue={data.tenedor_celular} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_direccion" label="Dirección" defaultValue={data.tenedor_direccion} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_email" label="Correo" defaultValue={data.tenedor_email} />
                </Stack>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 25, display: "center" }}>
                  <h4>DATOS BANCARIOS TENEDOR</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_cuenta" label="N° cuenta bancaria" defaultValue={data.tenedor_cuenta} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tenedor_tipo_cuenta"
                      // value={age}
                      defaultValue={data.tenedor_tipo_cuenta}
                    // onChange={handleChange_N}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      <MenuItem value={1}>CORRIENTE</MenuItem>
                      <MenuItem value={2}>AHORRO</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                    <Select
                      required
                      defaultValue={data.tenedor_bankcode}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tenedor_bankcode"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataBanco.map(item => {
                          return (
                            <MenuItem value={item.bankcode}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tenedor_cuenta_propia"
                      // value={age}
                      defaultValue={data.tenedor_cuenta_propia}
                      onChange={handleChange_N}
                    >
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"NO"}>NO</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_nombre_titular" label=" Titular Cuenta" defaultValue={data.tenedor_nombre_titular} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular</InputLabel>
                    <Select
                      required
                      defaultValue={data.tenedor_tipoidentificaciontitular_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="tenedor_tipoidentificaciontitular_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_identificacion_titular" label="N° identificacion titular" defaultValue={data.tenedor_identificacion_titular} />
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 5, display: "center" }}>
                  <h4>DATOS BASICOS PROPIETARIO</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_nombre" label="Nombres" defaultValue={data.propietario_nombre} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_apellido" label="Apellidos" defaultValue={data.propietario_apellido} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                    <Select
                      required
                      defaultValue={data.propietario_tipoidentificacion_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="propietario_tipoidentificacion_id"
                      //  label="Profesión"
                      onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_identificacion" label="N° Documento" defaultValue={data.propietario_identificacion} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_celular" label="Celular" defaultValue={data.propietario_celular} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_direccion" label="Dirección" defaultValue={data.propietario_direccion} />
                  <TextField
                    sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_email" label="Correo" defaultValue={data.propietario_email} />
                </Stack>
                <Grid container
                  spacing={0}
                  //  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 25, display: "center" }}>
                  <h4>DATOS BANCARIOS PROPIETARIO</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_cuenta" label="N° cuenta bancaria" defaultValue={data.propietario_cuenta} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="propietario_tipo_cuenta"
                      // value={age}
                      defaultValue={data.propietario_tipo_cuenta}
                    // onChange={handleChange_N}
                    >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                      <MenuItem value={1}>CORRIENTE</MenuItem>
                      <MenuItem value={2}>AHORRO</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                    <Select
                      required
                      defaultValue={data.propietario_bankcode}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="propietario_bankcode"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataBanco.map(item => {
                          return (
                            <MenuItem value={item.bankcode}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="propietario_cuenta_propia"
                      // value={age}
                      defaultValue={data.propietario_cuenta_propia}
                      onChange={handleChange_N}
                    >
                      <MenuItem value={"SI"}>SI</MenuItem>
                      <MenuItem value={"NO"}>NO</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="tenedor_nombre_titular" label=" Titular Cuenta" defaultValue={data.tenedor_nombre_titular} />
                  <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                    <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular</InputLabel>
                    <Select
                      required
                      defaultValue={data.propietario_tipoidentificaciontitular_id}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="propietario_tipoidentificaciontitular_id"
                    //  label="Profesión"
                    //  onChange={documentoChange}
                    >
                      {
                        dataDocumento.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nombre}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="propietario_identificacion_titular" label="N° identificacion titular" defaultValue={data.propietario_identificacion_titular} />
                </Stack>
                <div style={{ marginTop: 50 }}>
                  <Stack justifyContent="center" style={{ display: "center" }} direction="row"
                    divider={<Divider orientation="vertical" flexItem />} spacing={6}>

                  </Stack>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container spacing={0} justifyContent="center" style={{ marginTop: 5, display: "center" }}>
                  <h4>VEHICULO</h4>
                </Grid>
                <Stack justifyContent="space-between" style={{ display: "center", }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="numero_chasis" label="Número de chasís" variant="standard" defaultValue={data.numero_chasis} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="cantidad_ejes" label="Cantidad de ejes" variant="standard" defaultValue={data.cantidad_ejes} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Vehiculo" name="tipovehiculo" defaultValue={data.tipovehiculo} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Configuración" name="tipoconfiguracion_nombre" defaultValue={data.tipoconfiguracion_nombre} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Carga" name="tipocarga" defaultValue={data.tipocarga} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="modelo" label="Modelo" variant="standard" defaultValue={data.modelo} />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="numero_motor" label="Número de motor" variant="standard" defaultValue={data.numero_motor} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="placa" label="Placa" variant="standard" defaultValue={data.placa} />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Flota" name="tipoflota" defaultValue={data.tipoflota} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} defaultValue={data.marcacabezote} name="marcacabezote" required id="standard-required" label="Marca de cabezote" variant="standard" />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} defaultValue={data.lineacabezote} name="lineacabezote" required id="standard-required" label="Linea de cabezote" variant="standard" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Combustible" name="tipocombustible" defaultValue={data.tipocombustible} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="color" label="Color" defaultValue={data.color} variant="standard" />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Carriceria" name="carroceria" defaultValue={data.carroceria} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="peso_vacio" defaultValue={data.peso_vacio} label="Peso vacio" variant="standard" />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="peso_capacidad_carga" defaultValue={data.peso_capacidad_carga} label="Capacidad de carga" variant="standard" />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Unidada de Peso" name="unidad_capacidad" defaultValue={data.unidad_capacidad} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo de Carriceria" name="carroceria" defaultValue={data.carroceria} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Unidad de Empaque" name="unidad_empaque" defaultValue={data.unidad_empaque} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="trailer_asociado" defaultValue={data.trailer_asociado} label="Trailer asociado" variant="standard" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" label="Inicio Tecnicomecanica" defaultValue="1" focused />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" label="Fin Tecnicomecanica" defaultValue="Fin Tecnicomecanica" focused />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" defaultValue={data.numero_soat} name="numero_soat" label="Número de SOAT" variant="standard" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Compañia de SOAT" name="empresa_soat" defaultValue={data.empresa_soat} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" name="fechainicio_soat" defaultValue={data.fechainicio_soat} label="Fecha de inicio del SOAT" focused />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" name="fechafin_soat" defaultValue={data.fechafin_soat} label="Fecha de fin SOAT" focused />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo Carga Administrativa" name="carga_administrativa" defaultValue={data.carga_administrativa} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Tipo Conductor Vehiculo" name="conductor_vehiculo_tipo" defaultValue={data.conductor_vehiculo_tipo} variant="standard" >
                    {/* <option key={1} value={1}> Tipo de Vehículo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="no_poliza" defaultValue={data.no_poliza} label="Número de poliza RCE" variant="standard" />
                </Stack>
                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required label="Compañia de poliza" name="poliza_empresa" defaultValue={data.poliza_empresa} variant="standard" >
                    {/* <option key={1} value={1}> Tipo conductor de vehiculo </option> */}
                  </TextField>
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="poliza_fecha_inicio" type="date" label="Fecha de inicio" defaultValue={data.poliza_fecha_inicio} focused />
                  <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="poliza_fecha_fin" type="date" label="Fecha de fin" defaultValue={data.poliza_fecha_fin} focused />
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div style={{ display: "flex" }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='SOAT' save={saveImage} image={soat} id='1' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='TECNOMECANICA' save={saveImage} image={soat} id='2' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='TARJETA PROPIEDAD FRONTAL' save={saveImage} image={soat} id='3' />
                  </Stack>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='TARJETA PROPIEDAD TRASERA' save={saveImage} image={soat} id='4' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='AUTORIZACION TENEDOR' save={saveImage} image={soat} id='5' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='VEHICULO FRONTAL' save={saveImage} image={soat} id='6' />
                  </Stack>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='TARJETA TRAILER FRONTAL' save={saveImage} image={soat} id='7' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='TARJETA TRAILER TRASERA' save={saveImage} image={soat} id='8' />
                  </Stack>
                  <Stack justifyContent="center" style={{ display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <WindowModal title='POLIZA' save={saveImage} image={soat} id='9' />
                  </Stack>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
              </TabPanel>
            </Box>
          </Paper>
        </Dashboard>
      }
    </>
  );
}

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];


const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
];