//Importacion de complementos
import React, { useContext } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../context/AuthContext';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/img/logo.png';

const theme = createTheme();

export default function SignIn() {

  //Funcion para inicializar los datos
  const { signIn } = useContext(AuthContext)
  
  //Funcion para loguearse
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signIn(data.get('email'), data.get('password'));
  };

  return (
    <div style={{ backgroundColor: "#e5e5e5", overflow: 'auto', height: '100vh', }}>
      <ThemeProvider theme={theme} >
        <Container component="main" maxWidth="xs"   >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 9,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: "white",
              height: "auto",
            }}>
            <img style={{ width: 100, marginTop: 20 }} src={logo} alt="logo" />
            {/* Formulario de login */}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, marginLeft: 2, marginRight: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Cédula"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recuerdame"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, marginHorizontal: 12, }}
              >
                Entrar
              </Button>
              <Grid container style={{ margin: 12 }}>
                <Grid item xs>
                  <Link href="#/app/help" variant="body2" style={{ justifyContent: 'flex-end' }}>
                    ¿Necesitas ayuda?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
}