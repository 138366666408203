//Importacion de complementos
import * as React from 'react';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Dashboard from '../components/Dashboard';
import { Grid, TextField } from '@mui/material';
import API, { AXIOS } from '../environment/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingModal from "../components/LoadingModal"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingScreen from './LoadingScreen';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PqrsPages() {
  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [totalPage, setTotalPage] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('');
  const [setT, dateT] = React.useState(0);

  //Funcion para elegir el tipo de busqueda en el filtro  
  const handleChangeSearchType = (event) => {
    setSearchType(event.target.value);

    if (event.target.value === 1) {
      dateT(1);
      setSearchTypes("");
      setFilter("");
    };
    if (event.target.value === 2) {
      dateT(0);
      setSearchTypes("Petición");
      setFilter("");
      setPage(0);
      setRowsPerPage(10);

    };
    if (event.target.value === 3) {
      setSearchTypes("Queja");
      dateT(0);
      setPage(0);
      setRowsPerPage(10);

    };
    if (event.target.value === 4) {
      setSearchTypes("Reclamo");
      dateT(0);
      setFilter("");
      setPage(0);
      setRowsPerPage(10);

    };
    if (event.target.value === 5) {
      setSearchTypes("Solicitud");
      dateT(0);
      setFilter("");
      setPage(0);
      setRowsPerPage(10);

    };
  };

  //Funcion para abir notificacion de exito
  const handleClick_success = () => {
    setOpenSuccess(true);
  };
  //Funcion para cerrar notificacion de exito
  const handleClose_success = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para cerrar modal de carga  
  const closeLoad = () => setLoad(false);

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    if (setT === 1) {
      AXIOS.get(API.GET_PQRS + `?page=` + (1 + newPage) + `&per_page=` + rowsPerPage, {
      }).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.total);
      });
    } else {
      AXIOS.get(API.GET_PQRS + `?page=` + (1 + newPage) + `&per_page=` + rowsPerPage + `&tipo_pqrs=` + searchTypes + `&search=` + filter, {
      }).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.total);
      });
    }
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    if (setT === 1) {
      AXIOS.get(API.GET_PQRS + `?page=` + (1 + page) + `&per_page=` + (+event.target.value), {
      }).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.total);
      });
    } else {
      AXIOS.get(API.GET_PQRS + `?page=` + (1 + page) + `&per_page=` + (+event.target.value) + `&tipo_pqrs=` + searchTypes + `&search=` + filter, {
      }).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.total);
      });
    }
    setPage(page);
  };

  //Funcion para almacenar data del input de busqueda
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    setPage(0);
    setRowsPerPage(10);
    if (event.target.value === "") {
      refresh();
    } else {
      if (setT === 1) {
        AXIOS.get(API.GET_PQRS + `?page=1&per_page=10&search=` + event.target.value, {
        }).then((response) => {
          setLoading(false);
          setcompanies(response.data.data);
          setTotalPage(response.data.total);
        });
      } else {
        AXIOS.get(API.GET_PQRS + `?page=1&per_page=10&tipo_pqrs=` + searchTypes + `&search=` + event.target.value, {
        }).then((response) => {
          setLoading(false);
          setcompanies(response.data.data);
          setTotalPage(response.data.total);
        });
      }
    }
  };

  React.useEffect(() => {
    //Pre-carga de apis   
    AXIOS.get(API.GET_PQRS + "?page=1&per_page=10", {
    }).then((response) => {
      setLoading(false);
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    });
  }, []);

  //Funcion para refrescar tabla  
  const refresh = () => {
    openLoad();
    AXIOS.get(API.GET_PQRS + "?page=1&per_page=10", {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    }).catch((error) => {

      console.log(error);
    });

  };
  //Funcion para cambiar estado de PQRS
  const update = (row) => {
    openLoad();
    AXIOS.put(API.GET_PQRS + "/" + row.id, {
      "estado": 1
    }).then((response) => {
      closeLoad();
      refresh();
      handleClick_success();
    });
  };

  //Inicializando datos que trae la api de get
  function createData(titulo, usuario, tipo_pqrs, numid, empresa, transportista, created_at, estado, descripcion, celular, id) {
    return { titulo, usuario, tipo_pqrs, numid, empresa, transportista, created_at, estado, descripcion, celular, id };
  }
  //Seleccion de datos que se mostraran en la tabla  
  const rows = companies.map(item => {
    return (
      createData(item.titulo, item.usuario, item.tipo_pqrs, item.numid, item.empresa, item.transportista, item.created_at, item.estado, item.descripcion, item.celular, item.id)
    )
  });

  return (<>
    {(Loading === true) ?
      (Loading === "") ? null :
        <LoadingScreen /> :
      <Dashboard title='PQRS'>
        {/* Modal alerta */}
        <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleClose_success}>
          <Alert onClose={handleClose_success} severity="success" sx={{ width: '100%' }}>
            PQRS atendida con exito
          </Alert>
        </Snackbar>
        {/* Modal carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        <Grid sx={{ background: "white", p: 1 }}>
          <div style={{ float: 'center' }}>
            <div style={{ display: "flex", float: "left", marginTop: 10, marginRight: 10 }}>
              <Button size="small" varaint="outlined" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button>
            </div>
            <div style={{ display: "flex", float: "left", marginTop: 7, marginRight: 10 }}>
              {/* Select para el tipo de busqueda   */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={1}
                  value={searchType}
                  label="Tipo de busqueda"
                  onChange={handleChangeSearchType}
                >
                  <MenuItem value={1}>Ver todos</MenuItem>
                  <MenuItem value={2}>Peticiones</MenuItem>
                  <MenuItem value={3}>Quejas</MenuItem>
                  <MenuItem value={4}>Reclamos</MenuItem>
                  <MenuItem value={5}>Solicitudes</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* Input de busqueda */}
            <div style={{ display: "flex", marginTop: -1 }}>
              <TextField fullWidth style={{ float: 'center', background: "white" }} id="standard-basic" label="Buscar (Titulo & Nombre remitente)" variant="standard" name="filtro" onChange={handleChangeSearch} />
            </div>
          </div>
          {/* <div style={{marginTop:1, color:'white'}}>s</div> */}
        </Grid>
        <Grid style={{ marginTop: 55 }}>
          {rows.map((row, id) => (
            // Acordeon de contenido
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ display: "flex", float: "left" }}>
                  <Avatar alt={row.usuario} src={row.usuario} />
                  <div style={{ marginTop: 10, marginLeft: 10, display: "flex" }}>
                    <Typography><b>{row.tipo_pqrs}</b></Typography>
                    <Typography style={{ marginLeft: 5 }}>{row.titulo}</Typography>
                  </div>
                </div>
                <div style={{ marginTop: 7, display: "flex", position: 'absolute', right: 250 }}>
                  {<>{(row.estado === 0) ?
                    <Button size="small" style={{ background: "orange", color: "white" }}>Pendiente</Button>

                    :
                    <Button size="small" style={{ background: "green", color: "white" }}>Atendido</Button>
                  }</>}
                  <Typography style={{ marginTop: -9, position: 'absolute', right: 110 }}>{row.created_at}</Typography>
                </div>
                <div style={{ marginTop: 7, position: 'absolute', right: 48 }}>
                  <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => update(row)}>Marcar como Atendido</Button>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <Typography><b>Nombre remitente:</b></Typography>
                  <Typography style={{ marginLeft: 5 }}>{row.usuario}</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography><b>Contacto remitente:</b></Typography>
                  <Typography style={{ marginLeft: 5 }}>-</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography><b>Cedula:</b></Typography>
                  <Typography style={{ marginLeft: 5 }}>{row.numid}</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography><b>Placa:</b></Typography>
                  <Typography style={{ marginLeft: 5 }}>-</Typography>
                </div>
                <div style={{ display: "flex", marginTop: 8 }}>
                  <Typography>{row.descripcion}</Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        {<>{(rows === "") ?
          <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
            <Typography><b>No se ha recibido ningún PQRS hasta el momento</b></Typography>
          </Stack> :
          ""}</>}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Dashboard>
    }</>);
};