//Importacion de complementos
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dashboard from '../components/Dashboard';
import { Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API, { AXIOS } from '../environment/api';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ShowTableColumn } from '../components/ShowTableColumn';
import { ButtonShowColumnTable } from '../components/ButtonShowColumnTable';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import LoadingScreen from './LoadingScreen';
import IosShareIcon from '@mui/icons-material/IosShare';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { CopyDataToClipboard } from "../helpers/CopyTableToClipboard";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MostFrequentRoutes() {
  //Funcion para obtener fecha actual
  var today = new Date();
  var day = today.getDate();
  var months = (1 + today.getMonth());
  if (months < 10) {
    var month = ("0" + months);
  } else {
    var month = months;
  }
  var year = today.getFullYear();
  //Funcion para inicializar los datos
  const [query, setQuery] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [dateBegins, setDateBegin] = React.useState("2019-03-01");
  const [dateEnds, setDateEnd] = React.useState(year + "-" + month + "-" + day);
  const [checked, setChecked] = React.useState(['Empresa', 'Publicados', 'Origen', 'Destino', 'Tipo de vehiculo', 'Carroceria']);
  const [columChecked, setColumChecked] = React.useState(['Empresa', 'Publicados', 'Origen', 'Destino', 'Tipo de vehiculo', 'Carroceria']);
  const componentRef = React.useRef()
  const [load, setLoad] = React.useState(false);
  const [dataMessage, setMessage] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('origen');

  //Funcion para obtener tipo de busqueda
  const handleChangeSearchType = (event) => {
    setFilter('');
    setSearchType(event.target.value);
    setcompanies(query);
    if (event.target.value == 1) {
      setSearchTypes("origen");
    };
    if (event.target.value == 2) {
      setSearchTypes("destino");
    };
    if (event.target.value == 3) {
      setSearchTypes("tipo vehiculo");
    };
    if (event.target.value == 4) {
      setSearchTypes("carroceria");
    };
  };
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para cerrar modal de carga 
  const closeLoad = () => setLoad(false);
  //Funcion para cerrar notificacion de exito
  const handleClose_success = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  //Funcion para copiar datos de tabla
  const handleClickCopyToClipboard = (checked, array, attributes) => {
    CopyDataToClipboard(checked, array, attributes, () => {
      setMessage("Tabla copiada");
      setOpenSuccess(true);
      setTimeout(() => {
        setOpenSuccess(false);
      }, 2000)
    });
  };
  //Funcion para filtrar por fecha de inicio
  const dateBegin = (event) => {
    openLoad();
    setDateBegin(event.target.value);
    AXIOS.get(API.ROUTES_FREQUENT + "begin=" + (event.target.value) + "&end=" + dateEnds + "&id=154", {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data);
      setQuery(response.data);
    });
  };
  //Funcion para filtrar por fecha fin
  const dateEnd = (event) => {
    openLoad();
    setDateEnd(event.target.value);
    AXIOS.get(API.ROUTES_FREQUENT + "begin=" + dateBegins + "&end=" + (event.target.value) + "&id=154", {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data);
      setQuery(response.data);
    });
  };
  //Funcion para refrescar pantalla
  const refresh = (event) => {
    openLoad();
    AXIOS.get(API.ROUTES_FREQUENT + "begin=2023-04-01&end=" + year + "-" + month + "-" + day + "&id=154", {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data);
      setQuery(response.data);
    });
  };

  React.useEffect(() => {
    //Pre-carga de apis
    AXIOS.get(API.ROUTES_FREQUENT + "begin=2023-04-01&end=" + year + "-" + month + "-" + day + "&id=154", {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data);
      setQuery(response.data);
    });
  }, []);
  //Funcion para capturar input de filtro
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };
  //Inicializando datos que trae la api de get
  function createData(empresa, origen, destino, departamento_origen, departamento_destino,
    tipovehiculo, carroceria, servicios) {
    return {
      empresa, origen, destino, departamento_origen, departamento_destino,
      tipovehiculo, carroceria, servicios
    };
  }
  //Seleccion de datos que se mostraran en la tabla  
  const rows = companies.map(item => {
    return (
      createData(item.empresa, item.origen, item.destino, item.departamento_origen, item.departamento_destino,
        item.tipovehiculo, item.carroceria, item.servicios)
    )
  });
  //Funcion para imprimir PDF
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Ruta más frecuentes',
    onAfterPrint: () => alert('Print success')
  })

  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> : <>
        {/* Notificacion de exito */}
        <Snackbar
          open={openSuccess}
          autoHideDuration={8000}
          onClose={handleClose_success}
        >
          <Alert
            onClose={handleClose_success}
            severity="success"
            sx={{ width: "100%" }}
          >
            {dataMessage}
          </Alert>
        </Snackbar>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        <Dashboard title='Cantidad de publicaciones por ciudad de origen y destino'>
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          <Paper sx={{ width: '100%', overflow: 'hidden' }} >
            <Grid size="small" style={{ margin: 10, float: 'left', display: "flex" }}>
              {/* Botonera de checked */}
              <ButtonShowColumnTable size="small" checked={checked} setChecked={setColumChecked} />
              <div style={{ marginLeft: 10 }}>
                <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={() =>
                    handleClickCopyToClipboard(checked, rows, [
                      "empresa",
                      "servicios",
                      "departamento_origen",
                      "departamento_destino",
                      "tipovehiculo",
                      "carroceria",
                    ])
                  }
                >
                  Copiar
                </Button>
              </div>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {/* Boton impirmir PDF */}
                <Button variant="outlined" onClick={handlePrint} startIcon={<PrintIcon />}>Imprimir</Button>
              </div>
              {/* Boton exportar EXCEl */}
              <ReactHTMLTableToExcel
                // id="test-table-xls-button"
                // className="download-table-xls-button"
                className='export-btn'
                table="publicaciones"
                filename="Ruta más frecuentes"
                sheet="tablexls"
                buttonText={<div style={{ display: "flex" }}><IosShareIcon /><div style={{ marginTop: 4, marginLeft: 6 }}>EXPORTAR EXCEL</div></div>} />
            </Grid>
            <Grid size="small" style={{ margin: 10, float: 'right', display: "flex" }}>
              <div>
                {/* Boton filtro de fecha inicio           */}
                <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                  fullWidth id="upload_date" label="Desde" autoFocus
                  onChange={dateBegin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                {/* Boton filtro de fecha fin  */}
                <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                  fullWidth id="upload_date" label="Hasta" autoFocus
                  onChange={dateEnd}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
          </Paper>
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
            <div style={{ display: "flex", float: "right", margin: 15 }}>
              {/* Input de busqueda         */}
              <TextField inputProps={{ style: { textTransform: 'lowercase' } }} id="filled-basic" label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} defaultValue={filter} startIcon={<SearchIcon />} />
            </div>
            <div style={{ float: "right", marginTop: 28, marginRight: -5 }}>
              {/* Filtro de tipo busuqeda */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                <Select
                  sx={{ minWidth: 160 }}
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={1}
                  value={searchType}
                  label="Tipo de busqueda"
                  onChange={handleChangeSearchType}
                >
                  <MenuItem value={1}>Origen</MenuItem>
                  <MenuItem value={2}>Destino</MenuItem>
                  <MenuItem value={3}>Tipo de vehiculo</MenuItem>
                  <MenuItem value={4}>Carroceria</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* Boton de refrescar */}
            <div style={{ float: "right", marginTop: 28, marginRight: 10 }}>
              <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
              {/* Tabla de contenido */}
              <Table stickyHeader aria-label="sticky table" id="publicaciones" ref={componentRef}>
                <TableHead>
                  <TableRow>
                    <ShowTableColumn checked={columChecked}>
                      <TableCell align="center"><div id='Empresa'>Empresa</div></TableCell>
                      <TableCell align="center"><div id='Publicados'>Publicados</div></TableCell>
                      <TableCell align="center"><div id='Origen'>Origen</div></TableCell>
                      <TableCell align="center"><div id='Destino'>Destino</div></TableCell>
                      <TableCell align="center"><div id='Tipo de vehiculo'>Tipo de vehiculo</div></TableCell>
                      <TableCell align="center"><div id='Carroceria'>Carroceria</div></TableCell>
                    </ShowTableColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Busqueda por origen */}
                  <>
                    {<>{(searchTypes == "origen") ? (<>
                      {query.filter((row) =>
                        row.origen.toLowerCase().includes(filter)
                      ).map((row) => (<>

                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <ShowTableColumn checked={columChecked}>
                            <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                              (<>{row.empresa}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Publicados'>{<>{(row.servicios == null | row.servicios == "") ? (<>{"-"}</>) :
                              (<>{row.servicios}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Tipo de vehiculo'>{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) :
                              (<>{row.tipovehiculo}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Carroceria'>{<>{(row.carroceria == null | row.carroceria == "") ? (<>{"-"}</>) :
                              (<>{row.carroceria}</>)}</>}</div>
                            </TableCell>
                          </ShowTableColumn>
                        </TableRow>
                      </>)
                      )}
                    </>) :
                      null}</>}
                    {/* Busqueda por destino */}
                    {<>{(searchTypes == "destino") ? (<>
                      {query.filter((row) =>
                        row.origen.toLowerCase().includes(filter)
                      ).map((row) => (<>
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <ShowTableColumn checked={columChecked}>
                            <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                              (<>{row.empresa}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Publicados'>{<>{(row.servicios == null | row.servicios == "") ? (<>{"-"}</>) :
                              (<>{row.servicios}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Tipo de vehiculo'>{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) :
                              (<>{row.tipovehiculo}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Carroceria'>{<>{(row.carroceria == null | row.carroceria == "") ? (<>{"-"}</>) :
                              (<>{row.carroceria}</>)}</>}</div>
                            </TableCell>
                          </ShowTableColumn>
                        </TableRow>
                      </>)
                      )}
                    </>) :
                      null}</>}
                    {/* Busqueda por tipo de vehiculo */}
                    {<>{(searchTypes == "tipo vehiculo") ? (<>
                      {query.filter((row) =>
                        row.tipovehiculo.toLowerCase().includes(filter)
                      ).map((row) => (<>

                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <ShowTableColumn checked={columChecked}>
                            <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                              (<>{row.empresa}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Publicados'>{<>{(row.servicios == null | row.servicios == "") ? (<>{"-"}</>) :
                              (<>{row.servicios}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Tipo de vehiculo'>{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) :
                              (<>{row.tipovehiculo}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Carroceria'>{<>{(row.carroceria == null | row.carroceria == "") ? (<>{"-"}</>) :
                              (<>{row.carroceria}</>)}</>}</div>
                            </TableCell>
                          </ShowTableColumn>
                        </TableRow>
                      </>)
                      )}
                    </>) :
                      null}</>}
                    {/* Busqueda por carroceria */}
                    {<>{(searchTypes == "carroceria") ? (<>
                      {query.filter((row) =>
                        row.carroceria.toLowerCase().includes(filter)
                      ).map((row) => (<>

                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <ShowTableColumn checked={columChecked}>
                            <TableCell align="center"><div id='Empresa'>{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                              (<>{row.empresa}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Publicados'>{<>{(row.servicios == null | row.servicios == "") ? (<>{"-"}</>) :
                              (<>{row.servicios}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Tipo de vehiculo'>{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) :
                              (<>{row.tipovehiculo}</>)}</>}</div>
                            </TableCell>
                            <TableCell align="center"><div id='Carroceria'>{<>{(row.carroceria == null | row.carroceria == "") ? (<>{"-"}</>) :
                              (<>{row.carroceria}</>)}</>}</div>
                            </TableCell>
                          </ShowTableColumn>
                        </TableRow>
                      </>)
                      )}
                    </>) :
                      null}</>}
                  </>
                </TableBody>
                <TableBody>
                </TableBody>
              </Table>
            </TableContainer>
            {<>{(rows == "") ?
              <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                <Typography>NO HAY DATOS DISPONIBLES</Typography>
              </Stack> :
              ""}</>}
          </Paper>
        </Dashboard>
      </>}</>);
};
