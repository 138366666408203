//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import LoadingScreen from './LoadingScreen';
import API, { AXIOS } from '../environment/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useForm from '../hooks/useForm';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import LoadingModal from "../components/LoadingModal";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function fidel() {
  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open_editCompany, setOpen_editCompany] = React.useState(false);
  const [open_deleteCompany, setOpen_deleteCompany] = React.useState(false);
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [open_viajes, setviajes] = React.useState(false);
  const [cityData, setcityData] = React.useState([]);
  const [city, setcity] = React.useState("");
  const [stateData, setstateData] = React.useState([]);
  const [state, setstate] = React.useState("");
  const [validatorSuccess, setvalidatorSuccess] = React.useState(false);
  const [validatorErrors, setvalidatorErrors] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [delteData, setDelete] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [Loading, setLoading] = React.useState(true);
  const [totalPage, setTotalPage] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_toast_red, setOpen_toast_red] = React.useState(false);
  const [open_alert, setAlert] = React.useState(false);
  const [query, setQuery] = React.useState([]);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('cedula');

  //Funcion para el select del tipo de busqueda  
  const handleChangeSearchType = (event) => {
    setFilter('');
    setSearchType(event.target.value);
    setcompanies(query);
    if (event.target.value == 1) {
      setSearchTypes("cedula");
    };
    if (event.target.value == 2) {
      setSearchTypes("nombre");
    };
    if (event.target.value == 3) {
      setSearchTypes("apellido");
    };
  };

  //Funcion para mostrar pantalla carga
  const openLoad = () => setLoad(true);
  //Funcion para cerrar pantalla de carga
  const closeLoad = () => setLoad(false);
  //Funcion para cerrar alerta
  const closeAlert = () => setAlert(false);
  //Funcion para cerrar notificacion
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };
  //Funcion para cerrar notificacion de error
  const handleClose_toast_red = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_red(false);
  };

  //Funcion para reseteo de data
  const [formOffice, setFormOffice] = useForm({
    id: "",
    sku: "",
    name: "",
    state_id: "",
    city_id: "",
    state_name: "",
    city_id: "",
    city_name: "",
    address: "",
    phone: "",
    email: "",
    created_at: ""
  });
  const [formCompany, setFormCompany] = useForm({
  });

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }
  //Estilo de modales
  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  //Funcion para determinar el tiempo en pantalla de la notificacion
  setTimeout(function () {
    setvalidatorSuccess(false);
    setvalidatorErrors(false);
  }, 8000);

  //Funcion para refrescar la pantalla
  const refresh = () => {
    openLoad();
    AXIOS.get(API.GET_FLOTA_SEGURA + "?start=0&length=10", {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setQuery(response.data.data);
      setTotalPage(response.data.recordsTotal);
      // setvalidatorSuccessd(true);   
    });
  };

  //Funcion para eliminar servicio
  const DeleteServices = () => {
    openLoad();
    AXIOS.delete(API.CREATE_FLOAT + "/" + delteData.id, {
    }).then((response) => {
      closeLoad();
      closeviajes();
      setOpen_toast_red(true);
      refresh();
    });
  };
  //Funcion para digitar solo numeros en el input
  const isInputNumber = (event) => {
    let ch = String.fromCharCode(event.which);
    if (!(/[0-9-,]/.test(ch))) {
      event.preventDefault();
    }
  };

  //Funcion para editar servicio  
  const submitDeleteCompany = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      AXIOS.put("http://52.6.59.147/dev/logicem-back/public/api/admin/drivers/" + (companyData.id), {
        data: {
          password: data.get('pass'),
        }
      }).then(function (data) {
        refresh();
        closeViewOffices();
      })
        .catch(function (error) {
        });
    } catch (error) {
    }
  };
  const closeviajes = () => setviajes(false);

  //Funcion para cerrar moda del editar
  const close_editCompany = () => {
    setOpen_editCompany(false);
    setstate("");
    setcity("");
  }

  //Funcion para cerrar modal de eliminar
  const close_deleteCompany = () => setOpen_deleteCompany(false);
  //Funcion para cerrar modal de vista
  const closeViewOffices = () => setViewOffices(false);
  //Inicializacion de variable
  const [openE, setOpenE] = React.useState(false);
  //Funcion para mostar formulario de agg flota
  const handleOpenE = () => {
    setOpenE(true);
  };
  //Funcion para cerrar formulario de agg flota
  const handleCloseE = () => {
    setOpenE(false);
  };

  //Funcion para agregar una flota
  const editUser = (event) => {
    openLoad();
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('dni') == "") {
      closeLoad();
      setAlert(true)
      seterrorsMessage("Digite una Cédula")
    } else {
      AXIOS.post(API.CREATE_FLOAT, {
        instancia_id: 154,
        user_id: 21130,
        cedula: data.get('dni')
      }).then(function (data) {
        closeLoad();
        refresh();
        handleCloseE();
        setOpen_toast(true);
      })
        .catch(function (error) {
          closeLoad();
          setAlert(true)
          seterrorsMessage("La cédula no existe o ya pertenece a una flota.")
        });
    }
  }

  //Funcion para almacenar input de busqueda
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };
  //Funcion para almacenar informacion de servicio
  const openDeleteServices = (row) => {
    setDelete({
      id: row.id,
    })
    setviajes(true);
  };

  React.useEffect(() => {
    //Pre-carga de apis
    AXIOS.get(API.GET_FLOTA_SEGURA + "?start=0&length=10", {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setQuery(response.data.data);
      setTotalPage(response.data.recordsTotal);
    });

  }, []);

  if (!companies) return null;
  if (!stateData) return null;
  if (!cityData) return null;

  //Inicializando datos que trae la api de get
  function createData(id, created_at, identificacion, nombres, apellidos, celular, usuario_nombres, usuario_apellidos) {

    return { id, created_at, identificacion, nombres, apellidos, celular, usuario_nombres, usuario_apellidos };
  }

  //Seleccion de datos que se mostraran en la tabla  
  const dataCompany = companies.map(item => {
    return (
      createData(item.id, item.created_at, item.identificacion, item.nombres, item.apellidos, item.celular, item.usuario_nombres, item.usuario_apellidos)
    )
  })

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_FLOTA_SEGURA + "?start=" + (1 + newPage) + "&length=" + rowsPerPage, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setQuery(response.data.data);
      setTotalPage(response.data.recordsTotal);
    });
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_FLOTA_SEGURA + "?start=" + (page) + "&length=" + (+event.target.value), {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setQuery(response.data.data);
      setTotalPage(response.data.recordsTotal);
    });
    setPage(page);
  };

  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard title='Mi flota segura'>
          {/* Modal de alerta */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                {errorsMessage}
              </Typography>
            </Box>
          </Modal>
          {/* Notificacion success*/}
          <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
            <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
              Flota creada con exito
            </Alert>
          </Snackbar>
          {/* Notificacion error */}
          <Snackbar open={open_toast_red} autoHideDuration={8000} onClose={handleClose_toast_red}>
            <Alert onClose={handleClose_toast_red} severity="error" sx={{ width: '100%' }}>
              Flota eliminada con exito
            </Alert>
          </Snackbar>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          <Grid item xs={12} md={2} lg={29} style={{ display: 'flex' }}>
          </Grid>
          {/* Modal para agregar flota segura      */}
          <Modal
            open={openE}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                Agregar la flota segura, ingrese la cédula del transportista
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={editUser} noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={18}>
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField onKeyPress={isInputNumber} fullWidth name="dni" label="Cédula" variant="outlined" required />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseE}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > AGREGAR
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal para eliminar flota */}
          <Modal
            open={open_viajes}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿DESEA ELIMINAR LA FLOTA?
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={closeviajes}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 1, background: "gray" }}
                  >
                    No
                  </Button>
                  <div style={{ margin: 18 }}></div>
                  <Button type="submit" fullWidth variant="contained" onClick={DeleteServices}
                    sx={{ mt: 3, mb: 1, background: " #e35233" }}
                  > Si
                  </Button>
                </div>
              </Container>
            </Box>
          </Modal>
          {/* Modal para cambiar contraseña */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                Nueva Contraseña <spam style={{ color: "black" }}>{"- "}{companyData.nombre}</spam>
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={submitDeleteCompany} noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={18} >
                      <TextField autoComplete="given-name" name="pass" required type="text"
                        fullWidth id="pass" label="Contraseña" autoFocus defaultValue={companyData.id}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeViewOffices}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 2, background: " #e35233" }}
                    > Actualizar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal de alertas */}
          <Modal
            open={open_editCompany}
            onClose={close_editCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "auto",
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' color="red" >
                Alertas <spam style={{ color: "gray" }}>{"- "}</spam><spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Alerta Conductor</label>
                  </Grid>
                  {(companyData.alertas == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas}</>)}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Alerta Vehiculo</label>
                  </Grid>
                  {(companyData.alertas_vehiculo == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas_vehiculo}</>)}
                </Box>
              </Container>
            </Box>
          </Modal>
          <Modal
            open={open_deleteCompany}
            onClose={close_deleteCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' color="red">
                Alertas Datacarga <spam style={{ color: "gray" }}>{"- "}</spam><spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>

              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Habilitado Conductor</label>
                  </Grid>
                  {(companyData.habilitaciones == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones}</>)}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Habilitado Vehiculo</label>
                  </Grid>
                  {(companyData.habilitaciones_vehiculo == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones_vehiculo}</>)}
                </Box>
              </Container>
            </Box>
          </Modal>
          <Grid item xs={12} md={8} lg={29} marginTop={4}>
            <Paper sx={{}}>
              {/* Input de filtro */}
              <div style={{ margin: 10, float: 'right' }}>
                <TextField id="filled-basic" inputProps={{ style: { textTransform: 'lowercase' } }} label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} />
              </div>
              {/* Select para el tipo de busqueda */}
              <div style={{ float: "right", marginTop: 28 }}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                  <Select
                    sx={{ minWidth: 160 }}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={1}
                    value={searchType}
                    label="Tipo de busqueda"
                    onChange={handleChangeSearchType}
                  >
                    <MenuItem value={1}>Cédula</MenuItem>
                    <MenuItem value={2}>Nombre</MenuItem>
                    <MenuItem value={3}>Apellido</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* Boton de refrescar pagina */}
              <div style={{ float: "right", marginTop: 28, marginRight: 10 }}>
                <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button>
              </div>
              {/* Boton para agregar flota */}
              <div style={{ float: "right", marginTop: 28, marginRight: 10 }}>
                <Button type="submit" fullWidth variant="contained" onClick={() => handleOpenE()}
                  sx={{ float: "right", background: "#FF5733", width: "auto" }}>AGREGAR MI FLOTA SEGURA
                </Button>
              </div>
              <TableContainer sx={{ maxHeight: 440 }}>
                {/* Tabla de contenido */}
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <ShowTableColumn checked={columChecked}> */}
                      <TableCell align="center"><b>Fecha de reporte</b></TableCell>
                      <TableCell align="center"><b>Cedula</b></TableCell>
                      <TableCell align="center"><b>Nombre Completo</b></TableCell>
                      <TableCell align="center"><b>Celular</b></TableCell>
                      <TableCell align="center"><b>Ingresado Por</b></TableCell>
                      <TableCell align="center"><b>Remover</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {<>{(searchTypes == "cedula") ? (<>
                        {query.filter((row) =>
                          row.identificacion.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center" id="ID">{row.created_at}</TableCell>
                            <TableCell align="center" id="Fecha">{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) :
                              (<>{row.identificacion}</>)}</>}</TableCell>
                            <TableCell align="center" id="Empresa">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                              (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                            <TableCell align="center" id="Origen">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                              (<>{row.celular}</>)}</>}</TableCell>
                            <TableCell align="center" id="Destino">{<>{(row.usuario_nombres == null | row.usuario_nombres == "") ? (<>{"-"}</>) :
                              (<>{row.usuario_nombres}</>)}</>}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>

                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}
                      {<>{(searchTypes == "nombre") ? (<>
                        {query.filter((row) =>
                          row.nombres.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center" id="ID">{row.created_at}</TableCell>
                            <TableCell align="center" id="Fecha">{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) :
                              (<>{row.identificacion}</>)}</>}</TableCell>
                            <TableCell align="center" id="Empresa">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                              (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                            <TableCell align="center" id="Origen">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                              (<>{row.celular}</>)}</>}</TableCell>
                            <TableCell align="center" id="Destino">{<>{(row.usuario_nombres == null | row.usuario_nombres == "") ? (<>{"-"}</>) :
                              (<>{row.usuario_nombres}</>)}</>}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>

                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}
                      {<>{(searchTypes == "apellido") ? (<>
                        {query.filter((row) =>
                          row.apellidos.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center" id="ID">{row.created_at}</TableCell>
                            <TableCell align="center" id="Fecha">{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) :
                              (<>{row.identificacion}</>)}</>}</TableCell>
                            <TableCell align="center" id="Empresa">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                              (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                            <TableCell align="center" id="Origen">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                              (<>{row.celular}</>)}</>}</TableCell>
                            <TableCell align="center" id="Destino">{<>{(row.usuario_nombres == null | row.usuario_nombres == "") ? (<>{"-"}</>) :
                              (<>{row.usuario_nombres}</>)}</>}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>
                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}</>
                  </TableBody>
                  <TableBody>
                  </TableBody>
                </Table>
              </TableContainer>
              {<>{(dataCompany == "") ?
                <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                  <Typography>NO HAY DATOS DISPONIBLES</Typography>
                </Stack> :
                ""}</>}
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Dashboard>
      }
    </>
  );
}