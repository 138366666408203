//Importacion de complementos
import React from 'react'

export const ShowTableColumn = (props) => {
  return (
    // Check de items en mostrar la tabla
    <>{props.children.map((item, index) => {
      // console.log('includes', item.props.children.props.id)
      if (props.checked.includes(item.props.children.props.id)) {
        return (
          item
        )
      }
    })}</>
  )
};