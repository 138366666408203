//Importacion de complementos
import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import API, { AXIOS } from '../environment/api'

export const GraphBarChart = () => {
  // Inicializacion de datos
  const [fidelizadoH, setFidelizadoH] = React.useState([]);
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 20;
    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  };

  //Pre-carga de apis  
  React.useEffect(() => {
    AXIOS.get(API.REPORTS, {
    }).then((response) => {
      setFidelizadoH([
        { name: 'Registrados', total: response.data.user_total[0].registrados, semanal: response.data.user_weekly[0].registrados, diarios: response.data.user_daily[0].usuarios },
        { name: 'Verificados', total: response.data.user_total[0].verificados, semanal: response.data.user_weekly[0].verificados, diarios: response.data.user_daily[0].verificados },
        { name: 'Activos', total: response.data.user_total[0].activos, semanal: response.data.user_weekly[0].activos, diarios: response.data.user_daily[0].contratados },
        { name: 'Fidelizados', total: response.data.user_total[0].contratados, semanal: response.data.user_weekly[0].contratados, diarios: response.data.user_daily[0].contratados },
      ])
    });
  }, []);

  return (
    <div style={{ width: '100%', marginTop: 20, marginRight: 100, backgroundColor: 'white' }}>
      {/* Vista de Indicadores      */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <span><b>Indicadores</b></span>
      </div>
      <ResponsiveContainer width='100%' aspect={1.5}>
        <BarChart
          data={fidelizadoH}
          width={700}
          height={500}
          margin={{
            top: 60,
            left: 20,
            right: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='4 1' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={3} />
          <Bar dataKey='total' fill='#DF7857'>
            <LabelList dataKey="total" content={renderCustomizedLabel} />
          </Bar>
          <Bar dataKey='semanal' fill='#3A1078' />
          <Bar dataKey='diarios' fill='#3A1078' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}