import React, { useState, useEffect, forwardRef, useRef } from 'react';
import API, { AXIOS } from '../../environment/api';
import { useNavigate } from "react-router-dom";
import { FormControl, Select, TextField, MenuItem, InputLabel, Snackbar, Grid, Stack, Avatar, Button, Typography, Box, Tab, Paper, Tabs, Modal } from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import ArrowBack from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import './Curriculum.css';
import Dashboard from '../../components/Dashboard';
import LoadingScreen from './../LoadingScreen';
import CurriculumDoc from './CurriculumDoc';
import CurriculumPersonal from './CurriculumPersonal';
import CurriculumVehiculo from './CurriculumVehiculo';
import CurriculumTrailer from './CurriculumTrailer';
import CurriculumTenedor from './CurriculumTenedor';
import CurriculumPropietario from './CurriculumPropietario';
import CloseIcon from '@mui/icons-material/Close';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 4
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function CurriculumPage() {
  const rol = JSON.parse(localStorage.getItem("roles_usuario")).role_id;
  const [value, setValue] = useState(0);
  const [hv_data, set_hv_data] = useState([]);
  const [loading, set_Loading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingListExcepciones, setLoadingListExcepciones] = useState(true);
  const [open_saved, setOpenSaved] = useState(false);
  const [openModalDevolver, handleModalDevolver] = useState(false);
  const [openModalDetalleDevolucion, handleModalDetalleDevolucion] = useState(false);
  const [openModalHabExcepcion, handleModalHabExcepcion] = useState(false);

  const [msgAlert, setMsgAlert] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severityAlert, setSeverityAlert] = useState("error");
  const [permisoEdit, setPermisEdit] = useState(1);
  const [opcionesDevolucion, setOpcionesDevolucion] = useState([]);
  const [razonDevolucion, setRazonDevolucion] = useState([]);
  const [imageModal, setImageModal] = useState(null);
  const [fileImageModal, setFileImageModal] = useState(null);
  const [listExcepciones, setListExcepciones] = useState([]);
  const [dataInCurrentExcepcion, setDataInCurrentExcepcion] = useState(false);
  const descripcionExcepcionRef = useRef(null);
  const nombreExcepcionRef = useRef(null);
  let navigate = useNavigate();

  const handleCloseSaved = () => {
    setOpenSaved(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickInputFileModal = () => {
    document.getElementById('fileInputImageModal').click();
  };

  const atenderSolicitud = () => {
    if(hv_data.estados.put_datos_personales === true && 
      hv_data.estados.put_doc_personales === true && 
      hv_data.estados.put_vehiculo === true && 
      hv_data.estados.put_trailer === true && 
      hv_data.estados.put_doc_vehiculo === true && 
      hv_data.estados.put_tenedor === true && 
      hv_data.estados.put_doc_tenedor === true && 
      hv_data.estados.put_propietario === true
    ){
      submitUpdateCompany(1, "") // Se pasa el estado de la solicitud a atendido
    }else{
      callAlert('warning', 'Asegurate de que todos los datos esten cargados correctamente')
    }
  }

  const handleSubmitDevolver = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let select_value = data.get('motivo');
    let comentario = data.get('comentario');
    if(!select_value || !comentario){
      callAlert('warning', 'Llena todos los datos')
      return;
    }

    let detalle = {
      "opcion": select_value,
      "observacion": comentario
    }
    submitUpdateCompany(0, detalle)
  };
  
    //Funcion para cerrar mensaje
    const handleCloseAlert = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnackbar(false);
    };

  /**
 * Actualiza el estado de un campo específico en el objeto `estados` dentro de `hv_data`.
 *
 * @param {string} field - El nombre del campo en el objeto `estados` que se desea actualizar.
 * @param {boolean} value - El nuevo valor para el campo especificado.
 *
 * @example 
 * updateStatusHV('put_tenedor', true);
 */
  const updateStatusHV = (field, value) => {
    fetchData();
    set_hv_data(prevData => ({
      ...prevData,
      estados: {
          ...prevData.estados,
          [field]: value
      }
    }));
  }

  /**
   * Asigna la placa seleccionada para la vista de Documento de tenedor
   */
  const updateTrailerAsociado = (placa) => {
    set_hv_data(prevData => ({
      ...prevData,
      data: {
        ...prevData.data,
        trailer_asociado: placa
      }
    }));
  }
  

  /**
   * Muestra un mensaje de alerta en la parte inferior izquierda de la pantalla
   * @param type color y tono de la alerta ('success', 'error', 'warning')
   * @param {srting} msg Mensaje a mostrar 
   */
  const callAlert = (type, msg) => {
    if(openSnackbar === true){
      handleCloseAlert(undefined, 'clic'); 
    }
    setTimeout(() => {      
      setMsgAlert(msg)
      setSeverityAlert(type)
      setOpenSnackbar(true);  
      setTimeout(() => {
        if(openSnackbar === true){
          handleCloseAlert(undefined, 'clic');  
        }
      }, 6000);
    }, 100);
  }

  const handleCurrentExcepcion  = (item) => {
    if(item === 'clean'){
      nombreExcepcionRef.current.value = null;
      descripcionExcepcionRef.current.value = null;
      setImageModal(null)
      setDataInCurrentExcepcion(false)
      return;
    }
    nombreExcepcionRef.current.value = item.nombre;
    descripcionExcepcionRef.current.value = item.razon_de_excepcion;
    setImageModal(item.archivo)
    setDataInCurrentExcepcion(true)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImageModal(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageModal(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatDate = (date) => {
    const pad = (num) => String(num).padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleAddExcepcion = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const nombre = data.get('nombre');
    const descripcion = data.get('descripcion');

    if (!nombre || !descripcion || !fileImageModal) {
      callAlert('warning', 'complete todos los campos')
      return;
    }
    setLoadingModal(true);
    const form = new FormData();
    form.append('nombre', nombre);
    form.append('razon_de_excepcion', descripcion);
    form.append('archivo', fileImageModal);
    form.append('identificacion', hv_data.data.identificacion);
    form.append('user_id', localStorage.getItem('user_id'));
      await AXIOS.post(API.POST_ADD_EXCEPCIONES, form, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        console.log(openModalHabExcepcion)
        if(openModalHabExcepcion === true) {
          callAlert('success', 'Acción realizada con exito')
          const newException = {
            'nombre': nombre,
            'razon_de_excepcion': descripcion,
            'created_at': formatDate(new Date()), // Fecha actual como ejemplo
            'archivo': imageModal,
          };
          setListExcepciones((prevList) => [...prevList, newException]);
          // event.target.reset();
          setLoadingModal(false);
          setImageModal(null);
          setFileImageModal(null);    
        }
      }).catch((error) => {
        callAlert('error', 'Ha ocurrido un error')
        setLoadingModal(false);
      }).finally(() => {
        handleModalHabExcepcion(false);
      });

  };

    /**
     * Actualiza el estado de la solicitus actual
     * @param {number} estado_registro estado a asignar
     *  estados = 0 inicio de la solicitud
     *  estados = 1 atender solicitud
     *  estados = 2 habilitar solicitud
     *  estados = 3 rechazar solicitud
     *  estados = 4 por subsanar solicitud (devolver)
     */
    const submitUpdateCompany = (estado_registro, detalle) => {
      let id_user = hv_data.data.identificacion;
      set_Loading(true);
      try {
          AXIOS.put(API.VERIFIED_USER + id_user, {
            estado_registro: estado_registro,
          })
          .then(function(data) {                      
            if(estado_registro === 1){
              // !Redireccionar a la vista anterior
              linkTo('/drivers')
            }           

            if(estado_registro === 0){
              // Notificar observación de devolución
              AXIOS.post(API.POST_DEVOLUCION, {
                caso_devolucion:detalle.opcion,
                observacion:detalle.observacion,
                identificacion:localStorage.getItem('dni_hojaVida')
              }).then(() => {
                if(openModalDevolver === true) {
                  handleModalDevolver(false);
                }
              })
            } 
            callAlert('success', 'Estado actualizado con exito')
          })
          .catch(function(error) {
            if(error.response.data === "Ya subsano los datos solicitados"){
              callAlert('error', 'Ya subsano los datos solicitados')
            }else{
              callAlert('error', 'Ha ocurrido un error, intentalo más tarde')   
            }
          });
      } catch (error) {}
      set_Loading(false);
    };
    
//Funcion para la navegacion
  const linkTo = (url) => {
    return navigate(url);
  };

  /**
   * Habilita la solicitus actual, pero antes verifica que todos los datos esten correctamente cargados
   */
  const validarEstadosHV = (call) => {
    if(hv_data.estados.put_datos_personales === true && 
      hv_data.estados.put_doc_personales === true && 
      hv_data.estados.put_vehiculo === true && 
      hv_data.estados.put_trailer === true && 
      hv_data.estados.put_doc_vehiculo === true && 
      hv_data.estados.put_tenedor === true && 
      hv_data.estados.put_doc_tenedor === true && 
      hv_data.estados.put_propietario === true
    ){
      if(call === 'habilitar'){
        submitUpdateCompany(2, "");
      }
      if(call === 'enviar a sap'){
        submitEditProvider()
      }
    }else{
      callAlert('warning', 'Asegurate de que todos los datos esten cargados correctamente')
    }
  }

  /**
   * Maneja el permiso que tiene el usuario en sesión para con esta vista de HV
   * 1 = Puede aceptar solicitud y guardar cambios (default)
   * 2 = Puede subsanar y guardar cambios
   * 3 = No puede hacer nada, solo ver
   */
  const handlerPermiso = (estados, bloqueo) => {
    if(bloqueo === true){
      // No mostrar ningun boton, No puede hacer nada
      setPermisEdit(3);      
      return;
    }    

    // if (estados == undefined || estados.length === 0){
    //   // No mostrar ningun boton, No puede hacer nada
    //   setPermisEdit(3);      
    //   return;
    // }

    if(estados.estados === 1 || estados.estados === 2 || estados.estados === 3){
      // No mostrar ningun boton, No puede hacer nada
      setPermisEdit(3);   
      return;
    }

    if(estados.estados === 4){
      // El boton a mostrar es el de subsanar y puede guardar cambios
      setPermisEdit(2);
      setTimeout(() => {                
      }, 100);
      return;
    }

    if(estados.estados === 0){
      // Puede guardar cambios y atender la solicitud
      setPermisEdit(1);      
      return;
    }    
  }

  const fetchData = async () => {
    //Recargar hoja de vida
    AXIOS.get(API.GET_HOJAVIDA + localStorage.getItem("dni_hojaVida") + "/" + localStorage.getItem('user_id'), {
    }).then((response) => {
      handlerPermiso(response.data.estados, (response.data.data.bloqueo ||  undefined))
      set_hv_data(response.data);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      set_Loading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(openModalDevolver === true){
      // Consultar el listado de opciones
      AXIOS.get(API.GET_OPTIONS_DEVOLUCION, {}).then((response) => {
        setOpcionesDevolucion(response.data);        
      })
    }
  }, [openModalDevolver])

  useEffect(() => {
    if(openModalHabExcepcion === true){
      // Consultar el listado de opciones
      AXIOS.get(API.GET_LIST_EXCEPCIONES(hv_data.data.identificacion), {}).then((response) => {
        setListExcepciones(response.data.excepcion);        
        setLoadingListExcepciones(false);
      }).finally(() => {        
      })
    }
  }, [openModalHabExcepcion])

  
  const verDetalleDevolucion = () => {
    AXIOS.get(API.GET_MOTIVO_DEVOLUCION +"/"+ localStorage.getItem("dni_hojaVida"), {}).then((response) => {
      setRazonDevolucion(response.data[0])
      handleModalDetalleDevolucion(true);      
    }).catch(() => {
      callAlert('error', 'Ha ocurrido un error, intentalo más tarde')
    })
  }

  const submitEditProvider = () => {
    let response_gps = {};
    AXIOS.get(API.GET_GPS + (hv_data.data.vehiculo_id), {
    }).then((response) => {
      response_gps = response.data.data;
    })
    AXIOS.post(API.CREATE_HV_SAP, {
      "propietario": {
        "U_NoIdentificacion": hv_data.data.propietario_identificacion,
        "U_TipoIdentificacion": hv_data.data.propietario_tipoidentificacion_id,
        "U_Nombres": hv_data.data.propietario_nombre,
        "U_PrimerApellido": hv_data.data.propietario_apellido,
        "U_SegundoApellido": "",
        "U_Direccion": hv_data.data.propietario_direccion,
        "U_MunicipioDireccion": hv_data.data.propietario_lugar_residencia,
        "U_Celular": hv_data.data.propietario_celular,
        "U_email": hv_data.data.propietario_email,
        "U_ObsCreacion": hv_data.data.propietario_alerta,
        "U_FechaVencRUT": ""
      },
      "tenedor": {
        "U_NoIdentificacion": hv_data.data.tenedor_identificacion,
        "U_TipoIdentificacion": hv_data.data.tenedor_tipoidentificacion_id,
        "U_Nombres": hv_data.data.tenedor_nombre,
        "U_PrimerApellido": hv_data.data.tenedor_apellido,
        "U_SegundoApellido": "",
        "U_Direccion": hv_data.data.tenedor_direccion,
        "U_MunicipioDireccion": hv_data.data.tenedor_lugar_residencia,
        "U_Celular": hv_data.data.tenedor_celular,
        "U_email": hv_data.data.tenedor_email,
        "U_NoCuentaBancaria": hv_data.data.tenedor_cuenta,
        "U_TipoCuenta": hv_data.data.tenedor_tipo_cuenta,
        "U_Banco": hv_data.data.tenedor_banco,
        "U_ObsCreacion": hv_data.data.tenedor_alerta? hv_data.data.tenedor_alerta : hv_data.data.created,
        "U_FechaVencRUT": "",
      },
      "conductor": {
        "U_NoIdentificacion": hv_data.data.identificacion,
        "U_TipoIdentificacion": hv_data.data.tipoidentificacion_id,
        "U_MunicipioDocumento": hv_data.data.lugar_expedicion_documento,
        "U_Nombres": hv_data.data.nombres,
        "U_PrimerApellido": hv_data.data.apellidos,
        "U_SegundoApellido": "",
        "U_CategoriaLicencia": hv_data.data.licencia_id,
        "U_NoLicenciaConducci": hv_data.data.licencianumero,
        "U_FechaVencLicencia": hv_data.data.licenciavence,
        "U_Direccion": hv_data.data.direccion,
        "U_MunicipioDireccion":  hv_data.data.lugar_residencia,
        "U_Celular": hv_data.data.celular,
        "U_CuentaPropia": hv_data.data.cuenta_propia,
        "U_Titular": hv_data.data.nombre_titular,
        "U_NoIdentTitular": hv_data.data.identificacion_titular,
        "U_TipoIdentTitular": hv_data.data.tipoidentificaciontitular_id,
        "U_email": hv_data.data.email,
        "U_NoCuentaBancaria": hv_data.data.cuenta,
        "U_TipoCuenta": hv_data.data.tipo_cuenta,
        "U_Banco": hv_data.data.banco,
        "U_TipoConductor": hv_data.data.tipoconductor_id,
        "U_tipoCarga": hv_data.data.tipocarga_id,
        "U_EPS": hv_data.data.eps_id,
        "U_ARL": hv_data.data.arl_id,
        "U_AFP": hv_data.data.afp_id,
        "U_FechaPago": hv_data.data.fecha_pago,
        "U_ObsCreacion": hv_data.data.created,
      },
      "U_UsuarioCreacion": hv_data.data.usuario_creacion,
    }).then((response) => {
      AXIOS.post(API.CREATE_VEHICULO_SAP, {
        "trailer": {
          "U_TipoVehiculo": "63",
          "U_TipoConfiguracion": "63",
          "U_Modelo": hv_data.data.trailer_modelo,
          "U_Placa": hv_data.data.placa + "".toUpperCase(),
          "U_Color": hv_data.data.color_trailer,
          "U_MarcaTrailer": hv_data.data.marca_trailer,
          "U_TipoSemiRemolque": "NO RETRACTIL",
          "U_CapacidadCarga": hv_data.data.peso_capacidad_carga_trailer,
          "U_PesoVacio": hv_data.data.peso_vacio_trailer,
          "U_NumTarjPropiedad": hv_data.data.num_target_propiedad,
          "U_FechaMatricula": hv_data.data.fecha_matricula_trailer,
          "U_UmCapacidad": "1",
          "U_TipoCarga": "1",
          "U_TipoCarroceria": hv_data.data.carroceria_trailer,
          "U_Tenedor": ("PT" + hv_data.data.tenedor_identificacion),
          "U_Propietario": ("PT" + hv_data.data.propietario_identificacion),
          "U_Poseedor": ("PT" + hv_data.data.tenedor_identificacion),
        },
        "vehiculo": {
          "U_TipoVehiculo": hv_data.data.tipo_vehiculo_id,
          "U_TipoConfiguracion": hv_data.data.tipo_configuracion_id,
          "U_Modelo": hv_data.data.modelo,
          "U_NumeroMotor": hv_data.data.num_motor,
          "U_NumeroChasis": hv_data.data.num_chasis,
          "U_Placa": hv_data.data.placa + "".toUpperCase(),
          "U_MarcaCabezote": hv_data.data.marca_cabezote,
          "U_LineaCabezote": hv_data.data.linea_cabezote,
          "U_TipoCombustible": hv_data.data.tipo_combustible,
          "U_Color": hv_data.data.color,
          "U_PesoVacio": hv_data.data.peso_vacio,
          "U_TipoCarroceria": hv_data.data.tipo_carroceria,
          "U_TipoCarga": hv_data.data.tipo_carga,
          "U_NumeroSOAT": hv_data.data.num_soat,
          "U_CompaniaSOAT": hv_data.data.compania_soat_id,
          "U_FechaInicioSOAT": hv_data.data.fecha_inicio_soat,
          "U_FechaFinSOAT": hv_data.data.fecha_fin_soat,
          "U_Tenedor": ("PT" + hv_data.data.tenedor_identificacion),
          "U_Propietario": ("PT" + hv_data.data.propietario_identificacion),
          "U_Poseedor": ("PT" + hv_data.data.tenedor_identificacion),
          // "U_CodRNDC":dataDriver.codigornd,
          "U_CodRNDC": "21",
          "U_NumTarjPropiedad": hv_data.data.num_target_propiedad,
          "U_Repontenciado": hv_data.data.repontenciado,
          "U_CargaAdministra": hv_data.data.tipo_carga_admin_id,
          "U_RT_NoCertificado": hv_data.data.no_rtm,
          "U_RT_CDA": "01",
          "U_RT_FechaExp": hv_data.data.fecha_inicio_tecno,
          "U_RT_FechaVen": hv_data.data.fecha_fin_tecno,
          "U_EmpresaGPS": hv_data.data.empresa_gps_id,
          "U_FechaMatricula": hv_data.data.fecha_matricula,
          "U_SinTrailerAso": hv_data.data.sin_trailer_asoc,
          "U_TipoSemiRemolque": "NO RETRACTIL",
        },
        "conductor": {
          "Code": ("" + hv_data.data.tenedor_identificacion), //responseHvSap.data.conductor.id,
          "U_NoIdentificacion": hv_data.data.identificacion,
          "U_Nombres": hv_data.data.nombres,
          "U_PrimerApellido": hv_data.data.apellidos,
          "U_SegundoApellido": "",
          "U_Defecto": "SI",
        },
        "poliza": {
          "U_TipoPoliza": hv_data.data.vehiculo_tipo_poliza,
          "U_NumeroPoliza": hv_data.data.no_poliza,
          "U_CompaniaPoliza": hv_data.data.poliza_empresa_id,
          "U_FechaInicioPoliza": hv_data.data.poliza_fecha_inicio,
          "U_FechaFinPoliza": hv_data.data.poliza_fecha_fin,
        },
        "gps": {
          "U_EmpresaGPS": response_gps.empresasatelital,
          "U_UsuarioGPS": response_gps.satelitaluser,
          "U_PasswordGPS": response_gps.satelitalpass,
        },
        "U_UsuarioCreacion": hv_data.data.usuario_creacion,
        }).then(() => {
          callAlert('success', 'Acción realizada con exito')
        }).catch(() => {
          callAlert('error', 'Ha ocurrido un error, intentalo más tarde')
        })
    }).catch(() => {
      callAlert('error', 'Ha ocurrido un error, intentalo más tarde')
    })
  }

  return (
    <>
      {loading ?
        <LoadingScreen />
        : (
          <>
            <Dashboard>
              <Stack spacing={3} sx={{ width: '100%' }}>
               <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert severity={severityAlert} sx={{ width: '100%' }}>
                        {msgAlert}
                    </Alert>
                </Snackbar>
              </Stack>
              <Grid>
                <Modal
                  open={open_saved}
                  onClose={handleCloseSaved}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box sx={{ ...style, width: 400 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                      ¿Esta seguro de actualizar la hoja de vida?
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <Button
                        onClick={handleCloseSaved}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 1, background: "gray" }}
                      >
                        NO
                      </Button>
                      <div style={{ marginLeft: 18 }}></div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openModalDevolver}
                  onClose={() => {handleModalDevolver(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Grid component={"form"} onSubmit={handleSubmitDevolver}>
                    <Box sx={{...style, width: 800}}>
                  <CloseIcon className='closeModal' color='red' onClick={() => {handleModalDevolver(false)}}/>
                      <h2 id="modal-title">Motivo de devolución</h2>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="select-label">Selecciona una opción</InputLabel>
                        <Select
                         id="standard-basic"
                          labelId="select-label"
                          label="Selecciona una opción"        
                          name='motivo'
                          defaultValue={''}
                        >
                          {opcionesDevolucion.length > 0 ? 
                            opcionesDevolucion.map((item) => {
                              return (
                                <MenuItem value={item.id}>{item.caso_devolucion}</MenuItem>
                              )
                          }) : <>&nbsp;Cargando...</>}
             
                          {/* <MenuItem value={2}>Opción 2</MenuItem>
                          <MenuItem value={3}>Opción 3</MenuItem> */}
                        </Select>
                      </FormControl>
                      <TextField
                        multiline
                        id="standard-basic"
                        minRows={4}
                        placeholder="Describa el motivo de la devolución"
                        style={{ width: '100%', marginTop: '16px' }}
                        name='comentario'
                      />
                      <Button variant="contained" color="primary" sx={{ mt: 2 }} type='submit'>
                        Enviar
                      </Button>
                    </Box>
                  </Grid>
                </Modal>

                <Modal
                  open={openModalDetalleDevolucion}
                  onClose={() => {handleModalDetalleDevolucion(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Grid>
                    <Box sx={{...style, width: 800}}>
                      <CloseIcon className='closeModal' color='red' onClick={() => {handleModalDetalleDevolucion(false)}}/>
                        {razonDevolucion.length === 0 ? <>
                          <h2 id="modal-title" style={{textAlign: 'center'}}>No hay detalle por mostrar</h2></> 
                        : <>
                          <h2 id="modal-title" style={{textAlign: 'center'}}>Motivo de devolución</h2>
                          <p className='text-devolucion'>Motivo: <span>{razonDevolucion.caso_devolucion}</span></p>
                          <p className='text-devolucion'>Razon: <span>{razonDevolucion.observacion}</span></p>
                        </>}                      
                    </Box>
                  </Grid>
                </Modal>

                <Modal
                  open={openModalHabExcepcion}
                  onClose={() => {handleModalHabExcepcion(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Grid>
                    <Box sx={{...style, width: 900}}>
                      <CloseIcon className='closeModal' color='red' onClick={() => {handleModalHabExcepcion(false)}}/>
                          <h2 id="modal-title" style={{textAlign: 'center'}}>Habilitar con excepción</h2>        
                          <div style={{display: 'grid', gridTemplateColumns: '50% auto', gap: '1em'}}>
                            <div className='listExcepciones' style={{border: '1px solid #0000004a', borderRadius: '15px', padding: '1em', maxHeight: '543px', overflow: 'auto'}}>
                            <h4 style={{margin: '0px'}}>Listado</h4>
                            {loadingListExcepciones === true 
                            ? <>No hay excepciónes anteriores</> 
                            :  
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '16px' }}>
                              <thead>
                                <tr>
                                  <th style={{ border: '1px solid #ccc', padding: '8px' }}>Nombre</th>
                                  <th style={{ border: '1px solid #ccc', padding: '8px' }}>Fecha</th>
                                </tr>
                              </thead>
                              <tbody>
                                {listExcepciones.length > 0 ? 
                                  listExcepciones.map((item, index) => (
                                    <tr key={index} onClick={() => handleCurrentExcepcion(item)}>
                                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.nombre}</td>
                                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.created_at}</td>
                                    </tr>
                                  )): <p style={{textAlign: 'center', marginTop: '16px'}}>No hay datos en esta tabla</p>}
                              </tbody>
                            </table>
                            }
                           
                            </div>
                            <form onSubmit={handleAddExcepcion} style={{border: '1px solid #0000004a', borderRadius: '15px', padding: '1em'}}>
                              <h4 style={{margin: '0px'}}>Nuevo registro</h4>
                                <TextField
                                  id="input-name-excepcion"
                                  minRows={4}
                                  placeholder="Nombre"
                                  style={{ width: '100%', marginTop: '16px'}}
                                  name='nombre'
                                  inputRef={nombreExcepcionRef}
                                />
                                <TextField
                                  multiline
                                  id="input-razon-descripcion"
                                  minRows={4}
                                  placeholder="Descripción"
                                  style={{ width: '100%', marginTop: '16px' }}
                                  name='descripcion'
                                  inputRef={descripcionExcepcionRef}
                                />

                              <div
                                onClick={handleClickInputFileModal}
                                style={{
                                  height: '200px',
                                  border: '2px dashed #ccc',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  backgroundImage: imageModal ? `url(${imageModal})` : 'none',
                                  backgroundSize: '100% 100%',
                                  backgroundPosition: 'center',
                                  margin: 'auto',
                                  marginTop: '16px'
                                }}
                              >
                                {!imageModal && <span style={{ fontSize: '2em', color: '#ccc' }}>+</span>}
                                </div>
                                <input
                                  id="fileInputImageModal"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={handleImageChange}
                                />
                                {loadingModal === true ? 
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '16px'}}>Cargando...</div>
                                :                                 
                                <div style={{display: 'flex', gap: '1em', justifyContent: 'end'}}>                                  
                                  <Button onClick={() => handleCurrentExcepcion('clean')} style={{backgroundColor: 'blue', color: 'white', float: 'right', marginTop: '20px'}}>Limpiar</Button>
                                  {dataInCurrentExcepcion === false ? <Button type="submit" style={{backgroundColor: 'green', color: 'white', float: 'right', marginTop: '20px'}}>Agregar</Button> : <></>}
                                </div>
                                }
                            </form>
                          </div>
                    </Box>
                  </Grid>
                </Modal>
                <Button style={{ background: "white", marginBlockEnd: 20 }} onClick={() => navigate('/drivers')}><ArrowBack /> ATRAS</Button>
                <br />
                <Paper>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      alt="Remy Sharp"
                      sx={{ width: 80, height: 80, margin: 4 }}
                    />
                    <div style={{ marginTop: 20 }}>
                    <h2>{hv_data && hv_data.data && hv_data.data.nombres ? hv_data.data.nombres : ''} {hv_data && hv_data.data && hv_data.data.apellidos ? hv_data.data.apellidos: ''}</h2>
                      <div style={{ marginTop: -28 }}>
                      <Typography style={{ color: "gray" }}><b>{hv_data && hv_data.data && hv_data.data.identificacion}</b></Typography>
                      </div>
                      <div style={{ marginTop: 0 }}>
                        {(localStorage.getItem('verificado') === "Inhabilitado")?
                          <Typography style={{ background: "gray", width: 110, textAlign: "center", color: "white" }}><b>Inhabilitado</b></Typography>
                          :
                          <Typography style={{ background: "green", width: 110, textAlign: "center", color: "white" }}><b>{localStorage.getItem('verificado')}</b></Typography>
                        }
                      </div>
                    </div>
                  </div>
                  <Grid>
                    {(rol === 1 && permisoEdit !== 3) ? (
                      permisoEdit !== 2 ? 
                      <></>
                        // <Button variant="outlined" onClick={atenderSolicitud} sx={{ color: "white", background: "green", borderRadius: 0, float: "right", marginRight: 8, marginTop: -6 }}>Atender solicitud</Button>
                      : <>
                        <div className='content-buttons-header'>
                          <Button variant="outlined" onClick={atenderSolicitud} className='green'>Subsanar</Button>
                          <Button variant="outlined" onClick={verDetalleDevolucion} className='yellow'>Ver detalle</Button>
                        </div>                  
                      </>
                    ) : rol === 13 ? (
                      <>
                      <div className='content-buttons-header'>
                        {/* NUEVO BOTON SALE CON EL ESTADO 0 (SIN PROCESO) */}
                        {(hv_data.estados.estados === 0 || hv_data.estados.estados === undefined) ? <Button  onClick={() => {handleModalHabExcepcion(true)}} variant="outlined" className='green'>Habilitar con excepción</Button> : <></>}
                        {(hv_data.estados.estados !== 2 ) ? <Button variant="outlined" className='green' onClick={() => {validarEstadosHV('habilitar')}}>Habilitar</Button> : <></>} {/*Abierto siempre*/}
                        <Button variant="outlined" style={{color: 'white'}} className='red' onClick={() => {submitUpdateCompany(3, "")}}>Rechazar</Button> {/*Abierto siempre*/}
                        {hv_data.estados.estados === 1 || hv_data.estados.estados === 4 ? <Button variant="outlined" className='yellow' onClick={() => {handleModalDevolver(true)}}>Devolver</Button> : <></>} {/*Cuando el estado sea 1 y el de devolver*/}
                        {hv_data.estados.estados === 4 ? <Button variant="outlined" onClick={verDetalleDevolucion} className='yellow'>Ver detalle</Button> : <></>} 
                        {hv_data.estados.estados === 2 ? <Button variant="outlined" className='green dark' onClick={() => {validarEstadosHV('enviar a sap')}}>ENVIAR A SAP</Button> : <></>}                         
                      </div>
                      </>
                    ) : null}
                    <Box sx={{ width: '100%' }}>
                      <div style={{ border: '1px solid  #c5c5c5' }}>
                      </div>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_datos_personales === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="DATOS PERSONALES" id="simple-tab-0" aria-controls='simple-tabpanel-0' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_doc_personales === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="DOCM. PERSONALES" id="simple-tab-1" aria-controls='simple-tabpanel-1' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_vehiculo === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="VEHICULO" id="simple-tab-2" aria-controls='simple-tabpanel-2' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_trailer === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="TRAILER" id="simple-tab-3" aria-controls='simple-tabpanel-3' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_doc_vehiculo === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="DOCM. VEHICULO" id="simple-tab-4" aria-controls='simple-tabpanel-4' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_tenedor === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="TENEDOR" id="simple-tab-5" aria-controls='simple-tabpanel-5' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_doc_tenedor === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="DOCM. TENEDOR" id="simple-tab-6" aria-controls='simple-tabpanel-6' />
                          <Tab icon={<span style={{ backgroundColor: hv_data.estados.put_propietario === true ? 'green' :'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />} iconPosition="start" label="PROPIETARIO" id="simple-tab-7" aria-controls='simple-tabpanel-7' />                          
                        </Tabs>
                      </Box>
                      {/* <TabPanel value={value} index={0}>
                        <CurriculumPersonal data={hv_data} />
                      </TabPanel> */}
                      <TabPanel value={value} index={0}>
                        <CurriculumPersonal data={hv_data} func_update={updateStatusHV} permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <CurriculumDoc case={2} dni={hv_data.data.identificacion} identity={hv_data.data.identificacion} func_update={updateStatusHV}  permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <CurriculumVehiculo data={hv_data}  func_update={updateStatusHV} permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel> 
                      <TabPanel value={value} index={3}>
                        <CurriculumTrailer data={hv_data} func_update={updateStatusHV} fun_trailerAsociado={updateTrailerAsociado} permiso={permisoEdit} rolSession={rol} asociado={hv_data.data.trailer_asociado}/>
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <CurriculumDoc case={1} dni={hv_data.data.placa} func_update={updateStatusHV} identity={hv_data.data.identificacion} permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel>
                      <TabPanel value={value} index={5}>
                        <CurriculumTenedor data={hv_data} func_update={updateStatusHV} permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel>
                      {hv_data.data.trailer_asociado ? 
                        <TabPanel value={value} index={6}>
                          <CurriculumDoc case={3} dni={hv_data.data.trailer_asociado} identity={hv_data.data.identificacion} func_update={updateStatusHV} permiso={permisoEdit} rolSession={rol}/>
                        </TabPanel> : <><TabPanel value={value} index={6}>No hay trailer asociado</TabPanel></>
                      }
                      <TabPanel value={value} index={7}>
                        <CurriculumPropietario data={hv_data} func_update={updateStatusHV} permiso={permisoEdit} rolSession={rol}/>
                      </TabPanel>
                    </Box>
                  </Grid>
                </Paper>
              </Grid>
            </Dashboard>
          </>
        )
      }
    </>
  );
}