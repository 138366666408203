//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import API, { AXIOS } from '../environment/api';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import LoadingScreen from './LoadingScreen';
import { getDatabase, ref, get, child, onChildAdded, onChildChanged } from "firebase/database";
import firebaseApp from '../services/firebase';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import LoadingModal from "../components/LoadingModal"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const AvailablePage = () => {
  //Inicialización de datos
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_toast_red, setOpen_toast_red] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [DELETE, setDelete] = React.useState("");
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [Loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [load, setLoad] = React.useState(false);
  const [query, setQuery] = React.useState([]);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('cedula');

  //Funcion para el tipo de filtro
  const handleChangeSearchType = (event) => {
    setFilter('');
    setSearchType(event.target.value);
    setcompanies(query);
    if (event.target.value == 1) {
      setSearchTypes("cedula");
    };
    if (event.target.value == 2) {
      setSearchTypes("nombre");
    };
    if (event.target.value == 3) {
      setSearchTypes("placa");
    };
  };

  //Inicializando open & close modal  
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);

  //Funcion para resfrescar pagina
  const refresh = () => {
    openLoad();
    setFilter("");
    setPage(0);
    setRowsPerPage(10);
    //API get driver
    AXIOS.get(API.GET_DRIVER, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setQuery(response.data.data);
    });
  };
  const handleClose_Delete = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDelete(false);
  };
  //Funcion para cerrar toast
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };
  const handleClose_toast_red = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_red(false);
  };
  const closeViewOffices = () => setViewOffices(false);

  //Funcion para capturar datos del usuario a eliminar
  const openDeleteServices = (row) => {
    setDelete({
      id: row.id,
      nombre: (row.nombres) + (" ") + (row.apellidos)
    })
    setViewOffices(true);
  };

  //Funcion para eliminar servicio
  const DeleteServices = () => {
    //API Para eliminar servicio
    AXIOS.delete(API.DELETE_SERVICE + DELETE.id, {
    }).then((response) => {
      closeViewOffices();
      refresh();
    });
    closeViewOffices();
    refresh();
  };

  //Funcion para cargar data
  const loadPosts = () => {
    AXIOS.get(API.GET_DRIVER, {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setQuery(response.data.data);
    });
  }

  const getNotificationList = () => {

    AXIOS.get("https://api.atssalud.com/users-notifications", {
    }).then((response) => {
      // localStorage.setItem("contador",response.data.total);   
    });
  }

  //FUncion para filtrar datos en tabla
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    AXIOS.get(API.GET_DRIVER + "?page=" + (1 + newPage) + "&per_page=" + rowsPerPage, {
    }).then((response) => {
      setcompanies(response.data.data);
      setQuery(response.data.data);
    });
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    AXIOS.get(API.GET_DRIVER + "?page=" + (1 + page) + "&per_page=" + (event.target.value), {
    }).then((response) => {
      setcompanies(response.data.data);
      setQuery(response.data.data);
    });
  };

  //Funcion para acomular notificaciones
  const countNotications = () => {
    const dbRef = ref(getDatabase(firebaseApp));
    get(child(dbRef, `disponibles`)).then((snapshot) => {
      if (snapshot.exists()) {
        const obj = snapshot.val();
        localStorage.setItem("count", obj);
        console.log("Notifications count:", obj);
        localStorage.setItem("contador", "2");
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  //Pre-carga de apis
  React.useEffect(() => {
    //Inicializacion de notificaciones push
    const database = getDatabase(firebaseApp);
    const userNotiificationsRef = ref(database, 'disponibles');

    AXIOS.get(API.GET_DRIVER, {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setQuery(response.data.data);
      setLists(response.data.total);
    });

    //Activacion de notificaciones success
    onChildAdded(userNotiificationsRef, (data) => {
      // console.log('agregue uno nuevo',data.ref._repo.dataUpdateCount);
      if (data.ref._repo.dataUpdateCount >= 2) {
        loadPosts();
        setOpen_toast(true);
      }
    });
    //Activacion de notificaciones error
    onChildChanged(userNotiificationsRef, (data) => {
      // console.log('cambie el valor');
      loadPosts();
      setOpen_toast_red(true);
      // loadPosts();
      // countNotications();
      // getNotificationList();
      // setOpen_toast(true);
    });

  }, []);

  //Inicializando datos que trae la api de get
  function createData(identificacion, vehiculo_placa, trailer_placa, nombres, apellidos, origen, destino,
    celular, tipovehiculo, carroceria, id, departamento_origen, departamento_destino) {
    return {
      identificacion, vehiculo_placa, trailer_placa, nombres, apellidos, origen, destino,
      celular, tipovehiculo, carroceria, id, departamento_origen, departamento_destino
    };
  }

  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.identificacion, item.vehiculo_placa, item.trailer_placa, item.nombres, item.apellidos, item.origen, item.destino,
        item.celular, item.tipovehiculo, item.carroceria, item.id, item.departamento_origen, item.departamento_destino)
    )
  });

  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
        <LoadingScreen /> :
        <Dashboard title='Conductores Disponible'>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          <div>
            {/* Snackbar para mensaje de exito */}
            <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
              <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
                Tienes un nuevo conductor disponible
              </Alert>
            </Snackbar>
            {/* Snackbar para mensaje de cancelado */}
            <Snackbar open={open_toast_red} autoHideDuration={8000} onClose={handleClose_toast_red}>
              <Alert onClose={handleClose_toast} severity="error" sx={{ width: '100%' }}>
                Cancelado conductor con exito
              </Alert>
            </Snackbar>
          </div>
          {/* Modal para cancelar la disponibilidad */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2
            }}>
              <Typography id="modal-modal-title" style={{ textTransform: "uppercase" }} variant="h6" component="h2" align='center' >
                ¿Desea cancelar la disponibilidad de <spam style={{ color: "black" }}>{DELETE.nombre}</spam>?
              </Typography>
              <Container style={{ marginTop: 7 }}>
                <CssBaseline />
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={closeViewOffices}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ background: "gray" }}
                  >
                    No
                  </Button>
                  <div style={{ margin: 18 }}></div>
                  <Button type="submit" fullWidth variant="contained" onClick={DeleteServices}
                    sx={{ background: " #e35233" }}
                  > Si
                  </Button>
                </div>
              </Container>
            </Box>
          </Modal>
          {/* Menu de opciones */}
          <Grid item xs={12} md={8} lg={29}>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
              {/* Filtro de busqueda */}
              <div style={{ display: "flex", float: "right", margin: 15 }}>
                <TextField inputProps={{ style: { textTransform: 'lowercase' } }} id="filled-basic" label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} startIcon={<SearchIcon />} />
              </div>
              {/* Tipo de busqueda */}
              <div style={{ float: "right", marginTop: 28, marginRight: -5 }}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                  <Select
                    sx={{ minWidth: 160 }}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={1}
                    value={searchType}
                    label="Tipo de busqueda"
                    onChange={handleChangeSearchType}
                  >
                    <MenuItem value={1}>Cédula</MenuItem>
                    <MenuItem value={2}>Nombre</MenuItem>
                    <MenuItem value={3}>Placa</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* Boton de refrescar la pantalla */}
              <div style={{ float: "right", marginTop: 28, marginRight: 10 }}>
                <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
              {/* Tabla de datos */}
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">Cédula</TableCell>
                      <TableCell align="center">Nombres</TableCell>
                      <TableCell align="center">Placa</TableCell>
                      <TableCell align="center">Origen</TableCell>
                      <TableCell align="center">Destino</TableCell>
                      <TableCell align="center">Celular</TableCell>
                      <TableCell align="center">Vehiculo</TableCell>
                      <TableCell align="center">Carroceria</TableCell>
                      <TableCell align="center">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {<>{(searchTypes == "cedula") ? (<>
                        {query.filter((row) =>
                          row.identificacion.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{id + 1}</TableCell>
                            <TableCell align="center">{row.identificacion}</TableCell>
                            <TableCell align="center">{<>{row.nombres}{" "}{row.apellidos} </>}</TableCell>
                            <TableCell align="center">{row.vehiculo_placa}</TableCell>
                            <TableCell align="center">{<>{(row.origen == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</TableCell>
                            <TableCell align="center">{<>{(row.destino == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</TableCell>
                            <TableCell align="center">{row.celular}</TableCell>
                            <TableCell align="center">{row.tipovehiculo}</TableCell>
                            <TableCell align="center">{row.carroceria}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>
                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}
                      {<>{(searchTypes == "nombre") ? (<>
                        {query.filter((row) =>
                          row.nombres.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{id + 1}</TableCell>
                            <TableCell align="center">{row.identificacion}</TableCell>
                            <TableCell align="center">{<>{row.nombres}{" "}{row.apellidos} </>}</TableCell>
                            <TableCell align="center">{row.vehiculo_placa}</TableCell>
                            <TableCell align="center">{<>{(row.origen == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</TableCell>
                            <TableCell align="center">{<>{(row.destino == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</TableCell>
                            <TableCell align="center">{row.celular}</TableCell>
                            <TableCell align="center">{row.tipovehiculo}</TableCell>
                            <TableCell align="center">{row.carroceria}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>
                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}
                      {<>{(searchTypes == "placa") ? (<>
                        {query.filter((row) =>
                          row.vehiculo_placa.toLowerCase().includes(filter)
                        ).map((row, id) => (<>
                          <TableRow
                            key={row.id}
                            hover role="checkbox" tabIndex={-1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{id + 1}</TableCell>
                            <TableCell align="center">{row.identificacion}</TableCell>
                            <TableCell align="center">{<>{row.nombres}{" "}{row.apellidos} </>}</TableCell>
                            <TableCell align="center">{row.vehiculo_placa}</TableCell>
                            <TableCell align="center">{<>{(row.origen == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_origen}{", "}{row.origen}</>)}</>}</TableCell>
                            <TableCell align="center">{<>{(row.destino == null) ? (<>{"Cualquier destino"}</>) :
                              (<>{row.departamento_destino}{", "}{row.destino}</>)}</>}</TableCell>
                            <TableCell align="center">{row.celular}</TableCell>
                            <TableCell align="center">{row.tipovehiculo}</TableCell>
                            <TableCell align="center">{row.carroceria}</TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>
                          </TableRow>
                        </>)
                        )}
                      </>) :
                        null}</>}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              {<>{(rows == "") ?
                <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                  <Typography>NO HAY DATOS DISPONIBLES</Typography>
                </Stack> :
                ""}</>}
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={lists}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Dashboard>
      }
    </>);
}
export default AvailablePage;
