//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Tab from '@mui/material/Tab';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import CloseIcon from '@mui/icons-material/Close';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import API, { AXIOS } from '../environment/api';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import DirectionsIcon from '@mui/icons-material/Directions';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StraightIcon from '@mui/icons-material/Straight';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Select from '@mui/material/Select';
import BalanceIcon from '@mui/icons-material/Balance';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import LoadingScreen from './LoadingScreen';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingModal from "../components/LoadingModal";
import listadoCiudades from '../components/ListadoCiudades';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//Funcion para escribir solo numeros
const isInputNumber = (event) => {
  let ch = String.fromCharCode(event.which);
  if (!(/[0-9-,]/.test(ch))) {
    event.preventDefault();
  }
};

//Funcion para el tab panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AvailablePage = () => {
  //Funcion para inicializar los datos
  const [companies, setcompanies] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState("");
  const [filter, setFilter] = React.useState('');
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [open_viajes, setviajes] = React.useState(false);
  const [DELETE, setDelete] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [data1, setdata1] = React.useState("");
  const [data2, setdata2] = React.useState("");
  const [data3, setdata3] = React.useState("");
  const [datos1, setdatos1] = React.useState("");
  const [datos2, setdatos2] = React.useState("");
  const [datos3, setdatos3] = React.useState("");
  const [VEHICULOS2, setVehiculo] = React.useState([]);
  const [CARROCERIAS2, setCarroceria] = React.useState([]);
  const [RELATION, setRelation] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [open_alert, setAlert] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  //Funcion para abrir & cerrar modal
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);

  //Funcion para abrir mensaje de success  
  const handleClick_success = () => {
    setOpenSuccess(true);
  };

  //Funcion para cerrar mensaje de success
  const handleClose_success = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  //Funcion para abrir mensaje de error 
  const handleClick_error = () => {
    setOpenError(true);
  };

  //Funcion para cerrar mensaje de error
  const handleClose_error = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=` + (1 + newPage) + `&length=` + rowsPerPage + `&search={"value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=` + (1 + page) + `&length=` + (+event.target.value) + `&search={"value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
    setPage(page);
  };

  //Funcion para filtrar datos en tabla
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=` + rowsPerPage + `&search={"value":"` + (event.target.value) + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsTotal);
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Funcion back panel
  const back2 = () => {
    setValue(0);
  };
  const back3 = () => {
    setValue(1);
  };
  const back4 = () => {
    setValue(2);
  };

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  //Pre-carga de apis
  React.useEffect(() => {
    AXIOS.get(API.VEHICLE_TYPE, {
    }).then((response) => {
      setVehiculo(response.data.vehiculos);
      setCarroceria(response.data.carrocerias);
      setRelation(response.data.relacion);
    });
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=10&search={"value":""}`, {
    }).then((response) => {
      setLoading(false);
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
  }, []);

  //Funcion para crear servicio
  const submitCreateService = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const data8 = new FormData(event.currentTarget);
    if (value == 0) {
      setdata1(data);

      //Validacion de formulario
      if (data.get('origin') == "" | data.get('destiny') == "") {
        setAlert(true);
      } else if (data.get('origin') < data.get('effective_date')) {
        setAlert(true);
      } else {
        let origenid = listadoCiudades.find(({ nombre }) => nombre === data.get('origin'));
        let destinoid = listadoCiudades.find(({ nombre }) => nombre === data.get('destiny'));

        //Captura de datos del formulario
        setdatos1({
          origin: data.get('origin'),
          origin_id: origenid.id,
          destiny: data.get('destiny'),
          destiny_id: destinoid.id,
          upload_date: data.get('upload_date'),
          effective_date: data.get('effective_date'),
        })
        if (data.get('origin') != "" && data.get('destiny') != "" && data.get('upload_date') != "" && data.get('effective_date') != "") {
          setValue(1);
        } else {
          setAlert(true);
        }
      }
      if (value == 1) {
        //Captura de datos del formulario
        setdata2(data);
        setdatos2({
          high: data.get('high'),
          width: data.get('width'),
          length: data.get('length'),
          type_weight_length: data.get('type_weight_length'),
          weight: data.get('weight'),
          type_weight: data.get('type_weight'),
          client: data.get('client'),
          product: data.get('product'),
          volume: data.get('volume'),
          type_weight_volume: data.get('type_weight_volume'),
          massive: data.get('massive'),
          consolidable: data.get('consolidable'),
          observacion: data.get('observacion'),
        })
        //Validacion de formulario
        if (data.get('massive') != "" && data.get('weight') != "" && data.get('client') != "" && data.get('product') != "" && data.get('type_weight') != "" && data.get('consolidable') != "") {
          setValue(2);
        } else {
          setAlert(true);
        }
      }
    }
    if (value == 2) {
      setdata3(data);
      //Validacion de formulario  
      if (data.get('flete') != "" && data.get('vehicle_type') != "" && data.get('body_type') != "") {
        const current = new Date();
        const time = current.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false
        });

        let relaciones = RELATION.find(({ carroceria, tipo_vehiculo }) => carroceria === data.get('body_type') && tipo_vehiculo === data.get('vehicle_type'));
        let relaciones2 = RELATION.find(({ carroceria, tipo_vehiculo }) => carroceria === "CUALQUIERA" && tipo_vehiculo === data.get('vehicle_type'));

        //Data final que se envia en el POST de creacion
        if (relaciones.tipovehiculocarrocerias_id == relaciones2.tipovehiculocarrocerias_id) {
          AXIOS.post(API.CREATE_VIAJES, {
            origen: datos1.origin_id,
            destino: datos1.destiny_id,
            fechacarga: datos1.upload_date,
            fechavigencia: datos1.effective_date,
            dimalto: datos2.high,
            dimlargo: datos2.length,
            dimancho: datos2.width,
            cubicaje: datos2.volume,
            peso: datos2.weight,
            unidadlongitud_id: datos2.type_weight_length,
            unidadpeso_id: datos2.type_weight,
            unidadvolumen_id: datos2.type_weight_volume,
            costo: data.get('flete'),
            "producto": datos2.product,
            "producto_codigo": "",
            "cliente": datos2.client,
            "cliente_codigo": "",
            observacion: datos2.observacion,
            consolidable: datos2.consolidable,
            gestionado: localStorage.getItem("user_id"),
            tipovehiculocarroceria: [relaciones.tipovehiculocarrocerias_id],
            horacarga: time,
          }).then((response) => {
            setViewOffices(false);
            refresh();
            setdatos1({
              origin: "",
              origin_id: "",
              destiny: "",
              destiny_id: "",
              upload_date: "",
              effective_date: "",
            })
            setdatos2({
              high: "",
              width: "",
              length: "",
              type_weight_length: "",
              weight: "",
              type_weight: "",
              client: "",
              product: "",
              volume: "",
              type_weight_volume: "",
              massive: "",
              consolidable: "",
            })
            setValue(0);
          });
        } else {
          AXIOS.post(API.CREATE_VIAJES, {
            origen: datos1.origin_id,
            destino: datos1.destiny_id,
            fechacarga: datos1.upload_date,
            fechavigencia: datos1.effective_date,
            dimalto: datos2.high,
            dimlargo: datos2.length,
            dimancho: datos2.width,
            cubicaje: datos2.volume,
            peso: datos2.weight,
            unidadlongitud_id: datos2.type_weight_length,
            unidadpeso_id: datos2.type_weight,
            unidadvolumen_id: datos2.type_weight_volume,
            costo: data.get('flete'),
            "producto": datos2.product,
            "producto_codigo": "",
            "cliente": datos2.client,
            "cliente_codigo": "",
            observacion: datos2.observacion,
            consolidable: datos2.consolidable,
            gestionado: localStorage.getItem("user_id"),
            tipovehiculocarroceria: [relaciones.tipovehiculocarrocerias_id, relaciones2.tipovehiculocarrocerias_id],
            horacarga: time,
          }).then((response) => {
            setViewOffices(false);
            refresh();
            handleClick_success();
            setdatos1({
              origin: "",
              origin_id: "",
              destiny: "",
              destiny_id: "",
              upload_date: "",
              effective_date: "",
            })
            setdatos2({
              high: "",
              width: "",
              length: "",
              type_weight_length: "",
              weight: "",
              type_weight: "",
              client: "",
              product: "",
              volume: "",
              type_weight_volume: "",
              massive: "",
              consolidable: "",
            })
            setValue(0);
          });
        }
      } else {
        setAlert(true);
      }
    }
  };

  //Funcion para abrir modal de formulario
  const viewDataOffices = () => {
    setViewOffices(true);
  };

  //Funcion para cerrar modal de formulario 
  const closeViewOffices = () => {
    setViewOffices(false)
    setdatos1({
      origin: "",
      origin_id: "",
      destiny: "",
      destiny_id: "",
      upload_date: "",
      effective_date: "",
    })
    setdatos2({
      high: "",
      width: "",
      length: "",
      type_weight_length: "",
      weight: "",
      type_weight: "",
      client: "",
      product: "",
      volume: "",
      type_weight_volume: "",
      massive: "",
      consolidable: "",
    })
    setValue(0);
  };

  //Funcion para abir modal delete
  const closeviajes = () => setviajes(false);
  const openDeleteServices = (row) => {
    setDelete({
      id: row.id,
      nombre: (row.nombres) + (" ") + (row.apellidos)
    })
    setviajes(true);
  };

  //Funcion para eliminar servicio
  const DeleteServices = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //Validacion para elegir un motivo
    if (!data.get('radio-buttons-group')) {
      setAlert(true);
      console.log("SIN BOTON")
    } else {
      if (data.get('radio-buttons-group') != "motivo otro") {
        AXIOS.post("http://izicarga.logicem.com/api/servicios/busqueda_de_vehiculo/" + DELETE.id + "/reason?user_id=" + localStorage.getItem("user_id_LOGICEM"), {
          motivo: data.get('radio-buttons-group'),
        }).then((response) => {
          closeviajes();
          refresh();
          handleClick_error();
        });
      }
    }

    //Validacion si no elijio algun motivo
    if (data.get('radio-buttons-group') == "motivo otro" && data.get('otro_motivo') == "") {
      console.log("SIN MOTIVO")
      setAlert(true);
    } else {
      if (data.get('otro_motivo') != "" && data.get('radio-buttons-group') == "motivo otro") {
        AXIOS.post("http://izicarga.logicem.com/api/servicios/busqueda_de_vehiculo/" + DELETE.id + "/reason?user_id=" + localStorage.getItem("user_id_LOGICEM"), {
          motivo: data.get('otro_motivo'),
        }).then((response) => {
          closeviajes();
          refresh();
          handleClick_error();
        });
      }
    }
  };

  //Funcion para resfrescar data
  const refresh = () => {
    openLoad();
    setFilter("");
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=10&search={"value":""}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
  };

  //Funcion para capturar año
  const [age, setAge] = React.useState('');
  const handleChangeg = (event) => {
    setAge(event.target.value);
  };

  //Inicializando datos que trae la api de get
  function createData(empresa, departamento_origen, localidad_origen, departamento_destino, localidad_destino, cliente, producto, costo, id, fechacarga) {
    return { empresa, departamento_origen, localidad_origen, departamento_destino, localidad_destino, cliente, producto, costo, id, fechacarga };
  }

  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.empresa, item.departamento_origen, item.localidad_origen, item.departamento_destino, item.localidad_destino, item.cliente, item.producto, item.costo, item.id, item.fechacarga)
    )
  });

  //Funcion para seleccionar la fecha actual
  const hoy = new Date();
  function formatoFecha(fecha, formato) {
    //
  }
  const fecha_now = hoy.toLocaleDateString();
  formatoFecha(hoy, 'dd/mm/yy');


  //Data select para la unidad de peso
  const unidad_peso = [
    { id: 1, nombre: "KILOS", abreviatura: "KG" },
    { id: 2, nombre: "TONELADAS", abreviatura: "TON" },
    { id: 3, nombre: "GALON", abreviatura: "GALON" }
  ]
  //Data selecet para la unidad de longitud
  const unidad_longitud = [
    { id: 1, nombre: "METROS", abreviatura: "MTS" }
  ]
  //Data select para eleccion de consolidable
  const consolidable = [
    { id: 1, title: "SI CONSOLIDABLE" },
    { id: 2, title: "NO CONSOLIDABLE" }
  ]
  //Data select para eleccion de masivo     
  const masivo = [
    { id: 1, title: "SI MASIVO" },
    { id: 0, title: "NO MASIVO" }
  ]

  //Funcion para cerrar select
  const closeAlert = () => setAlert(false);

  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard title='Viajes Disponibles'>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Mensaje de extio */}
          <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleClose_success}>
            <Alert onClose={handleClose_success} severity="success" sx={{ width: '100%' }}>
              Viaje creado con exito
            </Alert>
          </Snackbar>
          {/* Mensaje de eliminado con exito       */}
          <Snackbar open={openError} autoHideDuration={8000} onClose={handleClose_error}>
            <Alert onClose={handleClose_error} severity="error" sx={{ width: '100%' }}>
              Viaje eliminado con exito
            </Alert>
          </Snackbar>
          {/* Modal validacion de datos, datos incompletos en el formulario */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                Por favor complete todos los campos obligatorios*
              </Typography>
            </Box>
          </Modal>
          {/* Modal creacion de servicio */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box >
              <Box component="form" onSubmit={submitCreateService} noValidate
                autoComplete="off"
                sx={{
                  // backgroundColor: (theme) =>
                  //   theme.palette.mode === 'light'
                  //     ? theme.palette.grey[100]
                  //     : theme.palette.grey[900],
                  flexGrow: 1,
                  background: "white",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  overflow: 'auto',
                  borderRadius: 8,
                  boxShadow: 24,
                  p: 1,
                }}>
                <Button style={{ float: "right" }} onClick={closeViewOffices}><CloseIcon /></Button>
                <Typography style={{ textAlign: "center", marginLeft: 80 }} component="h3" color="#e35233"><b>Publicar Servicio</b></Typography>
                <Box sx={{ width: '100%' }} >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} aria-label="basic tabs example" >
                      <Tab label="Logistica de la Carga" {...a11yProps(0)} onClick={back2} />
                      <Tab label="Características de la Carga" {...a11yProps(1)} onClick={back3} />
                      <Tab label="Tipo de Vehiculo" {...a11yProps(2)} onClick={back4} />
                    </Tabs>
                  </Box>
                  {/* Primer tab del formulario */}
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          defaultValue={datos1.origin}
                          disableClearable
                          options={listadoCiudades.map((option) => option.nombre)}
                          renderInput={(params) => (
                            <TextField
                              // helperText="Complete el campo"
                              {...params}
                              id="origin" autoFocus size="small"
                              name="origin" required
                              label="Origen (Campo obligatorio)"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          defaultValue={datos1.destiny}
                          disableClearable
                          options={listadoCiudades.map((option) => option.nombre)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="destiny" autoFocus size="small"
                              name="destiny" required
                              label="Destino (Campo obligatorio)"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="upload_date" required type="date" defaultValue={datos1.upload_date}
                          fullWidth id="upload_date" label="Fecha Carga (Campo obligatorio)" autoFocus size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarMonthIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="effective_date" required type="date" defaultValue={datos1.effective_date}
                          fullWidth id="effective_date" label="Fecha Vigencia (Campo obligatorio)" autoFocus size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarMonthIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </Grid>
                    {/* <Button    onClick={closeViewOffices} type="submit" variant="contained" 
    sx={{ mt: 3, mb: 2, background:"gray", float:"left", align:'left', width:"40%", marginLeft:"4%"}}>
    Cancelar</Button>
  {/* <div style={{margin:18}}></div> */}
                    {/* <Button type="submit" variant="contained" size="midum"
    sx={{ mt: 3, mb: 2, background:" #e35233", float:"right", align:'right', width:"40%", marginRight:"4%"}}> 
    Siguiente</Button> */}
                    <Button onClick={closeViewOffices} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Cancelar</Button>
                    {/* <div style={{margin:18}}></div> */}
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Siguiente</Button>
                  </TabPanel>
                  {/* Segundo tab del formulario */}
                  <TabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="high" size="small" defaultValue={datos2.high}
                          fullWidth id="high" label="Alto" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <StraightIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="width" size="small" defaultValue={datos2.width}
                          fullWidth id="width" label="Ancho" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SyncAltIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="length" size="small" defaultValue=""
                          fullWidth id="length" label="Largo" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <OpenInFullIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida</InputLabel>
                          <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight_length"
                            name="type_weight_length"
                            defaultValue={datos2.type_weight_length}
                            label="Unidad Medida"
                            onChange={handleChangeg}
                          >{
                              unidad_longitud.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="weight" required defaultValue={datos2.weight}
                          fullWidth id="weight" size="small" label="Peso (Campo obligatorio)" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BalanceIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight"
                            name="type_weight"
                            defaultValue={datos2.type_weight}
                            label="Unidad Medida (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              unidad_peso.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>

                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="client" required
                          fullWidth id="client" label="Cliente (Campo obligatorio)" autoFocus defaultValue={datos2.client}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="product" required
                          fullWidth id="product" label="Producto (Campo obligatorio)" autoFocus defaultValue={datos2.product}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="volume"
                          fullWidth id="volume" label="Volumen" autoFocus defaultValue={datos2.volume} onKeyPress={isInputNumber} a
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ViewInArIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida</InputLabel>
                          <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight_volume"
                            name="type_weight_volume"
                            defaultValue={datos2.type_weight_volume}
                            label="Unidad Medida"
                            onChange={handleChangeg}
                          >{
                              unidad_longitud.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Masivo (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="massive"
                            name="massive"
                            defaultValue={datos2.massive}
                            label="Masivo (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              masivo.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.title}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Consolidable (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="consolidable"
                            name="consolidable"
                            defaultValue={datos2.consolidable}
                            label="Consolidable (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              consolidable.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.title}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={12}>
                        <TextField size="medium" autoComplete="given-name" name="observacion"
                          fullWidth id="observacion" label="Observación" autoFocus defaultValue={datos2.observacion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </Grid>
                    <Button onClick={back2} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Atrás</Button>
                    {/* <div style={{margin:18}}></div> */}
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Siguiente</Button>
                  </TabPanel>
                  {/* TERCER TAB DE FORMULARIO */}
                  <TabPanel value={value} index={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="flete" required onKeyPress={isInputNumber}
                          fullWidth id="flete" label="Valor de flete (Campo obligatorio)" autoFocus type="number"
                          pattern="[0-9]*"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalAtmIcon />
                              </InputAdornment>
                            ),
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Tipo Vehiculo (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="vehicle_type"
                            name="vehicle_type"
                            defaultValue={datos3.vehicle_type}
                            label="Tipo Vehiculo (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              VEHICULOS2.map(item => {
                                return (
                                  <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Tipo Carroceria (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="body_type"
                            name="body_type"
                            defaultValue={datos3.body_type}
                            label="Tipo Carroceria (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              CARROCERIAS2.map(item => {
                                return (
                                  <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Button onClick={back3} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Atrás</Button>
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Publicar</Button>
                  </TabPanel>
                  <Box component="form" onSubmit={"submitCreateService"} noValidate sx={{ mt: 1 }}>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Button onClick={closeViewOffices} type="submit" variant="contained" size="medium"
    sx={{ mt: 3, mb: 2, background:"gray", marginTop:"12%",float:"left", align:'left', width:"2%", marginLeft:"49%"}}>
    x</Button>    */}
          </Modal>
          <Modal
            open={open_viajes}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿Desea cancelar el viaje?
              </Typography>
              {/* <div style={{float: "rigth"}}>
            {(validatorErrors == true)?
           (validatorErrors ==="")?null:
                    <Alert severity="error">{errorsMessage}</Alert>: ""
                }              
            </div> */}
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={DeleteServices} noValidate sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Motivo de cancelación</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Se cancelo el servicio"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel id="Se cancelo el servicio" value="Se cancelo el servicio" control={<Radio />} label="Se cancelo el servicio" />
                      <FormControlLabel id="Se Contrato el transportista por otro medio" value="Se Contrato el transportista por otro medio" control={<Radio />} label="Se Contrato el transportista por otro medio" />
                      <FormControlLabel value="motivo otro" id="otro motivo" control={<Radio />} label="Otro motivo:" />
                      {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                      <Grid item xs={2} sm={12} >
                        <TextField size="medium" autoComplete="given-name" name="otro_motivo"
                          fullWidth id="motivo otro" autoFocus
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </RadioGroup>
                  </FormControl>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeviajes}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "gray" }}
                    >
                      No
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 2, background: " #e35233" }}
                    > Si
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          <Grid item xs={12} md={8} lg={29}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: "auto",
              }}
            >
              <div>
                {/* TABLA DE DATOS  */}
                <div style={{ margin: 10, float: 'right' }}>
                  <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                </div>
                <Button type="submit" fullWidth variant="contained" onClick={() => viewDataOffices()}
                  sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}><DirectionsIcon />Publicar Servicio
                </Button>
                <Button type="submit" fullWidth variant="contained" onClick={() => refresh()}
                  sx={{ mt: 1, mb: 1, float: "right", background: "#FF5733", margin: 2, width: 0 }}><RefreshIcon />
                </Button>
                <div style={{ marginTop: -5 }}></div>
                {/* 
<div style={{margin:8,float:'right'}}>

                                <TextField id="filled-basic" label="Buscar" variant="filled"  name="filtro" onChange={ev=>setFilter(ev.target.value)}/>
                            </div> */}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Origen</TableCell>
                        <TableCell align="center">Destino</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Producto</TableCell>
                        <TableCell align="center">Flete</TableCell>
                        <TableCell align="center">Fecha Cargue</TableCell>
                        <TableCell align="center">Publicado Por</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, id) => (
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                            (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                            (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                            (<>{row.cliente}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                            (<>{row.producto}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                            (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.fechacarga == null | row.fechacarga == "") ? (<>{"-"}</>) :
                            (<>{row.fechacarga}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                            (<>{row.empresa}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                          </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
          </Grid>
        </Dashboard>
      }</>
  );
}

export default AvailablePage;