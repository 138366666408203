import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthContext';
import Navigator from './navigation/navigator';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppState= ({children}) =>{
	return(
		<AuthProvider>
		  {children}
		</AuthProvider>
	)
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppState>
        <Navigator/>
      </AppState>
    </BrowserRouter>
  </React.StrictMode>
);
