//Importacion de complementos
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import API, { AXIOS } from '../environment/api';
import imgNotFound from '../assets/img/logo.png';

//Estilo de modals
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function WindowModal(props) {
  //Inicializacion de datos
  const [open, setOpen] = React.useState(false);
  const [imagen, setImagen] = React.useState(props.image);
  const [imagen2, setImagen2] = React.useState();
  const [id, setId] = React.useState(props.id);
  const [exitSave, setExitSave] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    console.log('recargue modal')
  }, [imagen2])

  const saveImage = async (type, ref, file) => {
    //inicializacion de vehiculo
    let dni = localStorage.getItem('dni')
    let placa = localStorage.getItem('placa')
    let trailer = localStorage.getItem('trailer')
    console.log({ type })
    const form = new FormData();
    form.append('file', file);
    form.append('type', Number(type));
    console.log({ form })
    console.log({ ref })

    //Dependiendo la ref se sube el tipo de documento    
    if (ref === '1') {
      try {
        console.log({ placa })
        const resp = await AXIOS.post(API.POST_DOCUMENT_VEHICULO(placa), form, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        console.log({ resp })
        setExitSave(true)
      } catch (error) {
        console.log({ error })
      }
    }
    if (ref === '2') {
      try {
        const resp = await AXIOS.post(API.POST_DOCUMENT_CONDUCTOR(dni), form, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        setExitSave(true)
        console.log({ resp })
      } catch (error) {
        console.log({ error })
      }
    }
    if (ref === '3') {
      try {
        const resp = await AXIOS.post(API.POST_DOCUMENT_TRAILER(trailer), form, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        setExitSave(true)
        console.log({ resp })
      } catch (error) {
        console.log({ error })
      }
    }
  }

  //Funcion para subir archivo
  const subirArchivo = async (e) => {
    let id = localStorage.getItem('id')
    let dni = localStorage.getItem('dni')
    console.log({ id })
    let ref = localStorage.getItem('referencia')
    let trailer = localStorage.getItem('trailer')
    console.log('localst', trailer)

    let validId = false
    if (id === '10' || id === '11') {
      validId = true
    }
    if (trailer === null && validId) {
      let item = '0'
      props.save(item)
    } else {
      let divRef = ref.split('-')
      //mostrar la imagen
      let file = document.getElementById("contained-button-file").files[0];
      await saveImage(divRef[0], divRef[1], file)
      const reader = new FileReader();
      reader.onload = function (e) {
        console.log('este es el id', id)
        document.getElementById(`img-${id}`).src = e.target.result; // Renderizamos la imagen
      }
      reader.readAsDataURL(file)
      let item = '1'
      props.save(item)

      localStorage.removeItem('id')
    }
  };

  const saveData = () => {
    localStorage.setItem('id', props.id)
    localStorage.setItem('referencia', props.referencia)
  }

  return (
    <div>
      {/* Imagen subida */}
      <Grid container justifyContent="center" style={{ display: "center" }}>
        <Typography component={'span'} variant={'subtitle2'}>
          {props.title}
        </Typography>
      </Grid>
      <Button onClick={handleOpen}>
        {
          (props.image) ?
            <img id={`img-${props.id}`} src={(imagen2) ? imagen2 : imagen} style={{ width: 250, height: 250, }} alt="Imagen no cargada!" />
            : <div style={{ padding: '85px 85px 85px 85px' }}> <img id={`img-${props.id}`} src={imgNotFound} style={{ width: 80, height: 80, }} alt="Imagen no encontrada" /> </div>
        }
        {/* <img id ='prueba' style={{width: 250,height: 250,}} src={(imagen)? imagen :'http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png'}/>      */}
      </Button>
      <input
        type="file"
        accept="image/*"
        // multiple
        style={{ display: 'none' }}
        id="contained-button-file"
        onChange={(e) => subirArchivo(e.target.files)}
      />
      <Grid container justifyContent="center" onClick={() => saveData()} style={{ display: "center", marginBottom: 10 }}>
        <label htmlFor="contained-button-file">
          <Button variant="contained" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'grey' }} component="span">
            Subir imagen
          </Button>
        </label>
      </Grid>
      {/* Imagen vacia */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            (props.image) ?
              <img id={`#img-${props.id}`} src={imagen} style={{ width: '100%', height: '100%', }} alt="Imagen no cargada" />
              : <div style={{ padding: '85px 85px 85px 85px' }}> <img id={`img-${props.id}`} src={imgNotFound} style={{ width: 80, height: 80, }} alt="Imagen no encontrada" /> </div>
          }
        </Box>
      </Modal>
    </div>
  );
}