//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Button from '@mui/material/Button';
import { Box, Paper, TextField } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import MessageIcon from '@mui/icons-material/Message';
import Grid from '@mui/material/Grid';
import API, { AXIOS } from '../environment/api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingModal from "../components/LoadingModal"
import '../export-btn.css'
import DescriptionIcon from '@mui/icons-material/Description';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NoticePage = () => {
  //Funcion para inicializar los datos
  const [archivos, setArchivos] = React.useState(null);
  const [dataTitle, setTitle] = React.useState('');
  const [dataDescription, setDescription] = React.useState('');
  const [dataContent, setContent] = React.useState('');
  const [dataImage, setImage] = React.useState('');
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  //Funcion para abir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para abir modal de carga  
  const closeLoad = () => setLoad(false);
  //Funcion para cerrar modal de alerta
  const closeAlert = () => setAlert(false);
  //Funcion para formatear data  
  const resetData = () => {
    setTitle('');
    setDescription('');
    setContent('');
  };
  //Funcion para subir archivos e imagenes
  const subirArchivos = (e) => {
    setArchivos(e);
    const d = new FormData();
    for (let index = 0; index < e.length; index++) {
      d.append("file", e[index].name);
      d.append("type", Number(1));
    }
    setImage(d);
    // console.log(dataImage);
  }
  //Funcion para cerrar notificacion
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };
  //Funcion para almacenar y publicar datos del formulario 
  const textConst = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (archivos == null) {
      setAlert(true);
      seterrorsMessage("Por favor subir una imagen");
    } else {
      const f = new FormData();

      for (let index = 0; index < archivos.length; index++) {
        f.append("file", archivos[index]);
        f.append("titulo", data.get('title'));
        f.append("descripcion", data.get('description'));
        f.append("user_id", 21130);
      }
      openLoad();
      AXIOS.post(API.NOTICE, f, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        closeLoad();
        setOpen_toast(true);
        resetData();
      }).catch((error) => {
        closeLoad();
        setAlert(true);
        seterrorsMessage(error.response.data.messages[0]);
      });
    };
  };

  return (
    <Dashboard title='Noticias'>
      <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <LoadingModal />
      </Modal>
      {/* Modal de alerta */}
      <Modal
        open={open_alert}
        onClose={closeAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 4,
          boxShadow: 24,
          p: 1,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
            {/* Por favor complete todos los campos obligatorios* */}
            {errorsMessage}
          </Typography>
        </Box>
      </Modal>
      {/* Notificaion de exito */}
      <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
        <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
          Noticia subida con exito
        </Alert>
      </Snackbar>
      {/* Formulario de noticia */}
      <Box component="form" onSubmit={textConst}>
        <Button fullWidth variant="contained" type="submit"
          sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}>PUBLICAR
        </Button>
        <Grid container direction="row" rowSpacing={1}>
          <Grid
            container
            direction="column"
            xs={4}
          >
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
              <div style={{ alignItems: 'center' }}>
                <input className='export-btn' type="file" fullWidth variant="contained" name="files" onChange={(e) => subirArchivos(e.target.files)} />
              </div>
            </Paper>
          </Grid>
          <Grid xs={8}>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
              <Grid container direction="row" >
                <Grid xs={6} >
                  <div style={{ margin: 20, minWidth: '1000%' }}>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <FormatQuoteIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        defaultValue={dataTitle}

                        label="Título"
                        name="title"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10, width: '16%' }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <DescriptionIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        defaultValue={dataDescription}
                        id="standard-search"
                        name="description"
                        label="Descripción"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10, width: '16%' }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <MessageIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        defaultValue={dataContent}
                        id="standard-multiline-static"
                        name="content"
                        label="Contenido"
                        multiline
                        rows={6}
                        variant="standard"
                        style={{ marginRight: 10, width: '16%' }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Dashboard>
  );
}
export default NoticePage;