//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Tab from '@mui/material/Tab';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import CloseIcon from '@mui/icons-material/Close';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import API, { AXIOS } from '../environment/api';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import DirectionsIcon from '@mui/icons-material/Directions';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StraightIcon from '@mui/icons-material/Straight';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Select from '@mui/material/Select';
import BalanceIcon from '@mui/icons-material/Balance';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import LoadingScreen from './LoadingScreen';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LoadingModal from "../components/LoadingModal"
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import '../export-btn.css'
import IosShareIcon from '@mui/icons-material/IosShare';
import EXCEL from '../assets/formato_servicios_excel.xlsx'
import { CopyDataToClipboard } from "../helpers/CopyTableToClipboard";
import Collapse from '@mui/material/Collapse';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//Headers de tabla
const headers = [
  "#",
  "Publicaod por",
  "Origen",
  "Destino",
  "Cliente",
  "Placa",
  "Producto",
  "Masivo",
  "Costo",
  "Saldo",
  "Fecha registro",
  "Empresa",
  "Interesados",
];
//Funcion para input sea solo numerico
const isInputNumber = (event) => {
  let ch = String.fromCharCode(event.which);
  if (!(/[0-9-,]/.test(ch))) {
    event.preventDefault();
  }
};
//Funcion para configuracion de tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ServicesPage = () => {
  const [companies, setcompanies] = React.useState([]);
  const [busquedaVehiculoData, setBusquedaVehiculoData] = React.useState(null);
  const [totalPage, setTotalPage] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [open_viajes, setviajes] = React.useState(false);
  const [DELETE, setDelete] = React.useState("");
  const [dataCity_o, array_city_o] = React.useState([]);
  const [dataCity_d, array_city_d] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [data1, setdata1] = React.useState("");
  const [data2, setdata2] = React.useState("");
  const [data3, setdata3] = React.useState("");
  const [datos1, setdatos1] = React.useState("");
  const [datos2, setdatos2] = React.useState("");
  const [datos3, setdatos3] = React.useState("");
  const [VEHICULOS2, setVehiculo] = React.useState([]);
  const [CARROCERIAS2, setCarroceria] = React.useState([]);
  const [RELATION, setRelation] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [open_alert, setAlert] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const componentRef = React.useRef();
  const [dataMessage, setMessage] = React.useState(false);
  const [openSuccessC, setOpenSuccessC] = React.useState(false);
  const [statusU, setStatusU] = React.useState('');
  const [captData, setCapData] = React.useState('');
  const [openPUT, setOpenPUT] = React.useState(false);

  // ------------------------------------------------
  const [generalError, setGeneralError] = React.useState(false);
  const [messageResponseError, setMessageResponseError] = React.useState("Ha ocurrido un error con los datos proporcionados...");
  const handleOpenError = () => {
    setGeneralError(true);
  };
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setGeneralError(false);
  };
  // ------------------------------------------------


  //Funcion para abrir modal de cambio estado de viaje
  const handleOpenPUT = () => {
    setOpenPUT(true);
  };
  //Funcion para cerrar modal de cambio estado de viaje
  const handleClosePUT = () => {
    setOpenPUT(false);
  };
  //Funcion para estilo de modals
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  //Funcion para capturar id
  const capt_data = (row) => {
    setCapData(row.id);
  };
  //Funcion para capturar estado del viaje
  const handleChangeStatus = (event) => {
    setStatusU(event.target.value);
    handleOpenPUT();
  };
  //Funcion para cambiar estado de viaje
  const put_estado = () => {
    AXIOS.put(API.PUT_STATUS + captData + `/status`, {
      "status": statusU
    }).then((response) => {
      console.log("FECTCHING SERVICES... ", response.data)
      refresh();
      handleClosePUT();
    });
  };
  //funcion para copiar contenido de tabla
  const handleClickCopyToClipboard = (headers, array, attributes) => {
    CopyDataToClipboard(headers, array, attributes, () => {
      setMessage("Tabla copiada");
      setOpenSuccessC(true);
      setTimeout(() => {
        setOpenSuccessC(false);
      }, 2000)
    });
  };
  //Funcion para cerrar notificacion de exito
  const handleClose_successC = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessC(false);
  };
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para abrir modal de carga  
  const closeLoad = () => setLoad(false);
  //Funcion para abrir notificacion de viaje con exito
  const handleClick_success = () => {
    setOpenSuccess(true);
  };
  //Funcion para cerrar notificacion de viaje con exito
  const handleClose_success = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };
  //Funcion para abrir notificacion de error  
  const handleClick_error = () => {
    setOpenError(true);
  };
  //Funcion para cerrar notificacion de error  
  const handleClose_error = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };
  //Funcnon para redirigir a pantalla de interesado 
  const openInteresado = (row) => {
    localStorage.setItem("id_interesado", row.id);
    linkTo('/interesadobusqueda')
  };
  //Funcion para imprimir PDF
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Viajes publicados',
    onAfterPrint: () => alert('Print success')
  })
  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=` + (1 + newPage) + `&length=` + rowsPerPage + `&search={"value":"${filter}"}&service_status=${getFilterTypeByTab()}`, {
    }).then((response) => {
      console.log("FECTCHING SERVICES TYPE 1 ", response.data)
      closeLoad();
      setcompanies(response.data.data);
      setBusquedaVehiculoData(response.data.busqueda_vehiculo);
      setTotalPage(response.data.recordsFiltered);
    });
  };
  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=` + (1 + page) + `&length=` + (+event.target.value) + `&search={"value":"${filter}"}&service_status=${getFilterTypeByTab()}`, {
    }).then((response) => {
      console.log("FECTCHING SERVICES TYPE 2 ", response.data)
      closeLoad();
      setcompanies(response.data.data);
      setBusquedaVehiculoData(response.data.busqueda_vehiculo);
      setTotalPage(response.data.recordsFiltered);
    });
    setPage(page);
  };
  //Funcion para capturar posicion del tab
  const handleChange = (event, newValue) => {
    console.log("CAMBIO DE TAP ", newValue, " =z ", filter);
    setFilter("");
    setValue(newValue);
  };
  //Funcion para estar en el 1tab
  const back2 = () => {
    setValue(0);
  };
  //Funcion para estar en el 2tab
  const back3 = () => {
    setValue(1);
  };
  //Funcion para estar en el 3tab
  const back4 = () => {
    setValue(2);
  };
  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }
  //Funcion para cargar cliente SAP
  // const ClientChange = (event) => {
  //     AXIOS.get("http://168.197.69.85:8080/izicarga/logicem-api/buscar-cliente",{
  //       search:event.target.value
  //     }).then((response) => {
  //       setClientSAP(response.data);  
  //     }).catch ((error) => {
  //       console.log({ error })
  //     });
  //     // setCity(event.target.value);  
  //   };

  const CityOriginChangeO = (event) => {
    AXIOS.get(API.GET_LOCALIDADES + event.target.value, {
    }).then((response) => {
      array_city_o(response.data);
    }).catch((error) => {
      console.log({ error })
    });
  };

  const CityOriginChangeD = (event) => {
    AXIOS.get(API.GET_LOCALIDADES + event.target.value, {
    }).then((response) => {
      array_city_d(response.data);
    }).catch((error) => {
      console.log({ error })
    });
  };
  React.useEffect(() => {
    //Pre-carga de apis
    AXIOS.get(API.VEHICLE_TYPE, {
    }).then((response) => {
      setVehiculo(response.data.vehiculos);
      setCarroceria(response.data.carrocerias);
      setRelation(response.data.relacion);
      setLoading(false);
    });

    //   AXIOS.get(API.GET_VIAJES_PUBLICADOS+`?start=1&length=10&search={"value":""}`,{
    //   }).then((response) => {
    //     if(response){
    //       console.log("FECTCHING SERVICES TYPE 3 ",response.data)
    //       setLoading(false);  
    //     }
    //   setcompanies(response.data.data);
    // setBusquedaVehiculoData(response.data.busqueda_vehiculo);
    //   setTotalPage(response.data.recordsFiltered);
    //   console.log("prueba_data",response.data.recordsFiltered)
    // });
  }, []);

  // ---------------------------------------------------------------------------------------------

  //Funcion para crear servicio
  const submitCreateService = (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const data8 = new FormData(event.currentTarget);
      if (value == 0) {
        setdata1(data);
        if (data.get('origin') == "" | data.get('destiny') == "") {
          setAlert(true);
        } else if (data.get('origin') < data.get('effective_date')) {
          setAlert(true);
        } else {
          let origenid = dataCity_o.find(({ nombre }) => nombre === data.get('origin'));
          let destinoid = dataCity_d.find(({ nombre }) => nombre === data.get('destiny'));
          setdatos1({
            origin: data.get('origin'),
            origin_id: origenid.id,
            origin_code: origenid.codigo,
            destiny: data.get('destiny'),
            destiny_id: destinoid.id,
            destiny_code: destinoid.codigo,
            upload_date: data.get('upload_date'),
            effective_date: data.get('effective_date'),
          })
          if (data.get('origin') != "" && data.get('destiny') != "" && data.get('upload_date') != "" && data.get('effective_date') != "") {

            setValue(1);
          } else {
            setAlert(true);
          }
        }
      }
      if (value == 1) {
        setdata2(data);
        setdatos2({
          high: data.get('high'),
          width: data.get('width'),
          length: data.get('length'),
          type_weight_length: data.get('type_weight_length'),
          weight: data.get('weight'),
          type_weight: data.get('type_weight'),
          client: data.get('client'),
          product: data.get('product'),
          volume: data.get('volume'),
          type_weight_volume: data.get('type_weight_volume'),
          massive: data.get('massive'),
          consolidable: data.get('consolidable'),
          observacion: data.get('observacion'),
        })
        if (data.get('massive') != "" && data.get('weight') != "" && data.get('client') != "" && data.get('product') != "" && data.get('type_weight') != "" && data.get('consolidable') != "") {
          setValue(2);
        } else {
          setAlert(true);
        }
      }
      if (value == 2) {
        setdata3(data);
        if (data.get('flete') != "" && data.get('vehicle_type') != "" && data.get('body_type') != "") {
          const current = new Date();
          const time = current.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          });
          let relaciones = RELATION.find(({ carroceria, tipo_vehiculo }) => carroceria === data.get('body_type') && tipo_vehiculo === data.get('vehicle_type'));
          let relaciones2 = RELATION.find(({ carroceria, tipo_vehiculo }) => carroceria === "CUALQUIERA" && tipo_vehiculo === data.get('vehicle_type'));
          // console.log("IMPRESION DE RELACIONES R1", relaciones, " R2 ", relaciones2, " XXXXX ", data.get('body_type'), "  ---  ", data.get('vehicle_type'));
          if (relaciones && relaciones2 && relaciones.tipovehiculocarrocerias_id == relaciones2.tipovehiculocarrocerias_id) {
            AXIOS.post(API.CREATE_VIAJES, {
              origen: datos1.origin_id,
              destino: datos1.destiny_id,
              fechacarga: datos1.upload_date,
              fechavigencia: datos1.effective_date,
              dimalto: datos2.high,
              dimlargo: datos2.length,
              dimancho: datos2.width,
              cubicaje: datos2.volume,
              peso: datos2.weight,
              unidadlongitud_id: datos2.type_weight_length,
              unidadpeso_id: datos2.type_weight,
              unidadvolumen_id: datos2.type_weight_volume,
              costo: data.get('flete'),
              "producto": datos2.product,
              "producto_codigo": "",
              "cliente": datos2.client,
              "cliente_codigo": "",
              observacion: datos2.observacion,
              consolidable: datos2.consolidable,
              gestionado: localStorage.getItem("user_id"),
              tipovehiculocarroceria: [relaciones.tipovehiculocarrocerias_id],
              horacarga: time,
            }).then((response) => {
              setViewOffices(false);
              refresh();
              setdatos1({
                origin: "",
                origin_id: "",
                destiny: "",
                destiny_id: "",
                upload_date: "",
                effective_date: "",
              })
              setdatos2({
                high: "",
                width: "",
                length: "",
                type_weight_length: "",
                weight: "",
                type_weight: "",
                client: "",
                product: "",
                volume: "",
                type_weight_volume: "",
                massive: "",
                consolidable: "",
              })
              setValue(0);
            })
              .catch(function (error) {
                setMessageResponseError("Lo sentimos ha ocurrido un erro al tratar de crear el viaje");
                handleOpenError();
                // console.log("VIAJE ERROR", error);
              });
          } else {
            AXIOS.post(API.CREATE_VIAJES, {
              origen: datos1.origin_id,
              destino: datos1.destiny_id,
              fechacarga: datos1.upload_date,
              fechavigencia: datos1.effective_date,
              dimalto: datos2.high,
              dimlargo: datos2.length,
              dimancho: datos2.width,
              cubicaje: datos2.volume,
              peso: datos2.weight,
              unidadlongitud_id: datos2.type_weight_length,
              unidadpeso_id: datos2.type_weight,
              unidadvolumen_id: datos2.type_weight_volume,
              costo: data.get('flete'),
              "producto": datos2.product,
              "producto_codigo": "",
              "cliente": datos2.client,
              "cliente_codigo": "",
              observacion: datos2.observacion,
              consolidable: datos2.consolidable,
              gestionado: localStorage.getItem("user_id"),
              tipovehiculocarroceria: [relaciones.tipovehiculocarrocerias_id, relaciones2.tipovehiculocarrocerias_id],
              horacarga: time,
            }).then((response) => {
              setViewOffices(false);
              refresh();
              handleClick_success();
              setdatos1({
                origin: "",
                origin_id: "",
                destiny: "",
                destiny_id: "",
                upload_date: "",
                effective_date: "",
              })
              setdatos2({
                high: "",
                width: "",
                length: "",
                type_weight_length: "",
                weight: "",
                type_weight: "",
                client: "",
                product: "",
                volume: "",
                type_weight_volume: "",
                massive: "",
                consolidable: "",
              })
              setValue(0);
            })
              .catch(function (error) {
                setMessageResponseError("Lo sentimos ha ocurrido un erro al tratar de crear el viaje");
                handleOpenError();
                // console.log("VIAJE ERROR", error);
              });
          }
        } else {
          setAlert(true);
        }
      }
    } catch (error) {
      setMessageResponseError("Una de las combinaciones especificadas no son válidas.");
      handleOpenError();
      console.error("ERROR CONTROLADO ", error);
    }
  };

  // ---------------------------------------------------------------------------------------------

  //Funcion para abrir modal de creacion de servicio  
  const viewDataOffices = () => {
    setViewOffices(true);
  };

  //Funcion para cerrar modal de creacion de servicio    
  const closeViewOffices = () => {
    setViewOffices(false)
    setdatos1({
      origin: "",
      origin_id: "",
      destiny: "",
      destiny_id: "",
      upload_date: "",
      effective_date: "",
    })
    setdatos2({
      high: "",
      width: "",
      length: "",
      type_weight_length: "",
      weight: "",
      type_weight: "",
      client: "",
      product: "",
      volume: "",
      type_weight_volume: "",
      massive: "",
      consolidable: "",
    })
    setValue(0);
  };

  //Funcion para filtrar
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    setPage(0);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=` + rowsPerPage + `&search={"value":"${event.target.value}"}&service_status=${getFilterTypeByTab()}`, {
    }).then((response) => {
      // console.log("FECTCHING SERVICES TYPE 4 ", response.data)
      closeLoad();
      setcompanies(response.data.data);
      setBusquedaVehiculoData(response.data.busqueda_vehiculo);
      setTotalPage(response.data.recordsFiltered);
    });
  };
  //funcion para cerrar modal de cancelar servicio
  const closeviajes = () => setviajes(false);
  //Funcion para almacenar datos de viaje para eliminar
  const openDeleteServices = (row) => {
    setDelete({
      id: row.id,
      nombre: (row.nombres) + (" ") + (row.apellidos)
    })
    setviajes(true);
  };
  //Funcion para eliminar servicio
  const DeleteServices = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('radio-buttons-group')) {
      setAlert(true);
      // console.log("SIN BOTON")
    } else {
      if (data.get('radio-buttons-group') != "motivo otro") {
        // console.log("GATO")
        AXIOS.post("http://izicarga.logicem.com/api/servicios/busqueda_de_vehiculo/" + DELETE.id + "/reason?user_id=" + localStorage.getItem("user_id_LOGICEM"), {
          motivo: data.get('radio-buttons-group'),
        }).then((response) => {
          closeviajes();
          refresh();
          handleClick_error();
        });
      }
    }
    if (data.get('radio-buttons-group') == "motivo otro" && data.get('otro_motivo') == "") {
      // console.log("SIN MOTIVO")
      setAlert(true);
    } else {
      if (data.get('otro_motivo') != "" && data.get('radio-buttons-group') == "motivo otro") {
        // console.log("PERRO")
        AXIOS.post("http://izicarga.logicem.com/api/servicios/busqueda_de_vehiculo/" + DELETE.id + "/reason?user_id=" + localStorage.getItem("user_id_LOGICEM"), {
          motivo: data.get('otro_motivo'),
        }).then((response) => {
          closeviajes();
          refresh();
          handleClick_error();
        });
      }
    }
  };

  const getFilterTypeByTab = () => {
    switch (value) {
      case 0:
        return "vigentes";
        break;
      case 1:
        return "completos";
        break;
      case 2:
        return "cancelados";
        break;

      default:
        return "";
        break;
    }
  }

  React.useEffect(() => {
    // console.log("EJECUTNADO CAMBIO DE TAB EFFECT", value)
    //Funcion para refrescar pantalla
    const refreshTab = () => {
      openLoad();
      setFilter("");
      setPage(0);
      setRowsPerPage(10);
      AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=10&search={"value":"${filter}"}&service_status=${getFilterTypeByTab()}`, {
      }).then((response) => {
        // console.log("FECTCHING SERVICES TYPE 5 ", response.data)
        if (response) {
          closeLoad();
        }
        setcompanies(response.data.data);
        setBusquedaVehiculoData(response.data.busqueda_vehiculo);
        setTotalPage(response.data.recordsFiltered);
      });
    };
    refreshTab();
  }, [value]);


  //Funcion para refrescar pantalla
  const refresh = () => {
    openLoad();
    setFilter("");
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(API.GET_VIAJES_PUBLICADOS + `?start=1&length=10&search={"value":"${filter}"}&service_status=${getFilterTypeByTab()}`, {
    }).then((response) => {
      // console.log("FECTCHING SERVICES TYPE 5 ", response.data)
      if (response) {
        closeLoad();
      }
      setcompanies(response.data.data);
      setBusquedaVehiculoData(response.data.busqueda_vehiculo);
      setTotalPage(response.data.recordsFiltered);
    });
  };
  const [age, setAge] = React.useState('');
  //Funcion para almacenar la unidad de medida
  const handleChangeg = (event) => {
    setAge(event.target.value);
  };

  //Inicializando datos que trae la api de get
  function createData(has_ended, empresa, departamento_origen, localidad_origen, departamento_destino, localidad_destino, cliente, producto, costo, id, fechacarga, contacto_nombres, placa, saldo, fecha_montado, interesados, vencido, observacion, peso, contacto_celular) {
    return { has_ended, empresa, departamento_origen, localidad_origen, departamento_destino, localidad_destino, cliente, producto, costo, id, fechacarga, contacto_nombres, placa, saldo, fecha_montado, interesados, vencido, observacion, peso, contacto_celular };
  }

  //Seleccion de datos que se mostraran en la tabla  
  const rows = companies.map(item => {
    return (
      createData(item.has_ended, item.empresa, item.departamento_origen, item.localidad_origen, item.departamento_destino, item.localidad_destino, item.cliente, item.producto, item.costo, item.id, item.fechacarga, item.contacto_nombres, item.placa, item.saldo, item.fecha_montado, item.interesados, item.vencido, item.observacion, item.peso, item.contacto_celular)
    )
  });

  //Funcion para formato de fecha
  const hoy = new Date();
  function formatoFecha(fecha, formato) {
  }
  const fecha_now = hoy.toLocaleDateString();
  formatoFecha(hoy, 'dd/mm/yy');

  //Inicializacion de select
  const unidad_peso = [
    { id: 1, nombre: "KILOS", abreviatura: "KG" },
    { id: 2, nombre: "TONELADAS", abreviatura: "TON" },
    { id: 3, nombre: "GALON", abreviatura: "GALON" }
  ]
  const unidad_longitud = [
    { id: 1, nombre: "METROS", abreviatura: "MTS" }
  ]
  const consolidable = [
    { id: 1, title: "SI CONSOLIDABLE" },
    { id: 2, title: "NO CONSOLIDABLE" }
  ]
  const masivo = [
    { id: 1, title: "SI MASIVO" },
    { id: 0, title: "NO MASIVO" }
  ]
  //Funcion para cerrar modal de alerta   
  const closeAlert = () => setAlert(false);

  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard title='Viajes Publicados'>
          {/* Modal cambio de estado de viaje */}
          <Modal
            open={openPUT}
            onClose={handleClosePUT}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿Desea cambiarle el estado a este viaje?
              </Typography>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => handleClosePUT()}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1, background: "gray" }}
                >
                  Cancelar
                </Button>
                <div style={{ margin: 18 }}></div>
                <Button onClick={() => put_estado()} fullWidth variant="contained"
                  sx={{ mt: 3, mb: 1, background: " #e35233" }}
                > Actualizar
                </Button>
              </div>
            </Box>
          </Modal>
          {/* Notificacion de exito */}
          <Snackbar
            open={openSuccessC}
            autoHideDuration={8000}
            onClose={handleClose_successC}
          >
            <Alert
              onClose={handleClose_successC}
              severity="success"
              sx={{ width: "100%" }}
            >
              {dataMessage}
            </Alert>
          </Snackbar>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Notificacion de viaje creado         */}
          <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleClose_success}>
            <Alert onClose={handleClose_success} severity="success" sx={{ width: '100%' }}>
              Viaje creado con exito
            </Alert>
          </Snackbar>
          {/* Notificacion de error de creacion viaje        */}
          <Snackbar open={generalError} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
              {messageResponseError}
            </Alert>
          </Snackbar>
          {/* Notificacion de viaje eliminado       */}
          <Snackbar open={openError} autoHideDuration={8000} onClose={handleClose_error}>
            <Alert onClose={handleClose_error} severity="error" sx={{ width: '100%' }}>
              Viaje eliminado con exito
            </Alert>
          </Snackbar>
          {/* Modal de alerta */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                Por favor complete todos los campos obligatorios*
              </Typography>
            </Box>
          </Modal>
          {/* Modal de creacion de servicio */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box >
              <Box component="form" onSubmit={submitCreateService} noValidate
                autoComplete="off"
                sx={{
                  // backgroundColor: (theme) =>
                  //   theme.palette.mode === 'light'
                  //     ? theme.palette.grey[100]
                  //     : theme.palette.grey[900],
                  flexGrow: 1,
                  background: "white",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  overflow: 'auto',
                  borderRadius: 8,
                  boxShadow: 24,
                  p: 1,
                }}>
                <Button style={{ float: "right" }} onClick={closeViewOffices}><CloseIcon /></Button>
                <Typography style={{ textAlign: "center", marginLeft: 80 }} component="h3" color="#e35233"><b>Publicar Servicio</b></Typography>
                <Box sx={{ width: '100%' }} >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} aria-label="basic tabs example" >
                      <Tab label="Logistica de la Carga" {...a11yProps(0)} onClick={back2} />
                      <Tab label="Características de la Carga" {...a11yProps(1)} onClick={back3} />
                      <Tab label="Tipo de Vehiculo" {...a11yProps(2)} onClick={back4} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    {/* <Typography align='center'  component="h3" color="gray">Origen - Destino</Typography>
        <br/> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          disablePortal
                          required
                          options={dataCity_o.map((option) => option.nombre)}
                          defaultValue={datos1.origin}
                          sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                          renderInput={(params) => <TextField {...params} size="small" onChange={CityOriginChangeO} name="origin" label="Origen (Campo obligatorio)" id="origin" />} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          disablePortal
                          required
                          options={dataCity_d.map((option) => option.nombre)}
                          defaultValue={datos1.destiny}
                          sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                          renderInput={(params) => <TextField {...params} size="small" onChange={CityOriginChangeD} name="destiny" label="Destino (Campo obligatorio)" id="destiny" />} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="upload_date" required type="date" defaultValue={datos1.upload_date}
                          fullWidth id="upload_date" label="Fecha Carga (Campo obligatorio)" autoFocus size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarMonthIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="effective_date" required type="date" defaultValue={datos1.effective_date}
                          fullWidth id="effective_date" label="Fecha Vigencia (Campo obligatorio)" autoFocus size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarMonthIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </Grid>
                    {/* <Button    onClick={closeViewOffices} type="submit" variant="contained" 
                      sx={{ mt: 3, mb: 2, background:"gray", float:"left", align:'left', width:"40%", marginLeft:"4%"}}>
                      Cancelar</Button>
                    {/* <div style={{margin:18}}></div> */}
                                      {/* <Button type="submit" variant="contained" size="midum"
                      sx={{ mt: 3, mb: 2, background:" #e35233", float:"right", align:'right', width:"40%", marginRight:"4%"}}> 
                      Siguiente</Button> */}
                    <Button onClick={closeViewOffices} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Cancelar</Button>
                    {/* <div style={{margin:18}}></div> */}
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Siguiente</Button>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {/* <Typography align='center'  component="h3" color="gray">Características de la carga </Typography>
        <br/> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="high" size="small" defaultValue={datos2.high}
                          fullWidth id="high" label="Alto" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <StraightIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="width" size="small" defaultValue={datos2.width}
                          fullWidth id="width" label="Ancho" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SyncAltIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="length" size="small" defaultValue=""
                          fullWidth id="length" label="Largo" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <OpenInFullIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida</InputLabel>
                          <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight_length"
                            name="type_weight_length"
                            defaultValue={datos2.type_weight_length}
                            label="Unidad Medida"
                            onChange={handleChangeg}
                          >{
                              unidad_longitud.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField autoComplete="given-name" name="weight" required defaultValue={datos2.weight}
                          fullWidth id="weight" size="small" label="Peso (Campo obligatorio)" autoFocus onKeyPress={isInputNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BalanceIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight"
                            name="type_weight"
                            defaultValue={datos2.type_weight}
                            label="Unidad Medida (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              unidad_peso.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <Autocomplete
                          disablePortal
                          required
                          name="Cliente"
                          options={clientSAP.map((option) => option.nombre)}
                          defaultValue={datos2.client}
                          sx={{marginLeft:2,width:'100%', marginTop:-1 }}
                          renderInput={(params) => <TextField {...params} label="Cliente" onChange={ClientChange} id="filled-basic"/>}/> */}
                        <TextField size="small" autoComplete="given-name" name="client" require
                          fullWidth id="client" label="Cliente (Campo obligatorio)" autoFocus defaultValue={datos2.client}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="product" required
                          fullWidth id="product" label="Producto (Campo obligatorio)" autoFocus defaultValue={datos2.product}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="volume" onKeyPress={isInputNumber}
                          fullWidth id="volume" label="Volumen" autoFocus defaultValue={datos2.volume}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ViewInArIcon />
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Unidad Medida</InputLabel>
                          <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="type_weight_volume"
                            name="type_weight_volume"
                            defaultValue={datos2.type_weight_volume}
                            label="Unidad Medida"
                            onChange={handleChangeg}
                          >{
                              unidad_longitud.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Masivo (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="massive"
                            name="massive"
                            defaultValue={datos2.massive}
                            label="Masivo (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              masivo.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.title}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Consolidable (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="consolidable"
                            name="consolidable"
                            defaultValue={datos2.consolidable}
                            label="Consolidable (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              consolidable.map(item => {
                                return (
                                  <MenuItem value={item.id}>{item.title}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={12}>
                        <TextField size="medium" autoComplete="given-name" name="observacion"
                          fullWidth id="observacion" label="Observación" autoFocus defaultValue={datos2.observacion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </Grid>
                    <Button onClick={back2} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Atrás</Button>
                    {/* <div style={{margin:18}}></div> */}
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Siguiente</Button>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField size="small" autoComplete="given-name" name="flete" required onKeyPress={isInputNumber}
                          fullWidth id="flete" label="Valor de flete (Campo obligatorio)" autoFocus type="number"
                          pattern="[0-9]*"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalAtmIcon />
                              </InputAdornment>
                            ),
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Tipo Vehiculo (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="vehicle_type"
                            name="vehicle_type"
                            defaultValue={datos3.vehicle_type}
                            label="Tipo Vehiculo (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              VEHICULOS2.map(item => {
                                return (
                                  <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" size='small'>Tipo Carroceria (Campo obligatorio) *</InputLabel>
                          <Select
                            required
                            size='small'
                            labelId="demo-simple-select-label"
                            id="body_type"
                            name="body_type"
                            defaultValue={datos3.body_type}
                            label="Tipo Carroceria (Campo obligatorio) *"
                            onChange={handleChangeg}
                          >{
                              CARROCERIAS2.map(item => {
                                return (
                                  <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                                )
                              })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Button onClick={back3} type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: "gray", float: "left", align: 'left', width: "40%", marginLeft: "4%" }}>
                      Atrás</Button>
                    <Button type="submit" variant="contained" size="medium"
                      sx={{ mt: 3, mb: 2, background: " #e35233", float: "right", align: 'right', width: "40%", marginRight: "4%" }}>
                      Publicar</Button>
                  </TabPanel>
                  <Box component="form" onSubmit={"submitCreateService"} noValidate sx={{ mt: 1 }}>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
          {/* Modal para cancelar viaje */}
          <Modal
            open={open_viajes}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿Desea cancelar el viaje?
              </Typography>
              {/* <div style={{float: "rigth"}}>
            {(validatorErrors == true)?
           (validatorErrors ==="")?null:
                    <Alert severity="error">{errorsMessage}</Alert>: ""
                }
            </div> */}
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={DeleteServices} noValidate sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Motivo de cancelación</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Se cancelo el servicio"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel id="Se cancelo el servicio" value="Se cancelo el servicio" control={<Radio />} label="Se cancelo el servicio" />
                      <FormControlLabel id="Se Contrato el transportista por otro medio" value="Se Contrato el transportista por otro medio" control={<Radio />} label="Se Contrato el transportista por otro medio" />
                      <FormControlLabel value="motivo otro" id="otro motivo" control={<Radio />} label="Otro motivo:" />
                      <Grid item xs={2} sm={12} >
                        <TextField size="medium" autoComplete="given-name" name="otro_motivo"
                          fullWidth id="motivo otro" autoFocus
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              </InputAdornment>
                            ),
                          }}
                        /></Grid>
                    </RadioGroup>
                  </FormControl>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeviajes}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "gray" }}
                    >
                      No
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 2, background: " #e35233" }}
                    > Si
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          <Grid item xs={12} md={8} lg={29}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} >
              <Grid size="small" style={{ margin: 10, float: 'left', display: "flex" }}>
                {/* Panel de botonera         */}
                <div style={{ marginLeft: 10 }}>
                  <Button href={EXCEL} target="_blank" rel="noopener noreferrer" download="formato_servicios_excel.xlsx" variant="outlined" startIcon={<CloudDownloadIcon />}>Descargar formato</Button>
                </div>
                {/* <div style={{marginLeft:10}}>
          <Button variant="outlined" startIcon={<UploadFileIcon/>}>Subir excel</Button>
        </div> */}
                <div style={{ marginLeft: 10 }} id="trigger">
                  <Button
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}
                    onClick={() =>
                      handleClickCopyToClipboard(headers, rows, [
                        "id",
                        "contacto_nombres",
                        "departamento_origen",
                        "departamento_destino",
                        "cliente",
                        "placa",
                        "producto",
                        "masivo",
                        "costo",
                        "saldo",
                        "fecha_montado",
                        "empresa",
                        "interesados",
                        // {
                        //   key: "completo",
                        //   template: (value) => {
                        //     return value == 0 ? "Incompleto" : "Completo";
                        //   },
                        // },
                      ])
                    }
                  >
                    Copiar
                  </Button>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <Button variant="outlined" onClick={handlePrint} startIcon={<PrintIcon />}>Imprimir</Button>
                </div>
                <div>
                  {/* <input type="text" value={String(masivo)} readOnly />
      <button>
        <span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </button> */}
                </div>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className='export-btn'
                  table="viajes"
                  filename="Viajes publicados"
                  sheet="tablexls"
                  buttonText={<div style={{ display: "flex" }}><IosShareIcon /><div style={{ marginTop: 4, marginLeft: 6 }}>EXPORTAR EXCEL</div></div>} />
              </Grid>
              <Grid size="small" style={{ margin: 10, float: 'right', display: "flex" }}>
                {/* Boton para publicar servicio */}
                <Button type="submit" fullWidth variant="contained" onClick={() => viewDataOffices()}
                  sx={{ background: "white", color: "#FF5733" }}><DirectionsIcon />Publicar Servicio
                </Button>
              </Grid>
            </Paper>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
              {/* Tab estado de viajes         */}
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} onClick={() => null} aria-label="basic tabs example">
                    <Tab label="VIGENTES" {...a11yProps(0)} />
                    <Tab label="COMPLETADOS" {...a11yProps(1)} />
                    <Tab label="CANCELADOS" {...a11yProps(2)} />
                    {/* <Tab label="VENCIDOS" {...a11yProps(3)} /> */}
                    <Tab label="TODOS" {...a11yProps(4)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                    <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                  </div>
                  <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                    <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" id="viajes" ref={componentRef}>
                      <TableHead>
                        <TableRow>
                          {/* Tabla de contenido vigentes             */}
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Publicado Por</TableCell>
                          <TableCell align="center">Origen</TableCell>
                          <TableCell align="center">Destino</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Placa</TableCell>
                          <TableCell align="center">Producto</TableCell>
                          <TableCell align="center">Masivo</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell align="center">Saldo</TableCell>
                          <TableCell align="center">Fecha Registro</TableCell>
                          <TableCell align="center">Empresa</TableCell>
                          <TableCell align="center">Interesados</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, id) => (
                          <>
                            {/* Table Row Colapse */}
                            <TableRowCollapse rowData={row} busquedaVehiculoData={busquedaVehiculoData}>
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell align="center">{<>{(row.contacto_nombres == null | row.contacto_nombres == "") ? (<>{"-"}</>) :
                                (<>{row.contacto_nombres}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                (<>{row.cliente}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                (<>{row.placa}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                                (<>{row.producto}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.masivo == "0") ? (<>{"NO"}</>) :
                                (<>{"SI"}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.saldo == null | row.saldo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.saldo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.fecha_montado == null | row.fecha_montado == "") ? (<>{"-"}</>) :
                                (<>{row.fecha_montado}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                (<>{row.empresa}</>)}</>}</TableCell>
                              <TableCell align="center"><p style={{ fontSize: 13 }} id='Interesados'>{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) :
                                (<Button style={{ color: "red" }} id='Interesados' onClick={() => openInteresado(row)}> <><div style={{ color: "#0885c8" }}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</p></TableCell>
                              <TableCell align="center">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={row.has_ended}
                                    value={row.has_ended}
                                    label="Estado"
                                    onClick={() => capt_data(row)}
                                    onChange={handleChangeStatus}
                                  >
                                    <MenuItem value={0}>Vigente</MenuItem>
                                    <MenuItem value={1}>Cancelado</MenuItem>
                                    <MenuItem value={2}>Completado</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                              </Button>
                              </TableCell>
                            </TableRowCollapse>
                            {/* Fin Table Row Colapse */}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {<>{(rows == "") ?
                    <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                      <Typography>NO HAY DATOS DISPONIBLES</Typography>
                    </Stack> :
                    ""}</>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                    <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                  </div>
                  <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                    <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
                  <TableContainer component={Paper}>
                    {/* Tabla de contenido completados             */}
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" id="viajes" ref={componentRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Publicado Por</TableCell>
                          <TableCell align="center">Origen</TableCell>
                          <TableCell align="center">Destino</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Placa</TableCell>
                          <TableCell align="center">Producto</TableCell>
                          <TableCell align="center">Masivo</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell align="center">Saldo</TableCell>
                          <TableCell align="center">Fecha Registro</TableCell>
                          <TableCell align="center">Empresa</TableCell>
                          <TableCell align="center">Interesados</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, id) => (
                          <TableRowCollapse rowData={row}>
                            <TableCell align="center">{row.id}
                            </TableCell>
                            <TableCell align="center">{<>{(row.contacto_nombres == null | row.contacto_nombres == "") ? (<>{"-"}</>) :
                              (<>{row.contacto_nombres}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                              (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                              (<>{row.cliente}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                              (<>{row.placa}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                              (<>{row.producto}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.masivo == "0") ? (<>{"NO"}</>) :
                              (<>{"SI"}</>)}</>}</TableCell>
                            <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                              (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.saldo == null | row.saldo == "") ? (<>{"-"}</>) :
                              (<>{"$"}{new Intl.NumberFormat().format(row.saldo)}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.fecha_montado == null | row.fecha_montado == "") ? (<>{"-"}</>) :
                              (<>{row.fecha_montado}</>)}</>}
                            </TableCell>
                            <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                              (<>{row.empresa}</>)}</>}</TableCell>
                            <TableCell align="center"><p style={{ fontSize: 13 }} id='Interesados'>{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) :
                              (<Button style={{ color: "red" }} id='Interesados' onClick={() => openInteresado(row)}> <><div style={{ color: "#0885c8" }}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</p>
                            </TableCell>
                            <TableCell align="center">
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={row.has_ended}
                                  value={row.has_ended}
                                  label="Estado"
                                  onClick={() => capt_data(row)}
                                  onChange={handleChangeStatus}
                                >
                                  <MenuItem value={0}>Vigente</MenuItem>
                                  <MenuItem value={1}>Cancelado</MenuItem>
                                  <MenuItem value={2}>Completado</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                            </Button>
                            </TableCell>
                          </TableRowCollapse>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {<>{(rows == "") ?
                    <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                      <Typography>NO HAY DATOS DISPONIBLES</Typography>
                    </Stack> :
                    ""}</>}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                    <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                  </div>
                  <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                    <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" id="viajes" ref={componentRef}>
                      <TableHead>
                        <TableRow>
                          {/* Tabla de contenido cancelados             */}
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Publicado Por</TableCell>
                          <TableCell align="center">Origen</TableCell>
                          <TableCell align="center">Destino</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Placa</TableCell>
                          <TableCell align="center">Producto</TableCell>
                          <TableCell align="center">Masivo</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell align="center">Saldo</TableCell>
                          <TableCell align="center">Fecha Registro</TableCell>
                          <TableCell align="center">Empresa</TableCell>
                          <TableCell align="center">Interesados</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, id) => (
                          <>
                            <TableRowCollapse rowData={row} >
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell align="center">{<>{(row.contacto_nombres == null | row.contacto_nombres == "") ? (<>{"-"}</>) :
                                (<>{row.contacto_nombres}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                (<>{row.cliente}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                (<>{row.placa}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                                (<>{row.producto}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.masivo == "0") ? (<>{"NO"}</>) :
                                (<>{"SI"}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.saldo == null | row.saldo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.saldo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.fecha_montado == null | row.fecha_montado == "") ? (<>{"-"}</>) :
                                (<>{row.fecha_montado}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                (<>{row.empresa}</>)}</>}</TableCell>
                              <TableCell align="center"><p style={{ fontSize: 13 }} id='Interesados'>{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) :
                                (<Button style={{ color: "red" }} id='Interesados' onClick={() => openInteresado(row)}> <><div style={{ color: "#0885c8" }}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</p></TableCell>
                              <TableCell align="center">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={row.has_ended}
                                    value={row.has_ended}
                                    label="Estado"
                                    onClick={() => capt_data(row)}
                                    onChange={handleChangeStatus}
                                  >
                                    <MenuItem value={0}>Vigente</MenuItem>
                                    <MenuItem value={1}>Cancelado</MenuItem>
                                    <MenuItem value={2}>Completado</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                              </Button>
                              </TableCell>
                            </TableRowCollapse>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {<>{(rows == "") ?
                    <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                      <Typography>NO HAY DATOS DISPONIBLES</Typography>
                    </Stack> :
                    ""}</>}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                    <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                  </div>
                  <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                    <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" id="viajes" ref={componentRef}>
                      <TableHead>
                        <TableRow>
                          {/* Tabla de contenido todos             */}
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Publicado Por</TableCell>
                          <TableCell align="center">Origen</TableCell>
                          <TableCell align="center">Destino</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Placa</TableCell>
                          <TableCell align="center">Producto</TableCell>
                          <TableCell align="center">Masivo</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell align="center">Saldo</TableCell>
                          <TableCell align="center">Fecha Registro</TableCell>
                          <TableCell align="center">Empresa</TableCell>
                          <TableCell align="center">Interesados</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, id) => (
                          <>
                            {/* Table Row Colapse */}
                            <TableRowCollapse rowData={row}>
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell align="center">{<>{(row.contacto_nombres == null | row.contacto_nombres == "") ? (<>{"-"}</>) :
                                (<>{row.contacto_nombres}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                (<>{row.cliente}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                (<>{row.placa}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                                (<>{row.producto}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.masivo == "0") ? (<>{"NO"}</>) :
                                (<>{"SI"}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.saldo == null | row.saldo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.saldo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.fecha_montado == null | row.fecha_montado == "") ? (<>{"-"}</>) :
                                (<>{row.fecha_montado}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                (<>{row.empresa}</>)}</>}</TableCell>
                              <TableCell align="center"><p style={{ fontSize: 13 }} id='Interesados'>{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) :
                                (<Button style={{ color: "red" }} id='Interesados' onClick={() => openInteresado(row)}> <><div style={{ color: "#0885c8" }}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</p></TableCell>
                              <TableCell align="center">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={row.has_ended}
                                    value={row.has_ended}
                                    label="Estado"
                                    onClick={() => capt_data(row)}
                                    onChange={handleChangeStatus}
                                  >
                                    <MenuItem value={0}>Vigente</MenuItem>
                                    <MenuItem value={1}>Cancelado</MenuItem>
                                    <MenuItem value={2}>Completado</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                              </Button>
                              </TableCell>
                            </TableRowCollapse>
                            {/* Fin Table Row Colapse */}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {<>{(rows == "") ?
                    <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                      <Typography>NO HAY DATOS DISPONIBLES</Typography>
                    </Stack> :
                    ""}</>}
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <div style={{ display: "flex", float: "right", marginBottom: 15 }}>
                    <TextField id="filled-basic" label="Buscar (Origen & Destino)" variant="filled" name="filtro" onChange={handleChangeSearch} />
                  </div>
                  <div style={{ float: "right", marginTop: 14, marginRight: 10 }}>
                    <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" id="viajes" ref={componentRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Publicado Por</TableCell>
                          <TableCell align="center">Origen</TableCell>
                          <TableCell align="center">Destino</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Placa</TableCell>
                          <TableCell align="center">Producto</TableCell>
                          <TableCell align="center">Masivo</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell align="center">Saldo</TableCell>
                          <TableCell align="center">Fecha Registro</TableCell>
                          <TableCell align="center">Empresa</TableCell>
                          <TableCell align="center">Interesados</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, id) => (
                          <>
                            {/* Table Row Colapse */}
                            <TableRowCollapse rowData={row}>
                              <TableCell align="center" >{row.id}</TableCell>
                              <TableCell align="center">{<>{(row.contacto_nombres == null | row.contacto_nombres == "") ? (<>{"-"}</>) :
                                (<>{row.contacto_nombres}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) :
                                (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.cliente == null | row.cliente == "") ? (<>{"-"}</>) :
                                (<>{row.cliente}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) :
                                (<>{row.placa}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) :
                                (<>{row.producto}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.masivo == "0") ? (<>{"NO"}</>) :
                                (<>{"SI"}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.costo == null | row.costo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.costo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.saldo == null | row.saldo == "") ? (<>{"-"}</>) :
                                (<>{"$"}{new Intl.NumberFormat().format(row.saldo)}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.fecha_montado == null | row.fecha_montado == "") ? (<>{"-"}</>) :
                                (<>{row.fecha_montado}</>)}</>}</TableCell>
                              <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                                (<>{row.empresa}</>)}</>}</TableCell>
                              <TableCell align="center"><p style={{ fontSize: 13 }} id='Interesados'>{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) :
                                (<Button style={{ color: "red" }} id='Interesados' onClick={() => openInteresado(row)}> <><div style={{ color: "#0885c8" }}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</p></TableCell>
                              <TableCell align="center">
                                <FormControl fullWidth>BUENOS
                                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={row.has_ended}
                                    value={row.has_ended}
                                    label="Estado"
                                    onClick={() => capt_data(row)}
                                  >
                                    <MenuItem value={0}>Vigente</MenuItem>
                                    <MenuItem value={1}>Cancelado</MenuItem>
                                    <MenuItem value={2}>Completado</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center"><Button style={{ color: "red" }} onClick={() => openDeleteServices(row)}><CancelIcon />
                              </Button>
                              </TableCell>
                            </TableRowCollapse>
                            {/* Fin Table Row Colapse */}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {<>{(rows == "") ?
                    <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                      <Typography>NO HAY DATOS DISPONIBLES</Typography>
                    </Stack> :
                    ""}</>}
                </TabPanel>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Paper>
          </Grid>
        </Dashboard>
      }</>
  );
}
export default ServicesPage;


// --------------------------------------------
// Componentest Utiles
const TableRowCollapse = ({ children, rowData, busquedaVehiculoData }) => {
  const [open, setOpen] = React.useState(false);
  const stylesLabels = { textAlign: "left", width: "15%" };
  const stylesValues = { textAlign: "left", width: "60%" };
  const containerLabels = { display: "flex", justifyContent: "flex-start", marginTop: "6px" };

  let typeVehicle = null;
  if (busquedaVehiculoData)
    typeVehicle = busquedaVehiculoData.find((element) => element.servicio === rowData.id);
  // console.log("ROW DATA  ", rowData);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        key={rowData.id}
        hover role="checkbox" tabIndex={-1}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={handleToggle}
      >
        {children}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div style={{ display: "flex", flexDirection: "column", width: "60vw" }}>
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Peso:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{rowData.peso}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Empresa:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{rowData.empresa}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Publicado por:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{rowData.contacto_nombres}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Celular:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{rowData.contacto_celular}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Observación:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{rowData.observacion}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
                {/* ---------------------------------------------- */}
                <div style={containerLabels}>
                  <div style={stylesLabels}> <Typography component="h2" ><b>Tipo de vehículo:</b></Typography> </div>
                  <div style={stylesValues}> <Typography component="h3" >{typeVehicle ? typeVehicle.tipovehiculo : 'N/A'}</Typography> </div>
                </div>
                {/* ---------------------------------------------- */}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};