//Importacion de complementos
import * as React from "react";
import Dashboard from "../components/Dashboard";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import API, { AXIOS } from "../environment/api";
import useForm from "../hooks/useForm";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import LoadingModal from "../components/LoadingModal";
import MuiAlert from "@mui/material/Alert";
import {
  Stack,
  Grid,
  TextField,
  Container,
  Modal,
  Typography,
  Box,
  TablePagination,
  CssBaseline,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,  
} from "@mui/material";
import {
  Warning,
  AccountBox,
  Key,
  Equalizer,
  CheckCircle,
  Refresh,
  Search,
  Block,
  Description,  
  Send
} from "@mui/icons-material";
import HowToRegIcon from '@mui/icons-material/HowToReg';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Health() {
  //Funcion para inicializar los datos
  const rol = JSON.parse(localStorage.getItem("roles_usuario")).role_id;
  let id_user = localStorage.getItem('user_id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open_editCompany, setOpen_editCompany] = React.useState(false);
  const [open_deleteCompany, setOpen_deleteCompany] = React.useState(false);
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [open_deleteOffice, setOpen_deleteOffice] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [dataTotal, setTotalPage] = React.useState("");
  const [cityData, setcityData] = React.useState([]);
  const [city, setcity] = React.useState("");
  const [stateData, setstateData] = React.useState([]);
  const [state, setstate] = React.useState("");
  const [validatorSuccess, setvalidatorSuccess] = React.useState(false);
  const [validatorErrors, setvalidatorErrors] = React.useState(false);
  const [officeData, setOfficeData] = React.useState(0);
  const [verificadoData, setverificadoData] = React.useState(0);
  const [recazadoData, setrechazadoData] = React.useState(0);
  const [pendienteData, setpendienteData] = React.useState(0);
  const [completadoData, setcompletadoData] = React.useState(0);
  const [habilitadoData, sethabilitadoData] = React.useState(0);
  const [asignadosData, setAsignadosData] = React.useState(0);
  const [identificacionID, setID] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [filter, setFilter] = React.useState("");
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [dataMessage, setMessage] = React.useState(false);
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState(false);
  const [dataStatus, setStatus] = React.useState("");
  const [modalCreateCarrier, setModalCreateCarrier] = React.useState(false);

  //Funcion para abrir mensaje de success
  const handleClick_success = () => {
    setOpenSuccess(true);
  };

  //Funcion para cerrar mensaje de success
  const handleClose_success = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  //Funcion para resetear data
  const [formOffice, setFormOffice] = useForm({
    id: "",
    sku: "",
    name: "",
    state_id: "",
    city_id: "",
    state_name: "",
    city_name: "",
    address: "",
    phone: "",
    email: "",
    created_at: "",
  });

  //Funcion para cerrar alerta
  const closeAlert = () => setAlert(false);

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  //Funcion para el tiempo en pantalla de la notificacion
  setTimeout(function() {
    setvalidatorSuccess(false);
    setvalidatorErrors(false);
  }, 8000);

  //Funcion para refrescar la pantalla
  const refreshStatistics = () => {
    AXIOS.get(API.COUNTER + `${id_user}`, {}).then((response) => {
      //Almacenamiento de datos en tabla de estado
      setOfficeData(response.data.drivers_by_date[0].cantidad);
      setverificadoData(response.data.verified_users[0].cantidad);
      setrechazadoData(response.data.rejected_users[0].cantidad);
      setpendienteData(response.data.pending_users[0].cantidad);
      setcompletadoData(response.data.complete_data_users[0].cantidad);
      sethabilitadoData(response.data.habilitados_actualizar[0].cantidad);
      setAsignadosData(response.data.assigned_users[0].cantidad)
    });
  };

  const refresh = () => {
    setStatus("");
    setFilter("");
    setPage(0);
    setRowsPerPage(10);
    openLoad();
    refreshStatistics();
    AXIOS.get(API.DRIVERS + `?start=1&length=10&id_user=${id_user}`, {}).then((response) => {      
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
      closeLoad();
    });
  };

  /**
   * Filtro de la tabla de transportista 
   * @param {number} estado el estado por el cual se va a filtrar
   * 0 = pendientes por completar
   * 1 = completados 
   * 2 = Verificados
   * 3 = Rechazados
   * 4 = Habilitados para actualizar
   * 5 = Asignados
   */
  const handleFilterChange = (estado) => {
    setStatus(estado);  
    setFilter("");      
    setPage(0);         
    setRowsPerPage(10); 
  
    openLoad();  // Abrir un indicador de carga (implementación asumida)    
    // Buscar conductores en función del estado seleccionado
    AXIOS.get(API.DRIVERS + `?start=1&length=${10}&estado=${estado}&id_user=${id_user}`, {})
      .then((response) => {
        setcompanies(response.data.data);
        setTotalPage(response.data.recordsFiltered);
        closeLoad(); // Cerrar el indicador de carga
      })
      .catch((error) => {
        console.error('Error al buscar conductores:', error);
        closeLoad(); // Cerrar el indicador de carga incluso en caso de error
      });
  };

  /// Funcion para determinar el color de un registro dependiendo del estado
  const getStatusColorDriver = (row) => {
    if (row.completado == 0) return "#f5f05c"; // Rojo
    switch (row.estado_registro) {
      case 0:
        return "#f5f05c"; // Rojo
        break;
      case 1:
        return "#5cf583"; // Verde
        break;
      case 2:
        return "#5cb9f5"; // Azul
        break;
      case 3:
        return "#f55c5c"; // Rojo
        break;
      case 4:
        return "#cacac4"; // Gris claro
        break;
      case 4:
        return "#f5f05c"; // Amarillo
        break;
      default:
        return null;
        break;
    }
  };

  //Funcion para actualizar servicio
  const submitUpdateCompany = (row, estado_registro) => {
    setID({
      id: row.identificacion,
    });
    openLoad();
    try {
        AXIOS.put(API.VERIFIED_USER + row.identificacion, {
          estado_registro: estado_registro,
        })
        .then(function(data) {
          closeLoad();
          refresh();
          setMessage("Estado actualizado con exito");
          handleClick_success();
        })
        .catch(function(error) {
          console.log("update error", error);
          closeLoad();
        });
    } catch (error) {}
  };

  //Funcion para eliminar servicio
  const submitDeleteCompany = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("pass") == "") {
      setAlert(true);
      seterrorsMessage("Por favor completar el campo requerido*");
    } else {
      try {
        AXIOS.put(API.PUT_MASTER_PASS + companyData.id, {
          password: data.get("pass"),
        })
          .then(function(data) {
            refresh();
            closeViewOffices();
            setMessage("Contraseña restablecida con exito");
            handleClick_success();
          })
          .catch(function(error) {
            setAlert(true);
            seterrorsMessage(error.response.data.messages[0]);
          });
      } catch (error) {}
    }
  };

  const asignarmeSolicitud = (identificacion) => {
    openLoad();
    try {
      AXIOS.post(API.POST_ASSIGN_ME_REQUEST, {
        identificacion: identificacion,
        user_id: localStorage.getItem('user_id')
      })
        .then(function(data) {
          setOpenSuccess(true)
          setMessage("Solicitud asignada correctamente")
          setAsignadosData(asignadosData + 1)      
          closeLoad();    
          handleAsignar(identificacion);
          // !Editar dataCompany par anotificar en la tabla
          setTimeout(() => {
            handleClose_success();
          }, 6000);
          localStorage.setItem("dni_hojaVida", identificacion);
          linkTo("/curriculum");
        })
        .catch(function(error) {
          closeLoad();
          setAlert(true);
          seterrorsMessage(error.response.data);
          // console.log(error.response.data)
        });
    } catch (error) {}
  }

  const handleAsignar = (identificacion) => {
    setcompanies(prevItems =>
      prevItems.map(item =>
        item.identificacion === identificacion ? { ...item, asignado_a_mi: true } : item
      )
    );
  };

  const alertmodal = () => { 
    setAlert(true);
    seterrorsMessage("La Hoja de Vida del Transportista esta incompleta. Debe completarse para poder Enviar a Estudio de Activación");
  }

  //Funcion para almacenar datos al editar
  const openUpdateCompany = (row) => {
    setOpen_editCompany(true);
    setCompanyData({
      nombre: row.nombres + " " + row.apellidos,
      alertas: row.alertas,
      alertas_vehiculo: row.alertas_vehiculo,
      habilitaciones: row.habilitaciones,
      habilitaciones_vehiculo: row.habilitaciones_vehiculo,
    });
  };

  //Funcion para almacenar datos al eliminar
  const openDeleteCompany = (row) => {
    setOpen_deleteCompany(true);
    setCompanyData({
      nombre: row.nombres + " " + row.apellidos,
      alertas: row.alertas,
      alertas_vehiculo: row.alertas_vehiculo,
      habilitaciones: row.habilitaciones,
      habilitaciones_vehiculo: row.habilitaciones_vehiculo,
    });
  };

  //Funcion para almacenar datos en el modal de nueva contraseña
  const viewDataOffices = (row) => {
    setCompanyData({
      nombre: row.nombres + " " + row.apellidos,
      id: row.identificacion,
      pass: row.identificacion,
    });
    setViewOffices(true);
  };

  //Funcion para navegar hacia la hoja de vida del conductor
  const hojaVida = (row) => {
    let estadoVerificado = "";
    if (row.habilitar_usuario === true && row.habilitado_completo === false){
      estadoVerificado = "Preaprobado";
    } else if (row.habilitado_completo === true && row.habilitado_completo === true){
      estadoVerificado = "Habilitado";
    } else {
      estadoVerificado = "Inhabilitado";
    }
    localStorage.setItem("verificado", estadoVerificado);
    localStorage.setItem("dni_hojaVida", row.identificacion);
    linkTo("/curriculum");
  };

  //Funcion para cerrar modal de editar
  const close_editCompany = () => {
    setOpen_editCompany(false);
    setstate("");
    setcity("");
  };
  //Funcion para cerrar modal de alerta datacarga
  const close_deleteCompany = () => setOpen_deleteCompany(false);
  //Funcion para cerrar modal de nueva contraseña
  const closeViewOffices = () => setViewOffices(false);

  const close_deleteOffice = () => setOpen_deleteOffice(false);

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);

    if (dataStatus == "") {
      AXIOS.get(
        API.DRIVERS +
          `?start=` +
          (1 + newPage) +
          `&length=` +
          rowsPerPage +
          `&search=` +
          filter + 
          `&id_user=${id_user}`,
        {}
      ).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.recordsFiltered);
      });
    } else {
      AXIOS.get(
        API.DRIVERS +
          `?start=` +
          (1 + newPage) +
          `&length=` +
          rowsPerPage +
          `&estado=` +
          dataStatus + 
          `&id_user=${id_user}`,
        {}
      ).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.recordsFiltered);
      });
    }
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    if (dataStatus == "") {
      AXIOS.get(
        API.DRIVERS +
          `?start=` +
          (1 + page) +
          `&length=` +
          +event.target.value +
          `&search=` +
          filter + 
          `&id_user=${id_user}`,
        {}
      ).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.recordsFiltered);
      });
    } else {
      AXIOS.get(
        API.DRIVERS +
          `?start=` +
          (1 + page) +
          `&length=` +
          +event.target.value +
          `&estado=` +
          dataStatus + 
          `&id_user=${id_user}`,
        {}
      ).then((response) => {
        closeLoad();
        setcompanies(response.data.data);
        setTotalPage(response.data.recordsFiltered);
      });
    }
    setPage(page);
  };

  //Funcion para almacenar data del input de busqueda
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
    if (event.target.value == "") {
      refresh();
    }
  };

  const enviarSolicitud = (estado_datos, identificacion) => {
    if(estado_datos == true){
      let id_user = identificacion;
      try {
          AXIOS.put(API.VERIFIED_USER + id_user, {
            estado_registro: 1,
          })
          .then(function(data) {   
            setMessage("Acción realizada correctamente");
            setAlert(true);
          })
          .catch(function(error) {
            setAlert(true);
            seterrorsMessage(error.response.data);
          });
      } catch (error) {}
    }else if(estado_datos == false){
      setAlert(true);
      seterrorsMessage("Los datos deben estar completados para enviar la solicitud");
    }
  }

  const submitCreateCarrier = (event) => {
    event.preventDefault();    
    if (
      document.getElementById("cedula_conductor").value == "" ||
      document.getElementById("placa").value == "" ||
      document.getElementById("placa_trailer").value == ""
    ) {
      setAlert(true);
      seterrorsMessage("Por favor completar los campos requeridos*");
    } else {
      openLoad();
      AXIOS.post(API.REGISTRODATACARGA, {
        cedula: document.getElementById("cedula_conductor").value,
        placa: document.getElementById("placa").value,
        user_id: localStorage.getItem('user_id'),
        celular: document.getElementById("celular").value,
        placa_trailer: document.getElementById("placa_trailer").value
          ? document.getElementById("placa_trailer").value
          : null,
        cedula_tenedor: document.getElementById("cedula_tenedor").value
          ? document.getElementById("cedula_tenedor").value
          : null,
      })
        .then(function(response) {
          closeLoad();
          refresh();
          setModalCreateCarrier(false);
          setMessage(response.data.messages[0]);
          handleClick_success();
        })
        .catch(function(error) {
          setAlert(true);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            closeLoad();
            seterrorsMessage(error.response.data.messages[0]);
          } else {
            closeLoad();
            seterrorsMessage("Error al crear el transportista");
          }
        });
    }
  };

  //Funcion para filtrar
  const buscador = () => {
    openLoad();
    setStatus("");
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(
      API.DRIVERS_SEARCH + `start=0&search=` + filter + `&length=` + 10 + `&id_user=${id_user}`,
      {}
    ).then((response) => {
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsTotal);
      closeLoad();
      // setTotalPage(response.data.recordsTotal);
    });
    // setFilter(event.target.value)
    // setPage(0);
    // setRowsPerPage(10);
  };

  React.useEffect(() => {
    AXIOS.get(API.DRIVERS + `?start=1&length=10&id_user=${id_user}`, {}).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
      // setvalidatorSuccessd(true);
    });
    AXIOS.get(API.COUNTER +`${id_user}`, {}).then((response) => {
      //Almacenamiento de datos en tabla de estado
      setOfficeData(response.data.drivers_by_date[0].cantidad);
      setverificadoData(response.data.verified_users[0].cantidad);
      setrechazadoData(response.data.rejected_users[0].cantidad);
      setpendienteData(response.data.pending_users[0].cantidad);
      setcompletadoData(response.data.complete_data_users[0].cantidad);
      sethabilitadoData(response.data.habilitados_actualizar[0].cantidad);
      setAsignadosData(response.data.assigned_users[0].cantidad)
    });
  }, []);

  if (!companies) return null;
  if (!stateData) return null;
  if (!cityData) return null;

  //Inicializando datos que trae la api de get
  function createData(
    completo_estudio_activacion,
    completo,
    fecha_creacion,
    last_visit,
    identificacion,
    nombres,
    apellidos,
    celular,
    placa,
    tipovehiculo,
    cantidad_alerta_total,
    cantidad_habilitacion,
    viajes_realizados,
    verificado,
    estado_registro,
    alertas,
    habilitaciones,
    habilitaciones_vehiculo,
    alertas_vehiculo,
    fotoconductor,
    licenciafrontal,
    licenciatrasera,
    cedulafrontal,
    cedulatrasera,
    seguridadsocial,
    certificacionbancaria,
    soat,
    tecnomecanica,
    tarjetapropiedadfrontal,
    tarjetapropiedadtrasera,
    autorizaciontenedor,
    fotovehiculofrontal,
    fotovehiculolateralderecha,
    fotovehiculolateralizquierda,
    fotovehiculotrasera,
    trailer,
    poliza,
    rut,
    cedulatenedorf,
    cedulatenedort,
    datos_personales,
    doc_personales,
    vehiculo,
    doc_vehiculo,
    tenedor,
    doc_tenedor,
    propietario,
    asignado_a_mi,
    asignado_a_otro,
    habilitar_usuario,
    habilitado_completo
  ) {
    return {
      completo_estudio_activacion,
      completo,
      fecha_creacion,
      last_visit,
      identificacion,
      nombres,
      apellidos,
      celular,
      placa,
      tipovehiculo,
      cantidad_alerta_total,
      cantidad_habilitacion,
      viajes_realizados,
      verificado,
      estado_registro,
      alertas,
      habilitaciones,
      habilitaciones_vehiculo,
      alertas_vehiculo,
      fotoconductor,
      licenciafrontal,
      licenciatrasera,
      cedulafrontal,
      cedulatrasera,
      seguridadsocial,
      certificacionbancaria,
      soat,      
      tecnomecanica,
      tarjetapropiedadfrontal,
      tarjetapropiedadtrasera,
      autorizaciontenedor,
      fotovehiculofrontal,
      fotovehiculolateralderecha,
      fotovehiculolateralizquierda,
      fotovehiculotrasera,
      trailer,
      poliza,
      rut,
      cedulatenedorf,
      cedulatenedort,
      datos_personales,
      doc_personales,
      vehiculo,
      doc_vehiculo,
      tenedor,
      doc_tenedor,
      propietario,
      asignado_a_mi,
      asignado_a_otro,
      habilitar_usuario,
      habilitado_completo
    };
  }

  //Seleccion de datos que se mostraran en la tabla
  const dataCompany = companies.map((item) => {
    return createData(
      item.completo_estudio_activacion,
      item.completo,
      item.fecha_creacion,
      item.last_visit,
      item.identificacion,
      item.nombres,
      item.apellidos,
      item.celular,
      item.placa,
      item.tipovehiculo,
      item.cantidad_alerta_total,
      item.cantidad_habilitacion,
      item.viajes_realizados,
      item.verificado,
      item.estado_registro,
      item.alertas,
      item.habilitaciones,
      item.habilitaciones_vehiculo,
      item.alertas_vehiculo,
      item.fotoconductor,
      item.licenciafrontal,
      item.licenciatrasera,
      item.cedulafrontal,
      item.cedulatrasera,
      item.seguridadsocial,
      item.certificacionbancaria,
      item.soat,      
      item.tecnomecanica,
      item.tarjetapropiedadfrontal,
      item.tarjetapropiedadtrasera,
      item.autorizaciontenedor,
      item.fotovehiculofrontal,
      item.fotovehiculolateralderecha,
      item.pfotovehiculolateralizquierda,
      item.fotovehiculotrasera,
      item.trailer,
      item.poliza,
      item.rut,
      item.cedulatenedorf,
      item.cedulatenedort,
      item.datos_personales,
      item.doc_personales,
      item.vehiculo,
      item.doc_vehiculo,
      item.tenedor,
      item.doc_tenedor,
      item.propietario,
      item.asignado_a_mi,
      item.asignado_a_otro,
      item.habilitar_usuario,
      item.habilitado_completo
    );
  });

  return (
    <>
      {Loading == true ? (
        Loading === "" ? null : (
          <LoadingScreen />
        )
      ) : (
        <Dashboard title="Usuarios Registrados">
          {/* Modal de alerta  */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 4,
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                {errorsMessage}
              </Typography>
            </Box>
          </Modal>
          {/* Mensaje de extio */}
          <Snackbar
            open={openSuccess}
            autoHideDuration={8000}
            onClose={handleClose_success}
          >
            <Alert
              onClose={handleClose_success}
              severity="success"
              sx={{ width: "100%" }}
            >
              {dataMessage}
            </Alert>
            {/* Modal de carga         */}
          </Snackbar>
          <Modal
            open={load}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <LoadingModal />
          </Modal>
          {/* Capsula de verificados */}
          <Grid item xs={12} md={2} lg={29} style={{ display: "flex" }}>
            {/* Capsula de Rechazados */}
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: " #f55c5c ",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  <b>Rechazados</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{recazadoData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(3)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #b95cd6 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
            {/* Capsula de pendiente por completar */}
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: "  #f5f05c ",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: " #81726f ",
                    fontSize: 15,
                  }}
                >
                  <b>Pendiente por completar</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{pendienteData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(0)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #e35233 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
            {/* Capsula de completados */}
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: " #5cf583 ",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: " white",
                    fontSize: 15,
                  }}
                >
                  <b>Completados</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{completadoData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(1)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #b95cd6 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
            {/* Capsula de Verificados */}
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: " #5cb9f5 ",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: " white ",
                    fontSize: 15,
                  }}
                >
                  <b>Verificados</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{verificadoData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(2)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #e35233 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
          </Grid>
          {/* Modal para restaurar contraseña de conductor */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                borderRadius: 8,
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                NUEVA CONTRASEÑA{" "}
                <spam style={{ color: "black" }}>
                  {"- "}
                  {companyData.nombre}
                </spam>
              </Typography>
              <div style={{ float: "rigth" }}>
                {validatorErrors == true ? (
                  validatorErrors === "" ? null : (
                    <Alert severity="error">{errorsMessage}</Alert>
                  )
                ) : (
                  ""
                )}
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box
                  component="form"
                  onSubmit={submitDeleteCompany}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={18}>
                      <TextField
                        autoComplete="given-name"
                        name="pass"
                        required
                        type="text"
                        fullWidth
                        id="pass"
                        label="Contraseña"
                        autoFocus
                        defaultValue={companyData.id}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeViewOffices}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    >
                      {" "}
                      Actualizar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal de alerta de conductor */}
          <Modal
            open={open_editCompany}
            onClose={close_editCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "auto",
                height: "auto",
                bgcolor: "background.paper",
                borderRadius: 8,
                boxShadow: 24,
                p: 2,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                color="red"
              >
                ALERTAS <spam style={{ color: "gray" }}>{"- "}</spam>
                <spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}>
                      {" "}
                      <spam style={{ fontSize: 20 }}>•</spam> Alerta Conductor
                    </label>
                  </Grid>
                  {companyData.alertas && companyData.alertas.length > 0 ? (
                    <>
                      {companyData.alertas.map((alerta, index) => (
                        <div key={index}>
                          <spam style={{ fontSize: 20, padding:10 }}>•</spam><label>{alerta}</label>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div style={{ color: "gray" }}>No tiene alertas</div>
                  )}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}>
                      {" "}
                      <spam style={{ fontSize: 20 }}>• </spam> Alerta Vehiculo
                    </label>
                  </Grid>
                  {companyData.alertas_vehiculo && companyData.alertas_vehiculo.length > 0 ? (
                    <>
                      {companyData.alertas_vehiculo.map((alerta, index) => (
                        <div key={index}>
                          <spam style={{ fontSize: 20, padding:10 }}>•</spam><label>{alerta}</label>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div style={{ color: "gray" }}>No tiene alertas</div>
                  )}
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal de alerta Datacarga */}
          <Modal
            open={open_deleteCompany}
            onClose={close_deleteCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: "auto",
                bgcolor: "background.paper",
                borderRadius: 8,
                boxShadow: 24,
                p: 2,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                color="red"
              >
                Alertas Datacarga <spam style={{ color: "gray" }}>{"- "}</spam>
                <spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}>
                      {" "}
                      <spam style={{ fontSize: 20 }}>•</spam> Habilitado
                      Conductor
                    </label>
                  </Grid>
                  {companyData.habilitaciones == "" ? (
                    <>
                      {
                        <div style={{ color: "gray" }}>
                          {"No tiene alertas"}
                        </div>
                      }
                    </>
                  ) : (
                    <>{companyData.habilitaciones}</>
                  )}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}>
                      {" "}
                      <spam style={{ fontSize: 20 }}>•</spam> Habilitado
                      Vehiculo
                    </label>
                  </Grid>
                  {companyData.habilitaciones_vehiculo == "" ? (
                    <>
                      {
                        <div style={{ color: "gray" }}>
                          {"No tiene alertas"}
                        </div>
                      }
                    </>
                  ) : (
                    <>{companyData.habilitaciones_vehiculo}</>
                  )}
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal para crear transportista */}
          <Modal
            open={modalCreateCarrier}
            onClose={setModalCreateCarrier}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                height: "auto",
                bgcolor: "background.paper",
                borderRadius: 8,
                boxShadow: 24,
                p: 2,
              }}
              component={"form"}
              onSubmit={submitCreateCarrier}
            >
              <Typography
                style={{ margin: 10 }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                Crear Transportista
              </Typography>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="cedula_conductor"
                      required
                      fullWidth
                      type="number"
                      id="cedula_conductor"
                      label="Cédula Conductor"
                      autoFocus
                      minLength={5}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="placa"
                      required
                      fullWidth
                      id="placa"
                      label="Placa"
                      autoFocus
                      inputProps={{
                        maxLength: 6, 
                        minLength: 6
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="celular"
                      required
                      fullWidth
                      id="celular"
                      label="Celular"
                      autoFocus
                      type="number"
                      inputProps={{ min: 1000000000, max: 9999999999 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="placa_trailer"
                      required
                      fullWidth
                      id="placa_trailer"
                      label="Placa Tráiler"
                      autoFocus
                      inputProps={{
                        maxLength: 6, 
                        minLength: 6
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="cedula_tenedor"
                      fullWidth
                      type="number"
                      id="cedula_tenedor"
                      label="Cédula Tenedor (Opcional)"
                      autoFocus
                    />
                  </Grid>
                </Grid>
              </div>
              <Stack
                justifyContent="center"
                style={{ display: "center", margin: 10 }}
                direction="row"
              >
                <Button
                  style={{ background: "red", margin: 10 }}
                  fullWidth
                  variant="contained"
                  onClick={() => setModalCreateCarrier(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  style={{ background: "green", margin: 10 }}
                  fullWidth
                  variant="contained"
                >
                  Crear
                </Button>
              </Stack>
            </Box>
          </Modal>
          {/* Capsula de registrados */}
          <Grid
            item
            xs={12}
            md={2}
            lg={29}
            marginTop={1}
            style={{ display: "flex" }}
          >
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: "white",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: " #81726f ",
                    fontSize: 15,
                  }}
                >
                  <b>Total de Registrados</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{officeData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => refresh()}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #e35233 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: " #cacac4  ",
                display: "flex",
              }}
            >
              {/* Capsula de habiltados para actualizar      */}
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: " #81726f ",
                    fontSize: 15,
                  }}
                >
                  <b>Habilitados para actualizar</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{habilitadoData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(4)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: " #e35233 ",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
            <Box
              sx={{
                marginRight: 1,
                width: 250,
                height: 80,
                borderRadius: 2,
                backgroundColor: "#003F5D",
                display: "flex",
                color: 'white'
              }}
            >
              {/* Capsula de Asignados para actualizar      */}
              <div>
                <div
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    color: "#FFF",
                    fontSize: 15,
                  }}
                >
                  <b>Asignados</b>
                </div>
                <label style={{ marginLeft: 10 }}>
                  <div style={{ marginLeft: 18, marginTop: -20, fontSize: 25 }}>
                    <b>{asignadosData}</b>
                  </div>
                </label>
                <Button
                  onClick={() => handleFilterChange(5)}
                  style={{
                    marginTop: -76,
                    marginLeft: 170,
                    color: "#CD5C5C",
                  }}
                >
                  <Equalizer sx={{ fontSize: 58 }} />
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={29} marginTop={4}>
            <Paper sx={{}}>
              {/* Boton de crear el transportista*/}
              <div
                style={{
                  display: "flex",
                  float: "left",
                  margin: 20,
                  borderRadius: 20,
                  background: "green",
                  width: "auto",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setModalCreateCarrier(true)}
                >
                  <>Crear Transportista</>
                </Button>
              </div>
              {/* Boton de busqueda */}
              <div
                style={{
                  display: "flex",
                  float: "right",
                  marginTop: 20,
                  marginRight: 10,
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => buscador()}
                  sx={{ background: "#FF5733", width: "auto" }}
                >
                  <Search />
                </Button>
              </div>
              {/* Input de busqueda */}
              <div style={{ margin: 10, float: "right" }}>
                <TextField
                  id="filled-basic"
                  label="Buscar (CC, Nombre, Placa)"
                  variant="filled"
                  name="filtro"
                  onChange={handleChangeSearch}
                />
              </div>
              {/* Boton de refresco */}
              <div style={{ float: "right", marginTop: 21, marginRight: 10 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => refresh()}
                  sx={{ float: "right", background: "#FF5733", width: 0 }}
                >
                  <Refresh />
                </Button>
              </div>
              {/* Tabla de datos  */}
              <TableContainer sx={{ maxHeight: 440 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      {/* Headers */}
                      <TableRow backgroundColor={"red"}>
                        <TableCell align="center">
                          <b>Fecha Creación</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Ultima Conexión</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Cédula</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Nombre Completo</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Celular</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Placa</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Tipo Vehiculo</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Alertas</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Viajes Realizados</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Asignado a</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Restablecer</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>HV</b>
                        </TableCell>
                        <TableCell align="center">
                           <b>Estado</b>
                        </TableCell>
                        <TableCell align="center">
                           <b>Datos</b>
                        </TableCell>
                        {
                          rol == 1 ? (
                            <>                              
                              <TableCell align="center">
                                <b>Acciones</b>
                              </TableCell>
                            </>
                          ) : null
                        } 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Lista de documentos que tiene el conductor */}
                      {dataCompany.map((row, id) => {
                        const StyledTableRow = styled(TableRow)(
                          ({ theme }) => ({
                            "&:nth-of-type(odd)": {
                              backgroundColor: theme.palette.action.hover,
                            },
                            // hide last border
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          })
                        );
                        let dataDocument = "";
                        if (row.datos_personales === false) {
                          dataDocument += "• Datos Personales\n";
                        }
                        if (row.doc_personales === false) {
                          dataDocument += "• Documentos Personales\n";
                        }
                        if (row.vehiculo === false) {
                          dataDocument += "• Datos Vehículo\n";
                        }
                        if (row.trailer === false) {  
                          dataDocument += "• Datos Trailer\n";
                        }
                        if (row.doc_vehiculo === false) {
                          dataDocument += "• Documentos Vehículo\n";
                        }
                        if (row.tenedor === false) {
                          dataDocument += "• Datos Tenedor\n";
                        }
                        if (row.doc_tenedor === false) {
                          dataDocument += "• Documentos Tenedor\n";
                        }
                        if (row.propietario === false) {
                          dataDocument += "• Datos Propietario\n";
                        }
                        let color = getStatusColorDriver(row);
                        // row.completo == 0 ? '#f5f05c' : // Rojo
                        // row.estado_registro == 1 ? '#5cf583' : // Verde
                        // (row.estado_registro == 2 ? '#5cb9f5' :  // Azul
                        // row.estado_registro == 3 ? '#f55c5c' : // Rojo
                        // row.estado_registro == 4 ? '#cacac4' : // Gris claro
                        // row.estado_registro == 0 ? '#f5f05c' : // Amarillo
                        // null);
                        return (
                          <>
                            <StyledTableRow
                              key={row.name}
                              style={{ background: color }}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.fecha_creacion == null) |
                                    (row.fecha_creacion == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.fecha_creacion}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.last_visit == null) |
                                    (row.last_visit == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.last_visit}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.identificacion == null) |
                                    (row.identificacion == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.identificacion}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.nombres == null) |
                                    (row.nombres == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>
                                        {row.nombres} {row.apellidos}
                                      </>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.celular == null) |
                                    (row.celular == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.celular}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.placa == null) | (row.placa == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.placa}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.tipovehiculo == null) |
                                    (row.tipovehiculo == "") ? (
                                      <>{"-"}</>
                                    ) : (
                                      <>{row.tipovehiculo}</>
                                    )}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  <>
                                    <div style={{ color: "black" }}>
                                      {row.cantidad_alerta_total == 0 ? (
                                        <>
                                          {""}
                                          <div style={{ color: "green" }}>
                                            <CheckCircle />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            onClick={() =>
                                              openUpdateCompany(row)
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                marginLeft: -10,
                                              }}
                                            >
                                              {
                                                <div style={{ color: "black" }}>
                                                  {row.cantidad_alerta_total}
                                                </div>
                                              }
                                              <div style={{ color: "red" }}>
                                                <Warning />
                                              </div>
                                            </div>
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                }
                              </StyledTableCell>                              
                              <StyledTableCell align="center">
                                {row.viajes_realizados}
                              </StyledTableCell>
                              {/* ASIGNADO A */}
                              <StyledTableCell align="center">
                                {
                                  <>
                                    {(row.asignado_a_mi) ? (
                                      <><span style={{fontWeight: '400', backgroundColor: 'green', color: 'white', whiteSpace: 'nowrap', padding: '0.2em', borderRadius: '5px'}}>Asignado a mi</span></>
                                    ) : row.asignado_a_otro ? (
                                      <><span style={{fontWeight: '400', backgroundColor: 'grey', color: 'white', whiteSpace: 'nowrap', padding: '0.2em', borderRadius: '5px'}}>Asignado a otro</span></>
                                    ) : 
                                    <><span style={{fontWeight: '400', whiteSpace: 'nowrap', padding: '0.2em', borderRadius: '5px'}}>Sin asignar</span></>}
                                  </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Button
                                  style={{ color: "orange" }}
                                  onClick={() => viewDataOffices(row)}
                                >
                                  <Key />
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Button
                                  style={{ color: "green" }}
                                  onClick={() => hojaVida(row)}
                                >
                                  <AccountBox />
                                </Button>
                              </StyledTableCell>
                              {/* AGREGAR AQUI LO DE ESTADo  */}
                              <StyledTableCell align="center">
                                {(row.habilitar_usuario === true && row.habilitado_completo === false) ? 
                                <>
                                  <Button                                    
                                      size="small"
                                      style={{
                                      background: "green",
                                      color: "white",
                                        }}
                                      >
                                      Preaprobado
                                  </Button>
                                </> 
                                :(row.habilitado_completo === true && row.habilitado_completo === true) ? 
                                <>
                                  <Button                                    
                                      size="small"
                                      style={{
                                      background: "green",
                                      color: "white",
                                        }}
                                      >
                                      Habilitado
                                  </Button>
                                </> : 
                                <>
                                  <Button
                                    title="El transportista NO paso las validaciones de seguridad"
                                    size="small"
                                    style={{
                                    background: "grey",
                                    color: "white",
                                      }}
                                    >
                                    Inhabilitado
                                   </Button>                                 
                                </>
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                  {(row.asignado_a_mi === true || row.asignado_a_otro == false) ? //Que este asignado a mí o que no este asignado a nadie
                                  (row.completo_estudio_activacion === false) ? (
                                      <>
                                        <Button
                                          title={dataDocument}
                                          size="small"
                                          style={{
                                            background: "red",
                                            color: "white",
                                          }}
                                        >
                                          Incompleto
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          size="small"
                                          style={{
                                            background: "green",
                                            color: "white",
                                          }}
                                        >
                                          Completo
                                        </Button>
                                      </>
                                    ) : <Block />} 
                              </StyledTableCell> 
                              {(rol == 1) ? (
                                <StyledTableCell align="center">
                                  <div style={{display: 'flex', gap: '1em'}}>
                                    <Button 
                                      style={{color: row.completo_estudio_activacion === false ? 'grey' : 'green'}} title="Enviar a estudio de activación de seguridad" 
                                      onClick={() => row.completo_estudio_activacion === true ? enviarSolicitud(row.completo_estudio_activacion, row.identificacion) : alertmodal()}>
                                      <Send />
                                    </Button>
                                    {/* Si esta en revisión o no esta completado*/}
                                    {
                                      (row.habilitar_usuario === false && row.habilitado_completo === false && row.estado_registro === 0) ?    
                                        <Button 
                                          title="No cumple requisitos de seguridad para iniciar estudio de activación" style={{color: '#000'}}>
                                          <Block />
                                        </Button>
                                      : (row.estado_registro === 0) ? 
                                          (row.asignado_a_mi === false || row.asignado_a_otro == false) ? (
                                            <Button title="Atender solicitud" style={{ color: "green" }} onClick={() => asignarmeSolicitud(row.identificacion)}> 
                                              <Description />
                                            </Button>
                                          ) : (
                                            <Button title="Atender solicitud"  style={{ color: "grey" }}>
                                              <Description />
                                            </Button>
                                          )
                                      : <Button 
                                          style={{color: '#000'}}>                                            
                                          <HowToRegIcon />
                                        </Button>}
                                  </div>
                                </StyledTableCell>
                              ) : null}
                              </StyledTableRow>
                            </>
                          );
                        }
                      )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={dataTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Dashboard>
      )}{" "}
    </>
  );
}