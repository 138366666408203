import React, { useState, useEffect } from 'react';
import API, { AXIOS } from '../../environment/api';
import { Stack, Divider, Grid, TextField, Autocomplete, MenuItem, FormControl, InputLabel, Select, Button, Snackbar, Alert } from '@mui/material';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumVehiculo({func_update, ...props}) {
    const [loading, setLoading] = useState(true);
    const [blockButton, set_blockButton] = useState(false);
    const [dataTypePoliza, array_typePoliza] = useState([]);
    const [dataPoliza, array_Poliza] = useState([]);
    const [dataConfigurationType, array_configurationType] = useState([]);
    const [dataLoadType, array_loadType] = useState([]);
    const [dataHeadBrand, array_headBrand] = useState([]);
    const [dataHeadLine, array_headLine] = useState([]);
    const [dataFuelType, array_fuelType] = useState([]);
    const [dataColor, array_color] = useState([]);
    const [dataBody, array_bodyType] = useState([]);
    const [dataSoatCompany, array_soatCompany] = useState([]);
    const [hv_data, set_hv_data] = useState([]);
    const [severity, setSeverity] = useState("error");
    const [openSnackbar, setOpeSnackbar] = useState(false);
    const [messageResponse, setMessageResponse] = useState("");

    const handleSubmit = (event) => {
        set_blockButton(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        AXIOS.post(API.POST_UPDATE_HV_VEHICULO + props.data.data.placa, {
            "persona_id": props.data.data.persona_id,
            "U_NumeroChasis": data.get("numero_chasis"),
            "U_CantEjes": data.get("cantidad_ejes"),
            "U_TipoVehiculo": data.get("tipovehiculo"),
            "U_TipoConfiguracion": data.get("tipoconfiguracion_id"),
            "U_TipoCarga": data.get("tipocarga"),
            "U_Modelo": data.get("modelo"),
            "U_NumeroMotor": data.get("numero_motor"),
            "U_TipoFlota": data.get("tipoflota"),
            "U_UnidadEmpaque": data.get("unidad_empaque"),
            "U_Color": data.get("color"),
            "U_Carroceria": data.get("carroceria"),
            "U_PesoVacio": data.get("peso_vacio"),
            "U_CapacidadCarga": data.get("peso_capacidad_carga"),
            "U_UmCapacidad": data.get("unidad_capacidad"),
            "U_RT_FechaExp": data.get("tecno_fecha_inic"),
            "U_RT_FechaVen": data.get("tecno_fecha_fin"),
            "U_CompaniaSOAT": data.get("empresa_soat"),
            "U_NumeroSOAT": data.get("numero_soat"),
            "U_FechaInicioSOAT": data.get("fechainicio_soat"),
            "U_FechaFinSOAT": data.get("fechafin_soat"),
            "U_CargaAdministra": data.get("carga_administrativa"),
            "U_MarcaCabezote" : data.get("marca_cabezote"),
            "U_TipoCombustible": data.get("tipocombustible"),
            "U_TipoConductorVehiculo": data.get("conductor_vehiculo_tipo"),
            "U_LineaCabezote": data.get("lineacabezote"),
            "U_TipoPoliza": data.get("type_poliza"),
            "U_Poliza": data.get("poliza_empresa"),
            "U_PolizaId": data.get("poliza_empresa"),
            "U_NoPoliza": data.get("no_poliza"),
            "U_PolizaInicio": data.get("poliza_fecha_inicio"),
            "U_PolizaFin": data.get("poliza_fecha_fin"),
        }).then(function (data) {
            setSeverity("success");
            setMessageResponse("Datos actualizados correctamente");
            func_update('put_vehiculo', true); // Notificamos a CurriculumPageNew que se han llenado todos los datos del vehiculo
            setOpeSnackbar(true);
            set_blockButton(false);
        }
        ).catch(function (error) {
            setSeverity("error");
            setMessageResponse("Error al actualizar los datos");
            setOpeSnackbar(true);
            set_blockButton(false);
        });
    };

    useEffect(() => {
        if (props.data.vehiculo_disponible[0] === undefined) {
            set_hv_data({
                "persona_id": props.data.data.persona_id,
                "numero_chasis": props.data.data.numero_chasis,
                "cantidad_ejes": props.data.data.cantidad_ejes,
                "tipovehiculo_id": props.data.data.tipovehiculo_id,
                "tipoconfiguracion_codigo": props.data.data.tipoconfiguracion_codigo,
                "tipocarga_id": props.data.data.tipocarga_id,
                "modelo": props.data.data.modelo,
                "tipoflota_codigo": props.data.data.tipoflota_codigo,
                "unidad_empaque": props.data.data.unidad_empaque,
                "color": props.data.data.color_codigo,
                "carroceria_id": props.data.data.carroceria_id,
                "peso_vacio": props.data.data.peso_vacio,
                "peso_capacidad_carga": props.data.data.peso_capacidad_carga,
                "unidad_capacidad_id": props.data.data.unidad_capacidad_id,
                "fechaexpedicion_rtm": props.data.data.fechaexpedicion_rtm,
                "fechavigente_rtm": props.data.data.fechavigente_rtm,
                "numero_soat": props.data.data.numero_soat,
                "codigoempresa_soat": props.data.data.codigoempresa_soat,
                "fechainicio_soat": props.data.data.fechainicio_soat,
                "fechafin_soat": props.data.data.fechafin_soat,
                "carga_administrativa_codigo": props.data.data.carga_administrativa_codigo,
                "conductor_vehiculo_tipo_id": props.data.data.conductor_vehiculo_tipo_id,
                "no_poliza": props.data.data.no_poliza,
                "poliza_empresa_id": props.data.data.poliza_empresa_id,
                "poliza_fecha_inicio": props.data.data.poliza_fecha_inicio,
                "poliza_fecha_fin": props.data.data.poliza_fecha_fin,
                "lineacabezote":props.data.data.lineacabezote_codigo,
            });
        } else {
            set_hv_data({
                "numero_chasis": props.data.vehiculo_disponible[0].numero_chasis,
                "cantidad_ejes": props.data.vehiculo_disponible[0].cantidad_ejes,
                "tipovehiculo_id": props.data.vehiculo_disponible[0].tipovehiculo_id,
                "tipoconfiguracion_codigo": props.data.vehiculo_disponible[0].tipoconfiguracion_codigo,
                "tipocarga_id": props.data.vehiculo_disponible[0].tipocarga_id,
                "modelo": props.data.vehiculo_disponible[0].modelo,
                "tipoflota_codigo": props.data.vehiculo_disponible[0].tipoflota_codigo,
                "unidad_empaque": props.data.vehiculo_disponible[0].unidad_empaque,
                "color": props.data.vehiculo_disponible[0].color_codigo,
                "carroceria_id": props.data.vehiculo_disponible[0].carroceria_id,
                "peso_vacio": props.data.vehiculo_disponible[0].peso_vacio,
                "peso_capacidad_carga": props.data.vehiculo_disponible[0].peso_capacidad_carga,
                "unidad_capacidad_id": props.data.vehiculo_disponible[0].unidad_capacidad_id,
                "fechaexpedicion_rtm": props.data.vehiculo_disponible[0].fechaexpedicion_rtm,
                "fechavigente_rtm": props.data.vehiculo_disponible[0].fechavigente_rtm,
                "numero_soat": props.data.vehiculo_disponible[0].numero_soat,
                "codigoempresa_soat": props.data.vehiculo_disponible[0].codigoempresa_soat,
                "fechainicio_soat": props.data.vehiculo_disponible[0].fechainicio_soat,
                "fechafin_soat": props.data.vehiculo_disponible[0].fechafin_soat,
                "carga_administrativa_codigo": props.data.vehiculo_disponible[0].carga_administrativa_codigo,
                "conductor_vehiculo_tipo_id": props.data.vehiculo_disponible[0].conductor_vehiculo_tipo_id,
                "no_poliza": props.data.vehiculo_disponible[0].no_poliza,
                "poliza_empresa_id": props.data.vehiculo_disponible[0].poliza_empresa_id,
                "poliza_fecha_inicio": props.data.vehiculo_disponible[0].poliza_fecha_inicio,
                "poliza_fecha_fin": props.data.vehiculo_disponible[0].poliza_fecha_fin,
                "lineacabezote":props.data.vehiculo_disponible[0].lineacabezote_codigo,
            });
        }
        //Lista poliza
        AXIOS.get(API.GET_POLIZA, {
        }).then((response) => {
            array_Poliza(Array.isArray(response.data) ? response.data : []);
        })
        //Lista de SOAT
        AXIOS.get(API.SOAT_SAP, {
        }).then((response) => {
            array_soatCompany(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de carroceria
        AXIOS.get(API.TIPO_CARROCERIA_SAP, {
        }).then((response) => {
            array_bodyType(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de tipo de carga
        AXIOS.get(API.TIPO_CARGA, {
        }).then((response) => {
            array_loadType(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de colores
        AXIOS.get(API.COLOR_SAP, {
        }).then((response) => {
            array_color(Array.isArray(response.data) ? response.data : []);
        });
        //Lista tipo poliza
        AXIOS.get(API.GET_TIPO_POLIZA, {
        }).then((response) => {
            array_typePoliza(Array.isArray(response.data) ? response.data : []);
        });
        //Lista marca de cabezote
        AXIOS.get(API.MARCA_CABEZO, {
        }).then((response) => {
            array_headBrand(Array.isArray(response.data) ? response.data : []);
        });
        //Lista linea de cabezote
        AXIOS.get(API.LINEA_CABEZOTE, {
        }).then((response) => {
            array_headLine(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de combustible    
        AXIOS.get(API.TIPO_COMBUSTI, {
        }).then((response) => {
            array_fuelType(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de tipo de config.
        AXIOS.get(API.TIPO_CONFIG, {
        }).then((response) => {
            array_configurationType(Array.isArray(response.data) ? response.data : []);
        }).finally(() => {
            setLoading(false);
        });
    },
        []
    );

    const handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpeSnackbar(false);
      };

    // const notifyStatus = () => {
    //     onData("Notify")
    // }

    //LISTA VEHICULO
    const vehicles_select = [
        { id: 1, nombre: "Turbo" },
        { id: 2, nombre: "Sencillo" },
        { id: 3, nombre: "Mula" },
        { id: 4, nombre: "Mini Mula" },
        { id: 5, nombre: "Doble Troque" },
        { id: 6, nombre: "Carry" },
        { id: 7, nombre: "Camioneta" },
        { id: 8, nombre: "Cuatro Manos" }
    ];

    return (
        <>
            {loading ?
                <LoadingScreen />
                : (
                    <>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
                            <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
                                {messageResponse}
                            </Alert>
                        </Snackbar>
                        <Grid component={"form"} onSubmit={handleSubmit}>
                            <Grid container spacing={0} justifyContent="center" style={{ marginTop: 5, display: "center" }}>
                                <h4>VEHICULO</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center", }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="numero_chasis" label="Número de chasís" variant="standard" defaultValue={hv_data.numero_chasis} />
                                <FormControl variant="standard" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Cantidad de ejes</InputLabel>
                                    <Select
                                        disabled
                                        required
                                        defaultValue={hv_data.cantidad_ejes}
                                        labelId="demo-simple-select-filled-label"
                                        id="standard-required"
                                        name="cantidad_ejes"
                                    >
                                        <MenuItem value="0">0</MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="6">6</MenuItem>
                                        <MenuItem value="7">7</MenuItem>
                                        <MenuItem value="8">8</MenuItem>
                                        <MenuItem value="9">9</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de Vehiculo</InputLabel>
                                    <Select
                                        disabled
                                        required
                                        defaultValue={hv_data.tipovehiculo_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipovehiculo"
                                        name="tipovehiculo"
                                    >
                                        {
                                            vehicles_select.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="standard" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de Configuración</InputLabel>
                                    <Select
                                        disabled
                                        required
                                        defaultValue={hv_data.tipoconfiguracion_codigo}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipoconfiguracion_id"
                                        name="tipoconfiguracion_id"
                                    >
                                        {
                                            dataConfigurationType.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.U_Descripcion}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de Carga</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.tipocarga_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipocarga"
                                        name="tipocarga"
                                    >
                                        {
                                            dataLoadType.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="modelo" label="Modelo" variant="standard" defaultValue={hv_data.modelo} />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="num_tarj_propiedad" label="N° tarjeta de propiedad" variant="standard" defaultValue={props.data.data.numero_tarjeta_propiedad} />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="numero_motor" label="Número de motor" variant="standard" defaultValue={props.data.data.numero_motor} />
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="placa" label="Placa" variant="standard" defaultValue={props.data.data.placa} />
                                <FormControl variant="filled" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de flota</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.tipoflota_codigo}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipoflota"
                                        name="tipoflota"
                                    >
                                        <MenuItem value={"001"}>INTERNO</MenuItem>
                                        <MenuItem value={"002"}>EXTERNO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="flex-start" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <Autocomplete
                                    disablePortal
                                    required
                                    disabled
                                    name="marca_cabezote"
                                    id="marca_cabezote"
                                    options={dataHeadBrand.map((option) => option.Name)}
                                    defaultValue={props.data.data.marcacabezote_codigo}
                                    sx={{ marginLeft: 2, width: '28.5%', marginTop: -1 }}
                                    renderInput={(params) => <TextField {...params} required label="Marca de cabezote" name="marca_cabezote" id="filled-basic" />} />
                                <Autocomplete
                                    disablePortal
                                    required
                                    disabled
                                    name="lineacabezote"
                                    id="lineacabezote"
                                    options={dataHeadLine ? dataHeadLine.map((option) => option.U_Nombre) : []}
                                    defaultValue={hv_data.lineacabezote}
                                    sx={{ marginLeft: 2, width: '28.5%', marginTop: -1 }}
                                    renderInput={(params) => <TextField {...params} required label="Linea de cabezote" name="lineacabezote" id="filled-basic" />} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '27%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Unidad de Empaque</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="unidad_empaque"
                                        required
                                        name="unidad_empaque"
                                        label="Unidad de Empaque"
                                        defaultValue={hv_data.unidad_empaque}
                                    >
                                        <MenuItem value={"NINGUNO"}>NINGUNO</MenuItem>
                                        <MenuItem value={"PAQUETES"}>PAQUETES</MenuItem>
                                        <MenuItem value={"BULTOS"}>BULTOS</MenuItem>
                                        <MenuItem value={"GRANEL LIQUIDO"}>GRANEL LIQUIDO</MenuItem>
                                        <MenuItem value={"CONTENEDOR DE 20 PIES"}>CONTENEDOR DE 20 PIES</MenuItem>
                                        <MenuItem value={"CONTENEDOR DE 40 PIES"}>CONTENEDOR DE 40 PIES</MenuItem>
                                        <MenuItem value={"CILINDROS"}>CILINDROS</MenuItem>
                                        <MenuItem value={"GRANEL SOLIDO"}>GRANEL SOLIDO</MenuItem>
                                        <MenuItem value={"CARGA ESTIBADA"}>CARGA ESTIBADA</MenuItem>
                                        <MenuItem value={"PALET"}>PALET</MenuItem>
                                        <MenuItem value={"TAMBOR"}>TAMBOR</MenuItem>
                                        <MenuItem value={"ISOTANQUE"}>ISOTANQUE</MenuItem>
                                        <MenuItem value={"PIMPINA"}>PIMPINA</MenuItem>
                                        <MenuItem value={"CAJA"}>CAJA</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="standard" sx={{ m: -2, marginLeft: 2, width: '100%', marginTop: 1 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de combustible</InputLabel>
                                    <Select
                                        required
                                        disabled
                                        defaultValue={props.data.data.tipocombustible_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipocombustible"
                                        name="tipocombustible"
                                    >
                                        {
                                            dataFuelType.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Autocomplete
                                    disablePortal
                                    required
                                    disabled
                                    id="color"
                                    name="color"
                                    options={dataColor.map((option) => option.U_Descripcion)}
                                    defaultValue={hv_data.color}
                                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                    renderInput={(params) => <TextField {...params} required label="Color" name="color" id="filled-basic" />} />
                                <FormControl variant="standard" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de carroceria</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.carroceria_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="carroceria"
                                        disabled
                                        name="carroceria"
                                    >
                                        {
                                            dataBody.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled type="number" sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="peso_vacio" defaultValue={hv_data.peso_vacio} label="Peso vacio" variant="standard" />
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="peso_capacidad_carga" defaultValue={hv_data.peso_capacidad_carga} label="Capacidad de carga" variant="filled" />
                                <FormControl variant="standard" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Unidad de peso</InputLabel>
                                    <Select
                                        required
                                        disabled
                                        defaultValue={hv_data.unidad_capacidad_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="unidad_capacidad"
                                        name="unidad_capacidad"
                                    >
                                        <MenuItem value={1}>KILOGRAMOS</MenuItem>
                                        <MenuItem value={2}>GALONES</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl fullWidth sx={{ marginLeft: 2, marginTop: 2 }}>
                                    <TextField
                                        disabled
                                        required
                                        focused
                                        variant="standard"
                                        id="standard-basic"
                                        type="date"
                                        name="tecno_fecha_inic"
                                        defaultValue={hv_data.fechaexpedicion_rtm}
                                        label="Inicio Tecnicomecanica"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ marginLeft: 2, marginTop: 2 }}>
                                    <TextField
                                        disabled
                                        required
                                        focused
                                        variant="standard"
                                        id="standard-basic"
                                        type="date"
                                        name="tecno_fecha_fin"
                                        defaultValue={hv_data.fechavigente_rtm}
                                        label="Fin Tecnicomecanica"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                </FormControl>    
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} type="number" required id="standard-required" defaultValue={hv_data.numero_soat} name="numero_soat" label="Número de SOAT" variant="standard" />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="standard" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Compañia de SOAT</InputLabel>
                                    <Select
                                        disabled
                                        required
                                        defaultValue={hv_data.codigoempresa_soat}
                                        labelId="demo-simple-select-filled-label"
                                        id="empresa_soat"
                                        name="empresa_soat"
                                    >
                                        {
                                            dataSoatCompany.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.U_Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" name="fechainicio_soat" defaultValue={hv_data.fechainicio_soat} label="Fecha de inicio del SOAT" focused />
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" type="date" name="fechafin_soat" defaultValue={hv_data.fechafin_soat} label="Fecha de fin SOAT" focused />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo Carga Administrativa</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.carga_administrativa_codigo}
                                        labelId="demo-simple-select-filled-label"
                                        id="carga_administrativa"
                                        name="carga_administrativa"
                                    >
                                        <MenuItem value={1}>KILOGRAMOS</MenuItem>
                                        <MenuItem value={2}>GALONES</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo Conductor Vehiculo</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.conductor_vehiculo_tipo_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="conductor_vehiculo_tipo"
                                        name="conductor_vehiculo_tipo"
                                    >
                                        <MenuItem value={1}>CONDUCTOR DE UN VEHICULO TERCERO</MenuItem>
                                        <MenuItem value={2}>CONDUCTOR DE UN VEHICULO PROPIO</MenuItem>
                                        <MenuItem value={3}>PROPIETARIO O TENEDOR DE UN VEHICULO CON CONDUCTOR DIFERENTE</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%' }} required id="standard-required" name="no_poliza" defaultValue={hv_data.no_poliza} label="Número de poliza RCE" variant="filled" />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo de poliza *</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.vehiculo_tipo_poliza}
                                        labelId="demo-simple-select-filled-label"
                                        id="type_poliza"
                                        name="type_poliza"
                                    >
                                        {
                                            dataTypePoliza.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Compañia de poliza *</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_data.poliza_empresa_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="poliza_empresa"
                                        name="poliza_empresa"
                                    >
                                        {
                                            dataPoliza.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.U_Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 40 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={3}>
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="poliza_fecha_inicio" type="date" label="Fecha de inicio poliza" defaultValue={hv_data.poliza_fecha_inicio} focused />
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="poliza_fecha_fin" type="date" label="Fecha de fin poliza" defaultValue={hv_data.poliza_fecha_fin} focused />
                            </Stack>
                            <Grid container
                                spacing={1}
                                justifyContent="flex-end"
                                style={{ marginTop: 30, display: "center" }}>
                                    {
                                        (props.permiso !== 3 || props.rolSession === 13) ? 
                                            <Button variant="contained" sx={{ borderRadius: 0, background: "#3498db", padding: "12" }} disabled={blockButton} type="submit">
                                                GUARDAR CAMBIOS
                                            </Button>
                                        : <></>
                                    }
                                
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </>
    );
}