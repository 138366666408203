//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import API, { AXIOS } from '../environment/api';
import LoadingScreen from './LoadingScreen';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Avatar from '@mui/material/Avatar';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);



const StaticPage = () => {
  //Formato de fecha
  var today = new Date();
  var day = today.getDate();
  var months = (1 + today.getMonth());
  if (months < 10) {
    var month = ("0" + months);
  } else {
    var month = months;
  }
  var year = today.getFullYear();
  //Funcion para inicializar los datos
  const [data1, setData1] = React.useState('');
  const [dataLabel, setDataLabel] = React.useState('');
  const [dataLabel2, setDataLabel2] = React.useState('');
  const [dataLabel3, setDataLabel3] = React.useState('');
  const [data2, setData2] = React.useState('');
  const [data3, setData3] = React.useState('');
  const [dateHour, setHour] = React.useState('');
  const [dateHourInteresed, setHourIntersed] = React.useState('');
  const [DATAONEDATE, setDataOneDate] = React.useState('');
  const [dataTwoDate, setDataTwoDate] = React.useState('');
  const [dataTrheeDate, setDataTrheeDate] = React.useState('');
  const [dataFourDate, setDataFourDate] = React.useState('');
  const [dataFiveDate, setDataFiveDate] = React.useState('');
  const [dataSixDate, setDataSixDate] = React.useState('');
  const [dataSevenDate, setDataSevenDate] = React.useState('');
  const [dataOne, setDataOne] = React.useState('');
  const [dataTwo, setDataTwo] = React.useState('');
  const [dataTrhee, setDataTrhee] = React.useState('');
  const [dataFour, setDataFour] = React.useState('');
  const [dataFive, setDataFive] = React.useState('');
  const [dataSix, setDataSix] = React.useState('');
  const [dataSeven, setDataSeven] = React.useState('');
  const [lists, setLists] = React.useState([]);
  const [api_transportista, setTransportista] = React.useState([]);
  const [api_viajes, setViajes] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [monthDate_empresa, setMonth_empresa] = React.useState(month);
  const [yearDate_empresa, setYear_empresa] = React.useState(year);
  const [monthDate_transportista, setMonth_transportista] = React.useState(month);
  const [yearDate_transportista, setYear_transportista] = React.useState(year);
  const [monthDate_ruta, setMonth_ruta] = React.useState(month);
  const [yearDate_ruta, setYear_ruta] = React.useState(year);
  const [dateBegins, setDateBegin] = React.useState("2019-03-01");
  const [dateEnds, setDateEnd] = React.useState(year + "-" + month + "-" + day);
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion cerrar modal de carga
  const closeLoad = () => setLoad(false);

  //FUncion para descargar archivo .txt 
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById('tableta').value],
      { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  }

  //Funcion para filtrar por fecha inicio
  const dateBegin = (event) => {
    openLoad();
    setDateBegin(event.target.value);
    AXIOS.post(API.GET_ESTADISTICA, {
      "fecha_inicio": (event.target.value),
      "fecha_fin": dateEnds,
      "instancia_id": "154"
    }).then((response) => {
      closeLoad();
      if (response.data == [] | response === '') {
        setHour('0');
        setHourIntersed('');
        setDataOneDate('-');
        setDataOne('0');
        setDataTwoDate('-');
        setDataTwo('0');
        setDataTrheeDate('-');
        setDataTrhee('0');
        setDataFourDate('-');
        setDataFour('0');
        setDataFiveDate('-');
        setDataFive('0');
        setDataSixDate('-');
        setDataSix('0');
        setDataSevenDate('-');
        setDataSeven('0');
        setData1('');
        setDataLabel('-');
        setDataLabel2('-');
        setDataLabel3('-');
        setData2('');
        setData3('');
        closeLoad();
      } else {
        if (response.data[0]) {
          setData1(response.data[0]);
          setDataLabel(response.data[0]);
        } else {
          setData1('');
          setDataLabel('-');
        };
        if (response.data[1]) {
          setData2(response.data[1]);
          setDataLabel2(response.data[1]);
        } else {
          setData2('');
          setDataLabel2('-');
        };
        if (response.data[2]) {
          setData3(response.data[2]);
          setDataLabel3(response.data[2]);
        } else {
          setData3('');
          setDataLabel3('-');
        };
        if (response.data[0].horas_mas_interesados[0]) {
          setHour(response.data[0].horas_mas_interesados[0].hora);
          setHourIntersed(response.data[0].horas_mas_interesados[0].interesados);
        } else {
          setHour('0');
          setHourIntersed('');
        };
        if (response.data[0].dias_mas_interesados[0]) {
          setDataOneDate(response.data[0].dias_mas_interesados[0].dia);
          setDataOne(response.data[0].dias_mas_interesados[0].interesados);
        } else {
          setDataOneDate('-');
          setDataOne('0');
        };
        if (response.data[0].dias_mas_interesados[1]) {
          setDataTwoDate(response.data[0].dias_mas_interesados[1].dia);
          setDataTwo(response.data[0].dias_mas_interesados[1].interesados);
        } else {
          setDataTwoDate('-');
          setDataTwo('0');
        };
        if (response.data[0].dias_mas_interesados[2]) {
          setDataTrheeDate(response.data[0].dias_mas_interesados[2].dia);
          setDataTrhee(response.data[0].dias_mas_interesados[2].interesados);
        } else {
          setDataTrheeDate('-');
          setDataTrhee('0');
        };
        if (response.data[0].dias_mas_interesados[3]) {
          setDataFourDate(response.data[0].dias_mas_interesados[3].dia);
          setDataFour(response.data[0].dias_mas_interesados[3].interesados);
        } else {
          setDataFourDate('-');
          setDataFour('0');
        };
        if (response.data[0].dias_mas_interesados[4]) {
          setDataFiveDate(response.data[0].dias_mas_interesados[4].dia);
          setDataFive(response.data[0].dias_mas_interesados[4].interesados);
        } else {
          setDataFiveDate('-');
          setDataFive('0');
        };
        if (response.data[0].dias_mas_interesados[5]) {
          setDataSixDate(response.data[0].dias_mas_interesados[5].dia);
          setDataSix(response.data[0].dias_mas_interesados[5].interesados);
        } else {
          setDataSixDate('-');
          setDataSix('0');
        };
        if (response.data[0].dias_mas_interesados[6]) {
          setDataSevenDate(response.data[0].dias_mas_interesados[6].dia);
          setDataSeven(response.data[0].dias_mas_interesados[6].interesados);
        } else {
          setDataSevenDate('-');
          setDataSeven('0');
        };
        closeLoad();
      };
    });
  };
  //Funcion para filtrar por fecha fin
  const dateEnd = (event) => {
    openLoad();
    setDateEnd(event.target.value);
    AXIOS.post(API.GET_ESTADISTICA, {
      "fecha_inicio": dateBegins,
      "fecha_fin": (event.target.value),
      "instancia_id": "154"
    }).then((response) => {
      closeLoad();
      if (response.data == []) {
        setHour('0');
        setHourIntersed('');
        setDataOneDate('-');
        setDataOne('0');
        setDataTwoDate('-');
        setDataTwo('0');
        setDataTrheeDate('-');
        setDataTrhee('0');
        setDataFourDate('-');
        setDataFour('0');
        setDataFiveDate('-');
        setDataFive('0');
        setDataSixDate('-');
        setDataSix('0');
        setDataSevenDate('-');
        setDataSeven('0');
        setData1('');
        setDataLabel('-');
        setDataLabel2('-');
        setDataLabel3('-');
        setData2('');
        setData3('');
        closeLoad();
      } else {

        if (response.data[0]) {
          setData1(response.data[0]);
          setDataLabel(response.data[0]);
        } else {
          setData1('');
          setDataLabel('-');
        };
        if (response.data[1]) {
          setData2(response.data[1]);
          setDataLabel2(response.data[1]);
        } else {
          setData2('');
          setDataLabel2('-');
        };
        if (response.data[2]) {
          setData3(response.data[2]);
          setDataLabel3(response.data[2]);
        } else {
          setData3('');
          setDataLabel3('-');
        };


        if (response.data[0].horas_mas_interesados[0]) {
          setHour(response.data[0].horas_mas_interesados[0].hora);
          setHourIntersed(response.data[0].horas_mas_interesados[0].interesados);
        } else {
          setHour('0');
          setHourIntersed('');
        };
        if (response.data[0].dias_mas_interesados[0]) {
          setDataOneDate(response.data[0].dias_mas_interesados[0].dia);
          setDataOne(response.data[0].dias_mas_interesados[0].interesados);
        } else {
          setDataOneDate('-');
          setDataOne('0');
        };
        if (response.data[0].dias_mas_interesados[1]) {
          setDataTwoDate(response.data[0].dias_mas_interesados[1].dia);
          setDataTwo(response.data[0].dias_mas_interesados[1].interesados);
        } else {
          setDataTwoDate('-');
          setDataTwo('0');
        };
        if (response.data[0].dias_mas_interesados[2]) {
          setDataTrheeDate(response.data[0].dias_mas_interesados[2].dia);
          setDataTrhee(response.data[0].dias_mas_interesados[2].interesados);
        } else {
          setDataTrheeDate('-');
          setDataTrhee('0');
        };
        if (response.data[0].dias_mas_interesados[3]) {
          setDataFourDate(response.data[0].dias_mas_interesados[3].dia);
          setDataFour(response.data[0].dias_mas_interesados[3].interesados);
        } else {
          setDataFourDate('-');
          setDataFour('0');
        };
        if (response.data[0].dias_mas_interesados[4]) {
          setDataFiveDate(response.data[0].dias_mas_interesados[4].dia);
          setDataFive(response.data[0].dias_mas_interesados[4].interesados);
        } else {
          setDataFiveDate('-');
          setDataFive('0');
        };
        if (response.data[0].dias_mas_interesados[5]) {
          setDataSixDate(response.data[0].dias_mas_interesados[5].dia);
          setDataSix(response.data[0].dias_mas_interesados[5].interesados);
        } else {
          setDataSixDate('-');
          setDataSix('0');
        };
        if (response.data[0].dias_mas_interesados[6]) {
          setDataSevenDate(response.data[0].dias_mas_interesados[6].dia);
          setDataSeven(response.data[0].dias_mas_interesados[6].interesados);
        } else {
          setDataSevenDate('-');
          setDataSeven('0');
        };
        closeLoad();
      };
    });
  };

  React.useEffect(() => {
    //Pre-carga de apis    
    AXIOS.post(API.GET_ESTADISTICA, {
      "fecha_inicio": "2019-01-01",
      "fecha_fin": dateEnds,
      "instancia_id": "154"
    }).then((response) => {

      if (response.data[0].horas_mas_interesados[0]) {
        setHour(response.data[0].horas_mas_interesados[0].hora);
        setHourIntersed(response.data[0].horas_mas_interesados[0].interesados);
      } else {
        setHour('0');
        setHourIntersed('');
      };

      if (response.data[0].dias_mas_interesados[0]) {
        setDataOneDate(response.data[0].dias_mas_interesados[0].dia);
        setDataOne(response.data[0].dias_mas_interesados[0].interesados);
      } else {
        setDataOneDate('-');
        setDataOne('0');
      };
      if (response.data[0].dias_mas_interesados[1]) {
        setDataTwoDate(response.data[0].dias_mas_interesados[1].dia);
        setDataTwo(response.data[0].dias_mas_interesados[1].interesados);
      } else {
        setDataTwoDate('-');
        setDataTwo('0');
      };
      if (response.data[0].dias_mas_interesados[2]) {
        setDataTrheeDate(response.data[0].dias_mas_interesados[2].dia);
        setDataTrhee(response.data[0].dias_mas_interesados[2].interesados);
      } else {
        setDataTrheeDate('-');
        setDataTrhee('0');
      };
      if (response.data[0].dias_mas_interesados[3]) {
        setDataFourDate(response.data[0].dias_mas_interesados[3].dia);
        setDataFour(response.data[0].dias_mas_interesados[3].interesados);
      } else {
        setDataFourDate('-');
        setDataFour('0');
      };
      if (response.data[0].dias_mas_interesados[4]) {
        setDataFiveDate(response.data[0].dias_mas_interesados[4].dia);
        setDataFive(response.data[0].dias_mas_interesados[4].interesados);
      } else {
        setDataFiveDate('-');
        setDataFive('0');
      };
      if (response.data[0].dias_mas_interesados[5]) {
        setDataSixDate(response.data[0].dias_mas_interesados[5].dia);
        setDataSix(response.data[0].dias_mas_interesados[5].interesados);
      } else {
        setDataSixDate('-');
        setDataSix('0');
      };
      if (response.data[0].dias_mas_interesados[6]) {
        setDataSevenDate(response.data[0].dias_mas_interesados[6].dia);
        setDataSeven(response.data[0].dias_mas_interesados[6].interesados);
      } else {
        setDataSevenDate('-');
        setDataSeven('0');
      };
      setData1(response.data[0]);
      setData2(response.data[1]);
      setData3(response.data[2]);
      setDataLabel(response.data[0]);
      setDataLabel2(response.data[1]);
      setDataLabel3(response.data[2]);
      setLoading(false);
    });
    AXIOS.get(API.PRUEBA_DATA, {
    }).then((response) => {
      setLoading(false);
    });
  }, []);
  //Opciones de plugin
  const options_visualitation = {
    responsive: true,
    plugins: {
    },
  };
  //Labeles de tabla
  const labels_visualitation = [dataLabel, dataLabel2, dataLabel3];
  const one_visualitation = { "1° Anuncio": data1.visualizaciones_unicas, "2° Anuncio": data2.visualizaciones_unicas, "3° Anuncio": data3.visualizaciones_unicas };
  const two_visualitation = { "1° Anuncio": data1.cantidad_interesados, "2° Anuncio": data2.cantidad_interesados, "3° Anuncio": data3.cantidad_interesados };
  //Opciones de tabla
  const data_visualitation = {
    labels_visualitation,
    datasets: [
      {
        label: 'Visualizaciones',
        data: one_visualitation,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Cantidad de interesados',
        data: two_visualitation,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  //Labeles de segunda tabla
  const labels_interested = [dataLabel, dataLabel2, dataLabel3];
  const one_interested = { "1° Anuncio": data1.cantidad_interesados, "2° Anuncio": data2.cantidad_interesados, "3° Anuncio": data3.cantidad_interesados };

  const data_interested = {
    labels_interested,
    datasets: [
      {
        label: 'Interesados',
        data: one_interested,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };
  //Info de tabla  
  const one_days = { "Dia 1": dataOne, "Dia 2": dataTwo, "Dia 3": dataTrhee, "Dia 4": dataFour, "Dia 5": dataFive, "Dia 6": dataSix, "Dia 7": dataSeven };
  // const one_dayXD = data1D.map((row) => (
  //   {asd}
  //   )
  // );
  const labels_days = [DATAONEDATE, dataTwoDate, dataTrheeDate, dataFourDate];
  const data_days = {
    labels_days,
    datasets: [
      {

        data: one_days,
        label: 'Interesados',
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
      // {
      //   label: 'Dataset 2',
      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };

  //Cantidad de datos para mostrar
  const one_hours = { '6': dateHour, '7': '0', '9': '0', '10': '0', '11': '0', '12': '0', '13': '0', '14': '0', '15': '0', '16': '0', '17': '0', '18': '0', '19': '0', '20': '0', '21': '0', '22': '0', '23': '0' };
  const two_hours = { '6': dateHourInteresed, '7': '0', '9': '0', '10': '0', '11': '0', '12': '0', '13': '0', '14': '0', '15': '0', '16': '0', '17': '0', '18': '0', '19': '0', '20': '0', '21': '0', '22': '0', '23': '0' };

  const labels_hours = ['6', '7', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

  const data_hours = {
    labels_hours,
    datasets: [
      {

        data: one_hours,
        label: 'Hora',
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {

        data: two_hours,
        label: 'Interesado',
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };


  return (<>
    {(Loading === true) ?
      (Loading === "") ? null :
        <LoadingScreen /> : <>
        {/* Modal de carga       */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        <Dashboard title='Estadísticas de anuncios'>
          <Grid size="small" style={{ margin: 10, float: 'right', display: "flex" }}>
            {/* Boton para filtrar por fecha inicio */}
            <div>
              <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                fullWidth id="upload_date" label="Desde" autoFocus
                onChange={dateBegin}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* Boton para filtrar por fecha fin         */}
            <div style={{ marginLeft: 10 }}>
              <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                fullWidth id="upload_date" label="Hasta" autoFocus
                onChange={dateEnd}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          {/* Vista de estadistica */}
          <Grid item xs={12} md={8} lg={29}>
            <Paper
              sx={{
                p: 2,
                flexDirection: 'column',
                height: "auto",
              }}
            >
              <h3>Visualizaciones</h3>
              <Bar options={options_visualitation} data={data_visualitation} />

              <div style={{ display: "flex", marginLeft: 18, marginTop: 13 }}>
                <Typography style={{ display: "flex" }}><b>1° Anuncio:</b><Avatar alt={dataLabel.imagen} src={dataLabel.imagen} /> {dataLabel.ad_id}<br /><b style={{ marginLeft: 25 }}>2° Anuncio:</b><Avatar alt={dataLabel2.imagen} src={dataLabel2.imagen} /> {dataLabel2.ad_id}<br /><b style={{ marginLeft: 25 }}>3° Anuncio:</b><Avatar alt={dataLabel3.imagen} src={dataLabel3.imagen} /> {dataLabel3.ad_id}</Typography>
              </div>
              <div style={{ marginTop: 20 }}></div>
            </Paper>
          </Grid>
          <div style={{ marginTop: 30 }} />
          <Grid item xs={12} md={8} lg={29}>
            <Paper
              sx={{
                p: 2,
                flexDirection: 'column',
                height: "auto",
              }}
            >
              <h3>Interesados</h3>
              <Bar options={options_visualitation} data={data_interested} />
              <div style={{ display: "flex", marginLeft: 18, marginTop: 13 }}>
                <Typography style={{ display: "flex" }}><b>1° Anuncio:</b><Avatar alt={dataLabel.imagen} src={dataLabel.imagen} /> {dataLabel.ad_id}<br /><b style={{ marginLeft: 25 }}>2° Anuncio:</b><Avatar alt={dataLabel2.imagen} src={dataLabel2.imagen} /> {dataLabel2.ad_id}<br /><b style={{ marginLeft: 25 }}>3° Anuncio:</b><Avatar alt={dataLabel3.imagen} src={dataLabel3.imagen} /> {dataLabel3.ad_id}</Typography>
              </div>
              <div style={{ marginTop: 20 }}></div>
            </Paper>
          </Grid>
          <div style={{ marginTop: 30 }} />
        </Dashboard>
      </>}</>
  );
}

export default StaticPage;