//Importacion de complementos
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dashboard from '../components/Dashboard';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import API, { AXIOS } from '../environment/api';
import Avatar from '@mui/material/Avatar';
import LoadingScreen from './LoadingScreen';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import PreviewIcon from '@mui/icons-material/Preview';

//Titulo de columnas en tabla
const columns = [
  { id: 'portada', label: 'Portada', minWidth: 170 },
  { id: 'titulo', label: 'Título', minWidth: 100 },
  {
    id: 'descripcion',
    label: 'Descripción',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'precio',
    label: 'Precio',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'estado',
    label: 'Estado',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Cambiar estado',
    label: 'Cambiar estado',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Ver interesados',
    label: 'Ver interesados',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

//Inicializando datos que trae la api de get
function createData(portada, titulo, descripcion, precio, estado, acciones) {
  return { portada, titulo, descripcion, precio, estado, acciones };
}

//Seleccion de datos que se mostraran en la tabla
const rows = [
  createData('Por definir', 'Curso de crecimiento personal', 'Prueba', '$100.000', 'Inactivo',),
];

const BenefitsPage = () => {
  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [estado, setEstado] = React.useState(3);
  const [numFilter, setNumFilter] = React.useState('');
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [filter, setFilter] = React.useState('');
  const [query, setQuery] = React.useState([]);
  const closeAlert = () => setAlert(false);

  //Funcion para abrir & cerrar modal
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Funcion para guardar estado
  const handleEstado = (event) => {
    setEstado(event.target.value);
  };

  //Funcion para filtrar
  const handleNumFilter = (event) => {
    setNumFilter(event.target.value);
  };

  //Funcion para cambiar estado
  const ChangerStatus = (row) => {
    openLoad();
    AXIOS.post(API.CHANGE_STATUS + row.id, {
    }).then((response) => {
      closeLoad(false);
      refresh();
    });
  };

  //Funcion para ver conductores interesados en el beneficio
  const ViewPeople = (row) => {
    openLoad();
    AXIOS.get(API.VIEW_PEOPLE + row.id + "?user_id=21965", {
    }).then((response) => {
      closeLoad(false);
      // refresh();
      setAlert(true);
      seterrorsMessage("No hay conductores interesados en el beneficio.")
    }).catch(() => {
      closeLoad(false);
      setAlert(true);
      seterrorsMessage("No hay conductores interesados en el beneficio.")
    });
  };

  //Funcion para resfrescar la pagina
  const refresh = (event) => {
    openLoad();
    AXIOS.get(API.GET_BENEFITS, {
    }).then((response) => {
      setData(response.data);
      setQuery(response.data);
      closeLoad(false);
    });
  };

  //Pre-carga de apis
  React.useEffect(() => {
    AXIOS.get(API.GET_BENEFITS, {
    }).then((response) => {
      setData(response.data);
      setQuery(response.data);
      setLoading(false);
    }).catch((error) => {
      setData([]);
      setLoading(false);
    });
  }, []);


  //Funcion para filtrar datos en tabla
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };

  // const handleChangeSearch2 = (event) => {
  //   if(event.target.value == ""){
  //     setData(query);
  //   }else{
  //     {query.filter((row)=>
  //       row.despues.toLowerCase().includes(event.target.value)
  //       ).map((row)=>(
  //         setData([row])
  //       )
  //     )};
  //   } 
  // };

  //Inicializando datos que trae la api de get
  function createData(id, url, titulo, descripcion, despues, habilitado) {
    return { id, url, titulo, descripcion, despues, habilitado };
  };

  //Seleccion de datos que se mostraran en la tabla
  const rows = data.map(item => {
    return (
      createData(item.id, item.url, item.titulo, item.descripcion, item.despues, item.habilitado)
    )
  });

  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> :
      <Dashboard>
        {/* Modal de alerta  */}
        <Modal
          open={open_alert}
          onClose={closeAlert}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            p: 1,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
              {errorsMessage}
            </Typography>
          </Box>
        </Modal>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>
        {/* Modal de creacion de beneficios        */}
        <Button type="submit" fullWidth variant="contained" onClick={() => linkTo('/benefits/create-benefit')}
          sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}><FiberNewIcon />Publicar Beneficio
        </Button>
        {/* Modal de busqueda */}
        <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
          <div style={{ display: 'flex', }}>
            <TextField
              id="standard-search"
              label="Título"
              onChange={handleChangeSearch}
              type="text"
              variant="standard"
              style={{ marginRight: 10 }}
            />
            <Box sx={{ minWidth: 120, marginRight: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado..</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={estado}
                  label="Estado.."
                  onChange={handleEstado}
                >
                  {/* <MenuItem value={""}>Ninguno</MenuItem> */}
                  <MenuItem value={3}>Todos</MenuItem>
                  <MenuItem value={1}>Activos</MenuItem>
                  <MenuItem value={0}>Inactivos</MenuItem>
                  {/* <MenuItem value={30}>Vencidos</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            {/* <Box sx={{ minWidth: 120,marginRight:10 }}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Cantidad..</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={numFilter}
              onChange={handleNumFilter}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
          </div>
        </Paper>

        {/* Tabla de datos */}
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {query.filter((row) =>
                  row.titulo.toLowerCase().includes(filter)
                ).map((row, id) => (
                  <TableRow
                    key={row.id}
                    hover role="checkbox" tabIndex={-1}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {(row.habilitado == estado | estado == 3) ? <>
                      {/* <ShowTableColumn checked={columChecked}> */}
                      <TableCell align="center" id="ID">  <Avatar alt="Remy Sharp" src={row.url} /></TableCell>
                      <TableCell align="center" id="Fecha">{<>{(row.titulo == null | row.titulo == "") ? (<>{"-"}</>) :
                        (<>{row.titulo}</>)}</>}</TableCell>
                      <TableCell align="center" id="Empresa">{<>{(row.descripcion == null | row.descripcion == "") ? (<>{"-"}</>) :
                        (<>{row.descripcion}</>)}</>}</TableCell>
                      <TableCell align="center" id="Origen">{<>{(row.despues == null | row.despues == "") ? (<>{"-"}</>) :
                        (<>{row.despues}</>)}</>}</TableCell>
                      <TableCell align="center" id="Origen">{<>{(row.habilitado == 1) ? (<><Button style={{ background: "green", color: "white" }}>Activo</Button></>) :
                        (<><Button style={{ background: "orange", color: "white" }}>Inactivo</Button></>)}</>}</TableCell>
                      <TableCell align="center" id="Origen"><Button title="Cambiar estado" onClick={() => ChangerStatus(row)}><ChangeCircleIcon /></Button></TableCell>
                      <TableCell align="center" id="Origen"><Button title="Ver interesados" style={{ color: "gray" }} onClick={() => ViewPeople(row)}><PreviewIcon /></Button></TableCell>
                    </> : null}
                  </TableRow>
                ))}
              </TableBody>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
      </Dashboard>
    }</>);
};

export default BenefitsPage;