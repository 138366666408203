const listadoCiudades = [
    {"id":1,"nombre":"ABEJORRAL, ABEJORRAL, ANTIOQUIA"},
    {"id":2,"nombre":"EL CAIRO, ABEJORRAL, ANTIOQUIA"},
    {"id":3,"nombre":"PANTANILLO, ABEJORRAL, ANTIOQUIA"},
    {"id":4,"nombre":"PURNIA, ABEJORRAL, ANTIOQUIA"},
    {"id":5,"nombre":"ABREGO, ABREGO, NORTE DE SANTANDER"},
    {"id":6,"nombre":"CAPITAN LARGO, ABREGO, NORTE DE SANTANDER"},
    {"id":7,"nombre":"CASITAS, ABREGO, NORTE DE SANTANDER"},
    {"id":8,"nombre":"EL CAIRO, ABREGO, NORTE DE SANTANDER"},
    {"id":9,"nombre":"EL CRUCE, ABREGO, NORTE DE SANTANDER"},
    {"id":10,"nombre":"EL GUAMAL, ABREGO, NORTE DE SANTANDER"},
    {"id":11,"nombre":"EL HIGUERON, ABREGO, NORTE DE SANTANDER"},
    {"id":12,"nombre":"EL LLANON, ABREGO, NORTE DE SANTANDER"},
    {"id":13,"nombre":"EL LORO, ABREGO, NORTE DE SANTANDER"},
    {"id":14,"nombre":"EL TABACO, ABREGO, NORTE DE SANTANDER"},
    {"id":15,"nombre":"EL TARRA, ABREGO, NORTE DE SANTANDER"},
    {"id":16,"nombre":"LA ARENOSA, ABREGO, NORTE DE SANTANDER"},
    {"id":17,"nombre":"LA MARIA, ABREGO, NORTE DE SANTANDER"},
    {"id":18,"nombre":"LA SIERRA, ABREGO, NORTE DE SANTANDER"},
    {"id":19,"nombre":"MONTECRISTO, ABREGO, NORTE DE SANTANDER"},
    {"id":20,"nombre":"SAN ALBERTO, ABREGO, NORTE DE SANTANDER"},
    {"id":21,"nombre":"SAN JOSE, ABREGO, NORTE DE SANTANDER"},
    {"id":22,"nombre":"SAN PABLO, ABREGO, NORTE DE SANTANDER"},
    {"id":23,"nombre":"SAN VICENTE, ABREGO, NORTE DE SANTANDER"},
    {"id":24,"nombre":"ABRIAQUI, ABRIAQUI, ANTIOQUIA"},
    {"id":25,"nombre":"LA ANTIGUA, ABRIAQUI, ANTIOQUIA"},
    {"id":26,"nombre":"ACACIAS, ACACIAS, META"},
    {"id":27,"nombre":"CHICHIMENE, ACACIAS, META"},
    {"id":28,"nombre":"DINAMARCA, ACACIAS, META"},
    {"id":29,"nombre":"LOMA DE SAN JUAN, ACACIAS, META"},
    {"id":30,"nombre":"MANZANARES, ACACIAS, META"},
    {"id":31,"nombre":"SAN CRISTOBAL, ACACIAS, META"},
    {"id":32,"nombre":"ACANDI, ACANDI, CHOCO"},
    {"id":33,"nombre":"CAPITAN, ACANDI, CHOCO"},
    {"id":34,"nombre":"CAPURGANA, ACANDI, CHOCO"},
    {"id":35,"nombre":"GOLETA, ACANDI, CHOCO"},
    {"id":36,"nombre":"LA CALETA, ACANDI, CHOCO"},
    {"id":37,"nombre":"PENALOSA, ACANDI, CHOCO"},
    {"id":38,"nombre":"PINORROA, ACANDI, CHOCO"},
    {"id":39,"nombre":"RUFINO, ACANDI, CHOCO"},
    {"id":40,"nombre":"SAN FRANCISCO, ACANDI, CHOCO"},
    {"id":41,"nombre":"SAN MIGUEL, ACANDI, CHOCO"},
    {"id":42,"nombre":"SANTA CRUZ DE CHUGANDI, ACANDI, CHOCO"},
    {"id":43,"nombre":"SAPZURRO, ACANDI, CHOCO"},
    {"id":44,"nombre":"VILLA CLARET, ACANDI, CHOCO"},
    {"id":45,"nombre":"ACEVEDO, ACEVEDO, HUILA"},
    {"id":46,"nombre":"BATEAS, ACEVEDO, HUILA"},
    {"id":47,"nombre":"DELICIAS, ACEVEDO, HUILA"},
    {"id":48,"nombre":"EL CARMEN, ACEVEDO, HUILA"},
    {"id":49,"nombre":"EL ROSARIO, ACEVEDO, HUILA"},
    {"id":50,"nombre":"LA TIJINA, ACEVEDO, HUILA"},
    {"id":51,"nombre":"LA VICTORIA, ACEVEDO, HUILA"},
    {"id":52,"nombre":"LAS MERCEDES, ACEVEDO, HUILA"},
    {"id":53,"nombre":"LLANITOS, ACEVEDO, HUILA"},
    {"id":54,"nombre":"MARTICAS, ACEVEDO, HUILA"},
    {"id":55,"nombre":"PUEBLO VIEJO, ACEVEDO, HUILA"},
    {"id":56,"nombre":"SAN ADOLFO, ACEVEDO, HUILA"},
    {"id":57,"nombre":"SAN ISIDRO, ACEVEDO, HUILA"},
    {"id":58,"nombre":"SAN JOSE DE RIECITO, ACEVEDO, HUILA"},
    {"id":59,"nombre":"SAN MARCOS, ACEVEDO, HUILA"},
    {"id":60,"nombre":"SANTO DOMINGO, ACEVEDO, HUILA"},
    {"id":61,"nombre":"ACHI, ACHI, BOLIVAR"},
    {"id":62,"nombre":"ALGARROBO, ACHI, BOLIVAR"},
    {"id":63,"nombre":"BOYACA, ACHI, BOLIVAR"},
    {"id":64,"nombre":"BUENAVISTA, ACHI, BOLIVAR"},
    {"id":65,"nombre":"BUENOS AIRES, ACHI, BOLIVAR"},
    {"id":66,"nombre":"CENTRO ALEGRE, ACHI, BOLIVAR"},
    {"id":67,"nombre":"GALLEGO ARRIBA, ACHI, BOLIVAR"},
    {"id":68,"nombre":"GUACAMAYO, ACHI, BOLIVAR"},
    {"id":69,"nombre":"GUAMO, ACHI, BOLIVAR"},
    {"id":70,"nombre":"LOS NISPEROS, ACHI, BOLIVAR"},
    {"id":71,"nombre":"MONTECRISTO, ACHI, BOLIVAR"},
    {"id":72,"nombre":"NUEVA ESPERANZA, ACHI, BOLIVAR"},
    {"id":73,"nombre":"PALENQUILLO, ACHI, BOLIVAR"},
    {"id":74,"nombre":"PARAISO SINCERIN, ACHI, BOLIVAR"},
    {"id":75,"nombre":"PAYANDE, ACHI, BOLIVAR"},
    {"id":76,"nombre":"PLAYALTA, ACHI, BOLIVAR"},
    {"id":77,"nombre":"PROVIDENCIA, ACHI, BOLIVAR"},
    {"id":78,"nombre":"PUERTO ISABEL, ACHI, BOLIVAR"},
    {"id":79,"nombre":"PUERTO VENECIA, ACHI, BOLIVAR"},
    {"id":80,"nombre":"RIO NUEVO, ACHI, BOLIVAR"},
    {"id":81,"nombre":"RIONEGRO, ACHI, BOLIVAR"},
    {"id":82,"nombre":"SANTA LUCIA, ACHI, BOLIVAR"},
    {"id":83,"nombre":"TACUYALTA, ACHI, BOLIVAR"},
    {"id":84,"nombre":"TRES CRUCES, ACHI, BOLIVAR"},
    {"id":85,"nombre":"VIDA TRANQUILA, ACHI, BOLIVAR"},
    {"id":86,"nombre":"AGRADO, AGRADO, HUILA"},
    {"id":87,"nombre":"ESCALERETA, AGRADO, HUILA"},
    {"id":88,"nombre":"LA CANADA, AGRADO, HUILA"},
    {"id":89,"nombre":"AGUA DE DIOS, AGUA DE DIOS, CUNDINAMARCA"},
    {"id":90,"nombre":"LA PUNA, AGUA DE DIOS, CUNDINAMARCA"},
    {"id":91,"nombre":"LETICIA, AGUA DE DIOS, CUNDINAMARCA"},
    {"id":92,"nombre":"MANUEL SUR, AGUA DE DIOS, CUNDINAMARCA"},
    {"id":93,"nombre":"ADJUNTAS, AGUACHICA, CESAR"},
    {"id":94,"nombre":"AGUACHICA, AGUACHICA, CESAR"},
    {"id":95,"nombre":"BARRANCA DE LEBRIJA, AGUACHICA, CESAR"},
    {"id":96,"nombre":"BOQUERON, AGUACHICA, CESAR"},
    {"id":97,"nombre":"BUTURAMA, AGUACHICA, CESAR"},
    {"id":98,"nombre":"CAMPO AMALIA, AGUACHICA, CESAR"},
    {"id":99,"nombre":"CERRO BRAVO, AGUACHICA, CESAR"},
    {"id":100,"nombre":"CERRO REDONDO, AGUACHICA, CESAR"},
    {"id":101,"nombre":"LA MORENA, AGUACHICA, CESAR"},
    {"id":102,"nombre":"LA YEGUERA, AGUACHICA, CESAR"},
    {"id":103,"nombre":"LOMA DE CORREDOR, AGUACHICA, CESAR"},
    {"id":104,"nombre":"LUCAICAL, AGUACHICA, CESAR"},
    {"id":105,"nombre":"MUCURAS, AGUACHICA, CESAR"},
    {"id":106,"nombre":"NOREAN, AGUACHICA, CESAR"},
    {"id":107,"nombre":"PITA LIMON, AGUACHICA, CESAR"},
    {"id":108,"nombre":"PUERTO PATINO, AGUACHICA, CESAR"},
    {"id":109,"nombre":"SANTA BARBARA, AGUACHICA, CESAR"},
    {"id":110,"nombre":"SANTA LUCIA, AGUACHICA, CESAR"},
    {"id":111,"nombre":"TORCOROMA, AGUACHICA, CESAR"},
    {"id":112,"nombre":"VILLA DE SAN ANDRES, AGUACHICA, CESAR"},
    {"id":113,"nombre":"VILLA NUEVA, AGUACHICA, CESAR"},
    {"id":114,"nombre":"AGUADA, AGUADA, SANTANDER"},
    {"id":115,"nombre":"AGUADAS, AGUADAS, CALDAS"},
    {"id":116,"nombre":"ALTO DE LA MONTANA, AGUADAS, CALDAS"},
    {"id":117,"nombre":"ALTO DE PITO, AGUADAS, CALDAS"},
    {"id":118,"nombre":"ARMA, AGUADAS, CALDAS"},
    {"id":119,"nombre":"BOCAS, AGUADAS, CALDAS"},
    {"id":120,"nombre":"EDEN, AGUADAS, CALDAS"},
    {"id":121,"nombre":"ENCIMADAS, AGUADAS, CALDAS"},
    {"id":122,"nombre":"ESTACION LA MARIA, AGUADAS, CALDAS"},
    {"id":123,"nombre":"GUACO, AGUADAS, CALDAS"},
    {"id":124,"nombre":"GUAIMARAL, AGUADAS, CALDAS"},
    {"id":125,"nombre":"LA MERMITA, AGUADAS, CALDAS"},
    {"id":126,"nombre":"LOS CHARCOS, AGUADAS, CALDAS"},
    {"id":127,"nombre":"PORE, AGUADAS, CALDAS"},
    {"id":128,"nombre":"RIO ARRIBA, AGUADAS, CALDAS"},
    {"id":129,"nombre":"RIOARRIBA, AGUADAS, CALDAS"},
    {"id":130,"nombre":"SAN NICOLAS, AGUADAS, CALDAS"},
    {"id":131,"nombre":"VIBORAL, AGUADAS, CALDAS"},
    {"id":132,"nombre":"AGUAZUL, AGUAZUL, CASANARE"},
    {"id":133,"nombre":"BELLAVISTA, AGUAZUL, CASANARE"},
    {"id":134,"nombre":"CUNAMA, AGUAZUL, CASANARE"},
    {"id":135,"nombre":"CUPIAGUA, AGUAZUL, CASANARE"},
    {"id":136,"nombre":"MONTERRALO, AGUAZUL, CASANARE"},
    {"id":137,"nombre":"PUENTE CUSIANA, AGUAZUL, CASANARE"},
    {"id":138,"nombre":"SAN BENITO, AGUAZUL, CASANARE"},
    {"id":139,"nombre":"SAN JOSE, AGUAZUL, CASANARE"},
    {"id":140,"nombre":"SAN MIGUEL DE FARALLONES, AGUAZUL, CASANARE"},
    {"id":141,"nombre":"TURUA, AGUAZUL, CASANARE"},
    {"id":142,"nombre":"UNETE, AGUAZUL, CASANARE"},
    {"id":143,"nombre":"AGUSTIN CODAZZI, AGUSTIN CODAZZI, CESAR"},
    {"id":144,"nombre":"CASACARA, AGUSTIN CODAZZI, CESAR"},
    {"id":145,"nombre":"LLERASCA, AGUSTIN CODAZZI, CESAR"},
    {"id":146,"nombre":"PUNTA ARRECHA, AGUSTIN CODAZZI, CESAR"},
    {"id":147,"nombre":"SAN JACINTO, AGUSTIN CODAZZI, CESAR"},
    {"id":148,"nombre":"SICARARE, AGUSTIN CODAZZI, CESAR"},
    {"id":149,"nombre":"AIPE, AIPE, HUILA"},
    {"id":150,"nombre":"CRUCE DE GUACIRCO, AIPE, HUILA"},
    {"id":151,"nombre":"MESITAS, AIPE, HUILA"},
    {"id":152,"nombre":"PATA, AIPE, HUILA"},
    {"id":153,"nombre":"PRAGA, AIPE, HUILA"},
    {"id":154,"nombre":"SAN MIGUEL, AIPE, HUILA"},
    {"id":155,"nombre":"SANTA RITA, AIPE, HUILA"},
    {"id":156,"nombre":"VENTANAS, AIPE, HUILA"},
    {"id":157,"nombre":"ALCALA, ALCALA, VALLE DEL CAUCA"},
    {"id":158,"nombre":"EL CONGAL, ALCALA, VALLE DEL CAUCA"},
    {"id":159,"nombre":"LA CANA, ALCALA, VALLE DEL CAUCA"},
    {"id":160,"nombre":"LA CUCHILLA, ALCALA, VALLE DEL CAUCA"},
    {"id":161,"nombre":"LA ESTRELLA, ALCALA, VALLE DEL CAUCA"},
    {"id":162,"nombre":"MARAVELEZ, ALCALA, VALLE DEL CAUCA"},
    {"id":163,"nombre":"ALDANA, ALDANA, NARIÑO"},
    {"id":164,"nombre":"ALTO MUESAS, ALDANA, NARIÑO"},
    {"id":165,"nombre":"CAUPUERAN, ALDANA, NARIÑO"},
    {"id":166,"nombre":"CHAPUESMAL, ALDANA, NARIÑO"},
    {"id":167,"nombre":"CHAQUILULO, ALDANA, NARIÑO"},
    {"id":168,"nombre":"CHITAIRA, ALDANA, NARIÑO"},
    {"id":169,"nombre":"CHORRILLO, ALDANA, NARIÑO"},
    {"id":170,"nombre":"EL ROSA, ALDANA, NARIÑO"},
    {"id":171,"nombre":"GUESPUD, ALDANA, NARIÑO"},
    {"id":172,"nombre":"LA LAGUNA, ALDANA, NARIÑO"},
    {"id":173,"nombre":"MUESAS, ALDANA, NARIÑO"},
    {"id":174,"nombre":"PAMBA ROSA, ALDANA, NARIÑO"},
    {"id":175,"nombre":"PLAZUELAS, ALDANA, NARIÑO"},
    {"id":176,"nombre":"SAN LUIS, ALDANA, NARIÑO"},
    {"id":177,"nombre":"SANTA BARBARA, ALDANA, NARIÑO"},
    {"id":178,"nombre":"ALEJANDRIA, ALEJANDRIA, ANTIOQUIA"},
    {"id":179,"nombre":"ALGARROBO, ALGARROBO, MAGDALENA"},
    {"id":180,"nombre":"BELLA VISTA, ALGARROBO, MAGDALENA"},
    {"id":181,"nombre":"ESTACION LLERAS, ALGARROBO, MAGDALENA"},
    {"id":182,"nombre":"LOMA DEL BALSAMO, ALGARROBO, MAGDALENA"},
    {"id":183,"nombre":"ALGECIRAS, ALGECIRAS, HUILA"},
    {"id":184,"nombre":"EL PARAISO VIEJO, ALGECIRAS, HUILA"},
    {"id":185,"nombre":"EL PUENTE, ALGECIRAS, HUILA"},
    {"id":186,"nombre":"EL TORO, ALGECIRAS, HUILA"},
    {"id":187,"nombre":"LA ARCADIA, ALGECIRAS, HUILA"},
    {"id":188,"nombre":"PARAISO NUEVO, ALGECIRAS, HUILA"},
    {"id":189,"nombre":"ALMAGUER, ALMAGUER, CAUCA"},
    {"id":190,"nombre":"CAQUIONA, ALMAGUER, CAUCA"},
    {"id":191,"nombre":"EL TABLON, ALMAGUER, CAUCA"},
    {"id":192,"nombre":"HATOS HUMOS, ALMAGUER, CAUCA"},
    {"id":193,"nombre":"LA HERRADURA, ALMAGUER, CAUCA"},
    {"id":194,"nombre":"LA HONDA, ALMAGUER, CAUCA"},
    {"id":195,"nombre":"LA PILA, ALMAGUER, CAUCA"},
    {"id":196,"nombre":"LLACUANAS, ALMAGUER, CAUCA"},
    {"id":197,"nombre":"MARMATO TARABITA, ALMAGUER, CAUCA"},
    {"id":198,"nombre":"SAN JORGE, ALMAGUER, CAUCA"},
    {"id":199,"nombre":"SAUJI, ALMAGUER, CAUCA"},
    {"id":200,"nombre":"ALMEIDA, ALMEIDA, BOYACA"},
    {"id":201,"nombre":"ACHIRAL, ALPUJARRA, TOLIMA"},
    {"id":202,"nombre":"ALPUJARRA, ALPUJARRA, TOLIMA"},
    {"id":203,"nombre":"AMESES, ALPUJARRA, TOLIMA"},
    {"id":204,"nombre":"EL CARMEN, ALPUJARRA, TOLIMA"},
    {"id":205,"nombre":"LA ARADA, ALPUJARRA, TOLIMA"},
    {"id":206,"nombre":"LA LINDOSA, ALPUJARRA, TOLIMA"},
    {"id":207,"nombre":"SAN LUIS, ALPUJARRA, TOLIMA"},
    {"id":208,"nombre":"VEGA GRANDE, ALPUJARRA, TOLIMA"},
    {"id":209,"nombre":"ALTAMIRA, ALTAMIRA, HUILA"},
    {"id":210,"nombre":"PAJIJI, ALTAMIRA, HUILA"},
    {"id":211,"nombre":"ALMENDRO, ALTO BAUDO, CHOCO"},
    {"id":212,"nombre":"PIE DE PATO, ALTO BAUDO, CHOCO"},
    {"id":213,"nombre":"AMPARRAIDA, ALTO BAUDO, CHOCO"},
    {"id":214,"nombre":"APARTADO, ALTO BAUDO, CHOCO"},
    {"id":215,"nombre":"BATATAL, ALTO BAUDO, CHOCO"},
    {"id":216,"nombre":"BELLAVISTA, ALTO BAUDO, CHOCO"},
    {"id":217,"nombre":"CHACHAJO, ALTO BAUDO, CHOCO"},
    {"id":218,"nombre":"CHIGORODO, ALTO BAUDO, CHOCO"},
    {"id":219,"nombre":"DUBAZA, ALTO BAUDO, CHOCO"},
    {"id":220,"nombre":"NAUCA, ALTO BAUDO, CHOCO"},
    {"id":221,"nombre":"SAN FRANCISCO DE CUGUCHO, ALTO BAUDO, CHOCO"},
    {"id":222,"nombre":"SANTA CATALINA, ALTO BAUDO, CHOCO"},
    {"id":223,"nombre":"URUDO, ALTO BAUDO, CHOCO"},
    {"id":224,"nombre":"YUCAL, ALTO BAUDO, CHOCO"},
    {"id":225,"nombre":"ALTOS DEL ROSARIO, ALTOS DEL ROSARIO, BOLIVAR"},
    {"id":226,"nombre":"EL RUBIO, ALTOS DEL ROSARIO, BOLIVAR"},
    {"id":227,"nombre":"LA PACHA, ALTOS DEL ROSARIO, BOLIVAR"},
    {"id":228,"nombre":"SAN ISIDRO, ALTOS DEL ROSARIO, BOLIVAR"},
    {"id":229,"nombre":"SANTA LUCIA, ALTOS DEL ROSARIO, BOLIVAR"},
    {"id":230,"nombre":"ALVARADO, ALVARADO, TOLIMA"},
    {"id":231,"nombre":"CALDAS VIEJO, ALVARADO, TOLIMA"},
    {"id":232,"nombre":"ESTACION CALDAS, ALVARADO, TOLIMA"},
    {"id":233,"nombre":"LA TEBAIDA, ALVARADO, TOLIMA"},
    {"id":234,"nombre":"RINCON CHIPALO, ALVARADO, TOLIMA"},
    {"id":235,"nombre":"TOTARITO, ALVARADO, TOLIMA"},
    {"id":236,"nombre":"VERACRUZ, ALVARADO, TOLIMA"},
    {"id":237,"nombre":"AMAGA, AMAGA, ANTIOQUIA"},
    {"id":238,"nombre":"CAMILOCE, AMAGA, ANTIOQUIA"},
    {"id":239,"nombre":"DOS PENAS, AMAGA, ANTIOQUIA"},
    {"id":240,"nombre":"LA CLARITA, AMAGA, ANTIOQUIA"},
    {"id":241,"nombre":"LOS ALVAREZ, AMAGA, ANTIOQUIA"},
    {"id":242,"nombre":"MINAS, AMAGA, ANTIOQUIA"},
    {"id":243,"nombre":"AMALFI, AMALFI, ANTIOQUIA"},
    {"id":244,"nombre":"ARENAS BLANCAS, AMALFI, ANTIOQUIA"},
    {"id":245,"nombre":"BARRIO OBRERO, AMALFI, ANTIOQUIA"},
    {"id":246,"nombre":"EL TIGRE, AMALFI, ANTIOQUIA"},
    {"id":247,"nombre":"EL ZACATIN, AMALFI, ANTIOQUIA"},
    {"id":248,"nombre":"LA CANCANA, AMALFI, ANTIOQUIA"},
    {"id":249,"nombre":"LA GUAYANA, AMALFI, ANTIOQUIA"},
    {"id":250,"nombre":"PORTACHUELO, AMALFI, ANTIOQUIA"},
    {"id":251,"nombre":"AMBALEMA, AMBALEMA, TOLIMA"},
    {"id":252,"nombre":"BOQUERON, AMBALEMA, TOLIMA"},
    {"id":253,"nombre":"CUATRO ESQUINAS, AMBALEMA, TOLIMA"},
    {"id":254,"nombre":"DANUBIO, AMBALEMA, TOLIMA"},
    {"id":255,"nombre":"EL CHORRILLO, AMBALEMA, TOLIMA"},
    {"id":256,"nombre":"EL SANTUARIO, AMBALEMA, TOLIMA"},
    {"id":257,"nombre":"PAJONALES, AMBALEMA, TOLIMA"},
    {"id":258,"nombre":"RASTROJO, AMBALEMA, TOLIMA"},
    {"id":259,"nombre":"TAJO MEDIO, AMBALEMA, TOLIMA"},
    {"id":260,"nombre":"ANAPOIMA, ANAPOIMA, CUNDINAMARCA"},
    {"id":261,"nombre":"LA PAZ, ANAPOIMA, CUNDINAMARCA"},
    {"id":262,"nombre":"PATIO BONITO, ANAPOIMA, CUNDINAMARCA"},
    {"id":263,"nombre":"SAN ANTONIO, ANAPOIMA, CUNDINAMARCA"},
    {"id":264,"nombre":"ANCUYA, ANCUYA, NARIÑO"},
    {"id":265,"nombre":"COCHA BLANCA, ANCUYA, NARIÑO"},
    {"id":266,"nombre":"CRUZ DE MAYO, ANCUYA, NARIÑO"},
    {"id":267,"nombre":"EL INGENIO, ANCUYA, NARIÑO"},
    {"id":268,"nombre":"EL LLANO, ANCUYA, NARIÑO"},
    {"id":269,"nombre":"INDO, ANCUYA, NARIÑO"},
    {"id":270,"nombre":"LA LOMA, ANCUYA, NARIÑO"},
    {"id":271,"nombre":"LA QUINUA, ANCUYA, NARIÑO"},
    {"id":272,"nombre":"LIMONAL, ANCUYA, NARIÑO"},
    {"id":273,"nombre":"MACASCRUZ, ANCUYA, NARIÑO"},
    {"id":274,"nombre":"PEDREGAL, ANCUYA, NARIÑO"},
    {"id":275,"nombre":"SAN LUIS, ANCUYA, NARIÑO"},
    {"id":276,"nombre":"YANANCHA, ANCUYA, NARIÑO"},
    {"id":277,"nombre":"ALTAFLOR, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":278,"nombre":"ANDALUCIA, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":279,"nombre":"CAMPOALEGRE, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":280,"nombre":"EL SALTO, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":281,"nombre":"LA PAZ, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":282,"nombre":"MADRE VIEJA, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":283,"nombre":"MONTE HERMOSO, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":284,"nombre":"PARDO, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":285,"nombre":"POTRERILLO, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":286,"nombre":"TAMBORAL, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":287,"nombre":"ZABALETAS, ANDALUCIA, VALLE DEL CAUCA"},
    {"id":288,"nombre":"ANDES, ANDES, ANTIOQUIA"},
    {"id":289,"nombre":"BUENOS AIRES, ANDES, ANTIOQUIA"},
    {"id":290,"nombre":"QUEBRADA ARRIBA, ANDES, ANTIOQUIA"},
    {"id":291,"nombre":"SAN JOSE, ANDES, ANTIOQUIA"},
    {"id":292,"nombre":"SANTA BARBARA, ANDES, ANTIOQUIA"},
    {"id":293,"nombre":"SANTA INES, ANDES, ANTIOQUIA"},
    {"id":294,"nombre":"SANTA RITA, ANDES, ANTIOQUIA"},
    {"id":295,"nombre":"TAPARTO, ANDES, ANTIOQUIA"},
    {"id":296,"nombre":"ANGELOPOLIS, ANGELOPOLIS, ANTIOQUIA"},
    {"id":297,"nombre":"LA ESTACION, ANGELOPOLIS, ANTIOQUIA"},
    {"id":298,"nombre":"ANGOSTURA, ANGOSTURA, ANTIOQUIA"},
    {"id":299,"nombre":"CANOAS, ANGOSTURA, ANTIOQUIA"},
    {"id":300,"nombre":"EL CARMELO, ANGOSTURA, ANTIOQUIA"},
    {"id":301,"nombre":"LLANOS DE CUIBA, ANGOSTURA, ANTIOQUIA"},
    {"id":302,"nombre":"ANOLAIMA, ANOLAIMA, CUNDINAMARCA"},
    {"id":303,"nombre":"BOQUERON DE ILO, ANOLAIMA, CUNDINAMARCA"},
    {"id":304,"nombre":"CANAZEJAS, ANOLAIMA, CUNDINAMARCA"},
    {"id":305,"nombre":"CORRALEJAS, ANOLAIMA, CUNDINAMARCA"},
    {"id":306,"nombre":"LA FLORIDA, ANOLAIMA, CUNDINAMARCA"},
    {"id":307,"nombre":"REVENTONES, ANOLAIMA, CUNDINAMARCA"},
    {"id":308,"nombre":"ANORI, ANORI, ANTIOQUIA"},
    {"id":309,"nombre":"CRISTALINAS, ANORI, ANTIOQUIA"},
    {"id":310,"nombre":"LAS NIEVES, ANORI, ANTIOQUIA"},
    {"id":311,"nombre":"LIBERIA (CHARCON), ANORI, ANTIOQUIA"},
    {"id":312,"nombre":"MADRE SECA, ANORI, ANTIOQUIA"},
    {"id":313,"nombre":"MONTEFRIO, ANORI, ANTIOQUIA"},
    {"id":314,"nombre":"SANTO DOMINGO, ANORI, ANTIOQUIA"},
    {"id":315,"nombre":"ALSACIA, ANSERMA, CALDAS"},
    {"id":316,"nombre":"ANSERMA, ANSERMA, CALDAS"},
    {"id":317,"nombre":"BELLAVISTA, ANSERMA, CALDAS"},
    {"id":318,"nombre":"CHAPATA, ANSERMA, CALDAS"},
    {"id":319,"nombre":"CONCHARI, ANSERMA, CALDAS"},
    {"id":320,"nombre":"EL HORRO, ANSERMA, CALDAS"},
    {"id":321,"nombre":"MARAPRA, ANSERMA, CALDAS"},
    {"id":322,"nombre":"MIRAVALLE, ANSERMA, CALDAS"},
    {"id":323,"nombre":"SAN PEDRO, ANSERMA, CALDAS"},
    {"id":324,"nombre":"ALTO TIGRE, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":325,"nombre":"ANACARO, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":326,"nombre":"ANSERMANUEVO, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":327,"nombre":"CALABAZAS, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":328,"nombre":"EL BILLAR, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":329,"nombre":"EL BOSQUE, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":330,"nombre":"EL CAFE, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":331,"nombre":"EL ROBLE, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":332,"nombre":"EL VERGEL, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":333,"nombre":"GRAMALOTE, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":334,"nombre":"LA CABANA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":335,"nombre":"LA HONDURA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":336,"nombre":"LA PEDRERA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":337,"nombre":"LA POPALITA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":338,"nombre":"LA PRIMAVERA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":339,"nombre":"LA PUERTA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":340,"nombre":"LA RAIZ, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":341,"nombre":"LUSITANIA, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":342,"nombre":"SAN AGUSTIN, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":343,"nombre":"TRES ESQUINAS, ANSERMANUEVO, VALLE DEL CAUCA"},
    {"id":344,"nombre":"ANZA, ANZA, ANTIOQUIA"},
    {"id":345,"nombre":"GUINTAR, ANZA, ANTIOQUIA"},
    {"id":346,"nombre":"LA CEJITA, ANZA, ANTIOQUIA"},
    {"id":347,"nombre":"LA CHOLINA, ANZA, ANTIOQUIA"},
    {"id":348,"nombre":"LA CUEVA, ANZA, ANTIOQUIA"},
    {"id":349,"nombre":"LA HIGUINA, ANZA, ANTIOQUIA"},
    {"id":350,"nombre":"LOS LLANOS, ANZA, ANTIOQUIA"},
    {"id":351,"nombre":"ANZOATEGUI, ANZOATEGUI, TOLIMA"},
    {"id":352,"nombre":"LISBOA, ANZOATEGUI, TOLIMA"},
    {"id":353,"nombre":"PALOMAR, ANZOATEGUI, TOLIMA"},
    {"id":354,"nombre":"SANTA BARBARA, ANZOATEGUI, TOLIMA"},
    {"id":355,"nombre":"SANTA RITA, ANZOATEGUI, TOLIMA"},
    {"id":356,"nombre":"APARTADO, APARTADO, ANTIOQUIA"},
    {"id":357,"nombre":"BIJAGUAL, APARTADO, ANTIOQUIA"},
    {"id":358,"nombre":"CHURIDO, APARTADO, ANTIOQUIA"},
    {"id":359,"nombre":"EL REPOSO, APARTADO, ANTIOQUIA"},
    {"id":360,"nombre":"LOS NARANJALES, APARTADO, ANTIOQUIA"},
    {"id":361,"nombre":"PUEBLO QUEMADO, APARTADO, ANTIOQUIA"},
    {"id":362,"nombre":"PUERTO CARIBE, APARTADO, ANTIOQUIA"},
    {"id":363,"nombre":"SAN JOSE DE APARTADO, APARTADO, ANTIOQUIA"},
    {"id":364,"nombre":"ZUNGO CARRETERA, APARTADO, ANTIOQUIA"},
    {"id":365,"nombre":"ALTA CAMPANA, APIA, RISARALDA"},
    {"id":366,"nombre":"APIA, APIA, RISARALDA"},
    {"id":367,"nombre":"GUARNE, APIA, RISARALDA"},
    {"id":368,"nombre":"JORDANIA, APIA, RISARALDA"},
    {"id":369,"nombre":"LA CANDELARIA, APIA, RISARALDA"},
    {"id":370,"nombre":"LA MARIA, APIA, RISARALDA"},
    {"id":371,"nombre":"MATA DE CANA, APIA, RISARALDA"},
    {"id":372,"nombre":"SAN AGUSTIN, APIA, RISARALDA"},
    {"id":373,"nombre":"SAN CARLOS, APIA, RISARALDA"},
    {"id":374,"nombre":"VALLADOLID, APIA, RISARALDA"},
    {"id":375,"nombre":"APULO, APULO, CUNDINAMARCA"},
    {"id":376,"nombre":"LA MESETA, APULO, CUNDINAMARCA"},
    {"id":377,"nombre":"AQUITANIA, AQUITANIA, BOYACA"},
    {"id":378,"nombre":"DAITO, AQUITANIA, BOYACA"},
    {"id":379,"nombre":"DIGANOME, AQUITANIA, BOYACA"},
    {"id":380,"nombre":"HATO LAGUNA, AQUITANIA, BOYACA"},
    {"id":381,"nombre":"HIRVA, AQUITANIA, BOYACA"},
    {"id":382,"nombre":"LA ESPERANZA, AQUITANIA, BOYACA"},
    {"id":383,"nombre":"MARAVILLA, AQUITANIA, BOYACA"},
    {"id":384,"nombre":"MOMBITA, AQUITANIA, BOYACA"},
    {"id":385,"nombre":"PRIMAVERA, AQUITANIA, BOYACA"},
    {"id":386,"nombre":"SISVACA, AQUITANIA, BOYACA"},
    {"id":387,"nombre":"SORIANO, AQUITANIA, BOYACA"},
    {"id":388,"nombre":"SUSACA, AQUITANIA, BOYACA"},
    {"id":389,"nombre":"SUSE, AQUITANIA, BOYACA"},
    {"id":390,"nombre":"TOQUILLA, AQUITANIA, BOYACA"},
    {"id":391,"nombre":"ARACATACA, ARACATACA, MAGDALENA"},
    {"id":392,"nombre":"BUENOS AIRES, ARACATACA, MAGDALENA"},
    {"id":393,"nombre":"CAUCA, ARACATACA, MAGDALENA"},
    {"id":394,"nombre":"SAMPUES, ARACATACA, MAGDALENA"},
    {"id":395,"nombre":"SANTA ANA, ARACATACA, MAGDALENA"},
    {"id":396,"nombre":"ARANZAZU, ARANZAZU, CALDAS"},
    {"id":397,"nombre":"EL ROBLAL, ARANZAZU, CALDAS"},
    {"id":398,"nombre":"SAN RAFAEL, ARANZAZU, CALDAS"},
    {"id":399,"nombre":"ARATOCA, ARATOCA, SANTANDER"},
    {"id":400,"nombre":"ARAUCA, ARAUCA, ARAUCA"},
    {"id":401,"nombre":"CABUYARE, ARAUCA, ARAUCA"},
    {"id":402,"nombre":"CANAS BRAVAS, ARAUCA, ARAUCA"},
    {"id":403,"nombre":"CLARINETERO, ARAUCA, ARAUCA"},
    {"id":404,"nombre":"EL CARACOL, ARAUCA, ARAUCA"},
    {"id":405,"nombre":"EL ELE, ARAUCA, ARAUCA"},
    {"id":406,"nombre":"FELICIANO, ARAUCA, ARAUCA"},
    {"id":407,"nombre":"FLOR AMARILLA, ARAUCA, ARAUCA"},
    {"id":408,"nombre":"LA BECERRA, ARAUCA, ARAUCA"},
    {"id":409,"nombre":"LA SAYA, ARAUCA, ARAUCA"},
    {"id":410,"nombre":"LIPA, ARAUCA, ARAUCA"},
    {"id":411,"nombre":"MAPORILLAL, ARAUCA, ARAUCA"},
    {"id":412,"nombre":"MATA DE PINA, ARAUCA, ARAUCA"},
    {"id":413,"nombre":"PUERTO COLOMBIA, ARAUCA, ARAUCA"},
    {"id":414,"nombre":"ROSARIO, ARAUCA, ARAUCA"},
    {"id":415,"nombre":"SAN JOSE DE LIPA, ARAUCA, ARAUCA"},
    {"id":416,"nombre":"SAN PABLO, ARAUCA, ARAUCA"},
    {"id":417,"nombre":"SANTA BARBARA, ARAUCA, ARAUCA"},
    {"id":418,"nombre":"TODOS LOS SANTOS, ARAUCA, ARAUCA"},
    {"id":419,"nombre":"VILLANUEVA - CARA, ARAUCA, ARAUCA"},
    {"id":420,"nombre":"AGUACHICA, ARAUQUITA, ARAUCA"},
    {"id":421,"nombre":"ARAUQUITA, ARAUQUITA, ARAUCA"},
    {"id":422,"nombre":"BOCAS DE GAVIOTA, ARAUQUITA, ARAUCA"},
    {"id":423,"nombre":"BOCAS DEL BAYON, ARAUQUITA, ARAUCA"},
    {"id":424,"nombre":"BOCAS DEL CALAMAR, ARAUQUITA, ARAUCA"},
    {"id":425,"nombre":"BRISAS DE CARANAL, ARAUQUITA, ARAUCA"},
    {"id":426,"nombre":"CARRETERO, ARAUQUITA, ARAUCA"},
    {"id":427,"nombre":"EL CAMPAMENTO, ARAUQUITA, ARAUCA"},
    {"id":428,"nombre":"EL CAUCHO, ARAUQUITA, ARAUCA"},
    {"id":429,"nombre":"EL OASIS, ARAUQUITA, ARAUCA"},
    {"id":430,"nombre":"EL TRIUNFO, ARAUQUITA, ARAUCA"},
    {"id":431,"nombre":"EL TRONCAL, ARAUQUITA, ARAUCA"},
    {"id":432,"nombre":"GUAMALITO, ARAUQUITA, ARAUCA"},
    {"id":433,"nombre":"LA ESMERALDA (JUJUA), ARAUQUITA, ARAUCA"},
    {"id":434,"nombre":"LA ESPERANZA, ARAUQUITA, ARAUCA"},
    {"id":435,"nombre":"LA PAZ, ARAUQUITA, ARAUCA"},
    {"id":436,"nombre":"LA PESQUERA, ARAUQUITA, ARAUCA"},
    {"id":437,"nombre":"LOS ANGELITOS, ARAUQUITA, ARAUCA"},
    {"id":438,"nombre":"LOS CHORROS, ARAUQUITA, ARAUCA"},
    {"id":439,"nombre":"LOS PAJAROS, ARAUQUITA, ARAUCA"},
    {"id":440,"nombre":"PANAMA DE ARAUCA, ARAUQUITA, ARAUCA"},
    {"id":441,"nombre":"POTOSI (PRIMAVERA), ARAUQUITA, ARAUCA"},
    {"id":442,"nombre":"RAUDALES DE LIPA, ARAUQUITA, ARAUCA"},
    {"id":443,"nombre":"REINERA (GAVIOTA), ARAUQUITA, ARAUCA"},
    {"id":444,"nombre":"SAN LORENZO, ARAUQUITA, ARAUCA"},
    {"id":445,"nombre":"ARBELAEZ, ARBELAEZ, CUNDINAMARCA"},
    {"id":446,"nombre":"LA HONDA, ARBELAEZ, CUNDINAMARCA"},
    {"id":447,"nombre":"TISINCE, ARBELAEZ, CUNDINAMARCA"},
    {"id":448,"nombre":"BERRUECOS, ARBOLEDA, NARIÑO"},
    {"id":449,"nombre":"CARDENAS ROSAFLORIDA, ARBOLEDA, NARIÑO"},
    {"id":450,"nombre":"GOMEZ, ARBOLEDA, NARIÑO"},
    {"id":451,"nombre":"LA CANADA, ARBOLEDA, NARIÑO"},
    {"id":452,"nombre":"LA COCHA, ARBOLEDA, NARIÑO"},
    {"id":453,"nombre":"LA COMUNIDAD, ARBOLEDA, NARIÑO"},
    {"id":454,"nombre":"LA ESTANCIA, ARBOLEDA, NARIÑO"},
    {"id":455,"nombre":"MARTIN, ARBOLEDA, NARIÑO"},
    {"id":456,"nombre":"ROSA FLORIDA SUR - SECTOR LA CAPILLA, ARBOLEDA, NARIÑO"},
    {"id":457,"nombre":"ROSAFLORIDA NORTE, ARBOLEDA, NARIÑO"},
    {"id":458,"nombre":"SANTA TERESA, ARBOLEDA, NARIÑO"},
    {"id":459,"nombre":"ARBOLEDAS, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":460,"nombre":"BARRIENTOS, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":461,"nombre":"CASTRO, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":462,"nombre":"CINERA, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":463,"nombre":"GUZAMAN, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":464,"nombre":"LA UVITA, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":465,"nombre":"LOS MOLINOS, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":466,"nombre":"UVITO, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":467,"nombre":"VILLA SUCRE, ARBOLEDAS, NORTE DE SANTANDER"},
    {"id":468,"nombre":"ARBOLETES, ARBOLETES, ANTIOQUIA"},
    {"id":469,"nombre":"BUENOS AIRES, ARBOLETES, ANTIOQUIA"},
    {"id":470,"nombre":"EL CARMELO, ARBOLETES, ANTIOQUIA"},
    {"id":471,"nombre":"EL GUADUAL, ARBOLETES, ANTIOQUIA"},
    {"id":472,"nombre":"EL YESO, ARBOLETES, ANTIOQUIA"},
    {"id":473,"nombre":"LA CANDELARIA, ARBOLETES, ANTIOQUIA"},
    {"id":474,"nombre":"LA TRINIDAD, ARBOLETES, ANTIOQUIA"},
    {"id":475,"nombre":"LAS NARANJITAS, ARBOLETES, ANTIOQUIA"},
    {"id":476,"nombre":"LAS PLATAS (SANTAFE), ARBOLETES, ANTIOQUIA"},
    {"id":477,"nombre":"SIETE VUELTAS, ARBOLETES, ANTIOQUIA"},
    {"id":478,"nombre":"ARCABUCO, ARCABUCO, BOYACA"},
    {"id":479,"nombre":"ARENAL, ARENAL, BOLIVAR"},
    {"id":480,"nombre":"BUENAVISTA, ARENAL, BOLIVAR"},
    {"id":481,"nombre":"CARNIZALA, ARENAL, BOLIVAR"},
    {"id":482,"nombre":"LA BONITA, ARENAL, BOLIVAR"},
    {"id":483,"nombre":"LAS SABANAS, ARENAL, BOLIVAR"},
    {"id":484,"nombre":"LOS PENONES, ARENAL, BOLIVAR"},
    {"id":485,"nombre":"SAN RAFAEL, ARENAL, BOLIVAR"},
    {"id":486,"nombre":"SANTO DOMINGO, ARENAL, BOLIVAR"},
    {"id":487,"nombre":"SOYA, ARENAL, BOLIVAR"},
    {"id":488,"nombre":"ALEJANDRIA, ARIGUANI, MAGDALENA"},
    {"id":489,"nombre":"EL DIFICIL, ARIGUANI, MAGDALENA"},
    {"id":490,"nombre":"BADELCO, ARIGUANI, MAGDALENA"},
    {"id":491,"nombre":"PUEBLO NUEVO, ARIGUANI, MAGDALENA"},
    {"id":492,"nombre":"PUENTE ARIGUANI, ARIGUANI, MAGDALENA"},
    {"id":493,"nombre":"SAN JOSE DE ARIGUANI, ARIGUANI, MAGDALENA"},
    {"id":494,"nombre":"TRES ESQUINAS, ARIGUANI, MAGDALENA"},
    {"id":495,"nombre":"ARJONA, ARJONA, BOLIVAR"},
    {"id":496,"nombre":"GAMBOTE, ARJONA, BOLIVAR"},
    {"id":497,"nombre":"PUERTO BADEL (CANO SALADO), ARJONA, BOLIVAR"},
    {"id":498,"nombre":"ROCHA, ARJONA, BOLIVAR"},
    {"id":499,"nombre":"SAN RAFAEL DE LA CRUZ, ARJONA, BOLIVAR"},
    {"id":500,"nombre":"SINCERIN, ARJONA, BOLIVAR"},
    {"id":501,"nombre":"MARACAIBO, ARMERO, TOLIMA"},
    {"id":502,"nombre":"MENDEZ, ARMERO, TOLIMA"},
    {"id":503,"nombre":"NUEVO HORIZONTE, ARMERO, TOLIMA"},
    {"id":504,"nombre":"SAN FELIPE, ARMERO, TOLIMA"},
    {"id":505,"nombre":"SAN JORGE, ARMERO, TOLIMA"},
    {"id":506,"nombre":"SAN LORENZO, ARMERO, TOLIMA"},
    {"id":507,"nombre":"SAN PEDRO, ARMERO, TOLIMA"},
    {"id":508,"nombre":"GUAYABAL, ARMERO, TOLIMA"},
    {"id":509,"nombre":"ARROYOHONDO, ARROYOHONDO, BOLIVAR"},
    {"id":510,"nombre":"MACHADO, ARROYOHONDO, BOLIVAR"},
    {"id":511,"nombre":"MONROY, ARROYOHONDO, BOLIVAR"},
    {"id":512,"nombre":"PILON, ARROYOHONDO, BOLIVAR"},
    {"id":513,"nombre":"SAN FRANCISCO, ARROYOHONDO, BOLIVAR"},
    {"id":514,"nombre":"SATO, ARROYOHONDO, BOLIVAR"},
    {"id":515,"nombre":"SOLABANDA, ARROYOHONDO, BOLIVAR"},
    {"id":516,"nombre":"ARJONA, ASTREA, CESAR"},
    {"id":517,"nombre":"ASTREA, ASTREA, CESAR"},
    {"id":518,"nombre":"EL HEBRON, ASTREA, CESAR"},
    {"id":519,"nombre":"EL YUCAL, ASTREA, CESAR"},
    {"id":520,"nombre":"SANTA CECILIA, ASTREA, CESAR"},
    {"id":521,"nombre":"ANDES ESTRELLA, ATACO, TOLIMA"},
    {"id":522,"nombre":"ATACO, ATACO, TOLIMA"},
    {"id":523,"nombre":"BALSILLAS, ATACO, TOLIMA"},
    {"id":524,"nombre":"BERLIN, ATACO, TOLIMA"},
    {"id":525,"nombre":"CAMPOHERMOSO, ATACO, TOLIMA"},
    {"id":526,"nombre":"CASA DE ZINC, ATACO, TOLIMA"},
    {"id":527,"nombre":"CASA VERDE, ATACO, TOLIMA"},
    {"id":528,"nombre":"EL CONDOR, ATACO, TOLIMA"},
    {"id":529,"nombre":"EL PAUJIL, ATACO, TOLIMA"},
    {"id":530,"nombre":"LA CEJA (MESITAS), ATACO, TOLIMA"},
    {"id":531,"nombre":"LA ZONA MINERA, ATACO, TOLIMA"},
    {"id":532,"nombre":"MESA DE POLE, ATACO, TOLIMA"},
    {"id":533,"nombre":"MONTELORO, ATACO, TOLIMA"},
    {"id":534,"nombre":"POLECITO, ATACO, TOLIMA"},
    {"id":535,"nombre":"POMARROSO, ATACO, TOLIMA"},
    {"id":536,"nombre":"SAN ANTONIO DE POLE, ATACO, TOLIMA"},
    {"id":537,"nombre":"SANTIAGO PEREZ, ATACO, TOLIMA"},
    {"id":538,"nombre":"ARENAL, ATRATO, CHOCO"},
    {"id":539,"nombre":"YUTO, ATRATO, CHOCO"},
    {"id":540,"nombre":"BRAZO DE LOS NARANJOS, ATRATO, CHOCO"},
    {"id":541,"nombre":"DONA JOSEFA, ATRATO, CHOCO"},
    {"id":542,"nombre":"EL OCHO, ATRATO, CHOCO"},
    {"id":543,"nombre":"ISLA DE LOS PINILLA, ATRATO, CHOCO"},
    {"id":544,"nombre":"LA MOLANA, ATRATO, CHOCO"},
    {"id":545,"nombre":"LA TOMA, ATRATO, CHOCO"},
    {"id":546,"nombre":"MOTOLDO, ATRATO, CHOCO"},
    {"id":547,"nombre":"PLAYA DE ORO, ATRATO, CHOCO"},
    {"id":548,"nombre":"PUENTE DE PAIMADO, ATRATO, CHOCO"},
    {"id":549,"nombre":"PUENTE DE TANANDO, ATRATO, CHOCO"},
    {"id":550,"nombre":"REAL DE TANANDO, ATRATO, CHOCO"},
    {"id":551,"nombre":"SAMURINDO, ATRATO, CHOCO"},
    {"id":552,"nombre":"SAN JOSE DE PURRE, ATRATO, CHOCO"},
    {"id":553,"nombre":"SAN MARTIN DE PURRE, ATRATO, CHOCO"},
    {"id":554,"nombre":"VUELTA MANZA, ATRATO, CHOCO"},
    {"id":555,"nombre":"ALFONSO LOPEZ, AYAPEL, CORDOBA"},
    {"id":556,"nombre":"AYAPEL, AYAPEL, CORDOBA"},
    {"id":557,"nombre":"CECILIA, AYAPEL, CORDOBA"},
    {"id":558,"nombre":"EL CEDRO, AYAPEL, CORDOBA"},
    {"id":559,"nombre":"LAS DELICIAS, AYAPEL, CORDOBA"},
    {"id":560,"nombre":"MARRALU, AYAPEL, CORDOBA"},
    {"id":561,"nombre":"NARIÑO, AYAPEL, CORDOBA"},
    {"id":562,"nombre":"PALOTAL, AYAPEL, CORDOBA"},
    {"id":563,"nombre":"PUEBLO NUEVO - POPALES, AYAPEL, CORDOBA"},
    {"id":564,"nombre":"SINCELEJITO, AYAPEL, CORDOBA"},
    {"id":565,"nombre":"AGUASAL, BAGADO, CHOCO"},
    {"id":566,"nombre":"BAGADO, BAGADO, CHOCO"},
    {"id":567,"nombre":"CHAMBARE, BAGADO, CHOCO"},
    {"id":568,"nombre":"DABAIBE, BAGADO, CHOCO"},
    {"id":569,"nombre":"EL SALTO, BAGADO, CHOCO"},
    {"id":570,"nombre":"ENGRIVADO, BAGADO, CHOCO"},
    {"id":571,"nombre":"LA SIERRA, BAGADO, CHOCO"},
    {"id":572,"nombre":"PESCADITO, BAGADO, CHOCO"},
    {"id":573,"nombre":"PIEDRA HONDA, BAGADO, CHOCO"},
    {"id":574,"nombre":"PLAYA BONITA, BAGADO, CHOCO"},
    {"id":575,"nombre":"SAN MARINO, BAGADO, CHOCO"},
    {"id":576,"nombre":"TAPERA, BAGADO, CHOCO"},
    {"id":577,"nombre":"VIVICORA, BAGADO, CHOCO"},
    {"id":578,"nombre":"MUTIS, BAHIA SOLANO, CHOCO"},
    {"id":579,"nombre":"CUPICA, BAHIA SOLANO, CHOCO"},
    {"id":580,"nombre":"EL VALLE, BAHIA SOLANO, CHOCO"},
    {"id":581,"nombre":"HUACA, BAHIA SOLANO, CHOCO"},
    {"id":582,"nombre":"HUINA, BAHIA SOLANO, CHOCO"},
    {"id":583,"nombre":"JUNA, BAHIA SOLANO, CHOCO"},
    {"id":584,"nombre":"MECANA, BAHIA SOLANO, CHOCO"},
    {"id":585,"nombre":"NABUGA, BAHIA SOLANO, CHOCO"},
    {"id":586,"nombre":"TEBADA, BAHIA SOLANO, CHOCO"},
    {"id":587,"nombre":"PIZARRO, BAJO BAUDO, CHOCO"},
    {"id":588,"nombre":"BELEN DE DOCAMPODO, BAJO BAUDO, CHOCO"},
    {"id":589,"nombre":"CUEVITA, BAJO BAUDO, CHOCO"},
    {"id":590,"nombre":"CURUNDO, BAJO BAUDO, CHOCO"},
    {"id":591,"nombre":"DOTENEDO, BAJO BAUDO, CHOCO"},
    {"id":592,"nombre":"GUINEAL, BAJO BAUDO, CHOCO"},
    {"id":593,"nombre":"HIJUA, BAJO BAUDO, CHOCO"},
    {"id":594,"nombre":"ORPUA, BAJO BAUDO, CHOCO"},
    {"id":595,"nombre":"PAVASA, BAJO BAUDO, CHOCO"},
    {"id":596,"nombre":"PILIZA, BAJO BAUDO, CHOCO"},
    {"id":597,"nombre":"PLAYITA, BAJO BAUDO, CHOCO"},
    {"id":598,"nombre":"PUERTO ABADIA, BAJO BAUDO, CHOCO"},
    {"id":599,"nombre":"PUERTO ECHEVERRI, BAJO BAUDO, CHOCO"},
    {"id":600,"nombre":"PUNTA DE IGUA, BAJO BAUDO, CHOCO"},
    {"id":601,"nombre":"PUNTA PURRICHA, BAJO BAUDO, CHOCO"},
    {"id":602,"nombre":"PURRICHA, BAJO BAUDO, CHOCO"},
    {"id":603,"nombre":"QUERA, BAJO BAUDO, CHOCO"},
    {"id":604,"nombre":"SAN MIGUEL DE BAUDO, BAJO BAUDO, CHOCO"},
    {"id":605,"nombre":"SIVIRU, BAJO BAUDO, CHOCO"},
    {"id":606,"nombre":"TORREIDO DE ABAJO, BAJO BAUDO, CHOCO"},
    {"id":607,"nombre":"TORREIDO DE ARRIBA, BAJO BAUDO, CHOCO"},
    {"id":608,"nombre":"USARAGA, BAJO BAUDO, CHOCO"},
    {"id":609,"nombre":"VILLA COLOMBIA, BAJO BAUDO, CHOCO"},
    {"id":610,"nombre":"VILLA MARIA, BAJO BAUDO, CHOCO"},
    {"id":611,"nombre":"VIRUDO, BAJO BAUDO, CHOCO"},
    {"id":612,"nombre":"BARANOA, BARANOA, ATLANTICO"},
    {"id":613,"nombre":"CAMPECHE, BARANOA, ATLANTICO"},
    {"id":614,"nombre":"PITAL, BARANOA, ATLANTICO"},
    {"id":615,"nombre":"ZIBARCO, BARANOA, ATLANTICO"},
    {"id":616,"nombre":"BARAYA, BARAYA, HUILA"},
    {"id":617,"nombre":"EL HOTEL, BARAYA, HUILA"},
    {"id":618,"nombre":"LA TROJA, BARAYA, HUILA"},
    {"id":619,"nombre":"LA UNION, BARAYA, HUILA"},
    {"id":620,"nombre":"LAURELES, BARAYA, HUILA"},
    {"id":621,"nombre":"MIRAMAR, BARAYA, HUILA"},
    {"id":622,"nombre":"SOTO, BARAYA, HUILA"},
    {"id":623,"nombre":"TURQUESTAN, BARAYA, HUILA"},
    {"id":624,"nombre":"ALBI, BARBACOAS, NARIÑO"},
    {"id":625,"nombre":"ALTAQUER, BARBACOAS, NARIÑO"},
    {"id":626,"nombre":"BARBACOAS, BARBACOAS, NARIÑO"},
    {"id":627,"nombre":"BUENAVISTA, BARBACOAS, NARIÑO"},
    {"id":628,"nombre":"CARCUEL, BARBACOAS, NARIÑO"},
    {"id":629,"nombre":"CARGAZOL, BARBACOAS, NARIÑO"},
    {"id":630,"nombre":"CASCAJERO, BARBACOAS, NARIÑO"},
    {"id":631,"nombre":"CHALALBI, BARBACOAS, NARIÑO"},
    {"id":632,"nombre":"CHALCHAL, BARBACOAS, NARIÑO"},
    {"id":633,"nombre":"CHAPIRA, BARBACOAS, NARIÑO"},
    {"id":634,"nombre":"COSCORRON TELEMBI, BARBACOAS, NARIÑO"},
    {"id":635,"nombre":"DIAGUILLO, BARBACOAS, NARIÑO"},
    {"id":636,"nombre":"EL DIVISO, BARBACOAS, NARIÑO"},
    {"id":637,"nombre":"GUAGAYPI, BARBACOAS, NARIÑO"},
    {"id":638,"nombre":"JUNIN, BARBACOAS, NARIÑO"},
    {"id":639,"nombre":"JUSTO ORTIZ, BARBACOAS, NARIÑO"},
    {"id":640,"nombre":"LA PLAYA, BARBACOAS, NARIÑO"},
    {"id":641,"nombre":"LAS CRUCES, BARBACOAS, NARIÑO"},
    {"id":642,"nombre":"LOS BRAZOS, BARBACOAS, NARIÑO"},
    {"id":643,"nombre":"LUIS AVELINO PEREZ, BARBACOAS, NARIÑO"},
    {"id":644,"nombre":"MONGON, BARBACOAS, NARIÑO"},
    {"id":645,"nombre":"NAMBI, BARBACOAS, NARIÑO"},
    {"id":646,"nombre":"OLAYA HERRERA, BARBACOAS, NARIÑO"},
    {"id":647,"nombre":"PAMBANA, BARBACOAS, NARIÑO"},
    {"id":648,"nombre":"PAUNDE, BARBACOAS, NARIÑO"},
    {"id":649,"nombre":"PIMBI, BARBACOAS, NARIÑO"},
    {"id":650,"nombre":"SALI, BARBACOAS, NARIÑO"},
    {"id":651,"nombre":"SAN JUAN PALACIO, BARBACOAS, NARIÑO"},
    {"id":652,"nombre":"SAN MIGUEL NAMBI, BARBACOAS, NARIÑO"},
    {"id":653,"nombre":"SOLEDAD, BARBACOAS, NARIÑO"},
    {"id":654,"nombre":"SUCRE GUINULTE, BARBACOAS, NARIÑO"},
    {"id":655,"nombre":"TELPI, BARBACOAS, NARIÑO"},
    {"id":656,"nombre":"TERAIMBE, BARBACOAS, NARIÑO"},
    {"id":657,"nombre":"YACULA, BARBACOAS, NARIÑO"},
    {"id":658,"nombre":"YALARE, BARBACOAS, NARIÑO"},
    {"id":659,"nombre":"BARICHARA, BARICHARA, SANTANDER"},
    {"id":660,"nombre":"BUTAREGUA, BARICHARA, SANTANDER"},
    {"id":661,"nombre":"GUANE, BARICHARA, SANTANDER"},
    {"id":662,"nombre":"PARAMITO, BARICHARA, SANTANDER"},
    {"id":663,"nombre":"BARRANCA DE UPIA, BARRANCA DE UPIA, META"},
    {"id":664,"nombre":"EL HIJOA, BARRANCA DE UPIA, META"},
    {"id":665,"nombre":"GUACAVIA, BARRANCA DE UPIA, META"},
    {"id":666,"nombre":"GUAICARAMO, BARRANCA DE UPIA, META"},
    {"id":667,"nombre":"SAN IGNACIO, BARRANCA DE UPIA, META"},
    {"id":668,"nombre":"VERACRUZ, BARRANCA DE UPIA, META"},
    {"id":669,"nombre":"BARRANCABERMEJA, BARRANCABERMEJA, SANTANDER"},
    {"id":670,"nombre":"EL CENTRO, BARRANCABERMEJA, SANTANDER"},
    {"id":671,"nombre":"EL LLANITO, BARRANCABERMEJA, SANTANDER"},
    {"id":672,"nombre":"LA FORTUNA, BARRANCABERMEJA, SANTANDER"},
    {"id":673,"nombre":"LIZAMA, BARRANCABERMEJA, SANTANDER"},
    {"id":674,"nombre":"LOS LAURELES, BARRANCABERMEJA, SANTANDER"},
    {"id":675,"nombre":"MESETA SAN RAFAEL, BARRANCABERMEJA, SANTANDER"},
    {"id":676,"nombre":"SAN RAFAEL DE CHUCURI, BARRANCABERMEJA, SANTANDER"},
    {"id":677,"nombre":"BARRANCAS, BARRANCAS, LA GUAJIRA"},
    {"id":678,"nombre":"CARRETALITO, BARRANCAS, LA GUAJIRA"},
    {"id":679,"nombre":"GUACAMAYAL, BARRANCAS, LA GUAJIRA"},
    {"id":680,"nombre":"GUAYACANAL, BARRANCAS, LA GUAJIRA"},
    {"id":681,"nombre":"LAGUNITA, BARRANCAS, LA GUAJIRA"},
    {"id":682,"nombre":"MANANTIAL, BARRANCAS, LA GUAJIRA"},
    {"id":683,"nombre":"OREGANAL, BARRANCAS, LA GUAJIRA"},
    {"id":684,"nombre":"PAPAYAL, BARRANCAS, LA GUAJIRA"},
    {"id":685,"nombre":"POZO HONDO, BARRANCAS, LA GUAJIRA"},
    {"id":686,"nombre":"ROCHE, BARRANCAS, LA GUAJIRA"},
    {"id":687,"nombre":"SAN PEDRO, BARRANCAS, LA GUAJIRA"},
    {"id":688,"nombre":"BARRANCO DE LOBA, BARRANCO DE LOBA, BOLIVAR"},
    {"id":689,"nombre":"EL RUBIO, BARRANCO DE LOBA, BOLIVAR"},
    {"id":690,"nombre":"LAS DELICIAS, BARRANCO DE LOBA, BOLIVAR"},
    {"id":691,"nombre":"LOS CERRITOS, BARRANCO DE LOBA, BOLIVAR"},
    {"id":692,"nombre":"RIONUEVO, BARRANCO DE LOBA, BOLIVAR"},
    {"id":693,"nombre":"SAN ANTONIO, BARRANCO DE LOBA, BOLIVAR"},
    {"id":694,"nombre":"SAN ISIDRO, BARRANCO DE LOBA, BOLIVAR"},
    {"id":695,"nombre":"ARRECIFAL, BARRANCO MINAS, GUAINIA"},
    {"id":696,"nombre":"BARRANCO MINAS, BARRANCO MINAS, GUAINIA"},
    {"id":697,"nombre":"SAPUARA, BARRANCO MINAS, GUAINIA"},
    {"id":698,"nombre":"BARRANQUILLA, BARRANQUILLA, ATLANTICO"},
    {"id":699,"nombre":"EDUARDO SANTOS (LA PLAYA), BARRANQUILLA, ATLANTICO"},
    {"id":700,"nombre":"JUAN MINA, BARRANQUILLA, ATLANTICO"},
    {"id":701,"nombre":"LAS FLORES, BARRANQUILLA, ATLANTICO"},
    {"id":702,"nombre":"SIAPE, BARRANQUILLA, ATLANTICO"},
    {"id":703,"nombre":"BECERRIL, BECERRIL, CESAR"},
    {"id":704,"nombre":"ESTADOS UNIDOS, BECERRIL, CESAR"},
    {"id":705,"nombre":"TAMAQUITO, BECERRIL, CESAR"},
    {"id":706,"nombre":"BELALCAZAR, BELALCAZAR, CALDAS"},
    {"id":707,"nombre":"EL AGUILA, BELALCAZAR, CALDAS"},
    {"id":708,"nombre":"EL MADRONO, BELALCAZAR, CALDAS"},
    {"id":709,"nombre":"LA CASCADA, BELALCAZAR, CALDAS"},
    {"id":710,"nombre":"QUIEBRA DE LA HABANA, BELALCAZAR, CALDAS"},
    {"id":711,"nombre":"SAN ISIDRO, BELALCAZAR, CALDAS"},
    {"id":712,"nombre":"BELEN DE LOS ANDAQUIES, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":713,"nombre":"EL PORTAL LA MONO, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":714,"nombre":"LOS ALETONES, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":715,"nombre":"LOS ANGELES, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":716,"nombre":"PUERTO TORRES, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":717,"nombre":"SAN JORGE, BELEN DE LOS ANDAQUIES, CAQUETA"},
    {"id":718,"nombre":"ANDICA, BELEN DE UMBRIA, RISARALDA"},
    {"id":719,"nombre":"BAJO SIRGUIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":720,"nombre":"BALDELOMAR, BELEN DE UMBRIA, RISARALDA"},
    {"id":721,"nombre":"BELEN DE UMBRIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":722,"nombre":"COLUMBIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":723,"nombre":"EL AGUACATE, BELEN DE UMBRIA, RISARALDA"},
    {"id":724,"nombre":"EL CONGO, BELEN DE UMBRIA, RISARALDA"},
    {"id":725,"nombre":"MATA DE GUADUA, BELEN DE UMBRIA, RISARALDA"},
    {"id":726,"nombre":"PROVIDENCIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":727,"nombre":"PUENTE UMBRIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":728,"nombre":"SANTA EMILIA, BELEN DE UMBRIA, RISARALDA"},
    {"id":729,"nombre":"TAPARCAL, BELEN DE UMBRIA, RISARALDA"},
    {"id":730,"nombre":"BELLAVISTA, BELLO, ANTIOQUIA"},
    {"id":731,"nombre":"BELLO, BELLO, ANTIOQUIA"},
    {"id":732,"nombre":"EL PARAISO, BELLO, ANTIOQUIA"},
    {"id":733,"nombre":"LA CAMILA, BELLO, ANTIOQUIA"},
    {"id":734,"nombre":"SAN FELIX, BELLO, ANTIOQUIA"},
    {"id":735,"nombre":"SAN JOSE DEL PINAL, BELLO, ANTIOQUIA"},
    {"id":736,"nombre":"SANTA RITA, BELLO, ANTIOQUIA"},
    {"id":737,"nombre":"ZARZAL, BELLO, ANTIOQUIA"},
    {"id":738,"nombre":"BELMIRA, BELMIRA, ANTIOQUIA"},
    {"id":739,"nombre":"LABORES, BELMIRA, ANTIOQUIA"},
    {"id":740,"nombre":"PLAYAS, BELMIRA, ANTIOQUIA"},
    {"id":741,"nombre":"QUEBRADITAS, BELMIRA, ANTIOQUIA"},
    {"id":742,"nombre":"BELTRAN, BELTRAN, CUNDINAMARCA"},
    {"id":743,"nombre":"LA POPA, BELTRAN, CUNDINAMARCA"},
    {"id":744,"nombre":"PAQUILO, BELTRAN, CUNDINAMARCA"},
    {"id":745,"nombre":"PUERTO - TEJA, BELTRAN, CUNDINAMARCA"},
    {"id":746,"nombre":"PUERTO DE GRAMALOTAL, BELTRAN, CUNDINAMARCA"},
    {"id":747,"nombre":"BERBEO, BERBEO, BOYACA"},
    {"id":748,"nombre":"BETANIA, BETANIA, ANTIOQUIA"},
    {"id":749,"nombre":"BETEITIVA, BETEITIVA, BOYACA"},
    {"id":750,"nombre":"OTENGA, BETEITIVA, BOYACA"},
    {"id":751,"nombre":"BITUIMA, BITUIMA, CUNDINAMARCA"},
    {"id":752,"nombre":"BOQUERON DE ILO, BITUIMA, CUNDINAMARCA"},
    {"id":753,"nombre":"LA PLAZUELA, BITUIMA, CUNDINAMARCA"},
    {"id":754,"nombre":"LA SIERRA, BITUIMA, CUNDINAMARCA"},
    {"id":755,"nombre":"BOAVITA, BOAVITA, BOYACA"},
    {"id":756,"nombre":"EL ESPIGON, BOAVITA, BOYACA"},
    {"id":757,"nombre":"SAN FRANCISCO, BOAVITA, BOYACA"},
    {"id":758,"nombre":"BOCHALEMA, BOCHALEMA, NORTE DE SANTANDER"},
    {"id":759,"nombre":"ESPEJUELOS, BOCHALEMA, NORTE DE SANTANDER"},
    {"id":760,"nombre":"LA DONJUANA, BOCHALEMA, NORTE DE SANTANDER"},
    {"id":761,"nombre":"MONTERREDONDO, BOCHALEMA, NORTE DE SANTANDER"},
    {"id":762,"nombre":"PORTACHUELO, BOCHALEMA, NORTE DE SANTANDER"},
    {"id":763,"nombre":"BOJACA, BOJACA, CUNDINAMARCA"},
    {"id":764,"nombre":"SANTA BARBARA, BOJACA, CUNDINAMARCA"},
    {"id":765,"nombre":"ALFONSO LOPEZ, BOJAYA, CHOCO"},
    {"id":766,"nombre":"APOGADO, BOJAYA, CHOCO"},
    {"id":767,"nombre":"BELLAVISTA, BOJAYA, CHOCO"},
    {"id":768,"nombre":"EL TIGRE, BOJAYA, CHOCO"},
    {"id":769,"nombre":"ISLA DE PALACIOS, BOJAYA, CHOCO"},
    {"id":770,"nombre":"LA BOBA, BOJAYA, CHOCO"},
    {"id":771,"nombre":"LOMA DE BOJAYA, BOJAYA, CHOCO"},
    {"id":772,"nombre":"MESOPOTAMIA, BOJAYA, CHOCO"},
    {"id":773,"nombre":"NAPIPI, BOJAYA, CHOCO"},
    {"id":774,"nombre":"POGUE, BOJAYA, CHOCO"},
    {"id":775,"nombre":"PUEBLO NUEVO, BOJAYA, CHOCO"},
    {"id":776,"nombre":"PUERTO CONTO, BOJAYA, CHOCO"},
    {"id":777,"nombre":"PUERTO LOPEZ, BOJAYA, CHOCO"},
    {"id":778,"nombre":"SAN JOSE DE LA CALLE, BOJAYA, CHOCO"},
    {"id":779,"nombre":"SANTA CRUZ, BOJAYA, CHOCO"},
    {"id":780,"nombre":"VERACRUZ, BOJAYA, CHOCO"},
    {"id":781,"nombre":"BOCAS DE TIGRE, BOSCONIA, CESAR"},
    {"id":782,"nombre":"BOSCONIA, BOSCONIA, CESAR"},
    {"id":783,"nombre":"LAS MARGARITAS, BOSCONIA, CESAR"},
    {"id":784,"nombre":"LAS PAVAS, BOSCONIA, CESAR"},
    {"id":785,"nombre":"LOMA COLORADA, BOSCONIA, CESAR"},
    {"id":786,"nombre":"BOYACA, BOYACA, BOYACA"},
    {"id":787,"nombre":"BARRIO GIRARDOT, BUCARAMANGA, SANTANDER"},
    {"id":788,"nombre":"BARRIO KENNEDY, BUCARAMANGA, SANTANDER"},
    {"id":789,"nombre":"BARRIO LAS AMERICAS, BUCARAMANGA, SANTANDER"},
    {"id":790,"nombre":"BARRIO REGADERO, BUCARAMANGA, SANTANDER"},
    {"id":791,"nombre":"BARRIO SANTANDER, BUCARAMANGA, SANTANDER"},
    {"id":792,"nombre":"BUCARAMANGA, BUCARAMANGA, SANTANDER"},
    {"id":793,"nombre":"CAMPO HERMOSO, BUCARAMANGA, SANTANDER"},
    {"id":794,"nombre":"CIUDAD MUTIS, BUCARAMANGA, SANTANDER"},
    {"id":795,"nombre":"ESTACION MADRID, BUCARAMANGA, SANTANDER"},
    {"id":796,"nombre":"LA PEDREGOSA, BUCARAMANGA, SANTANDER"},
    {"id":797,"nombre":"MONSERRATE, BUCARAMANGA, SANTANDER"},
    {"id":798,"nombre":"AGUABLANCA, BUCARASICA, NORTE DE SANTANDER"},
    {"id":799,"nombre":"BUCARASICA, BUCARASICA, NORTE DE SANTANDER"},
    {"id":800,"nombre":"LA CURVA, BUCARASICA, NORTE DE SANTANDER"},
    {"id":801,"nombre":"LA SANJUANA, BUCARASICA, NORTE DE SANTANDER"},
    {"id":802,"nombre":"LAS CUADRAS, BUCARASICA, NORTE DE SANTANDER"},
    {"id":803,"nombre":"LAS FORTUNAS, BUCARASICA, NORTE DE SANTANDER"},
    {"id":804,"nombre":"ARAGON, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":805,"nombre":"AUCA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":806,"nombre":"BAJO CALIMA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":807,"nombre":"BARCO CAJAMBRE, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":808,"nombre":"BUENAVENTURA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":809,"nombre":"CACOLI REPOSO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":810,"nombre":"CALLE LARGA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":811,"nombre":"CHAMUSCADO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":812,"nombre":"CISNEROS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":813,"nombre":"CITRONELA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":814,"nombre":"CONCHERITO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":815,"nombre":"CORDOBA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":816,"nombre":"DOSQUEBRADAS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":817,"nombre":"EL ALAMBIQUE, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":818,"nombre":"EL BARRANCO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":819,"nombre":"EL BRACITO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":820,"nombre":"EL PAPAYO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":821,"nombre":"EL TRIGAL, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":822,"nombre":"GAMBOA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":823,"nombre":"GUADUALITO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":824,"nombre":"GUAINIA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":825,"nombre":"JUANCHACO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":826,"nombre":"JUNTA DE YURUMANGUI, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":827,"nombre":"JUNTAS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":828,"nombre":"KILOMETRO 43, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":829,"nombre":"LA BARRA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":830,"nombre":"LA BARTOLA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":831,"nombre":"LA BOCANA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":832,"nombre":"LA BREA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":833,"nombre":"LA DELFINA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":834,"nombre":"LA MINA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":835,"nombre":"LADRILLEROS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":836,"nombre":"MALAGUITA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":837,"nombre":"MALLORQUIN, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":838,"nombre":"PAPAYAL, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":839,"nombre":"PIANGUITA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":840,"nombre":"PITAL, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":841,"nombre":"POTEDO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":842,"nombre":"PUERTO ESPANA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":843,"nombre":"PUERTO MERIZALDE, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":844,"nombre":"PUERTO PIZARRO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":845,"nombre":"PUNTA SOLDADO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":846,"nombre":"SAN ANTONIO DE ANCHICAYA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":847,"nombre":"SAN ANTONIO DE YURUMANGUI, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":848,"nombre":"SAN CIPRIANO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":849,"nombre":"SAN FRANCISCO DE NAYA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":850,"nombre":"SAN ISIDRO, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":851,"nombre":"SAN JOAQUIN, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":852,"nombre":"SAN JOSE DE ANCHICAYA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":853,"nombre":"SAN JOSE DE NAYA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":854,"nombre":"SAN JOSE DE YURUMANGUI, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":855,"nombre":"SAN MARCOS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":856,"nombre":"SANTA CRUZ, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":857,"nombre":"SILVA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":858,"nombre":"VENERAL, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":859,"nombre":"YURUMANGUI, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":860,"nombre":"ZABALETAS, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":861,"nombre":"ZARAGOZA, BUENAVENTURA, VALLE DEL CAUCA"},
    {"id":862,"nombre":"ALTAMIRA, BUENOS AIRES, CAUCA"},
    {"id":863,"nombre":"ALTO NAYA, BUENOS AIRES, CAUCA"},
    {"id":864,"nombre":"ASNAZU, BUENOS AIRES, CAUCA"},
    {"id":865,"nombre":"BELLAVISTA, BUENOS AIRES, CAUCA"},
    {"id":866,"nombre":"BETULIA, BUENOS AIRES, CAUCA"},
    {"id":867,"nombre":"BUENOS AIRES, BUENOS AIRES, CAUCA"},
    {"id":868,"nombre":"EL CARMEN, BUENOS AIRES, CAUCA"},
    {"id":869,"nombre":"EL CERAL, BUENOS AIRES, CAUCA"},
    {"id":870,"nombre":"EL LLANITO, BUENOS AIRES, CAUCA"},
    {"id":871,"nombre":"EL PORVENIR, BUENOS AIRES, CAUCA"},
    {"id":872,"nombre":"HONDURAS, BUENOS AIRES, CAUCA"},
    {"id":873,"nombre":"LA BALASTRERA, BUENOS AIRES, CAUCA"},
    {"id":874,"nombre":"LA BALSA, BUENOS AIRES, CAUCA"},
    {"id":875,"nombre":"LA ESPERANZA, BUENOS AIRES, CAUCA"},
    {"id":876,"nombre":"LA MESETA, BUENOS AIRES, CAUCA"},
    {"id":877,"nombre":"LA TOMA, BUENOS AIRES, CAUCA"},
    {"id":878,"nombre":"LA VENTURA, BUENOS AIRES, CAUCA"},
    {"id":879,"nombre":"MUNCHIQUE, BUENOS AIRES, CAUCA"},
    {"id":880,"nombre":"PALOBLANCO, BUENOS AIRES, CAUCA"},
    {"id":881,"nombre":"SAN FRANCISCO, BUENOS AIRES, CAUCA"},
    {"id":882,"nombre":"SAN IGNACIO, BUENOS AIRES, CAUCA"},
    {"id":883,"nombre":"SANTA MARTHA, BUENOS AIRES, CAUCA"},
    {"id":884,"nombre":"TIMBA, BUENOS AIRES, CAUCA"},
    {"id":885,"nombre":"ALTACLARA, BUESACO, NARIÑO"},
    {"id":886,"nombre":"BUESACO, BUESACO, NARIÑO"},
    {"id":887,"nombre":"HATO TONGOSOY, BUESACO, NARIÑO"},
    {"id":888,"nombre":"JUANAMEL, BUESACO, NARIÑO"},
    {"id":889,"nombre":"ORTEGA, BUESACO, NARIÑO"},
    {"id":890,"nombre":"PAJAJOY, BUESACO, NARIÑO"},
    {"id":891,"nombre":"PALASINOY, BUESACO, NARIÑO"},
    {"id":892,"nombre":"PARAPETOS, BUESACO, NARIÑO"},
    {"id":893,"nombre":"ROSAL DEL MONTE, BUESACO, NARIÑO"},
    {"id":894,"nombre":"SAN ANTONIO, BUESACO, NARIÑO"},
    {"id":895,"nombre":"SAN IGNACIO, BUESACO, NARIÑO"},
    {"id":896,"nombre":"SANTAFE, BUESACO, NARIÑO"},
    {"id":897,"nombre":"SANTAMARIA, BUESACO, NARIÑO"},
    {"id":898,"nombre":"VERACRUZ, BUESACO, NARIÑO"},
    {"id":899,"nombre":"VILLAMORENO, BUESACO, NARIÑO"},
    {"id":900,"nombre":"BUGALAGRANDE, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":901,"nombre":"CEILAN, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":902,"nombre":"CHICORAL, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":903,"nombre":"CHORRERAS, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":904,"nombre":"EL GUAYABO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":905,"nombre":"EL OVERO SECTOR EL POBLADO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":906,"nombre":"EL OVERO SECTOR LA MARIA, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":907,"nombre":"EL PLACER, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":908,"nombre":"EL RAICERO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":909,"nombre":"EL ROCIO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":910,"nombre":"EL VOLADERO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":911,"nombre":"GALICIA, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":912,"nombre":"LA MORENA, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":913,"nombre":"MESTIZAL, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":914,"nombre":"PAILA ARRIBA, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":915,"nombre":"SAN ANTONIO, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":916,"nombre":"URIBE URIBE, BUGALAGRANDE, VALLE DEL CAUCA"},
    {"id":917,"nombre":"BURITICA, BURITICA, ANTIOQUIA"},
    {"id":918,"nombre":"EL NARANJO, BURITICA, ANTIOQUIA"},
    {"id":919,"nombre":"GUARCO, BURITICA, ANTIOQUIA"},
    {"id":920,"nombre":"LA ANGELINA, BURITICA, ANTIOQUIA"},
    {"id":921,"nombre":"MANGLAR, BURITICA, ANTIOQUIA"},
    {"id":922,"nombre":"TABACAL, BURITICA, ANTIOQUIA"},
    {"id":923,"nombre":"URARCO, BURITICA, ANTIOQUIA"},
    {"id":924,"nombre":"BUSBANZA, BUSBANZA, BOYACA"},
    {"id":925,"nombre":"CABUYARO, CABUYARO, META"},
    {"id":926,"nombre":"GUAYABAL DE UPIA, CABUYARO, META"},
    {"id":927,"nombre":"LOS MANGOS, CABUYARO, META"},
    {"id":928,"nombre":"VISO DE UPIA, CABUYARO, META"},
    {"id":929,"nombre":"CACAHUAL, CACAHUAL, GUAINIA"},
    {"id":930,"nombre":"CACERES, CACERES, ANTIOQUIA"},
    {"id":931,"nombre":"EL JARDIN (TAMANA), CACERES, ANTIOQUIA"},
    {"id":932,"nombre":"GUARUMO, CACERES, ANTIOQUIA"},
    {"id":933,"nombre":"MANIZALES, CACERES, ANTIOQUIA"},
    {"id":934,"nombre":"NUEVA ESPERANZA, CACERES, ANTIOQUIA"},
    {"id":935,"nombre":"PIAMONTE, CACERES, ANTIOQUIA"},
    {"id":936,"nombre":"PUERTO BELGICA, CACERES, ANTIOQUIA"},
    {"id":937,"nombre":"RIO MAN, CACERES, ANTIOQUIA"},
    {"id":938,"nombre":"CACHIPAY, CACHIPAY, CUNDINAMARCA"},
    {"id":939,"nombre":"PENA NEGRA, CACHIPAY, CUNDINAMARCA"},
    {"id":940,"nombre":"CACHIRA, CACHIRA, NORTE DE SANTANDER"},
    {"id":941,"nombre":"CAMPO ALEGRE, CACHIRA, NORTE DE SANTANDER"},
    {"id":942,"nombre":"CARCASI, CACHIRA, NORTE DE SANTANDER"},
    {"id":943,"nombre":"EL LLANO, CACHIRA, NORTE DE SANTANDER"},
    {"id":944,"nombre":"LA CARRERA, CACHIRA, NORTE DE SANTANDER"},
    {"id":945,"nombre":"LA VEGA, CACHIRA, NORTE DE SANTANDER"},
    {"id":946,"nombre":"LAGUNA DE ORIENTE, CACHIRA, NORTE DE SANTANDER"},
    {"id":947,"nombre":"LOS MANGOS, CACHIRA, NORTE DE SANTANDER"},
    {"id":948,"nombre":"MIRAFLORES, CACHIRA, NORTE DE SANTANDER"},
    {"id":949,"nombre":"PRIMAVERA, CACHIRA, NORTE DE SANTANDER"},
    {"id":950,"nombre":"PUEBLO NUEVO, CACHIRA, NORTE DE SANTANDER"},
    {"id":951,"nombre":"RAMIREZ, CACHIRA, NORTE DE SANTANDER"},
    {"id":952,"nombre":"SAN JOSE DE LA MONTANA, CACHIRA, NORTE DE SANTANDER"},
    {"id":953,"nombre":"VEINTE DE JULIO, CACHIRA, NORTE DE SANTANDER"},
    {"id":954,"nombre":"CACOTA, CACOTA, NORTE DE SANTANDER"},
    {"id":955,"nombre":"FENICIA, CACOTA, NORTE DE SANTANDER"},
    {"id":956,"nombre":"ICOTA, CACOTA, NORTE DE SANTANDER"},
    {"id":957,"nombre":"TARGUALA, CACOTA, NORTE DE SANTANDER"},
    {"id":958,"nombre":"ASESI, CAICEDO, ANTIOQUIA"},
    {"id":959,"nombre":"CAICEDO, CAICEDO, ANTIOQUIA"},
    {"id":960,"nombre":"LA SALAZAR, CAICEDO, ANTIOQUIA"},
    {"id":961,"nombre":"AURES, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":962,"nombre":"BARRAGAN, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":963,"nombre":"BURILA, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":964,"nombre":"CAICEDONIA, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":965,"nombre":"EL CRUCERO, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":966,"nombre":"LA CAMELIA, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":967,"nombre":"LA RIVERA, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":968,"nombre":"MONTEGRANDE, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":969,"nombre":"SAMARIA, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":970,"nombre":"SAN GERARDO, CAICEDONIA, VALLE DEL CAUCA"},
    {"id":971,"nombre":"ALFEREZ, CAIMITO, SUCRE"},
    {"id":972,"nombre":"CAIMITO, CAIMITO, SUCRE"},
    {"id":973,"nombre":"CANDELARIA, CAIMITO, SUCRE"},
    {"id":974,"nombre":"CEDENO, CAIMITO, SUCRE"},
    {"id":975,"nombre":"EL MAMON, CAIMITO, SUCRE"},
    {"id":976,"nombre":"LA SOLERA, CAIMITO, SUCRE"},
    {"id":977,"nombre":"LOS CAYITOS, CAIMITO, SUCRE"},
    {"id":978,"nombre":"MOLINERO, CAIMITO, SUCRE"},
    {"id":979,"nombre":"NUEVA ESTACION, CAIMITO, SUCRE"},
    {"id":980,"nombre":"SIETE PALMAS, CAIMITO, SUCRE"},
    {"id":981,"nombre":"TOFEME, CAIMITO, SUCRE"},
    {"id":982,"nombre":"ANAIME, CAJAMARCA, TOLIMA"},
    {"id":983,"nombre":"CAJAMARCA, CAJAMARCA, TOLIMA"},
    {"id":984,"nombre":"CAJIBIO, CAJIBIO, CAUCA"},
    {"id":985,"nombre":"CAMPOALEGRE, CAJIBIO, CAUCA"},
    {"id":986,"nombre":"CASAS BAJAS, CAJIBIO, CAUCA"},
    {"id":987,"nombre":"CHAUX, CAJIBIO, CAUCA"},
    {"id":988,"nombre":"DINDE, CAJIBIO, CAUCA"},
    {"id":989,"nombre":"EL CARMELO, CAJIBIO, CAUCA"},
    {"id":990,"nombre":"EL ROSARIO, CAJIBIO, CAUCA"},
    {"id":991,"nombre":"EL TUNEL, CAJIBIO, CAUCA"},
    {"id":992,"nombre":"LA CAPILLA, CAJIBIO, CAUCA"},
    {"id":993,"nombre":"LA GRANJA, CAJIBIO, CAUCA"},
    {"id":994,"nombre":"LA ISLA, CAJIBIO, CAUCA"},
    {"id":995,"nombre":"LA PEDREGOSA, CAJIBIO, CAUCA"},
    {"id":996,"nombre":"LA VENTA, CAJIBIO, CAUCA"},
    {"id":997,"nombre":"ORTEGA, CAJIBIO, CAUCA"},
    {"id":998,"nombre":"SAN ANTONIO, CAJIBIO, CAUCA"},
    {"id":999,"nombre":"SANTA TERESA, CAJIBIO, CAUCA"},
    {"id":1000,"nombre":"CAJICA, CAJICA, CUNDINAMARCA"},
    {"id":1001,"nombre":"CALAHORRA, CAJICA, CUNDINAMARCA"},
    {"id":1002,"nombre":"CANELON, CAJICA, CUNDINAMARCA"},
    {"id":1003,"nombre":"CHUNTAME - AGUANICA, CAJICA, CUNDINAMARCA"},
    {"id":1004,"nombre":"CHUNTAME, CAJICA, CUNDINAMARCA"},
    {"id":1005,"nombre":"RINCON SANTO, CAJICA, CUNDINAMARCA"},
    {"id":1006,"nombre":"RIO FRIO - LA FLORIDA, CAJICA, CUNDINAMARCA"},
    {"id":1007,"nombre":"RIO FRIO - LA PALMA, CAJICA, CUNDINAMARCA"},
    {"id":1008,"nombre":"RIO GRANDE - EL MISTERIO, CAJICA, CUNDINAMARCA"},
    {"id":1009,"nombre":"RIO GRANDE - LA ESPERANZA, CAJICA, CUNDINAMARCA"},
    {"id":1010,"nombre":"RIO GRANDE - PUENTE VARGAS, CAJICA, CUNDINAMARCA"},
    {"id":1011,"nombre":"RIO GRANDE, CAJICA, CUNDINAMARCA"},
    {"id":1012,"nombre":"ALTO DEL RIO, CALARCA, QUINDIO"},
    {"id":1013,"nombre":"BARCELONA, CALARCA, QUINDIO"},
    {"id":1014,"nombre":"CALARCA, CALARCA, QUINDIO"},
    {"id":1015,"nombre":"LA ALBANIA, CALARCA, QUINDIO"},
    {"id":1016,"nombre":"LA BELLA, CALARCA, QUINDIO"},
    {"id":1017,"nombre":"LA MARIA, CALARCA, QUINDIO"},
    {"id":1018,"nombre":"LA PRADERA, CALARCA, QUINDIO"},
    {"id":1019,"nombre":"LA VIRGINIA, CALARCA, QUINDIO"},
    {"id":1020,"nombre":"PUERTO RICO, CALARCA, QUINDIO"},
    {"id":1021,"nombre":"QUEBRADANEGRA, CALARCA, QUINDIO"},
    {"id":1022,"nombre":"CALDONO, CALDONO, CAUCA"},
    {"id":1023,"nombre":"CERRO ALTO, CALDONO, CAUCA"},
    {"id":1024,"nombre":"EL PITAL, CALDONO, CAUCA"},
    {"id":1025,"nombre":"EL SOCORRO, CALDONO, CAUCA"},
    {"id":1026,"nombre":"LA AGUADA, CALDONO, CAUCA"},
    {"id":1027,"nombre":"PESCADOR, CALDONO, CAUCA"},
    {"id":1028,"nombre":"PIOYA, CALDONO, CAUCA"},
    {"id":1029,"nombre":"PLAN DE ZUNIGA, CALDONO, CAUCA"},
    {"id":1030,"nombre":"PUEBLO NUEVO, CALDONO, CAUCA"},
    {"id":1031,"nombre":"SIBERIA - OVEJAS, CALDONO, CAUCA"},
    {"id":1032,"nombre":"SIBERIA, CALDONO, CAUCA"},
    {"id":1033,"nombre":"SANTIAGO DE CALI, CALI, VALLE DEL CAUCA"},
    {"id":1034,"nombre":"CASCAJAL, CALI, VALLE DEL CAUCA"},
    {"id":1035,"nombre":"EL SALADITO, CALI, VALLE DEL CAUCA"},
    {"id":1036,"nombre":"FELIDIA, CALI, VALLE DEL CAUCA"},
    {"id":1037,"nombre":"GOLONDRINAS, CALI, VALLE DEL CAUCA"},
    {"id":1038,"nombre":"HORMIGUERO, CALI, VALLE DEL CAUCA"},
    {"id":1039,"nombre":"LA BUITRERA, CALI, VALLE DEL CAUCA"},
    {"id":1040,"nombre":"LA CASTILLA, CALI, VALLE DEL CAUCA"},
    {"id":1041,"nombre":"LA ELVIRA, CALI, VALLE DEL CAUCA"},
    {"id":1042,"nombre":"LA LEONERA, CALI, VALLE DEL CAUCA"},
    {"id":1043,"nombre":"LA PAZ, CALI, VALLE DEL CAUCA"},
    {"id":1044,"nombre":"LOS ANDES, CALI, VALLE DEL CAUCA"},
    {"id":1045,"nombre":"MONTEBELLO, CALI, VALLE DEL CAUCA"},
    {"id":1046,"nombre":"NAVARRO, CALI, VALLE DEL CAUCA"},
    {"id":1047,"nombre":"PANCE, CALI, VALLE DEL CAUCA"},
    {"id":1048,"nombre":"PICHINDE, CALI, VALLE DEL CAUCA"},
    {"id":1049,"nombre":"VILLACARMELO, CALI, VALLE DEL CAUCA"},
    {"id":1050,"nombre":"CALIFORNIA, CALIFORNIA, SANTANDER"},
    {"id":1051,"nombre":"EL CERRILLO, CALIFORNIA, SANTANDER"},
    {"id":1052,"nombre":"LA BAJA, CALIFORNIA, SANTANDER"},
    {"id":1053,"nombre":"DARIEN, CALIMA, VALLE DEL CAUCA"},
    {"id":1054,"nombre":"EL BOLEO, CALIMA, VALLE DEL CAUCA"},
    {"id":1055,"nombre":"EL DIAMANTE, CALIMA, VALLE DEL CAUCA"},
    {"id":1056,"nombre":"EL MIRADOR, CALIMA, VALLE DEL CAUCA"},
    {"id":1057,"nombre":"EL VERGEL, CALIMA, VALLE DEL CAUCA"},
    {"id":1058,"nombre":"JIGUALES, CALIMA, VALLE DEL CAUCA"},
    {"id":1059,"nombre":"LA CECILIA, CALIMA, VALLE DEL CAUCA"},
    {"id":1060,"nombre":"LA CRISTALINA, CALIMA, VALLE DEL CAUCA"},
    {"id":1061,"nombre":"LA FLORIDA, CALIMA, VALLE DEL CAUCA"},
    {"id":1062,"nombre":"LA GAVIOTA, CALIMA, VALLE DEL CAUCA"},
    {"id":1063,"nombre":"LA ITALIA, CALIMA, VALLE DEL CAUCA"},
    {"id":1064,"nombre":"LA PLAYA, CALIMA, VALLE DEL CAUCA"},
    {"id":1065,"nombre":"LA PRIMAVERA, CALIMA, VALLE DEL CAUCA"},
    {"id":1066,"nombre":"LA RIVERA, CALIMA, VALLE DEL CAUCA"},
    {"id":1067,"nombre":"PUENTE TIERRA, CALIMA, VALLE DEL CAUCA"},
    {"id":1068,"nombre":"RIOBRAVO, CALIMA, VALLE DEL CAUCA"},
    {"id":1069,"nombre":"SAN JOSE, CALIMA, VALLE DEL CAUCA"},
    {"id":1070,"nombre":"CABANA, CALOTO, CAUCA"},
    {"id":1071,"nombre":"CALOTO, CALOTO, CAUCA"},
    {"id":1072,"nombre":"CAMPOALEGRE PARTE PLANA, CALOTO, CAUCA"},
    {"id":1073,"nombre":"CAPONERA, CALOTO, CAUCA"},
    {"id":1074,"nombre":"CARPINTERO, CALOTO, CAUCA"},
    {"id":1075,"nombre":"CRUCERO DE GUALI, CALOTO, CAUCA"},
    {"id":1076,"nombre":"EL ALBA, CALOTO, CAUCA"},
    {"id":1077,"nombre":"EL CRUCERO, CALOTO, CAUCA"},
    {"id":1078,"nombre":"EL GUABAL, CALOTO, CAUCA"},
    {"id":1079,"nombre":"EL PALO, CALOTO, CAUCA"},
    {"id":1080,"nombre":"EL PLACER, CALOTO, CAUCA"},
    {"id":1081,"nombre":"EL SILENCIO, CALOTO, CAUCA"},
    {"id":1082,"nombre":"GUACHENE, CALOTO, CAUCA"},
    {"id":1083,"nombre":"HUASANO, CALOTO, CAUCA"},
    {"id":1084,"nombre":"HUELLA, CALOTO, CAUCA"},
    {"id":1085,"nombre":"LA ARROBLEDA, CALOTO, CAUCA"},
    {"id":1086,"nombre":"LA SOFIA, CALOTO, CAUCA"},
    {"id":1087,"nombre":"LLANO DE TAULA, CALOTO, CAUCA"},
    {"id":1088,"nombre":"LOMA PELADA, CALOTO, CAUCA"},
    {"id":1089,"nombre":"MINGO, CALOTO, CAUCA"},
    {"id":1090,"nombre":"OBANDO, CALOTO, CAUCA"},
    {"id":1091,"nombre":"PALOMERA, CALOTO, CAUCA"},
    {"id":1092,"nombre":"PEDREGAL, CALOTO, CAUCA"},
    {"id":1093,"nombre":"QUINTERO, CALOTO, CAUCA"},
    {"id":1094,"nombre":"SABANETA, CALOTO, CAUCA"},
    {"id":1095,"nombre":"SAN ANTONIO, CALOTO, CAUCA"},
    {"id":1096,"nombre":"SAN JACINTO, CALOTO, CAUCA"},
    {"id":1097,"nombre":"SAN NICOLAS, CALOTO, CAUCA"},
    {"id":1098,"nombre":"SANTA RITA, CALOTO, CAUCA"},
    {"id":1099,"nombre":"CAMPAMENTO, CAMPAMENTO, ANTIOQUIA"},
    {"id":1100,"nombre":"LA CHIQUITA, CAMPAMENTO, ANTIOQUIA"},
    {"id":1101,"nombre":"LA SOLITA, CAMPAMENTO, ANTIOQUIA"},
    {"id":1102,"nombre":"LLANADAS, CAMPAMENTO, ANTIOQUIA"},
    {"id":1103,"nombre":"LOS CHORROS, CAMPAMENTO, ANTIOQUIA"},
    {"id":1104,"nombre":"MORROPELON, CAMPAMENTO, ANTIOQUIA"},
    {"id":1105,"nombre":"BOHORQUEZ, CAMPO DE LA CRUZ, ATLANTICO"},
    {"id":1106,"nombre":"CAMPO DE LA CRUZ, CAMPO DE LA CRUZ, ATLANTICO"},
    {"id":1107,"nombre":"BAJO PIRAVANTE, CAMPOALEGRE, HUILA"},
    {"id":1108,"nombre":"CAMPOALEGRE, CAMPOALEGRE, HUILA"},
    {"id":1109,"nombre":"LA VEGA DE ORIENTE, CAMPOALEGRE, HUILA"},
    {"id":1110,"nombre":"OTAS, CAMPOALEGRE, HUILA"},
    {"id":1111,"nombre":"RIO NEIVA, CAMPOALEGRE, HUILA"},
    {"id":1112,"nombre":"CAMPOHERMOSO, CAMPOHERMOSO, BOYACA"},
    {"id":1113,"nombre":"LOS CEDROS, CAMPOHERMOSO, BOYACA"},
    {"id":1114,"nombre":"VISTAHERMOSA, CAMPOHERMOSO, BOYACA"},
    {"id":1115,"nombre":"BUENOS AIRES - LAS PAVAS, CANALETE, CORDOBA"},
    {"id":1116,"nombre":"CADILLO, CANALETE, CORDOBA"},
    {"id":1117,"nombre":"CANALETE, CANALETE, CORDOBA"},
    {"id":1118,"nombre":"CORDOBITA CENTRAL, CANALETE, CORDOBA"},
    {"id":1119,"nombre":"CORDOBITA FRONTERA, CANALETE, CORDOBA"},
    {"id":1120,"nombre":"EL GUINEO, CANALETE, CORDOBA"},
    {"id":1121,"nombre":"EL LIMON, CANALETE, CORDOBA"},
    {"id":1122,"nombre":"EL TOMATE, CANALETE, CORDOBA"},
    {"id":1123,"nombre":"LA ALCANCIA, CANALETE, CORDOBA"},
    {"id":1124,"nombre":"LA LORENZA, CANALETE, CORDOBA"},
    {"id":1125,"nombre":"POPAYAN, CANALETE, CORDOBA"},
    {"id":1126,"nombre":"PUEBLO MONO, CANALETE, CORDOBA"},
    {"id":1127,"nombre":"SI SE VAN, CANALETE, CORDOBA"},
    {"id":1128,"nombre":"TIERRADENTRO, CANALETE, CORDOBA"},
    {"id":1129,"nombre":"URANGO, CANALETE, CORDOBA"},
    {"id":1130,"nombre":"BRISAS DE BOLIVAR, CANTAGALLO, BOLIVAR"},
    {"id":1131,"nombre":"CAGUI, CANTAGALLO, BOLIVAR"},
    {"id":1132,"nombre":"CANTAGALLO, CANTAGALLO, BOLIVAR"},
    {"id":1133,"nombre":"EL BAGRE, CANTAGALLO, BOLIVAR"},
    {"id":1134,"nombre":"EL CARANO, CANTAGALLO, BOLIVAR"},
    {"id":1135,"nombre":"EL CEDRO, CANTAGALLO, BOLIVAR"},
    {"id":1136,"nombre":"EL FIRME, CANTAGALLO, BOLIVAR"},
    {"id":1137,"nombre":"LA ESPERANZA, CANTAGALLO, BOLIVAR"},
    {"id":1138,"nombre":"LA NUTRIA, CANTAGALLO, BOLIVAR"},
    {"id":1139,"nombre":"LA POZA, CANTAGALLO, BOLIVAR"},
    {"id":1140,"nombre":"LA VICTORIA (SEPULTURA), CANTAGALLO, BOLIVAR"},
    {"id":1141,"nombre":"LAS PAVAS, CANTAGALLO, BOLIVAR"},
    {"id":1142,"nombre":"LEJANIA, CANTAGALLO, BOLIVAR"},
    {"id":1143,"nombre":"LOS CORONCOROS, CANTAGALLO, BOLIVAR"},
    {"id":1144,"nombre":"LOS PATICOS, CANTAGALLO, BOLIVAR"},
    {"id":1145,"nombre":"MURIBA, CANTAGALLO, BOLIVAR"},
    {"id":1146,"nombre":"NO HAY COMO DIOS, CANTAGALLO, BOLIVAR"},
    {"id":1147,"nombre":"SAN LORENZO, CANTAGALLO, BOLIVAR"},
    {"id":1148,"nombre":"SINZONA, CANTAGALLO, BOLIVAR"},
    {"id":1149,"nombre":"YANACUE, CANTAGALLO, BOLIVAR"},
    {"id":1150,"nombre":"AZAUNCHA, CAPARRAPI, CUNDINAMARCA"},
    {"id":1151,"nombre":"CACERES, CAPARRAPI, CUNDINAMARCA"},
    {"id":1152,"nombre":"CAMBRAS, CAPARRAPI, CUNDINAMARCA"},
    {"id":1153,"nombre":"CAMBULO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1154,"nombre":"CANCHIMAY, CAPARRAPI, CUNDINAMARCA"},
    {"id":1155,"nombre":"CAPARRAPI, CAPARRAPI, CUNDINAMARCA"},
    {"id":1156,"nombre":"CORDOBA, CAPARRAPI, CUNDINAMARCA"},
    {"id":1157,"nombre":"EL DINDAL, CAPARRAPI, CUNDINAMARCA"},
    {"id":1158,"nombre":"MATA DE PABLO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1159,"nombre":"PUERTO CAJUCHE, CAPARRAPI, CUNDINAMARCA"},
    {"id":1160,"nombre":"SAN CARLOS, CAPARRAPI, CUNDINAMARCA"},
    {"id":1161,"nombre":"SAN PABLO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1162,"nombre":"SAN PEDRO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1163,"nombre":"SAN PEDRO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1164,"nombre":"SAN RAMON ALTO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1165,"nombre":"SAN RAMON BAJO, CAPARRAPI, CUNDINAMARCA"},
    {"id":1166,"nombre":"TATI, CAPARRAPI, CUNDINAMARCA"},
    {"id":1167,"nombre":"CAPITANEJO, CAPITANEJO, SANTANDER"},
    {"id":1168,"nombre":"CAQUEZA, CAQUEZA, CUNDINAMARCA"},
    {"id":1169,"nombre":"CARACOLI, CARACOLI, ANTIOQUIA"},
    {"id":1170,"nombre":"CASCARON, CARACOLI, ANTIOQUIA"},
    {"id":1171,"nombre":"EL BAGRE, CARACOLI, ANTIOQUIA"},
    {"id":1172,"nombre":"LA FONTANA, CARACOLI, ANTIOQUIA"},
    {"id":1173,"nombre":"LAS AGUILAS, CARACOLI, ANTIOQUIA"},
    {"id":1174,"nombre":"SANTA ROSA, CARACOLI, ANTIOQUIA"},
    {"id":1175,"nombre":"SARDINAS, CARACOLI, ANTIOQUIA"},
    {"id":1176,"nombre":"ALEGRIAS, CARAMANTA, ANTIOQUIA"},
    {"id":1177,"nombre":"BARRO BLANCO, CARAMANTA, ANTIOQUIA"},
    {"id":1178,"nombre":"CARAMANTA, CARAMANTA, ANTIOQUIA"},
    {"id":1179,"nombre":"CHIRAPOTO, CARAMANTA, ANTIOQUIA"},
    {"id":1180,"nombre":"SUCRE, CARAMANTA, ANTIOQUIA"},
    {"id":1181,"nombre":"AGUATENDIDA, CARCASI, SANTANDER"},
    {"id":1182,"nombre":"BITARIGUA, CARCASI, SANTANDER"},
    {"id":1183,"nombre":"CARCASI, CARCASI, SANTANDER"},
    {"id":1184,"nombre":"EL ASTILLA, CARCASI, SANTANDER"},
    {"id":1185,"nombre":"EL ROSAL, CARCASI, SANTANDER"},
    {"id":1186,"nombre":"EL TOBAL, CARCASI, SANTANDER"},
    {"id":1187,"nombre":"LA LEONA, CARCASI, SANTANDER"},
    {"id":1188,"nombre":"LA RAMADA, CARCASI, SANTANDER"},
    {"id":1189,"nombre":"LOS CINCHOS, CARCASI, SANTANDER"},
    {"id":1190,"nombre":"SAN LUIS, CARCASI, SANTANDER"},
    {"id":1191,"nombre":"SAUCARA, CARCASI, SANTANDER"},
    {"id":1192,"nombre":"BIJAGUAL, CAREPA, ANTIOQUIA"},
    {"id":1193,"nombre":"CAMPAMENTO, CAREPA, ANTIOQUIA"},
    {"id":1194,"nombre":"CAREPA, CAREPA, ANTIOQUIA"},
    {"id":1195,"nombre":"PIEDRAS BLANCAS, CAREPA, ANTIOQUIA"},
    {"id":1196,"nombre":"ZUNGO - PUEBLO NUEVO, CAREPA, ANTIOQUIA"},
    {"id":1197,"nombre":"ZUNGO EMBARCADERO, CAREPA, ANTIOQUIA"},
    {"id":1198,"nombre":"CARMEN DE APICALA, CARMEN DE APICALA, TOLIMA"},
    {"id":1199,"nombre":"ALISAL, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1200,"nombre":"CARMEN DE CARUPA, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1201,"nombre":"CHARQUITA, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1202,"nombre":"HATICO Y ENEAS, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1203,"nombre":"HATO, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1204,"nombre":"NAZARETH, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1205,"nombre":"SALITRE, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1206,"nombre":"SUCRE, CARMEN DE CARUPA, CUNDINAMARCA"},
    {"id":1207,"nombre":"BRISAS, CARMEN DEL DARIEN, CHOCO"},
    {"id":1208,"nombre":"CURBARADO, CARMEN DEL DARIEN, CHOCO"},
    {"id":1209,"nombre":"DOMINGODO, CARMEN DEL DARIEN, CHOCO"},
    {"id":1210,"nombre":"LA GRANDE, CARMEN DEL DARIEN, CHOCO"},
    {"id":1211,"nombre":"PUERTO LLERAS, CARMEN DEL DARIEN, CHOCO"},
    {"id":1212,"nombre":"TURRIQUITADO, CARMEN DEL DARIEN, CHOCO"},
    {"id":1213,"nombre":"VIGIA DE CURBARADO, CARMEN DEL DARIEN, CHOCO"},
    {"id":1214,"nombre":"VILLA NUEVA DE MONTANO, CARMEN DEL DARIEN, CHOCO"},
    {"id":1215,"nombre":"CAROLINA, CAROLINA, ANTIOQUIA"},
    {"id":1216,"nombre":"GUANACAS (GUAPAGUES), CAROLINA, ANTIOQUIA"},
    {"id":1217,"nombre":"ARARCA, CARTAGENA, BOLIVAR"},
    {"id":1218,"nombre":"ARCHIPIELAGO DE SAN BERNARDO, CARTAGENA, BOLIVAR"},
    {"id":1219,"nombre":"ARROYO DE PIEDRA, CARTAGENA, BOLIVAR"},
    {"id":1220,"nombre":"ARROYO GRANDE, CARTAGENA, BOLIVAR"},
    {"id":1221,"nombre":"BARU, CARTAGENA, BOLIVAR"},
    {"id":1222,"nombre":"BAYUNCA, CARTAGENA, BOLIVAR"},
    {"id":1223,"nombre":"BOCACHICA, CARTAGENA, BOLIVAR"},
    {"id":1224,"nombre":"CANO DEL ORO, CARTAGENA, BOLIVAR"},
    {"id":1225,"nombre":"CARTAGENA, CARTAGENA, BOLIVAR"},
    {"id":1226,"nombre":"EL RECREO, CARTAGENA, BOLIVAR"},
    {"id":1227,"nombre":"ISLA FUERTE, CARTAGENA, BOLIVAR"},
    {"id":1228,"nombre":"ISLAS DEL ROSARIO, CARTAGENA, BOLIVAR"},
    {"id":1229,"nombre":"LA BOQUILLA, CARTAGENA, BOLIVAR"},
    {"id":1230,"nombre":"LETICIA, CARTAGENA, BOLIVAR"},
    {"id":1231,"nombre":"PALENQUILLO, CARTAGENA, BOLIVAR"},
    {"id":1232,"nombre":"PASACABALLOS, CARTAGENA, BOLIVAR"},
    {"id":1233,"nombre":"PIEDRECITAS, CARTAGENA, BOLIVAR"},
    {"id":1234,"nombre":"PLAYA DORADA, CARTAGENA, BOLIVAR"},
    {"id":1235,"nombre":"PONTEZUELA, CARTAGENA, BOLIVAR"},
    {"id":1236,"nombre":"PUEBLO NUEVO, CARTAGENA, BOLIVAR"},
    {"id":1237,"nombre":"PUERTO REY, CARTAGENA, BOLIVAR"},
    {"id":1238,"nombre":"PUNTA ARENA, CARTAGENA, BOLIVAR"},
    {"id":1239,"nombre":"PUNTA CANOA, CARTAGENA, BOLIVAR"},
    {"id":1240,"nombre":"SANTA ANA, CARTAGENA, BOLIVAR"},
    {"id":1241,"nombre":"TIERRA BOMBA, CARTAGENA, BOLIVAR"},
    {"id":1242,"nombre":"BOLIVIA, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1243,"nombre":"CARTAGENA DEL CHAIRA, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1244,"nombre":"EL GUAMO, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1245,"nombre":"LOS CRISTALES, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1246,"nombre":"MONSERRATE, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1247,"nombre":"PENAS COLORADAS, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1248,"nombre":"PUERTO CAMELIA, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1249,"nombre":"REMOLINO DEL CAGUAN, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1250,"nombre":"RISARALDA, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1251,"nombre":"SANTA FE DEL CAGUAN, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1252,"nombre":"SANTO DOMINGO, CARTAGENA DEL CHAIRA, CAQUETA"},
    {"id":1253,"nombre":"CAMPOALEGRE, CARTAGO, VALLE DEL CAUCA"},
    {"id":1254,"nombre":"CARTAGO, CARTAGO, VALLE DEL CAUCA"},
    {"id":1255,"nombre":"CAUCA, CARTAGO, VALLE DEL CAUCA"},
    {"id":1256,"nombre":"COLORADAS, CARTAGO, VALLE DEL CAUCA"},
    {"id":1257,"nombre":"LA GRECIA, CARTAGO, VALLE DEL CAUCA"},
    {"id":1258,"nombre":"MODIN, CARTAGO, VALLE DEL CAUCA"},
    {"id":1259,"nombre":"PIEDRA DE MOLER, CARTAGO, VALLE DEL CAUCA"},
    {"id":1260,"nombre":"PUERTO VALLE, CARTAGO, VALLE DEL CAUCA"},
    {"id":1261,"nombre":"SAN JOAQUIN, CARTAGO, VALLE DEL CAUCA"},
    {"id":1262,"nombre":"SANTA ANA, CARTAGO, VALLE DEL CAUCA"},
    {"id":1263,"nombre":"ZARAGOZA, CARTAGO, VALLE DEL CAUCA"},
    {"id":1264,"nombre":"CARURU, CARURU, VAUPES"},
    {"id":1265,"nombre":"YURUPARI, CARURU, VAUPES"},
    {"id":1266,"nombre":"CASABIANCA, CASABIANCA, TOLIMA"},
    {"id":1267,"nombre":"SAN JERONIMO, CASABIANCA, TOLIMA"},
    {"id":1268,"nombre":"CASTILLA LA NUEVA, CASTILLA LA NUEVA, META"},
    {"id":1269,"nombre":"PUEBLO NUEVO, CASTILLA LA NUEVA, META"},
    {"id":1270,"nombre":"SAN LORENZO, CASTILLA LA NUEVA, META"},
    {"id":1271,"nombre":"CACERI, CAUCASIA, ANTIOQUIA"},
    {"id":1272,"nombre":"CARACOLI, CAUCASIA, ANTIOQUIA"},
    {"id":1273,"nombre":"CAUCASIA, CAUCASIA, ANTIOQUIA"},
    {"id":1274,"nombre":"CHONTADURO, CAUCASIA, ANTIOQUIA"},
    {"id":1275,"nombre":"CUTURU, CAUCASIA, ANTIOQUIA"},
    {"id":1276,"nombre":"EL GUTINAJO, CAUCASIA, ANTIOQUIA"},
    {"id":1277,"nombre":"EL PANDO, CAUCASIA, ANTIOQUIA"},
    {"id":1278,"nombre":"EL SABALO, CAUCASIA, ANTIOQUIA"},
    {"id":1279,"nombre":"LA ILUSION, CAUCASIA, ANTIOQUIA"},
    {"id":1280,"nombre":"MARGENTO, CAUCASIA, ANTIOQUIA"},
    {"id":1281,"nombre":"PALANCA, CAUCASIA, ANTIOQUIA"},
    {"id":1282,"nombre":"PALOMAR, CAUCASIA, ANTIOQUIA"},
    {"id":1283,"nombre":"PUEBLO NUEVO, CAUCASIA, ANTIOQUIA"},
    {"id":1284,"nombre":"PUERTO COLOMBIA, CAUCASIA, ANTIOQUIA"},
    {"id":1285,"nombre":"PUERTO GAITAN, CAUCASIA, ANTIOQUIA"},
    {"id":1286,"nombre":"PUERTO TRIANA, CAUCASIA, ANTIOQUIA"},
    {"id":1287,"nombre":"SANTA ROSITA, CAUCASIA, ANTIOQUIA"},
    {"id":1288,"nombre":"CEPITA, CEPITA, SANTANDER"},
    {"id":1289,"nombre":"EL EMBUDO, CEPITA, SANTANDER"},
    {"id":1290,"nombre":"EL LAUREL, CEPITA, SANTANDER"},
    {"id":1291,"nombre":"RODALITO, CEPITA, SANTANDER"},
    {"id":1292,"nombre":"SAN FRANCISCO, CEPITA, SANTANDER"},
    {"id":1293,"nombre":"SAN MIGUEL, CEPITA, SANTANDER"},
    {"id":1294,"nombre":"CANITO, CERETE, CORDOBA"},
    {"id":1295,"nombre":"CARACAS, CERETE, CORDOBA"},
    {"id":1296,"nombre":"CERETE, CERETE, CORDOBA"},
    {"id":1297,"nombre":"CHUCHURUBI, CERETE, CORDOBA"},
    {"id":1298,"nombre":"CUERO CURTIDO, CERETE, CORDOBA"},
    {"id":1299,"nombre":"EL PUEBLO, CERETE, CORDOBA"},
    {"id":1300,"nombre":"EL TOMATE, CERETE, CORDOBA"},
    {"id":1301,"nombre":"EL VIDUAL, CERETE, CORDOBA"},
    {"id":1302,"nombre":"LA ESMERALDA, CERETE, CORDOBA"},
    {"id":1303,"nombre":"LAS MUJERES, CERETE, CORDOBA"},
    {"id":1304,"nombre":"MANGUELITO, CERETE, CORDOBA"},
    {"id":1305,"nombre":"MARTINEZ, CERETE, CORDOBA"},
    {"id":1306,"nombre":"MATEO GOMEZ, CERETE, CORDOBA"},
    {"id":1307,"nombre":"RABO LARGO, CERETE, CORDOBA"},
    {"id":1308,"nombre":"RETIRO DE LOS INDIOS, CERETE, CORDOBA"},
    {"id":1309,"nombre":"SAN ANTONIO, CERETE, CORDOBA"},
    {"id":1310,"nombre":"SANTA CLARA, CERETE, CORDOBA"},
    {"id":1311,"nombre":"SEVERA, CERETE, CORDOBA"},
    {"id":1312,"nombre":"TRES MARIAS, CERETE, CORDOBA"},
    {"id":1313,"nombre":"CERINZA, CERINZA, BOYACA"},
    {"id":1314,"nombre":"CERRITO, CERRITO, SANTANDER"},
    {"id":1315,"nombre":"LA PEDREGOSA, CERRITO, SANTANDER"},
    {"id":1316,"nombre":"SERVITA, CERRITO, SANTANDER"},
    {"id":1317,"nombre":"CANDELARIA, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1318,"nombre":"CERRO SAN ANTONIO, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1319,"nombre":"CONCEPCION, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1320,"nombre":"JESUS DEL MONTE, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1321,"nombre":"PUEBLITO LIBERAL, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1322,"nombre":"PUERTO NINO, CERRO SAN ANTONIO, MAGDALENA"},
    {"id":1323,"nombre":"CERTEGUI, CERTEGUI, CHOCO"},
    {"id":1324,"nombre":"LA TOMA, CERTEGUI, CHOCO"},
    {"id":1325,"nombre":"PARECITO, CERTEGUI, CHOCO"},
    {"id":1326,"nombre":"PAREDES, CERTEGUI, CHOCO"},
    {"id":1327,"nombre":"VARIANTE DE CERTERGUI, CERTEGUI, CHOCO"},
    {"id":1328,"nombre":"CHAGUANI, CHAGUANI, CUNDINAMARCA"},
    {"id":1329,"nombre":"CHALAN, CHALAN, SUCRE"},
    {"id":1330,"nombre":"LA CEIBA, CHALAN, SUCRE"},
    {"id":1331,"nombre":"CHAMEZA, CHAMEZA, CASANARE"},
    {"id":1332,"nombre":"GURUVITA, CHAMEZA, CASANARE"},
    {"id":1333,"nombre":"LAGUNITAS, CHAMEZA, CASANARE"},
    {"id":1334,"nombre":"TEGUITA, CHAMEZA, CASANARE"},
    {"id":1335,"nombre":"AMOYA, CHAPARRAL, TOLIMA"},
    {"id":1336,"nombre":"CALARMA, CHAPARRAL, TOLIMA"},
    {"id":1337,"nombre":"CHAPARRAL, CHAPARRAL, TOLIMA"},
    {"id":1338,"nombre":"EL LIMON, CHAPARRAL, TOLIMA"},
    {"id":1339,"nombre":"LA MARINA, CHAPARRAL, TOLIMA"},
    {"id":1340,"nombre":"LA PROFUNDA, CHAPARRAL, TOLIMA"},
    {"id":1341,"nombre":"LAS CRUCES, CHAPARRAL, TOLIMA"},
    {"id":1342,"nombre":"LAS HERMOSAS, CHAPARRAL, TOLIMA"},
    {"id":1343,"nombre":"POTRERITO DE LUGO, CHAPARRAL, TOLIMA"},
    {"id":1344,"nombre":"SAN JOSE - LAS HERMOSAS, CHAPARRAL, TOLIMA"},
    {"id":1345,"nombre":"CANTERA, CHARALA, SANTANDER"},
    {"id":1346,"nombre":"CHARALA, CHARALA, SANTANDER"},
    {"id":1347,"nombre":"RIACHUELO, CHARALA, SANTANDER"},
    {"id":1348,"nombre":"VIROLIN, CHARALA, SANTANDER"},
    {"id":1349,"nombre":"CHARTA, CHARTA, SANTANDER"},
    {"id":1350,"nombre":"ESCUELA DE PERICO, CHARTA, SANTANDER"},
    {"id":1351,"nombre":"LA AGUADA, CHARTA, SANTANDER"},
    {"id":1352,"nombre":"LA RINCONADA, CHARTA, SANTANDER"},
    {"id":1353,"nombre":"OVEJERA, CHARTA, SANTANDER"},
    {"id":1354,"nombre":"PIRITA, CHARTA, SANTANDER"},
    {"id":1355,"nombre":"BOJACA, CHIA, CUNDINAMARCA"},
    {"id":1356,"nombre":"CERCA DE PIEDRA, CHIA, CUNDINAMARCA"},
    {"id":1357,"nombre":"CHIA, CHIA, CUNDINAMARCA"},
    {"id":1358,"nombre":"CUATRO ESQUINAS - TIQUIZA, CHIA, CUNDINAMARCA"},
    {"id":1359,"nombre":"FAGUA, CHIA, CUNDINAMARCA"},
    {"id":1360,"nombre":"FONQUETA, CHIA, CUNDINAMARCA"},
    {"id":1361,"nombre":"LA BALSA, CHIA, CUNDINAMARCA"},
    {"id":1362,"nombre":"SINDAMANOY I, CHIA, CUNDINAMARCA"},
    {"id":1363,"nombre":"TIQUIZA, CHIA, CUNDINAMARCA"},
    {"id":1364,"nombre":"BARRANQUILLITA, CHIGORODO, ANTIOQUIA"},
    {"id":1365,"nombre":"CHIGORODO, CHIGORODO, ANTIOQUIA"},
    {"id":1366,"nombre":"CANDELARIA, CHIMICHAGUA, CESAR"},
    {"id":1367,"nombre":"CHIMICHAGUA, CHIMICHAGUA, CESAR"},
    {"id":1368,"nombre":"EL CANAL, CHIMICHAGUA, CESAR"},
    {"id":1369,"nombre":"EL GUAMO, CHIMICHAGUA, CESAR"},
    {"id":1370,"nombre":"EL HEBRON, CHIMICHAGUA, CESAR"},
    {"id":1371,"nombre":"HIGO AMARILLO, CHIMICHAGUA, CESAR"},
    {"id":1372,"nombre":"JUAN MARCOS, CHIMICHAGUA, CESAR"},
    {"id":1373,"nombre":"LA MATA, CHIMICHAGUA, CESAR"},
    {"id":1374,"nombre":"LAS FLORES, CHIMICHAGUA, CESAR"},
    {"id":1375,"nombre":"LAS VEGAS, CHIMICHAGUA, CESAR"},
    {"id":1376,"nombre":"MANDIGUILLA, CHIMICHAGUA, CESAR"},
    {"id":1377,"nombre":"PLATA PERDIDA, CHIMICHAGUA, CESAR"},
    {"id":1378,"nombre":"SALOA, CHIMICHAGUA, CESAR"},
    {"id":1379,"nombre":"SANTO DOMINGO, CHIMICHAGUA, CESAR"},
    {"id":1380,"nombre":"SEMPEGUA, CHIMICHAGUA, CESAR"},
    {"id":1381,"nombre":"SOLEDAD, CHIMICHAGUA, CESAR"},
    {"id":1382,"nombre":"TODOS LOS SANTOS, CHIMICHAGUA, CESAR"},
    {"id":1383,"nombre":"TRONCONAL, CHIMICHAGUA, CESAR"},
    {"id":1384,"nombre":"CHINACOTA, CHINACOTA, NORTE DE SANTANDER"},
    {"id":1385,"nombre":"LOBATICA, CHINACOTA, NORTE DE SANTANDER"},
    {"id":1386,"nombre":"NUEVA DONJUANA, CHINACOTA, NORTE DE SANTANDER"},
    {"id":1387,"nombre":"CHINAVITA, CHINAVITA, BOYACA"},
    {"id":1388,"nombre":"ALTO DE LA MINA, CHINCHINA, CALDAS"},
    {"id":1389,"nombre":"BAJO ESPANOL, CHINCHINA, CALDAS"},
    {"id":1390,"nombre":"CHINCHINA, CHINCHINA, CALDAS"},
    {"id":1391,"nombre":"EL REPOSO, CHINCHINA, CALDAS"},
    {"id":1392,"nombre":"EL TREBOL, CHINCHINA, CALDAS"},
    {"id":1393,"nombre":"GUAYABAL, CHINCHINA, CALDAS"},
    {"id":1394,"nombre":"LA CACHUCHA, CHINCHINA, CALDAS"},
    {"id":1395,"nombre":"LA ESTRELLA, CHINCHINA, CALDAS"},
    {"id":1396,"nombre":"LA FLORESTA, CHINCHINA, CALDAS"},
    {"id":1397,"nombre":"LA QUIEBRA DEL NARANJAL, CHINCHINA, CALDAS"},
    {"id":1398,"nombre":"SAN ANDRES, CHINCHINA, CALDAS"},
    {"id":1399,"nombre":"AGUAS VIVAS, CHINU, CORDOBA"},
    {"id":1400,"nombre":"ANDALUCIA, CHINU, CORDOBA"},
    {"id":1401,"nombre":"ARRIMADERO, CHINU, CORDOBA"},
    {"id":1402,"nombre":"BOCAS DEL MONTE, CHINU, CORDOBA"},
    {"id":1403,"nombre":"CACAOTAL, CHINU, CORDOBA"},
    {"id":1404,"nombre":"CARBONERO, CHINU, CORDOBA"},
    {"id":1405,"nombre":"CEJA GRANDE, CHINU, CORDOBA"},
    {"id":1406,"nombre":"CHINU, CHINU, CORDOBA"},
    {"id":1407,"nombre":"EL BRILLANTE, CHINU, CORDOBA"},
    {"id":1408,"nombre":"EL CHORRILLO, CHINU, CORDOBA"},
    {"id":1409,"nombre":"EL DESEO, CHINU, CORDOBA"},
    {"id":1410,"nombre":"EL OROZCO, CHINU, CORDOBA"},
    {"id":1411,"nombre":"EL PARAISO, CHINU, CORDOBA"},
    {"id":1412,"nombre":"EL PITAL, CHINU, CORDOBA"},
    {"id":1413,"nombre":"EL TIGRE, CHINU, CORDOBA"},
    {"id":1414,"nombre":"FLECHAS SABANAS, CHINU, CORDOBA"},
    {"id":1415,"nombre":"FLECHAS SEVILLA, CHINU, CORDOBA"},
    {"id":1416,"nombre":"FLORAL, CHINU, CORDOBA"},
    {"id":1417,"nombre":"GARBAO, CHINU, CORDOBA"},
    {"id":1418,"nombre":"HEREDIA, CHINU, CORDOBA"},
    {"id":1419,"nombre":"LA PANAMA, CHINU, CORDOBA"},
    {"id":1420,"nombre":"LA PILONA, CHINU, CORDOBA"},
    {"id":1421,"nombre":"LAS JARABAS, CHINU, CORDOBA"},
    {"id":1422,"nombre":"LAS LOMAS, CHINU, CORDOBA"},
    {"id":1423,"nombre":"LOMA DE PIEDRA, CHINU, CORDOBA"},
    {"id":1424,"nombre":"LOS ALGARROBOS, CHINU, CORDOBA"},
    {"id":1425,"nombre":"LOS ANGELES, CHINU, CORDOBA"},
    {"id":1426,"nombre":"NOVA, CHINU, CORDOBA"},
    {"id":1427,"nombre":"NUEVO ORIENTE, CHINU, CORDOBA"},
    {"id":1428,"nombre":"PAJONAL, CHINU, CORDOBA"},
    {"id":1429,"nombre":"PALMITAL, CHINU, CORDOBA"},
    {"id":1430,"nombre":"PISA BONITO, CHINU, CORDOBA"},
    {"id":1431,"nombre":"RETIRO DE LOS PEREZ, CHINU, CORDOBA"},
    {"id":1432,"nombre":"SAN MATEO, CHINU, CORDOBA"},
    {"id":1433,"nombre":"SAN QUIRRE, CHINU, CORDOBA"},
    {"id":1434,"nombre":"SAN RAFAEL, CHINU, CORDOBA"},
    {"id":1435,"nombre":"SANTA CECILIA, CHINU, CORDOBA"},
    {"id":1436,"nombre":"SANTA FE, CHINU, CORDOBA"},
    {"id":1437,"nombre":"SANTA ROSA, CHINU, CORDOBA"},
    {"id":1438,"nombre":"TERMOELECTRICA, CHINU, CORDOBA"},
    {"id":1439,"nombre":"TIERRA GRATA, CHINU, CORDOBA"},
    {"id":1440,"nombre":"VILLA FATIMA, CHINU, CORDOBA"},
    {"id":1441,"nombre":"ALTO DE RAMO, CHIPAQUE, CUNDINAMARCA"},
    {"id":1442,"nombre":"CALDERA, CHIPAQUE, CUNDINAMARCA"},
    {"id":1443,"nombre":"CARAZA, CHIPAQUE, CUNDINAMARCA"},
    {"id":1444,"nombre":"CEREZOS, CHIPAQUE, CUNDINAMARCA"},
    {"id":1445,"nombre":"CEREZOS CHIQUITO, CHIPAQUE, CUNDINAMARCA"},
    {"id":1446,"nombre":"CHIPAQUE, CHIPAQUE, CUNDINAMARCA"},
    {"id":1447,"nombre":"CUMBA, CHIPAQUE, CUNDINAMARCA"},
    {"id":1448,"nombre":"FLOREZ, CHIPAQUE, CUNDINAMARCA"},
    {"id":1449,"nombre":"GUAICA, CHIPAQUE, CUNDINAMARCA"},
    {"id":1450,"nombre":"MUNAR, CHIPAQUE, CUNDINAMARCA"},
    {"id":1451,"nombre":"QUERENTE, CHIPAQUE, CUNDINAMARCA"},
    {"id":1452,"nombre":"BATAN, CHIPATA, SANTANDER"},
    {"id":1453,"nombre":"CHIPATA, CHIPATA, SANTANDER"},
    {"id":1454,"nombre":"CHIPATA VIEJO, CHIPATA, SANTANDER"},
    {"id":1455,"nombre":"EL CUCHARO, CHIPATA, SANTANDER"},
    {"id":1456,"nombre":"EL PAPAYO, CHIPATA, SANTANDER"},
    {"id":1457,"nombre":"LAS CRUCES, CHIPATA, SANTANDER"},
    {"id":1458,"nombre":"LLANO DE SAN JUAN, CHIPATA, SANTANDER"},
    {"id":1459,"nombre":"MIRABUENO, CHIPATA, SANTANDER"},
    {"id":1460,"nombre":"SAN MIGUEL, CHIPATA, SANTANDER"},
    {"id":1461,"nombre":"CHIQUINQUIRA, CHIQUINQUIRA, BOYACA"},
    {"id":1462,"nombre":"SASA - MOYAVITA, CHIQUINQUIRA, BOYACA"},
    {"id":1463,"nombre":"VARELA, CHIQUINQUIRA, BOYACA"},
    {"id":1464,"nombre":"CHIQUIZA, CHIQUIZA, BOYACA"},
    {"id":1465,"nombre":"SAN PEDRO DE IGUAQUE, CHIQUIZA, BOYACA"},
    {"id":1466,"nombre":"AGUA FRIA, CHIRIGUANA, CESAR"},
    {"id":1467,"nombre":"ARENAS BLANCAS, CHIRIGUANA, CESAR"},
    {"id":1468,"nombre":"BAUTISTA, CHIRIGUANA, CESAR"},
    {"id":1469,"nombre":"CHIRIGUANA, CHIRIGUANA, CESAR"},
    {"id":1470,"nombre":"EL CARMEN, CHIRIGUANA, CESAR"},
    {"id":1471,"nombre":"EL CRUCE, CHIRIGUANA, CESAR"},
    {"id":1472,"nombre":"ESTACION CHIRIGUANA, CHIRIGUANA, CESAR"},
    {"id":1473,"nombre":"ESTACION EL PASO, CHIRIGUANA, CESAR"},
    {"id":1474,"nombre":"LA AURORA, CHIRIGUANA, CESAR"},
    {"id":1475,"nombre":"LA SIERRA, CHIRIGUANA, CESAR"},
    {"id":1476,"nombre":"POPONTE, CHIRIGUANA, CESAR"},
    {"id":1477,"nombre":"RINCON HONDO, CHIRIGUANA, CESAR"},
    {"id":1478,"nombre":"CHISCAS, CHISCAS, BOYACA"},
    {"id":1479,"nombre":"EL TAMBO, CHISCAS, BOYACA"},
    {"id":1480,"nombre":"GIBRALTAR, CHISCAS, BOYACA"},
    {"id":1481,"nombre":"LA UPA, CHISCAS, BOYACA"},
    {"id":1482,"nombre":"LAS CANAS, CHISCAS, BOYACA"},
    {"id":1483,"nombre":"LLANO DE TABACO, CHISCAS, BOYACA"},
    {"id":1484,"nombre":"MUNDO NUEVO, CHISCAS, BOYACA"},
    {"id":1485,"nombre":"PUEBLO LAS MERCEDES, CHISCAS, BOYACA"},
    {"id":1486,"nombre":"SAMORE, CHISCAS, BOYACA"},
    {"id":1487,"nombre":"TAMARA, CHISCAS, BOYACA"},
    {"id":1488,"nombre":"CANOAS, CHITA, BOYACA"},
    {"id":1489,"nombre":"CHIPA CENTRO, CHITA, BOYACA"},
    {"id":1490,"nombre":"CHIPAVIEJO, CHITA, BOYACA"},
    {"id":1491,"nombre":"CHITA, CHITA, BOYACA"},
    {"id":1492,"nombre":"EL ARENAL, CHITA, BOYACA"},
    {"id":1493,"nombre":"EL MORAL, CHITA, BOYACA"},
    {"id":1494,"nombre":"LA PLAYA, CHITA, BOYACA"},
    {"id":1495,"nombre":"MINAS, CHITA, BOYACA"},
    {"id":1496,"nombre":"MONSERRATE, CHITA, BOYACA"},
    {"id":1497,"nombre":"CHITAGA, CHITAGA, NORTE DE SANTANDER"},
    {"id":1498,"nombre":"CHUCARIMA, CHITAGA, NORTE DE SANTANDER"},
    {"id":1499,"nombre":"CORNEJO, CHITAGA, NORTE DE SANTANDER"},
    {"id":1500,"nombre":"EL ALIZAL, CHITAGA, NORTE DE SANTANDER"},
    {"id":1501,"nombre":"EL PORVENIR, CHITAGA, NORTE DE SANTANDER"},
    {"id":1502,"nombre":"LLANO GRANDE, CHITAGA, NORTE DE SANTANDER"},
    {"id":1503,"nombre":"PRESIDENTE, CHITAGA, NORTE DE SANTANDER"},
    {"id":1504,"nombre":"TANE, CHITAGA, NORTE DE SANTANDER"},
    {"id":1505,"nombre":"CHITARAQUE, CHITARAQUE, BOYACA"},
    {"id":1506,"nombre":"GUAMO Y LADERA, CHITARAQUE, BOYACA"},
    {"id":1507,"nombre":"SANTO DOMINGO, CHITARAQUE, BOYACA"},
    {"id":1508,"nombre":"CHIVATA, CHIVATA, BOYACA"},
    {"id":1509,"nombre":"CHIVOR, CHIVOR, BOYACA"},
    {"id":1510,"nombre":"ALTO DEL PALO, CHOACHI, CUNDINAMARCA"},
    {"id":1511,"nombre":"CHOACHI, CHOACHI, CUNDINAMARCA"},
    {"id":1512,"nombre":"EL HATO, CHOACHI, CUNDINAMARCA"},
    {"id":1513,"nombre":"EL RESGUARDO, CHOACHI, CUNDINAMARCA"},
    {"id":1514,"nombre":"LA LLANADA, CHOACHI, CUNDINAMARCA"},
    {"id":1515,"nombre":"POTRERO GRANDE, CHOACHI, CUNDINAMARCA"},
    {"id":1516,"nombre":"CHOCONTA, CHOCONTA, CUNDINAMARCA"},
    {"id":1517,"nombre":"EL SISGA, CHOCONTA, CUNDINAMARCA"},
    {"id":1518,"nombre":"CAMPO SERENO, CICUCO, BOLIVAR"},
    {"id":1519,"nombre":"CICUCO, CICUCO, BOLIVAR"},
    {"id":1520,"nombre":"LA PENA, CICUCO, BOLIVAR"},
    {"id":1521,"nombre":"SAN FRANCISCO DE LOBA, CICUCO, BOLIVAR"},
    {"id":1522,"nombre":"SAN JAVIER, CICUCO, BOLIVAR"},
    {"id":1523,"nombre":"CANO MOCHO, CIENAGA, MAGDALENA"},
    {"id":1524,"nombre":"CIENAGA, CIENAGA, MAGDALENA"},
    {"id":1525,"nombre":"CORDOBITA, CIENAGA, MAGDALENA"},
    {"id":1526,"nombre":"EL MAMON, CIENAGA, MAGDALENA"},
    {"id":1527,"nombre":"PALMOR, CIENAGA, MAGDALENA"},
    {"id":1528,"nombre":"SAN PEDRO DE LA SIERRA, CIENAGA, MAGDALENA"},
    {"id":1529,"nombre":"SEVILLANO, CIENAGA, MAGDALENA"},
    {"id":1530,"nombre":"SIBERIA, CIENAGA, MAGDALENA"},
    {"id":1531,"nombre":"BARRO PRIETO, CIENAGA DE ORO, CORDOBA"},
    {"id":1532,"nombre":"BERASTEGUI, CIENAGA DE ORO, CORDOBA"},
    {"id":1533,"nombre":"CHARCON, CIENAGA DE ORO, CORDOBA"},
    {"id":1534,"nombre":"CIENAGA DE ORO, CIENAGA DE ORO, CORDOBA"},
    {"id":1535,"nombre":"EGIPTO, CIENAGA DE ORO, CORDOBA"},
    {"id":1536,"nombre":"EL BOBO, CIENAGA DE ORO, CORDOBA"},
    {"id":1537,"nombre":"EL GUAIMARO, CIENAGA DE ORO, CORDOBA"},
    {"id":1538,"nombre":"EL HIGAL, CIENAGA DE ORO, CORDOBA"},
    {"id":1539,"nombre":"EL SALADO, CIENAGA DE ORO, CORDOBA"},
    {"id":1540,"nombre":"EL SIGLO, CIENAGA DE ORO, CORDOBA"},
    {"id":1541,"nombre":"EL TEMPLO, CIENAGA DE ORO, CORDOBA"},
    {"id":1542,"nombre":"LA ARENA, CIENAGA DE ORO, CORDOBA"},
    {"id":1543,"nombre":"LA BARRA, CIENAGA DE ORO, CORDOBA"},
    {"id":1544,"nombre":"LA DRAGA, CIENAGA DE ORO, CORDOBA"},
    {"id":1545,"nombre":"LA GLORIA, CIENAGA DE ORO, CORDOBA"},
    {"id":1546,"nombre":"LA SACA, CIENAGA DE ORO, CORDOBA"},
    {"id":1547,"nombre":"LAGUNETA, CIENAGA DE ORO, CORDOBA"},
    {"id":1548,"nombre":"LAS BALSAS, CIENAGA DE ORO, CORDOBA"},
    {"id":1549,"nombre":"LAS PALMAS, CIENAGA DE ORO, CORDOBA"},
    {"id":1550,"nombre":"LAS PALMITAS, CIENAGA DE ORO, CORDOBA"},
    {"id":1551,"nombre":"LAS PALOMAS, CIENAGA DE ORO, CORDOBA"},
    {"id":1552,"nombre":"LAS PIEDRAS, CIENAGA DE ORO, CORDOBA"},
    {"id":1553,"nombre":"LOS MIMBRES, CIENAGA DE ORO, CORDOBA"},
    {"id":1554,"nombre":"LOS VENADOS, CIENAGA DE ORO, CORDOBA"},
    {"id":1555,"nombre":"MALAGANA, CIENAGA DE ORO, CORDOBA"},
    {"id":1556,"nombre":"MAYORIA, CIENAGA DE ORO, CORDOBA"},
    {"id":1557,"nombre":"NOCHE AZUL, CIENAGA DE ORO, CORDOBA"},
    {"id":1558,"nombre":"PIJIGUAYAL, CIENAGA DE ORO, CORDOBA"},
    {"id":1559,"nombre":"PLAYA BLANCA, CIENAGA DE ORO, CORDOBA"},
    {"id":1560,"nombre":"POZA REDONDA, CIENAGA DE ORO, CORDOBA"},
    {"id":1561,"nombre":"PUERTO DE LA CRUZ, CIENAGA DE ORO, CORDOBA"},
    {"id":1562,"nombre":"PUNTA DE YANEZ, CIENAGA DE ORO, CORDOBA"},
    {"id":1563,"nombre":"ROSA VIEJA, CIENAGA DE ORO, CORDOBA"},
    {"id":1564,"nombre":"SALGUERO, CIENAGA DE ORO, CORDOBA"},
    {"id":1565,"nombre":"SAN ANTONIO DEL TACHIRA, CIENAGA DE ORO, CORDOBA"},
    {"id":1566,"nombre":"SANTIAGO ARRIBA, CIENAGA DE ORO, CORDOBA"},
    {"id":1567,"nombre":"SANTIAGO DEL SUR, CIENAGA DE ORO, CORDOBA"},
    {"id":1568,"nombre":"SANTIAGO POBRE, CIENAGA DE ORO, CORDOBA"},
    {"id":1569,"nombre":"VENADO CENTRAL, CIENAGA DE ORO, CORDOBA"},
    {"id":1570,"nombre":"CIENEGA, CIENEGA, BOYACA"},
    {"id":1571,"nombre":"CAMPO SECO, CIMITARRA, SANTANDER"},
    {"id":1572,"nombre":"CANO BAUL, CIMITARRA, SANTANDER"},
    {"id":1573,"nombre":"CIMITARRA, CIMITARRA, SANTANDER"},
    {"id":1574,"nombre":"DOS HERMANOS, CIMITARRA, SANTANDER"},
    {"id":1575,"nombre":"EXPLANACION NUTRIAS, CIMITARRA, SANTANDER"},
    {"id":1576,"nombre":"GUAYABITO, CIMITARRA, SANTANDER"},
    {"id":1577,"nombre":"LA INDIA, CIMITARRA, SANTANDER"},
    {"id":1578,"nombre":"LA PERDIDA, CIMITARRA, SANTANDER"},
    {"id":1579,"nombre":"LA VERDE, CIMITARRA, SANTANDER"},
    {"id":1580,"nombre":"LOS MORROS (LA YE), CIMITARRA, SANTANDER"},
    {"id":1581,"nombre":"PADILLA, CIMITARRA, SANTANDER"},
    {"id":1582,"nombre":"PUERTO ARAUJO, CIMITARRA, SANTANDER"},
    {"id":1583,"nombre":"PUERTO OLAYA, CIMITARRA, SANTANDER"},
    {"id":1584,"nombre":"SAN FERNANDO, CIMITARRA, SANTANDER"},
    {"id":1585,"nombre":"SANTA ROSA DEL CARARE, CIMITARRA, SANTANDER"},
    {"id":1586,"nombre":"ZAMBITO, CIMITARRA, SANTANDER"},
    {"id":1587,"nombre":"BOSQUES DE TOSCANA, CIRCASIA, QUINDIO"},
    {"id":1588,"nombre":"CIRCASIA, CIRCASIA, QUINDIO"},
    {"id":1589,"nombre":"EL CANEY - LLANADAS, CIRCASIA, QUINDIO"},
    {"id":1590,"nombre":"EL TRIUNFO, CIRCASIA, QUINDIO"},
    {"id":1591,"nombre":"HOJAS ANCHAS, CIRCASIA, QUINDIO"},
    {"id":1592,"nombre":"LA ALDEA, CIRCASIA, QUINDIO"},
    {"id":1593,"nombre":"LA CRISTALINA - GUAYABAL, CIRCASIA, QUINDIO"},
    {"id":1594,"nombre":"LA FRONTERA, CIRCASIA, QUINDIO"},
    {"id":1595,"nombre":"LA PIZARRA, CIRCASIA, QUINDIO"},
    {"id":1596,"nombre":"LA POLA, CIRCASIA, QUINDIO"},
    {"id":1597,"nombre":"LA SIRIA, CIRCASIA, QUINDIO"},
    {"id":1598,"nombre":"LOS ABEDULES, CIRCASIA, QUINDIO"},
    {"id":1599,"nombre":"LOS ROBLES, CIRCASIA, QUINDIO"},
    {"id":1600,"nombre":"LOS ROSALES, CIRCASIA, QUINDIO"},
    {"id":1601,"nombre":"MEMBRILLAL, CIRCASIA, QUINDIO"},
    {"id":1602,"nombre":"QUINTAS DEL BOSQUE, CIRCASIA, QUINDIO"},
    {"id":1603,"nombre":"VILLA JIMENA, CIRCASIA, QUINDIO"},
    {"id":1604,"nombre":"VILLA LIGIA, CIRCASIA, QUINDIO"},
    {"id":1605,"nombre":"VILLA MARINA, CIRCASIA, QUINDIO"},
    {"id":1606,"nombre":"VILLARAZO - SAN LUIS, CIRCASIA, QUINDIO"},
    {"id":1607,"nombre":"ALGARROBO, CISNEROS, ANTIOQUIA"},
    {"id":1608,"nombre":"CISNEROS, CISNEROS, ANTIOQUIA"},
    {"id":1609,"nombre":"EL LIMON, CISNEROS, ANTIOQUIA"},
    {"id":1610,"nombre":"FATIMA, CISNEROS, ANTIOQUIA"},
    {"id":1611,"nombre":"ALFONSO LOPEZ, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1612,"nombre":"CIUDAD BOLIVAR, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1613,"nombre":"LA LINDA, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1614,"nombre":"SAN BERNARDO DE LOS FARALLONES, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1615,"nombre":"SAN GREGORIO, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1616,"nombre":"VILLA ALEGRIA, CIUDAD BOLIVAR, ANTIOQUIA"},
    {"id":1617,"nombre":"AQUI ME PARO, CLEMENCIA, BOLIVAR"},
    {"id":1618,"nombre":"CLEMENCIA, CLEMENCIA, BOLIVAR"},
    {"id":1619,"nombre":"EL CERRO, CLEMENCIA, BOLIVAR"},
    {"id":1620,"nombre":"EL COCO Y SAN ISIDRO, CLEMENCIA, BOLIVAR"},
    {"id":1621,"nombre":"EL PENIQUE, CLEMENCIA, BOLIVAR"},
    {"id":1622,"nombre":"EL SOCORRO, CLEMENCIA, BOLIVAR"},
    {"id":1623,"nombre":"FRANCO, CLEMENCIA, BOLIVAR"},
    {"id":1624,"nombre":"HONDURAS, CLEMENCIA, BOLIVAR"},
    {"id":1625,"nombre":"LAS CARAS, CLEMENCIA, BOLIVAR"},
    {"id":1626,"nombre":"LOS CAMARONES, CLEMENCIA, BOLIVAR"},
    {"id":1627,"nombre":"PESCADERO, CLEMENCIA, BOLIVAR"},
    {"id":1628,"nombre":"AGUA LINDA, COCORNA, ANTIOQUIA"},
    {"id":1629,"nombre":"COCORNA, COCORNA, ANTIOQUIA"},
    {"id":1630,"nombre":"FARALLONES, COCORNA, ANTIOQUIA"},
    {"id":1631,"nombre":"LA FLORIDA, COCORNA, ANTIOQUIA"},
    {"id":1632,"nombre":"LA GRANJA, COCORNA, ANTIOQUIA"},
    {"id":1633,"nombre":"LA HONDA, COCORNA, ANTIOQUIA"},
    {"id":1634,"nombre":"LA PINUELA, COCORNA, ANTIOQUIA"},
    {"id":1635,"nombre":"LA PLACITA, COCORNA, ANTIOQUIA"},
    {"id":1636,"nombre":"LA PRIMAVERA, COCORNA, ANTIOQUIA"},
    {"id":1637,"nombre":"LAS CRUCES, COCORNA, ANTIOQUIA"},
    {"id":1638,"nombre":"PAILANIA, COCORNA, ANTIOQUIA"},
    {"id":1639,"nombre":"BARRIALOSA, COELLO, TOLIMA"},
    {"id":1640,"nombre":"CAIMITO, COELLO, TOLIMA"},
    {"id":1641,"nombre":"CHAGUALA ADENTRO, COELLO, TOLIMA"},
    {"id":1642,"nombre":"CHAGUALA AFUERA, COELLO, TOLIMA"},
    {"id":1643,"nombre":"COELLO, COELLO, TOLIMA"},
    {"id":1644,"nombre":"CUNIRA, COELLO, TOLIMA"},
    {"id":1645,"nombre":"DOSQUEBRADAS, COELLO, TOLIMA"},
    {"id":1646,"nombre":"GUALANDAY, COELLO, TOLIMA"},
    {"id":1647,"nombre":"LA ARENOSA, COELLO, TOLIMA"},
    {"id":1648,"nombre":"LA SALINA, COELLO, TOLIMA"},
    {"id":1649,"nombre":"LLANO DE LA VIRGEN, COELLO, TOLIMA"},
    {"id":1650,"nombre":"LUCHA ADENTRO, COELLO, TOLIMA"},
    {"id":1651,"nombre":"LUCHA AFUERA, COELLO, TOLIMA"},
    {"id":1652,"nombre":"POTRERILLO, COELLO, TOLIMA"},
    {"id":1653,"nombre":"SANTA BARBARA, COELLO, TOLIMA"},
    {"id":1654,"nombre":"VEGA DE LOS PADRES, COELLO, TOLIMA"},
    {"id":1655,"nombre":"VINDI, COELLO, TOLIMA"},
    {"id":1656,"nombre":"COGUA, COGUA, CUNDINAMARCA"},
    {"id":1657,"nombre":"EL ALTICO, COGUA, CUNDINAMARCA"},
    {"id":1658,"nombre":"EL CASCAJAL, COGUA, CUNDINAMARCA"},
    {"id":1659,"nombre":"EL DURAZNO, COGUA, CUNDINAMARCA"},
    {"id":1660,"nombre":"EL MORTINO (LA ESCUELA), COGUA, CUNDINAMARCA"},
    {"id":1661,"nombre":"EL OLIVO (LA AUTOPISTA), COGUA, CUNDINAMARCA"},
    {"id":1662,"nombre":"LA CHAPA, COGUA, CUNDINAMARCA"},
    {"id":1663,"nombre":"LA PLAZUELA, COGUA, CUNDINAMARCA"},
    {"id":1664,"nombre":"RINCON SANTO, COGUA, CUNDINAMARCA"},
    {"id":1665,"nombre":"RODAMONTAL, COGUA, CUNDINAMARCA"},
    {"id":1666,"nombre":"SAN ANTONIO, COGUA, CUNDINAMARCA"},
    {"id":1667,"nombre":"COLOMBIA, COLOMBIA, HUILA"},
    {"id":1668,"nombre":"LAS LAJAS, COLOMBIA, HUILA"},
    {"id":1669,"nombre":"LOS RIOS, COLOMBIA, HUILA"},
    {"id":1670,"nombre":"MONGUI, COLOMBIA, HUILA"},
    {"id":1671,"nombre":"POTRERO GRANDE, COLOMBIA, HUILA"},
    {"id":1672,"nombre":"SAN MARCOS, COLOMBIA, HUILA"},
    {"id":1673,"nombre":"SANTANA, COLOMBIA, HUILA"},
    {"id":1674,"nombre":"BAJO DON JUAN, COLOSO, SUCRE"},
    {"id":1675,"nombre":"CHINULITO, COLOSO, SUCRE"},
    {"id":1676,"nombre":"RICAURTE, COLOSO, SUCRE"},
    {"id":1677,"nombre":"EL CERRO, COLOSO, SUCRE"},
    {"id":1678,"nombre":"EL OJITO, COLOSO, SUCRE"},
    {"id":1679,"nombre":"JONEY, COLOSO, SUCRE"},
    {"id":1680,"nombre":"COMBITA, COMBITA, BOYACA"},
    {"id":1681,"nombre":"EL BARNE, COMBITA, BOYACA"},
    {"id":1682,"nombre":"SANTA BARBARA, COMBITA, BOYACA"},
    {"id":1683,"nombre":"ACOSO, CONDOTO, CHOCO"},
    {"id":1684,"nombre":"CONDOTO, CONDOTO, CHOCO"},
    {"id":1685,"nombre":"CONSUELO ANDRAPA, CONDOTO, CHOCO"},
    {"id":1686,"nombre":"CORODO, CONDOTO, CHOCO"},
    {"id":1687,"nombre":"EL PASO, CONDOTO, CHOCO"},
    {"id":1688,"nombre":"ILARIA, CONDOTO, CHOCO"},
    {"id":1689,"nombre":"LA ENCHARCAZON, CONDOTO, CHOCO"},
    {"id":1690,"nombre":"LA FLORIDA, CONDOTO, CHOCO"},
    {"id":1691,"nombre":"LA MURINA, CONDOTO, CHOCO"},
    {"id":1692,"nombre":"LA PLANTA, CONDOTO, CHOCO"},
    {"id":1693,"nombre":"MANDINGA, CONDOTO, CHOCO"},
    {"id":1694,"nombre":"OPOGODO, CONDOTO, CHOCO"},
    {"id":1695,"nombre":"SANTA ANA, CONDOTO, CHOCO"},
    {"id":1696,"nombre":"TAJUATO, CONDOTO, CHOCO"},
    {"id":1697,"nombre":"VIROVIRO, CONDOTO, CHOCO"},
    {"id":1698,"nombre":"CONFINES, CONFINES, SANTANDER"},
    {"id":1699,"nombre":"SAN JOAQUIN, CONFINES, SANTANDER"},
    {"id":1700,"nombre":"ALFONSO LOPEZ, CONSACA, NARIÑO"},
    {"id":1701,"nombre":"BOMBONA, CONSACA, NARIÑO"},
    {"id":1702,"nombre":"CAJABAMBA, CONSACA, NARIÑO"},
    {"id":1703,"nombre":"CONSACA, CONSACA, NARIÑO"},
    {"id":1704,"nombre":"EL CAMPAMENTO, CONSACA, NARIÑO"},
    {"id":1705,"nombre":"EL GUABO, CONSACA, NARIÑO"},
    {"id":1706,"nombre":"EL HATILLO, CONSACA, NARIÑO"},
    {"id":1707,"nombre":"LA AGUADA, CONSACA, NARIÑO"},
    {"id":1708,"nombre":"OLAYA HERRERA, CONSACA, NARIÑO"},
    {"id":1709,"nombre":"PALTAPAMBA, CONSACA, NARIÑO"},
    {"id":1710,"nombre":"SAN JOSE DE SAL, CONSACA, NARIÑO"},
    {"id":1711,"nombre":"SAN MIGUEL CARIACO, CONSACA, NARIÑO"},
    {"id":1712,"nombre":"VERACRUZ, CONSACA, NARIÑO"},
    {"id":1713,"nombre":"ALDEA DE MARIA, CONTADERO, NARIÑO"},
    {"id":1714,"nombre":"CONTADERO, CONTADERO, NARIÑO"},
    {"id":1715,"nombre":"LA JOSEFINA, CONTADERO, NARIÑO"},
    {"id":1716,"nombre":"OSPINA PEREZ, CONTADERO, NARIÑO"},
    {"id":1717,"nombre":"SANTO DOMINGO, CONTADERO, NARIÑO"},
    {"id":1718,"nombre":"CONTRATACION, CONTRATACION, SANTANDER"},
    {"id":1719,"nombre":"SAN PABLO, CONTRATACION, SANTANDER"},
    {"id":1720,"nombre":"BALCONES, CONVENCION, NORTE DE SANTANDER"},
    {"id":1721,"nombre":"CARTAGENITA, CONVENCION, NORTE DE SANTANDER"},
    {"id":1722,"nombre":"CONVENCION, CONVENCION, NORTE DE SANTANDER"},
    {"id":1723,"nombre":"EL HOYO, CONVENCION, NORTE DE SANTANDER"},
    {"id":1724,"nombre":"GUAMAL, CONVENCION, NORTE DE SANTANDER"},
    {"id":1725,"nombre":"HONDURAS, CONVENCION, NORTE DE SANTANDER"},
    {"id":1726,"nombre":"LA ESPERANZA, CONVENCION, NORTE DE SANTANDER"},
    {"id":1727,"nombre":"LA LIBERTAD, CONVENCION, NORTE DE SANTANDER"},
    {"id":1728,"nombre":"LA TRINIDAD, CONVENCION, NORTE DE SANTANDER"},
    {"id":1729,"nombre":"LA VEGA, CONVENCION, NORTE DE SANTANDER"},
    {"id":1730,"nombre":"LAS MERCEDES, CONVENCION, NORTE DE SANTANDER"},
    {"id":1731,"nombre":"MESA RICA, CONVENCION, NORTE DE SANTANDER"},
    {"id":1732,"nombre":"MIRAFLORES, CONVENCION, NORTE DE SANTANDER"},
    {"id":1733,"nombre":"PIEDECUESTA, CONVENCION, NORTE DE SANTANDER"},
    {"id":1734,"nombre":"SAN JOSE DE LAS PITAS, CONVENCION, NORTE DE SANTANDER"},
    {"id":1735,"nombre":"SOLEDAD, CONVENCION, NORTE DE SANTANDER"},
    {"id":1736,"nombre":"CANOAS, COPACABANA, ANTIOQUIA"},
    {"id":1737,"nombre":"COPACABANA, COPACABANA, ANTIOQUIA"},
    {"id":1738,"nombre":"EL AMOR, COPACABANA, ANTIOQUIA"},
    {"id":1739,"nombre":"EL NAVAL (TOLDA), COPACABANA, ANTIOQUIA"},
    {"id":1740,"nombre":"EL TABLAZO, COPACABANA, ANTIOQUIA"},
    {"id":1741,"nombre":"LA LOMITA, COPACABANA, ANTIOQUIA"},
    {"id":1742,"nombre":"CANTINO, COPER, BOYACA"},
    {"id":1743,"nombre":"COPER, COPER, BOYACA"},
    {"id":1744,"nombre":"GUASIMAL, COPER, BOYACA"},
    {"id":1745,"nombre":"PEDRO GOMEZ, COPER, BOYACA"},
    {"id":1746,"nombre":"TURTUR, COPER, BOYACA"},
    {"id":1747,"nombre":"CORINTO, CORINTO, CAUCA"},
    {"id":1748,"nombre":"EL BARRANCO, CORINTO, CAUCA"},
    {"id":1749,"nombre":"EL JAGUAL, CORINTO, CAUCA"},
    {"id":1750,"nombre":"LOS ANDES, CORINTO, CAUCA"},
    {"id":1751,"nombre":"MEDIA NARANJA, CORINTO, CAUCA"},
    {"id":1752,"nombre":"QUEBRADITAS, CORINTO, CAUCA"},
    {"id":1753,"nombre":"RIONEGRO, CORINTO, CAUCA"},
    {"id":1754,"nombre":"CINCELADA, COROMORO, SANTANDER"},
    {"id":1755,"nombre":"COROMORO, COROMORO, SANTANDER"},
    {"id":1756,"nombre":"PUEBLO VIEJO, COROMORO, SANTANDER"},
    {"id":1757,"nombre":"CANTAGALLO, COROZAL, SUCRE"},
    {"id":1758,"nombre":"CHAPINERO, COROZAL, SUCRE"},
    {"id":1759,"nombre":"COROZAL, COROZAL, SUCRE"},
    {"id":1760,"nombre":"DON ALONSO, COROZAL, SUCRE"},
    {"id":1761,"nombre":"EL MAMON, COROZAL, SUCRE"},
    {"id":1762,"nombre":"HATO NUEVO, COROZAL, SUCRE"},
    {"id":1763,"nombre":"LAS LLANADAS, COROZAL, SUCRE"},
    {"id":1764,"nombre":"LAS PENAS, COROZAL, SUCRE"},
    {"id":1765,"nombre":"LAS TINAS, COROZAL, SUCRE"},
    {"id":1766,"nombre":"LOMA ALTA, COROZAL, SUCRE"},
    {"id":1767,"nombre":"PILETA, COROZAL, SUCRE"},
    {"id":1768,"nombre":"RINCON DE FLORES, COROZAL, SUCRE"},
    {"id":1769,"nombre":"SAN JOSE DE PILETA, COROZAL, SUCRE"},
    {"id":1770,"nombre":"CORRALES, CORRALES, BOYACA"},
    {"id":1771,"nombre":"COTA, COTA, CUNDINAMARCA"},
    {"id":1772,"nombre":"ABROJAL, COTORRA, CORDOBA"},
    {"id":1773,"nombre":"COTORRA, COTORRA, CORDOBA"},
    {"id":1774,"nombre":"EL BONGO, COTORRA, CORDOBA"},
    {"id":1775,"nombre":"EL CHIQUI, COTORRA, CORDOBA"},
    {"id":1776,"nombre":"EL PASO DE LAS FLORES, COTORRA, CORDOBA"},
    {"id":1777,"nombre":"LA CULEBRA, COTORRA, CORDOBA"},
    {"id":1778,"nombre":"LAS AREPAS, COTORRA, CORDOBA"},
    {"id":1779,"nombre":"LOS CEDROS, COTORRA, CORDOBA"},
    {"id":1780,"nombre":"LOS GOMEZ, COTORRA, CORDOBA"},
    {"id":1781,"nombre":"LOS SAHINOS, COTORRA, CORDOBA"},
    {"id":1782,"nombre":"MORALITO, COTORRA, CORDOBA"},
    {"id":1783,"nombre":"PUERTO GRANDE, COTORRA, CORDOBA"},
    {"id":1784,"nombre":"TREMENTINO, COTORRA, CORDOBA"},
    {"id":1785,"nombre":"COVARACHIA, COVARACHIA, BOYACA"},
    {"id":1786,"nombre":"EL NARANJO, COVARACHIA, BOYACA"},
    {"id":1787,"nombre":"LA PALMERA, COVARACHIA, BOYACA"},
    {"id":1788,"nombre":"LAS TAPIAS, COVARACHIA, BOYACA"},
    {"id":1789,"nombre":"LIMON DULCE, COVARACHIA, BOYACA"},
    {"id":1790,"nombre":"LOS SIATES, COVARACHIA, BOYACA"},
    {"id":1791,"nombre":"NOGONTOVA - LA CAPILLA DE SAN LUIS, COVARACHIA, BOYACA"},
    {"id":1792,"nombre":"PUERTO RICO, COVARACHIA, BOYACA"},
    {"id":1793,"nombre":"SATOBA ARRIBA, COVARACHIA, BOYACA"},
    {"id":1794,"nombre":"CASTILLA, COYAIMA, TOLIMA"},
    {"id":1795,"nombre":"COYAIMA, COYAIMA, TOLIMA"},
    {"id":1796,"nombre":"GUAYAQUIL, COYAIMA, TOLIMA"},
    {"id":1797,"nombre":"MESAS DE SAN JUAN, COYAIMA, TOLIMA"},
    {"id":1798,"nombre":"SANTA MARTA, COYAIMA, TOLIMA"},
    {"id":1799,"nombre":"CRAVO NORTE, CRAVO NORTE, ARAUCA"},
    {"id":1800,"nombre":"JURIEPE, CRAVO NORTE, ARAUCA"},
    {"id":1801,"nombre":"LA VIRGEN, CRAVO NORTE, ARAUCA"},
    {"id":1802,"nombre":"LOS CABALLOS, CRAVO NORTE, ARAUCA"},
    {"id":1803,"nombre":"SAN RAFAEL, CRAVO NORTE, ARAUCA"},
    {"id":1804,"nombre":"CARLOSAMA, CUASPUD, NARIÑO"},
    {"id":1805,"nombre":"EL CARCHI, CUASPUD, NARIÑO"},
    {"id":1806,"nombre":"MACAS, CUASPUD, NARIÑO"},
    {"id":1807,"nombre":"AGUA BLANCA, CUBARA, BOYACA"},
    {"id":1808,"nombre":"BOJABA, CUBARA, BOYACA"},
    {"id":1809,"nombre":"CHUSCAL, CUBARA, BOYACA"},
    {"id":1810,"nombre":"COVARIA, CUBARA, BOYACA"},
    {"id":1811,"nombre":"CUBARA, CUBARA, BOYACA"},
    {"id":1812,"nombre":"EL GUAMO, CUBARA, BOYACA"},
    {"id":1813,"nombre":"EL INDIO, CUBARA, BOYACA"},
    {"id":1814,"nombre":"GIBRALTAR, CUBARA, BOYACA"},
    {"id":1815,"nombre":"MOJICONES, CUBARA, BOYACA"},
    {"id":1816,"nombre":"SAMORE, CUBARA, BOYACA"},
    {"id":1817,"nombre":"TEGRIA, CUBARA, BOYACA"},
    {"id":1818,"nombre":"CUBARRAL, CUBARRAL, META"},
    {"id":1819,"nombre":"PUERTO ARIARI, CUBARRAL, META"},
    {"id":1820,"nombre":"PUERTO GOMEZ, CUBARRAL, META"},
    {"id":1821,"nombre":"CUCAITA, CUCAITA, BOYACA"},
    {"id":1822,"nombre":"CUCUNUBA, CUCUNUBA, CUNDINAMARCA"},
    {"id":1823,"nombre":"AGUA CLARA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1824,"nombre":"AGUA LA SAL, CUCUTA, NORTE DE SANTANDER"},
    {"id":1825,"nombre":"ALTO VIENTO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1826,"nombre":"ARRAYANES, CUCUTA, NORTE DE SANTANDER"},
    {"id":1827,"nombre":"BANCO DE ARENA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1828,"nombre":"BOCONO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1829,"nombre":"CAMPO DOS, CUCUTA, NORTE DE SANTANDER"},
    {"id":1830,"nombre":"CASA DE ZINC, CUCUTA, NORTE DE SANTANDER"},
    {"id":1831,"nombre":"CUCUTA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1832,"nombre":"EL CARMEN DE TONCHALA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1833,"nombre":"EL ESCOBAL, CUCUTA, NORTE DE SANTANDER"},
    {"id":1834,"nombre":"EL PORTICO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1835,"nombre":"EL PORVENIR, CUCUTA, NORTE DE SANTANDER"},
    {"id":1836,"nombre":"EL PRADO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1837,"nombre":"EL RODEO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1838,"nombre":"EL SALADO - PASTILLALES, CUCUTA, NORTE DE SANTANDER"},
    {"id":1839,"nombre":"GUAROMITO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1840,"nombre":"LA BUENA ESPERANZA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1841,"nombre":"LA ERMITA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1842,"nombre":"LA FLORESTA - CAMILO TORRES, CUCUTA, NORTE DE SANTANDER"},
    {"id":1843,"nombre":"LA JARRA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1844,"nombre":"LA PUNTA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1845,"nombre":"LAS VACAS, CUCUTA, NORTE DE SANTANDER"},
    {"id":1846,"nombre":"LIMONCITO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1847,"nombre":"LOS NEGROS, CUCUTA, NORTE DE SANTANDER"},
    {"id":1848,"nombre":"ORIPAYA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1849,"nombre":"PALMARITO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1850,"nombre":"PUERTO LEON, CUCUTA, NORTE DE SANTANDER"},
    {"id":1851,"nombre":"PUERTO LLERAS, CUCUTA, NORTE DE SANTANDER"},
    {"id":1852,"nombre":"PUERTO NUEVO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1853,"nombre":"PUERTO VILLAMIZAR, CUCUTA, NORTE DE SANTANDER"},
    {"id":1854,"nombre":"PUNTA BRAVA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1855,"nombre":"RICAURTE, CUCUTA, NORTE DE SANTANDER"},
    {"id":1856,"nombre":"SAN FAUSTINO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1857,"nombre":"SAN PEDRO, CUCUTA, NORTE DE SANTANDER"},
    {"id":1858,"nombre":"SANTA CECILIA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1859,"nombre":"VIGILANCIA, CUCUTA, NORTE DE SANTANDER"},
    {"id":1860,"nombre":"CUCUTILLA, CUCUTILLA, NORTE DE SANTANDER"},
    {"id":1861,"nombre":"PUENTE JULIO ARBOLEDA, CUCUTILLA, NORTE DE SANTANDER"},
    {"id":1862,"nombre":"ROMAN, CUCUTILLA, NORTE DE SANTANDER"},
    {"id":1863,"nombre":"SAN JOSE DE LA MONTANA, CUCUTILLA, NORTE DE SANTANDER"},
    {"id":1864,"nombre":"CUITIVA, CUITIVA, BOYACA"},
    {"id":1865,"nombre":"LLANO DE ALARCON, CUITIVA, BOYACA"},
    {"id":1866,"nombre":"CANEY MEDIO, CUMARAL, META"},
    {"id":1867,"nombre":"CUMARAL, CUMARAL, META"},
    {"id":1868,"nombre":"EL CAIBE, CUMARAL, META"},
    {"id":1869,"nombre":"GUACAVIA, CUMARAL, META"},
    {"id":1870,"nombre":"MONTEBELLO, CUMARAL, META"},
    {"id":1871,"nombre":"PRESENTADO, CUMARAL, META"},
    {"id":1872,"nombre":"PUERTO PECUCA, CUMARAL, META"},
    {"id":1873,"nombre":"SAN IGNACIO CARUTAL, CUMARAL, META"},
    {"id":1874,"nombre":"SAN NICOLAS, CUMARAL, META"},
    {"id":1875,"nombre":"VERACRUZ, CUMARAL, META"},
    {"id":1876,"nombre":"AMANAVEN, CUMARIBO, VICHADA"},
    {"id":1877,"nombre":"CHUPABE, CUMARIBO, VICHADA"},
    {"id":1878,"nombre":"CUMARIBO, CUMARIBO, VICHADA"},
    {"id":1879,"nombre":"EL CEJAL, CUMARIBO, VICHADA"},
    {"id":1880,"nombre":"EL VIENTO, CUMARIBO, VICHADA"},
    {"id":1881,"nombre":"GUACO, CUMARIBO, VICHADA"},
    {"id":1882,"nombre":"GUANAPE, CUMARIBO, VICHADA"},
    {"id":1883,"nombre":"GUERIMA, CUMARIBO, VICHADA"},
    {"id":1884,"nombre":"LA VICTORIA (PUERTO PRINCIPE), CUMARIBO, VICHADA"},
    {"id":1885,"nombre":"MANAJUARE, CUMARIBO, VICHADA"},
    {"id":1886,"nombre":"MATAVEN, CUMARIBO, VICHADA"},
    {"id":1887,"nombre":"PALMARITO, CUMARIBO, VICHADA"},
    {"id":1888,"nombre":"PUERTO NARIÑO, CUMARIBO, VICHADA"},
    {"id":1889,"nombre":"SAN JOSE DE OCUNE, CUMARIBO, VICHADA"},
    {"id":1890,"nombre":"SANTA RITA, CUMARIBO, VICHADA"},
    {"id":1891,"nombre":"SHIARE, CUMARIBO, VICHADA"},
    {"id":1892,"nombre":"TRES MATAS, CUMARIBO, VICHADA"},
    {"id":1893,"nombre":"CHILES, CUMBAL, NARIÑO"},
    {"id":1894,"nombre":"CUMBAL, CUMBAL, NARIÑO"},
    {"id":1895,"nombre":"MAYASQUER, CUMBAL, NARIÑO"},
    {"id":1896,"nombre":"MIRAFLORES, CUMBAL, NARIÑO"},
    {"id":1897,"nombre":"NAZATE, CUMBAL, NARIÑO"},
    {"id":1898,"nombre":"PANAN, CUMBAL, NARIÑO"},
    {"id":1899,"nombre":"PUEBLO VIEJO, CUMBAL, NARIÑO"},
    {"id":1900,"nombre":"SAN MARTIN, CUMBAL, NARIÑO"},
    {"id":1901,"nombre":"TALAMBI, CUMBAL, NARIÑO"},
    {"id":1902,"nombre":"CUMBITARA, CUMBITARA, NARIÑO"},
    {"id":1903,"nombre":"DAMASCO, CUMBITARA, NARIÑO"},
    {"id":1904,"nombre":"EL DESIERTO, CUMBITARA, NARIÑO"},
    {"id":1905,"nombre":"GUAYABALITO NULPI, CUMBITARA, NARIÑO"},
    {"id":1906,"nombre":"LA ESPERANZA, CUMBITARA, NARIÑO"},
    {"id":1907,"nombre":"MIGUEL NULPI, CUMBITARA, NARIÑO"},
    {"id":1908,"nombre":"PESQUERIA, CUMBITARA, NARIÑO"},
    {"id":1909,"nombre":"PISANDA, CUMBITARA, NARIÑO"},
    {"id":1910,"nombre":"SANTA ANA, CUMBITARA, NARIÑO"},
    {"id":1911,"nombre":"SANTA ROSA, CUMBITARA, NARIÑO"},
    {"id":1912,"nombre":"SIDON, CUMBITARA, NARIÑO"},
    {"id":1913,"nombre":"CUNDAY, CUNDAY, TOLIMA"},
    {"id":1914,"nombre":"EL REVES, CUNDAY, TOLIMA"},
    {"id":1915,"nombre":"LA AURORA, CUNDAY, TOLIMA"},
    {"id":1916,"nombre":"SAN PABLO, CUNDAY, TOLIMA"},
    {"id":1917,"nombre":"TRES ESQUINAS, CUNDAY, TOLIMA"},
    {"id":1918,"nombre":"VALENCIA, CUNDAY, TOLIMA"},
    {"id":1919,"nombre":"VARSOVIA, CUNDAY, TOLIMA"},
    {"id":1920,"nombre":"CURILLO, CURILLO, CAQUETA"},
    {"id":1921,"nombre":"SALAMINA, CURILLO, CAQUETA"},
    {"id":1922,"nombre":"CANTABARA, CURITI, SANTANDER"},
    {"id":1923,"nombre":"CUCHICUTE, CURITI, SANTANDER"},
    {"id":1924,"nombre":"CURITI, CURITI, SANTANDER"},
    {"id":1925,"nombre":"EL BASTO, CURITI, SANTANDER"},
    {"id":1926,"nombre":"EL COMUN, CURITI, SANTANDER"},
    {"id":1927,"nombre":"EL UVO, CURITI, SANTANDER"},
    {"id":1928,"nombre":"LAS VUELTAS, CURITI, SANTANDER"},
    {"id":1929,"nombre":"PALO BLANCO BAJO, CURITI, SANTANDER"},
    {"id":1930,"nombre":"PIEDRA GORDA, CURITI, SANTANDER"},
    {"id":1931,"nombre":"ALGARROBO, CURUMANI, CESAR"},
    {"id":1932,"nombre":"BARRIO ACOSTA, CURUMANI, CESAR"},
    {"id":1933,"nombre":"CHAMPAN, CURUMANI, CESAR"},
    {"id":1934,"nombre":"CURUMANI, CURUMANI, CESAR"},
    {"id":1935,"nombre":"EL MAMEY LA SERENA, CURUMANI, CESAR"},
    {"id":1936,"nombre":"GUAIMARAL, CURUMANI, CESAR"},
    {"id":1937,"nombre":"HOJANCHA, CURUMANI, CESAR"},
    {"id":1938,"nombre":"LAS VEGAS, CURUMANI, CESAR"},
    {"id":1939,"nombre":"SABANAGRANDE, CURUMANI, CESAR"},
    {"id":1940,"nombre":"SAN ROQUE, CURUMANI, CESAR"},
    {"id":1941,"nombre":"SAN SEBASTIAN, CURUMANI, CESAR"},
    {"id":1942,"nombre":"SANTA ISABEL, CURUMANI, CESAR"},
    {"id":1943,"nombre":"ARENERA, DABEIBA, ANTIOQUIA"},
    {"id":1944,"nombre":"ARMENIA - CAMPARRUSIA, DABEIBA, ANTIOQUIA"},
    {"id":1945,"nombre":"CARRA, DABEIBA, ANTIOQUIA"},
    {"id":1946,"nombre":"CHIMIANDO, DABEIBA, ANTIOQUIA"},
    {"id":1947,"nombre":"DABEIBA, DABEIBA, ANTIOQUIA"},
    {"id":1948,"nombre":"EL BOTON, DABEIBA, ANTIOQUIA"},
    {"id":1949,"nombre":"GALILEA, DABEIBA, ANTIOQUIA"},
    {"id":1950,"nombre":"LAS CRUCES DE URAMA, DABEIBA, ANTIOQUIA"},
    {"id":1951,"nombre":"LLANO GRANDE, DABEIBA, ANTIOQUIA"},
    {"id":1952,"nombre":"SAN JOSE DE URAMA (URAMAGRANDE), DABEIBA, ANTIOQUIA"},
    {"id":1953,"nombre":"SANTA TERESA, DABEIBA, ANTIOQUIA"},
    {"id":1954,"nombre":"ATUNCELAS, DAGUA, VALLE DEL CAUCA"},
    {"id":1955,"nombre":"BORRERO AYERBE, DAGUA, VALLE DEL CAUCA"},
    {"id":1956,"nombre":"CENTELLA, DAGUA, VALLE DEL CAUCA"},
    {"id":1957,"nombre":"CISNEROS, DAGUA, VALLE DEL CAUCA"},
    {"id":1958,"nombre":"CRISTALES, DAGUA, VALLE DEL CAUCA"},
    {"id":1959,"nombre":"DAGUA, DAGUA, VALLE DEL CAUCA"},
    {"id":1960,"nombre":"EL CARMEN, DAGUA, VALLE DEL CAUCA"},
    {"id":1961,"nombre":"EL DANUBIO, DAGUA, VALLE DEL CAUCA"},
    {"id":1962,"nombre":"EL LIMONAR, DAGUA, VALLE DEL CAUCA"},
    {"id":1963,"nombre":"EL NARANJO, DAGUA, VALLE DEL CAUCA"},
    {"id":1964,"nombre":"EL PALMAR, DAGUA, VALLE DEL CAUCA"},
    {"id":1965,"nombre":"EL PINAL, DAGUA, VALLE DEL CAUCA"},
    {"id":1966,"nombre":"EL QUEREMAL, DAGUA, VALLE DEL CAUCA"},
    {"id":1967,"nombre":"EL ROCIO, DAGUA, VALLE DEL CAUCA"},
    {"id":1968,"nombre":"EL SALADO, DAGUA, VALLE DEL CAUCA"},
    {"id":1969,"nombre":"GUADUALITO, DAGUA, VALLE DEL CAUCA"},
    {"id":1970,"nombre":"JIGUALES, DAGUA, VALLE DEL CAUCA"},
    {"id":1971,"nombre":"JUNTAS, DAGUA, VALLE DEL CAUCA"},
    {"id":1972,"nombre":"KILOMETRO 18, DAGUA, VALLE DEL CAUCA"},
    {"id":1973,"nombre":"LA CASCADA, DAGUA, VALLE DEL CAUCA"},
    {"id":1974,"nombre":"LA ELSA, DAGUA, VALLE DEL CAUCA"},
    {"id":1975,"nombre":"LA PROVIDENCIA, DAGUA, VALLE DEL CAUCA"},
    {"id":1976,"nombre":"LA VENTURA, DAGUA, VALLE DEL CAUCA"},
    {"id":1977,"nombre":"LOBO GUERRERO, DAGUA, VALLE DEL CAUCA"},
    {"id":1978,"nombre":"LOS ALPES, DAGUA, VALLE DEL CAUCA"},
    {"id":1979,"nombre":"PUEBLO NUEVO, DAGUA, VALLE DEL CAUCA"},
    {"id":1980,"nombre":"SAN BERNARDO, DAGUA, VALLE DEL CAUCA"},
    {"id":1981,"nombre":"SAN VICENTE, DAGUA, VALLE DEL CAUCA"},
    {"id":1982,"nombre":"SANTA MARIA, DAGUA, VALLE DEL CAUCA"},
    {"id":1983,"nombre":"SENDO, DAGUA, VALLE DEL CAUCA"},
    {"id":1984,"nombre":"TOCOTA, DAGUA, VALLE DEL CAUCA"},
    {"id":1985,"nombre":"VILLAHERMOSA, DAGUA, VALLE DEL CAUCA"},
    {"id":1986,"nombre":"ZABALETAS, DAGUA, VALLE DEL CAUCA"},
    {"id":1987,"nombre":"ZELANDIA, DAGUA, VALLE DEL CAUCA"},
    {"id":1988,"nombre":"CAMPANA, DIBULLA, LA GUAJIRA"},
    {"id":1989,"nombre":"DIBULLA, DIBULLA, LA GUAJIRA"},
    {"id":1990,"nombre":"LA PUNTA, DIBULLA, LA GUAJIRA"},
    {"id":1991,"nombre":"LAS FLORES, DIBULLA, LA GUAJIRA"},
    {"id":1992,"nombre":"MINGUEO, DIBULLA, LA GUAJIRA"},
    {"id":1993,"nombre":"PALOMINO, DIBULLA, LA GUAJIRA"},
    {"id":1994,"nombre":"RIO ANCHO, DIBULLA, LA GUAJIRA"},
    {"id":1995,"nombre":"BUENAVISTA, DISTRACCION, LA GUAJIRA"},
    {"id":1996,"nombre":"CAIMITO, DISTRACCION, LA GUAJIRA"},
    {"id":1997,"nombre":"CHORRERAS, DISTRACCION, LA GUAJIRA"},
    {"id":1998,"nombre":"DISTRACCION, DISTRACCION, LA GUAJIRA"},
    {"id":1999,"nombre":"DOS CAMINOS, DISTRACCION, LA GUAJIRA"},
    {"id":2000,"nombre":"LA CEIBA, DISTRACCION, LA GUAJIRA"},
    {"id":2001,"nombre":"LA DUDA, DISTRACCION, LA GUAJIRA"},
    {"id":2002,"nombre":"LA LOMA, DISTRACCION, LA GUAJIRA"},
    {"id":2003,"nombre":"LOS HORNITOS, DISTRACCION, LA GUAJIRA"},
    {"id":2004,"nombre":"MADRE VIEJA, DISTRACCION, LA GUAJIRA"},
    {"id":2005,"nombre":"PARAISO, DISTRACCION, LA GUAJIRA"},
    {"id":2006,"nombre":"POTRERITO, DISTRACCION, LA GUAJIRA"},
    {"id":2007,"nombre":"PULGAR, DISTRACCION, LA GUAJIRA"},
    {"id":2008,"nombre":"AMBICA, DOLORES, TOLIMA"},
    {"id":2009,"nombre":"BERMEJO, DOLORES, TOLIMA"},
    {"id":2010,"nombre":"DOLORES, DOLORES, TOLIMA"},
    {"id":2011,"nombre":"EL CARMEN, DOLORES, TOLIMA"},
    {"id":2012,"nombre":"LA SOLEDAD, DOLORES, TOLIMA"},
    {"id":2013,"nombre":"LOS LLANITOS, DOLORES, TOLIMA"},
    {"id":2014,"nombre":"PALOS ALTOS, DOLORES, TOLIMA"},
    {"id":2015,"nombre":"RIO NEGRO, DOLORES, TOLIMA"},
    {"id":2016,"nombre":"SAN ANDRES, DOLORES, TOLIMA"},
    {"id":2017,"nombre":"SAN JOSE, DOLORES, TOLIMA"},
    {"id":2018,"nombre":"SAN PEDRO, DOLORES, TOLIMA"},
    {"id":2019,"nombre":"BELLAVISTA, DON MATIAS, ANTIOQUIA"},
    {"id":2020,"nombre":"DON MATIAS, DON MATIAS, ANTIOQUIA"},
    {"id":2021,"nombre":"RIOGRANDE, DON MATIAS, ANTIOQUIA"},
    {"id":2022,"nombre":"AGUAZUL - COFRE, DOSQUEBRADAS, RISARALDA"},
    {"id":2023,"nombre":"BUENA VISTA, DOSQUEBRADAS, RISARALDA"},
    {"id":2024,"nombre":"COMUNEROS, DOSQUEBRADAS, RISARALDA"},
    {"id":2025,"nombre":"DOSQUEBRADAS, DOSQUEBRADAS, RISARALDA"},
    {"id":2026,"nombre":"EL ESTANQUILLO, DOSQUEBRADAS, RISARALDA"},
    {"id":2027,"nombre":"FRAILES, DOSQUEBRADAS, RISARALDA"},
    {"id":2028,"nombre":"GAITAN, DOSQUEBRADAS, RISARALDA"},
    {"id":2029,"nombre":"LA DIVISA, DOSQUEBRADAS, RISARALDA"},
    {"id":2030,"nombre":"LA ESMERALDA, DOSQUEBRADAS, RISARALDA"},
    {"id":2031,"nombre":"LA ESPERANZA, DOSQUEBRADAS, RISARALDA"},
    {"id":2032,"nombre":"LA FRIA, DOSQUEBRADAS, RISARALDA"},
    {"id":2033,"nombre":"LA PLAYITA, DOSQUEBRADAS, RISARALDA"},
    {"id":2034,"nombre":"LA RIVERA, DOSQUEBRADAS, RISARALDA"},
    {"id":2035,"nombre":"LA ROMELIA, DOSQUEBRADAS, RISARALDA"},
    {"id":2036,"nombre":"LA UNION, DOSQUEBRADAS, RISARALDA"},
    {"id":2037,"nombre":"NARANJALES, DOSQUEBRADAS, RISARALDA"},
    {"id":2038,"nombre":"SANTANA BAJA, DOSQUEBRADAS, RISARALDA"},
    {"id":2039,"nombre":"VILLA CAROLA, DOSQUEBRADAS, RISARALDA"},
    {"id":2040,"nombre":"VILLA DIANA, DOSQUEBRADAS, RISARALDA"},
    {"id":2041,"nombre":"AVENDANOS Y EL CARMEN, DUITAMA, BOYACA"},
    {"id":2042,"nombre":"CIUDADELA INDUSTRIAL, DUITAMA, BOYACA"},
    {"id":2043,"nombre":"DUITAMA, DUITAMA, BOYACA"},
    {"id":2044,"nombre":"SAN LORENZO ABAJO, DUITAMA, BOYACA"},
    {"id":2045,"nombre":"SURBA Y BONZA Y QUEBRADA DE BECERRAS, DUITAMA, BOYACA"},
    {"id":2046,"nombre":"TRINIDAD, DUITAMA, BOYACA"},
    {"id":2047,"nombre":"CUAJADORAS, DURANIA, NORTE DE SANTANDER"},
    {"id":2048,"nombre":"DURANIA, DURANIA, NORTE DE SANTANDER"},
    {"id":2049,"nombre":"HATOVIEJO, DURANIA, NORTE DE SANTANDER"},
    {"id":2050,"nombre":"LA CUCHILLA, DURANIA, NORTE DE SANTANDER"},
    {"id":2051,"nombre":"LA MONTUOSA, DURANIA, NORTE DE SANTANDER"},
    {"id":2052,"nombre":"LA TRINIDAD, DURANIA, NORTE DE SANTANDER"},
    {"id":2053,"nombre":"LA UNION, DURANIA, NORTE DE SANTANDER"},
    {"id":2054,"nombre":"BRASIL, EBEJICO, ANTIOQUIA"},
    {"id":2055,"nombre":"EBEJICO, EBEJICO, ANTIOQUIA"},
    {"id":2056,"nombre":"ECUADOR, EBEJICO, ANTIOQUIA"},
    {"id":2057,"nombre":"GUAYABAL, EBEJICO, ANTIOQUIA"},
    {"id":2058,"nombre":"LA CLARA, EBEJICO, ANTIOQUIA"},
    {"id":2059,"nombre":"SEVILLA, EBEJICO, ANTIOQUIA"},
    {"id":2060,"nombre":"VUELTA LA OREJA, EBEJICO, ANTIOQUIA"},
    {"id":2061,"nombre":"CAJA DE ORO, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2062,"nombre":"CAJONES, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2063,"nombre":"CHORRITOS, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2064,"nombre":"EL AGUILA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2065,"nombre":"EL COFRE, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2066,"nombre":"EL EMBAL, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2067,"nombre":"EL GUAYABO, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2068,"nombre":"ESPARTA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2069,"nombre":"LA ALBANIA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2070,"nombre":"LA GALANA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2071,"nombre":"LA GUAYACANA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2072,"nombre":"LA JUDEA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2073,"nombre":"LA LIBERTAD, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2074,"nombre":"LA MARIA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2075,"nombre":"LA QUIEBRA DE SAN PABLO, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2076,"nombre":"SAN JOSE, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2077,"nombre":"SAN PABLO, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2078,"nombre":"SANTA HELENA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2079,"nombre":"SANTA MARTA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2080,"nombre":"VILLANUEVA, EL AGUILA, VALLE DEL CAUCA"},
    {"id":2081,"nombre":"EL BAGRE, EL BAGRE, ANTIOQUIA"},
    {"id":2082,"nombre":"EL REAL, EL BAGRE, ANTIOQUIA"},
    {"id":2083,"nombre":"LAS FLORES, EL BAGRE, ANTIOQUIA"},
    {"id":2084,"nombre":"PUERTO CLAVER, EL BAGRE, ANTIOQUIA"},
    {"id":2085,"nombre":"PUERTO LOPEZ, EL BAGRE, ANTIOQUIA"},
    {"id":2086,"nombre":"SANTA BARBARA, EL BAGRE, ANTIOQUIA"},
    {"id":2087,"nombre":"SANTA MARGARITA, EL BAGRE, ANTIOQUIA"},
    {"id":2088,"nombre":"AGUA ESTRADA, EL BANCO, MAGDALENA"},
    {"id":2089,"nombre":"ALGARROBO, EL BANCO, MAGDALENA"},
    {"id":2090,"nombre":"BARRANCO DE CHILLOA, EL BANCO, MAGDALENA"},
    {"id":2091,"nombre":"BASTIDAS, EL BANCO, MAGDALENA"},
    {"id":2092,"nombre":"BELEN, EL BANCO, MAGDALENA"},
    {"id":2093,"nombre":"BOTILLERO, EL BANCO, MAGDALENA"},
    {"id":2094,"nombre":"CAIMANERA, EL BANCO, MAGDALENA"},
    {"id":2095,"nombre":"CANO DE PALMA, EL BANCO, MAGDALENA"},
    {"id":2096,"nombre":"EL BANCO, EL BANCO, MAGDALENA"},
    {"id":2097,"nombre":"EL CEDRO, EL BANCO, MAGDALENA"},
    {"id":2098,"nombre":"EL CERRITO, EL BANCO, MAGDALENA"},
    {"id":2099,"nombre":"EL SALTO, EL BANCO, MAGDALENA"},
    {"id":2100,"nombre":"EL TREBOL, EL BANCO, MAGDALENA"},
    {"id":2101,"nombre":"FELIPE EDUARDO, EL BANCO, MAGDALENA"},
    {"id":2102,"nombre":"GARZON, EL BANCO, MAGDALENA"},
    {"id":2103,"nombre":"GUACAMAYAL, EL BANCO, MAGDALENA"},
    {"id":2104,"nombre":"HATILLO DE LA SABANA, EL BANCO, MAGDALENA"},
    {"id":2105,"nombre":"ISLITAS, EL BANCO, MAGDALENA"},
    {"id":2106,"nombre":"LAS CABRAS, EL BANCO, MAGDALENA"},
    {"id":2107,"nombre":"LOS MAMONES, EL BANCO, MAGDALENA"},
    {"id":2108,"nombre":"LOS NEGRITOS, EL BANCO, MAGDALENA"},
    {"id":2109,"nombre":"MATA DE CANA, EL BANCO, MAGDALENA"},
    {"id":2110,"nombre":"MENCHIQUEJO, EL BANCO, MAGDALENA"},
    {"id":2111,"nombre":"PUEBLO NUEVO, EL BANCO, MAGDALENA"},
    {"id":2112,"nombre":"RINCON MALPICA, EL BANCO, MAGDALENA"},
    {"id":2113,"nombre":"SAN EDUARDO, EL BANCO, MAGDALENA"},
    {"id":2114,"nombre":"SAN FELIPE, EL BANCO, MAGDALENA"},
    {"id":2115,"nombre":"SAN JOSE, EL BANCO, MAGDALENA"},
    {"id":2116,"nombre":"SAN ROQUE, EL BANCO, MAGDALENA"},
    {"id":2117,"nombre":"TAMALAMEQUITO, EL BANCO, MAGDALENA"},
    {"id":2118,"nombre":"ALBAN, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2119,"nombre":"ALTO BONITO, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2120,"nombre":"BELLAVISTA, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2121,"nombre":"BOQUERON, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2122,"nombre":"EL CAIRO, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2123,"nombre":"LA CARBONERA, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2124,"nombre":"LA GUARDIA, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2125,"nombre":"PLAYA RICA, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2126,"nombre":"SALMELIA, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2127,"nombre":"SAN JOSE, EL CAIRO, VALLE DEL CAUCA"},
    {"id":2128,"nombre":"EL CALVARIO, EL CALVARIO, META"},
    {"id":2129,"nombre":"MONTFORT, EL CALVARIO, META"},
    {"id":2130,"nombre":"SAN FRANCISCO, EL CALVARIO, META"},
    {"id":2131,"nombre":"SAN JUANITO, EL CALVARIO, META"},
    {"id":2132,"nombre":"SANTA ELENA, EL CALVARIO, META"},
    {"id":2133,"nombre":"BOCA DE RASPADURA, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2134,"nombre":"MANAGRU, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2135,"nombre":"GUAPANDO, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2136,"nombre":"JORODO, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2137,"nombre":"LA ISLA, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2138,"nombre":"LA VICTORIA, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2139,"nombre":"PAVAZA, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2140,"nombre":"PUERTO PERVEL, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2141,"nombre":"SAN LUIS, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2142,"nombre":"TARIDO, EL CANTON DEL SAN PABLO, CHOCO"},
    {"id":2143,"nombre":"ASTILLERO, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2144,"nombre":"BELLALUZ, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2145,"nombre":"EL CAJON, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2146,"nombre":"EL CARMEN, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2147,"nombre":"EL COBRE, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2148,"nombre":"EL ZUL, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2149,"nombre":"GUAMALITO, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2150,"nombre":"LA CAMORRA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2151,"nombre":"LA CULEBRITA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2152,"nombre":"LA ESTRELLA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2153,"nombre":"LA LATA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2154,"nombre":"LA OSA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2155,"nombre":"LA PELOTA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2156,"nombre":"LA QUIEBRA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2157,"nombre":"LAS AGUILAS, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2158,"nombre":"LAS VEGAS DE LA MOTILONA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2159,"nombre":"LAS VEGAS, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2160,"nombre":"MARACAIBO, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2161,"nombre":"PAJITAS, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2162,"nombre":"QUEBRADA ARRIBA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2163,"nombre":"QUEBRADA HONDA, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2164,"nombre":"SANTA INES, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2165,"nombre":"SANTO DOMINGO, EL CARMEN, NORTE DE SANTANDER"},
    {"id":2166,"nombre":"EL CARMEN DE ATRATO, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2167,"nombre":"EL PINON, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2168,"nombre":"EL PORVENIR, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2169,"nombre":"EL SIETE 1, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2170,"nombre":"EL SIETE 2, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2171,"nombre":"GUADUAS, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2172,"nombre":"LA MANSA, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2173,"nombre":"LA PLAYA, EL CARMEN DE ATRATO, CHOCO"},
    {"id":2174,"nombre":"BAJO GRANDE, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2175,"nombre":"CARACOLI GRANDE, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2176,"nombre":"EL CARMEN DE BOLIVAR, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2177,"nombre":"EL HOBO, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2178,"nombre":"EL JOBO, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2179,"nombre":"EL RAIZAL, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2180,"nombre":"EL SALADO, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2181,"nombre":"HATO NUEVO, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2182,"nombre":"JESUS DEL MONTE, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2183,"nombre":"LAS LAJITAS, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2184,"nombre":"MACAYEPOS, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2185,"nombre":"MESA, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2186,"nombre":"SAN CARLOS, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2187,"nombre":"SAN ISIDRO, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2188,"nombre":"SANTA LUCIA, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2189,"nombre":"SANTO DOMINGO DE MEZA, EL CARMEN DE BOLIVAR, BOLIVAR"},
    {"id":2190,"nombre":"ANGOSTURA DE LOS ANDES, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2191,"nombre":"DIVISO DE CIRALES, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2192,"nombre":"DOS BOCAS, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2193,"nombre":"EL CARMEN DE CHUCURI, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2194,"nombre":"EL CENTENARIO, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2195,"nombre":"EL PROGRESO, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2196,"nombre":"EL TOBOSO, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2197,"nombre":"EL VERGEL, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2198,"nombre":"EXPLANACION, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2199,"nombre":"ISLANDA, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2200,"nombre":"LA SALINA, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2201,"nombre":"LA YE, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2202,"nombre":"PORVENIR DE LOS ANDES, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2203,"nombre":"SANTO DOMINGO, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2204,"nombre":"TAMBO REDONDO, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2205,"nombre":"TRES AMIGOS, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2206,"nombre":"VISTA HERMOSA, EL CARMEN DE CHUCURI, SANTANDER"},
    {"id":2207,"nombre":"AGUAS CLARAS, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2208,"nombre":"ALTO GRANDE, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2209,"nombre":"CANADA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2210,"nombre":"EL CARMEN DE VIBORAL, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2211,"nombre":"LA CHAPA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2212,"nombre":"LA ESPERANZA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2213,"nombre":"LA MADERA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2214,"nombre":"LA MARIA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2215,"nombre":"SANTA INES, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2216,"nombre":"SANTA RITA, EL CARMEN DE VIBORAL, ANTIOQUIA"},
    {"id":2217,"nombre":"EL CASTILLO, EL CASTILLO, META"},
    {"id":2218,"nombre":"LA ESMERALDA, EL CASTILLO, META"},
    {"id":2219,"nombre":"MEDELLIN DEL ARIARI, EL CASTILLO, META"},
    {"id":2220,"nombre":"MIRAVALLES, EL CASTILLO, META"},
    {"id":2221,"nombre":"PUERTO ESPERANZA, EL CASTILLO, META"},
    {"id":2222,"nombre":"PUERTO UNION, EL CASTILLO, META"},
    {"id":2223,"nombre":"AUJI, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2224,"nombre":"CAMPOALEGRE, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2225,"nombre":"CARRIZAL, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2226,"nombre":"CHINCHE VIEJO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2227,"nombre":"EL CARMEN, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2228,"nombre":"EL CASTILLO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2229,"nombre":"EL CERRITO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2230,"nombre":"EL MORAL, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2231,"nombre":"EL PARAISO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2232,"nombre":"EL PLACER, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2233,"nombre":"EL POMO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2234,"nombre":"LOS ANDES, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2235,"nombre":"SAN ANTONIO, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2236,"nombre":"SANTA ELENA, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2237,"nombre":"SANTA LUISA, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2238,"nombre":"TENERIFE, EL CERRITO, VALLE DEL CAUCA"},
    {"id":2239,"nombre":"ARENAL, EL CHARCO, NARIÑO"},
    {"id":2240,"nombre":"BAHIA MULATAS, EL CHARCO, NARIÑO"},
    {"id":2241,"nombre":"BAZAN, EL CHARCO, NARIÑO"},
    {"id":2242,"nombre":"BENJAMIN HERRERA, EL CHARCO, NARIÑO"},
    {"id":2243,"nombre":"BOLIVAR (SAN PEDRO), EL CHARCO, NARIÑO"},
    {"id":2244,"nombre":"CUIL NUEVO, EL CHARCO, NARIÑO"},
    {"id":2245,"nombre":"EL CHARCO, EL CHARCO, NARIÑO"},
    {"id":2246,"nombre":"GAITAN, EL CHARCO, NARIÑO"},
    {"id":2247,"nombre":"HORMIGUERO, EL CHARCO, NARIÑO"},
    {"id":2248,"nombre":"LAS MERCEDES, EL CHARCO, NARIÑO"},
    {"id":2249,"nombre":"PLINIO OLIVEROS, EL CHARCO, NARIÑO"},
    {"id":2250,"nombre":"PULBUSA, EL CHARCO, NARIÑO"},
    {"id":2251,"nombre":"RIO TAPAJE, EL CHARCO, NARIÑO"},
    {"id":2252,"nombre":"ROBERTO PAYAN, EL CHARCO, NARIÑO"},
    {"id":2253,"nombre":"SAN FRANCISCO DE TAIGA, EL CHARCO, NARIÑO"},
    {"id":2254,"nombre":"SAN JOSE DEL TAPAJE, EL CHARCO, NARIÑO"},
    {"id":2255,"nombre":"SECADERO SEQUITO, EL CHARCO, NARIÑO"},
    {"id":2256,"nombre":"TURBAY, EL CHARCO, NARIÑO"},
    {"id":2257,"nombre":"URIBE URIBE, EL CHARCO, NARIÑO"},
    {"id":2258,"nombre":"EL COCUY, EL COCUY, BOYACA"},
    {"id":2259,"nombre":"EL COLEGIO, EL COLEGIO, CUNDINAMARCA"},
    {"id":2260,"nombre":"EL TRIUNFO, EL COLEGIO, CUNDINAMARCA"},
    {"id":2261,"nombre":"LA VICTORIA, EL COLEGIO, CUNDINAMARCA"},
    {"id":2262,"nombre":"PRADILLA, EL COLEGIO, CUNDINAMARCA"},
    {"id":2263,"nombre":"CARACOLICITO, EL COPEY, CESAR"},
    {"id":2264,"nombre":"CHIMILA, EL COPEY, CESAR"},
    {"id":2265,"nombre":"EL COPEY, EL COPEY, CESAR"},
    {"id":2266,"nombre":"SAN FRANCISCO DE ASIS, EL COPEY, CESAR"},
    {"id":2267,"nombre":"EL DONCELLO, EL DONCELLO, CAQUETA"},
    {"id":2268,"nombre":"MAGUARE, EL DONCELLO, CAQUETA"},
    {"id":2269,"nombre":"PUERTO HUNGRIA, EL DONCELLO, CAQUETA"},
    {"id":2270,"nombre":"PUERTO MANRIQUE, EL DONCELLO, CAQUETA"},
    {"id":2271,"nombre":"RIONEGRO, EL DONCELLO, CAQUETA"},
    {"id":2272,"nombre":"EL DORADO, EL DORADO, META"},
    {"id":2273,"nombre":"PUEBLO SANCHEZ, EL DORADO, META"},
    {"id":2274,"nombre":"SAN ISIDRO, EL DORADO, META"},
    {"id":2275,"nombre":"BALKANES, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2276,"nombre":"BATATAL, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2277,"nombre":"BERLIN, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2278,"nombre":"BITACO, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2279,"nombre":"CAJAMARCA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2280,"nombre":"EL DOVIO, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2281,"nombre":"EL DUMAR, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2282,"nombre":"EL ORO, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2283,"nombre":"GUACHAL, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2284,"nombre":"LA CABANA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2285,"nombre":"LA ESPERANZA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2286,"nombre":"LA PRADERA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2287,"nombre":"LITUANIA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2288,"nombre":"MONTEAZUL, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2289,"nombre":"PLAYA RICA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2290,"nombre":"SANTA TERESA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2291,"nombre":"SIRIMUNDA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2292,"nombre":"TOLDAFRIA, EL DOVIO, VALLE DEL CAUCA"},
    {"id":2293,"nombre":"EL ENCANTO, EL ENCANTO, AMAZONAS"},
    {"id":2294,"nombre":"EL ESPINO, EL ESPINO, BOYACA"},
    {"id":2295,"nombre":"LA BARRERA, EL ESPINO, BOYACA"},
    {"id":2296,"nombre":"LA LAGUNA, EL ESPINO, BOYACA"},
    {"id":2297,"nombre":"SANTA ANA, EL ESPINO, BOYACA"},
    {"id":2298,"nombre":"EL GUACAMAYO, EL GUACAMAYO, SANTANDER"},
    {"id":2299,"nombre":"SANTA RITA, EL GUACAMAYO, SANTANDER"},
    {"id":2300,"nombre":"EL GUAMO, EL GUAMO, BOLIVAR"},
    {"id":2301,"nombre":"LA ENEA, EL GUAMO, BOLIVAR"},
    {"id":2302,"nombre":"LATA, EL GUAMO, BOLIVAR"},
    {"id":2303,"nombre":"NERVITI, EL GUAMO, BOLIVAR"},
    {"id":2304,"nombre":"ROBLES, EL GUAMO, BOLIVAR"},
    {"id":2305,"nombre":"TASAJERA, EL GUAMO, BOLIVAR"},
    {"id":2306,"nombre":"BURUJON, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2307,"nombre":"CARRA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2308,"nombre":"CHAPPIEN, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2309,"nombre":"CHARAMBIRA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2310,"nombre":"CHONTADURO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2311,"nombre":"COPOMA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2312,"nombre":"CORRIENTE PALO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2313,"nombre":"CUCURRUPI, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2314,"nombre":"DESCOLGADERO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2315,"nombre":"EL CHONCHO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2316,"nombre":"EL COCO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2317,"nombre":"SANTA GENOVEVA DE DOCORDO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2318,"nombre":"GARCIA GOMEZ, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2319,"nombre":"GUACHAL, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2320,"nombre":"GUAGUALITO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2321,"nombre":"JIGUALITO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2322,"nombre":"LA PENITAS, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2323,"nombre":"LOS PEREA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2324,"nombre":"MUNGUIDO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2325,"nombre":"PALESTINA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2326,"nombre":"PANGALA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2327,"nombre":"PANGALITA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2328,"nombre":"PAPAYO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2329,"nombre":"PICHIMA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2330,"nombre":"PLAYITA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2331,"nombre":"PUERTO MURILLO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2332,"nombre":"QUEBRADA DE PICHIMA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2333,"nombre":"QUEBRADA DE TOGOROMA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2334,"nombre":"QUEBRADA GIRON, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2335,"nombre":"QUICHARO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2336,"nombre":"SAN JOSE, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2337,"nombre":"TAPARAL, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2338,"nombre":"TAPARALITO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2339,"nombre":"TIO CIRILO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2340,"nombre":"TORDO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2341,"nombre":"TROJITA, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2342,"nombre":"UNION VALSALITO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2343,"nombre":"VENADO, EL LITORAL DEL SAN JUAN, CHOCO"},
    {"id":2344,"nombre":"EL MOLINO, EL MOLINO, LA GUAJIRA"},
    {"id":2345,"nombre":"CUATRO VIENTOS, EL PASO, CESAR"},
    {"id":2346,"nombre":"EL CARMEN, EL PASO, CESAR"},
    {"id":2347,"nombre":"EL PASO, EL PASO, CESAR"},
    {"id":2348,"nombre":"EL VALLITO, EL PASO, CESAR"},
    {"id":2349,"nombre":"LA LOMA, EL PASO, CESAR"},
    {"id":2350,"nombre":"POTRERILLO, EL PASO, CESAR"},
    {"id":2351,"nombre":"PUEBLO REGADO, EL PASO, CESAR"},
    {"id":2352,"nombre":"BOLIVIA, EL PAUJIL, CAQUETA"},
    {"id":2353,"nombre":"EL PAUJIL, EL PAUJIL, CAQUETA"},
    {"id":2354,"nombre":"GALICIA, EL PAUJIL, CAQUETA"},
    {"id":2355,"nombre":"PORVENIR, EL PAUJIL, CAQUETA"},
    {"id":2356,"nombre":"VERSALLES, EL PAUJIL, CAQUETA"},
    {"id":2357,"nombre":"BARRIO NUEVO, EL PLAYON, SANTANDER"},
    {"id":2358,"nombre":"BETANIA, EL PLAYON, SANTANDER"},
    {"id":2359,"nombre":"EL FILO, EL PLAYON, SANTANDER"},
    {"id":2360,"nombre":"EL PINO, EL PLAYON, SANTANDER"},
    {"id":2361,"nombre":"EL PLAYON, EL PLAYON, SANTANDER"},
    {"id":2362,"nombre":"ESTACION LAGUNA, EL PLAYON, SANTANDER"},
    {"id":2363,"nombre":"LA BATECA, EL PLAYON, SANTANDER"},
    {"id":2364,"nombre":"LIMITES, EL PLAYON, SANTANDER"},
    {"id":2365,"nombre":"PLANADAS DE LA ARRUMBAZON, EL PLAYON, SANTANDER"},
    {"id":2366,"nombre":"SAN BERNARDO, EL PLAYON, SANTANDER"},
    {"id":2367,"nombre":"SAN IGNACIO DE LA AGUADA, EL PLAYON, SANTANDER"},
    {"id":2368,"nombre":"SAN PEDRO DE LA TIGRA, EL PLAYON, SANTANDER"},
    {"id":2369,"nombre":"SANTA BARBARA, EL PLAYON, SANTANDER"},
    {"id":2370,"nombre":"EL BONGO, EL RETEN, MAGDALENA"},
    {"id":2371,"nombre":"EL RETEN, EL RETEN, MAGDALENA"},
    {"id":2372,"nombre":"LA COLOMBIA, EL RETEN, MAGDALENA"},
    {"id":2373,"nombre":"LAS FLORES, EL RETEN, MAGDALENA"},
    {"id":2374,"nombre":"CERRITOS, EL RETORNO, GUAVIARE"},
    {"id":2375,"nombre":"EL RETORNO, EL RETORNO, GUAVIARE"},
    {"id":2376,"nombre":"EL UNILLA, EL RETORNO, GUAVIARE"},
    {"id":2377,"nombre":"LA LIBERTAD, EL RETORNO, GUAVIARE"},
    {"id":2378,"nombre":"MORICHAL VIEJO, EL RETORNO, GUAVIARE"},
    {"id":2379,"nombre":"SAN LUCAS, EL RETORNO, GUAVIARE"},
    {"id":2380,"nombre":"CALLEJON, EL ROBLE, SUCRE"},
    {"id":2381,"nombre":"CAYO DE PALMA, EL ROBLE, SUCRE"},
    {"id":2382,"nombre":"CORNETA, EL ROBLE, SUCRE"},
    {"id":2383,"nombre":"EL ROBLE, EL ROBLE, SUCRE"},
    {"id":2384,"nombre":"EL SITIO, EL ROBLE, SUCRE"},
    {"id":2385,"nombre":"LAS TABLITAS, EL ROBLE, SUCRE"},
    {"id":2386,"nombre":"PALMITAL, EL ROBLE, SUCRE"},
    {"id":2387,"nombre":"PATILLAL, EL ROBLE, SUCRE"},
    {"id":2388,"nombre":"PUEBLO NUEVO O GRILLO ALEGRE, EL ROBLE, SUCRE"},
    {"id":2389,"nombre":"RANCHO DE LA CRUZ, EL ROBLE, SUCRE"},
    {"id":2390,"nombre":"SAN FRANCISCO, EL ROBLE, SUCRE"},
    {"id":2391,"nombre":"SANTA ROSA, EL ROBLE, SUCRE"},
    {"id":2392,"nombre":"TIERRA SANTA, EL ROBLE, SUCRE"},
    {"id":2393,"nombre":"VILLAVICENCIO, EL ROBLE, SUCRE"},
    {"id":2394,"nombre":"CAMPO ALEGRE, EL ROSAL, CUNDINAMARCA"},
    {"id":2395,"nombre":"EL ROSAL, EL ROSAL, CUNDINAMARCA"},
    {"id":2396,"nombre":"SAN JOSE, EL ROSAL, CUNDINAMARCA"},
    {"id":2397,"nombre":"ALTAMIRA, EL ROSARIO, NARIÑO"},
    {"id":2398,"nombre":"CANADUZAL, EL ROSARIO, NARIÑO"},
    {"id":2399,"nombre":"EL RINCON, EL ROSARIO, NARIÑO"},
    {"id":2400,"nombre":"EL ROSARIO, EL ROSARIO, NARIÑO"},
    {"id":2401,"nombre":"ESMERALDAS, EL ROSARIO, NARIÑO"},
    {"id":2402,"nombre":"LA FLORIDA, EL ROSARIO, NARIÑO"},
    {"id":2403,"nombre":"LA MONTANA, EL ROSARIO, NARIÑO"},
    {"id":2404,"nombre":"LA SIERRA, EL ROSARIO, NARIÑO"},
    {"id":2405,"nombre":"MADRIGAL, EL ROSARIO, NARIÑO"},
    {"id":2406,"nombre":"MARTIN PEREZ, EL ROSARIO, NARIÑO"},
    {"id":2407,"nombre":"POLICARPA, EL ROSARIO, NARIÑO"},
    {"id":2408,"nombre":"PUEBLO NUEVO, EL ROSARIO, NARIÑO"},
    {"id":2409,"nombre":"RESTREPO, EL ROSARIO, NARIÑO"},
    {"id":2410,"nombre":"SAN JOSE DE LA MONTANA, EL ROSARIO, NARIÑO"},
    {"id":2411,"nombre":"SAN PABLO, EL ROSARIO, NARIÑO"},
    {"id":2412,"nombre":"SAN ROQUE, EL ROSARIO, NARIÑO"},
    {"id":2413,"nombre":"SANCHEZ, EL ROSARIO, NARIÑO"},
    {"id":2414,"nombre":"SANTA ISABEL, EL ROSARIO, NARIÑO"},
    {"id":2415,"nombre":"SANTA ROSA, EL ROSARIO, NARIÑO"},
    {"id":2416,"nombre":"EL SANTUARIO, EL SANTUARIO, ANTIOQUIA"},
    {"id":2417,"nombre":"VISTA HERMOSA, EL SANTUARIO, ANTIOQUIA"},
    {"id":2418,"nombre":"APONTE, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2419,"nombre":"EL GRANADILLO, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2420,"nombre":"EL TABLON DE GOMEZ, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2421,"nombre":"FATIMA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2422,"nombre":"LA CUEVA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2423,"nombre":"LA ISLA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2424,"nombre":"LA VICTORIA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2425,"nombre":"LAS ARADAS, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2426,"nombre":"LAS MESAS, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2427,"nombre":"LOS YUNGAS, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2428,"nombre":"MARCELLA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2429,"nombre":"PITALITO BAJO, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2430,"nombre":"POMPEYA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2431,"nombre":"PORVENIR, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2432,"nombre":"SAN FRANCISCO, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2433,"nombre":"SAN RAFAEL, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2434,"nombre":"VALENCIA, EL TABLON DE GOMEZ, NARIÑO"},
    {"id":2435,"nombre":"EL TARRA, EL TARRA, NORTE DE SANTANDER"},
    {"id":2436,"nombre":"FILO GRINGO, EL TARRA, NORTE DE SANTANDER"},
    {"id":2437,"nombre":"NUEVA GRANADA, EL TARRA, NORTE DE SANTANDER"},
    {"id":2438,"nombre":"ORU, EL TARRA, NORTE DE SANTANDER"},
    {"id":2439,"nombre":"PALMAS DE VINO, EL TARRA, NORTE DE SANTANDER"},
    {"id":2440,"nombre":"ASTILLEROS, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2441,"nombre":"CAMPO ALICIA, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2442,"nombre":"EL ZULIA, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2443,"nombre":"ENCERRADEROS, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2444,"nombre":"LA ALEJANDRA, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2445,"nombre":"LA YE - SAN MIGUEL, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2446,"nombre":"LAS PIEDRAS, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2447,"nombre":"PAN DE AZUCAR, EL ZULIA, NORTE DE SANTANDER"},
    {"id":2448,"nombre":"EL VISO, ELIAS, HUILA"},
    {"id":2449,"nombre":"ELIAS, ELIAS, HUILA"},
    {"id":2450,"nombre":"LAS AGUADAS, ELIAS, HUILA"},
    {"id":2451,"nombre":"ORITOGUAZ, ELIAS, HUILA"},
    {"id":2452,"nombre":"POTRERILLOS, ELIAS, HUILA"},
    {"id":2453,"nombre":"CANADA, ENCINO, SANTANDER"},
    {"id":2454,"nombre":"ENCINO, ENCINO, SANTANDER"},
    {"id":2455,"nombre":"ENCISO, ENCISO, SANTANDER"},
    {"id":2456,"nombre":"PENA COLORADA, ENCISO, SANTANDER"},
    {"id":2457,"nombre":"ENTRERRIOS, ENTRERRIOS, ANTIOQUIA"},
    {"id":2458,"nombre":"ENVIGADO, ENVIGADO, ANTIOQUIA"},
    {"id":2459,"nombre":"LAS PALMAS, ENVIGADO, ANTIOQUIA"},
    {"id":2460,"nombre":"CHICORAL, ESPINAL, TOLIMA"},
    {"id":2461,"nombre":"ESPINAL, ESPINAL, TOLIMA"},
    {"id":2462,"nombre":"LA ESPERANZA, ESPINAL, TOLIMA"},
    {"id":2463,"nombre":"MONTALVO CENTRO, ESPINAL, TOLIMA"},
    {"id":2464,"nombre":"SAN FRANCISCO, ESPINAL, TOLIMA"},
    {"id":2465,"nombre":"ALTO DE CORDOBA, FACATATIVA, CUNDINAMARCA"},
    {"id":2466,"nombre":"ARRAYANES, FACATATIVA, CUNDINAMARCA"},
    {"id":2467,"nombre":"BERLIN, FACATATIVA, CUNDINAMARCA"},
    {"id":2468,"nombre":"EL PESEBRE, FACATATIVA, CUNDINAMARCA"},
    {"id":2469,"nombre":"FACATATIVA, FACATATIVA, CUNDINAMARCA"},
    {"id":2470,"nombre":"LOS ANDES, FACATATIVA, CUNDINAMARCA"},
    {"id":2471,"nombre":"LOS LLANITOS, FACATATIVA, CUNDINAMARCA"},
    {"id":2472,"nombre":"LOS MANZANOS, FACATATIVA, CUNDINAMARCA"},
    {"id":2473,"nombre":"PASO ANCHO, FACATATIVA, CUNDINAMARCA"},
    {"id":2474,"nombre":"PUEBLO VIEJO, FACATATIVA, CUNDINAMARCA"},
    {"id":2475,"nombre":"SAN ANTONIO I, FACATATIVA, CUNDINAMARCA"},
    {"id":2476,"nombre":"SAN ANTONIO II, FACATATIVA, CUNDINAMARCA"},
    {"id":2477,"nombre":"SAN RAFAEL, FACATATIVA, CUNDINAMARCA"},
    {"id":2478,"nombre":"SANTA MARTHA, FACATATIVA, CUNDINAMARCA"},
    {"id":2479,"nombre":"TIERRA GRATA, FACATATIVA, CUNDINAMARCA"},
    {"id":2480,"nombre":"YERBABUENA, FACATATIVA, CUNDINAMARCA"},
    {"id":2481,"nombre":"FALAN, FALAN, TOLIMA"},
    {"id":2482,"nombre":"FRIAS, FALAN, TOLIMA"},
    {"id":2483,"nombre":"PIEDECUESTA, FALAN, TOLIMA"},
    {"id":2484,"nombre":"BALMORAL, FILADELFIA, CALDAS"},
    {"id":2485,"nombre":"EL PINTADO, FILADELFIA, CALDAS"},
    {"id":2486,"nombre":"EL VERSO, FILADELFIA, CALDAS"},
    {"id":2487,"nombre":"FILADELFIA, FILADELFIA, CALDAS"},
    {"id":2488,"nombre":"LA MARINA, FILADELFIA, CALDAS"},
    {"id":2489,"nombre":"LA PAILA, FILADELFIA, CALDAS"},
    {"id":2490,"nombre":"MORRITOS, FILADELFIA, CALDAS"},
    {"id":2491,"nombre":"SAMARIA, FILADELFIA, CALDAS"},
    {"id":2492,"nombre":"SAN LUIS, FILADELFIA, CALDAS"},
    {"id":2493,"nombre":"CRUCES, FILANDIA, QUINDIO"},
    {"id":2494,"nombre":"EL PARAISO, FILANDIA, QUINDIO"},
    {"id":2495,"nombre":"FILANDIA, FILANDIA, QUINDIO"},
    {"id":2496,"nombre":"LA INDIA, FILANDIA, QUINDIO"},
    {"id":2497,"nombre":"PAVAS, FILANDIA, QUINDIO"},
    {"id":2498,"nombre":"FIRAVITOBA, FIRAVITOBA, BOYACA"},
    {"id":2499,"nombre":"ALFONSO LOPEZ, FLANDES, TOLIMA"},
    {"id":2500,"nombre":"CAMALA, FLANDES, TOLIMA"},
    {"id":2501,"nombre":"EL COLEGIO, FLANDES, TOLIMA"},
    {"id":2502,"nombre":"FLANDES, FLANDES, TOLIMA"},
    {"id":2503,"nombre":"PARADERO I, FLANDES, TOLIMA"},
    {"id":2504,"nombre":"PARADERO II (TARQUI), FLANDES, TOLIMA"},
    {"id":2505,"nombre":"PUERTA BLANCA, FLANDES, TOLIMA"},
    {"id":2506,"nombre":"TOPACIO, FLANDES, TOLIMA"},
    {"id":2507,"nombre":"FLORESTA, FLORESTA, BOYACA"},
    {"id":2508,"nombre":"TOBASIA, FLORESTA, BOYACA"},
    {"id":2509,"nombre":"AREPITAS, FLORIAN, SANTANDER"},
    {"id":2510,"nombre":"BUENAVISTA, FLORIAN, SANTANDER"},
    {"id":2511,"nombre":"FLORIAN, FLORIAN, SANTANDER"},
    {"id":2512,"nombre":"LA VENTA, FLORIAN, SANTANDER"},
    {"id":2513,"nombre":"SAN ANTONIO DE LEONES, FLORIAN, SANTANDER"},
    {"id":2514,"nombre":"TRAVESIAS, FLORIAN, SANTANDER"},
    {"id":2515,"nombre":"BETANIA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2516,"nombre":"CHOCOCITO, FLORIDA, VALLE DEL CAUCA"},
    {"id":2517,"nombre":"EL PEDREGAL ETAPAS I Y II, FLORIDA, VALLE DEL CAUCA"},
    {"id":2518,"nombre":"FLORIDA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2519,"nombre":"LA DIANA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2520,"nombre":"LA RIVERA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2521,"nombre":"LA UNION, FLORIDA, VALLE DEL CAUCA"},
    {"id":2522,"nombre":"LOS CALENOS, FLORIDA, VALLE DEL CAUCA"},
    {"id":2523,"nombre":"PARRAGA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2524,"nombre":"PUEBLO NUEVO, FLORIDA, VALLE DEL CAUCA"},
    {"id":2525,"nombre":"REMOLINO, FLORIDA, VALLE DEL CAUCA"},
    {"id":2526,"nombre":"SAN ANTONIO DE LOS CABALLEROS, FLORIDA, VALLE DEL CAUCA"},
    {"id":2527,"nombre":"SAN FRANCISCO (EL LLANITO), FLORIDA, VALLE DEL CAUCA"},
    {"id":2528,"nombre":"SANTO DOMINGO, FLORIDA, VALLE DEL CAUCA"},
    {"id":2529,"nombre":"TARRAGONA PARTE ALTA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2530,"nombre":"TARRAGONA PARTE BAJA, FLORIDA, VALLE DEL CAUCA"},
    {"id":2531,"nombre":"EL MORTINO, FLORIDABLANCA, SANTANDER"},
    {"id":2532,"nombre":"FLORIDABLANCA, FLORIDABLANCA, SANTANDER"},
    {"id":2533,"nombre":"MONTEARROYO CONDOMINIO, FLORIDABLANCA, SANTANDER"},
    {"id":2534,"nombre":"RUITOQUE CONDOMINIO, FLORIDABLANCA, SANTANDER"},
    {"id":2535,"nombre":"VALLE DE RUITOQUE, FLORIDABLANCA, SANTANDER"},
    {"id":2536,"nombre":"VILLA EDILIA CONDOMINIO, FLORIDABLANCA, SANTANDER"},
    {"id":2537,"nombre":"VILLA GUADALQUIVIR CONDOMINIO, FLORIDABLANCA, SANTANDER"},
    {"id":2538,"nombre":"FOMEQUE, FOMEQUE, CUNDINAMARCA"},
    {"id":2539,"nombre":"LA UNION, FOMEQUE, CUNDINAMARCA"},
    {"id":2540,"nombre":"BANGANITA, FONSECA, LA GUAJIRA"},
    {"id":2541,"nombre":"CARDONAL, FONSECA, LA GUAJIRA"},
    {"id":2542,"nombre":"CONEJO, FONSECA, LA GUAJIRA"},
    {"id":2543,"nombre":"EL CONFUSO, FONSECA, LA GUAJIRA"},
    {"id":2544,"nombre":"EL HATICO, FONSECA, LA GUAJIRA"},
    {"id":2545,"nombre":"FONSECA, FONSECA, LA GUAJIRA"},
    {"id":2546,"nombre":"GUAMACHAL, FONSECA, LA GUAJIRA"},
    {"id":2547,"nombre":"LOS ALTOS, FONSECA, LA GUAJIRA"},
    {"id":2548,"nombre":"POTRERITO, FONSECA, LA GUAJIRA"},
    {"id":2549,"nombre":"QUEBRACHAL, FONSECA, LA GUAJIRA"},
    {"id":2550,"nombre":"SITIO NUEVO, FONSECA, LA GUAJIRA"},
    {"id":2551,"nombre":"CARANAL, FORTUL, ARAUCA"},
    {"id":2552,"nombre":"FORTUL, FORTUL, ARAUCA"},
    {"id":2553,"nombre":"PUERTO NIDIA, FORTUL, ARAUCA"},
    {"id":2554,"nombre":"FOSCA, FOSCA, CUNDINAMARCA"},
    {"id":2555,"nombre":"SANAME, FOSCA, CUNDINAMARCA"},
    {"id":2556,"nombre":"BOCA DE CURAY, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2557,"nombre":"CORDOBA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2558,"nombre":"SALAHONDA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2559,"nombre":"HUGO BELALCAZAR, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2560,"nombre":"JORGE A.CUERO, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2561,"nombre":"LA PLAYA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2562,"nombre":"LAUREANO ARELLA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2563,"nombre":"LUIS AVELINO PEREZ, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2564,"nombre":"NICANOR VALENCIA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2565,"nombre":"PATIA, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2566,"nombre":"PEDRO VEIRA ARIAS, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2567,"nombre":"SIMON BOLIVAR, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2568,"nombre":"VICTOR CALONGE, FRANCISCO PIZARRO, NARIÑO"},
    {"id":2569,"nombre":"BERLIN, FREDONIA, ANTIOQUIA"},
    {"id":2570,"nombre":"COMBIA GRANDE, FREDONIA, ANTIOQUIA"},
    {"id":2571,"nombre":"EL EDEN, FREDONIA, ANTIOQUIA"},
    {"id":2572,"nombre":"EL PLAN, FREDONIA, ANTIOQUIA"},
    {"id":2573,"nombre":"EL ZANCUDO, FREDONIA, ANTIOQUIA"},
    {"id":2574,"nombre":"FREDONIA, FREDONIA, ANTIOQUIA"},
    {"id":2575,"nombre":"LA LOMA DEL PLAN, FREDONIA, ANTIOQUIA"},
    {"id":2576,"nombre":"LA MINA, FREDONIA, ANTIOQUIA"},
    {"id":2577,"nombre":"LOS PALOMOS, FREDONIA, ANTIOQUIA"},
    {"id":2578,"nombre":"MARSELLA, FREDONIA, ANTIOQUIA"},
    {"id":2579,"nombre":"PIANOLA, FREDONIA, ANTIOQUIA"},
    {"id":2580,"nombre":"PIEDRA VERDE, FREDONIA, ANTIOQUIA"},
    {"id":2581,"nombre":"PUENTE IGLESIAS, FREDONIA, ANTIOQUIA"},
    {"id":2582,"nombre":"UVITAL, FREDONIA, ANTIOQUIA"},
    {"id":2583,"nombre":"ZABALETAS, FREDONIA, ANTIOQUIA"},
    {"id":2584,"nombre":"AGUA CLARAS, FRESNO, TOLIMA"},
    {"id":2585,"nombre":"BETANIA, FRESNO, TOLIMA"},
    {"id":2586,"nombre":"BOCAS DEL GUARINO, FRESNO, TOLIMA"},
    {"id":2587,"nombre":"CAMPEON, FRESNO, TOLIMA"},
    {"id":2588,"nombre":"CAMPEON MEDIO, FRESNO, TOLIMA"},
    {"id":2589,"nombre":"EL TABLAZO, FRESNO, TOLIMA"},
    {"id":2590,"nombre":"FRESNO, FRESNO, TOLIMA"},
    {"id":2591,"nombre":"LA AGUADITA, FRESNO, TOLIMA"},
    {"id":2592,"nombre":"LOS ANDES, FRESNO, TOLIMA"},
    {"id":2593,"nombre":"PAVAS, FRESNO, TOLIMA"},
    {"id":2594,"nombre":"PIEDRA GRANDE, FRESNO, TOLIMA"},
    {"id":2595,"nombre":"SAN BERNARDO, FRESNO, TOLIMA"},
    {"id":2596,"nombre":"BUENOS AIRES, FRONTINO, ANTIOQUIA"},
    {"id":2597,"nombre":"CARAUTA, FRONTINO, ANTIOQUIA"},
    {"id":2598,"nombre":"CHONTADURO, FRONTINO, ANTIOQUIA"},
    {"id":2599,"nombre":"EL CERRO, FRONTINO, ANTIOQUIA"},
    {"id":2600,"nombre":"EL MADERO, FRONTINO, ANTIOQUIA"},
    {"id":2601,"nombre":"FRONTINO, FRONTINO, ANTIOQUIA"},
    {"id":2602,"nombre":"FUEMIA, FRONTINO, ANTIOQUIA"},
    {"id":2603,"nombre":"JENGAMECODA, FRONTINO, ANTIOQUIA"},
    {"id":2604,"nombre":"LA CLARITA - LAS MINAS, FRONTINO, ANTIOQUIA"},
    {"id":2605,"nombre":"MURRI, FRONTINO, ANTIOQUIA"},
    {"id":2606,"nombre":"MUSINGA, FRONTINO, ANTIOQUIA"},
    {"id":2607,"nombre":"NOBOGA, FRONTINO, ANTIOQUIA"},
    {"id":2608,"nombre":"NUTIBARA, FRONTINO, ANTIOQUIA"},
    {"id":2609,"nombre":"PANTANOS, FRONTINO, ANTIOQUIA"},
    {"id":2610,"nombre":"PARQUE LAS ORQUIDEAS, FRONTINO, ANTIOQUIA"},
    {"id":2611,"nombre":"PONTON, FRONTINO, ANTIOQUIA"},
    {"id":2612,"nombre":"SAN LAZARO, FRONTINO, ANTIOQUIA"},
    {"id":2613,"nombre":"CANO BLANCO, FUENTE DE ORO, META"},
    {"id":2614,"nombre":"FUENTE DE ORO, FUENTE DE ORO, META"},
    {"id":2615,"nombre":"LA COOPERATIVA, FUENTE DE ORO, META"},
    {"id":2616,"nombre":"PUERTO ALJURE, FUENTE DE ORO, META"},
    {"id":2617,"nombre":"PUERTO LIMON, FUENTE DE ORO, META"},
    {"id":2618,"nombre":"PUERTO SANTANDER, FUENTE DE ORO, META"},
    {"id":2619,"nombre":"UNION DEL ARIARI, FUENTE DE ORO, META"},
    {"id":2620,"nombre":"DONA MARIA, FUNDACION, MAGDALENA"},
    {"id":2621,"nombre":"ESTACION ALGARROBO, FUNDACION, MAGDALENA"},
    {"id":2622,"nombre":"FUNDACION, FUNDACION, MAGDALENA"},
    {"id":2623,"nombre":"LOMA DEL BALSAMO, FUNDACION, MAGDALENA"},
    {"id":2624,"nombre":"RIO MAR, FUNDACION, MAGDALENA"},
    {"id":2625,"nombre":"SANTA CLARA, FUNDACION, MAGDALENA"},
    {"id":2626,"nombre":"SANTA ROSA, FUNDACION, MAGDALENA"},
    {"id":2627,"nombre":"SI DIOS QUIERE, FUNDACION, MAGDALENA"},
    {"id":2628,"nombre":"CHAPAL, FUNES, NARIÑO"},
    {"id":2629,"nombre":"CHITARRAL, FUNES, NARIÑO"},
    {"id":2630,"nombre":"EL TOTORAL, FUNES, NARIÑO"},
    {"id":2631,"nombre":"FUNES, FUNES, NARIÑO"},
    {"id":2632,"nombre":"GUAPUSCAL, FUNES, NARIÑO"},
    {"id":2633,"nombre":"LA MESA, FUNES, NARIÑO"},
    {"id":2634,"nombre":"LA VEGA, FUNES, NARIÑO"},
    {"id":2635,"nombre":"LOMA DE DELGADO, FUNES, NARIÑO"},
    {"id":2636,"nombre":"SAN MIGUEL TELL, FUNES, NARIÑO"},
    {"id":2637,"nombre":"SUCUMBIOS, FUNES, NARIÑO"},
    {"id":2638,"nombre":"FUNZA, FUNZA, CUNDINAMARCA"},
    {"id":2639,"nombre":"CAPELLANIA, FUQUENE, CUNDINAMARCA"},
    {"id":2640,"nombre":"FUQUENE, FUQUENE, CUNDINAMARCA"},
    {"id":2641,"nombre":"NUEVO FUQUENE, FUQUENE, CUNDINAMARCA"},
    {"id":2642,"nombre":"CHINAUTA LOS PANCHES, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2643,"nombre":"EL ESPINALITO, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2644,"nombre":"EL PLACER, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2645,"nombre":"EL TRIUNFO BOQUERON, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2646,"nombre":"FUSAGASUGA, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2647,"nombre":"GUAVIO ALTO, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2648,"nombre":"JORDAN 1, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2649,"nombre":"JORDAN 111, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2650,"nombre":"LA AGUADITA, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2651,"nombre":"LA ISLA, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2652,"nombre":"LA VENTA, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2653,"nombre":"LAS VIUDAS DEL SUR, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2654,"nombre":"PIAMONTE, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2655,"nombre":"TIERRA NEGRA, FUSAGASUGA, CUNDINAMARCA"},
    {"id":2656,"nombre":"EL GUAVIO, GACHALA, CUNDINAMARCA"},
    {"id":2657,"nombre":"GACHALA, GACHALA, CUNDINAMARCA"},
    {"id":2658,"nombre":"LOS ALPES, GACHALA, CUNDINAMARCA"},
    {"id":2659,"nombre":"MINAS, GACHALA, CUNDINAMARCA"},
    {"id":2660,"nombre":"MONTECRISTO, GACHALA, CUNDINAMARCA"},
    {"id":2661,"nombre":"PALOMAS, GACHALA, CUNDINAMARCA"},
    {"id":2662,"nombre":"SANTA RITA DEL RIO NEGRO, GACHALA, CUNDINAMARCA"},
    {"id":2663,"nombre":"EL ROBLE, GACHANCIPA, CUNDINAMARCA"},
    {"id":2664,"nombre":"GACHANCIPA, GACHANCIPA, CUNDINAMARCA"},
    {"id":2665,"nombre":"SAN BARTOLOME, GACHANCIPA, CUNDINAMARCA"},
    {"id":2666,"nombre":"SAN JOSE, GACHANCIPA, CUNDINAMARCA"},
    {"id":2667,"nombre":"SAN MARTIN, GACHANCIPA, CUNDINAMARCA"},
    {"id":2668,"nombre":"SANTA BARBARA, GACHANCIPA, CUNDINAMARCA"},
    {"id":2669,"nombre":"GACHANTIVA, GACHANTIVA, BOYACA"},
    {"id":2670,"nombre":"GACHETA, GACHETA, CUNDINAMARCA"},
    {"id":2671,"nombre":"LOS LOPEZ, GACHETA, CUNDINAMARCA"},
    {"id":2672,"nombre":"GALAN, GALAN, SANTANDER"},
    {"id":2673,"nombre":"GALAPA, GALAPA, ATLANTICO"},
    {"id":2674,"nombre":"PALUATO, GALAPA, ATLANTICO"},
    {"id":2675,"nombre":"BARAYA, GALERAS, SUCRE"},
    {"id":2676,"nombre":"NUEVA GRANADA, GALERAS, SUCRE"},
    {"id":2677,"nombre":"JUNIN, GALERAS, SUCRE"},
    {"id":2678,"nombre":"PUEBLO NUEVO PRIMERO, GALERAS, SUCRE"},
    {"id":2679,"nombre":"PUEBLO NUEVO SEGUNDO, GALERAS, SUCRE"},
    {"id":2680,"nombre":"RIVERA, GALERAS, SUCRE"},
    {"id":2681,"nombre":"SAN ANDRES DE PALOMO, GALERAS, SUCRE"},
    {"id":2682,"nombre":"SAN ANDRES, GALERAS, SUCRE"},
    {"id":2683,"nombre":"SAN JOSE DE RIVERA, GALERAS, SUCRE"},
    {"id":2684,"nombre":"TRES PUNTAS, GALERAS, SUCRE"},
    {"id":2685,"nombre":"GAMA, GAMA, CUNDINAMARCA"},
    {"id":2686,"nombre":"SAN ROQUE, GAMA, CUNDINAMARCA"},
    {"id":2687,"nombre":"CASCAJAL, GAMARRA, CESAR"},
    {"id":2688,"nombre":"EL CONTENTO, GAMARRA, CESAR"},
    {"id":2689,"nombre":"ESTACION, GAMARRA, CESAR"},
    {"id":2690,"nombre":"GAMARRA, GAMARRA, CESAR"},
    {"id":2691,"nombre":"PALENQUILLO, GAMARRA, CESAR"},
    {"id":2692,"nombre":"PUERTO MOSQUITO, GAMARRA, CESAR"},
    {"id":2693,"nombre":"PUERTO VIEJO, GAMARRA, CESAR"},
    {"id":2694,"nombre":"CORONTUJO, GAMBITA, SANTANDER"},
    {"id":2695,"nombre":"EL TALADRO, GAMBITA, SANTANDER"},
    {"id":2696,"nombre":"GAMBITA, GAMBITA, SANTANDER"},
    {"id":2697,"nombre":"LA PALMA, GAMBITA, SANTANDER"},
    {"id":2698,"nombre":"SAN RAFAEL, GAMBITA, SANTANDER"},
    {"id":2699,"nombre":"GAMEZA, GAMEZA, BOYACA"},
    {"id":2700,"nombre":"SAZA, GAMEZA, BOYACA"},
    {"id":2701,"nombre":"BANCOS DE PARA, GARAGOA, BOYACA"},
    {"id":2702,"nombre":"CALDERA ABAJO, GARAGOA, BOYACA"},
    {"id":2703,"nombre":"GARAGOA, GARAGOA, BOYACA"},
    {"id":2704,"nombre":"GUAYABAL, GARAGOA, BOYACA"},
    {"id":2705,"nombre":"LA VALVANERA, GARAGOA, BOYACA"},
    {"id":2706,"nombre":"CAGUANCITO, GARZON, HUILA"},
    {"id":2707,"nombre":"EL DESCANSO, GARZON, HUILA"},
    {"id":2708,"nombre":"EL MESON, GARZON, HUILA"},
    {"id":2709,"nombre":"EL PARAISO, GARZON, HUILA"},
    {"id":2710,"nombre":"EL RECREO, GARZON, HUILA"},
    {"id":2711,"nombre":"GARZON, GARZON, HUILA"},
    {"id":2712,"nombre":"LA JAGUA, GARZON, HUILA"},
    {"id":2713,"nombre":"MAJO, GARZON, HUILA"},
    {"id":2714,"nombre":"PLAZUELA, GARZON, HUILA"},
    {"id":2715,"nombre":"PROVIDENCIA, GARZON, HUILA"},
    {"id":2716,"nombre":"SAN ANTONIO DEL PESCADO, GARZON, HUILA"},
    {"id":2717,"nombre":"SAN GERARDO, GARZON, HUILA"},
    {"id":2718,"nombre":"SANTA MARTA, GARZON, HUILA"},
    {"id":2719,"nombre":"SARTENJO, GARZON, HUILA"},
    {"id":2720,"nombre":"ZULUAGA, GARZON, HUILA"},
    {"id":2721,"nombre":"GENOVA, GENOVA, QUINDIO"},
    {"id":2722,"nombre":"EL JARDIN, GIGANTE, HUILA"},
    {"id":2723,"nombre":"EL MESON, GIGANTE, HUILA"},
    {"id":2724,"nombre":"GIGANTE, GIGANTE, HUILA"},
    {"id":2725,"nombre":"LA CHIQUITA, GIGANTE, HUILA"},
    {"id":2726,"nombre":"LA GRAN VIA - EL PORVENIR, GIGANTE, HUILA"},
    {"id":2727,"nombre":"LA GRAN VIA, GIGANTE, HUILA"},
    {"id":2728,"nombre":"POTRERILLOS, GIGANTE, HUILA"},
    {"id":2729,"nombre":"PUEBLO NUEVO, GIGANTE, HUILA"},
    {"id":2730,"nombre":"RIOLORO, GIGANTE, HUILA"},
    {"id":2731,"nombre":"SAN JUANITO, GIGANTE, HUILA"},
    {"id":2732,"nombre":"SILVANIA, GIGANTE, HUILA"},
    {"id":2733,"nombre":"TRES ESQUINAS, GIGANTE, HUILA"},
    {"id":2734,"nombre":"VERACRUZ, GIGANTE, HUILA"},
    {"id":2735,"nombre":"VUELTAS ARRIBA, GIGANTE, HUILA"},
    {"id":2736,"nombre":"COCUYOS, GINEBRA, VALLE DEL CAUCA"},
    {"id":2737,"nombre":"COSTA RICA, GINEBRA, VALLE DEL CAUCA"},
    {"id":2738,"nombre":"GINEBRA, GINEBRA, VALLE DEL CAUCA"},
    {"id":2739,"nombre":"JUNTAS, GINEBRA, VALLE DEL CAUCA"},
    {"id":2740,"nombre":"LA FLORESTA, GINEBRA, VALLE DEL CAUCA"},
    {"id":2741,"nombre":"LA NOVILLERA, GINEBRA, VALLE DEL CAUCA"},
    {"id":2742,"nombre":"LOS MEDIOS, GINEBRA, VALLE DEL CAUCA"},
    {"id":2743,"nombre":"ZABALETAS, GINEBRA, VALLE DEL CAUCA"},
    {"id":2744,"nombre":"GIRALDO, GIRALDO, ANTIOQUIA"},
    {"id":2745,"nombre":"MANGLAR, GIRALDO, ANTIOQUIA"},
    {"id":2746,"nombre":"PINGURTO, GIRALDO, ANTIOQUIA"},
    {"id":2747,"nombre":"BARLAZA, GIRARDOT, CUNDINAMARCA"},
    {"id":2748,"nombre":"BERLIN, GIRARDOT, CUNDINAMARCA"},
    {"id":2749,"nombre":"GIRARDOT, GIRARDOT, CUNDINAMARCA"},
    {"id":2750,"nombre":"GUABINAL, GIRARDOT, CUNDINAMARCA"},
    {"id":2751,"nombre":"SAN LORENZO, GIRARDOT, CUNDINAMARCA"},
    {"id":2752,"nombre":"ZUMBAMICOS, GIRARDOT, CUNDINAMARCA"},
    {"id":2753,"nombre":"GIRARDOTA, GIRARDOTA, ANTIOQUIA"},
    {"id":2754,"nombre":"JUAN COJO, GIRARDOTA, ANTIOQUIA"},
    {"id":2755,"nombre":"SAN ANDRES, GIRARDOTA, ANTIOQUIA"},
    {"id":2756,"nombre":"ACAPULCO, GIRON, SANTANDER"},
    {"id":2757,"nombre":"BOCAS, GIRON, SANTANDER"},
    {"id":2758,"nombre":"CHOCOITA, GIRON, SANTANDER"},
    {"id":2759,"nombre":"EL LINDERO, GIRON, SANTANDER"},
    {"id":2760,"nombre":"EL RINCON DE GIRON, GIRON, SANTANDER"},
    {"id":2761,"nombre":"EL TABLAZO, GIRON, SANTANDER"},
    {"id":2762,"nombre":"GIRON, GIRON, SANTANDER"},
    {"id":2763,"nombre":"MALPASO, GIRON, SANTANDER"},
    {"id":2764,"nombre":"MARTA, GIRON, SANTANDER"},
    {"id":2765,"nombre":"RIO PRADO, GIRON, SANTANDER"},
    {"id":2766,"nombre":"SAN JOSE DE MOTOSO, GIRON, SANTANDER"},
    {"id":2767,"nombre":"SAN LUIS DE RIO SUCIO, GIRON, SANTANDER"},
    {"id":2768,"nombre":"EL SALTO, GOMEZ PLATA, ANTIOQUIA"},
    {"id":2769,"nombre":"GOMEZ PLATA, GOMEZ PLATA, ANTIOQUIA"},
    {"id":2770,"nombre":"LA ESTRELLA, GOMEZ PLATA, ANTIOQUIA"},
    {"id":2771,"nombre":"SAN MATIAS, GOMEZ PLATA, ANTIOQUIA"},
    {"id":2772,"nombre":"BIJAGUAL, GONZALEZ, CESAR"},
    {"id":2773,"nombre":"BUJARAVITA, GONZALEZ, CESAR"},
    {"id":2774,"nombre":"BURBURA, GONZALEZ, CESAR"},
    {"id":2775,"nombre":"CULEBRITA, GONZALEZ, CESAR"},
    {"id":2776,"nombre":"EL POTRERO, GONZALEZ, CESAR"},
    {"id":2777,"nombre":"GONZALEZ, GONZALEZ, CESAR"},
    {"id":2778,"nombre":"MATA DE FIQUE, GONZALEZ, CESAR"},
    {"id":2779,"nombre":"MONTERA, GONZALEZ, CESAR"},
    {"id":2780,"nombre":"SAN ISIDRO, GONZALEZ, CESAR"},
    {"id":2781,"nombre":"EL ROSARIO, GRAMALOTE, NORTE DE SANTANDER"},
    {"id":2782,"nombre":"EL ZUMBADOR, GRAMALOTE, NORTE DE SANTANDER"},
    {"id":2783,"nombre":"GRAMALOTE, GRAMALOTE, NORTE DE SANTANDER"},
    {"id":2784,"nombre":"SAN ISIDRO, GRAMALOTE, NORTE DE SANTANDER"},
    {"id":2785,"nombre":"SANTA TERESITA, GRAMALOTE, NORTE DE SANTANDER"},
    {"id":2786,"nombre":"BARAYA, GUACA, SANTANDER"},
    {"id":2787,"nombre":"BARIA, GUACA, SANTANDER"},
    {"id":2788,"nombre":"CAMARA, GUACA, SANTANDER"},
    {"id":2789,"nombre":"EL PALMAR, GUACA, SANTANDER"},
    {"id":2790,"nombre":"EL PORTILLO, GUACA, SANTANDER"},
    {"id":2791,"nombre":"EL RETIRO, GUACA, SANTANDER"},
    {"id":2792,"nombre":"GUACA, GUACA, SANTANDER"},
    {"id":2793,"nombre":"MATA DE LATA, GUACA, SANTANDER"},
    {"id":2794,"nombre":"NUCUBUCA, GUACA, SANTANDER"},
    {"id":2795,"nombre":"NUEVA GRANADA, GUACA, SANTANDER"},
    {"id":2796,"nombre":"POTRERO GRANDE, GUACA, SANTANDER"},
    {"id":2797,"nombre":"QUEBRADAS, GUACA, SANTANDER"},
    {"id":2798,"nombre":"SISOTA, GUACA, SANTANDER"},
    {"id":2799,"nombre":"TABACAL, GUACA, SANTANDER"},
    {"id":2800,"nombre":"CHICHIMITA, GUACAMAYAS, BOYACA"},
    {"id":2801,"nombre":"GUACAMAYAS, GUACAMAYAS, BOYACA"},
    {"id":2802,"nombre":"GUIRAGON, GUACAMAYAS, BOYACA"},
    {"id":2803,"nombre":"LA PALMA, GUACAMAYAS, BOYACA"},
    {"id":2804,"nombre":"ALTO DE GUACAS, GUACARI, VALLE DEL CAUCA"},
    {"id":2805,"nombre":"ALTO DE LA JULIA, GUACARI, VALLE DEL CAUCA"},
    {"id":2806,"nombre":"CANANGUA, GUACARI, VALLE DEL CAUCA"},
    {"id":2807,"nombre":"EL PLACER, GUACARI, VALLE DEL CAUCA"},
    {"id":2808,"nombre":"EL TRIUNFO, GUACARI, VALLE DEL CAUCA"},
    {"id":2809,"nombre":"GUABAS, GUACARI, VALLE DEL CAUCA"},
    {"id":2810,"nombre":"GUABITAS, GUACARI, VALLE DEL CAUCA"},
    {"id":2811,"nombre":"GUACARI, GUACARI, VALLE DEL CAUCA"},
    {"id":2812,"nombre":"GUACAS, GUACARI, VALLE DEL CAUCA"},
    {"id":2813,"nombre":"LA MAGDALENA, GUACARI, VALLE DEL CAUCA"},
    {"id":2814,"nombre":"PICHICHI, GUACARI, VALLE DEL CAUCA"},
    {"id":2815,"nombre":"PUENTE ROJO, GUACARI, VALLE DEL CAUCA"},
    {"id":2816,"nombre":"SANTA ROSA DE TAPIAS, GUACARI, VALLE DEL CAUCA"},
    {"id":2817,"nombre":"SONSO, GUACARI, VALLE DEL CAUCA"},
    {"id":2818,"nombre":"FRONTERA, GUACHETA, CUNDINAMARCA"},
    {"id":2819,"nombre":"GACHA, GUACHETA, CUNDINAMARCA"},
    {"id":2820,"nombre":"GUACHETA, GUACHETA, CUNDINAMARCA"},
    {"id":2821,"nombre":"MONROY, GUACHETA, CUNDINAMARCA"},
    {"id":2822,"nombre":"NENGUA, GUACHETA, CUNDINAMARCA"},
    {"id":2823,"nombre":"PUEBLO VIEJO, GUACHETA, CUNDINAMARCA"},
    {"id":2824,"nombre":"PUNTA GRANDE, GUACHETA, CUNDINAMARCA"},
    {"id":2825,"nombre":"CHILLANQUER - EL CONSUELO, GUACHUCAL, NARIÑO"},
    {"id":2826,"nombre":"COLIMBA, GUACHUCAL, NARIÑO"},
    {"id":2827,"nombre":"GUACHUCAL, GUACHUCAL, NARIÑO"},
    {"id":2828,"nombre":"LA VICTORIA, GUACHUCAL, NARIÑO"},
    {"id":2829,"nombre":"MUELLAMUES, GUACHUCAL, NARIÑO"},
    {"id":2830,"nombre":"SAN JOSE DE CHILLANQUER, GUACHUCAL, NARIÑO"},
    {"id":2831,"nombre":"ALASKA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2832,"nombre":"CHAMBIMBAL, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2833,"nombre":"EL CRUCERO - NOGALES, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2834,"nombre":"EL PLACER, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2835,"nombre":"EL PORVENIR, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2836,"nombre":"EL ROSARIO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2837,"nombre":"EL SALADO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2838,"nombre":"EL VINCULO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2839,"nombre":"FRISOLES, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2840,"nombre":"GUADALAJARA DE BUGA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2841,"nombre":"LA HABANA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2842,"nombre":"LA MAGDALENA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2843,"nombre":"LA MARIA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2844,"nombre":"LA PLAYA DEL BUEY, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2845,"nombre":"LOS BANCOS, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2846,"nombre":"MIRAFLORES, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2847,"nombre":"MONTERREY, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2848,"nombre":"PUEBLO NUEVO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2849,"nombre":"QUEBRADASECA, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2850,"nombre":"RIOLORO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2851,"nombre":"SANJON HONDO, GUADALAJARA DE BUGA, VALLE DEL CAUCA"},
    {"id":2852,"nombre":"GUADUAS, GUADUAS, CUNDINAMARCA"},
    {"id":2853,"nombre":"GUADUERO, GUADUAS, CUNDINAMARCA"},
    {"id":2854,"nombre":"LA PAZ, GUADUAS, CUNDINAMARCA"},
    {"id":2855,"nombre":"PUERTO BOGOTA, GUADUAS, CUNDINAMARCA"},
    {"id":2856,"nombre":"AHUMADA, GUAITARILLA, NARIÑO"},
    {"id":2857,"nombre":"ALEX, GUAITARILLA, NARIÑO"},
    {"id":2858,"nombre":"BUENOS AIRES, GUAITARILLA, NARIÑO"},
    {"id":2859,"nombre":"CUATRO ESQUINAS, GUAITARILLA, NARIÑO"},
    {"id":2860,"nombre":"EL CID, GUAITARILLA, NARIÑO"},
    {"id":2861,"nombre":"EL ROSAL, GUAITARILLA, NARIÑO"},
    {"id":2862,"nombre":"ESPERANZA, GUAITARILLA, NARIÑO"},
    {"id":2863,"nombre":"GIRARDOT, GUAITARILLA, NARIÑO"},
    {"id":2864,"nombre":"GUAITARILLA, GUAITARILLA, NARIÑO"},
    {"id":2865,"nombre":"LA VICTORIA, GUAITARILLA, NARIÑO"},
    {"id":2866,"nombre":"SAN ALEJANDRO, GUAITARILLA, NARIÑO"},
    {"id":2867,"nombre":"SAN NICOLAS, GUAITARILLA, NARIÑO"},
    {"id":2868,"nombre":"CUATIS, GUALMATAN, NARIÑO"},
    {"id":2869,"nombre":"GUALMATAN, GUALMATAN, NARIÑO"},
    {"id":2870,"nombre":"BARROSO, GUAMO, TOLIMA"},
    {"id":2871,"nombre":"CANADA RODEO, GUAMO, TOLIMA"},
    {"id":2872,"nombre":"CERROGORDO, GUAMO, TOLIMA"},
    {"id":2873,"nombre":"CHIPUELO ORIENTE, GUAMO, TOLIMA"},
    {"id":2874,"nombre":"CHONTADURO, GUAMO, TOLIMA"},
    {"id":2875,"nombre":"EL JARDIN, GUAMO, TOLIMA"},
    {"id":2876,"nombre":"GUAMAL, GUAMO, TOLIMA"},
    {"id":2877,"nombre":"GUAMO, GUAMO, TOLIMA"},
    {"id":2878,"nombre":"LA CHAMBA, GUAMO, TOLIMA"},
    {"id":2879,"nombre":"LA TROJA, GUAMO, TOLIMA"},
    {"id":2880,"nombre":"LAS MERCEDES, GUAMO, TOLIMA"},
    {"id":2881,"nombre":"LOMA DE LUISA, GUAMO, TOLIMA"},
    {"id":2882,"nombre":"PUEBLO NUEVO, GUAMO, TOLIMA"},
    {"id":2883,"nombre":"QUINTO CHIPUELO, GUAMO, TOLIMA"},
    {"id":2884,"nombre":"RINCON SANTO CENTRO, GUAMO, TOLIMA"},
    {"id":2885,"nombre":"SERREZUELA GARZAS, GUAMO, TOLIMA"},
    {"id":2886,"nombre":"TOVAR, GUAMO, TOLIMA"},
    {"id":2887,"nombre":"ALFONSO LOPEZ (BALSITAS), GUAPI, CAUCA"},
    {"id":2888,"nombre":"BENJAMIN HERRERA (SAN VICENTE), GUAPI, CAUCA"},
    {"id":2889,"nombre":"BOCA DE NAPI, GUAPI, CAUCA"},
    {"id":2890,"nombre":"CALLELARGA, GUAPI, CAUCA"},
    {"id":2891,"nombre":"CASCAJERO, GUAPI, CAUCA"},
    {"id":2892,"nombre":"CHAMON, GUAPI, CAUCA"},
    {"id":2893,"nombre":"CHANZARA, GUAPI, CAUCA"},
    {"id":2894,"nombre":"CHUARE, GUAPI, CAUCA"},
    {"id":2895,"nombre":"CONCEPCION DE GUAJUI, GUAPI, CAUCA"},
    {"id":2896,"nombre":"EL ATAJO, GUAPI, CAUCA"},
    {"id":2897,"nombre":"EL CARMELO, GUAPI, CAUCA"},
    {"id":2898,"nombre":"GUAPI, GUAPI, CAUCA"},
    {"id":2899,"nombre":"ISLAS GORGONA, GUAPI, CAUCA"},
    {"id":2900,"nombre":"LA SOLEDAD, GUAPI, CAUCA"},
    {"id":2901,"nombre":"LAS JUNTA, GUAPI, CAUCA"},
    {"id":2902,"nombre":"LIMONES, GUAPI, CAUCA"},
    {"id":2903,"nombre":"LLANTIN, GUAPI, CAUCA"},
    {"id":2904,"nombre":"NAPI, GUAPI, CAUCA"},
    {"id":2905,"nombre":"OLAYA HERRERA (GUARE), GUAPI, CAUCA"},
    {"id":2906,"nombre":"QUIROGA, GUAPI, CAUCA"},
    {"id":2907,"nombre":"ROSARIO, GUAPI, CAUCA"},
    {"id":2908,"nombre":"SAN AGUSTIN, GUAPI, CAUCA"},
    {"id":2909,"nombre":"SAN ANTONIO DE GUAJUI, GUAPI, CAUCA"},
    {"id":2910,"nombre":"SAN FRANCISCO, GUAPI, CAUCA"},
    {"id":2911,"nombre":"SAN JOSE DE GUAPI, GUAPI, CAUCA"},
    {"id":2912,"nombre":"SANTA CLARA, GUAPI, CAUCA"},
    {"id":2913,"nombre":"URIBE URIBE (EL NARANJO), GUAPI, CAUCA"},
    {"id":2914,"nombre":"GUAPOTA, GUAPOTA, SANTANDER"},
    {"id":2915,"nombre":"PILAS, GUAPOTA, SANTANDER"},
    {"id":2916,"nombre":"DIAZGRANADOS, GUARANDA, SUCRE"},
    {"id":2917,"nombre":"GAVALDA, GUARANDA, SUCRE"},
    {"id":2918,"nombre":"GUARANDA, GUARANDA, SUCRE"},
    {"id":2919,"nombre":"LA CONCORDIA, GUARANDA, SUCRE"},
    {"id":2920,"nombre":"PALMARITICO, GUARANDA, SUCRE"},
    {"id":2921,"nombre":"PUERTO LOPEZ, GUARANDA, SUCRE"},
    {"id":2922,"nombre":"GUARNE, GUARNE, ANTIOQUIA"},
    {"id":2923,"nombre":"TOLDAS - HONDA, GUARNE, ANTIOQUIA"},
    {"id":2924,"nombre":"EL SANTUARIO, GUASCA, CUNDINAMARCA"},
    {"id":2925,"nombre":"GUASCA, GUASCA, CUNDINAMARCA"},
    {"id":2926,"nombre":"LA CABRERITA, GUASCA, CUNDINAMARCA"},
    {"id":2927,"nombre":"EL ROBLE, GUATAPE, ANTIOQUIA"},
    {"id":2928,"nombre":"GUATAPE, GUATAPE, ANTIOQUIA"},
    {"id":2929,"nombre":"LA PIEDRA, GUATAPE, ANTIOQUIA"},
    {"id":2930,"nombre":"EL PORVENIR, GUATAQUI, CUNDINAMARCA"},
    {"id":2931,"nombre":"GUATAQUI, GUATAQUI, CUNDINAMARCA"},
    {"id":2932,"nombre":"GUATAVITA, GUATAVITA, CUNDINAMARCA"},
    {"id":2933,"nombre":"CHINQUICO, GUATEQUE, BOYACA"},
    {"id":2934,"nombre":"CHORRO DE ORO, GUATEQUE, BOYACA"},
    {"id":2935,"nombre":"GAUNZA ABAJO, GUATEQUE, BOYACA"},
    {"id":2936,"nombre":"GAUNZA ARRIBA, GUATEQUE, BOYACA"},
    {"id":2937,"nombre":"GUATEQUE, GUATEQUE, BOYACA"},
    {"id":2938,"nombre":"LLANO GRANDE, GUATEQUE, BOYACA"},
    {"id":2939,"nombre":"PIEDRA PARADA, GUATEQUE, BOYACA"},
    {"id":2940,"nombre":"ROSALES, GUATEQUE, BOYACA"},
    {"id":2941,"nombre":"SIBATE, GUATEQUE, BOYACA"},
    {"id":2942,"nombre":"BETANIA, GUATICA, RISARALDA"},
    {"id":2943,"nombre":"EL JORDAN, GUATICA, RISARALDA"},
    {"id":2944,"nombre":"EL PARAISO, GUATICA, RISARALDA"},
    {"id":2945,"nombre":"EL SILENCIO, GUATICA, RISARALDA"},
    {"id":2946,"nombre":"GUATICA, GUATICA, RISARALDA"},
    {"id":2947,"nombre":"GUATICA VIEJO, GUATICA, RISARALDA"},
    {"id":2948,"nombre":"OSPIRMA, GUATICA, RISARALDA"},
    {"id":2949,"nombre":"SAN CLEMENTE, GUATICA, RISARALDA"},
    {"id":2950,"nombre":"SANTA ANA, GUATICA, RISARALDA"},
    {"id":2951,"nombre":"TARQUI, GUATICA, RISARALDA"},
    {"id":2952,"nombre":"TRAVESIAS, GUATICA, RISARALDA"},
    {"id":2953,"nombre":"GUAVATA, GUAVATA, SANTANDER"},
    {"id":2954,"nombre":"MATA REDONDA, GUAVATA, SANTANDER"},
    {"id":2955,"nombre":"MERCADILLO, GUAVATA, SANTANDER"},
    {"id":2956,"nombre":"PAVA CHOQUE, GUAVATA, SANTANDER"},
    {"id":2957,"nombre":"POPOA NORTE, GUAVATA, SANTANDER"},
    {"id":2958,"nombre":"ALTO DEL TRIGO, GUAYABAL DE SIQUIMA, CUNDINAMARCA"},
    {"id":2959,"nombre":"GUAYABAL DE SIQUIMA, GUAYABAL DE SIQUIMA, CUNDINAMARCA"},
    {"id":2960,"nombre":"LA TRINIDAD, GUAYABAL DE SIQUIMA, CUNDINAMARCA"},
    {"id":2961,"nombre":"GUAYABETAL, GUAYABETAL, CUNDINAMARCA"},
    {"id":2962,"nombre":"MONTERREDONDO, GUAYABETAL, CUNDINAMARCA"},
    {"id":2963,"nombre":"TUNQUE DEL NARANJAL, GUAYABETAL, CUNDINAMARCA"},
    {"id":2964,"nombre":"GUAYATA, GUAYATA, BOYACA"},
    {"id":2965,"nombre":"SUNUBA, GUAYATA, BOYACA"},
    {"id":2966,"nombre":"GUTIERREZ, GUTIERREZ, CUNDINAMARCA"},
    {"id":2967,"nombre":"PASCOTE, GUTIERREZ, CUNDINAMARCA"},
    {"id":2968,"nombre":"SAN ANTONIO, GUTIERREZ, CUNDINAMARCA"},
    {"id":2969,"nombre":"ASTILLEROS, HACARI, NORTE DE SANTANDER"},
    {"id":2970,"nombre":"HACARI, HACARI, NORTE DE SANTANDER"},
    {"id":2971,"nombre":"LAS JUNTAS, HACARI, NORTE DE SANTANDER"},
    {"id":2972,"nombre":"LOS LAURELES, HACARI, NORTE DE SANTANDER"},
    {"id":2973,"nombre":"MARACAIBO, HACARI, NORTE DE SANTANDER"},
    {"id":2974,"nombre":"MESITAS, HACARI, NORTE DE SANTANDER"},
    {"id":2975,"nombre":"SAN JOSE DEL TARRA, HACARI, NORTE DE SANTANDER"},
    {"id":2976,"nombre":"EL CERRO DE LAS AGUADAS, HATILLO DE LOBA, BOLIVAR"},
    {"id":2977,"nombre":"EL POZON, HATILLO DE LOBA, BOLIVAR"},
    {"id":2978,"nombre":"HATILLO DE LOBA, HATILLO DE LOBA, BOLIVAR"},
    {"id":2979,"nombre":"JUANA SANCHEZ, HATILLO DE LOBA, BOLIVAR"},
    {"id":2980,"nombre":"LA RIBONA, HATILLO DE LOBA, BOLIVAR"},
    {"id":2981,"nombre":"LA VICTORIA, HATILLO DE LOBA, BOLIVAR"},
    {"id":2982,"nombre":"LAS BRISAS, HATILLO DE LOBA, BOLIVAR"},
    {"id":2983,"nombre":"PUEBLO NUEVO, HATILLO DE LOBA, BOLIVAR"},
    {"id":2984,"nombre":"SAN MIGUEL, HATILLO DE LOBA, BOLIVAR"},
    {"id":2985,"nombre":"HATO, HATO, SANTANDER"},
    {"id":2986,"nombre":"BERLIN, HATO COROZAL, CASANARE"},
    {"id":2987,"nombre":"CHIRE, HATO COROZAL, CASANARE"},
    {"id":2988,"nombre":"CORRALITO, HATO COROZAL, CASANARE"},
    {"id":2989,"nombre":"EL GUAFAL, HATO COROZAL, CASANARE"},
    {"id":2990,"nombre":"HATO COROZAL, HATO COROZAL, CASANARE"},
    {"id":2991,"nombre":"LA FRONTERA - LA CHAPA, HATO COROZAL, CASANARE"},
    {"id":2992,"nombre":"LAS CAMELIAS, HATO COROZAL, CASANARE"},
    {"id":2993,"nombre":"LAS TAPIAS, HATO COROZAL, CASANARE"},
    {"id":2994,"nombre":"MANARE, HATO COROZAL, CASANARE"},
    {"id":2995,"nombre":"PASO REAL DE ARIPORO, HATO COROZAL, CASANARE"},
    {"id":2996,"nombre":"PUERTO COLOMBIA, HATO COROZAL, CASANARE"},
    {"id":2997,"nombre":"SAN JOSE DE ARIPORO, HATO COROZAL, CASANARE"},
    {"id":2998,"nombre":"SAN NICOLAS, HATO COROZAL, CASANARE"},
    {"id":2999,"nombre":"SAN SALVADOR, HATO COROZAL, CASANARE"},
    {"id":3000,"nombre":"SANTA BARBARA, HATO COROZAL, CASANARE"},
    {"id":3001,"nombre":"SANTA RITA, HATO COROZAL, CASANARE"},
    {"id":3002,"nombre":"HATONUEVO, HATONUEVO, LA GUAJIRA"},
    {"id":3003,"nombre":"TABACO, HATONUEVO, LA GUAJIRA"},
    {"id":3004,"nombre":"ALTO DEL CORRAL, HELICONIA, ANTIOQUIA"},
    {"id":3005,"nombre":"EL HATILLO, HELICONIA, ANTIOQUIA"},
    {"id":3006,"nombre":"HELICONIA, HELICONIA, ANTIOQUIA"},
    {"id":3007,"nombre":"LA CHORRERA, HELICONIA, ANTIOQUIA"},
    {"id":3008,"nombre":"LA PAVA, HELICONIA, ANTIOQUIA"},
    {"id":3009,"nombre":"LLANOS DE SAN JOSE, HELICONIA, ANTIOQUIA"},
    {"id":3010,"nombre":"PUEBLITO, HELICONIA, ANTIOQUIA"},
    {"id":3011,"nombre":"HERRAN, HERRAN, NORTE DE SANTANDER"},
    {"id":3012,"nombre":"HONDA SUR, HERRAN, NORTE DE SANTANDER"},
    {"id":3013,"nombre":"LA SIERRA, HERRAN, NORTE DE SANTANDER"},
    {"id":3014,"nombre":"SIBERIA, HERRAN, NORTE DE SANTANDER"},
    {"id":3015,"nombre":"ARENILLO, HERVEO, TOLIMA"},
    {"id":3016,"nombre":"BRASIL, HERVEO, TOLIMA"},
    {"id":3017,"nombre":"HERVEO, HERVEO, TOLIMA"},
    {"id":3018,"nombre":"LA PICOTA, HERVEO, TOLIMA"},
    {"id":3019,"nombre":"LETRAS, HERVEO, TOLIMA"},
    {"id":3020,"nombre":"MESONES, HERVEO, TOLIMA"},
    {"id":3021,"nombre":"PADUA, HERVEO, TOLIMA"},
    {"id":3022,"nombre":"HISPANIA, HISPANIA, ANTIOQUIA"},
    {"id":3023,"nombre":"HOYO GRANDE (LLANETE), HISPANIA, ANTIOQUIA"},
    {"id":3024,"nombre":"HOBO, HOBO, HUILA"},
    {"id":3025,"nombre":"HONDA, HONDA, TOLIMA"},
    {"id":3026,"nombre":"ALASKA, IBAGUE, TOLIMA"},
    {"id":3027,"nombre":"ALTO DE GUALANDAY, IBAGUE, TOLIMA"},
    {"id":3028,"nombre":"AMBALA, IBAGUE, TOLIMA"},
    {"id":3029,"nombre":"APARCO, IBAGUE, TOLIMA"},
    {"id":3030,"nombre":"AURES, IBAGUE, TOLIMA"},
    {"id":3031,"nombre":"BRICENO, IBAGUE, TOLIMA"},
    {"id":3032,"nombre":"BUENOS AIRES, IBAGUE, TOLIMA"},
    {"id":3033,"nombre":"CALAMBEO, IBAGUE, TOLIMA"},
    {"id":3034,"nombre":"CARMEN DE BULIRA, IBAGUE, TOLIMA"},
    {"id":3035,"nombre":"CARRIZALES, IBAGUE, TOLIMA"},
    {"id":3036,"nombre":"CAY, IBAGUE, TOLIMA"},
    {"id":3037,"nombre":"CHAPETON, IBAGUE, TOLIMA"},
    {"id":3038,"nombre":"CHARCO RICO, IBAGUE, TOLIMA"},
    {"id":3039,"nombre":"CHEMBE, IBAGUE, TOLIMA"},
    {"id":3040,"nombre":"CHUCUNI, IBAGUE, TOLIMA"},
    {"id":3041,"nombre":"COELLO - COCORA, IBAGUE, TOLIMA"},
    {"id":3042,"nombre":"DANTAS, IBAGUE, TOLIMA"},
    {"id":3043,"nombre":"DANTAS LAS PAVAS, IBAGUE, TOLIMA"},
    {"id":3044,"nombre":"EL COLEGIO, IBAGUE, TOLIMA"},
    {"id":3045,"nombre":"EL RODEO, IBAGUE, TOLIMA"},
    {"id":3046,"nombre":"EL SALADO, IBAGUE, TOLIMA"},
    {"id":3047,"nombre":"EL TOTUMO, IBAGUE, TOLIMA"},
    {"id":3048,"nombre":"EL TRIUNFO, IBAGUE, TOLIMA"},
    {"id":3049,"nombre":"GAMBOA CURALITO, IBAGUE, TOLIMA"},
    {"id":3050,"nombre":"GAMBOA, IBAGUE, TOLIMA"},
    {"id":3051,"nombre":"HONDURAS, IBAGUE, TOLIMA"},
    {"id":3052,"nombre":"IBAGUE, IBAGUE, TOLIMA"},
    {"id":3053,"nombre":"JUNTAS, IBAGUE, TOLIMA"},
    {"id":3054,"nombre":"LA ESPERANZA, IBAGUE, TOLIMA"},
    {"id":3055,"nombre":"LA FLOR, IBAGUE, TOLIMA"},
    {"id":3056,"nombre":"LA FLORIDA, IBAGUE, TOLIMA"},
    {"id":3057,"nombre":"LA HELENA, IBAGUE, TOLIMA"},
    {"id":3058,"nombre":"LA MARIA, IBAGUE, TOLIMA"},
    {"id":3059,"nombre":"LA MIEL, IBAGUE, TOLIMA"},
    {"id":3060,"nombre":"LA PALMILLA, IBAGUE, TOLIMA"},
    {"id":3061,"nombre":"LAURELES, IBAGUE, TOLIMA"},
    {"id":3062,"nombre":"LLANITOS, IBAGUE, TOLIMA"},
    {"id":3063,"nombre":"LLANO DEL COMBEIMA, IBAGUE, TOLIMA"},
    {"id":3064,"nombre":"LOS PASTOS, IBAGUE, TOLIMA"},
    {"id":3065,"nombre":"PASTALES NUEVO, IBAGUE, TOLIMA"},
    {"id":3066,"nombre":"PASTALES VIEJO, IBAGUE, TOLIMA"},
    {"id":3067,"nombre":"PENARANDA PARTE ALTA, IBAGUE, TOLIMA"},
    {"id":3068,"nombre":"RODEITO, IBAGUE, TOLIMA"},
    {"id":3069,"nombre":"SAN BERNARDO, IBAGUE, TOLIMA"},
    {"id":3070,"nombre":"SAN CRISTOBAL, IBAGUE, TOLIMA"},
    {"id":3071,"nombre":"SAN FRANCISCO, IBAGUE, TOLIMA"},
    {"id":3072,"nombre":"SAN JUAN DE LA CHINA, IBAGUE, TOLIMA"},
    {"id":3073,"nombre":"SANTA ANA, IBAGUE, TOLIMA"},
    {"id":3074,"nombre":"SANTA TERESA, IBAGUE, TOLIMA"},
    {"id":3075,"nombre":"TAPIAS, IBAGUE, TOLIMA"},
    {"id":3076,"nombre":"TOCHE, IBAGUE, TOLIMA"},
    {"id":3077,"nombre":"VILLARRESTREPO, IBAGUE, TOLIMA"},
    {"id":3078,"nombre":"BALCONCITOS, ICONONZO, TOLIMA"},
    {"id":3079,"nombre":"BOQUERON, ICONONZO, TOLIMA"},
    {"id":3080,"nombre":"ICONONZO, ICONONZO, TOLIMA"},
    {"id":3081,"nombre":"MUNDO NUEVO, ICONONZO, TOLIMA"},
    {"id":3082,"nombre":"PATICUINDE, ICONONZO, TOLIMA"},
    {"id":3083,"nombre":"BOLIVAR, ILES, NARIÑO"},
    {"id":3084,"nombre":"EL ALTO DEL REY, ILES, NARIÑO"},
    {"id":3085,"nombre":"EL TABLON, ILES, NARIÑO"},
    {"id":3086,"nombre":"ILES, ILES, NARIÑO"},
    {"id":3087,"nombre":"SAN FRANCISCO, ILES, NARIÑO"},
    {"id":3088,"nombre":"SAN JAVIER, ILES, NARIÑO"},
    {"id":3089,"nombre":"BETANIA, IMUES, NARIÑO"},
    {"id":3090,"nombre":"CAMUESTES, IMUES, NARIÑO"},
    {"id":3091,"nombre":"CUARCHU, IMUES, NARIÑO"},
    {"id":3092,"nombre":"EL ALISAL, IMUES, NARIÑO"},
    {"id":3093,"nombre":"IMUES, IMUES, NARIÑO"},
    {"id":3094,"nombre":"NEIRA, IMUES, NARIÑO"},
    {"id":3095,"nombre":"NEIRA SANTA ANA, IMUES, NARIÑO"},
    {"id":3096,"nombre":"PEDREGAL, IMUES, NARIÑO"},
    {"id":3097,"nombre":"PILCUAN RECIA, IMUES, NARIÑO"},
    {"id":3098,"nombre":"PILCUAN VIEJO 2, IMUES, NARIÑO"},
    {"id":3099,"nombre":"SAN BUENAVENTURA, IMUES, NARIÑO"},
    {"id":3100,"nombre":"VALENCIA, IMUES, NARIÑO"},
    {"id":3101,"nombre":"BARRANCO PICURE, INIRIDA, GUAINIA"},
    {"id":3102,"nombre":"BARRANCO TIGRE, INIRIDA, GUAINIA"},
    {"id":3103,"nombre":"CHAGUITA, INIRIDA, GUAINIA"},
    {"id":3104,"nombre":"COAYARE, INIRIDA, GUAINIA"},
    {"id":3105,"nombre":"COCONUEVO, INIRIDA, GUAINIA"},
    {"id":3106,"nombre":"EL COCO, INIRIDA, GUAINIA"},
    {"id":3107,"nombre":"GUASACAVI, INIRIDA, GUAINIA"},
    {"id":3108,"nombre":"INIRIDA, INIRIDA, GUAINIA"},
    {"id":3109,"nombre":"PUERTO CAMANAOS, INIRIDA, GUAINIA"},
    {"id":3110,"nombre":"SAN FELIPE, INIRIDA, GUAINIA"},
    {"id":3111,"nombre":"YURI, INIRIDA, GUAINIA"},
    {"id":3112,"nombre":"CALDERAS, INZA, CAUCA"},
    {"id":3113,"nombre":"CARMEN DE VIBORAL, INZA, CAUCA"},
    {"id":3114,"nombre":"GUANACAS, INZA, CAUCA"},
    {"id":3115,"nombre":"INZA, INZA, CAUCA"},
    {"id":3116,"nombre":"LOS ALPES, INZA, CAUCA"},
    {"id":3117,"nombre":"PEDREGAL, INZA, CAUCA"},
    {"id":3118,"nombre":"PUERTO VALENCIA, INZA, CAUCA"},
    {"id":3119,"nombre":"SAN ANDRES, INZA, CAUCA"},
    {"id":3120,"nombre":"SAN JOSE, INZA, CAUCA"},
    {"id":3121,"nombre":"SAN MIGUEL, INZA, CAUCA"},
    {"id":3122,"nombre":"SANTA ROSA, INZA, CAUCA"},
    {"id":3123,"nombre":"SANTA TERESA, INZA, CAUCA"},
    {"id":3124,"nombre":"SEGOVIA, INZA, CAUCA"},
    {"id":3125,"nombre":"TOPA, INZA, CAUCA"},
    {"id":3126,"nombre":"TUMBICHUCUE, INZA, CAUCA"},
    {"id":3127,"nombre":"TURMINA, INZA, CAUCA"},
    {"id":3128,"nombre":"CHIRES BAJO, IPIALES, NARIÑO"},
    {"id":3129,"nombre":"CHIRES CENTRO, IPIALES, NARIÑO"},
    {"id":3130,"nombre":"IPIALES, IPIALES, NARIÑO"},
    {"id":3131,"nombre":"LA VICTORIA, IPIALES, NARIÑO"},
    {"id":3132,"nombre":"LAS CRUCES, IPIALES, NARIÑO"},
    {"id":3133,"nombre":"LAS LAJAS, IPIALES, NARIÑO"},
    {"id":3134,"nombre":"SAN ANTONIO, IPIALES, NARIÑO"},
    {"id":3135,"nombre":"SAN JUAN, IPIALES, NARIÑO"},
    {"id":3136,"nombre":"YARAMAL, IPIALES, NARIÑO"},
    {"id":3137,"nombre":"ZAGUARAN, IPIALES, NARIÑO"},
    {"id":3138,"nombre":"ZURAS, IPIALES, NARIÑO"},
    {"id":3139,"nombre":"CHAPARRO, IQUIRA, HUILA"},
    {"id":3140,"nombre":"IQUIRA, IQUIRA, HUILA"},
    {"id":3141,"nombre":"RIO NEGRO, IQUIRA, HUILA"},
    {"id":3142,"nombre":"VALENCIA LA PAZ, IQUIRA, HUILA"},
    {"id":3143,"nombre":"CIENAGA CHIQUITA, ISNOS, HUILA"},
    {"id":3144,"nombre":"EL SALTO DE BORDONES, ISNOS, HUILA"},
    {"id":3145,"nombre":"IDOLOS, ISNOS, HUILA"},
    {"id":3146,"nombre":"SAN JOSE DE ISNOS, ISNOS, HUILA"},
    {"id":3147,"nombre":"SAN VICENTE, ISNOS, HUILA"},
    {"id":3148,"nombre":"BASURU, ISTMINA, CHOCO"},
    {"id":3149,"nombre":"BOCA DE SAN JUAN, ISTMINA, CHOCO"},
    {"id":3150,"nombre":"CHAMBACU, ISTMINA, CHOCO"},
    {"id":3151,"nombre":"CHAMBUN, ISTMINA, CHOCO"},
    {"id":3152,"nombre":"COCOVE, ISTMINA, CHOCO"},
    {"id":3153,"nombre":"EL DOS (SAN RAFAEL), ISTMINA, CHOCO"},
    {"id":3154,"nombre":"FUGIANDO, ISTMINA, CHOCO"},
    {"id":3155,"nombre":"ISTMINA, ISTMINA, CHOCO"},
    {"id":3156,"nombre":"LA LERMA, ISTMINA, CHOCO"},
    {"id":3157,"nombre":"LA VIBORA, ISTMINA, CHOCO"},
    {"id":3158,"nombre":"LAS MOJARRAS, ISTMINA, CHOCO"},
    {"id":3159,"nombre":"MATARE, ISTMINA, CHOCO"},
    {"id":3160,"nombre":"MONTEBRAVO, ISTMINA, CHOCO"},
    {"id":3161,"nombre":"MUNGIDO, ISTMINA, CHOCO"},
    {"id":3162,"nombre":"NEGRIA, ISTMINA, CHOCO"},
    {"id":3163,"nombre":"OLAVE, ISTMINA, CHOCO"},
    {"id":3164,"nombre":"PALMADO, ISTMINA, CHOCO"},
    {"id":3165,"nombre":"PANAMACITO, ISTMINA, CHOCO"},
    {"id":3166,"nombre":"PERRU, ISTMINA, CHOCO"},
    {"id":3167,"nombre":"POTEDO, ISTMINA, CHOCO"},
    {"id":3168,"nombre":"PRIMAVERA, ISTMINA, CHOCO"},
    {"id":3169,"nombre":"PUERTO NUEVO, ISTMINA, CHOCO"},
    {"id":3170,"nombre":"QUEBRADA, ISTMINA, CHOCO"},
    {"id":3171,"nombre":"SALAZAR, ISTMINA, CHOCO"},
    {"id":3172,"nombre":"SAN ANTONIO, ISTMINA, CHOCO"},
    {"id":3173,"nombre":"SAN PABLO ADENTRO, ISTMINA, CHOCO"},
    {"id":3174,"nombre":"SURUCO, ISTMINA, CHOCO"},
    {"id":3175,"nombre":"TRAPICHE, ISTMINA, CHOCO"},
    {"id":3176,"nombre":"ITAGUI, ITAGUI, ANTIOQUIA"},
    {"id":3177,"nombre":"BADILLO, ITUANGO, ANTIOQUIA"},
    {"id":3178,"nombre":"EL ARO - BUILOPOLIS, ITUANGO, ANTIOQUIA"},
    {"id":3179,"nombre":"EL CEDRAL, ITUANGO, ANTIOQUIA"},
    {"id":3180,"nombre":"EL MEDIO, ITUANGO, ANTIOQUIA"},
    {"id":3181,"nombre":"ITUANGO, ITUANGO, ANTIOQUIA"},
    {"id":3182,"nombre":"LA GRANJA, ITUANGO, ANTIOQUIA"},
    {"id":3183,"nombre":"LA HUNDIDA, ITUANGO, ANTIOQUIA"},
    {"id":3184,"nombre":"PALO BLANCO, ITUANGO, ANTIOQUIA"},
    {"id":3185,"nombre":"PASCUITA, ITUANGO, ANTIOQUIA"},
    {"id":3186,"nombre":"SAN JUANILLO, ITUANGO, ANTIOQUIA"},
    {"id":3187,"nombre":"SAN PABLO - RIO SUCIO, ITUANGO, ANTIOQUIA"},
    {"id":3188,"nombre":"SANTA ANA, ITUANGO, ANTIOQUIA"},
    {"id":3189,"nombre":"SANTA LUCIA, ITUANGO, ANTIOQUIA"},
    {"id":3190,"nombre":"SANTA RITA, ITUANGO, ANTIOQUIA"},
    {"id":3191,"nombre":"IZA, IZA, BOYACA"},
    {"id":3192,"nombre":"JAMBALO, JAMBALO, CAUCA"},
    {"id":3193,"nombre":"LA ESPERANZA, JAMBALO, CAUCA"},
    {"id":3194,"nombre":"LA MARIA - EL TRAPICHE, JAMBALO, CAUCA"},
    {"id":3195,"nombre":"LA MINA, JAMBALO, CAUCA"},
    {"id":3196,"nombre":"LOMA GRUESA, JAMBALO, CAUCA"},
    {"id":3197,"nombre":"LOMA REDONDA, JAMBALO, CAUCA"},
    {"id":3198,"nombre":"VALLES HONDOS, JAMBALO, CAUCA"},
    {"id":3199,"nombre":"AMPUDIA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3200,"nombre":"BOCAS DEL PALO, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3201,"nombre":"CHAGRES, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3202,"nombre":"GUACHINTE, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3203,"nombre":"JAMUNDI, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3204,"nombre":"LA LIBERIA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3205,"nombre":"LA MESETA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3206,"nombre":"LA VENTURA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3207,"nombre":"PASO DE LA BOLSA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3208,"nombre":"PEON, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3209,"nombre":"POTRERITO, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3210,"nombre":"PUENTE TIERRA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3211,"nombre":"PUENTE VELEZ, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3212,"nombre":"QUINAMAYO, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3213,"nombre":"ROBLES, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3214,"nombre":"SAN ANTONIO, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3215,"nombre":"SAN ISIDRO, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3216,"nombre":"SAN VICENTE, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3217,"nombre":"TIMBA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3218,"nombre":"VILLA COLOMBIA, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3219,"nombre":"VILLA PAZ, JAMUNDI, VALLE DEL CAUCA"},
    {"id":3220,"nombre":"JARDIN, JARDIN, ANTIOQUIA"},
    {"id":3221,"nombre":"LA CASIANA, JARDIN, ANTIOQUIA"},
    {"id":3222,"nombre":"LAS MACANAS, JARDIN, ANTIOQUIA"},
    {"id":3223,"nombre":"FORAQUIRA, JENESANO, BOYACA"},
    {"id":3224,"nombre":"JENESANO, JENESANO, BOYACA"},
    {"id":3225,"nombre":"JERUSALEN, JERUSALEN, CUNDINAMARCA"},
    {"id":3226,"nombre":"CRISTALES, JESUS MARIA, SANTANDER"},
    {"id":3227,"nombre":"JESUS MARIA, JESUS MARIA, SANTANDER"},
    {"id":3228,"nombre":"PAEZ (PARROQUIA), JESUS MARIA, SANTANDER"},
    {"id":3229,"nombre":"EL GUASIMO, JORDAN, SANTANDER"},
    {"id":3230,"nombre":"EL HOBO, JORDAN, SANTANDER"},
    {"id":3231,"nombre":"EL POZO LARONDO, JORDAN, SANTANDER"},
    {"id":3232,"nombre":"HATO VIEJO, JORDAN, SANTANDER"},
    {"id":3233,"nombre":"JORDAN, JORDAN, SANTANDER"},
    {"id":3234,"nombre":"BOCATOCINO, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3235,"nombre":"EL VAIVEN, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3236,"nombre":"JUAN DE ACOSTA, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3237,"nombre":"LA CHORRERA, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3238,"nombre":"SACO, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3239,"nombre":"SALINAS DEL REY, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3240,"nombre":"SANTA VERONICA, JUAN DE ACOSTA, ATLANTICO"},
    {"id":3241,"nombre":"CHUSCALES, JUNIN, CUNDINAMARCA"},
    {"id":3242,"nombre":"CLARAVAL, JUNIN, CUNDINAMARCA"},
    {"id":3243,"nombre":"EL SALITRICO (SUEVA), JUNIN, CUNDINAMARCA"},
    {"id":3244,"nombre":"JUNIN, JUNIN, CUNDINAMARCA"},
    {"id":3245,"nombre":"AGUACATE, JURADO, CHOCO"},
    {"id":3246,"nombre":"CABO MARZO, JURADO, CHOCO"},
    {"id":3247,"nombre":"COREDO, JURADO, CHOCO"},
    {"id":3248,"nombre":"CURICHE, JURADO, CHOCO"},
    {"id":3249,"nombre":"GUARIN, JURADO, CHOCO"},
    {"id":3250,"nombre":"JURADO, JURADO, CHOCO"},
    {"id":3251,"nombre":"PUNTA ARDITA, JURADO, CHOCO"},
    {"id":3252,"nombre":"PUNTA DE CRUCES, JURADO, CHOCO"},
    {"id":3253,"nombre":"PUNTA PINA, JURADO, CHOCO"},
    {"id":3254,"nombre":"CAMPO ALEGRE, LA APARTADA, CORDOBA"},
    {"id":3255,"nombre":"EL CORCHO, LA APARTADA, CORDOBA"},
    {"id":3256,"nombre":"LA APARTADA Y LA FRONTERA, LA APARTADA, CORDOBA"},
    {"id":3257,"nombre":"LA BALSA, LA APARTADA, CORDOBA"},
    {"id":3258,"nombre":"LA ESCOBILLA, LA APARTADA, CORDOBA"},
    {"id":3259,"nombre":"LA MAGDALENA, LA APARTADA, CORDOBA"},
    {"id":3260,"nombre":"LA VIRGEN, LA APARTADA, CORDOBA"},
    {"id":3261,"nombre":"LAS MARGARITAS, LA APARTADA, CORDOBA"},
    {"id":3262,"nombre":"MANZANARES, LA APARTADA, CORDOBA"},
    {"id":3263,"nombre":"MARGEN DERECHA DE CAMPO ALEGRE, LA APARTADA, CORDOBA"},
    {"id":3264,"nombre":"MARGEN DERECHA DE PUERTO CORDOBA, LA APARTADA, CORDOBA"},
    {"id":3265,"nombre":"MARGEN IZQUIERDA DE CAMPO ALEGRE, LA APARTADA, CORDOBA"},
    {"id":3266,"nombre":"MARGEN IZQUIERDA DE PUERTO CORDOBA, LA APARTADA, CORDOBA"},
    {"id":3267,"nombre":"NUEVO PUEBLO, LA APARTADA, CORDOBA"},
    {"id":3268,"nombre":"SAN JERONIMO, LA APARTADA, CORDOBA"},
    {"id":3269,"nombre":"SITIO NUEVO, LA APARTADA, CORDOBA"},
    {"id":3270,"nombre":"BUENOS AIRES, LA ARGENTINA, HUILA"},
    {"id":3271,"nombre":"EL PENSIL, LA ARGENTINA, HUILA"},
    {"id":3272,"nombre":"LA ARGENTINA, LA ARGENTINA, HUILA"},
    {"id":3273,"nombre":"EL RUBI, LA BELLEZA, SANTANDER"},
    {"id":3274,"nombre":"LA BELLEZA, LA BELLEZA, SANTANDER"},
    {"id":3275,"nombre":"LA PLAYA, LA BELLEZA, SANTANDER"},
    {"id":3276,"nombre":"LA QUITAY, LA BELLEZA, SANTANDER"},
    {"id":3277,"nombre":"OTROMUNDO, LA BELLEZA, SANTANDER"},
    {"id":3278,"nombre":"EL SALITRE, LA CALERA, CUNDINAMARCA"},
    {"id":3279,"nombre":"LA CALERA, LA CALERA, CUNDINAMARCA"},
    {"id":3280,"nombre":"MUNDONUEVO, LA CALERA, CUNDINAMARCA"},
    {"id":3281,"nombre":"TREINTA Y SEIS, LA CALERA, CUNDINAMARCA"},
    {"id":3282,"nombre":"EL ZINC, LA CAPILLA, BOYACA"},
    {"id":3283,"nombre":"LA CAPILLA, LA CAPILLA, BOYACA"},
    {"id":3284,"nombre":"LA PALMA, LA CAPILLA, BOYACA"},
    {"id":3285,"nombre":"LA CEJA, LA CEJA, ANTIOQUIA"},
    {"id":3286,"nombre":"PAYUCO, LA CEJA, ANTIOQUIA"},
    {"id":3287,"nombre":"SAN JOSE, LA CEJA, ANTIOQUIA"},
    {"id":3288,"nombre":"LA CELIA, LA CELIA, RISARALDA"},
    {"id":3289,"nombre":"PATIO BONITO, LA CELIA, RISARALDA"},
    {"id":3290,"nombre":"LA CHORRERA, LA CHORRERA, AMAZONAS"},
    {"id":3291,"nombre":"ALTO LEDESMA, LA CRUZ, NARIÑO"},
    {"id":3292,"nombre":"ALTO MAYO, LA CRUZ, NARIÑO"},
    {"id":3293,"nombre":"CABUYALES, LA CRUZ, NARIÑO"},
    {"id":3294,"nombre":"CASCABEL, LA CRUZ, NARIÑO"},
    {"id":3295,"nombre":"COFRADIA, LA CRUZ, NARIÑO"},
    {"id":3296,"nombre":"EL PULPITO, LA CRUZ, NARIÑO"},
    {"id":3297,"nombre":"JUAN ESTEBAN, LA CRUZ, NARIÑO"},
    {"id":3298,"nombre":"JUAN LOPEZ, LA CRUZ, NARIÑO"},
    {"id":3299,"nombre":"LA CANADA, LA CRUZ, NARIÑO"},
    {"id":3300,"nombre":"LA CRUZ, LA CRUZ, NARIÑO"},
    {"id":3301,"nombre":"LA ESPERANZA, LA CRUZ, NARIÑO"},
    {"id":3302,"nombre":"PLAZUELAS, LA CRUZ, NARIÑO"},
    {"id":3303,"nombre":"SAN GERARDO, LA CRUZ, NARIÑO"},
    {"id":3304,"nombre":"TAJUMBINA, LA CRUZ, NARIÑO"},
    {"id":3305,"nombre":"BITACO, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3306,"nombre":"LA CUMBRE, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3307,"nombre":"LA MARIA, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3308,"nombre":"LOMITAS, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3309,"nombre":"PAVAS, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3310,"nombre":"PUENTE PALO, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3311,"nombre":"SAN ANTONIO, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3312,"nombre":"SAN SALVADOR, LA CUMBRE, VALLE DEL CAUCA"},
    {"id":3313,"nombre":"BUENAVISTA, LA DORADA, CALDAS"},
    {"id":3314,"nombre":"CAMELIAS, LA DORADA, CALDAS"},
    {"id":3315,"nombre":"DONA JUANA, LA DORADA, CALDAS"},
    {"id":3316,"nombre":"EL TIGRE, LA DORADA, CALDAS"},
    {"id":3317,"nombre":"GUARINOCITO, LA DORADA, CALDAS"},
    {"id":3318,"nombre":"HORIZONTE, LA DORADA, CALDAS"},
    {"id":3319,"nombre":"LA AGUSTINA, LA DORADA, CALDAS"},
    {"id":3320,"nombre":"LA ATARRAYA, LA DORADA, CALDAS"},
    {"id":3321,"nombre":"LA DORADA, LA DORADA, CALDAS"},
    {"id":3322,"nombre":"LA ESPERANZA, LA DORADA, CALDAS"},
    {"id":3323,"nombre":"LA HABANA, LA DORADA, CALDAS"},
    {"id":3324,"nombre":"POZO REDONDO, LA DORADA, CALDAS"},
    {"id":3325,"nombre":"PUERTO PLATANO, LA DORADA, CALDAS"},
    {"id":3326,"nombre":"PURNIO, LA DORADA, CALDAS"},
    {"id":3327,"nombre":"EL TROPEZON, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3328,"nombre":"LA ESPERANZA, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3329,"nombre":"LA PEDREGOSA, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3330,"nombre":"LA RAYA, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3331,"nombre":"LEON XIII, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3332,"nombre":"LOS CEDROS, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3333,"nombre":"PUEBLO NUEVO, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3334,"nombre":"VEINTE DE JULIO, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3335,"nombre":"VILLA MARIA, LA ESPERANZA, NORTE DE SANTANDER"},
    {"id":3336,"nombre":"CALLE VIEJA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3337,"nombre":"EL PEDRERO, LA ESTRELLA, ANTIOQUIA"},
    {"id":3338,"nombre":"LA ESTRELLA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3339,"nombre":"LA RAYA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3340,"nombre":"LA TABLACITA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3341,"nombre":"LA TABLAZA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3342,"nombre":"PUEBLO VIEJO, LA ESTRELLA, ANTIOQUIA"},
    {"id":3343,"nombre":"SAGRADA FAMILIA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3344,"nombre":"SAN ISIDRO, LA ESTRELLA, ANTIOQUIA"},
    {"id":3345,"nombre":"SAN JOSE - MELEGUINDO, LA ESTRELLA, ANTIOQUIA"},
    {"id":3346,"nombre":"SAN JOSE TARAPACA, LA ESTRELLA, ANTIOQUIA"},
    {"id":3347,"nombre":"SAN MIGUEL, LA ESTRELLA, ANTIOQUIA"},
    {"id":3348,"nombre":"BELLAVISTA, LA FLORIDA, NARIÑO"},
    {"id":3349,"nombre":"EL BARRANCO, LA FLORIDA, NARIÑO"},
    {"id":3350,"nombre":"EL CACIQUE, LA FLORIDA, NARIÑO"},
    {"id":3351,"nombre":"EL MACO, LA FLORIDA, NARIÑO"},
    {"id":3352,"nombre":"EL RODEO, LA FLORIDA, NARIÑO"},
    {"id":3353,"nombre":"LA FLORIDA, LA FLORIDA, NARIÑO"},
    {"id":3354,"nombre":"LAS PLAZUELAS, LA FLORIDA, NARIÑO"},
    {"id":3355,"nombre":"MATITUY, LA FLORIDA, NARIÑO"},
    {"id":3356,"nombre":"SAN FRANCISCO, LA FLORIDA, NARIÑO"},
    {"id":3357,"nombre":"SANTACRUZ DE ROBLES, LA FLORIDA, NARIÑO"},
    {"id":3358,"nombre":"TUNJA GRANDE, LA FLORIDA, NARIÑO"},
    {"id":3359,"nombre":"AYACUCHO, LA GLORIA, CESAR"},
    {"id":3360,"nombre":"BESOTE, LA GLORIA, CESAR"},
    {"id":3361,"nombre":"BUBETA, LA GLORIA, CESAR"},
    {"id":3362,"nombre":"CAROLINA, LA GLORIA, CESAR"},
    {"id":3363,"nombre":"EL CRUCE, LA GLORIA, CESAR"},
    {"id":3364,"nombre":"ESTACION FERROCARRIL, LA GLORIA, CESAR"},
    {"id":3365,"nombre":"LA GLORIA, LA GLORIA, CESAR"},
    {"id":3366,"nombre":"LA MATA, LA GLORIA, CESAR"},
    {"id":3367,"nombre":"MOLINA, LA GLORIA, CESAR"},
    {"id":3368,"nombre":"SAN PABLO, LA GLORIA, CESAR"},
    {"id":3369,"nombre":"SIMANA, LA GLORIA, CESAR"},
    {"id":3370,"nombre":"LA GUADALUPE, LA GUADALUPE, GUAINIA"},
    {"id":3371,"nombre":"BOQUERON, LA JAGUA DE IBIRICO, CESAR"},
    {"id":3372,"nombre":"LA JAGUA DE IBIRICO, LA JAGUA DE IBIRICO, CESAR"},
    {"id":3373,"nombre":"LA VICTORIA DE SAN ISIDRO, LA JAGUA DE IBIRICO, CESAR"},
    {"id":3374,"nombre":"LAS PALMITAS, LA JAGUA DE IBIRICO, CESAR"},
    {"id":3375,"nombre":"EL PLAN, LA JAGUA DEL PILAR, LA GUAJIRA"},
    {"id":3376,"nombre":"LA JAGUA DEL PILAR, LA JAGUA DEL PILAR, LA GUAJIRA"},
    {"id":3377,"nombre":"CAMPANARIO, LA LLANADA, NARIÑO"},
    {"id":3378,"nombre":"EL PALMAR, LA LLANADA, NARIÑO"},
    {"id":3379,"nombre":"EL REMATE, LA LLANADA, NARIÑO"},
    {"id":3380,"nombre":"EL SASPI, LA LLANADA, NARIÑO"},
    {"id":3381,"nombre":"EL VERGEL, LA LLANADA, NARIÑO"},
    {"id":3382,"nombre":"FLORESTA, LA LLANADA, NARIÑO"},
    {"id":3383,"nombre":"FLORIDA, LA LLANADA, NARIÑO"},
    {"id":3384,"nombre":"GUARANGO, LA LLANADA, NARIÑO"},
    {"id":3385,"nombre":"LA LLANADA, LA LLANADA, NARIÑO"},
    {"id":3386,"nombre":"MACO, LA LLANADA, NARIÑO"},
    {"id":3387,"nombre":"MURCIELAGO, LA LLANADA, NARIÑO"},
    {"id":3388,"nombre":"PALMA, LA LLANADA, NARIÑO"},
    {"id":3389,"nombre":"PRADO, LA LLANADA, NARIÑO"},
    {"id":3390,"nombre":"SAN FRANCISCO, LA LLANADA, NARIÑO"},
    {"id":3391,"nombre":"SANTA ROSA, LA LLANADA, NARIÑO"},
    {"id":3392,"nombre":"LA MACARENA, LA MACARENA, META"},
    {"id":3393,"nombre":"LA SOMBRA, LA MACARENA, META"},
    {"id":3394,"nombre":"LOS POZOS, LA MACARENA, META"},
    {"id":3395,"nombre":"SAN JUAN DE LOZADA, LA MACARENA, META"},
    {"id":3396,"nombre":"VILLA DEL RIO, LA MACARENA, META"},
    {"id":3397,"nombre":"EL LIMON, LA MERCED, CALDAS"},
    {"id":3398,"nombre":"EL PALO, LA MERCED, CALDAS"},
    {"id":3399,"nombre":"EL TAMBOR, LA MERCED, CALDAS"},
    {"id":3400,"nombre":"LA CHUSPA, LA MERCED, CALDAS"},
    {"id":3401,"nombre":"LA FELISA, LA MERCED, CALDAS"},
    {"id":3402,"nombre":"LA MERCED, LA MERCED, CALDAS"},
    {"id":3403,"nombre":"LLANADAS, LA MERCED, CALDAS"},
    {"id":3404,"nombre":"MACIEGAL, LA MERCED, CALDAS"},
    {"id":3405,"nombre":"PENA RICA, LA MERCED, CALDAS"},
    {"id":3406,"nombre":"SAN JOSE, LA MERCED, CALDAS"},
    {"id":3407,"nombre":"LA ESPERANZA, LA MESA, CUNDINAMARCA"},
    {"id":3408,"nombre":"LA MESA, LA MESA, CUNDINAMARCA"},
    {"id":3409,"nombre":"SAN JAVIER, LA MESA, CUNDINAMARCA"},
    {"id":3410,"nombre":"SAN JOAQUIN, LA MESA, CUNDINAMARCA"},
    {"id":3411,"nombre":"EL HATO, LA PALMA, CUNDINAMARCA"},
    {"id":3412,"nombre":"EL POTRERO, LA PALMA, CUNDINAMARCA"},
    {"id":3413,"nombre":"LA HOYA TUDELA, LA PALMA, CUNDINAMARCA"},
    {"id":3414,"nombre":"LA PALMA, LA PALMA, CUNDINAMARCA"},
    {"id":3415,"nombre":"LAMA, LA PALMA, CUNDINAMARCA"},
    {"id":3416,"nombre":"MIMASAL, LA PALMA, CUNDINAMARCA"},
    {"id":3417,"nombre":"MURCA, LA PALMA, CUNDINAMARCA"},
    {"id":3418,"nombre":"LA PEDRERA, LA PEDRERA, AMAZONAS"},
    {"id":3419,"nombre":"LA PINTADA, LA PINTADA, ANTIOQUIA"},
    {"id":3420,"nombre":"RAFAEL URIBE URIBE (EL CRUCERO), LA PINTADA, ANTIOQUIA"},
    {"id":3421,"nombre":"BELEN, LA PLATA, HUILA"},
    {"id":3422,"nombre":"GALLEGO, LA PLATA, HUILA"},
    {"id":3423,"nombre":"LA PLATA, LA PLATA, HUILA"},
    {"id":3424,"nombre":"MONSERRATE, LA PLATA, HUILA"},
    {"id":3425,"nombre":"MOSCOPAN, LA PLATA, HUILA"},
    {"id":3426,"nombre":"SAN ANDRES, LA PLATA, HUILA"},
    {"id":3427,"nombre":"SAN VICENTE, LA PLATA, HUILA"},
    {"id":3428,"nombre":"VILLA LOSADA, LA PLATA, HUILA"},
    {"id":3429,"nombre":"ASPASICA, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3430,"nombre":"CURASICA, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3431,"nombre":"EL CINCHO, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3432,"nombre":"LA PLAYA, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3433,"nombre":"LA VEGA DE SAN ANTONIO, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3434,"nombre":"MACIEGAS, LA PLAYA, NORTE DE SANTANDER"},
    {"id":3435,"nombre":"LA PRIMAVERA, LA PRIMAVERA, VICHADA"},
    {"id":3436,"nombre":"MARANDUA, LA PRIMAVERA, VICHADA"},
    {"id":3437,"nombre":"MATIYURE, LA PRIMAVERA, VICHADA"},
    {"id":3438,"nombre":"NUEVA ANTIOQUIA, LA PRIMAVERA, VICHADA"},
    {"id":3439,"nombre":"SAN TEODORO (LA PASCUA), LA PRIMAVERA, VICHADA"},
    {"id":3440,"nombre":"SANTA BARBARA, LA PRIMAVERA, VICHADA"},
    {"id":3441,"nombre":"SANTA CECILIA, LA PRIMAVERA, VICHADA"},
    {"id":3442,"nombre":"LA SALINA, LA SALINA, CASANARE"},
    {"id":3443,"nombre":"JUANA CASTANA, LA SIERRA, CAUCA"},
    {"id":3444,"nombre":"LA CUCHILLA, LA SIERRA, CAUCA"},
    {"id":3445,"nombre":"LA DEPRESION, LA SIERRA, CAUCA"},
    {"id":3446,"nombre":"LA SIERRA, LA SIERRA, CAUCA"},
    {"id":3447,"nombre":"LOS ROBLES, LA SIERRA, CAUCA"},
    {"id":3448,"nombre":"SAN PEDRO, LA SIERRA, CAUCA"},
    {"id":3449,"nombre":"LA HERRADURA, LA TEBAIDA, QUINDIO"},
    {"id":3450,"nombre":"LA TEBAIDA, LA TEBAIDA, QUINDIO"},
    {"id":3451,"nombre":"AGUACATAL, LA TOLA, NARIÑO"},
    {"id":3452,"nombre":"ANTONIO NARIÑO, LA TOLA, NARIÑO"},
    {"id":3453,"nombre":"EL CEDRO, LA TOLA, NARIÑO"},
    {"id":3454,"nombre":"LA TOLA, LA TOLA, NARIÑO"},
    {"id":3455,"nombre":"LA VIGIA, LA TOLA, NARIÑO"},
    {"id":3456,"nombre":"MULATOS, LA TOLA, NARIÑO"},
    {"id":3457,"nombre":"NERETE, LA TOLA, NARIÑO"},
    {"id":3458,"nombre":"PANGAMOSA, LA TOLA, NARIÑO"},
    {"id":3459,"nombre":"POIJAL, LA TOLA, NARIÑO"},
    {"id":3460,"nombre":"SAN ANTONIO DE LA MAR, LA TOLA, NARIÑO"},
    {"id":3461,"nombre":"SAN PABLO DE LA TOLA, LA TOLA, NARIÑO"},
    {"id":3462,"nombre":"SOFONIAS YACUP, LA TOLA, NARIÑO"},
    {"id":3463,"nombre":"TANGAREAL DE LA TOLA, LA TOLA, NARIÑO"},
    {"id":3464,"nombre":"CUSAGUI, LA UVITA, BOYACA"},
    {"id":3465,"nombre":"LA UVITA, LA UVITA, BOYACA"},
    {"id":3466,"nombre":"EL AGUACATE, LA VIRGINIA, RISARALDA"},
    {"id":3467,"nombre":"LA PALMA, LA VIRGINIA, RISARALDA"},
    {"id":3468,"nombre":"LA VIRGINIA, LA VIRGINIA, RISARALDA"},
    {"id":3469,"nombre":"LA CUCHILLA, LABATECA, NORTE DE SANTANDER"},
    {"id":3470,"nombre":"LABATECA, LABATECA, NORTE DE SANTANDER"},
    {"id":3471,"nombre":"SANTA MARIA, LABATECA, NORTE DE SANTANDER"},
    {"id":3472,"nombre":"LA AGUADA, LABRANZAGRANDE, BOYACA"},
    {"id":3473,"nombre":"LABRANZAGRANDE, LABRANZAGRANDE, BOYACA"},
    {"id":3474,"nombre":"BAJO JORDAN, LANDAZURI, SANTANDER"},
    {"id":3475,"nombre":"CHOROLO, LANDAZURI, SANTANDER"},
    {"id":3476,"nombre":"EL TAGUAL, LANDAZURI, SANTANDER"},
    {"id":3477,"nombre":"KILOMETRO 15, LANDAZURI, SANTANDER"},
    {"id":3478,"nombre":"LA HERMOSURA, LANDAZURI, SANTANDER"},
    {"id":3479,"nombre":"LA INDIA, LANDAZURI, SANTANDER"},
    {"id":3480,"nombre":"LA MELONA, LANDAZURI, SANTANDER"},
    {"id":3481,"nombre":"LANDAZURI, LANDAZURI, SANTANDER"},
    {"id":3482,"nombre":"MIRALINDO, LANDAZURI, SANTANDER"},
    {"id":3483,"nombre":"PLAN DE ARMAS, LANDAZURI, SANTANDER"},
    {"id":3484,"nombre":"POPAYAN, LANDAZURI, SANTANDER"},
    {"id":3485,"nombre":"QUEBRADA LARGA (BETANIA), LANDAZURI, SANTANDER"},
    {"id":3486,"nombre":"RIO BLANCO, LANDAZURI, SANTANDER"},
    {"id":3487,"nombre":"SAN IGNACIO DEL OPON, LANDAZURI, SANTANDER"},
    {"id":3488,"nombre":"SAN PEDRO DEL OPON, LANDAZURI, SANTANDER"},
    {"id":3489,"nombre":"SOLEDAD, LANDAZURI, SANTANDER"},
    {"id":3490,"nombre":"VALPARAISO, LANDAZURI, SANTANDER"},
    {"id":3491,"nombre":"AGUIRRE, LEBRIJA, SANTANDER"},
    {"id":3492,"nombre":"CENTENARIO, LEBRIJA, SANTANDER"},
    {"id":3493,"nombre":"CHUSPAS, LEBRIJA, SANTANDER"},
    {"id":3494,"nombre":"EL CONCHAL, LEBRIJA, SANTANDER"},
    {"id":3495,"nombre":"EL OSO, LEBRIJA, SANTANDER"},
    {"id":3496,"nombre":"GUZAMAN, LEBRIJA, SANTANDER"},
    {"id":3497,"nombre":"LA AGUADA, LEBRIJA, SANTANDER"},
    {"id":3498,"nombre":"LA PAZ, LEBRIJA, SANTANDER"},
    {"id":3499,"nombre":"LA RENTA, LEBRIJA, SANTANDER"},
    {"id":3500,"nombre":"LEBRIJA, LEBRIJA, SANTANDER"},
    {"id":3501,"nombre":"LIBANO, LEBRIJA, SANTANDER"},
    {"id":3502,"nombre":"LLANADAS, LEBRIJA, SANTANDER"},
    {"id":3503,"nombre":"PORTUGAL, LEBRIJA, SANTANDER"},
    {"id":3504,"nombre":"SARDINAS, LEBRIJA, SANTANDER"},
    {"id":3505,"nombre":"URIBE URIBE, LEBRIJA, SANTANDER"},
    {"id":3506,"nombre":"VANEGAS, LEBRIJA, SANTANDER"},
    {"id":3507,"nombre":"DELICIAS, LEGUIZAMO, PUTUMAYO"},
    {"id":3508,"nombre":"LA PAYA, LEGUIZAMO, PUTUMAYO"},
    {"id":3509,"nombre":"LA TAGUA, LEGUIZAMO, PUTUMAYO"},
    {"id":3510,"nombre":"LA VICTORIA, LEGUIZAMO, PUTUMAYO"},
    {"id":3511,"nombre":"LEGUIZAMO, LEGUIZAMO, PUTUMAYO"},
    {"id":3512,"nombre":"MECAYA, LEGUIZAMO, PUTUMAYO"},
    {"id":3513,"nombre":"NUEVA APAYA, LEGUIZAMO, PUTUMAYO"},
    {"id":3514,"nombre":"PINUNA NEGRO, LEGUIZAMO, PUTUMAYO"},
    {"id":3515,"nombre":"PUERTO NARIÑO, LEGUIZAMO, PUTUMAYO"},
    {"id":3516,"nombre":"PUERTO OSPINA, LEGUIZAMO, PUTUMAYO"},
    {"id":3517,"nombre":"SENCELLA, LEGUIZAMO, PUTUMAYO"},
    {"id":3518,"nombre":"CANADUZAL, LEIVA, NARIÑO"},
    {"id":3519,"nombre":"EL OFRIO, LEIVA, NARIÑO"},
    {"id":3520,"nombre":"EL PALMAR, LEIVA, NARIÑO"},
    {"id":3521,"nombre":"EL TABLON, LEIVA, NARIÑO"},
    {"id":3522,"nombre":"ESMERALDAS, LEIVA, NARIÑO"},
    {"id":3523,"nombre":"FLORIDA BAJA, LEIVA, NARIÑO"},
    {"id":3524,"nombre":"LA FLORIDA, LEIVA, NARIÑO"},
    {"id":3525,"nombre":"LA VILLA, LEIVA, NARIÑO"},
    {"id":3526,"nombre":"LAS DELICIAS, LEIVA, NARIÑO"},
    {"id":3527,"nombre":"LEIVA, LEIVA, NARIÑO"},
    {"id":3528,"nombre":"NARIÑO, LEIVA, NARIÑO"},
    {"id":3529,"nombre":"PUERTO NUEVO, LEIVA, NARIÑO"},
    {"id":3530,"nombre":"SANTA LUCIA, LEIVA, NARIÑO"},
    {"id":3531,"nombre":"VILLA BAJA, LEIVA, NARIÑO"},
    {"id":3532,"nombre":"ANGOSTURAS DEL GUAPE, LEJANIAS, META"},
    {"id":3533,"nombre":"CACAYAL, LEJANIAS, META"},
    {"id":3534,"nombre":"LEJANIAS, LEJANIAS, META"},
    {"id":3535,"nombre":"LENGUAZAQUE, LENGUAZAQUE, CUNDINAMARCA"},
    {"id":3536,"nombre":"DELICIAS, LERIDA, TOLIMA"},
    {"id":3537,"nombre":"EL CENSO, LERIDA, TOLIMA"},
    {"id":3538,"nombre":"IGUASITOS, LERIDA, TOLIMA"},
    {"id":3539,"nombre":"LA SIERRA, LERIDA, TOLIMA"},
    {"id":3540,"nombre":"LERIDA, LERIDA, TOLIMA"},
    {"id":3541,"nombre":"PADILLA, LERIDA, TOLIMA"},
    {"id":3542,"nombre":"ANCA, LETICIA, AMAZONAS"},
    {"id":3543,"nombre":"ARARA, LETICIA, AMAZONAS"},
    {"id":3544,"nombre":"LETICIA, LETICIA, AMAZONAS"},
    {"id":3545,"nombre":"NAZARETH, LETICIA, AMAZONAS"},
    {"id":3546,"nombre":"RIO CALDERON, LETICIA, AMAZONAS"},
    {"id":3547,"nombre":"SAN JOSE, LETICIA, AMAZONAS"},
    {"id":3548,"nombre":"SAN MARTIN DE AMACAYACU, LETICIA, AMAZONAS"},
    {"id":3549,"nombre":"SAN SEBASTIAN, LETICIA, AMAZONAS"},
    {"id":3550,"nombre":"SANTA SOFIA, LETICIA, AMAZONAS"},
    {"id":3551,"nombre":"ZARAGOZA, LETICIA, AMAZONAS"},
    {"id":3552,"nombre":"CAMPO ALEGRE, LIBANO, TOLIMA"},
    {"id":3553,"nombre":"CONVENIO, LIBANO, TOLIMA"},
    {"id":3554,"nombre":"LA CRUZ, LIBANO, TOLIMA"},
    {"id":3555,"nombre":"LIBANO, LIBANO, TOLIMA"},
    {"id":3556,"nombre":"SAN FERNANDO, LIBANO, TOLIMA"},
    {"id":3557,"nombre":"SAN JORGE, LIBANO, TOLIMA"},
    {"id":3558,"nombre":"SANTA TERESA, LIBANO, TOLIMA"},
    {"id":3559,"nombre":"TIERRADENTRO, LIBANO, TOLIMA"},
    {"id":3560,"nombre":"BELEN, LIBORINA, ANTIOQUIA"},
    {"id":3561,"nombre":"CURITI, LIBORINA, ANTIOQUIA"},
    {"id":3562,"nombre":"EL CARMEN - LA VENTA, LIBORINA, ANTIOQUIA"},
    {"id":3563,"nombre":"EL GUAMAL, LIBORINA, ANTIOQUIA"},
    {"id":3564,"nombre":"EL POTRERO, LIBORINA, ANTIOQUIA"},
    {"id":3565,"nombre":"LA HONDA, LIBORINA, ANTIOQUIA"},
    {"id":3566,"nombre":"LA MERCED (PLAYON), LIBORINA, ANTIOQUIA"},
    {"id":3567,"nombre":"LIBORINA, LIBORINA, ANTIOQUIA"},
    {"id":3568,"nombre":"LOS PENOLES, LIBORINA, ANTIOQUIA"},
    {"id":3569,"nombre":"SAN CRISTOBAL, LIBORINA, ANTIOQUIA"},
    {"id":3570,"nombre":"SAN DIEGO (PLACITA), LIBORINA, ANTIOQUIA"},
    {"id":3571,"nombre":"SOBRESABANA, LIBORINA, ANTIOQUIA"},
    {"id":3572,"nombre":"BELLA FLORIDA, LINARES, NARIÑO"},
    {"id":3573,"nombre":"LINARES, LINARES, NARIÑO"},
    {"id":3574,"nombre":"SAN FRANCISCO, LINARES, NARIÑO"},
    {"id":3575,"nombre":"TABILES, LINARES, NARIÑO"},
    {"id":3576,"nombre":"TAMBILLO BRAVOS, LINARES, NARIÑO"},
    {"id":3577,"nombre":"BORAUDO, LLORO, CHOCO"},
    {"id":3578,"nombre":"CALLE LARGA, LLORO, CHOCO"},
    {"id":3579,"nombre":"CHAGRATADA, LLORO, CHOCO"},
    {"id":3580,"nombre":"EL LLANO, LLORO, CHOCO"},
    {"id":3581,"nombre":"GUAITADO, LLORO, CHOCO"},
    {"id":3582,"nombre":"LA VUELTA, LLORO, CHOCO"},
    {"id":3583,"nombre":"LAS HAMACAS, LLORO, CHOCO"},
    {"id":3584,"nombre":"LLORO, LLORO, CHOCO"},
    {"id":3585,"nombre":"MUMBARADO, LLORO, CHOCO"},
    {"id":3586,"nombre":"NIPORDU, LLORO, CHOCO"},
    {"id":3587,"nombre":"OGODO, LLORO, CHOCO"},
    {"id":3588,"nombre":"TUMUTUMBUDO, LLORO, CHOCO"},
    {"id":3589,"nombre":"VILLA CLARET, LLORO, CHOCO"},
    {"id":3590,"nombre":"AGUA CLARA (GOLONDRO), LOPEZ, CAUCA"},
    {"id":3591,"nombre":"ALTO DE CHUARE, LOPEZ, CAUCA"},
    {"id":3592,"nombre":"ALTO NAYA, LOPEZ, CAUCA"},
    {"id":3593,"nombre":"BAJO SIGUI, LOPEZ, CAUCA"},
    {"id":3594,"nombre":"BETANIA, LOPEZ, CAUCA"},
    {"id":3595,"nombre":"BOCA GRANDE, LOPEZ, CAUCA"},
    {"id":3596,"nombre":"BRAZO DE LA ROTURA, LOPEZ, CAUCA"},
    {"id":3597,"nombre":"CACAO, LOPEZ, CAUCA"},
    {"id":3598,"nombre":"CALLE LARGA - NAYA, LOPEZ, CAUCA"},
    {"id":3599,"nombre":"CANDELARIA, LOPEZ, CAUCA"},
    {"id":3600,"nombre":"CHIGUERO, LOPEZ, CAUCA"},
    {"id":3601,"nombre":"EL COCO, LOPEZ, CAUCA"},
    {"id":3602,"nombre":"EL PLAYON (SIGUI), LOPEZ, CAUCA"},
    {"id":3603,"nombre":"EL TRAPICHE, LOPEZ, CAUCA"},
    {"id":3604,"nombre":"EL TRUENO, LOPEZ, CAUCA"},
    {"id":3605,"nombre":"GUAYABAL, LOPEZ, CAUCA"},
    {"id":3606,"nombre":"JOLI, LOPEZ, CAUCA"},
    {"id":3607,"nombre":"LA CONCEPCION, LOPEZ, CAUCA"},
    {"id":3608,"nombre":"LA SAGRADA FAMILIA, LOPEZ, CAUCA"},
    {"id":3609,"nombre":"LAS CRUCES, LOPEZ, CAUCA"},
    {"id":3610,"nombre":"LAS PAVAS, LOPEZ, CAUCA"},
    {"id":3611,"nombre":"MICAY, LOPEZ, CAUCA"},
    {"id":3612,"nombre":"NOANAMITO, LOPEZ, CAUCA"},
    {"id":3613,"nombre":"PLAYA GRANDE, LOPEZ, CAUCA"},
    {"id":3614,"nombre":"RIO MAYA (DOS QUEBRADAS), LOPEZ, CAUCA"},
    {"id":3615,"nombre":"SAN ANTONIO DE CHUARE, LOPEZ, CAUCA"},
    {"id":3616,"nombre":"SAN ANTONIO DE GURUMENDY, LOPEZ, CAUCA"},
    {"id":3617,"nombre":"SAN BARTOLO, LOPEZ, CAUCA"},
    {"id":3618,"nombre":"SAN FERNANDO, LOPEZ, CAUCA"},
    {"id":3619,"nombre":"SAN FRANCISCO ADENTRO, LOPEZ, CAUCA"},
    {"id":3620,"nombre":"SAN ISIDRO, LOPEZ, CAUCA"},
    {"id":3621,"nombre":"SAN PEDRO DE NAYA, LOPEZ, CAUCA"},
    {"id":3622,"nombre":"SANTA ANA, LOPEZ, CAUCA"},
    {"id":3623,"nombre":"SANTA CRUZ DE SIGUIN, LOPEZ, CAUCA"},
    {"id":3624,"nombre":"TAPARAL, LOPEZ, CAUCA"},
    {"id":3625,"nombre":"ZARAGOZA, LOPEZ, CAUCA"},
    {"id":3626,"nombre":"CAMPO ALEGRE, LORICA, CORDOBA"},
    {"id":3627,"nombre":"CANDELARIA, LORICA, CORDOBA"},
    {"id":3628,"nombre":"CASTILLERAL, LORICA, CORDOBA"},
    {"id":3629,"nombre":"CENTROS DE AFLUENCIA, LORICA, CORDOBA"},
    {"id":3630,"nombre":"COTOCA ABAJO, LORICA, CORDOBA"},
    {"id":3631,"nombre":"COTOCA ARRIBA, LORICA, CORDOBA"},
    {"id":3632,"nombre":"EL CAMPANO DE LOS INDIOS, LORICA, CORDOBA"},
    {"id":3633,"nombre":"EL CARITO, LORICA, CORDOBA"},
    {"id":3634,"nombre":"EL GUANABANO, LORICA, CORDOBA"},
    {"id":3635,"nombre":"EL LAZO, LORICA, CORDOBA"},
    {"id":3636,"nombre":"EL PLAYON, LORICA, CORDOBA"},
    {"id":3637,"nombre":"EL RODEO, LORICA, CORDOBA"},
    {"id":3638,"nombre":"LA DOCTRINA, LORICA, CORDOBA"},
    {"id":3639,"nombre":"LA PALMA, LORICA, CORDOBA"},
    {"id":3640,"nombre":"LA PEINADA, LORICA, CORDOBA"},
    {"id":3641,"nombre":"LA SUBIDA, LORICA, CORDOBA"},
    {"id":3642,"nombre":"LAS CAMORRAS, LORICA, CORDOBA"},
    {"id":3643,"nombre":"LAS FLORES, LORICA, CORDOBA"},
    {"id":3644,"nombre":"SANTA CRUZ DE LORICA, LORICA, CORDOBA"},
    {"id":3645,"nombre":"LOS HIGALES, LORICA, CORDOBA"},
    {"id":3646,"nombre":"LOS MONOS, LORICA, CORDOBA"},
    {"id":3647,"nombre":"LOS MORALES, LORICA, CORDOBA"},
    {"id":3648,"nombre":"MANANTIAL, LORICA, CORDOBA"},
    {"id":3649,"nombre":"MARACAYO, LORICA, CORDOBA"},
    {"id":3650,"nombre":"MATA DE CANA, LORICA, CORDOBA"},
    {"id":3651,"nombre":"NARIÑO, LORICA, CORDOBA"},
    {"id":3652,"nombre":"PALO DE AGUA, LORICA, CORDOBA"},
    {"id":3653,"nombre":"REMOLINO, LORICA, CORDOBA"},
    {"id":3654,"nombre":"SAN ANTERITO, LORICA, CORDOBA"},
    {"id":3655,"nombre":"SAN NICOLAS DE BARI, LORICA, CORDOBA"},
    {"id":3656,"nombre":"SAN SEBASTIAN, LORICA, CORDOBA"},
    {"id":3657,"nombre":"SANTA LUCIA, LORICA, CORDOBA"},
    {"id":3658,"nombre":"TIERRALTA, LORICA, CORDOBA"},
    {"id":3659,"nombre":"TRAGEDIA, LORICA, CORDOBA"},
    {"id":3660,"nombre":"VILLA CONCEPCION, LORICA, CORDOBA"},
    {"id":3661,"nombre":"VILLAVICENCIO, LORICA, CORDOBA"},
    {"id":3662,"nombre":"EL CARRIZAL, LOS ANDES, NARIÑO"},
    {"id":3663,"nombre":"EL PALMAR, LOS ANDES, NARIÑO"},
    {"id":3664,"nombre":"LA PLANADA, LOS ANDES, NARIÑO"},
    {"id":3665,"nombre":"SOTOMAYOR, LOS ANDES, NARIÑO"},
    {"id":3666,"nombre":"PANGUS, LOS ANDES, NARIÑO"},
    {"id":3667,"nombre":"SAN SEBASTIAN, LOS ANDES, NARIÑO"},
    {"id":3668,"nombre":"BARRIO MAMEY, LOS CORDOBAS, CORDOBA"},
    {"id":3669,"nombre":"EL EBANO, LOS CORDOBAS, CORDOBA"},
    {"id":3670,"nombre":"EL GUAIMARO, LOS CORDOBAS, CORDOBA"},
    {"id":3671,"nombre":"JALISCO, LOS CORDOBAS, CORDOBA"},
    {"id":3672,"nombre":"LA PONDERANCIA, LOS CORDOBAS, CORDOBA"},
    {"id":3673,"nombre":"LA SALADA, LOS CORDOBAS, CORDOBA"},
    {"id":3674,"nombre":"LOS CORDOBAS, LOS CORDOBAS, CORDOBA"},
    {"id":3675,"nombre":"MORINDO SANTANA, LOS CORDOBAS, CORDOBA"},
    {"id":3676,"nombre":"PUERTO REY, LOS CORDOBAS, CORDOBA"},
    {"id":3677,"nombre":"SANTA ROSA - LA CANA, LOS CORDOBAS, CORDOBA"},
    {"id":3678,"nombre":"BIRMANIA, LOS PALMITOS, SUCRE"},
    {"id":3679,"nombre":"EL COLEY, LOS PALMITOS, SUCRE"},
    {"id":3680,"nombre":"EL PINAL, LOS PALMITOS, SUCRE"},
    {"id":3681,"nombre":"HATILLO, LOS PALMITOS, SUCRE"},
    {"id":3682,"nombre":"LOS PALMITOS, LOS PALMITOS, SUCRE"},
    {"id":3683,"nombre":"NARANJAL, LOS PALMITOS, SUCRE"},
    {"id":3684,"nombre":"PALMAS DE VINO, LOS PALMITOS, SUCRE"},
    {"id":3685,"nombre":"SABANAS DE BELTRAN, LOS PALMITOS, SUCRE"},
    {"id":3686,"nombre":"SABANAS DE PEDRO, LOS PALMITOS, SUCRE"},
    {"id":3687,"nombre":"AGUALINDA, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3688,"nombre":"LA GARITA, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3689,"nombre":"LA MUTIS, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3690,"nombre":"LOS PATIOS, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3691,"nombre":"LOS TRAPICHES, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3692,"nombre":"LOS VADOS, LOS PATIOS, NORTE DE SANTANDER"},
    {"id":3693,"nombre":"EL ESPINAL - REGADERO, LOS SANTOS, SANTANDER"},
    {"id":3694,"nombre":"LA PURNIA, LOS SANTOS, SANTANDER"},
    {"id":3695,"nombre":"LOS SANTOS, LOS SANTOS, SANTANDER"},
    {"id":3696,"nombre":"LOURDES, LOURDES, NORTE DE SANTANDER"},
    {"id":3697,"nombre":"ARROYO DE PIEDRA, LURUACO, ATLANTICO"},
    {"id":3698,"nombre":"LA PUNTICA, LURUACO, ATLANTICO"},
    {"id":3699,"nombre":"LAS CARAS, LURUACO, ATLANTICO"},
    {"id":3700,"nombre":"LAS TABLAS, LURUACO, ATLANTICO"},
    {"id":3701,"nombre":"LOS LIMITES, LURUACO, ATLANTICO"},
    {"id":3702,"nombre":"LOS PENDALES, LURUACO, ATLANTICO"},
    {"id":3703,"nombre":"LURUACO, LURUACO, ATLANTICO"},
    {"id":3704,"nombre":"PALMAR DE CANDELARIA, LURUACO, ATLANTICO"},
    {"id":3705,"nombre":"SAN JUAN DE TOCAGUAS, LURUACO, ATLANTICO"},
    {"id":3706,"nombre":"SANTA CRUZ, LURUACO, ATLANTICO"},
    {"id":3707,"nombre":"MACANAL, MACANAL, BOYACA"},
    {"id":3708,"nombre":"NARANJOS, MACANAL, BOYACA"},
    {"id":3709,"nombre":"SAN CARLOS, MACANAL, BOYACA"},
    {"id":3710,"nombre":"SAN PEDRO DE MUCENO, MACANAL, BOYACA"},
    {"id":3711,"nombre":"BUENAVISTA, MACARAVITA, SANTANDER"},
    {"id":3712,"nombre":"BURAGA, MACARAVITA, SANTANDER"},
    {"id":3713,"nombre":"ILARCUTA - TIENDA NUEVA, MACARAVITA, SANTANDER"},
    {"id":3714,"nombre":"LA BRICHA, MACARAVITA, SANTANDER"},
    {"id":3715,"nombre":"LA HUERTA, MACARAVITA, SANTANDER"},
    {"id":3716,"nombre":"MACARAVITA, MACARAVITA, SANTANDER"},
    {"id":3717,"nombre":"RASGON, MACARAVITA, SANTANDER"},
    {"id":3718,"nombre":"LA FLORESTA, MACEO, ANTIOQUIA"},
    {"id":3719,"nombre":"LA SUSANA, MACEO, ANTIOQUIA"},
    {"id":3720,"nombre":"MACEO, MACEO, ANTIOQUIA"},
    {"id":3721,"nombre":"SAN JOSE DEL NUS, MACEO, ANTIOQUIA"},
    {"id":3722,"nombre":"CORRALILLOS, MACHETA, CUNDINAMARCA"},
    {"id":3723,"nombre":"GASUCO, MACHETA, CUNDINAMARCA"},
    {"id":3724,"nombre":"MACHETA, MACHETA, CUNDINAMARCA"},
    {"id":3725,"nombre":"SAN LUIS, MACHETA, CUNDINAMARCA"},
    {"id":3726,"nombre":"ZOLANA, MACHETA, CUNDINAMARCA"},
    {"id":3727,"nombre":"CHAUTA, MADRID, CUNDINAMARCA"},
    {"id":3728,"nombre":"EL CORZO, MADRID, CUNDINAMARCA"},
    {"id":3729,"nombre":"MADRID, MADRID, CUNDINAMARCA"},
    {"id":3730,"nombre":"MOYANO, MADRID, CUNDINAMARCA"},
    {"id":3731,"nombre":"PABLO VI, MADRID, CUNDINAMARCA"},
    {"id":3732,"nombre":"PUENTE DE PIEDRA, MADRID, CUNDINAMARCA"},
    {"id":3733,"nombre":"BARBOSA, MAGANGUE, BOLIVAR"},
    {"id":3734,"nombre":"BARRANCA DE YUCA, MAGANGUE, BOLIVAR"},
    {"id":3735,"nombre":"BARRANCA, MAGANGUE, BOLIVAR"},
    {"id":3736,"nombre":"BETANIA, MAGANGUE, BOLIVAR"},
    {"id":3737,"nombre":"BOCA DE GUAMAL, MAGANGUE, BOLIVAR"},
    {"id":3738,"nombre":"BOCA DE SAN ANTONIO, MAGANGUE, BOLIVAR"},
    {"id":3739,"nombre":"CASCAJAL, MAGANGUE, BOLIVAR"},
    {"id":3740,"nombre":"CEIBAL, MAGANGUE, BOLIVAR"},
    {"id":3741,"nombre":"COYONGAL, MAGANGUE, BOLIVAR"},
    {"id":3742,"nombre":"EL CUATRO, MAGANGUE, BOLIVAR"},
    {"id":3743,"nombre":"EL RETIRO, MAGANGUE, BOLIVAR"},
    {"id":3744,"nombre":"EMAUS, MAGANGUE, BOLIVAR"},
    {"id":3745,"nombre":"GUAZO, MAGANGUE, BOLIVAR"},
    {"id":3746,"nombre":"HENEQUEN, MAGANGUE, BOLIVAR"},
    {"id":3747,"nombre":"ISLA DE PERICO, MAGANGUE, BOLIVAR"},
    {"id":3748,"nombre":"ISLA GRANDE, MAGANGUE, BOLIVAR"},
    {"id":3749,"nombre":"JUAN ARIAS, MAGANGUE, BOLIVAR"},
    {"id":3750,"nombre":"LA PASCUALA, MAGANGUE, BOLIVAR"},
    {"id":3751,"nombre":"LA VENTURA, MAGANGUE, BOLIVAR"},
    {"id":3752,"nombre":"LAS BRISAS, MAGANGUE, BOLIVAR"},
    {"id":3753,"nombre":"LAS MARIAS, MAGANGUE, BOLIVAR"},
    {"id":3754,"nombre":"LAS PAVAS, MAGANGUE, BOLIVAR"},
    {"id":3755,"nombre":"MADRID, MAGANGUE, BOLIVAR"},
    {"id":3756,"nombre":"MAGANGUE, MAGANGUE, BOLIVAR"},
    {"id":3757,"nombre":"PALMARITO, MAGANGUE, BOLIVAR"},
    {"id":3758,"nombre":"PANSEGUITA, MAGANGUE, BOLIVAR"},
    {"id":3759,"nombre":"PINALITO, MAGANGUE, BOLIVAR"},
    {"id":3760,"nombre":"PLAYA DE LAS FLORES, MAGANGUE, BOLIVAR"},
    {"id":3761,"nombre":"PROVIDENCIA, MAGANGUE, BOLIVAR"},
    {"id":3762,"nombre":"PUERTO AMOR, MAGANGUE, BOLIVAR"},
    {"id":3763,"nombre":"PUERTO KENNEDY, MAGANGUE, BOLIVAR"},
    {"id":3764,"nombre":"PUERTO NARIÑO, MAGANGUE, BOLIVAR"},
    {"id":3765,"nombre":"PUNTA DE CARTAGENA, MAGANGUE, BOLIVAR"},
    {"id":3766,"nombre":"ROMA, MAGANGUE, BOLIVAR"},
    {"id":3767,"nombre":"SABANETA, MAGANGUE, BOLIVAR"},
    {"id":3768,"nombre":"SAN ANTONIO, MAGANGUE, BOLIVAR"},
    {"id":3769,"nombre":"SAN ANTONITO, MAGANGUE, BOLIVAR"},
    {"id":3770,"nombre":"SAN JOSE DE LAS MARTAS, MAGANGUE, BOLIVAR"},
    {"id":3771,"nombre":"SAN RAFAEL DE CORTINA, MAGANGUE, BOLIVAR"},
    {"id":3772,"nombre":"SAN SEBASTIAN DE BUENAVISTA, MAGANGUE, BOLIVAR"},
    {"id":3773,"nombre":"SANTA COITA, MAGANGUE, BOLIVAR"},
    {"id":3774,"nombre":"SANTA FE, MAGANGUE, BOLIVAR"},
    {"id":3775,"nombre":"SANTA LUCIA, MAGANGUE, BOLIVAR"},
    {"id":3776,"nombre":"SANTA MONICA, MAGANGUE, BOLIVAR"},
    {"id":3777,"nombre":"SANTA PABLA, MAGANGUE, BOLIVAR"},
    {"id":3778,"nombre":"SITIO NUEVO, MAGANGUE, BOLIVAR"},
    {"id":3779,"nombre":"TACALOA, MAGANGUE, BOLIVAR"},
    {"id":3780,"nombre":"TACASALUMA, MAGANGUE, BOLIVAR"},
    {"id":3781,"nombre":"TOLU, MAGANGUE, BOLIVAR"},
    {"id":3782,"nombre":"TRES PUNTAS, MAGANGUE, BOLIVAR"},
    {"id":3783,"nombre":"EVITAR, MAHATES, BOLIVAR"},
    {"id":3784,"nombre":"GAMERO, MAHATES, BOLIVAR"},
    {"id":3785,"nombre":"MAHATES, MAHATES, BOLIVAR"},
    {"id":3786,"nombre":"MALAGANA, MAHATES, BOLIVAR"},
    {"id":3787,"nombre":"MANDINGA, MAHATES, BOLIVAR"},
    {"id":3788,"nombre":"SAN BASILIO DE PALENQUE, MAHATES, BOLIVAR"},
    {"id":3789,"nombre":"SAN JOAQUIN, MAHATES, BOLIVAR"},
    {"id":3790,"nombre":"CARRAIPIA, MAICAO, LA GUAJIRA"},
    {"id":3791,"nombre":"EL LIMONCITO, MAICAO, LA GUAJIRA"},
    {"id":3792,"nombre":"IPAPURE, MAICAO, LA GUAJIRA"},
    {"id":3793,"nombre":"LA ARENA, MAICAO, LA GUAJIRA"},
    {"id":3794,"nombre":"LA PAZ, MAICAO, LA GUAJIRA"},
    {"id":3795,"nombre":"MAICAO, MAICAO, LA GUAJIRA"},
    {"id":3796,"nombre":"MAJAYURA, MAICAO, LA GUAJIRA"},
    {"id":3797,"nombre":"MARANAMANA, MAICAO, LA GUAJIRA"},
    {"id":3798,"nombre":"PARAGUACHON, MAICAO, LA GUAJIRA"},
    {"id":3799,"nombre":"YOTOJOROY, MAICAO, LA GUAJIRA"},
    {"id":3800,"nombre":"BELLAVISTA, MAJAGUAL, SUCRE"},
    {"id":3801,"nombre":"EDUARDO SANTOS, MAJAGUAL, SUCRE"},
    {"id":3802,"nombre":"EL NARANJO, MAJAGUAL, SUCRE"},
    {"id":3803,"nombre":"LA SIERPITA, MAJAGUAL, SUCRE"},
    {"id":3804,"nombre":"LAS CANDELARIAS, MAJAGUAL, SUCRE"},
    {"id":3805,"nombre":"LAS PALMITAS, MAJAGUAL, SUCRE"},
    {"id":3806,"nombre":"LEON BLANCO, MAJAGUAL, SUCRE"},
    {"id":3807,"nombre":"LOS PATOS, MAJAGUAL, SUCRE"},
    {"id":3808,"nombre":"MAJAGUAL, MAJAGUAL, SUCRE"},
    {"id":3809,"nombre":"MIRAFLORES, MAJAGUAL, SUCRE"},
    {"id":3810,"nombre":"PALMAR TIPICO, MAJAGUAL, SUCRE"},
    {"id":3811,"nombre":"PALMARITO, MAJAGUAL, SUCRE"},
    {"id":3812,"nombre":"PIZA, MAJAGUAL, SUCRE"},
    {"id":3813,"nombre":"PUEBLONUEVO, MAJAGUAL, SUCRE"},
    {"id":3814,"nombre":"SAN MIGUEL, MAJAGUAL, SUCRE"},
    {"id":3815,"nombre":"SAN ROQUE, MAJAGUAL, SUCRE"},
    {"id":3816,"nombre":"SANTANDER, MAJAGUAL, SUCRE"},
    {"id":3817,"nombre":"SINCELEJITO, MAJAGUAL, SUCRE"},
    {"id":3818,"nombre":"TOMALA, MAJAGUAL, SUCRE"},
    {"id":3819,"nombre":"ZAPATA, MAJAGUAL, SUCRE"},
    {"id":3820,"nombre":"MALAGA, MALAGA, SANTANDER"},
    {"id":3821,"nombre":"CAIMITAL, MALAMBO, ATLANTICO"},
    {"id":3822,"nombre":"CARACOLI, MALAMBO, ATLANTICO"},
    {"id":3823,"nombre":"EL ESFUERZO, MALAMBO, ATLANTICO"},
    {"id":3824,"nombre":"LA AGUADA, MALAMBO, ATLANTICO"},
    {"id":3825,"nombre":"MALAMBO, MALAMBO, ATLANTICO"},
    {"id":3826,"nombre":"CHAMBU, MALLAMA, NARIÑO"},
    {"id":3827,"nombre":"CHUCUNES, MALLAMA, NARIÑO"},
    {"id":3828,"nombre":"EL GUABO, MALLAMA, NARIÑO"},
    {"id":3829,"nombre":"LA OSCURANA, MALLAMA, NARIÑO"},
    {"id":3830,"nombre":"PIEDRANCHA, MALLAMA, NARIÑO"},
    {"id":3831,"nombre":"PUERAN, MALLAMA, NARIÑO"},
    {"id":3832,"nombre":"PUSPUED, MALLAMA, NARIÑO"},
    {"id":3833,"nombre":"PUSUSQUER, MALLAMA, NARIÑO"},
    {"id":3834,"nombre":"SAN MIGUEL, MALLAMA, NARIÑO"},
    {"id":3835,"nombre":"MANATI, MANATI, ATLANTICO"},
    {"id":3836,"nombre":"GUAFALPINTADO, MANI, CASANARE"},
    {"id":3837,"nombre":"LA POYATA, MANI, CASANARE"},
    {"id":3838,"nombre":"LAS GAVIOTAS, MANI, CASANARE"},
    {"id":3839,"nombre":"MANI, MANI, CASANARE"},
    {"id":3840,"nombre":"PASO REAL DE GUARIMENA, MANI, CASANARE"},
    {"id":3841,"nombre":"SAN JOAQUIN - GARIBAY, MANI, CASANARE"},
    {"id":3842,"nombre":"SANTA ELENA DE CUSIVA, MANI, CASANARE"},
    {"id":3843,"nombre":"AGUA BONITA, MANIZALES, CALDAS"},
    {"id":3844,"nombre":"ALTO BONITO, MANIZALES, CALDAS"},
    {"id":3845,"nombre":"ALTO DE LISBOA, MANIZALES, CALDAS"},
    {"id":3846,"nombre":"ALTO DEL NARANJO, MANIZALES, CALDAS"},
    {"id":3847,"nombre":"ALTO TABLAZO, MANIZALES, CALDAS"},
    {"id":3848,"nombre":"BAJO TABLAZO, MANIZALES, CALDAS"},
    {"id":3849,"nombre":"COLOMBIA, MANIZALES, CALDAS"},
    {"id":3850,"nombre":"EL ARENILLO, MANIZALES, CALDAS"},
    {"id":3851,"nombre":"FONDITOS, MANIZALES, CALDAS"},
    {"id":3852,"nombre":"KILOMETRO 41, MANIZALES, CALDAS"},
    {"id":3853,"nombre":"LA AURORA, MANIZALES, CALDAS"},
    {"id":3854,"nombre":"LA CABANA, MANIZALES, CALDAS"},
    {"id":3855,"nombre":"LA CHINA, MANIZALES, CALDAS"},
    {"id":3856,"nombre":"LA CUCHILLA DEL SALADO, MANIZALES, CALDAS"},
    {"id":3857,"nombre":"LA GARRUCHA, MANIZALES, CALDAS"},
    {"id":3858,"nombre":"LA TRINIDAD, MANIZALES, CALDAS"},
    {"id":3859,"nombre":"LA UNION, MANIZALES, CALDAS"},
    {"id":3860,"nombre":"LA VIOLETA, MANIZALES, CALDAS"},
    {"id":3861,"nombre":"LAS PAVAS, MANIZALES, CALDAS"},
    {"id":3862,"nombre":"MANIZALES, MANIZALES, CALDAS"},
    {"id":3863,"nombre":"MINA RICA, MANIZALES, CALDAS"},
    {"id":3864,"nombre":"MINITAS, MANIZALES, CALDAS"},
    {"id":3865,"nombre":"SAN PEREGRINO, MANIZALES, CALDAS"},
    {"id":3866,"nombre":"MANTA, MANTA, CUNDINAMARCA"},
    {"id":3867,"nombre":"AGUABONITA, MANZANARES, CALDAS"},
    {"id":3868,"nombre":"LA CEIBA, MANZANARES, CALDAS"},
    {"id":3869,"nombre":"LAS MARGARITAS, MANZANARES, CALDAS"},
    {"id":3870,"nombre":"MANZANARES, MANZANARES, CALDAS"},
    {"id":3871,"nombre":"PLANES, MANZANARES, CALDAS"},
    {"id":3872,"nombre":"SAN JUAN - LA SIRIA, MANZANARES, CALDAS"},
    {"id":3873,"nombre":"SAN VICENTE, MANZANARES, CALDAS"},
    {"id":3874,"nombre":"BUENOS AIRES, MAPIRIPAN, META"},
    {"id":3875,"nombre":"EL ANZUELO, MAPIRIPAN, META"},
    {"id":3876,"nombre":"EL MIELON, MAPIRIPAN, META"},
    {"id":3877,"nombre":"GUACAMAYAS, MAPIRIPAN, META"},
    {"id":3878,"nombre":"LA COOPERATIVA, MAPIRIPAN, META"},
    {"id":3879,"nombre":"MAPIRIPAN, MAPIRIPAN, META"},
    {"id":3880,"nombre":"PUERTO ALVIRA, MAPIRIPAN, META"},
    {"id":3881,"nombre":"PUERTO SIARE, MAPIRIPAN, META"},
    {"id":3882,"nombre":"SARDINATA, MAPIRIPAN, META"},
    {"id":3883,"nombre":"MAPIRIPANA, MAPIRIPANA, GUAINIA"},
    {"id":3884,"nombre":"PUERTO ZANCUDO, MAPIRIPANA, GUAINIA"},
    {"id":3885,"nombre":"BOTON DE LEYVA, MARGARITA, BOLIVAR"},
    {"id":3886,"nombre":"CAIMITAL, MARGARITA, BOLIVAR"},
    {"id":3887,"nombre":"CANO MONO, MARGARITA, BOLIVAR"},
    {"id":3888,"nombre":"CANTERA, MARGARITA, BOLIVAR"},
    {"id":3889,"nombre":"CAUSADO, MARGARITA, BOLIVAR"},
    {"id":3890,"nombre":"CHILLOA, MARGARITA, BOLIVAR"},
    {"id":3891,"nombre":"COROCITO, MARGARITA, BOLIVAR"},
    {"id":3892,"nombre":"DONA JUANA, MARGARITA, BOLIVAR"},
    {"id":3893,"nombre":"EL BARRANCO, MARGARITA, BOLIVAR"},
    {"id":3894,"nombre":"EL ZAFIRO, MARGARITA, BOLIVAR"},
    {"id":3895,"nombre":"GUATACA SUR, MARGARITA, BOLIVAR"},
    {"id":3896,"nombre":"GUATAQUITA, MARGARITA, BOLIVAR"},
    {"id":3897,"nombre":"LA MONTANA, MARGARITA, BOLIVAR"},
    {"id":3898,"nombre":"LOS MANGOS, MARGARITA, BOLIVAR"},
    {"id":3899,"nombre":"MAMONCITO, MARGARITA, BOLIVAR"},
    {"id":3900,"nombre":"MARGARITA, MARGARITA, BOLIVAR"},
    {"id":3901,"nombre":"SAN IGNACIO, MARGARITA, BOLIVAR"},
    {"id":3902,"nombre":"SAN JOSE DE LOS TRAPICHES, MARGARITA, BOLIVAR"},
    {"id":3903,"nombre":"SAN MARTIN, MARGARITA, BOLIVAR"},
    {"id":3904,"nombre":"SANDOVAL, MARGARITA, BOLIVAR"},
    {"id":3905,"nombre":"SANDOVALITO, MARGARITA, BOLIVAR"},
    {"id":3906,"nombre":"ARROYO GRANDE, MARIA LA BAJA, BOLIVAR"},
    {"id":3907,"nombre":"COLU, MARIA LA BAJA, BOLIVAR"},
    {"id":3908,"nombre":"CORREA, MARIA LA BAJA, BOLIVAR"},
    {"id":3909,"nombre":"EL FLORIDO, MARIA LA BAJA, BOLIVAR"},
    {"id":3910,"nombre":"EL NISPERO, MARIA LA BAJA, BOLIVAR"},
    {"id":3911,"nombre":"EL PUERTO, MARIA LA BAJA, BOLIVAR"},
    {"id":3912,"nombre":"EL RECREO, MARIA LA BAJA, BOLIVAR"},
    {"id":3913,"nombre":"FLAMENCO, MARIA LA BAJA, BOLIVAR"},
    {"id":3914,"nombre":"LOS BELLOS, MARIA LA BAJA, BOLIVAR"},
    {"id":3915,"nombre":"MAJAGUAS, MARIA LA BAJA, BOLIVAR"},
    {"id":3916,"nombre":"MANPUJAN, MARIA LA BAJA, BOLIVAR"},
    {"id":3917,"nombre":"MARIA LA BAJA, MARIA LA BAJA, BOLIVAR"},
    {"id":3918,"nombre":"MATUYA, MARIA LA BAJA, BOLIVAR"},
    {"id":3919,"nombre":"NANGUMA, MARIA LA BAJA, BOLIVAR"},
    {"id":3920,"nombre":"NUEVA ESPERANZA, MARIA LA BAJA, BOLIVAR"},
    {"id":3921,"nombre":"NUEVA FLORIDA, MARIA LA BAJA, BOLIVAR"},
    {"id":3922,"nombre":"NUEVO RETEN, MARIA LA BAJA, BOLIVAR"},
    {"id":3923,"nombre":"PALO ALTICO, MARIA LA BAJA, BOLIVAR"},
    {"id":3924,"nombre":"PRIMERO DE JULIO, MARIA LA BAJA, BOLIVAR"},
    {"id":3925,"nombre":"PUEBLO NUEVO, MARIA LA BAJA, BOLIVAR"},
    {"id":3926,"nombre":"RETIRO NUEVO, MARIA LA BAJA, BOLIVAR"},
    {"id":3927,"nombre":"SAN JOSE DE PLAYA, MARIA LA BAJA, BOLIVAR"},
    {"id":3928,"nombre":"SAN PABLO, MARIA LA BAJA, BOLIVAR"},
    {"id":3929,"nombre":"BELEN, MARINILLA, ANTIOQUIA"},
    {"id":3930,"nombre":"LA DALIA, MARINILLA, ANTIOQUIA"},
    {"id":3931,"nombre":"LA PRIMAVERA, MARINILLA, ANTIOQUIA"},
    {"id":3932,"nombre":"MARINILLA, MARINILLA, ANTIOQUIA"},
    {"id":3933,"nombre":"GUAYABAL, MARIPI, BOYACA"},
    {"id":3934,"nombre":"GUAZO, MARIPI, BOYACA"},
    {"id":3935,"nombre":"MARIPI, MARIPI, BOYACA"},
    {"id":3936,"nombre":"NARAPAY, MARIPI, BOYACA"},
    {"id":3937,"nombre":"PORTACHUELO, MARIPI, BOYACA"},
    {"id":3938,"nombre":"SANTA ROSA, MARIPI, BOYACA"},
    {"id":3939,"nombre":"ZULIA, MARIPI, BOYACA"},
    {"id":3940,"nombre":"EL HATILLO, MARIQUITA, TOLIMA"},
    {"id":3941,"nombre":"LA ALBANIA, MARIQUITA, TOLIMA"},
    {"id":3942,"nombre":"LA CABANA, MARIQUITA, TOLIMA"},
    {"id":3943,"nombre":"MARIQUITA, MARIQUITA, TOLIMA"},
    {"id":3944,"nombre":"PITALITO, MARIQUITA, TOLIMA"},
    {"id":3945,"nombre":"CABRAS, MARMATO, CALDAS"},
    {"id":3946,"nombre":"ECHANDIA, MARMATO, CALDAS"},
    {"id":3947,"nombre":"EL LLANO, MARMATO, CALDAS"},
    {"id":3948,"nombre":"LA CUCHILLA, MARMATO, CALDAS"},
    {"id":3949,"nombre":"LA GARRUCHA, MARMATO, CALDAS"},
    {"id":3950,"nombre":"LA MIEL, MARMATO, CALDAS"},
    {"id":3951,"nombre":"MARMATO, MARMATO, CALDAS"},
    {"id":3952,"nombre":"SAN JUAN, MARMATO, CALDAS"},
    {"id":3953,"nombre":"EL PLACER, MARQUETALIA, CALDAS"},
    {"id":3954,"nombre":"MARQUETALIA, MARQUETALIA, CALDAS"},
    {"id":3955,"nombre":"SANTA ELENA, MARQUETALIA, CALDAS"},
    {"id":3956,"nombre":"ALTO CAUCA, MARSELLA, RISARALDA"},
    {"id":3957,"nombre":"EL GUAYABO, MARSELLA, RISARALDA"},
    {"id":3958,"nombre":"ESTACION PEREIRA, MARSELLA, RISARALDA"},
    {"id":3959,"nombre":"LA ARGENTINA, MARSELLA, RISARALDA"},
    {"id":3960,"nombre":"LA NUBIA, MARSELLA, RISARALDA"},
    {"id":3961,"nombre":"LA ORIENTAL, MARSELLA, RISARALDA"},
    {"id":3962,"nombre":"MARSELLA, MARSELLA, RISARALDA"},
    {"id":3963,"nombre":"MIRACAMPO, MARSELLA, RISARALDA"},
    {"id":3964,"nombre":"PLAN DE VIVIENDA EL PARAISO, MARSELLA, RISARALDA"},
    {"id":3965,"nombre":"PLAN DE VIVIENDA EL RAYO, MARSELLA, RISARALDA"},
    {"id":3966,"nombre":"PLAN DE VIVIENDA TACURRUMBI, MARSELLA, RISARALDA"},
    {"id":3967,"nombre":"PUEBLO TAPADO, MARSELLA, RISARALDA"},
    {"id":3968,"nombre":"EL PARAMO, MARULANDA, CALDAS"},
    {"id":3969,"nombre":"EL ZANCUDO, MARULANDA, CALDAS"},
    {"id":3970,"nombre":"MARULANDA, MARULANDA, CALDAS"},
    {"id":3971,"nombre":"MOLLEJONES, MARULANDA, CALDAS"},
    {"id":3972,"nombre":"MONTEBONITO, MARULANDA, CALDAS"},
    {"id":3973,"nombre":"PARAMO HERVEO, MARULANDA, CALDAS"},
    {"id":3974,"nombre":"MATANZA, MATANZA, SANTANDER"},
    {"id":3975,"nombre":"PATIO BARRIDO, MATANZA, SANTANDER"},
    {"id":3976,"nombre":"PAUJIL, MATANZA, SANTANDER"},
    {"id":3977,"nombre":"SAN FRANCISCO, MATANZA, SANTANDER"},
    {"id":3978,"nombre":"SANTA CRUZ DE LA COLINA, MATANZA, SANTANDER"},
    {"id":3979,"nombre":"AGUAS FRIAS, MEDELLIN, ANTIOQUIA"},
    {"id":3980,"nombre":"ALTAVISTA, MEDELLIN, ANTIOQUIA"},
    {"id":3981,"nombre":"MEDELLIN, MEDELLIN, ANTIOQUIA"},
    {"id":3982,"nombre":"PALMITAS, MEDELLIN, ANTIOQUIA"},
    {"id":3983,"nombre":"PEDREGAL, MEDELLIN, ANTIOQUIA"},
    {"id":3984,"nombre":"SAN ANTONIO DE PRADO, MEDELLIN, ANTIOQUIA"},
    {"id":3985,"nombre":"SAN CRISTOBAL, MEDELLIN, ANTIOQUIA"},
    {"id":3986,"nombre":"SANTA ELENA, MEDELLIN, ANTIOQUIA"},
    {"id":3987,"nombre":"EL ARENAL, MEDINA, CUNDINAMARCA"},
    {"id":3988,"nombre":"GAZABINA, MEDINA, CUNDINAMARCA"},
    {"id":3989,"nombre":"GAZADUJE, MEDINA, CUNDINAMARCA"},
    {"id":3990,"nombre":"GAZATAVENA, MEDINA, CUNDINAMARCA"},
    {"id":3991,"nombre":"LA ESMERALDA, MEDINA, CUNDINAMARCA"},
    {"id":3992,"nombre":"LA NAGUAYA, MEDINA, CUNDINAMARCA"},
    {"id":3993,"nombre":"LOS ALPES, MEDINA, CUNDINAMARCA"},
    {"id":3994,"nombre":"MEDINA, MEDINA, CUNDINAMARCA"},
    {"id":3995,"nombre":"MESA DE LOS REYES, MEDINA, CUNDINAMARCA"},
    {"id":3996,"nombre":"SAN PEDRO DE GUAJARAY, MEDINA, CUNDINAMARCA"},
    {"id":3997,"nombre":"SANTA TERESITA, MEDINA, CUNDINAMARCA"},
    {"id":3998,"nombre":"BOCA DE AME, MEDIO ATRATO, CHOCO"},
    {"id":3999,"nombre":"BOCA DE BEBARA, MEDIO ATRATO, CHOCO"},
    {"id":4000,"nombre":"CAMPOALEGRE, MEDIO ATRATO, CHOCO"},
    {"id":4001,"nombre":"EL LLANO DE BEBARA, MEDIO ATRATO, CHOCO"},
    {"id":4002,"nombre":"EL LLANO DE BEBARAMA, MEDIO ATRATO, CHOCO"},
    {"id":4003,"nombre":"BETE, MEDIO ATRATO, CHOCO"},
    {"id":4004,"nombre":"SAN ANTONIO DEL BUEY (CAMPO SANTO), MEDIO ATRATO, CHOCO"},
    {"id":4005,"nombre":"SAN JOSE DE BUEY (ALTO BUEY), MEDIO ATRATO, CHOCO"},
    {"id":4006,"nombre":"SAN ROQUE, MEDIO ATRATO, CHOCO"},
    {"id":4007,"nombre":"TANGUI, MEDIO ATRATO, CHOCO"},
    {"id":4008,"nombre":"ALMENDRO, MEDIO BAUDO, CHOCO"},
    {"id":4009,"nombre":"ARENAL, MEDIO BAUDO, CHOCO"},
    {"id":4010,"nombre":"BAUDOCITO, MEDIO BAUDO, CHOCO"},
    {"id":4011,"nombre":"BELLAVISTA, MEDIO BAUDO, CHOCO"},
    {"id":4012,"nombre":"BERIGUADO, MEDIO BAUDO, CHOCO"},
    {"id":4013,"nombre":"CURUNDO LA BACUA, MEDIO BAUDO, CHOCO"},
    {"id":4014,"nombre":"BOCA DE PEPE, MEDIO BAUDO, CHOCO"},
    {"id":4015,"nombre":"NUVO PLATANARES, MEDIO BAUDO, CHOCO"},
    {"id":4016,"nombre":"OGODO, MEDIO BAUDO, CHOCO"},
    {"id":4017,"nombre":"PIE DE PEPE, MEDIO BAUDO, CHOCO"},
    {"id":4018,"nombre":"PUERTO ADAN TORREIDO, MEDIO BAUDO, CHOCO"},
    {"id":4019,"nombre":"PUERTO MELUK, MEDIO BAUDO, CHOCO"},
    {"id":4020,"nombre":"SAN JOSE DE QUERA, MEDIO BAUDO, CHOCO"},
    {"id":4021,"nombre":"SAN MIGUEL BAUDOCITO, MEDIO BAUDO, CHOCO"},
    {"id":4022,"nombre":"BEBEDO, MEDIO SAN JUAN, CHOCO"},
    {"id":4023,"nombre":"BOCA DE SURUCO, MEDIO SAN JUAN, CHOCO"},
    {"id":4024,"nombre":"CHIQUICHOQUI, MEDIO SAN JUAN, CHOCO"},
    {"id":4025,"nombre":"DIPURDU, MEDIO SAN JUAN, CHOCO"},
    {"id":4026,"nombre":"EL GUAMO, MEDIO SAN JUAN, CHOCO"},
    {"id":4027,"nombre":"JIGUALITO, MEDIO SAN JUAN, CHOCO"},
    {"id":4028,"nombre":"LA RANCHA, MEDIO SAN JUAN, CHOCO"},
    {"id":4029,"nombre":"ANDAGOYA, MEDIO SAN JUAN, CHOCO"},
    {"id":4030,"nombre":"NOAMANA, MEDIO SAN JUAN, CHOCO"},
    {"id":4031,"nombre":"PAIMADO, MEDIO SAN JUAN, CHOCO"},
    {"id":4032,"nombre":"PRINGAMO, MEDIO SAN JUAN, CHOCO"},
    {"id":4033,"nombre":"SAN MARTIN (GUARAPITO), MEDIO SAN JUAN, CHOCO"},
    {"id":4034,"nombre":"SAN MIGUEL, MEDIO SAN JUAN, CHOCO"},
    {"id":4035,"nombre":"BALCONES DE SUMAPAZ, MELGAR, TOLIMA"},
    {"id":4036,"nombre":"CHIMBI, MELGAR, TOLIMA"},
    {"id":4037,"nombre":"CUALAMANA, MELGAR, TOLIMA"},
    {"id":4038,"nombre":"EL POBLADITO, MELGAR, TOLIMA"},
    {"id":4039,"nombre":"EL RUBI, MELGAR, TOLIMA"},
    {"id":4040,"nombre":"LA ESTANCIA I, MELGAR, TOLIMA"},
    {"id":4041,"nombre":"LA ESTANCIA II, MELGAR, TOLIMA"},
    {"id":4042,"nombre":"LA ESTANCIA, MELGAR, TOLIMA"},
    {"id":4043,"nombre":"MELGAR, MELGAR, TOLIMA"},
    {"id":4044,"nombre":"QUEBRADITAS 1, MELGAR, TOLIMA"},
    {"id":4045,"nombre":"QUEBRADITAS 2, MELGAR, TOLIMA"},
    {"id":4046,"nombre":"SAN JOSE DE LA COLORADA, MELGAR, TOLIMA"},
    {"id":4047,"nombre":"ALTO DE MAYO, MERCADERES, CAUCA"},
    {"id":4048,"nombre":"ARBOLEDAS, MERCADERES, CAUCA"},
    {"id":4049,"nombre":"CAJAMARCA, MERCADERES, CAUCA"},
    {"id":4050,"nombre":"CASA FRIA, MERCADERES, CAUCA"},
    {"id":4051,"nombre":"CURACAS, MERCADERES, CAUCA"},
    {"id":4052,"nombre":"EL BADO, MERCADERES, CAUCA"},
    {"id":4053,"nombre":"EL CARBONERO, MERCADERES, CAUCA"},
    {"id":4054,"nombre":"EL PILON, MERCADERES, CAUCA"},
    {"id":4055,"nombre":"EL ROSARIO, MERCADERES, CAUCA"},
    {"id":4056,"nombre":"ESMERALDAS, MERCADERES, CAUCA"},
    {"id":4057,"nombre":"LA DESPENSA, MERCADERES, CAUCA"},
    {"id":4058,"nombre":"LA PLAYA, MERCADERES, CAUCA"},
    {"id":4059,"nombre":"LOS ARBOLES, MERCADERES, CAUCA"},
    {"id":4060,"nombre":"LOS LLANOS, MERCADERES, CAUCA"},
    {"id":4061,"nombre":"LOS MEDIOS, MERCADERES, CAUCA"},
    {"id":4062,"nombre":"MERCADERES, MERCADERES, CAUCA"},
    {"id":4063,"nombre":"MOJARRAS, MERCADERES, CAUCA"},
    {"id":4064,"nombre":"SAN JOAQUIN, MERCADERES, CAUCA"},
    {"id":4065,"nombre":"SAN JUANITO, MERCADERES, CAUCA"},
    {"id":4066,"nombre":"SOMBRERILLOS, MERCADERES, CAUCA"},
    {"id":4067,"nombre":"TABLONCITO, MERCADERES, CAUCA"},
    {"id":4068,"nombre":"TABLONES ALTOS, MERCADERES, CAUCA"},
    {"id":4069,"nombre":"BRISAS DEL DUDA, MESETAS, META"},
    {"id":4070,"nombre":"EL MIRADOR, MESETAS, META"},
    {"id":4071,"nombre":"EL ORIENTE, MESETAS, META"},
    {"id":4072,"nombre":"EL TRIQUE, MESETAS, META"},
    {"id":4073,"nombre":"JARDIN DE PENAS, MESETAS, META"},
    {"id":4074,"nombre":"LA ARGENTINA, MESETAS, META"},
    {"id":4075,"nombre":"LA GUAJIRA, MESETAS, META"},
    {"id":4076,"nombre":"MESETAS, MESETAS, META"},
    {"id":4077,"nombre":"PUERTO NARIÑO, MESETAS, META"},
    {"id":4078,"nombre":"SAN ISIDRO, MESETAS, META"},
    {"id":4079,"nombre":"EL DIAMANTE, MILAN, CAQUETA"},
    {"id":4080,"nombre":"LA ILUSION MATICURU, MILAN, CAQUETA"},
    {"id":4081,"nombre":"LA RASTRA, MILAN, CAQUETA"},
    {"id":4082,"nombre":"MATICURU - GRANARIO, MILAN, CAQUETA"},
    {"id":4083,"nombre":"MILAN, MILAN, CAQUETA"},
    {"id":4084,"nombre":"REMOLINOS DE ARICUNTI, MILAN, CAQUETA"},
    {"id":4085,"nombre":"SAN ANTONIO DE GETUCHA, MILAN, CAQUETA"},
    {"id":4086,"nombre":"EL CANON, MIRANDA, CAUCA"},
    {"id":4087,"nombre":"MIRANDA, MIRANDA, CAUCA"},
    {"id":4088,"nombre":"ORTIGAL, MIRANDA, CAUCA"},
    {"id":4089,"nombre":"SANTA ANA, MIRANDA, CAUCA"},
    {"id":4090,"nombre":"TULIPAN, MIRANDA, CAUCA"},
    {"id":4091,"nombre":"ZANJON RICO, MIRANDA, CAUCA"},
    {"id":4092,"nombre":"MIRITI - PARANA, MIRITI - PARANA, AMAZONAS"},
    {"id":4093,"nombre":"LA JEGUADA, MISTRATO, RISARALDA"},
    {"id":4094,"nombre":"MISTRATO, MISTRATO, RISARALDA"},
    {"id":4095,"nombre":"PINAR DEL RIO, MISTRATO, RISARALDA"},
    {"id":4096,"nombre":"PUERTO DE ORO, MISTRATO, RISARALDA"},
    {"id":4097,"nombre":"QUEBRADA ARRIBA, MISTRATO, RISARALDA"},
    {"id":4098,"nombre":"SAN ANTONIO DE CHAMI, MISTRATO, RISARALDA"},
    {"id":4099,"nombre":"ACARICUARA, MITU, VAUPES"},
    {"id":4100,"nombre":"CAMANAOS, MITU, VAUPES"},
    {"id":4101,"nombre":"MACUANA, MITU, VAUPES"},
    {"id":4102,"nombre":"MITU, MITU, VAUPES"},
    {"id":4103,"nombre":"QUERARI, MITU, VAUPES"},
    {"id":4104,"nombre":"TIQUIE, MITU, VAUPES"},
    {"id":4105,"nombre":"VILLAFATIMA, MITU, VAUPES"},
    {"id":4106,"nombre":"CONDAGUA, MOCOA, PUTUMAYO"},
    {"id":4107,"nombre":"EL PEPINO, MOCOA, PUTUMAYO"},
    {"id":4108,"nombre":"GALLINAZO, MOCOA, PUTUMAYO"},
    {"id":4109,"nombre":"JAUNO, MOCOA, PUTUMAYO"},
    {"id":4110,"nombre":"JOSE MARIA HERNANDEZ, MOCOA, PUTUMAYO"},
    {"id":4111,"nombre":"MANGALPA, MOCOA, PUTUMAYO"},
    {"id":4112,"nombre":"MAYOYOQUE, MOCOA, PUTUMAYO"},
    {"id":4113,"nombre":"MOCOA, MOCOA, PUTUMAYO"},
    {"id":4114,"nombre":"PUERTO LIMON, MOCOA, PUTUMAYO"},
    {"id":4115,"nombre":"QUINORO, MOCOA, PUTUMAYO"},
    {"id":4116,"nombre":"SAN ROQUE, MOCOA, PUTUMAYO"},
    {"id":4117,"nombre":"SANTA LUCIA, MOCOA, PUTUMAYO"},
    {"id":4118,"nombre":"YUNGUILLO, MOCOA, PUTUMAYO"},
    {"id":4119,"nombre":"CERRO NEGRO, MOGOTES, SANTANDER"},
    {"id":4120,"nombre":"CUCHIQUIRA, MOGOTES, SANTANDER"},
    {"id":4121,"nombre":"EL GUANO, MOGOTES, SANTANDER"},
    {"id":4122,"nombre":"EL HOYO, MOGOTES, SANTANDER"},
    {"id":4123,"nombre":"FELISCO, MOGOTES, SANTANDER"},
    {"id":4124,"nombre":"FLORES, MOGOTES, SANTANDER"},
    {"id":4125,"nombre":"GUAURE, MOGOTES, SANTANDER"},
    {"id":4126,"nombre":"GUAYAGUATA, MOGOTES, SANTANDER"},
    {"id":4127,"nombre":"LA LOMITA, MOGOTES, SANTANDER"},
    {"id":4128,"nombre":"LA PALMITA, MOGOTES, SANTANDER"},
    {"id":4129,"nombre":"LOS CAUCHOS, MOGOTES, SANTANDER"},
    {"id":4130,"nombre":"MIRABEL, MOGOTES, SANTANDER"},
    {"id":4131,"nombre":"MOGOTES, MOGOTES, SANTANDER"},
    {"id":4132,"nombre":"MONCHIA, MOGOTES, SANTANDER"},
    {"id":4133,"nombre":"PITIGUAO, MOGOTES, SANTANDER"},
    {"id":4134,"nombre":"TUBUGA, MOGOTES, SANTANDER"},
    {"id":4135,"nombre":"EL JUNCO, MOLAGAVITA, SANTANDER"},
    {"id":4136,"nombre":"MOLAGAVITA, MOLAGAVITA, SANTANDER"},
    {"id":4137,"nombre":"CEDRO DE PIEDRA, MOMIL, CORDOBA"},
    {"id":4138,"nombre":"GUAYMARAL, MOMIL, CORDOBA"},
    {"id":4139,"nombre":"MOMIL, MOMIL, CORDOBA"},
    {"id":4140,"nombre":"PUEBLECITO (SAN MIGUEL), MOMIL, CORDOBA"},
    {"id":4141,"nombre":"SABANETA, MOMIL, CORDOBA"},
    {"id":4142,"nombre":"SACANA, MOMIL, CORDOBA"},
    {"id":4143,"nombre":"TREMENTINO, MOMIL, CORDOBA"},
    {"id":4144,"nombre":"ANCON, MOMPOS, BOLIVAR"},
    {"id":4145,"nombre":"BOMBA, MOMPOS, BOLIVAR"},
    {"id":4146,"nombre":"CALDERA, MOMPOS, BOLIVAR"},
    {"id":4147,"nombre":"CANDELARIA, MOMPOS, BOLIVAR"},
    {"id":4148,"nombre":"CARMEN DEL CICUCO, MOMPOS, BOLIVAR"},
    {"id":4149,"nombre":"EL GUAMO, MOMPOS, BOLIVAR"},
    {"id":4150,"nombre":"EL ROSARIO, MOMPOS, BOLIVAR"},
    {"id":4151,"nombre":"GUAIMARAL, MOMPOS, BOLIVAR"},
    {"id":4152,"nombre":"GUAIMARO, MOMPOS, BOLIVAR"},
    {"id":4153,"nombre":"GUATACA, MOMPOS, BOLIVAR"},
    {"id":4154,"nombre":"LA JAGUA, MOMPOS, BOLIVAR"},
    {"id":4155,"nombre":"LA LOBATA, MOMPOS, BOLIVAR"},
    {"id":4156,"nombre":"LA RINCONADA, MOMPOS, BOLIVAR"},
    {"id":4157,"nombre":"LA TRAVESIA, MOMPOS, BOLIVAR"},
    {"id":4158,"nombre":"LAS BOQUILLAS, MOMPOS, BOLIVAR"},
    {"id":4159,"nombre":"LOMA DE SIMON, MOMPOS, BOLIVAR"},
    {"id":4160,"nombre":"LOS PINONES, MOMPOS, BOLIVAR"},
    {"id":4161,"nombre":"MOMPOS, MOMPOS, BOLIVAR"},
    {"id":4162,"nombre":"PUEBLONUEVO, MOMPOS, BOLIVAR"},
    {"id":4163,"nombre":"SAN IGNACIO, MOMPOS, BOLIVAR"},
    {"id":4164,"nombre":"SAN LUIS, MOMPOS, BOLIVAR"},
    {"id":4165,"nombre":"SAN MARTIN, MOMPOS, BOLIVAR"},
    {"id":4166,"nombre":"SAN NICOLAS, MOMPOS, BOLIVAR"},
    {"id":4167,"nombre":"SANTA CRUZ, MOMPOS, BOLIVAR"},
    {"id":4168,"nombre":"SANTA HELENA, MOMPOS, BOLIVAR"},
    {"id":4169,"nombre":"SANTA ROSA, MOMPOS, BOLIVAR"},
    {"id":4170,"nombre":"SANTA TERESITA (TIERRA FIRME), MOMPOS, BOLIVAR"},
    {"id":4171,"nombre":"VILLA NUEVA, MOMPOS, BOLIVAR"},
    {"id":4172,"nombre":"CENTRO TUNJUELA, MONGUA, BOYACA"},
    {"id":4173,"nombre":"MONGUA, MONGUA, BOYACA"},
    {"id":4174,"nombre":"SIRGUAZA, MONGUA, BOYACA"},
    {"id":4175,"nombre":"TUNJUELO, MONGUA, BOYACA"},
    {"id":4176,"nombre":"MONGUI, MONGUI, BOYACA"},
    {"id":4177,"nombre":"LA LAJA, MONIQUIRA, BOYACA"},
    {"id":4178,"nombre":"MACIEGAL, MONIQUIRA, BOYACA"},
    {"id":4179,"nombre":"MONIQUIRA, MONIQUIRA, BOYACA"},
    {"id":4180,"nombre":"PAJONALES, MONIQUIRA, BOYACA"},
    {"id":4181,"nombre":"PANTANILLO, MONIQUIRA, BOYACA"},
    {"id":4182,"nombre":"PUENTE ROCADA, MONIQUIRA, BOYACA"},
    {"id":4183,"nombre":"UBAZA, MONIQUIRA, BOYACA"},
    {"id":4184,"nombre":"EL CARMELO, MONTEBELLO, ANTIOQUIA"},
    {"id":4185,"nombre":"EL TABLAZO, MONTEBELLO, ANTIOQUIA"},
    {"id":4186,"nombre":"MONTE DE VENADO, MONTEBELLO, ANTIOQUIA"},
    {"id":4187,"nombre":"MONTEBELLO, MONTEBELLO, ANTIOQUIA"},
    {"id":4188,"nombre":"SABALETAS, MONTEBELLO, ANTIOQUIA"},
    {"id":4189,"nombre":"BETANIA, MONTECRISTO, BOLIVAR"},
    {"id":4190,"nombre":"EL DORADO, MONTECRISTO, BOLIVAR"},
    {"id":4191,"nombre":"MONTECRISTO, MONTECRISTO, BOLIVAR"},
    {"id":4192,"nombre":"PARAISO, MONTECRISTO, BOLIVAR"},
    {"id":4193,"nombre":"PLATANAL, MONTECRISTO, BOLIVAR"},
    {"id":4194,"nombre":"PUEBLO LINDO, MONTECRISTO, BOLIVAR"},
    {"id":4195,"nombre":"PUEBLO NUEVO (REGENCIA), MONTECRISTO, BOLIVAR"},
    {"id":4196,"nombre":"PUERTO ESPANA, MONTECRISTO, BOLIVAR"},
    {"id":4197,"nombre":"RANGEL, MONTECRISTO, BOLIVAR"},
    {"id":4198,"nombre":"SAN AGUSTIN, MONTECRISTO, BOLIVAR"},
    {"id":4199,"nombre":"SAN MATEO, MONTECRISTO, BOLIVAR"},
    {"id":4200,"nombre":"TABURETERA, MONTECRISTO, BOLIVAR"},
    {"id":4201,"nombre":"VILLA URIBE, MONTECRISTO, BOLIVAR"},
    {"id":4202,"nombre":"BOCA DE URE, MONTELIBANO, CORDOBA"},
    {"id":4203,"nombre":"BOCAS DE PERRO, MONTELIBANO, CORDOBA"},
    {"id":4204,"nombre":"CORDOBA, MONTELIBANO, CORDOBA"},
    {"id":4205,"nombre":"EL ANCLAR, MONTELIBANO, CORDOBA"},
    {"id":4206,"nombre":"EL BRILLANTE, MONTELIBANO, CORDOBA"},
    {"id":4207,"nombre":"EL DISPARO, MONTELIBANO, CORDOBA"},
    {"id":4208,"nombre":"EL PALMAR, MONTELIBANO, CORDOBA"},
    {"id":4209,"nombre":"EL SOL, MONTELIBANO, CORDOBA"},
    {"id":4210,"nombre":"JUAN JOSE, MONTELIBANO, CORDOBA"},
    {"id":4211,"nombre":"LA NUEVA, MONTELIBANO, CORDOBA"},
    {"id":4212,"nombre":"LOS CORDOBAS, MONTELIBANO, CORDOBA"},
    {"id":4213,"nombre":"MONTELIBANO, MONTELIBANO, CORDOBA"},
    {"id":4214,"nombre":"MORALITO, MONTELIBANO, CORDOBA"},
    {"id":4215,"nombre":"PICA PICA, MONTELIBANO, CORDOBA"},
    {"id":4216,"nombre":"PUEBLECITO, MONTELIBANO, CORDOBA"},
    {"id":4217,"nombre":"PUERTO ANCHICA, MONTELIBANO, CORDOBA"},
    {"id":4218,"nombre":"PUERTO CAREPA, MONTELIBANO, CORDOBA"},
    {"id":4219,"nombre":"PUERTO LOPEZ, MONTELIBANO, CORDOBA"},
    {"id":4220,"nombre":"PUERTO NUEVO, MONTELIBANO, CORDOBA"},
    {"id":4221,"nombre":"SAN FRANCISCO DEL RAYO, MONTELIBANO, CORDOBA"},
    {"id":4222,"nombre":"TIERRADENTRO, MONTELIBANO, CORDOBA"},
    {"id":4223,"nombre":"URE, MONTELIBANO, CORDOBA"},
    {"id":4224,"nombre":"BARAYA, MONTENEGRO, QUINDIO"},
    {"id":4225,"nombre":"EL CASTILLO, MONTENEGRO, QUINDIO"},
    {"id":4226,"nombre":"EL GIGANTE, MONTENEGRO, QUINDIO"},
    {"id":4227,"nombre":"LA MONTANA, MONTENEGRO, QUINDIO"},
    {"id":4228,"nombre":"MACHO NEGRO, MONTENEGRO, QUINDIO"},
    {"id":4229,"nombre":"MONTENEGRO, MONTENEGRO, QUINDIO"},
    {"id":4230,"nombre":"ONCE CASAS, MONTENEGRO, QUINDIO"},
    {"id":4231,"nombre":"PUEBLO TAPADO, MONTENEGRO, QUINDIO"},
    {"id":4232,"nombre":"PUERTO SAMARIA, MONTENEGRO, QUINDIO"},
    {"id":4233,"nombre":"BUENOS AIRES, MONTERIA, CORDOBA"},
    {"id":4234,"nombre":"CANO VIEJO, MONTERIA, CORDOBA"},
    {"id":4235,"nombre":"EL BARSAL, MONTERIA, CORDOBA"},
    {"id":4236,"nombre":"EL CERRITO, MONTERIA, CORDOBA"},
    {"id":4237,"nombre":"EL DOCE, MONTERIA, CORDOBA"},
    {"id":4238,"nombre":"EL SABANAL, MONTERIA, CORDOBA"},
    {"id":4239,"nombre":"GUASIMAL, MONTERIA, CORDOBA"},
    {"id":4240,"nombre":"GUATEQUE, MONTERIA, CORDOBA"},
    {"id":4241,"nombre":"JARAQUIEL, MONTERIA, CORDOBA"},
    {"id":4242,"nombre":"LA MANTA, MONTERIA, CORDOBA"},
    {"id":4243,"nombre":"LA VICTORIA, MONTERIA, CORDOBA"},
    {"id":4244,"nombre":"LAS PALOMAS, MONTERIA, CORDOBA"},
    {"id":4245,"nombre":"LETICIA, MONTERIA, CORDOBA"},
    {"id":4246,"nombre":"LOMA VERDE, MONTERIA, CORDOBA"},
    {"id":4247,"nombre":"LOS GARZONES, MONTERIA, CORDOBA"},
    {"id":4248,"nombre":"MARTINICA, MONTERIA, CORDOBA"},
    {"id":4249,"nombre":"MONTERIA, MONTERIA, CORDOBA"},
    {"id":4250,"nombre":"MORINDO CENTRAL, MONTERIA, CORDOBA"},
    {"id":4251,"nombre":"NUEVA ESPERANZA, MONTERIA, CORDOBA"},
    {"id":4252,"nombre":"NUEVA LUCIA, MONTERIA, CORDOBA"},
    {"id":4253,"nombre":"NUEVO PARAISO, MONTERIA, CORDOBA"},
    {"id":4254,"nombre":"PATIO BONITO, MONTERIA, CORDOBA"},
    {"id":4255,"nombre":"PUEBLO BUHO, MONTERIA, CORDOBA"},
    {"id":4256,"nombre":"SAN ANTERITO, MONTERIA, CORDOBA"},
    {"id":4257,"nombre":"SAN ISIDRO, MONTERIA, CORDOBA"},
    {"id":4258,"nombre":"SANTA CLARA, MONTERIA, CORDOBA"},
    {"id":4259,"nombre":"SANTA ISABEL, MONTERIA, CORDOBA"},
    {"id":4260,"nombre":"SANTA LUCIA, MONTERIA, CORDOBA"},
    {"id":4261,"nombre":"TRES PALMAS, MONTERIA, CORDOBA"},
    {"id":4262,"nombre":"TRES PIEDRAS, MONTERIA, CORDOBA"},
    {"id":4263,"nombre":"BRISAS DE LLANO, MONTERREY, CASANARE"},
    {"id":4264,"nombre":"EL PORVENIR, MONTERREY, CASANARE"},
    {"id":4265,"nombre":"MONTERREY, MONTERREY, CASANARE"},
    {"id":4266,"nombre":"PALO NEGRO, MONTERREY, CASANARE"},
    {"id":4267,"nombre":"VILLA CAROLA, MONTERREY, CASANARE"},
    {"id":4268,"nombre":"MORELIA, MORELIA, CAQUETA"},
    {"id":4269,"nombre":"MORICHAL NUEVO, MORICHAL, GUAINIA"},
    {"id":4270,"nombre":"BRISAS DEL MAR, MORROA, SUCRE"},
    {"id":4271,"nombre":"CAMBIMBA, MORROA, SUCRE"},
    {"id":4272,"nombre":"EL RINCON, MORROA, SUCRE"},
    {"id":4273,"nombre":"EL YESO, MORROA, SUCRE"},
    {"id":4274,"nombre":"LAS FLORES, MORROA, SUCRE"},
    {"id":4275,"nombre":"MORROA, MORROA, SUCRE"},
    {"id":4276,"nombre":"SABANAS DE CALI, MORROA, SUCRE"},
    {"id":4277,"nombre":"SABANETA, MORROA, SUCRE"},
    {"id":4278,"nombre":"TIEMPO PERDIDO, MORROA, SUCRE"},
    {"id":4279,"nombre":"TUMBATORO, MORROA, SUCRE"},
    {"id":4280,"nombre":"MOTAVITA, MOTAVITA, BOYACA"},
    {"id":4281,"nombre":"PANELAS, MOTAVITA, BOYACA"},
    {"id":4282,"nombre":"EL BOSQUE, MURILLO, TOLIMA"},
    {"id":4283,"nombre":"LA ESPERANZA, MURILLO, TOLIMA"},
    {"id":4284,"nombre":"MURILLO, MURILLO, TOLIMA"},
    {"id":4285,"nombre":"GUADUALITO, MURINDO, ANTIOQUIA"},
    {"id":4286,"nombre":"JEDEGA, MURINDO, ANTIOQUIA"},
    {"id":4287,"nombre":"MURINDO, MURINDO, ANTIOQUIA"},
    {"id":4288,"nombre":"OPOGADO, MURINDO, ANTIOQUIA"},
    {"id":4289,"nombre":"SAN ALEJANDRO, MURINDO, ANTIOQUIA"},
    {"id":4290,"nombre":"TADIA, MURINDO, ANTIOQUIA"},
    {"id":4291,"nombre":"BEJUQUILLO, MUTATA, ANTIOQUIA"},
    {"id":4292,"nombre":"CAUCHERAS, MUTATA, ANTIOQUIA"},
    {"id":4293,"nombre":"MUTATA, MUTATA, ANTIOQUIA"},
    {"id":4294,"nombre":"PAVARANDO GRANDE, MUTATA, ANTIOQUIA"},
    {"id":4295,"nombre":"PAVARANDOCITO, MUTATA, ANTIOQUIA"},
    {"id":4296,"nombre":"VILLA ARTEAGA, MUTATA, ANTIOQUIA"},
    {"id":4297,"nombre":"LA LAGUNA, MUTISCUA, NORTE DE SANTANDER"},
    {"id":4298,"nombre":"MUTISCUA, MUTISCUA, NORTE DE SANTANDER"},
    {"id":4299,"nombre":"SUCRE, MUTISCUA, NORTE DE SANTANDER"},
    {"id":4300,"nombre":"MUZO, MUZO, BOYACA"},
    {"id":4301,"nombre":"VERDUN, MUZO, BOYACA"},
    {"id":4302,"nombre":"LLANO BUCO, NATAGA, HUILA"},
    {"id":4303,"nombre":"NATAGA, NATAGA, HUILA"},
    {"id":4304,"nombre":"PATIO BONITO, NATAGA, HUILA"},
    {"id":4305,"nombre":"YARUMAL, NATAGA, HUILA"},
    {"id":4306,"nombre":"BALSILLAS, NATAGAIMA, TOLIMA"},
    {"id":4307,"nombre":"LA PALMITA, NATAGAIMA, TOLIMA"},
    {"id":4308,"nombre":"MONTEFRIO, NATAGAIMA, TOLIMA"},
    {"id":4309,"nombre":"NATAGAIMA, NATAGAIMA, TOLIMA"},
    {"id":4310,"nombre":"PALMA ALTA, NATAGAIMA, TOLIMA"},
    {"id":4311,"nombre":"POCHARCO, NATAGAIMA, TOLIMA"},
    {"id":4312,"nombre":"PUEBLO NUEVO, NATAGAIMA, TOLIMA"},
    {"id":4313,"nombre":"RINCON DE ACHIQUE, NATAGAIMA, TOLIMA"},
    {"id":4314,"nombre":"TAMIRCO, NATAGAIMA, TOLIMA"},
    {"id":4315,"nombre":"VELU, NATAGAIMA, TOLIMA"},
    {"id":4316,"nombre":"BIJAGUAL, NECHI, ANTIOQUIA"},
    {"id":4317,"nombre":"CARGUEROS, NECHI, ANTIOQUIA"},
    {"id":4318,"nombre":"COLORADO, NECHI, ANTIOQUIA"},
    {"id":4319,"nombre":"LA CONCHA, NECHI, ANTIOQUIA"},
    {"id":4320,"nombre":"LAS FLORES, NECHI, ANTIOQUIA"},
    {"id":4321,"nombre":"NECHI, NECHI, ANTIOQUIA"},
    {"id":4322,"nombre":"CARIBIA, NECOCLI, ANTIOQUIA"},
    {"id":4323,"nombre":"CASA BLANCA, NECOCLI, ANTIOQUIA"},
    {"id":4324,"nombre":"EL BOBAL, NECOCLI, ANTIOQUIA"},
    {"id":4325,"nombre":"EL MELLITO, NECOCLI, ANTIOQUIA"},
    {"id":4326,"nombre":"EL TOTUMO, NECOCLI, ANTIOQUIA"},
    {"id":4327,"nombre":"LAS CHANGAS, NECOCLI, ANTIOQUIA"},
    {"id":4328,"nombre":"MULATOS, NECOCLI, ANTIOQUIA"},
    {"id":4329,"nombre":"NECOCLI, NECOCLI, ANTIOQUIA"},
    {"id":4330,"nombre":"PUEBLO NUEVO, NECOCLI, ANTIOQUIA"},
    {"id":4331,"nombre":"ZAPATA, NECOCLI, ANTIOQUIA"},
    {"id":4332,"nombre":"AGROVILLA, NEIRA, CALDAS"},
    {"id":4333,"nombre":"BARRIO MEDELLIN, NEIRA, CALDAS"},
    {"id":4334,"nombre":"LA CRISTALINA, NEIRA, CALDAS"},
    {"id":4335,"nombre":"LA ESPERANZA, NEIRA, CALDAS"},
    {"id":4336,"nombre":"LA FELICIA, NEIRA, CALDAS"},
    {"id":4337,"nombre":"LA ISLA, NEIRA, CALDAS"},
    {"id":4338,"nombre":"LLANO GRANDE, NEIRA, CALDAS"},
    {"id":4339,"nombre":"NEIRA, NEIRA, CALDAS"},
    {"id":4340,"nombre":"PAN DE AZUCAR, NEIRA, CALDAS"},
    {"id":4341,"nombre":"PUEBLO RICO, NEIRA, CALDAS"},
    {"id":4342,"nombre":"SAN JOSE, NEIRA, CALDAS"},
    {"id":4343,"nombre":"TAPIAS, NEIRA, CALDAS"},
    {"id":4344,"nombre":"AIPECITO, NEIVA, HUILA"},
    {"id":4345,"nombre":"CHAPINERO, NEIVA, HUILA"},
    {"id":4346,"nombre":"EL CAGUAN, NEIVA, HUILA"},
    {"id":4347,"nombre":"EL COLEGIO, NEIVA, HUILA"},
    {"id":4348,"nombre":"EL TRIUNFO, NEIVA, HUILA"},
    {"id":4349,"nombre":"FORTALECILLAS, NEIVA, HUILA"},
    {"id":4350,"nombre":"GUACIRCO, NEIVA, HUILA"},
    {"id":4351,"nombre":"MOTILON, NEIVA, HUILA"},
    {"id":4352,"nombre":"NEIVA, NEIVA, HUILA"},
    {"id":4353,"nombre":"PALACIO, NEIVA, HUILA"},
    {"id":4354,"nombre":"SAN ANTONIO DE ANACONIA, NEIVA, HUILA"},
    {"id":4355,"nombre":"SAN ANTONIO, NEIVA, HUILA"},
    {"id":4356,"nombre":"SAN BARTOLO, NEIVA, HUILA"},
    {"id":4357,"nombre":"SAN FRANCISCO, NEIVA, HUILA"},
    {"id":4358,"nombre":"SAN LUIS, NEIVA, HUILA"},
    {"id":4359,"nombre":"VEGALARGA, NEIVA, HUILA"},
    {"id":4360,"nombre":"CAMACHO, NEMOCON, CUNDINAMARCA"},
    {"id":4361,"nombre":"DIVINO NINO, NEMOCON, CUNDINAMARCA"},
    {"id":4362,"nombre":"EL ORATORIO, NEMOCON, CUNDINAMARCA"},
    {"id":4363,"nombre":"LA PUERTA, NEMOCON, CUNDINAMARCA"},
    {"id":4364,"nombre":"NEMOCON, NEMOCON, CUNDINAMARCA"},
    {"id":4365,"nombre":"PATIO BONITO, NEMOCON, CUNDINAMARCA"},
    {"id":4366,"nombre":"LA ESMERALDA, NILO, CUNDINAMARCA"},
    {"id":4367,"nombre":"NILO, NILO, CUNDINAMARCA"},
    {"id":4368,"nombre":"PUEBLO NUEVO, NILO, CUNDINAMARCA"},
    {"id":4369,"nombre":"EL CERRO, NIMAIMA, CUNDINAMARCA"},
    {"id":4370,"nombre":"NIMAIMA, NIMAIMA, CUNDINAMARCA"},
    {"id":4371,"nombre":"TOBIA, NIMAIMA, CUNDINAMARCA"},
    {"id":4372,"nombre":"BELENCITO, NOBSA, BOYACA"},
    {"id":4373,"nombre":"CHAMEZA MAYOR, NOBSA, BOYACA"},
    {"id":4374,"nombre":"DICHO, NOBSA, BOYACA"},
    {"id":4375,"nombre":"GALERAS, NOBSA, BOYACA"},
    {"id":4376,"nombre":"NAZARETH, NOBSA, BOYACA"},
    {"id":4377,"nombre":"NOBSA, NOBSA, BOYACA"},
    {"id":4378,"nombre":"PUNTA LARGA, NOBSA, BOYACA"},
    {"id":4379,"nombre":"UCUANGA, NOBSA, BOYACA"},
    {"id":4380,"nombre":"NOCAIMA, NOCAIMA, CUNDINAMARCA"},
    {"id":4381,"nombre":"TOBIA CHICA, NOCAIMA, CUNDINAMARCA"},
    {"id":4382,"nombre":"KILOMETRO 40, NORCASIA, CALDAS"},
    {"id":4383,"nombre":"LA QUIEBRA, NORCASIA, CALDAS"},
    {"id":4384,"nombre":"MONTEBELLO, NORCASIA, CALDAS"},
    {"id":4385,"nombre":"MOSCOVITA, NORCASIA, CALDAS"},
    {"id":4386,"nombre":"NORCASIA, NORCASIA, CALDAS"},
    {"id":4387,"nombre":"CURUNDO, NOVITA, CHOCO"},
    {"id":4388,"nombre":"EL BARRANCON, NOVITA, CHOCO"},
    {"id":4389,"nombre":"EL CAJON, NOVITA, CHOCO"},
    {"id":4390,"nombre":"EL TAMBITO, NOVITA, CHOCO"},
    {"id":4391,"nombre":"EL TIGRE, NOVITA, CHOCO"},
    {"id":4392,"nombre":"IRABUBU, NOVITA, CHOCO"},
    {"id":4393,"nombre":"JUNTAS DE TAMANA, NOVITA, CHOCO"},
    {"id":4394,"nombre":"LA PLAYITA, NOVITA, CHOCO"},
    {"id":4395,"nombre":"NOVITA, NOVITA, CHOCO"},
    {"id":4396,"nombre":"PLAZA DEL ROSARIO, NOVITA, CHOCO"},
    {"id":4397,"nombre":"SAN LORENZO, NOVITA, CHOCO"},
    {"id":4398,"nombre":"SESEGO, NOVITA, CHOCO"},
    {"id":4399,"nombre":"URABARA, NOVITA, CHOCO"},
    {"id":4400,"nombre":"EL BAJO, NUEVA GRANADA, MAGDALENA"},
    {"id":4401,"nombre":"LA GLORIA, NUEVA GRANADA, MAGDALENA"},
    {"id":4402,"nombre":"LAS TINAS, NUEVA GRANADA, MAGDALENA"},
    {"id":4403,"nombre":"LOS ANDES, NUEVA GRANADA, MAGDALENA"},
    {"id":4404,"nombre":"GRANADA, NUEVA GRANADA, MAGDALENA"},
    {"id":4405,"nombre":"NUEVA GRANADA, NUEVA GRANADA, MAGDALENA"},
    {"id":4406,"nombre":"NUEVO COLON, NUEVO COLON, BOYACA"},
    {"id":4407,"nombre":"SORCA, NUEVO COLON, BOYACA"},
    {"id":4408,"nombre":"BARRANQUILLA, NUNCHIA, CASANARE"},
    {"id":4409,"nombre":"EL CAUCHO, NUNCHIA, CASANARE"},
    {"id":4410,"nombre":"EL PRETEXTO, NUNCHIA, CASANARE"},
    {"id":4411,"nombre":"GUANAPALO, NUNCHIA, CASANARE"},
    {"id":4412,"nombre":"LA YOPALOSA, NUNCHIA, CASANARE"},
    {"id":4413,"nombre":"NUNCHIA, NUNCHIA, CASANARE"},
    {"id":4414,"nombre":"PEDREGAL, NUNCHIA, CASANARE"},
    {"id":4415,"nombre":"SANTA CRUZ, NUNCHIA, CASANARE"},
    {"id":4416,"nombre":"SIRIVANA, NUNCHIA, CASANARE"},
    {"id":4417,"nombre":"VIZERTA, NUNCHIA, CASANARE"},
    {"id":4418,"nombre":"APARTADO, NUQUI, CHOCO"},
    {"id":4419,"nombre":"ARUSI, NUQUI, CHOCO"},
    {"id":4420,"nombre":"CHORI, NUQUI, CHOCO"},
    {"id":4421,"nombre":"COQUI, NUQUI, CHOCO"},
    {"id":4422,"nombre":"JOBI, NUQUI, CHOCO"},
    {"id":4423,"nombre":"JURUBIDA, NUQUI, CHOCO"},
    {"id":4424,"nombre":"NUQUI, NUQUI, CHOCO"},
    {"id":4425,"nombre":"PANGUI, NUQUI, CHOCO"},
    {"id":4426,"nombre":"TERMALES - AGUA CALIENTE, NUQUI, CHOCO"},
    {"id":4427,"nombre":"TRIBUGA, NUQUI, CHOCO"},
    {"id":4428,"nombre":"BUENOS AIRES, OBANDO, VALLE DEL CAUCA"},
    {"id":4429,"nombre":"CRUCES, OBANDO, VALLE DEL CAUCA"},
    {"id":4430,"nombre":"EL CHUZO, OBANDO, VALLE DEL CAUCA"},
    {"id":4431,"nombre":"FRIAS, OBANDO, VALLE DEL CAUCA"},
    {"id":4432,"nombre":"JUAN DIAZ, OBANDO, VALLE DEL CAUCA"},
    {"id":4433,"nombre":"LA ESPERANZA, OBANDO, VALLE DEL CAUCA"},
    {"id":4434,"nombre":"OBANDO, OBANDO, VALLE DEL CAUCA"},
    {"id":4435,"nombre":"PUERTO MOLINA, OBANDO, VALLE DEL CAUCA"},
    {"id":4436,"nombre":"PUERTO SAMARIA, OBANDO, VALLE DEL CAUCA"},
    {"id":4437,"nombre":"SAN ISIDRO, OBANDO, VALLE DEL CAUCA"},
    {"id":4438,"nombre":"SAN JOSE, OBANDO, VALLE DEL CAUCA"},
    {"id":4439,"nombre":"VILLA RODAS, OBANDO, VALLE DEL CAUCA"},
    {"id":4440,"nombre":"AGUA BLANCA, OCAMONTE, SANTANDER"},
    {"id":4441,"nombre":"BUENAVISTA, OCAMONTE, SANTANDER"},
    {"id":4442,"nombre":"EL HATILLO, OCAMONTE, SANTANDER"},
    {"id":4443,"nombre":"LA CANADA, OCAMONTE, SANTANDER"},
    {"id":4444,"nombre":"MAUCHIA, OCAMONTE, SANTANDER"},
    {"id":4445,"nombre":"MIRAFLORES, OCAMONTE, SANTANDER"},
    {"id":4446,"nombre":"OCAMONTE, OCAMONTE, SANTANDER"},
    {"id":4447,"nombre":"OIBA, OIBA, SANTANDER"},
    {"id":4448,"nombre":"OICATA, OICATA, BOYACA"},
    {"id":4449,"nombre":"LLANADAS, OLAYA, ANTIOQUIA"},
    {"id":4450,"nombre":"OLAYA, OLAYA, ANTIOQUIA"},
    {"id":4451,"nombre":"SUCRE, OLAYA, ANTIOQUIA"},
    {"id":4452,"nombre":"ALFONSO LOPEZ PUMAREJO (FLORIDA), OLAYA HERRERA, NARIÑO"},
    {"id":4453,"nombre":"BELLAVISTA, OLAYA HERRERA, NARIÑO"},
    {"id":4454,"nombre":"CORDOBA (CARMEN), OLAYA HERRERA, NARIÑO"},
    {"id":4455,"nombre":"JORGE ELIECER GAITAN, OLAYA HERRERA, NARIÑO"},
    {"id":4456,"nombre":"LERIDA (LAS MARIAS), OLAYA HERRERA, NARIÑO"},
    {"id":4457,"nombre":"LOZANO TORRIJOS, OLAYA HERRERA, NARIÑO"},
    {"id":4458,"nombre":"MERIZALDE PORVENIR, OLAYA HERRERA, NARIÑO"},
    {"id":4459,"nombre":"BOCAS DE SATINGA, OLAYA HERRERA, NARIÑO"},
    {"id":4460,"nombre":"SAN JOSE CALABAZAL, OLAYA HERRERA, NARIÑO"},
    {"id":4461,"nombre":"SANTANDER SOLEDAD, OLAYA HERRERA, NARIÑO"},
    {"id":4462,"nombre":"URIBE URIBE, OLAYA HERRERA, NARIÑO"},
    {"id":4463,"nombre":"CAPILLA DEL CARMEN, ONZAGA, SANTANDER"},
    {"id":4464,"nombre":"EL TOPON, ONZAGA, SANTANDER"},
    {"id":4465,"nombre":"ONZAGA, ONZAGA, SANTANDER"},
    {"id":4466,"nombre":"PADUA, ONZAGA, SANTANDER"},
    {"id":4467,"nombre":"SUSA, ONZAGA, SANTANDER"},
    {"id":4468,"nombre":"EL CARMEN, OPORAPA, HUILA"},
    {"id":4469,"nombre":"OPORAPA, OPORAPA, HUILA"},
    {"id":4470,"nombre":"SAN ROQUE, OPORAPA, HUILA"},
    {"id":4471,"nombre":"BUENOS AIRES, ORITO, PUTUMAYO"},
    {"id":4472,"nombre":"CHURUYACO, ORITO, PUTUMAYO"},
    {"id":4473,"nombre":"EMPALME, ORITO, PUTUMAYO"},
    {"id":4474,"nombre":"JARDINES DE SUCUMBIOS, ORITO, PUTUMAYO"},
    {"id":4475,"nombre":"LA TESALIA, ORITO, PUTUMAYO"},
    {"id":4476,"nombre":"LUCITANIA CHURUYACO, ORITO, PUTUMAYO"},
    {"id":4477,"nombre":"ORITO, ORITO, PUTUMAYO"},
    {"id":4478,"nombre":"PORTUGAL, ORITO, PUTUMAYO"},
    {"id":4479,"nombre":"SAN JUAN VIDES, ORITO, PUTUMAYO"},
    {"id":4480,"nombre":"SAN VICENTE DE LUZON, ORITO, PUTUMAYO"},
    {"id":4481,"nombre":"SANTA ROSA DE SUCUMBIOS, ORITO, PUTUMAYO"},
    {"id":4482,"nombre":"SIBERIA, ORITO, PUTUMAYO"},
    {"id":4483,"nombre":"SIMON BOLIVAR, ORITO, PUTUMAYO"},
    {"id":4484,"nombre":"BANCO LARGO, OROCUE, CASANARE"},
    {"id":4485,"nombre":"BOCAS DEL CRAVO, OROCUE, CASANARE"},
    {"id":4486,"nombre":"CAMPO ALEGRE, OROCUE, CASANARE"},
    {"id":4487,"nombre":"COREA, OROCUE, CASANARE"},
    {"id":4488,"nombre":"CUMACO, OROCUE, CASANARE"},
    {"id":4489,"nombre":"EL ALGARROBO, OROCUE, CASANARE"},
    {"id":4490,"nombre":"LA LIBERTAD, OROCUE, CASANARE"},
    {"id":4491,"nombre":"OROCUE, OROCUE, CASANARE"},
    {"id":4492,"nombre":"PALMARITO, OROCUE, CASANARE"},
    {"id":4493,"nombre":"TUJUA, OROCUE, CASANARE"},
    {"id":4494,"nombre":"EL VERGEL, ORTEGA, TOLIMA"},
    {"id":4495,"nombre":"GUAIPA, ORTEGA, TOLIMA"},
    {"id":4496,"nombre":"HATO DE IGLESIA, ORTEGA, TOLIMA"},
    {"id":4497,"nombre":"LA MESA DE ORTEGA, ORTEGA, TOLIMA"},
    {"id":4498,"nombre":"LETICIA, ORTEGA, TOLIMA"},
    {"id":4499,"nombre":"LOS OLIVOS, ORTEGA, TOLIMA"},
    {"id":4500,"nombre":"OLAYA HERRERA, ORTEGA, TOLIMA"},
    {"id":4501,"nombre":"ORTEGA, ORTEGA, TOLIMA"},
    {"id":4502,"nombre":"PILU, ORTEGA, TOLIMA"},
    {"id":4503,"nombre":"PUEBLO NUEVO, ORTEGA, TOLIMA"},
    {"id":4504,"nombre":"PUENTE CUCUANA, ORTEGA, TOLIMA"},
    {"id":4505,"nombre":"SAMARIA, ORTEGA, TOLIMA"},
    {"id":4506,"nombre":"SAN VICENTE, ORTEGA, TOLIMA"},
    {"id":4507,"nombre":"CUCHILLA MORENA, OSPINA, NARIÑO"},
    {"id":4508,"nombre":"GAVILANES, OSPINA, NARIÑO"},
    {"id":4509,"nombre":"MERCEDES, OSPINA, NARIÑO"},
    {"id":4510,"nombre":"OSPINA, OSPINA, NARIÑO"},
    {"id":4511,"nombre":"SAN ISIDRO, OSPINA, NARIÑO"},
    {"id":4512,"nombre":"SAN MIGUEL, OSPINA, NARIÑO"},
    {"id":4513,"nombre":"BETANIA, OTANCHE, BOYACA"},
    {"id":4514,"nombre":"BUENAVISTA, OTANCHE, BOYACA"},
    {"id":4515,"nombre":"CACHIPAY, OTANCHE, BOYACA"},
    {"id":4516,"nombre":"CURUBITA, OTANCHE, BOYACA"},
    {"id":4517,"nombre":"OTANCHE, OTANCHE, BOYACA"},
    {"id":4518,"nombre":"PIZARRA, OTANCHE, BOYACA"},
    {"id":4519,"nombre":"SAN JOSE DE NAZARETH, OTANCHE, BOYACA"},
    {"id":4520,"nombre":"ALMAGRA, OVEJAS, SUCRE"},
    {"id":4521,"nombre":"CANUTAL, OVEJAS, SUCRE"},
    {"id":4522,"nombre":"CANUTALITO, OVEJAS, SUCRE"},
    {"id":4523,"nombre":"CHENGUE, OVEJAS, SUCRE"},
    {"id":4524,"nombre":"DAMASCO, OVEJAS, SUCRE"},
    {"id":4525,"nombre":"DON GABRIEL, OVEJAS, SUCRE"},
    {"id":4526,"nombre":"EL FLORAL, OVEJAS, SUCRE"},
    {"id":4527,"nombre":"EL PALMAR, OVEJAS, SUCRE"},
    {"id":4528,"nombre":"FLOR DEL MONTE, OVEJAS, SUCRE"},
    {"id":4529,"nombre":"LA PENA, OVEJAS, SUCRE"},
    {"id":4530,"nombre":"LOMA DEL BANCO, OVEJAS, SUCRE"},
    {"id":4531,"nombre":"OSOS, OVEJAS, SUCRE"},
    {"id":4532,"nombre":"OVEJAS, OVEJAS, SUCRE"},
    {"id":4533,"nombre":"PIJIGUAY, OVEJAS, SUCRE"},
    {"id":4534,"nombre":"SALITRAL, OVEJAS, SUCRE"},
    {"id":4535,"nombre":"SAN RAFAEL ALTO, OVEJAS, SUCRE"},
    {"id":4536,"nombre":"SAN RAFAEL, OVEJAS, SUCRE"},
    {"id":4537,"nombre":"PACHAVITA, PACHAVITA, BOYACA"},
    {"id":4538,"nombre":"PACHO, PACHO, CUNDINAMARCA"},
    {"id":4539,"nombre":"PASUNCHA, PACHO, CUNDINAMARCA"},
    {"id":4540,"nombre":"PACOA, PACOA, VAUPES"},
    {"id":4541,"nombre":"BUENOS AIRES, PACORA, CALDAS"},
    {"id":4542,"nombre":"CASTILLA, PACORA, CALDAS"},
    {"id":4543,"nombre":"LAS COLES, PACORA, CALDAS"},
    {"id":4544,"nombre":"LOS MORROS, PACORA, CALDAS"},
    {"id":4545,"nombre":"PACORA, PACORA, CALDAS"},
    {"id":4546,"nombre":"SAN BARTOLOME, PACORA, CALDAS"},
    {"id":4547,"nombre":"SAN LORENZO, PACORA, CALDAS"},
    {"id":4548,"nombre":"CALLE TIBIA, PADILLA, CAUCA"},
    {"id":4549,"nombre":"CUERNAVACA, PADILLA, CAUCA"},
    {"id":4550,"nombre":"EL CHAMIZO, PADILLA, CAUCA"},
    {"id":4551,"nombre":"EL DESCANSO, PADILLA, CAUCA"},
    {"id":4552,"nombre":"EL TETILLO, PADILLA, CAUCA"},
    {"id":4553,"nombre":"LA PAILA, PADILLA, CAUCA"},
    {"id":4554,"nombre":"LAS COSECHAS, PADILLA, CAUCA"},
    {"id":4555,"nombre":"LOS ROBLES, PADILLA, CAUCA"},
    {"id":4556,"nombre":"PADILLA, PADILLA, CAUCA"},
    {"id":4557,"nombre":"YARUMALES, PADILLA, CAUCA"},
    {"id":4558,"nombre":"PAICOL, PAICOL, HUILA"},
    {"id":4559,"nombre":"EL BURRO, PAILITAS, CESAR"},
    {"id":4560,"nombre":"FLORESTA, PAILITAS, CESAR"},
    {"id":4561,"nombre":"LOS LLANOS, PAILITAS, CESAR"},
    {"id":4562,"nombre":"PAILITAS, PAILITAS, CESAR"},
    {"id":4563,"nombre":"PALESTINA, PAILITAS, CESAR"},
    {"id":4564,"nombre":"RAYITA, PAILITAS, CESAR"},
    {"id":4565,"nombre":"RIVERA, PAILITAS, CESAR"},
    {"id":4566,"nombre":"CUATRO CAMINOS, PAIME, CUNDINAMARCA"},
    {"id":4567,"nombre":"EL PLOMO (EL PARAISO), PAIME, CUNDINAMARCA"},
    {"id":4568,"nombre":"PAIME, PAIME, CUNDINAMARCA"},
    {"id":4569,"nombre":"TUDELA, PAIME, CUNDINAMARCA"},
    {"id":4570,"nombre":"VENECIA, PAIME, CUNDINAMARCA"},
    {"id":4571,"nombre":"EL VENADO, PAIPA, BOYACA"},
    {"id":4572,"nombre":"EL VOLCAN, PAIPA, BOYACA"},
    {"id":4573,"nombre":"PAIPA, PAIPA, BOYACA"},
    {"id":4574,"nombre":"PALERMO, PAIPA, BOYACA"},
    {"id":4575,"nombre":"PANTANO DE VARGAS, PAIPA, BOYACA"},
    {"id":4576,"nombre":"ROMITA, PAIPA, BOYACA"},
    {"id":4577,"nombre":"CORINTO, PAJARITO, BOYACA"},
    {"id":4578,"nombre":"PAJARITO, PAJARITO, BOYACA"},
    {"id":4579,"nombre":"AMBORCO, PALERMO, HUILA"},
    {"id":4580,"nombre":"BETANIA, PALERMO, HUILA"},
    {"id":4581,"nombre":"EL CARMEN, PALERMO, HUILA"},
    {"id":4582,"nombre":"EL JUNCAL, PALERMO, HUILA"},
    {"id":4583,"nombre":"EL PARAGUAY - ALELUYAS, PALERMO, HUILA"},
    {"id":4584,"nombre":"NILO, PALERMO, HUILA"},
    {"id":4585,"nombre":"OSPINA PEREZ, PALERMO, HUILA"},
    {"id":4586,"nombre":"PALERMO, PALERMO, HUILA"},
    {"id":4587,"nombre":"SAN JUAN, PALERMO, HUILA"},
    {"id":4588,"nombre":"PALMAR, PALMAR, SANTANDER"},
    {"id":4589,"nombre":"PALO GORDO, PALMAR, SANTANDER"},
    {"id":4590,"nombre":"BURRUSCOS, PALMAR DE VARELA, ATLANTICO"},
    {"id":4591,"nombre":"PALMAR DE VARELA, PALMAR DE VARELA, ATLANTICO"},
    {"id":4592,"nombre":"PALMAS DEL SOCORRO, PALMAS DEL SOCORRO, SANTANDER"},
    {"id":4593,"nombre":"AGUACLARA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4594,"nombre":"AMAIME, PALMIRA, VALLE DEL CAUCA"},
    {"id":4595,"nombre":"AYACUCHO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4596,"nombre":"BARRANCAS, PALMIRA, VALLE DEL CAUCA"},
    {"id":4597,"nombre":"BOLO ALIZAL, PALMIRA, VALLE DEL CAUCA"},
    {"id":4598,"nombre":"BOLO LA ITALIA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4599,"nombre":"BOLO SAN ISIDRO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4600,"nombre":"BOYACA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4601,"nombre":"CALUCE, PALMIRA, VALLE DEL CAUCA"},
    {"id":4602,"nombre":"CAUCASECO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4603,"nombre":"CHONTADURO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4604,"nombre":"COMBIA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4605,"nombre":"CORONADO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4606,"nombre":"GUANABANAL, PALMIRA, VALLE DEL CAUCA"},
    {"id":4607,"nombre":"GUAYABAL, PALMIRA, VALLE DEL CAUCA"},
    {"id":4608,"nombre":"JUANCHITO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4609,"nombre":"LA ACEQUIA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4610,"nombre":"LA BOLSA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4611,"nombre":"LA BUITRERA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4612,"nombre":"LA DOLORES, PALMIRA, VALLE DEL CAUCA"},
    {"id":4613,"nombre":"LA HERRADURA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4614,"nombre":"LA MANUELITA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4615,"nombre":"LA ORLIDIA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4616,"nombre":"LA PAMPA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4617,"nombre":"LA TORRE, PALMIRA, VALLE DEL CAUCA"},
    {"id":4618,"nombre":"LA ZAPATA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4619,"nombre":"MATAPALO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4620,"nombre":"MONTECLARO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4621,"nombre":"OBANDO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4622,"nombre":"PALMASECA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4623,"nombre":"PALMIRA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4624,"nombre":"POTRERILLO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4625,"nombre":"ROZO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4626,"nombre":"TABLONES, PALMIRA, VALLE DEL CAUCA"},
    {"id":4627,"nombre":"TENJO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4628,"nombre":"TIENDA NUEVA, PALMIRA, VALLE DEL CAUCA"},
    {"id":4629,"nombre":"TOCHE, PALMIRA, VALLE DEL CAUCA"},
    {"id":4630,"nombre":"ZAMORANO, PALMIRA, VALLE DEL CAUCA"},
    {"id":4631,"nombre":"ALGODONCILLO, PALMITO, SUCRE"},
    {"id":4632,"nombre":"GUAIMARAL, PALMITO, SUCRE"},
    {"id":4633,"nombre":"GUAIMI, PALMITO, SUCRE"},
    {"id":4634,"nombre":"PALMITO, PALMITO, SUCRE"},
    {"id":4635,"nombre":"ASTURIAS, PALOCABILDO, TOLIMA"},
    {"id":4636,"nombre":"BUENOS AIRES, PALOCABILDO, TOLIMA"},
    {"id":4637,"nombre":"PALOCABILDO, PALOCABILDO, TOLIMA"},
    {"id":4638,"nombre":"LA PENA, PAMPLONA, NORTE DE SANTANDER"},
    {"id":4639,"nombre":"LAUREANO GOMEZ, PAMPLONA, NORTE DE SANTANDER"},
    {"id":4640,"nombre":"NEGAVITA, PAMPLONA, NORTE DE SANTANDER"},
    {"id":4641,"nombre":"PAMPLONA, PAMPLONA, NORTE DE SANTANDER"},
    {"id":4642,"nombre":"EL DIAMANTE, PAMPLONITA, NORTE DE SANTANDER"},
    {"id":4643,"nombre":"PAMPLONITA, PAMPLONITA, NORTE DE SANTANDER"},
    {"id":4644,"nombre":"BOCAS DEL YARI, PANA PANA, GUAINIA"},
    {"id":4645,"nombre":"CAMPO ALEGRE, PANA PANA, GUAINIA"},
    {"id":4646,"nombre":"VENADO ISANA, PANA PANA, GUAINIA"},
    {"id":4647,"nombre":"PANDI, PANDI, CUNDINAMARCA"},
    {"id":4648,"nombre":"PANQUEBA, PANQUEBA, BOYACA"},
    {"id":4649,"nombre":"MORICHAL, PAPUNAUA, VAUPES"},
    {"id":4650,"nombre":"JUAN CURI, PARAMO, SANTANDER"},
    {"id":4651,"nombre":"PARAMO, PARAMO, SANTANDER"},
    {"id":4652,"nombre":"PUENTE MIRANDA, PARAMO, SANTANDER"},
    {"id":4653,"nombre":"EL ENGANO, PARATEBUENO, CUNDINAMARCA"},
    {"id":4654,"nombre":"EL JAPON, PARATEBUENO, CUNDINAMARCA"},
    {"id":4655,"nombre":"GUAICARAMO, PARATEBUENO, CUNDINAMARCA"},
    {"id":4656,"nombre":"MAYA, PARATEBUENO, CUNDINAMARCA"},
    {"id":4657,"nombre":"PARATEBUENO, PARATEBUENO, CUNDINAMARCA"},
    {"id":4658,"nombre":"SANTA CECILIA, PARATEBUENO, CUNDINAMARCA"},
    {"id":4659,"nombre":"VILLA PACELLY, PARATEBUENO, CUNDINAMARCA"},
    {"id":4660,"nombre":"ALTO DEL MOLINO, PASCA, CUNDINAMARCA"},
    {"id":4661,"nombre":"GUACHIPAS, PASCA, CUNDINAMARCA"},
    {"id":4662,"nombre":"PASCA, PASCA, CUNDINAMARCA"},
    {"id":4663,"nombre":"ANGANOY, PASTO, NARIÑO"},
    {"id":4664,"nombre":"ARANDA, PASTO, NARIÑO"},
    {"id":4665,"nombre":"ATICANCE, PASTO, NARIÑO"},
    {"id":4666,"nombre":"BAJO CASANARE, PASTO, NARIÑO"},
    {"id":4667,"nombre":"BUESAQUILLO CENTRO, PASTO, NARIÑO"},
    {"id":4668,"nombre":"BUESAQUILLO DOS, PASTO, NARIÑO"},
    {"id":4669,"nombre":"CABRERA, PASTO, NARIÑO"},
    {"id":4670,"nombre":"CANCHALA, PASTO, NARIÑO"},
    {"id":4671,"nombre":"CASABUY, PASTO, NARIÑO"},
    {"id":4672,"nombre":"CASTILLO LOMA, PASTO, NARIÑO"},
    {"id":4673,"nombre":"CATAMBUCO, PASTO, NARIÑO"},
    {"id":4674,"nombre":"CEROTAL, PASTO, NARIÑO"},
    {"id":4675,"nombre":"CHAVES, PASTO, NARIÑO"},
    {"id":4676,"nombre":"CHORRO DE CHACHAGUI, PASTO, NARIÑO"},
    {"id":4677,"nombre":"CUJACAL DOS, PASTO, NARIÑO"},
    {"id":4678,"nombre":"CUJACAL, PASTO, NARIÑO"},
    {"id":4679,"nombre":"CUVIJAN, PASTO, NARIÑO"},
    {"id":4680,"nombre":"DAZA, PASTO, NARIÑO"},
    {"id":4681,"nombre":"DOLORES, PASTO, NARIÑO"},
    {"id":4682,"nombre":"ECTANILLA, PASTO, NARIÑO"},
    {"id":4683,"nombre":"EL BARBERO, PASTO, NARIÑO"},
    {"id":4684,"nombre":"EL CONVENTO, PASTO, NARIÑO"},
    {"id":4685,"nombre":"EL ENCANO, PASTO, NARIÑO"},
    {"id":4686,"nombre":"EL PUERTO, PASTO, NARIÑO"},
    {"id":4687,"nombre":"EL ROSARIO, PASTO, NARIÑO"},
    {"id":4688,"nombre":"EL SOCORRO CIMARRON, PASTO, NARIÑO"},
    {"id":4689,"nombre":"GENOY, PASTO, NARIÑO"},
    {"id":4690,"nombre":"GUALMATAN, PASTO, NARIÑO"},
    {"id":4691,"nombre":"JAMONDINO, PASTO, NARIÑO"},
    {"id":4692,"nombre":"JONGOVITO, PASTO, NARIÑO"},
    {"id":4693,"nombre":"JUANOY, PASTO, NARIÑO"},
    {"id":4694,"nombre":"LA LAGUNA, PASTO, NARIÑO"},
    {"id":4695,"nombre":"LA VICTORIA, PASTO, NARIÑO"},
    {"id":4696,"nombre":"LOS ALISALES, PASTO, NARIÑO"},
    {"id":4697,"nombre":"LOS ANGELES, PASTO, NARIÑO"},
    {"id":4698,"nombre":"MARACHICO BAJO, PASTO, NARIÑO"},
    {"id":4699,"nombre":"MOCONDINO, PASTO, NARIÑO"},
    {"id":4700,"nombre":"MOTILON, PASTO, NARIÑO"},
    {"id":4701,"nombre":"NARIÑO, PASTO, NARIÑO"},
    {"id":4702,"nombre":"OBONUCO, PASTO, NARIÑO"},
    {"id":4703,"nombre":"PASIPARA, PASTO, NARIÑO"},
    {"id":4704,"nombre":"SAN JUAN DE PASTO, PASTO, NARIÑO"},
    {"id":4705,"nombre":"PORTACHUELO, PASTO, NARIÑO"},
    {"id":4706,"nombre":"PUENTE TABLA, PASTO, NARIÑO"},
    {"id":4707,"nombre":"SAN FERNANDO, PASTO, NARIÑO"},
    {"id":4708,"nombre":"SAN JOSE, PASTO, NARIÑO"},
    {"id":4709,"nombre":"SAN JUAN - LA JOSEFINA, PASTO, NARIÑO"},
    {"id":4710,"nombre":"SANCHEZ, PASTO, NARIÑO"},
    {"id":4711,"nombre":"SANTA BARBARA, PASTO, NARIÑO"},
    {"id":4712,"nombre":"TESCUAL, PASTO, NARIÑO"},
    {"id":4713,"nombre":"ANGULO, PATIA, CAUCA"},
    {"id":4714,"nombre":"BELLO HORIZONTE, PATIA, CAUCA"},
    {"id":4715,"nombre":"BETANIA, PATIA, CAUCA"},
    {"id":4716,"nombre":"BRISAS, PATIA, CAUCA"},
    {"id":4717,"nombre":"CHONDURAL, PATIA, CAUCA"},
    {"id":4718,"nombre":"CHONTADURO, PATIA, CAUCA"},
    {"id":4719,"nombre":"DON ALONSO, PATIA, CAUCA"},
    {"id":4720,"nombre":"EL ESTRECHO, PATIA, CAUCA"},
    {"id":4721,"nombre":"EL HOYO, PATIA, CAUCA"},
    {"id":4722,"nombre":"EL PLACER, PATIA, CAUCA"},
    {"id":4723,"nombre":"EL PURO, PATIA, CAUCA"},
    {"id":4724,"nombre":"EL TUNO, PATIA, CAUCA"},
    {"id":4725,"nombre":"EL ZARZAL, PATIA, CAUCA"},
    {"id":4726,"nombre":"GALINDEZ, PATIA, CAUCA"},
    {"id":4727,"nombre":"LA FLORIDA, PATIA, CAUCA"},
    {"id":4728,"nombre":"LA FONDA, PATIA, CAUCA"},
    {"id":4729,"nombre":"LA MESA, PATIA, CAUCA"},
    {"id":4730,"nombre":"LAS TALLAS, PATIA, CAUCA"},
    {"id":4731,"nombre":"MENDEZ, PATIA, CAUCA"},
    {"id":4732,"nombre":"PAN DE AZUCAR, PATIA, CAUCA"},
    {"id":4733,"nombre":"EL BORDO, PATIA, CAUCA"},
    {"id":4734,"nombre":"PATIA, PATIA, CAUCA"},
    {"id":4735,"nombre":"PIEDRASENTADA, PATIA, CAUCA"},
    {"id":4736,"nombre":"QUEBRADA OSCURA, PATIA, CAUCA"},
    {"id":4737,"nombre":"SACHAMATES, PATIA, CAUCA"},
    {"id":4738,"nombre":"SAJANDI, PATIA, CAUCA"},
    {"id":4739,"nombre":"SANTA CRUZ, PATIA, CAUCA"},
    {"id":4740,"nombre":"SANTA ROSA BAJA, PATIA, CAUCA"},
    {"id":4741,"nombre":"VERSALLES BOQUIRROTO, PATIA, CAUCA"},
    {"id":4742,"nombre":"AGUAFRIA, PAUNA, BOYACA"},
    {"id":4743,"nombre":"PAUNA, PAUNA, BOYACA"},
    {"id":4744,"nombre":"QUEBRADA SECA, PAUNA, BOYACA"},
    {"id":4745,"nombre":"MORCOTE, PAYA, BOYACA"},
    {"id":4746,"nombre":"PAYA, PAYA, BOYACA"},
    {"id":4747,"nombre":"AGUAVERDE, PAZ DE ARIPORO, CASANARE"},
    {"id":4748,"nombre":"BOCAS DE LA HERMOSA, PAZ DE ARIPORO, CASANARE"},
    {"id":4749,"nombre":"CANO CHIQUITO, PAZ DE ARIPORO, CASANARE"},
    {"id":4750,"nombre":"CENTRO GAITAN, PAZ DE ARIPORO, CASANARE"},
    {"id":4751,"nombre":"EL BORAL, PAZ DE ARIPORO, CASANARE"},
    {"id":4752,"nombre":"LA AGUADA, PAZ DE ARIPORO, CASANARE"},
    {"id":4753,"nombre":"LAS GUAMAS, PAZ DE ARIPORO, CASANARE"},
    {"id":4754,"nombre":"MONTANA DEL TOTUMO, PAZ DE ARIPORO, CASANARE"},
    {"id":4755,"nombre":"PAZ DE ARIPORO, PAZ DE ARIPORO, CASANARE"},
    {"id":4756,"nombre":"RINCON HONDO, PAZ DE ARIPORO, CASANARE"},
    {"id":4757,"nombre":"SAN PABLO, PAZ DE ARIPORO, CASANARE"},
    {"id":4758,"nombre":"PAZ DE RIO, PAZ DE RIO, BOYACA"},
    {"id":4759,"nombre":"PAZ VIEJO, PAZ DE RIO, BOYACA"},
    {"id":4760,"nombre":"BAHIA HONDA, PEDRAZA, MAGDALENA"},
    {"id":4761,"nombre":"BOMBA, PEDRAZA, MAGDALENA"},
    {"id":4762,"nombre":"EL BONGO, PEDRAZA, MAGDALENA"},
    {"id":4763,"nombre":"GUAQUIRI, PEDRAZA, MAGDALENA"},
    {"id":4764,"nombre":"HEREDIA, PEDRAZA, MAGDALENA"},
    {"id":4765,"nombre":"PEDRAZA, PEDRAZA, MAGDALENA"},
    {"id":4766,"nombre":"COSTILLA, PELAYA, CESAR"},
    {"id":4767,"nombre":"EL CRUCE, PELAYA, CESAR"},
    {"id":4768,"nombre":"EL EDEN, PELAYA, CESAR"},
    {"id":4769,"nombre":"EL SENDERITO, PELAYA, CESAR"},
    {"id":4770,"nombre":"EL TANQUE, PELAYA, CESAR"},
    {"id":4771,"nombre":"GUITARRILLA, PELAYA, CESAR"},
    {"id":4772,"nombre":"LA PASCUALA, PELAYA, CESAR"},
    {"id":4773,"nombre":"LAS DUMAS, PELAYA, CESAR"},
    {"id":4774,"nombre":"PELAYA, PELAYA, CESAR"},
    {"id":4775,"nombre":"SAN BERNARDO, PELAYA, CESAR"},
    {"id":4776,"nombre":"SANTANA (LA LOMA), PELAYA, CESAR"},
    {"id":4777,"nombre":"AGUABONITA, PENSILVANIA, CALDAS"},
    {"id":4778,"nombre":"ARBOLEDA, PENSILVANIA, CALDAS"},
    {"id":4779,"nombre":"BOLIVIA, PENSILVANIA, CALDAS"},
    {"id":4780,"nombre":"EL HIGUERON, PENSILVANIA, CALDAS"},
    {"id":4781,"nombre":"GUACAS, PENSILVANIA, CALDAS"},
    {"id":4782,"nombre":"LA LINDA, PENSILVANIA, CALDAS"},
    {"id":4783,"nombre":"LA RIOJA, PENSILVANIA, CALDAS"},
    {"id":4784,"nombre":"LA SOLEDAD ALTA, PENSILVANIA, CALDAS"},
    {"id":4785,"nombre":"LA TORRE, PENSILVANIA, CALDAS"},
    {"id":4786,"nombre":"PENSILVANIA, PENSILVANIA, CALDAS"},
    {"id":4787,"nombre":"PUEBLONUEVO, PENSILVANIA, CALDAS"},
    {"id":4788,"nombre":"SAMARIA, PENSILVANIA, CALDAS"},
    {"id":4789,"nombre":"SAN DANIEL, PENSILVANIA, CALDAS"},
    {"id":4790,"nombre":"BARBACOAS, PEQUE, ANTIOQUIA"},
    {"id":4791,"nombre":"EL AGRIO, PEQUE, ANTIOQUIA"},
    {"id":4792,"nombre":"JERIGUA, PEQUE, ANTIOQUIA"},
    {"id":4793,"nombre":"LA VEGA DEL INGLES, PEQUE, ANTIOQUIA"},
    {"id":4794,"nombre":"LOMITAS, PEQUE, ANTIOQUIA"},
    {"id":4795,"nombre":"LOS LLANOS, PEQUE, ANTIOQUIA"},
    {"id":4796,"nombre":"PEQUE, PEQUE, ANTIOQUIA"},
    {"id":4797,"nombre":"ALEGRIAS, PEREIRA, RISARALDA"},
    {"id":4798,"nombre":"ALTAGRACIA, PEREIRA, RISARALDA"},
    {"id":4799,"nombre":"ALTO ALEGRIAS, PEREIRA, RISARALDA"},
    {"id":4800,"nombre":"ALTO ERAZO, PEREIRA, RISARALDA"},
    {"id":4801,"nombre":"ARABIA, PEREIRA, RISARALDA"},
    {"id":4802,"nombre":"BARRIO EL BOSQUE, PEREIRA, RISARALDA"},
    {"id":4803,"nombre":"BELMONTE, PEREIRA, RISARALDA"},
    {"id":4804,"nombre":"BETANIA, PEREIRA, RISARALDA"},
    {"id":4805,"nombre":"BETULIA, PEREIRA, RISARALDA"},
    {"id":4806,"nombre":"BRISAS DEL CONDINA, PEREIRA, RISARALDA"},
    {"id":4807,"nombre":"CABANITA, PEREIRA, RISARALDA"},
    {"id":4808,"nombre":"CAIMALITO, PEREIRA, RISARALDA"},
    {"id":4809,"nombre":"CALLE LARGA, PEREIRA, RISARALDA"},
    {"id":4810,"nombre":"CANAVERAL, PEREIRA, RISARALDA"},
    {"id":4811,"nombre":"CANCELES, PEREIRA, RISARALDA"},
    {"id":4812,"nombre":"CESTILLAL, PEREIRA, RISARALDA"},
    {"id":4813,"nombre":"COMBIA, PEREIRA, RISARALDA"},
    {"id":4814,"nombre":"CONDINA GUACARY, PEREIRA, RISARALDA"},
    {"id":4815,"nombre":"EL BOSQUE, PEREIRA, RISARALDA"},
    {"id":4816,"nombre":"EL CHOCHO, PEREIRA, RISARALDA"},
    {"id":4817,"nombre":"EL CONGOLO, PEREIRA, RISARALDA"},
    {"id":4818,"nombre":"EL CONTENTO, PEREIRA, RISARALDA"},
    {"id":4819,"nombre":"EL JARDIN, PEREIRA, RISARALDA"},
    {"id":4820,"nombre":"EL JAZMIN, PEREIRA, RISARALDA"},
    {"id":4821,"nombre":"EL MANZANO, PEREIRA, RISARALDA"},
    {"id":4822,"nombre":"EL PLACER, PEREIRA, RISARALDA"},
    {"id":4823,"nombre":"EL POMO, PEREIRA, RISARALDA"},
    {"id":4824,"nombre":"EL PORVENIR, PEREIRA, RISARALDA"},
    {"id":4825,"nombre":"EL RETIRO, PEREIRA, RISARALDA"},
    {"id":4826,"nombre":"EL ROCIO, PEREIRA, RISARALDA"},
    {"id":4827,"nombre":"ESPERANZA GALICIA, PEREIRA, RISARALDA"},
    {"id":4828,"nombre":"ESTACION AZUFRAL, PEREIRA, RISARALDA"},
    {"id":4829,"nombre":"ESTACION VILLEGAS, PEREIRA, RISARALDA"},
    {"id":4830,"nombre":"ESTRELLA MORRON, PEREIRA, RISARALDA"},
    {"id":4831,"nombre":"FILO BONITO, PEREIRA, RISARALDA"},
    {"id":4832,"nombre":"FONDA CENTRAL, PEREIRA, RISARALDA"},
    {"id":4833,"nombre":"GALICIA ALTA, PEREIRA, RISARALDA"},
    {"id":4834,"nombre":"GILIPINAS, PEREIRA, RISARALDA"},
    {"id":4835,"nombre":"HERIBERTO HERRERA, PEREIRA, RISARALDA"},
    {"id":4836,"nombre":"HUERTAS, PEREIRA, RISARALDA"},
    {"id":4837,"nombre":"LA BANANERA, PEREIRA, RISARALDA"},
    {"id":4838,"nombre":"LA BELLA, PEREIRA, RISARALDA"},
    {"id":4839,"nombre":"LA CARBONERA, PEREIRA, RISARALDA"},
    {"id":4840,"nombre":"LA CONVENCION, PEREIRA, RISARALDA"},
    {"id":4841,"nombre":"LA CORALIA, PEREIRA, RISARALDA"},
    {"id":4842,"nombre":"LA ESTRELLA, PEREIRA, RISARALDA"},
    {"id":4843,"nombre":"LA FLORIDA, PEREIRA, RISARALDA"},
    {"id":4844,"nombre":"LA GRAMINEA, PEREIRA, RISARALDA"},
    {"id":4845,"nombre":"LA HONDA, PEREIRA, RISARALDA"},
    {"id":4846,"nombre":"LA LAGUNA DEL OTUN, PEREIRA, RISARALDA"},
    {"id":4847,"nombre":"LA PALMILLA, PEREIRA, RISARALDA"},
    {"id":4848,"nombre":"LA RENTA, PEREIRA, RISARALDA"},
    {"id":4849,"nombre":"LA SELVA, PEREIRA, RISARALDA"},
    {"id":4850,"nombre":"LA SUIZA, PEREIRA, RISARALDA"},
    {"id":4851,"nombre":"LA UNION, PEREIRA, RISARALDA"},
    {"id":4852,"nombre":"LA YE, PEREIRA, RISARALDA"},
    {"id":4853,"nombre":"LAGUNETA, PEREIRA, RISARALDA"},
    {"id":4854,"nombre":"LIBARE, PEREIRA, RISARALDA"},
    {"id":4855,"nombre":"LLANO GRANDE, PEREIRA, RISARALDA"},
    {"id":4856,"nombre":"MONTELARGO, PEREIRA, RISARALDA"},
    {"id":4857,"nombre":"MORELIA, PEREIRA, RISARALDA"},
    {"id":4858,"nombre":"MUNDO NUEVO, PEREIRA, RISARALDA"},
    {"id":4859,"nombre":"NARANJITO, PEREIRA, RISARALDA"},
    {"id":4860,"nombre":"NUEVA SIRIA, PEREIRA, RISARALDA"},
    {"id":4861,"nombre":"NUEVO SOL, PEREIRA, RISARALDA"},
    {"id":4862,"nombre":"PENJAMO, PEREIRA, RISARALDA"},
    {"id":4863,"nombre":"PEREIRA, PEREIRA, RISARALDA"},
    {"id":4864,"nombre":"PEREZ ALTO, PEREIRA, RISARALDA"},
    {"id":4865,"nombre":"PEREZ BAJO, PEREIRA, RISARALDA"},
    {"id":4866,"nombre":"PITAL DE COMBIA, PEREIRA, RISARALDA"},
    {"id":4867,"nombre":"PUEBLO NUEVO, PEREIRA, RISARALDA"},
    {"id":4868,"nombre":"PUERTO CALDAS, PEREIRA, RISARALDA"},
    {"id":4869,"nombre":"SAN CARLOS, PEREIRA, RISARALDA"},
    {"id":4870,"nombre":"SAN JOSE, PEREIRA, RISARALDA"},
    {"id":4871,"nombre":"SAN MARINO, PEREIRA, RISARALDA"},
    {"id":4872,"nombre":"SAN VICENTE, PEREIRA, RISARALDA"},
    {"id":4873,"nombre":"TRIBUNAS CONSOTA, PEREIRA, RISARALDA"},
    {"id":4874,"nombre":"TRIBUNAS CORCEGA, PEREIRA, RISARALDA"},
    {"id":4875,"nombre":"YARUMAL, PEREIRA, RISARALDA"},
    {"id":4876,"nombre":"YARUMAL, PEREIRA, RISARALDA"},
    {"id":4877,"nombre":"YARUMITO, PEREIRA, RISARALDA"},
    {"id":4878,"nombre":"EL HATO, PESCA, BOYACA"},
    {"id":4879,"nombre":"EL PALMAR, PESCA, BOYACA"},
    {"id":4880,"nombre":"PESCA, PESCA, BOYACA"},
    {"id":4881,"nombre":"SUANEME, PESCA, BOYACA"},
    {"id":4882,"nombre":"PIAMONTE, PIAMONTE, CAUCA"},
    {"id":4883,"nombre":"CRISTALES, PIEDECUESTA, SANTANDER"},
    {"id":4884,"nombre":"EL GRANADILLO, PIEDECUESTA, SANTANDER"},
    {"id":4885,"nombre":"LA ESPERANZA, PIEDECUESTA, SANTANDER"},
    {"id":4886,"nombre":"LA VEGA, PIEDECUESTA, SANTANDER"},
    {"id":4887,"nombre":"LAS COLINAS, PIEDECUESTA, SANTANDER"},
    {"id":4888,"nombre":"LOS CUROS, PIEDECUESTA, SANTANDER"},
    {"id":4889,"nombre":"MENSULI, PIEDECUESTA, SANTANDER"},
    {"id":4890,"nombre":"MESITAS SAN JAVIER, PIEDECUESTA, SANTANDER"},
    {"id":4891,"nombre":"PESCADERO, PIEDECUESTA, SANTANDER"},
    {"id":4892,"nombre":"PIEDECUESTA, PIEDECUESTA, SANTANDER"},
    {"id":4893,"nombre":"SAN FRANCISCO, PIEDECUESTA, SANTANDER"},
    {"id":4894,"nombre":"SAN ISIDRO, PIEDECUESTA, SANTANDER"},
    {"id":4895,"nombre":"SEVILLA, PIEDECUESTA, SANTANDER"},
    {"id":4896,"nombre":"TABLANCA, PIEDECUESTA, SANTANDER"},
    {"id":4897,"nombre":"UMPALA, PIEDECUESTA, SANTANDER"},
    {"id":4898,"nombre":"DOIMA, PIEDRAS, TOLIMA"},
    {"id":4899,"nombre":"GUATAQUISITO, PIEDRAS, TOLIMA"},
    {"id":4900,"nombre":"PIEDRAS, PIEDRAS, TOLIMA"},
    {"id":4901,"nombre":"CAMILO TORRES, PIENDAMO, CAUCA"},
    {"id":4902,"nombre":"CAMPOALEGRE, PIENDAMO, CAUCA"},
    {"id":4903,"nombre":"CANADULCE, PIENDAMO, CAUCA"},
    {"id":4904,"nombre":"CORRALES, PIENDAMO, CAUCA"},
    {"id":4905,"nombre":"EL AGRADO, PIENDAMO, CAUCA"},
    {"id":4906,"nombre":"EL ARRAYAN, PIENDAMO, CAUCA"},
    {"id":4907,"nombre":"EL CARMEN, PIENDAMO, CAUCA"},
    {"id":4908,"nombre":"EL MANGO, PIENDAMO, CAUCA"},
    {"id":4909,"nombre":"FARALLONES, PIENDAMO, CAUCA"},
    {"id":4910,"nombre":"LA UNION, PIENDAMO, CAUCA"},
    {"id":4911,"nombre":"LOMA CORTA, PIENDAMO, CAUCA"},
    {"id":4912,"nombre":"LOS UVALES, PIENDAMO, CAUCA"},
    {"id":4913,"nombre":"MATARREDONDA, PIENDAMO, CAUCA"},
    {"id":4914,"nombre":"MELCHO, PIENDAMO, CAUCA"},
    {"id":4915,"nombre":"ONCE DE NOVIEMBRE, PIENDAMO, CAUCA"},
    {"id":4916,"nombre":"PIENDAMO, PIENDAMO, CAUCA"},
    {"id":4917,"nombre":"SAN ISIDRO, PIENDAMO, CAUCA"},
    {"id":4918,"nombre":"SAN JOSE, PIENDAMO, CAUCA"},
    {"id":4919,"nombre":"SAN PEDRO, PIENDAMO, CAUCA"},
    {"id":4920,"nombre":"SANTA HELENA, PIENDAMO, CAUCA"},
    {"id":4921,"nombre":"TUNIA, PIENDAMO, CAUCA"},
    {"id":4922,"nombre":"USENDA, PIENDAMO, CAUCA"},
    {"id":4923,"nombre":"BARRAGAN, PIJAO, QUINDIO"},
    {"id":4924,"nombre":"LA MARIELA, PIJAO, QUINDIO"},
    {"id":4925,"nombre":"PIJAO, PIJAO, QUINDIO"},
    {"id":4926,"nombre":"EL CUCHARO, PINCHOTE, SANTANDER"},
    {"id":4927,"nombre":"LLANO GRANDE, PINCHOTE, SANTANDER"},
    {"id":4928,"nombre":"PINCHOTE, PINCHOTE, SANTANDER"},
    {"id":4929,"nombre":"ARMENIA, PINILLOS, BOLIVAR"},
    {"id":4930,"nombre":"LA RUFINA, PINILLOS, BOLIVAR"},
    {"id":4931,"nombre":"LA UNION, PINILLOS, BOLIVAR"},
    {"id":4932,"nombre":"LAS CONCHITAS, PINILLOS, BOLIVAR"},
    {"id":4933,"nombre":"LAS FLORES, PINILLOS, BOLIVAR"},
    {"id":4934,"nombre":"LOS CERRITOS, PINILLOS, BOLIVAR"},
    {"id":4935,"nombre":"MANTEQUERA, PINILLOS, BOLIVAR"},
    {"id":4936,"nombre":"PALENQUITO, PINILLOS, BOLIVAR"},
    {"id":4937,"nombre":"PALOMINO, PINILLOS, BOLIVAR"},
    {"id":4938,"nombre":"PINILLOS, PINILLOS, BOLIVAR"},
    {"id":4939,"nombre":"PUERTO COCA, PINILLOS, BOLIVAR"},
    {"id":4940,"nombre":"PUERTO LOPEZ, PINILLOS, BOLIVAR"},
    {"id":4941,"nombre":"RUFINA NUEVA, PINILLOS, BOLIVAR"},
    {"id":4942,"nombre":"SANTA COA, PINILLOS, BOLIVAR"},
    {"id":4943,"nombre":"SANTA ROSA, PINILLOS, BOLIVAR"},
    {"id":4944,"nombre":"AGUA VIVA, PIOJO, ATLANTICO"},
    {"id":4945,"nombre":"EL CERRITO, PIOJO, ATLANTICO"},
    {"id":4946,"nombre":"HIBACHARO, PIOJO, ATLANTICO"},
    {"id":4947,"nombre":"PIOJO, PIOJO, ATLANTICO"},
    {"id":4948,"nombre":"PISBA, PISBA, BOYACA"},
    {"id":4949,"nombre":"CARMELO, PITAL, HUILA"},
    {"id":4950,"nombre":"EL SOCORRO, PITAL, HUILA"},
    {"id":4951,"nombre":"MINAS, PITAL, HUILA"},
    {"id":4952,"nombre":"PITAL, PITAL, HUILA"},
    {"id":4953,"nombre":"BRUSELAS, PITALITO, HUILA"},
    {"id":4954,"nombre":"CHARGUAYACO, PITALITO, HUILA"},
    {"id":4955,"nombre":"CHILLURCO, PITALITO, HUILA"},
    {"id":4956,"nombre":"GUACACALLO, PITALITO, HUILA"},
    {"id":4957,"nombre":"LA LAGUNA, PITALITO, HUILA"},
    {"id":4958,"nombre":"PALMAR DE CRIOLLO, PITALITO, HUILA"},
    {"id":4959,"nombre":"PALMARITO, PITALITO, HUILA"},
    {"id":4960,"nombre":"PITALITO, PITALITO, HUILA"},
    {"id":4961,"nombre":"AVIANCA, PIVIJAY, MAGDALENA"},
    {"id":4962,"nombre":"CARABALLO, PIVIJAY, MAGDALENA"},
    {"id":4963,"nombre":"CARMEN DEL MAGDALENA (PARACO), PIVIJAY, MAGDALENA"},
    {"id":4964,"nombre":"CHINOBLAS, PIVIJAY, MAGDALENA"},
    {"id":4965,"nombre":"ESTACION VILLA, PIVIJAY, MAGDALENA"},
    {"id":4966,"nombre":"LAS CANOAS, PIVIJAY, MAGDALENA"},
    {"id":4967,"nombre":"LAS PIEDRAS, PIVIJAY, MAGDALENA"},
    {"id":4968,"nombre":"MEDIA LUNA, PIVIJAY, MAGDALENA"},
    {"id":4969,"nombre":"MONTERRUBIO, PIVIJAY, MAGDALENA"},
    {"id":4970,"nombre":"PARAISO, PIVIJAY, MAGDALENA"},
    {"id":4971,"nombre":"PINUELAS, PIVIJAY, MAGDALENA"},
    {"id":4972,"nombre":"PIVIJAY, PIVIJAY, MAGDALENA"},
    {"id":4973,"nombre":"PLACITAS, PIVIJAY, MAGDALENA"},
    {"id":4974,"nombre":"SALAMINITA, PIVIJAY, MAGDALENA"},
    {"id":4975,"nombre":"SAN JOSE DE LA MONTANA (GARRAPATA), PIVIJAY, MAGDALENA"},
    {"id":4976,"nombre":"BILBAO, PLANADAS, TOLIMA"},
    {"id":4977,"nombre":"BRUSELAS, PLANADAS, TOLIMA"},
    {"id":4978,"nombre":"GAITANIA, PLANADAS, TOLIMA"},
    {"id":4979,"nombre":"LA ESTRELLA, PLANADAS, TOLIMA"},
    {"id":4980,"nombre":"PLANADAS, PLANADAS, TOLIMA"},
    {"id":4981,"nombre":"RIO CLARO, PLANADAS, TOLIMA"},
    {"id":4982,"nombre":"SAN MIGUEL, PLANADAS, TOLIMA"},
    {"id":4983,"nombre":"SUR DE ATA, PLANADAS, TOLIMA"},
    {"id":4984,"nombre":"AGUAS VIVAS, PLANETA RICA, CORDOBA"},
    {"id":4985,"nombre":"ARENOSO, PLANETA RICA, CORDOBA"},
    {"id":4986,"nombre":"ARROYO ARENA, PLANETA RICA, CORDOBA"},
    {"id":4987,"nombre":"CALLE NUEVA, PLANETA RICA, CORDOBA"},
    {"id":4988,"nombre":"CAMPOBELLO, PLANETA RICA, CORDOBA"},
    {"id":4989,"nombre":"CAROLINA, PLANETA RICA, CORDOBA"},
    {"id":4990,"nombre":"CENTRO ALEGRE, PLANETA RICA, CORDOBA"},
    {"id":4991,"nombre":"COREA, PLANETA RICA, CORDOBA"},
    {"id":4992,"nombre":"EL ALMENDRO, PLANETA RICA, CORDOBA"},
    {"id":4993,"nombre":"EL BALSAL, PLANETA RICA, CORDOBA"},
    {"id":4994,"nombre":"EL DANUBIO, PLANETA RICA, CORDOBA"},
    {"id":4995,"nombre":"EL GOLERO, PLANETA RICA, CORDOBA"},
    {"id":4996,"nombre":"EL GUAYABO, PLANETA RICA, CORDOBA"},
    {"id":4997,"nombre":"EL REPARO, PLANETA RICA, CORDOBA"},
    {"id":4998,"nombre":"LAS CHIQUITAS, PLANETA RICA, CORDOBA"},
    {"id":4999,"nombre":"LAS CRUCES, PLANETA RICA, CORDOBA"},
    {"id":5000,"nombre":"LAS ICOTEAS, PLANETA RICA, CORDOBA"},
    {"id": 5001,"nombre": "LAS PELONAS, PLANETA RICA, CORDOBA"},
    {"id": 5002,"nombre": "LOMA AZUL, PLANETA RICA, CORDOBA"},
    {"id": 5003,"nombre": "LOMA DE PIEDRA, PLANETA RICA, CORDOBA"},
    {"id": 5004,"nombre": "LOS AZULES, PLANETA RICA, CORDOBA"},
    {"id": 5005,"nombre": "LOS CAFONGOS, PLANETA RICA, CORDOBA"},
    {"id": 5006,"nombre": "LOS CERROS, PLANETA RICA, CORDOBA"},
    {"id": 5007,"nombre": "LOS PASTELES, PLANETA RICA, CORDOBA"},
    {"id": 5008,"nombre": "LOS PLACERES, PLANETA RICA, CORDOBA"},
    {"id": 5009,"nombre": "MARANONAL, PLANETA RICA, CORDOBA"},
    {"id": 5010,"nombre": "MARIMBA, PLANETA RICA, CORDOBA"},
    {"id": 5011,"nombre": "MEDIO RANCHO, PLANETA RICA, CORDOBA"},
    {"id": 5012,"nombre": "NARANJAL, PLANETA RICA, CORDOBA"},
    {"id": 5013,"nombre": "NO HAY COMO DIOS, PLANETA RICA, CORDOBA"},
    {"id": 5014,"nombre": "NUEVO PARAISO, PLANETA RICA, CORDOBA"},
    {"id": 5015,"nombre": "OSCURANA ARRIBA, PLANETA RICA, CORDOBA"},
    {"id": 5016,"nombre": "PAMPLONA, PLANETA RICA, CORDOBA"},
    {"id": 5017,"nombre": "PINALITO, PLANETA RICA, CORDOBA"},
    {"id": 5018,"nombre": "PLANETA RICA, PLANETA RICA, CORDOBA"},
    {"id": 5019,"nombre": "PLANETICA, PLANETA RICA, CORDOBA"},
    {"id": 5020,"nombre": "PLAZA BONITA, PLANETA RICA, CORDOBA"},
    {"id": 5021,"nombre": "PROVIDENCIA, PLANETA RICA, CORDOBA"},
    {"id": 5022,"nombre": "PUEBLO RIZO, PLANETA RICA, CORDOBA"},
    {"id": 5023,"nombre": "PUEBLO SECO, PLANETA RICA, CORDOBA"},
    {"id": 5024,"nombre": "PUNTA VERDE, PLANETA RICA, CORDOBA"},
    {"id": 5025,"nombre": "REVOLCAO, PLANETA RICA, CORDOBA"},
    {"id": 5026,"nombre": "SAN ISIDRO, PLANETA RICA, CORDOBA"},
    {"id": 5027,"nombre": "SANTA ANA, PLANETA RICA, CORDOBA"},
    {"id": 5028,"nombre": "SANTA ROSA, PLANETA RICA, CORDOBA"},
    {"id": 5029,"nombre": "SOLIS, PLANETA RICA, CORDOBA"},
    {"id": 5030,"nombre": "AGUAS VIVAS, PLATO, MAGDALENA"},
    {"id": 5031,"nombre": "APURE, PLATO, MAGDALENA"},
    {"id": 5032,"nombre": "BUENAVISTA, PLATO, MAGDALENA"},
    {"id": 5033,"nombre": "CARMEN DEL MAGDALENA, PLATO, MAGDALENA"},
    {"id": 5034,"nombre": "CERRO GRANDE, PLATO, MAGDALENA"},
    {"id": 5035,"nombre": "CIENAGUETA, PLATO, MAGDALENA"},
    {"id": 5036,"nombre": "DISCIPLINA, PLATO, MAGDALENA"},
    {"id": 5037,"nombre": "LAS MERCEDES, PLATO, MAGDALENA"},
    {"id": 5038,"nombre": "LOS POZOS, PLATO, MAGDALENA"},
    {"id": 5039,"nombre": "PLATO, PLATO, MAGDALENA"},
    {"id": 5040,"nombre": "PUEBLO NUEVO, PLATO, MAGDALENA"},
    {"id": 5041,"nombre": "SAN ANTONIO DEL RIO, PLATO, MAGDALENA"},
    {"id": 5042,"nombre": "SAN JOSE DE MAGDALENA, PLATO, MAGDALENA"},
    {"id": 5043,"nombre": "SAN JOSE DEL PURGATORIO, PLATO, MAGDALENA"},
    {"id": 5044,"nombre": "ZARATE, PLATO, MAGDALENA"},
    {"id": 5045,"nombre": "ALTAMIRA, POLICARPA, NARIÑO"},
    {"id": 5046,"nombre": "EL EJIDO, POLICARPA, NARIÑO"},
    {"id": 5047,"nombre": "MADRIGAL, POLICARPA, NARIÑO"},
    {"id": 5048,"nombre": "POLICARPA, POLICARPA, NARIÑO"},
    {"id": 5049,"nombre": "SAN ROQUE, POLICARPA, NARIÑO"},
    {"id": 5050,"nombre": "SANCHEZ, POLICARPA, NARIÑO"},
    {"id": 5051,"nombre": "SANTA CRUZ, POLICARPA, NARIÑO"},
    {"id": 5052,"nombre": "PITALITO, POLONUEVO, ATLANTICO"},
    {"id": 5053,"nombre": "POLONUEVO, POLONUEVO, ATLANTICO"},
    {"id": 5054,"nombre": "LA RETIRADA, PONEDERA, ATLANTICO"},
    {"id": 5055,"nombre": "MARTILLO, PONEDERA, ATLANTICO"},
    {"id": 5056,"nombre": "PONEDERA, PONEDERA, ATLANTICO"},
    {"id": 5057,"nombre": "PUERTO GIRALDO, PONEDERA, ATLANTICO"},
    {"id": 5058,"nombre": "SANTA RITA, PONEDERA, ATLANTICO"},
    {"id": 5059,"nombre": "BELLO HORIZONTE, POPAYAN, CAUCA"},
    {"id": 5060,"nombre": "CAJETE, POPAYAN, CAUCA"},
    {"id": 5061,"nombre": "CALIBIO, POPAYAN, CAUCA"},
    {"id": 5062,"nombre": "EL CANELO, POPAYAN, CAUCA"},
    {"id": 5063,"nombre": "EL CHARCO, POPAYAN, CAUCA"},
    {"id": 5064,"nombre": "EL PLACER, POPAYAN, CAUCA"},
    {"id": 5065,"nombre": "EL SENDERO, POPAYAN, CAUCA"},
    {"id": 5066,"nombre": "EL TABLON, POPAYAN, CAUCA"},
    {"id": 5067,"nombre": "FIGUEROA, POPAYAN, CAUCA"},
    {"id": 5068,"nombre": "JULUMITO, POPAYAN, CAUCA"},
    {"id": 5069,"nombre": "LA MESETA, POPAYAN, CAUCA"},
    {"id": 5070,"nombre": "LA REJOYA, POPAYAN, CAUCA"},
    {"id": 5071,"nombre": "LA YUNGA, POPAYAN, CAUCA"},
    {"id": 5072,"nombre": "LAS MERCEDES, POPAYAN, CAUCA"},
    {"id": 5073,"nombre": "LAS PIEDRAS, POPAYAN, CAUCA"},
    {"id": 5074,"nombre": "OLAYA HERRERA, POPAYAN, CAUCA"},
    {"id": 5075,"nombre": "POBLAZON, POPAYAN, CAUCA"},
    {"id": 5076,"nombre": "POPAYAN, POPAYAN, CAUCA"},
    {"id": 5077,"nombre": "PUEBLILLO, POPAYAN, CAUCA"},
    {"id": 5078,"nombre": "PUELENJE, POPAYAN, CAUCA"},
    {"id": 5079,"nombre": "QUINTANA, POPAYAN, CAUCA"},
    {"id": 5080,"nombre": "SAMANGA, POPAYAN, CAUCA"},
    {"id": 5081,"nombre": "SAMUEL SILVERIO, POPAYAN, CAUCA"},
    {"id": 5082,"nombre": "SAN RAFAEL, POPAYAN, CAUCA"},
    {"id": 5083,"nombre": "SANTA BARBARA, POPAYAN, CAUCA"},
    {"id": 5084,"nombre": "SANTA ROSA, POPAYAN, CAUCA"},
    {"id": 5085,"nombre": "YANACONAS, POPAYAN, CAUCA"},
    {"id": 5086,"nombre": "EL BANCO, PORE, CASANARE"},
    {"id": 5087,"nombre": "LA PLATA, PORE, CASANARE"},
    {"id": 5088,"nombre": "PORE, PORE, CASANARE"},
    {"id": 5089,"nombre": "CARDENAS, POTOSI, NARIÑO"},
    {"id": 5090,"nombre": "CARRIZAL, POTOSI, NARIÑO"},
    {"id": 5091,"nombre": "CUASPUD NUCLEO, POTOSI, NARIÑO"},
    {"id": 5092,"nombre": "LA CABANA, POTOSI, NARIÑO"},
    {"id": 5093,"nombre": "LA CENTINELA, POTOSI, NARIÑO"},
    {"id": 5094,"nombre": "LA PALMA, POTOSI, NARIÑO"},
    {"id": 5095,"nombre": "MUESES, POTOSI, NARIÑO"},
    {"id": 5096,"nombre": "POTOSI, POTOSI, NARIÑO"},
    {"id": 5097,"nombre": "SAN ANTONIO, POTOSI, NARIÑO"},
    {"id": 5098,"nombre": "SAN FRANCISCO SINAI, POTOSI, NARIÑO"},
    {"id": 5099,"nombre": "SAN MARCOS, POTOSI, NARIÑO"},
    {"id": 5100,"nombre": "SAN PEDRO, POTOSI, NARIÑO"},
    {"id": 5101,"nombre": "VILLA NUEVA, POTOSI, NARIÑO"},
    {"id": 5102,"nombre": "YAMUESQUER, POTOSI, NARIÑO"},
    {"id": 5103,"nombre": "BOLIVAR, PRADERA, VALLE DEL CAUCA"},
    {"id": 5104,"nombre": "BOLO AZUL, PRADERA, VALLE DEL CAUCA"},
    {"id": 5105,"nombre": "BOLO BLANCO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5106,"nombre": "BOLO NEGRO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5107,"nombre": "EL HARTONAL, PRADERA, VALLE DEL CAUCA"},
    {"id": 5108,"nombre": "EL HIGUERONAL, PRADERA, VALLE DEL CAUCA"},
    {"id": 5109,"nombre": "EL LIBANO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5110,"nombre": "EL NOGAL, PRADERA, VALLE DEL CAUCA"},
    {"id": 5111,"nombre": "EL RECREO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5112,"nombre": "EL RETIRO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5113,"nombre": "LA CARBONERA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5114,"nombre": "LA FERIA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5115,"nombre": "LA FLORESTA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5116,"nombre": "LA GRANJA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5117,"nombre": "LA RUIZA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5118,"nombre": "LA TUPIA II, PRADERA, VALLE DEL CAUCA"},
    {"id": 5119,"nombre": "LA TUPIA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5120,"nombre": "LOMITAS, PRADERA, VALLE DEL CAUCA"},
    {"id": 5121,"nombre": "LOS NEGROS, PRADERA, VALLE DEL CAUCA"},
    {"id": 5122,"nombre": "MURILLO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5123,"nombre": "PARRAGA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5124,"nombre": "POTRERITO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5125,"nombre": "PRADERA, PRADERA, VALLE DEL CAUCA"},
    {"id": 5126,"nombre": "SAN ISIDRO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5127,"nombre": "VALLECITO, PRADERA, VALLE DEL CAUCA"},
    {"id": 5128,"nombre": "ACO, PRADO, TOLIMA"},
    {"id": 5129,"nombre": "MALTA, PRADO, TOLIMA"},
    {"id": 5130,"nombre": "MONTOSO, PRADO, TOLIMA"},
    {"id": 5131,"nombre": "PRADO, PRADO, TOLIMA"},
    {"id": 5132,"nombre": "LA CAJA, PUEBLO BELLO, CESAR"},
    {"id": 5133,"nombre": "LAS MINAS DE IRACAL, PUEBLO BELLO, CESAR"},
    {"id": 5134,"nombre": "NABUSIMAKE (SAN SEBASTIAN), PUEBLO BELLO, CESAR"},
    {"id": 5135,"nombre": "PALMARITO (NUEVO COLON), PUEBLO BELLO, CESAR"},
    {"id": 5136,"nombre": "PUEBLO BELLO, PUEBLO BELLO, CESAR"},
    {"id": 5137,"nombre": "AGUAS CLARAS, PUEBLO NUEVO, CORDOBA"},
    {"id": 5138,"nombre": "ARENA DEL SUR, PUEBLO NUEVO, CORDOBA"},
    {"id": 5139,"nombre": "ARROYO DE ARENA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5140,"nombre": "ARROYO GUADUAL, PUEBLO NUEVO, CORDOBA"},
    {"id": 5141,"nombre": "BETANIA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5142,"nombre": "CINTURA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5143,"nombre": "CORCOVADO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5144,"nombre": "EL ARCIAL, PUEBLO NUEVO, CORDOBA"},
    {"id": 5145,"nombre": "EL CAMPANO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5146,"nombre": "EL CONTENTO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5147,"nombre": "EL POBLADO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5148,"nombre": "EL VARAL, PUEBLO NUEVO, CORDOBA"},
    {"id": 5149,"nombre": "LA GRANJITA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5150,"nombre": "LA MAGDALENA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5151,"nombre": "LOS LIMONES, PUEBLO NUEVO, CORDOBA"},
    {"id": 5152,"nombre": "NEIVA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5153,"nombre": "NUEVA ESPERANZA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5154,"nombre": "PALMIRA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5155,"nombre": "PRIMAVERA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5156,"nombre": "PUEBLO NUEVO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5157,"nombre": "PUEBLO REGAO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5158,"nombre": "PUERTO LETICIA, PUEBLO NUEVO, CORDOBA"},
    {"id": 5159,"nombre": "PUERTO SANTO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5160,"nombre": "TAPA DE FRASCO, PUEBLO NUEVO, CORDOBA"},
    {"id": 5161,"nombre": "AGUITA, PUEBLO RICO, RISARALDA"},
    {"id": 5162,"nombre": "EL RECREO, PUEBLO RICO, RISARALDA"},
    {"id": 5163,"nombre": "ITAURY, PUEBLO RICO, RISARALDA"},
    {"id": 5164,"nombre": "PUEBLO RICO, PUEBLO RICO, RISARALDA"},
    {"id": 5165,"nombre": "SANTA CECILIA, PUEBLO RICO, RISARALDA"},
    {"id": 5166,"nombre": "TAIBA, PUEBLO RICO, RISARALDA"},
    {"id": 5167,"nombre": "VILLA CLARET, PUEBLO RICO, RISARALDA"},
    {"id": 5168,"nombre": "CALIFORNIA, PUEBLORRICO, ANTIOQUIA"},
    {"id": 5169,"nombre": "PUEBLORRICO, PUEBLORRICO, ANTIOQUIA"},
    {"id": 5170,"nombre": "SINAI, PUEBLORRICO, ANTIOQUIA"},
    {"id": 5171,"nombre": "BOCAS DE ARACATACA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5172,"nombre": "EL TRIUNFO, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5173,"nombre": "ISLA DE CATAQUITA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5174,"nombre": "ISLA DEL ROSARIO, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5175,"nombre": "NUEVA FRONTERA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5176,"nombre": "PALMIRA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5177,"nombre": "PUEBLOVIEJO, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5178,"nombre": "TASAJERA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5179,"nombre": "TIERRA NUEVA, PUEBLOVIEJO, MAGDALENA"},
    {"id": 5180,"nombre": "ALTO CANTANO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5181,"nombre": "ALTO GUAMITO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5182,"nombre": "BAJO SEMISA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5183,"nombre": "CANTANO BAJO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5184,"nombre": "CORINTO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5185,"nombre": "CUCHILLA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5186,"nombre": "DELICIAS, PUENTE NACIONAL, SANTANDER"},
    {"id": 5187,"nombre": "EL URUMAL, PUENTE NACIONAL, SANTANDER"},
    {"id": 5188,"nombre": "EL VENADO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5189,"nombre": "LA CAPILLA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5190,"nombre": "LOS ROBLES, PUENTE NACIONAL, SANTANDER"},
    {"id": 5191,"nombre": "PENA BLANCA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5192,"nombre": "PONTON - BAJO GUAMITO, PUENTE NACIONAL, SANTANDER"},
    {"id": 5193,"nombre": "POPOA NORTE, PUENTE NACIONAL, SANTANDER"},
    {"id": 5194,"nombre": "PROVIDENCIA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5195,"nombre": "PUENTE NACIONAL, PUENTE NACIONAL, SANTANDER"},
    {"id": 5196,"nombre": "QUEBRADA NEGRA, PUENTE NACIONAL, SANTANDER"},
    {"id": 5197,"nombre": "RINCON, PUENTE NACIONAL, SANTANDER"},
    {"id": 5198,"nombre": "DESMONTES ALTOS, PUERRES, NARIÑO"},
    {"id": 5199,"nombre": "EL ESCRITORIO, PUERRES, NARIÑO"},
    {"id": 5200,"nombre": "EL LLANO, PUERRES, NARIÑO"},
    {"id": 5201,"nombre": "EL PARAMO, PUERRES, NARIÑO"},
    {"id": 5202,"nombre": "LOMA LARGA, PUERRES, NARIÑO"},
    {"id": 5203,"nombre": "MAICIRA, PUERRES, NARIÑO"},
    {"id": 5204,"nombre": "MONOPAMBA, PUERRES, NARIÑO"},
    {"id": 5205,"nombre": "PUERRES, PUERRES, NARIÑO"},
    {"id": 5206,"nombre": "SAN MATEO, PUERRES, NARIÑO"},
    {"id": 5207,"nombre": "SAN MIGUEL, PUERRES, NARIÑO"},
    {"id": 5208,"nombre": "SAN PABLO, PUERRES, NARIÑO"},
    {"id": 5209,"nombre": "TESCUAL ALTO, PUERRES, NARIÑO"},
    {"id": 5210,"nombre": "TRES CRUCES, PUERRES, NARIÑO"},
    {"id": 5211,"nombre": "YANALE, PUERRES, NARIÑO"},
    {"id": 5212,"nombre": "PUERTO ALEGRIA, PUERTO ALEGRIA, AMAZONAS"},
    {"id": 5213,"nombre": "PUERTO ARICA, PUERTO ARICA, AMAZONAS"},
    {"id": 5214,"nombre": "ALTO CUEMBI, PUERTO ASIS, PUTUMAYO"},
    {"id": 5215,"nombre": "ALTO DANUBIO, PUERTO ASIS, PUTUMAYO"},
    {"id": 5216,"nombre": "BAJO CUEMBI, PUERTO ASIS, PUTUMAYO"},
    {"id": 5217,"nombre": "BUENOS AIRES, PUERTO ASIS, PUTUMAYO"},
    {"id": 5218,"nombre": "CANA BRAVA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5219,"nombre": "COMANDANTE, PUERTO ASIS, PUTUMAYO"},
    {"id": 5220,"nombre": "EL CRISTAL, PUERTO ASIS, PUTUMAYO"},
    {"id": 5221,"nombre": "EL PLACER, PUERTO ASIS, PUTUMAYO"},
    {"id": 5222,"nombre": "EL TIGRE, PUERTO ASIS, PUTUMAYO"},
    {"id": 5223,"nombre": "LA CARMELITA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5224,"nombre": "LA DORADA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5225,"nombre": "LA LIBERTAD, PUERTO ASIS, PUTUMAYO"},
    {"id": 5226,"nombre": "MEDIO, PUERTO ASIS, PUTUMAYO"},
    {"id": 5227,"nombre": "PAUJIL, PUERTO ASIS, PUTUMAYO"},
    {"id": 5228,"nombre": "PINUNA BLANCO, PUERTO ASIS, PUTUMAYO"},
    {"id": 5229,"nombre": "PUERTO ASIS, PUERTO ASIS, PUTUMAYO"},
    {"id": 5230,"nombre": "PUERTO BELLO, PUERTO ASIS, PUTUMAYO"},
    {"id": 5231,"nombre": "PUERTO COLON, PUERTO ASIS, PUTUMAYO"},
    {"id": 5232,"nombre": "PUERTO VEGA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5233,"nombre": "SAN ANTONIO DEL GUAMUEZ, PUERTO ASIS, PUTUMAYO"},
    {"id": 5234,"nombre": "SAN MIGUEL, PUERTO ASIS, PUTUMAYO"},
    {"id": 5235,"nombre": "SANTANA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5236,"nombre": "TETEYE, PUERTO ASIS, PUTUMAYO"},
    {"id": 5237,"nombre": "VILLA VICTORIA, PUERTO ASIS, PUTUMAYO"},
    {"id": 5238,"nombre": "CRISTALINA, PUERTO BERRIO, ANTIOQUIA"},
    {"id": 5239,"nombre": "PUERTO BERRIO, PUERTO BERRIO, ANTIOQUIA"},
    {"id": 5240,"nombre": "PUERTO MURILLO, PUERTO BERRIO, ANTIOQUIA"},
    {"id": 5241,"nombre": "SANTA CRUZ, PUERTO BERRIO, ANTIOQUIA"},
    {"id": 5242,"nombre": "VIRGINIAS, PUERTO BERRIO, ANTIOQUIA"},
    {"id": 5243,"nombre": "CENTRO CALDERON, PUERTO BOYACA, BOYACA"},
    {"id": 5244,"nombre": "GUANEGRO, PUERTO BOYACA, BOYACA"},
    {"id": 5245,"nombre": "KILOMETRO 25 VIA A LA DORADA, PUERTO BOYACA, BOYACA"},
    {"id": 5246,"nombre": "MARFIL, PUERTO BOYACA, BOYACA"},
    {"id": 5247,"nombre": "PALAGUA, PUERTO BOYACA, BOYACA"},
    {"id": 5248,"nombre": "POZO DOS BOYACA, PUERTO BOYACA, BOYACA"},
    {"id": 5249,"nombre": "PUERTO BOYACA, PUERTO BOYACA, BOYACA"},
    {"id": 5250,"nombre": "PUERTO PINZON, PUERTO BOYACA, BOYACA"},
    {"id": 5251,"nombre": "PUERTO ROMERO, PUERTO BOYACA, BOYACA"},
    {"id": 5252,"nombre": "PUERTO SERVIEZ, PUERTO BOYACA, BOYACA"},
    {"id": 5253,"nombre": "VASCONIA, PUERTO BOYACA, BOYACA"},
    {"id": 5254,"nombre": "EL CEDRAL, PUERTO CAICEDO, PUTUMAYO"},
    {"id": 5255,"nombre": "PUERTO CAICEDO, PUERTO CAICEDO, PUTUMAYO"},
    {"id": 5256,"nombre": "SAN PEDRO, PUERTO CAICEDO, PUTUMAYO"},
    {"id": 5257,"nombre": "VILLA FLOR, PUERTO CAICEDO, PUTUMAYO"},
    {"id": 5258,"nombre": "PORORORIO, PUERTO CONCORDIA, META"},
    {"id": 5259,"nombre": "PUERTO CONCORDIA, PUERTO CONCORDIA, META"},
    {"id": 5260,"nombre": "CRISTO REY, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5261,"nombre": "EL ARIZAL, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5262,"nombre": "EL CONTENTO ARRIBA, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5263,"nombre": "EL PANTANO, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5264,"nombre": "EL SILENCIO, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5265,"nombre": "JARAMAGAL, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5266,"nombre": "LA CANA DE CANALETE, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5267,"nombre": "LAS MUJERES, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5268,"nombre": "MORINDO, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5269,"nombre": "PUERTO ESCONDIDO, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5270,"nombre": "SAN JOSE DE CANALETE, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5271,"nombre": "SAN LUIS, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5272,"nombre": "SAN MIGUEL, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5273,"nombre": "VILLA ESTER, PUERTO ESCONDIDO, CORDOBA"},
    {"id": 5274,"nombre": "ALTO YUCAO, PUERTO GAITAN, META"},
    {"id": 5275,"nombre": "CHAVIVA, PUERTO GAITAN, META"},
    {"id": 5276,"nombre": "CRISTALINAS, PUERTO GAITAN, META"},
    {"id": 5277,"nombre": "EL PORVENIR, PUERTO GAITAN, META"},
    {"id": 5278,"nombre": "PLANAS, PUERTO GAITAN, META"},
    {"id": 5279,"nombre": "PUENTE ARIMENA, PUERTO GAITAN, META"},
    {"id": 5280,"nombre": "PUERTO GAITAN, PUERTO GAITAN, META"},
    {"id": 5281,"nombre": "PUERTO TRUJILLO, PUERTO GAITAN, META"},
    {"id": 5282,"nombre": "RUBIALES, PUERTO GAITAN, META"},
    {"id": 5283,"nombre": "SAN MIGUEL, PUERTO GAITAN, META"},
    {"id": 5284,"nombre": "SAN PEDRO DE ARIMENA, PUERTO GAITAN, META"},
    {"id": 5285,"nombre": "TILLAVA, PUERTO GAITAN, META"},
    {"id": 5286,"nombre": "EL CEDRO, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5287,"nombre": "EL GALLINAZO, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5288,"nombre": "EL JUANO, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5289,"nombre": "GALILEA, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5290,"nombre": "JOSE MARIA, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5291,"nombre": "MAYOYOQUE, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5292,"nombre": "PUERTO GUZMAN, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5293,"nombre": "PUERTO ROSARIO, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5294,"nombre": "SAN ROQUE, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5295,"nombre": "SANTA LUCIA, PUERTO GUZMAN, PUTUMAYO"},
    {"id": 5296,"nombre": "BELLA ROSA, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5297,"nombre": "BUENOS AIRES, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5298,"nombre": "EL BRILLANTE, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5299,"nombre": "JUAN JOSE, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5300,"nombre": "LA RICA, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5301,"nombre": "PICA PICA, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5302,"nombre": "PUERTO BELEN, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5303,"nombre": "PUERTO LIBERTADOR, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5304,"nombre": "SAN JUAN, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5305,"nombre": "SAN PEDRO, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5306,"nombre": "SANTAFE DEL CLARO (RIO VERDE), PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5307,"nombre": "VILLANUEVA, PUERTO LIBERTADOR, CORDOBA"},
    {"id": 5308,"nombre": "CANO RAYADO, PUERTO LLERAS, META"},
    {"id": 5309,"nombre": "CASIBARE, PUERTO LLERAS, META"},
    {"id": 5310,"nombre": "PRIMAVERA, PUERTO LLERAS, META"},
    {"id": 5311,"nombre": "PUERTO LLERAS, PUERTO LLERAS, META"},
    {"id": 5312,"nombre": "VILLA LAPAZ, PUERTO LLERAS, META"},
    {"id": 5313,"nombre": "ALTAMIRA, PUERTO LOPEZ, META"},
    {"id": 5314,"nombre": "BOCAS DE GUAYURIBA, PUERTO LOPEZ, META"},
    {"id": 5315,"nombre": "CHAVIVA, PUERTO LOPEZ, META"},
    {"id": 5316,"nombre": "CUMARALITO, PUERTO LOPEZ, META"},
    {"id": 5317,"nombre": "EL TIGRE, PUERTO LOPEZ, META"},
    {"id": 5318,"nombre": "GUICHARAL, PUERTO LOPEZ, META"},
    {"id": 5319,"nombre": "LA BALSA, PUERTO LOPEZ, META"},
    {"id": 5320,"nombre": "PACHIAQUIARO, PUERTO LOPEZ, META"},
    {"id": 5321,"nombre": "PUERTO GUADALUPE, PUERTO LOPEZ, META"},
    {"id": 5322,"nombre": "PUERTO LOPEZ, PUERTO LOPEZ, META"},
    {"id": 5323,"nombre": "PUERTO PORFIA, PUERTO LOPEZ, META"},
    {"id": 5324,"nombre": "REMOLINO, PUERTO LOPEZ, META"},
    {"id": 5325,"nombre": "LA ARABIA, PUERTO NARE, ANTIOQUIA"},
    {"id": 5326,"nombre": "LA PESCA, PUERTO NARE, ANTIOQUIA"},
    {"id": 5327,"nombre": "LA SIERRA, PUERTO NARE, ANTIOQUIA"},
    {"id": 5328,"nombre": "LA UNION, PUERTO NARE, ANTIOQUIA"},
    {"id": 5329,"nombre": "LOS DELIRIOS, PUERTO NARE, ANTIOQUIA"},
    {"id": 5330,"nombre": "PUERTO NARE, PUERTO NARE, ANTIOQUIA"},
    {"id": 5331,"nombre": "AGUA LINDA, PUERTO PARRA, SANTANDER"},
    {"id": 5332,"nombre": "BOCAS DEL CARARE, PUERTO PARRA, SANTANDER"},
    {"id": 5333,"nombre": "CAMPO CAPOTE, PUERTO PARRA, SANTANDER"},
    {"id": 5334,"nombre": "LAS MONTOYAS, PUERTO PARRA, SANTANDER"},
    {"id": 5335,"nombre": "PUERTO PARRA, PUERTO PARRA, SANTANDER"},
    {"id": 5336,"nombre": "EL LORO, PUERTO RONDON, ARAUCA"},
    {"id": 5337,"nombre": "LA CORREA, PUERTO RONDON, ARAUCA"},
    {"id": 5338,"nombre": "LAS ACACIAS, PUERTO RONDON, ARAUCA"},
    {"id": 5339,"nombre": "LOS HIGUERONES, PUERTO RONDON, ARAUCA"},
    {"id": 5340,"nombre": "MARRERO, PUERTO RONDON, ARAUCA"},
    {"id": 5341,"nombre": "PUERTO RONDON, PUERTO RONDON, ARAUCA"},
    {"id": 5342,"nombre": "SAN IGNACIO, PUERTO RONDON, ARAUCA"},
    {"id": 5343,"nombre": "SAN RAMON, PUERTO RONDON, ARAUCA"},
    {"id": 5344,"nombre": "COLORADOS, PUERTO SALGAR, CUNDINAMARCA"},
    {"id": 5345,"nombre": "EL GUAYABO, PUERTO SALGAR, CUNDINAMARCA"},
    {"id": 5346,"nombre": "PUERTO LIBRE, PUERTO SALGAR, CUNDINAMARCA"},
    {"id": 5347,"nombre": "PUERTO SALGAR, PUERTO SALGAR, CUNDINAMARCA"},
    {"id": 5348,"nombre": "BOCAS DEL PALO, PUERTO TEJADA, CAUCA"},
    {"id": 5349,"nombre": "PERICO NEGRO, PUERTO TEJADA, CAUCA"},
    {"id": 5350,"nombre": "PUERTO TEJADA, PUERTO TEJADA, CAUCA"},
    {"id": 5351,"nombre": "SAN CARLOS, PUERTO TEJADA, CAUCA"},
    {"id": 5352,"nombre": "VUELTA LARGA, PUERTO TEJADA, CAUCA"},
    {"id": 5353,"nombre": "BUENOS AIRES, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5354,"nombre": "DORADAL, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5355,"nombre": "ESTACION COCORNA, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5356,"nombre": "ESTACION PITA, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5357,"nombre": "LA MERCEDES, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5358,"nombre": "PUERTO PERALES NUEVO, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5359,"nombre": "PUERTO TRIUNFO, PUERTO TRIUNFO, ANTIOQUIA"},
    {"id": 5360,"nombre": "BADILLO, PUERTO WILCHES, SANTANDER"},
    {"id": 5361,"nombre": "BOCAS RIO LEBRIJA, PUERTO WILCHES, SANTANDER"},
    {"id": 5362,"nombre": "BOCAS ROSARIO, PUERTO WILCHES, SANTANDER"},
    {"id": 5363,"nombre": "CARPINTERO, PUERTO WILCHES, SANTANDER"},
    {"id": 5364,"nombre": "CAYUMBA, PUERTO WILCHES, SANTANDER"},
    {"id": 5365,"nombre": "CHINGALE, PUERTO WILCHES, SANTANDER"},
    {"id": 5366,"nombre": "EL GUAYABO, PUERTO WILCHES, SANTANDER"},
    {"id": 5367,"nombre": "EL PEDRAL, PUERTO WILCHES, SANTANDER"},
    {"id": 5368,"nombre": "KILOMETRO 16, PUERTO WILCHES, SANTANDER"},
    {"id": 5369,"nombre": "KILOMETRO 20, PUERTO WILCHES, SANTANDER"},
    {"id": 5370,"nombre": "KILOMETRO 8, PUERTO WILCHES, SANTANDER"},
    {"id": 5371,"nombre": "LAS GARZAS, PUERTO WILCHES, SANTANDER"},
    {"id": 5372,"nombre": "PATURIA, PUERTO WILCHES, SANTANDER"},
    {"id": 5373,"nombre": "PRADILLA, PUERTO WILCHES, SANTANDER"},
    {"id": 5374,"nombre": "PUENTE SOGAMOSO, PUERTO WILCHES, SANTANDER"},
    {"id": 5375,"nombre": "PUERTO WILCHES, PUERTO WILCHES, SANTANDER"},
    {"id": 5376,"nombre": "SAN CLAVER, PUERTO WILCHES, SANTANDER"},
    {"id": 5377,"nombre": "SITIO NUEVO, PUERTO WILCHES, SANTANDER"},
    {"id": 5378,"nombre": "VIJAGUAL, PUERTO WILCHES, SANTANDER"},
    {"id": 5379,"nombre": "GIBRALTAR, PULI, CUNDINAMARCA"},
    {"id": 5380,"nombre": "PALESTINA, PULI, CUNDINAMARCA"},
    {"id": 5381,"nombre": "PARAMON, PULI, CUNDINAMARCA"},
    {"id": 5382,"nombre": "PULI, PULI, CUNDINAMARCA"},
    {"id": 5383,"nombre": "TALIPA, PULI, CUNDINAMARCA"},
    {"id": 5384,"nombre": "VALPARAISO, PULI, CUNDINAMARCA"},
    {"id": 5385,"nombre": "CASAFRIA, PUPIALES, NARIÑO"},
    {"id": 5386,"nombre": "CHIRES CENTRO, PUPIALES, NARIÑO"},
    {"id": 5387,"nombre": "EL ESPINO, PUPIALES, NARIÑO"},
    {"id": 5388,"nombre": "JOSE MARIA HERNANDEZ, PUPIALES, NARIÑO"},
    {"id": 5389,"nombre": "MIRAFLORES, PUPIALES, NARIÑO"},
    {"id": 5390,"nombre": "PISCUM, PUPIALES, NARIÑO"},
    {"id": 5391,"nombre": "PUPIALES, PUPIALES, NARIÑO"},
    {"id": 5392,"nombre": "CANDELARIA, PURACE, CAUCA"},
    {"id": 5393,"nombre": "GUILLERMO VALENCIA, PURACE, CAUCA"},
    {"id": 5394,"nombre": "MOSCOPAN, PURACE, CAUCA"},
    {"id": 5395,"nombre": "COCONUCO, PURACE, CAUCA"},
    {"id": 5396,"nombre": "PURACE, PURACE, CAUCA"},
    {"id": 5397,"nombre": "SAN RAFAEL, PURACE, CAUCA"},
    {"id": 5398,"nombre": "SANTA LETICIA, PURACE, CAUCA"},
    {"id": 5399,"nombre": "TIJERAS, PURACE, CAUCA"},
    {"id": 5400,"nombre": "BUENAVISTA, PURIFICACION, TOLIMA"},
    {"id": 5401,"nombre": "CAIRO SOCORRO, PURIFICACION, TOLIMA"},
    {"id": 5402,"nombre": "CHENCHE ASOLEADO, PURIFICACION, TOLIMA"},
    {"id": 5403,"nombre": "CHENCHE TRES, PURIFICACION, TOLIMA"},
    {"id": 5404,"nombre": "CHENCHE UNO, PURIFICACION, TOLIMA"},
    {"id": 5405,"nombre": "CONSUEL Y TIGRE, PURIFICACION, TOLIMA"},
    {"id": 5406,"nombre": "EL BAURA, PURIFICACION, TOLIMA"},
    {"id": 5407,"nombre": "LA MATA, PURIFICACION, TOLIMA"},
    {"id": 5408,"nombre": "LOZANIA, PURIFICACION, TOLIMA"},
    {"id": 5409,"nombre": "PENONES ALTOS, PURIFICACION, TOLIMA"},
    {"id": 5410,"nombre": "PURIFICACION, PURIFICACION, TOLIMA"},
    {"id": 5411,"nombre": "VILLA COLOMBIA, PURIFICACION, TOLIMA"},
    {"id": 5412,"nombre": "VILLA ESPERANZA, PURIFICACION, TOLIMA"},
    {"id": 5413,"nombre": "ASERRADERO, PURISIMA, CORDOBA"},
    {"id": 5414,"nombre": "EL HUESO, PURISIMA, CORDOBA"},
    {"id": 5415,"nombre": "LOS CORALES, PURISIMA, CORDOBA"},
    {"id": 5416,"nombre": "PURISIMA, PURISIMA, CORDOBA"},
    {"id": 5417,"nombre": "SAN PEDRO DE ARROYO HONDO, PURISIMA, CORDOBA"},
    {"id": 5418,"nombre": "LA MAGDALENA, QUEBRADANEGRA, CUNDINAMARCA"},
    {"id": 5419,"nombre": "QUEBRADANEGRA, QUEBRADANEGRA, CUNDINAMARCA"},
    {"id": 5420,"nombre": "SAN CARLOS, QUEBRADANEGRA, CUNDINAMARCA"},
    {"id": 5421,"nombre": "TOBIA, QUEBRADANEGRA, CUNDINAMARCA"},
    {"id": 5422,"nombre": "PUENTE QUETAME, QUETAME, CUNDINAMARCA"},
    {"id": 5423,"nombre": "QUETAME, QUETAME, CUNDINAMARCA"},
    {"id": 5424,"nombre": "ALTAGRACIA, QUIBDO, CHOCO"},
    {"id": 5425,"nombre": "ALTO MUNGUIDO, QUIBDO, CHOCO"},
    {"id": 5426,"nombre": "BELEN, QUIBDO, CHOCO"},
    {"id": 5427,"nombre": "BELLALUZ, QUIBDO, CHOCO"},
    {"id": 5428,"nombre": "BOCA DE NAURITA (NAURITA), QUIBDO, CHOCO"},
    {"id": 5429,"nombre": "BOCA DE NEMOTA (NEMOTA), QUIBDO, CHOCO"},
    {"id": 5430,"nombre": "BOCA DE TANANDO, QUIBDO, CHOCO"},
    {"id": 5431,"nombre": "CALAHORRA, QUIBDO, CHOCO"},
    {"id": 5432,"nombre": "CAMPOBONITO, QUIBDO, CHOCO"},
    {"id": 5433,"nombre": "EL FUERTE, QUIBDO, CHOCO"},
    {"id": 5434,"nombre": "EL TAMBO, QUIBDO, CHOCO"},
    {"id": 5435,"nombre": "GITRADO, QUIBDO, CHOCO"},
    {"id": 5436,"nombre": "GUADALUPE, QUIBDO, CHOCO"},
    {"id": 5437,"nombre": "GUARANDO, QUIBDO, CHOCO"},
    {"id": 5438,"nombre": "GUAYABAL, QUIBDO, CHOCO"},
    {"id": 5439,"nombre": "LA TROJE, QUIBDO, CHOCO"},
    {"id": 5440,"nombre": "LAS MERCEDES, QUIBDO, CHOCO"},
    {"id": 5441,"nombre": "LOMA DE BELEN, QUIBDO, CHOCO"},
    {"id": 5442,"nombre": "MOJAUDO, QUIBDO, CHOCO"},
    {"id": 5443,"nombre": "PACURITA (CABI), QUIBDO, CHOCO"},
    {"id": 5444,"nombre": "PUERTO MURILLO, QUIBDO, CHOCO"},
    {"id": 5445,"nombre": "SAN FRANCISCO DE QUIBDO, QUIBDO, CHOCO"},
    {"id": 5446,"nombre": "SAN ANTONIO DE ICHO, QUIBDO, CHOCO"},
    {"id": 5447,"nombre": "SAN FRANCISCO DE ICHO, QUIBDO, CHOCO"},
    {"id": 5448,"nombre": "SAN RAFAEL DE NEGUA, QUIBDO, CHOCO"},
    {"id": 5449,"nombre": "SANCENO, QUIBDO, CHOCO"},
    {"id": 5450,"nombre": "TAGACHI, QUIBDO, CHOCO"},
    {"id": 5451,"nombre": "TUTUNENDO, QUIBDO, CHOCO"},
    {"id": 5452,"nombre": "VILLA DEL ROSARIO, QUIBDO, CHOCO"},
    {"id": 5453,"nombre": "WINANDO, QUIBDO, CHOCO"},
    {"id": 5454,"nombre": "EL LAUREL, QUIMBAYA, QUINDIO"},
    {"id": 5455,"nombre": "EL NARANJAL, QUIMBAYA, QUINDIO"},
    {"id": 5456,"nombre": "LA ESPANOLA, QUIMBAYA, QUINDIO"},
    {"id": 5457,"nombre": "LA MESA BAJA, QUIMBAYA, QUINDIO"},
    {"id": 5458,"nombre": "PUEBLO RICO, QUIMBAYA, QUINDIO"},
    {"id": 5459,"nombre": "PUERTO ALEJANDRIA, QUIMBAYA, QUINDIO"},
    {"id": 5460,"nombre": "QUIMBAYA, QUIMBAYA, QUINDIO"},
    {"id": 5461,"nombre": "TROCADEROS, QUIMBAYA, QUINDIO"},
    {"id": 5462,"nombre": "BATERO, QUINCHIA, RISARALDA"},
    {"id": 5463,"nombre": "BUENAVISTA, QUINCHIA, RISARALDA"},
    {"id": 5464,"nombre": "ENCENILLAL, QUINCHIA, RISARALDA"},
    {"id": 5465,"nombre": "IRRA, QUINCHIA, RISARALDA"},
    {"id": 5466,"nombre": "LA ESMERALDA, QUINCHIA, RISARALDA"},
    {"id": 5467,"nombre": "LA FLORESTA, QUINCHIA, RISARALDA"},
    {"id": 5468,"nombre": "MIRACAMPOS, QUINCHIA, RISARALDA"},
    {"id": 5469,"nombre": "MORETA, QUINCHIA, RISARALDA"},
    {"id": 5470,"nombre": "MURRAPAL, QUINCHIA, RISARALDA"},
    {"id": 5471,"nombre": "NARANJAL, QUINCHIA, RISARALDA"},
    {"id": 5472,"nombre": "QUINCHIA, QUINCHIA, RISARALDA"},
    {"id": 5473,"nombre": "SAN JOSE, QUINCHIA, RISARALDA"},
    {"id": 5474,"nombre": "SANTA ELENA, QUINCHIA, RISARALDA"},
    {"id": 5475,"nombre": "VILLA RICA, QUINCHIA, RISARALDA"},
    {"id": 5476,"nombre": "CORMAL, QUIPAMA, BOYACA"},
    {"id": 5477,"nombre": "EL MANGO (LA YE), QUIPAMA, BOYACA"},
    {"id": 5478,"nombre": "EL PARQUE, QUIPAMA, BOYACA"},
    {"id": 5479,"nombre": "HUMBO, QUIPAMA, BOYACA"},
    {"id": 5480,"nombre": "ITOCO, QUIPAMA, BOYACA"},
    {"id": 5481,"nombre": "LA VEGA DE CORMAL, QUIPAMA, BOYACA"},
    {"id": 5482,"nombre": "QUIPAMA, QUIPAMA, BOYACA"},
    {"id": 5483,"nombre": "LA BOTICA, QUIPILE, CUNDINAMARCA"},
    {"id": 5484,"nombre": "LA SIERRA, QUIPILE, CUNDINAMARCA"},
    {"id": 5485,"nombre": "LA VIRGEN, QUIPILE, CUNDINAMARCA"},
    {"id": 5486,"nombre": "QUIPILE, QUIPILE, CUNDINAMARCA"},
    {"id": 5487,"nombre": "SANTA MARTA, QUIPILE, CUNDINAMARCA"},
    {"id": 5488,"nombre": "CANUELAL, RAGONVALIA, NORTE DE SANTANDER"},
    {"id": 5489,"nombre": "EL NARANJAL, RAGONVALIA, NORTE DE SANTANDER"},
    {"id": 5490,"nombre": "HONDA NORTE, RAGONVALIA, NORTE DE SANTANDER"},
    {"id": 5491,"nombre": "RAGONVALIA, RAGONVALIA, NORTE DE SANTANDER"},
    {"id": 5492,"nombre": "SAN ISIDRO, RAGONVALIA, NORTE DE SANTANDER"},
    {"id": 5493,"nombre": "EL ESCOBAL, RAMIRIQUI, BOYACA"},
    {"id": 5494,"nombre": "GUAYABAL (FATIMA), RAMIRIQUI, BOYACA"},
    {"id": 5495,"nombre": "RAMIRIQUI, RAMIRIQUI, BOYACA"},
    {"id": 5496,"nombre": "LA CANDELARIA, RAQUIRA, BOYACA"},
    {"id": 5497,"nombre": "RAQUIRA, RAQUIRA, BOYACA"},
    {"id": 5498,"nombre": "SAN CAYETANO, RAQUIRA, BOYACA"},
    {"id": 5499,"nombre": "PUEBLO NUEVO, RECETOR, CASANARE"},
    {"id": 5500,"nombre": "RECETOR, RECETOR, CASANARE"},
    {"id": 5501,"nombre": "EL PINAL, REGIDOR, BOLIVAR"},
    {"id": 5502,"nombre": "LA VICTORIA, REGIDOR, BOLIVAR"},
    {"id": 5503,"nombre": "LOS CAIMANES, REGIDOR, BOLIVAR"},
    {"id": 5504,"nombre": "REGIDOR, REGIDOR, BOLIVAR"},
    {"id": 5505,"nombre": "SAN ANTONIO, REGIDOR, BOLIVAR"},
    {"id": 5506,"nombre": "SAN CAYETANO, REGIDOR, BOLIVAR"},
    {"id": 5507,"nombre": "SANTA LUCIA, REGIDOR, BOLIVAR"},
    {"id": 5508,"nombre": "SANTA TERESA, REGIDOR, BOLIVAR"},
    {"id": 5509,"nombre": "LA CRUZADA, REMEDIOS, ANTIOQUIA"},
    {"id": 5510,"nombre": "LAS MERCEDES, REMEDIOS, ANTIOQUIA"},
    {"id": 5511,"nombre": "LLANO DE CORDOBA, REMEDIOS, ANTIOQUIA"},
    {"id": 5512,"nombre": "OTUL, REMEDIOS, ANTIOQUIA"},
    {"id": 5513,"nombre": "REMEDIOS, REMEDIOS, ANTIOQUIA"},
    {"id": 5514,"nombre": "SANTA ISABEL, REMEDIOS, ANTIOQUIA"},
    {"id": 5515,"nombre": "CORRAL VIEJO, REMOLINO, MAGDALENA"},
    {"id": 5516,"nombre": "EL DIVIDIVI, REMOLINO, MAGDALENA"},
    {"id": 5517,"nombre": "EL SALAO, REMOLINO, MAGDALENA"},
    {"id": 5518,"nombre": "LAS CASITAS, REMOLINO, MAGDALENA"},
    {"id": 5519,"nombre": "MARTINETE, REMOLINO, MAGDALENA"},
    {"id": 5520,"nombre": "REMOLINO, REMOLINO, MAGDALENA"},
    {"id": 5521,"nombre": "SAN RAFAEL, REMOLINO, MAGDALENA"},
    {"id": 5522,"nombre": "SANTA RITA, REMOLINO, MAGDALENA"},
    {"id": 5523,"nombre": "ARROYO NEGRO, REPELON, ATLANTICO"},
    {"id": 5524,"nombre": "CIEN PESOS, REPELON, ATLANTICO"},
    {"id": 5525,"nombre": "CIENAGUITA, REPELON, ATLANTICO"},
    {"id": 5526,"nombre": "EL PORVENIR (LAS COMPUERTAS), REPELON, ATLANTICO"},
    {"id": 5527,"nombre": "LAS TABLAS, REPELON, ATLANTICO"},
    {"id": 5528,"nombre": "PITA, REPELON, ATLANTICO"},
    {"id": 5529,"nombre": "REPELON, REPELON, ATLANTICO"},
    {"id": 5530,"nombre": "ROTINET, REPELON, ATLANTICO"},
    {"id": 5531,"nombre": "VILLA ROSA, REPELON, ATLANTICO"},
    {"id": 5532,"nombre": "CARRIZALES, RETIRO, ANTIOQUIA"},
    {"id": 5533,"nombre": "DON DIEGO, RETIRO, ANTIOQUIA"},
    {"id": 5534,"nombre": "EL CHUSCAL, RETIRO, ANTIOQUIA"},
    {"id": 5535,"nombre": "LEJOS DEL NIDO, RETIRO, ANTIOQUIA"},
    {"id": 5536,"nombre": "LOS SALADOS, RETIRO, ANTIOQUIA"},
    {"id": 5537,"nombre": "RETIRO, RETIRO, ANTIOQUIA"},
    {"id": 5538,"nombre": "SANTA ELENA, RETIRO, ANTIOQUIA"},
    {"id": 5539,"nombre": "EL HOBO, RIO DE ORO, CESAR"},
    {"id": 5540,"nombre": "EL SALOBRE, RIO DE ORO, CESAR"},
    {"id": 5541,"nombre": "HONDURAS, RIO DE ORO, CESAR"},
    {"id": 5542,"nombre": "LOS ANGELES, RIO DE ORO, CESAR"},
    {"id": 5543,"nombre": "MARQUEZ, RIO DE ORO, CESAR"},
    {"id": 5544,"nombre": "MONTECITOS, RIO DE ORO, CESAR"},
    {"id": 5545,"nombre": "MORRINSON (B. MILITAR), RIO DE ORO, CESAR"},
    {"id": 5546,"nombre": "PUERTO NUEVO, RIO DE ORO, CESAR"},
    {"id": 5547,"nombre": "RIO DE ORO, RIO DE ORO, CESAR"},
    {"id": 5548,"nombre": "SAN JOSE, RIO DE ORO, CESAR"},
    {"id": 5549,"nombre": "ALTO CHATO, RIO IRO, CHOCO"},
    {"id": 5550,"nombre": "CALLE DEL CEDRO, RIO IRO, CHOCO"},
    {"id": 5551,"nombre": "COROCO, RIO IRO, CHOCO"},
    {"id": 5552,"nombre": "ENCHARCAZON, RIO IRO, CHOCO"},
    {"id": 5553,"nombre": "SANTA RITA, RIO IRO, CHOCO"},
    {"id": 5554,"nombre": "SAN JOSE DE VIRO VIRO, RIO IRO, CHOCO"},
    {"id": 5555,"nombre": "SANTA BARBARA, RIO IRO, CHOCO"},
    {"id": 5556,"nombre": "BOCA DE PARTADO, RIO QUITO, CHOCO"},
    {"id": 5557,"nombre": "LA PUNTA ANTADO, RIO QUITO, CHOCO"},
    {"id": 5558,"nombre": "PAIMADO, RIO QUITO, CHOCO"},
    {"id": 5559,"nombre": "SAN ISIDRO, RIO QUITO, CHOCO"},
    {"id": 5560,"nombre": "VILLA CONTO, RIO QUITO, CHOCO"},
    {"id": 5561,"nombre": "NOROSI, RIO VIEJO, BOLIVAR"},
    {"id": 5562,"nombre": "RIO VIEJO, RIO VIEJO, BOLIVAR"},
    {"id": 5563,"nombre": "GAITAN, RIOBLANCO, TOLIMA"},
    {"id": 5564,"nombre": "HERRERA, RIOBLANCO, TOLIMA"},
    {"id": 5565,"nombre": "LA LINDOSA, RIOBLANCO, TOLIMA"},
    {"id": 5566,"nombre": "MARACAIBO, RIOBLANCO, TOLIMA"},
    {"id": 5567,"nombre": "PALONEGRO, RIOBLANCO, TOLIMA"},
    {"id": 5568,"nombre": "PUERTO SALDANA, RIOBLANCO, TOLIMA"},
    {"id": 5569,"nombre": "RIOBLANCO, RIOBLANCO, TOLIMA"},
    {"id": 5570,"nombre": "URIBE, RIOBLANCO, TOLIMA"},
    {"id": 5571,"nombre": "EL JAGUAL, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5572,"nombre": "FENICIA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5573,"nombre": "LA CUCHILLA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5574,"nombre": "LA JUDEA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5575,"nombre": "LA VIGOROZA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5576,"nombre": "LA ZULIA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5577,"nombre": "MADRIGAL, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5578,"nombre": "PORTO FENICIA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5579,"nombre": "PORTUGAL DE PIEDRAS, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5580,"nombre": "RIOFRIO, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5581,"nombre": "SALONICA, RIOFRIO, VALLE DEL CAUCA"},
    {"id": 5582,"nombre": "ARROYO ARENA, RIOHACHA, LA GUAJIRA"},
    {"id": 5583,"nombre": "BARBACOAS, RIOHACHA, LA GUAJIRA"},
    {"id": 5584,"nombre": "CAMARONES, RIOHACHA, LA GUAJIRA"},
    {"id": 5585,"nombre": "CASCAJALITO, RIOHACHA, LA GUAJIRA"},
    {"id": 5586,"nombre": "CHOLE, RIOHACHA, LA GUAJIRA"},
    {"id": 5587,"nombre": "COMEJENES, RIOHACHA, LA GUAJIRA"},
    {"id": 5588,"nombre": "COTOPRIX, RIOHACHA, LA GUAJIRA"},
    {"id": 5589,"nombre": "EL LABRAS, RIOHACHA, LA GUAJIRA"},
    {"id": 5590,"nombre": "GALAN, RIOHACHA, LA GUAJIRA"},
    {"id": 5591,"nombre": "LA PALMA, RIOHACHA, LA GUAJIRA"},
    {"id": 5592,"nombre": "LA PUNTA DE LOS REMEDIOS, RIOHACHA, LA GUAJIRA"},
    {"id": 5593,"nombre": "LAS CASITAS, RIOHACHA, LA GUAJIRA"},
    {"id": 5594,"nombre": "MATITA, RIOHACHA, LA GUAJIRA"},
    {"id": 5595,"nombre": "MONGUI, RIOHACHA, LA GUAJIRA"},
    {"id": 5596,"nombre": "MORENEROS, RIOHACHA, LA GUAJIRA"},
    {"id": 5597,"nombre": "PELECHUA, RIOHACHA, LA GUAJIRA"},
    {"id": 5598,"nombre": "PERICO, RIOHACHA, LA GUAJIRA"},
    {"id": 5599,"nombre": "RIOHACHA, RIOHACHA, LA GUAJIRA"},
    {"id": 5600,"nombre": "SAN ANTONIO, RIOHACHA, LA GUAJIRA"},
    {"id": 5601,"nombre": "TIGRERA, RIOHACHA, LA GUAJIRA"},
    {"id": 5602,"nombre": "TOMARRAZON, RIOHACHA, LA GUAJIRA"},
    {"id": 5603,"nombre": "VILLA MARTIN, RIOHACHA, LA GUAJIRA"},
    {"id": 5604,"nombre": "ALTO DE ARAUCA, RISARALDA, CALDAS"},
    {"id": 5605,"nombre": "CALLE LARGA, RISARALDA, CALDAS"},
    {"id": 5606,"nombre": "EL PALO, RISARALDA, CALDAS"},
    {"id": 5607,"nombre": "QUIEBRA DE SANTA BARBARA, RISARALDA, CALDAS"},
    {"id": 5608,"nombre": "QUIEBRA DE VARILLAS, RISARALDA, CALDAS"},
    {"id": 5609,"nombre": "RISARALDA, RISARALDA, CALDAS"},
    {"id": 5610,"nombre": "LA PRIMAVERA, RIVERA, HUILA"},
    {"id": 5611,"nombre": "LA ULLOA, RIVERA, HUILA"},
    {"id": 5612,"nombre": "RIO FRIO, RIVERA, HUILA"},
    {"id": 5613,"nombre": "RIVERA, RIVERA, HUILA"},
    {"id": 5614,"nombre": "RIVERITA, RIVERA, HUILA"},
    {"id": 5615,"nombre": "ANTONIO NARIÑO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5616,"nombre": "ARTEAGA - LIMONAR, ROBERTO PAYAN, NARIÑO"},
    {"id": 5617,"nombre": "CACAO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5618,"nombre": "CARLOS LLERAS RESTREPO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5619,"nombre": "CHAFALOTE, ROBERTO PAYAN, NARIÑO"},
    {"id": 5620,"nombre": "CONQUISTA, ROBERTO PAYAN, NARIÑO"},
    {"id": 5621,"nombre": "EL GUAYABAL, ROBERTO PAYAN, NARIÑO"},
    {"id": 5622,"nombre": "EL SANDE RIO ISPI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5623,"nombre": "FATIMA - EL CARMEN, ROBERTO PAYAN, NARIÑO"},
    {"id": 5624,"nombre": "GOMEZ JURADO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5625,"nombre": "GUABAL, ROBERTO PAYAN, NARIÑO"},
    {"id": 5626,"nombre": "GUALPI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5627,"nombre": "INDU, ROBERTO PAYAN, NARIÑO"},
    {"id": 5628,"nombre": "INGUANBI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5629,"nombre": "JALARAL, ROBERTO PAYAN, NARIÑO"},
    {"id": 5630,"nombre": "JORGE ELIECER GAITAN, ROBERTO PAYAN, NARIÑO"},
    {"id": 5631,"nombre": "LAS LAJAS PUMBI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5632,"nombre": "LAS MERCEDES - CHIMBUZA, ROBERTO PAYAN, NARIÑO"},
    {"id": 5633,"nombre": "LIMONES DEL PATIA, ROBERTO PAYAN, NARIÑO"},
    {"id": 5634,"nombre": "MIALO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5635,"nombre": "MUNAMBI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5636,"nombre": "NEGRITO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5637,"nombre": "PALOSECO, ROBERTO PAYAN, NARIÑO"},
    {"id": 5638,"nombre": "PALSAPI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5639,"nombre": "PANGA, ROBERTO PAYAN, NARIÑO"},
    {"id": 5640,"nombre": "PENON G. MARTINEZ, ROBERTO PAYAN, NARIÑO"},
    {"id": 5641,"nombre": "PIRI (PARAISO), ROBERTO PAYAN, NARIÑO"},
    {"id": 5642,"nombre": "SAN JOSE, ROBERTO PAYAN, NARIÑO"},
    {"id": 5643,"nombre": "SAN ANTONIO - BOCA TELEMBI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5644,"nombre": "SANTA ELENA, ROBERTO PAYAN, NARIÑO"},
    {"id": 5645,"nombre": "TASDAN, ROBERTO PAYAN, NARIÑO"},
    {"id": 5646,"nombre": "TRINIDAD LA MERCED, ROBERTO PAYAN, NARIÑO"},
    {"id": 5647,"nombre": "VUELTA DE PAPI, ROBERTO PAYAN, NARIÑO"},
    {"id": 5648,"nombre": "BELGICA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5649,"nombre": "BUENAVISTA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5650,"nombre": "CAJAMARCA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5651,"nombre": "EL AGUACATE, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5652,"nombre": "EL CASCARILLO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5653,"nombre": "EL CASTILLO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5654,"nombre": "EL CIRUELO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5655,"nombre": "EL HOBO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5656,"nombre": "EL PIE, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5657,"nombre": "EL RETIRO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5658,"nombre": "EL REY, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5659,"nombre": "EL SILENCIO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5660,"nombre": "HIGUERONCITO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5661,"nombre": "ISUGU, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5662,"nombre": "LA ARMENIA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5663,"nombre": "LA CANDELARIA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5664,"nombre": "LA SECA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5665,"nombre": "MATA DE GUADUA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5666,"nombre": "MONTANUELA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5667,"nombre": "MORELIA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5668,"nombre": "PALMAR GUAYABAL, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5669,"nombre": "PUERTO QUINTERO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5670,"nombre": "REMOLINO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5671,"nombre": "ROLDANILLO, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5672,"nombre": "SANTA RITA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5673,"nombre": "TIERRA BLANCA, ROLDANILLO, VALLE DEL CAUCA"},
    {"id": 5674,"nombre": "EL CEDRO, RONCESVALLES, TOLIMA"},
    {"id": 5675,"nombre": "RONCESVALLES, RONCESVALLES, TOLIMA"},
    {"id": 5676,"nombre": "SANTA ELENA, RONCESVALLES, TOLIMA"},
    {"id": 5677,"nombre": "RANCHOGRANDE, RONDON, BOYACA"},
    {"id": 5678,"nombre": "RONDON, RONDON, BOYACA"},
    {"id": 5679,"nombre": "GUALOTO, ROSAS, CAUCA"},
    {"id": 5680,"nombre": "LOMABAJO, ROSAS, CAUCA"},
    {"id": 5681,"nombre": "LOMBAJO, ROSAS, CAUCA"},
    {"id": 5682,"nombre": "MARQUEZ, ROSAS, CAUCA"},
    {"id": 5683,"nombre": "PARRAGA, ROSAS, CAUCA"},
    {"id": 5684,"nombre": "ROSAS, ROSAS, CAUCA"},
    {"id": 5685,"nombre": "UFUGU, ROSAS, CAUCA"},
    {"id": 5686,"nombre": "EL CORAZON, ROVIRA, TOLIMA"},
    {"id": 5687,"nombre": "HATO VIEJO, ROVIRA, TOLIMA"},
    {"id": 5688,"nombre": "LA FLORIDA, ROVIRA, TOLIMA"},
    {"id": 5689,"nombre": "LA LUISA, ROVIRA, TOLIMA"},
    {"id": 5690,"nombre": "LA PAZ - GUADUALITO, ROVIRA, TOLIMA"},
    {"id": 5691,"nombre": "LA SELVA, ROVIRA, TOLIMA"},
    {"id": 5692,"nombre": "LOS ANDES, ROVIRA, TOLIMA"},
    {"id": 5693,"nombre": "PANDO PRADO, ROVIRA, TOLIMA"},
    {"id": 5694,"nombre": "RIOMANSO, ROVIRA, TOLIMA"},
    {"id": 5695,"nombre": "ROVIRA, ROVIRA, TOLIMA"},
    {"id": 5696,"nombre": "SAN PEDRO, ROVIRA, TOLIMA"},
    {"id": 5697,"nombre": "LA GOMEZ, SABANA DE TORRES, SANTANDER"},
    {"id": 5698,"nombre": "MAGARA, SABANA DE TORRES, SANTANDER"},
    {"id": 5699,"nombre": "PAYOA CINCO, SABANA DE TORRES, SANTANDER"},
    {"id": 5700,"nombre": "PROVINCIA, SABANA DE TORRES, SANTANDER"},
    {"id": 5701,"nombre": "SABANA DE TORRES, SABANA DE TORRES, SANTANDER"},
    {"id": 5702,"nombre": "SABANETA, SABANA DE TORRES, SANTANDER"},
    {"id": 5703,"nombre": "VERACRUZ KILOMETRO 80, SABANA DE TORRES, SANTANDER"},
    {"id": 5704,"nombre": "SABANAGRANDE, SABANAGRANDE, ATLANTICO"},
    {"id": 5705,"nombre": "CASA DE TABLA, SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5706,"nombre": "CESPEDES, SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5707,"nombre": "FLORES DE MARIA, SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5708,"nombre": "LAS MULAS (SAN ROQUE), SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5709,"nombre": "PUEBLITO DE LOS BARRIOS, SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5710,"nombre": "SAN ANGEL, SABANAS DE SAN ANGEL, MAGDALENA"},
    {"id": 5711,"nombre": "CANAVERALEJO, SABANETA, ANTIOQUIA"},
    {"id": 5712,"nombre": "LA DOCTORA, SABANETA, ANTIOQUIA"},
    {"id": 5713,"nombre": "LA INMACULADA, SABANETA, ANTIOQUIA"},
    {"id": 5714,"nombre": "LA MILAGROSA, SABANETA, ANTIOQUIA"},
    {"id": 5715,"nombre": "LAS PLAYAS, SABANETA, ANTIOQUIA"},
    {"id": 5716,"nombre": "MARIA AUXILIADORA, SABANETA, ANTIOQUIA"},
    {"id": 5717,"nombre": "PAN DE AZUCAR, SABANETA, ANTIOQUIA"},
    {"id": 5718,"nombre": "SABANETA, SABANETA, ANTIOQUIA"},
    {"id": 5719,"nombre": "SAN ANTONIO, SABANETA, ANTIOQUIA"},
    {"id": 5720,"nombre": "SAN ISIDRO, SABANETA, ANTIOQUIA"},
    {"id": 5721,"nombre": "EL MOLINO, SABOYA, BOYACA"},
    {"id": 5722,"nombre": "GARAVITO, SABOYA, BOYACA"},
    {"id": 5723,"nombre": "MERCHAN, SABOYA, BOYACA"},
    {"id": 5724,"nombre": "PANTANOS, SABOYA, BOYACA"},
    {"id": 5725,"nombre": "SABOYA, SABOYA, BOYACA"},
    {"id": 5726,"nombre": "VELANDIA, SABOYA, BOYACA"},
    {"id": 5727,"nombre": "SACAMA, SACAMA, CASANARE"},
    {"id": 5728,"nombre": "SACHICA, SACHICA, BOYACA"},
    {"id": 5729,"nombre": "AGUAS VIVAS, SAHAGUN, CORDOBA"},
    {"id": 5730,"nombre": "ARENAS DEL NORTE, SAHAGUN, CORDOBA"},
    {"id": 5731,"nombre": "BAJO GRANDE, SAHAGUN, CORDOBA"},
    {"id": 5732,"nombre": "BRISAS DEL MAR, SAHAGUN, CORDOBA"},
    {"id": 5733,"nombre": "CATALINA, SAHAGUN, CORDOBA"},
    {"id": 5734,"nombre": "CEIBA DE LECHE, SAHAGUN, CORDOBA"},
    {"id": 5735,"nombre": "COLOMBOY, SAHAGUN, CORDOBA"},
    {"id": 5736,"nombre": "DIVIDIVI, SAHAGUN, CORDOBA"},
    {"id": 5737,"nombre": "EL CAMPANO, SAHAGUN, CORDOBA"},
    {"id": 5738,"nombre": "EL CRUCERO, SAHAGUN, CORDOBA"},
    {"id": 5739,"nombre": "EL OLIVO, SAHAGUN, CORDOBA"},
    {"id": 5740,"nombre": "EL ROBLE, SAHAGUN, CORDOBA"},
    {"id": 5741,"nombre": "EL TESORO, SAHAGUN, CORDOBA"},
    {"id": 5742,"nombre": "EL VIAJANO, SAHAGUN, CORDOBA"},
    {"id": 5743,"nombre": "ESCOBALITO, SAHAGUN, CORDOBA"},
    {"id": 5744,"nombre": "GUAIMARO, SAHAGUN, CORDOBA"},
    {"id": 5745,"nombre": "GUAYABAL, SAHAGUN, CORDOBA"},
    {"id": 5746,"nombre": "KILOMETRO 32, SAHAGUN, CORDOBA"},
    {"id": 5747,"nombre": "KILOMETRO 34, SAHAGUN, CORDOBA"},
    {"id": 5748,"nombre": "KILOMETRO 9, SAHAGUN, CORDOBA"},
    {"id": 5749,"nombre": "KILOMETRO TREINTA Y CINCO, SAHAGUN, CORDOBA"},
    {"id": 5750,"nombre": "LA ASAMBLEA, SAHAGUN, CORDOBA"},
    {"id": 5751,"nombre": "LA BALSA, SAHAGUN, CORDOBA"},
    {"id": 5752,"nombre": "LA BURRA, SAHAGUN, CORDOBA"},
    {"id": 5753,"nombre": "LA COROCITA, SAHAGUN, CORDOBA"},
    {"id": 5754,"nombre": "LA FLORESTA, SAHAGUN, CORDOBA"},
    {"id": 5755,"nombre": "LA MUSICA, SAHAGUN, CORDOBA"},
    {"id": 5756,"nombre": "LA YE, SAHAGUN, CORDOBA"},
    {"id": 5757,"nombre": "LAS AGUADITAS, SAHAGUN, CORDOBA"},
    {"id": 5758,"nombre": "LAS BOCAS, SAHAGUN, CORDOBA"},
    {"id": 5759,"nombre": "LAS CRUCES, SAHAGUN, CORDOBA"},
    {"id": 5760,"nombre": "LAS CUMBRES, SAHAGUN, CORDOBA"},
    {"id": 5761,"nombre": "LAS HUERTAS, SAHAGUN, CORDOBA"},
    {"id": 5762,"nombre": "LAS MANUELITAS, SAHAGUN, CORDOBA"},
    {"id": 5763,"nombre": "LLANADAS, SAHAGUN, CORDOBA"},
    {"id": 5764,"nombre": "LOS GALANES, SAHAGUN, CORDOBA"},
    {"id": 5765,"nombre": "LOS PLACERES, SAHAGUN, CORDOBA"},
    {"id": 5766,"nombre": "MORROCOICITO, SAHAGUN, CORDOBA"},
    {"id": 5767,"nombre": "MORROCOY, SAHAGUN, CORDOBA"},
    {"id": 5768,"nombre": "PATIO BONITO, SAHAGUN, CORDOBA"},
    {"id": 5769,"nombre": "PISA FLORES, SAHAGUN, CORDOBA"},
    {"id": 5770,"nombre": "RANCHERIA, SAHAGUN, CORDOBA"},
    {"id": 5771,"nombre": "RIVALENCIA, SAHAGUN, CORDOBA"},
    {"id": 5772,"nombre": "RODANIA (RODACULO), SAHAGUN, CORDOBA"},
    {"id": 5773,"nombre": "SABANETA, SAHAGUN, CORDOBA"},
    {"id": 5774,"nombre": "SAHAGUN, SAHAGUN, CORDOBA"},
    {"id": 5775,"nombre": "SALGUERITO, SAHAGUN, CORDOBA"},
    {"id": 5776,"nombre": "SALITRAL, SAHAGUN, CORDOBA"},
    {"id": 5777,"nombre": "SAN ANTONIO, SAHAGUN, CORDOBA"},
    {"id": 5778,"nombre": "SAN FRANCISCO, SAHAGUN, CORDOBA"},
    {"id": 5779,"nombre": "SAN MIGUEL, SAHAGUN, CORDOBA"},
    {"id": 5780,"nombre": "SANTIAGO ABAJO, SAHAGUN, CORDOBA"},
    {"id": 5781,"nombre": "TREMENTINO, SAHAGUN, CORDOBA"},
    {"id": 5782,"nombre": "LA CABANA, SALADOBLANCO, HUILA"},
    {"id": 5783,"nombre": "LAS MERCEDES, SALADOBLANCO, HUILA"},
    {"id": 5784,"nombre": "MORELIA, SALADOBLANCO, HUILA"},
    {"id": 5785,"nombre": "PARAISO CHILCA, SALADOBLANCO, HUILA"},
    {"id": 5786,"nombre": "PEDREGAL, SALADOBLANCO, HUILA"},
    {"id": 5787,"nombre": "PIRULINDA, SALADOBLANCO, HUILA"},
    {"id": 5788,"nombre": "SALADOBLANCO, SALADOBLANCO, HUILA"},
    {"id": 5789,"nombre": "SAN RAFAEL, SALADOBLANCO, HUILA"},
    {"id": 5790,"nombre": "EL CARMEN DE NAZARETH, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5791,"nombre": "LA LAGUNA, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5792,"nombre": "MONTECRISTO, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5793,"nombre": "QUEBRADA HONDA, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5794,"nombre": "SALAZAR, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5795,"nombre": "SAN ANTONIO, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5796,"nombre": "SAN JOSE DEL AVILA, SALAZAR, NORTE DE SANTANDER"},
    {"id": 5797,"nombre": "BOQUIA, SALENTO, QUINDIO"},
    {"id": 5798,"nombre": "CANAAN, SALENTO, QUINDIO"},
    {"id": 5799,"nombre": "COCORA, SALENTO, QUINDIO"},
    {"id": 5800,"nombre": "EL CAMINO NACIONAL, SALENTO, QUINDIO"},
    {"id": 5801,"nombre": "LA EXPLANACION, SALENTO, QUINDIO"},
    {"id": 5802,"nombre": "LA NUBIA, SALENTO, QUINDIO"},
    {"id": 5803,"nombre": "SALENTO, SALENTO, QUINDIO"},
    {"id": 5804,"nombre": "EL CONCILIO, SALGAR, ANTIOQUIA"},
    {"id": 5805,"nombre": "EL JUNCO, SALGAR, ANTIOQUIA"},
    {"id": 5806,"nombre": "LA ARGELIA, SALGAR, ANTIOQUIA"},
    {"id": 5807,"nombre": "LA CAMARA, SALGAR, ANTIOQUIA"},
    {"id": 5808,"nombre": "LA GULUNGA, SALGAR, ANTIOQUIA"},
    {"id": 5809,"nombre": "LA MARGARITA, SALGAR, ANTIOQUIA"},
    {"id": 5810,"nombre": "LA SIBERIA, SALGAR, ANTIOQUIA"},
    {"id": 5811,"nombre": "LA TABORDA, SALGAR, ANTIOQUIA"},
    {"id": 5812,"nombre": "PENALISA, SALGAR, ANTIOQUIA"},
    {"id": 5813,"nombre": "SALGAR, SALGAR, ANTIOQUIA"},
    {"id": 5814,"nombre": "LA CUMBRE, SAMACA, BOYACA"},
    {"id": 5815,"nombre": "LA FABRICA, SAMACA, BOYACA"},
    {"id": 5816,"nombre": "SAMACA, SAMACA, BOYACA"},
    {"id": 5817,"nombre": "BERLIN, SAMANA, CALDAS"},
    {"id": 5818,"nombre": "EL CONGAL, SAMANA, CALDAS"},
    {"id": 5819,"nombre": "ENCIMADAS, SAMANA, CALDAS"},
    {"id": 5820,"nombre": "FLORENCIA, SAMANA, CALDAS"},
    {"id": 5821,"nombre": "LOS POMOS, SAMANA, CALDAS"},
    {"id": 5822,"nombre": "PUEBLO NUEVO, SAMANA, CALDAS"},
    {"id": 5823,"nombre": "RANCHOLARGO, SAMANA, CALDAS"},
    {"id": 5824,"nombre": "SAMANA, SAMANA, CALDAS"},
    {"id": 5825,"nombre": "SAN DIEGO, SAMANA, CALDAS"},
    {"id": 5826,"nombre": "ANDALUCIA, SAMANIEGO, NARIÑO"},
    {"id": 5827,"nombre": "BOLIVAR, SAMANIEGO, NARIÑO"},
    {"id": 5828,"nombre": "CANADA, SAMANIEGO, NARIÑO"},
    {"id": 5829,"nombre": "CARRIZAL, SAMANIEGO, NARIÑO"},
    {"id": 5830,"nombre": "CARTAGENA, SAMANIEGO, NARIÑO"},
    {"id": 5831,"nombre": "CHUGULDI (CHUPIN), SAMANIEGO, NARIÑO"},
    {"id": 5832,"nombre": "DECIO, SAMANIEGO, NARIÑO"},
    {"id": 5833,"nombre": "DONA ANA, SAMANIEGO, NARIÑO"},
    {"id": 5834,"nombre": "EL LLANO, SAMANIEGO, NARIÑO"},
    {"id": 5835,"nombre": "EL MOTILON (MESA), SAMANIEGO, NARIÑO"},
    {"id": 5836,"nombre": "EL SALADO, SAMANIEGO, NARIÑO"},
    {"id": 5837,"nombre": "EL SALTO, SAMANIEGO, NARIÑO"},
    {"id": 5838,"nombre": "LA AGUADA, SAMANIEGO, NARIÑO"},
    {"id": 5839,"nombre": "LA PLANADA, SAMANIEGO, NARIÑO"},
    {"id": 5840,"nombre": "MARANGUAY, SAMANIEGO, NARIÑO"},
    {"id": 5841,"nombre": "PUERCHAG, SAMANIEGO, NARIÑO"},
    {"id": 5842,"nombre": "SACAMPUES, SAMANIEGO, NARIÑO"},
    {"id": 5843,"nombre": "SAMANIEGO, SAMANIEGO, NARIÑO"},
    {"id": 5844,"nombre": "TANAMA, SAMANIEGO, NARIÑO"},
    {"id": 5845,"nombre": "TURUPAMBA, SAMANIEGO, NARIÑO"},
    {"id": 5846,"nombre": "YUNGUILLA, SAMANIEGO, NARIÑO"},
    {"id": 5847,"nombre": "ACHIOTE, SAMPUES, SUCRE"},
    {"id": 5848,"nombre": "BOSSA NAVARRO, SAMPUES, SUCRE"},
    {"id": 5849,"nombre": "CALLE NUEVA, SAMPUES, SUCRE"},
    {"id": 5850,"nombre": "CEJA DEL MANGO, SAMPUES, SUCRE"},
    {"id": 5851,"nombre": "CORNETA, SAMPUES, SUCRE"},
    {"id": 5852,"nombre": "ESCOBAR ABAJO, SAMPUES, SUCRE"},
    {"id": 5853,"nombre": "ESCOBAR ARRIBA, SAMPUES, SUCRE"},
    {"id": 5854,"nombre": "HUERTAS CHICAS, SAMPUES, SUCRE"},
    {"id": 5855,"nombre": "LA NEGRA, SAMPUES, SUCRE"},
    {"id": 5856,"nombre": "MATEO PEREZ, SAMPUES, SUCRE"},
    {"id": 5857,"nombre": "PALITO, SAMPUES, SUCRE"},
    {"id": 5858,"nombre": "PIEDRAS BLANCAS, SAMPUES, SUCRE"},
    {"id": 5859,"nombre": "SABANALARGA, SAMPUES, SUCRE"},
    {"id": 5860,"nombre": "SAMPUES, SAMPUES, SUCRE"},
    {"id": 5861,"nombre": "SAN LUIS, SAMPUES, SUCRE"},
    {"id": 5862,"nombre": "SEGOVIA, SAMPUES, SUCRE"},
    {"id": 5863,"nombre": "ALTO DEL OBISPO, SAN AGUSTIN, HUILA"},
    {"id": 5864,"nombre": "EL PALMAR, SAN AGUSTIN, HUILA"},
    {"id": 5865,"nombre": "EL ROSARIO, SAN AGUSTIN, HUILA"},
    {"id": 5866,"nombre": "LA CUCHILLA, SAN AGUSTIN, HUILA"},
    {"id": 5867,"nombre": "LOS CAUCHOS, SAN AGUSTIN, HUILA"},
    {"id": 5868,"nombre": "OBANDO, SAN AGUSTIN, HUILA"},
    {"id": 5869,"nombre": "PRADERA, SAN AGUSTIN, HUILA"},
    {"id": 5870,"nombre": "PUERTO QUINCHANA, SAN AGUSTIN, HUILA"},
    {"id": 5871,"nombre": "SAN AGUSTIN, SAN AGUSTIN, HUILA"},
    {"id": 5872,"nombre": "VILLA FATIMA, SAN AGUSTIN, HUILA"},
    {"id": 5873,"nombre": "LA LLANA, SAN ALBERTO, CESAR"},
    {"id": 5874,"nombre": "LA PALMA, SAN ALBERTO, CESAR"},
    {"id": 5875,"nombre": "LIBANO, SAN ALBERTO, CESAR"},
    {"id": 5876,"nombre": "PUERTO CARRENO, SAN ALBERTO, CESAR"},
    {"id": 5877,"nombre": "SAN ALBERTO, SAN ALBERTO, CESAR"},
    {"id": 5878,"nombre": "BIJAITO, SAN ANTERO, CORDOBA"},
    {"id": 5879,"nombre": "EL PORVENIR, SAN ANTERO, CORDOBA"},
    {"id": 5880,"nombre": "LOS SECOS, SAN ANTERO, CORDOBA"},
    {"id": 5881,"nombre": "NUEVO AGRADO, SAN ANTERO, CORDOBA"},
    {"id": 5882,"nombre": "SAN ANTERO, SAN ANTERO, CORDOBA"},
    {"id": 5883,"nombre": "SANTA ROSA DEL BALSAMO, SAN ANTERO, CORDOBA"},
    {"id": 5884,"nombre": "TIJERETAS, SAN ANTERO, CORDOBA"},
    {"id": 5885,"nombre": "LA FLORIDA, SAN ANTONIO, TOLIMA"},
    {"id": 5886,"nombre": "PLAYARRICA, SAN ANTONIO, TOLIMA"},
    {"id": 5887,"nombre": "SAN ANTONIO, SAN ANTONIO, TOLIMA"},
    {"id": 5888,"nombre": "VILLA HERMOSA, SAN ANTONIO, TOLIMA"},
    {"id": 5889,"nombre": "ALTO SAN ROQUE, SAN BENITO, SANTANDER"},
    {"id": 5890,"nombre": "SAN BENITO NUEVO, SAN BENITO, SANTANDER"},
    {"id": 5891,"nombre": "SAN BENITO, SAN BENITO, SANTANDER"},
    {"id": 5892,"nombre": "SAN BENITO, SAN BENITO, SANTANDER"},
    {"id": 5893,"nombre": "BOQUERONES, SAN BENITO ABAD, SUCRE"},
    {"id": 5894,"nombre": "CIENAGA NUEVA, SAN BENITO ABAD, SUCRE"},
    {"id": 5895,"nombre": "CIZPATACA, SAN BENITO ABAD, SUCRE"},
    {"id": 5896,"nombre": "CORRAL VIEJO, SAN BENITO ABAD, SUCRE"},
    {"id": 5897,"nombre": "CUIVA, SAN BENITO ABAD, SUCRE"},
    {"id": 5898,"nombre": "DONA ANA, SAN BENITO ABAD, SUCRE"},
    {"id": 5899,"nombre": "EL CAUCHAL, SAN BENITO ABAD, SUCRE"},
    {"id": 5900,"nombre": "EL LIMON, SAN BENITO ABAD, SUCRE"},
    {"id": 5901,"nombre": "GUAYABAL, SAN BENITO ABAD, SUCRE"},
    {"id": 5902,"nombre": "JEGUA, SAN BENITO ABAD, SUCRE"},
    {"id": 5903,"nombre": "LA CEIBA, SAN BENITO ABAD, SUCRE"},
    {"id": 5904,"nombre": "LA VENTURA, SAN BENITO ABAD, SUCRE"},
    {"id": 5905,"nombre": "LAS DELICIAS, SAN BENITO ABAD, SUCRE"},
    {"id": 5906,"nombre": "LOMA SECA, SAN BENITO ABAD, SUCRE"},
    {"id": 5907,"nombre": "LOS ANGELES, SAN BENITO ABAD, SUCRE"},
    {"id": 5908,"nombre": "POMPUMA, SAN BENITO ABAD, SUCRE"},
    {"id": 5909,"nombre": "PUERTO FRANCO, SAN BENITO ABAD, SUCRE"},
    {"id": 5910,"nombre": "PUNTA DE BLANCO, SAN BENITO ABAD, SUCRE"},
    {"id": 5911,"nombre": "PUNTA NUEVA, SAN BENITO ABAD, SUCRE"},
    {"id": 5912,"nombre": "RABON, SAN BENITO ABAD, SUCRE"},
    {"id": 5913,"nombre": "SAN BENITO ABAD, SAN BENITO ABAD, SUCRE"},
    {"id": 5914,"nombre": "SAN ISIDRO, SAN BENITO ABAD, SUCRE"},
    {"id": 5915,"nombre": "SAN ROQUE, SAN BENITO ABAD, SUCRE"},
    {"id": 5916,"nombre": "SANTIAGO APOSTOL, SAN BENITO ABAD, SUCRE"},
    {"id": 5917,"nombre": "VILLA NUEVA, SAN BENITO ABAD, SUCRE"},
    {"id": 5918,"nombre": "BARBASCAL DE ASTURIAS, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5919,"nombre": "BARCELONA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5920,"nombre": "CAMINO REAL - PARAISO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5921,"nombre": "CANO GRANDE, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5922,"nombre": "CHIQUI, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5923,"nombre": "EL CASTILLO - DAMASCO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5924,"nombre": "JOSE MANUEL DE ALTAMIRA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5925,"nombre": "LAS CANAS, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5926,"nombre": "MIRAMAR, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5927,"nombre": "NUEVA ESTRELLA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5928,"nombre": "PAJONAL, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5929,"nombre": "PASO NUEVO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5930,"nombre": "PLAYAS DEL VIENTO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5931,"nombre": "SAN BERNARDO DEL VIENTO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5932,"nombre": "SAN BLAS DE JUNIN, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5933,"nombre": "SICARA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5934,"nombre": "TINAJON COMPOSTELA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5935,"nombre": "TREMENTINO, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5936,"nombre": "VILLA CLARA, SAN BERNARDO DEL VIENTO, CORDOBA"},
    {"id": 5937,"nombre": "ALGARROBOS, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5938,"nombre": "BANDERAS, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5939,"nombre": "CASAS VIEJAS, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5940,"nombre": "EL ESPEJO, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5941,"nombre": "GUADUALES, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5942,"nombre": "MESALLANA, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5943,"nombre": "PALMARITO, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5944,"nombre": "PALMAS DE VINO, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5945,"nombre": "PUENTE REAL, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5946,"nombre": "QUEBRADA GRANDE, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5947,"nombre": "SAN CALIXTO, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5948,"nombre": "SAN JERONIMO, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5949,"nombre": "SANTA CATALINA, SAN CALIXTO, NORTE DE SANTANDER"},
    {"id": 5950,"nombre": "LA PALMERA, SAN CARLOS DE GUAROA, META"},
    {"id": 5951,"nombre": "RINCON DE PAJURE, SAN CARLOS DE GUAROA, META"},
    {"id": 5952,"nombre": "SAN CARLOS DE GUAROA, SAN CARLOS DE GUAROA, META"},
    {"id": 5953,"nombre": "SURIMENA, SAN CARLOS DE GUAROA, META"},
    {"id": 5954,"nombre": "FINCAS, SAN CRISTOBAL, BOLIVAR"},
    {"id": 5955,"nombre": "HIGUERETAL, SAN CRISTOBAL, BOLIVAR"},
    {"id": 5956,"nombre": "LAS CRUCES, SAN CRISTOBAL, BOLIVAR"},
    {"id": 5957,"nombre": "SAN CRISTOBAL, SAN CRISTOBAL, BOLIVAR"},
    {"id": 5958,"nombre": "EL DESASTRE, SAN DIEGO, CESAR"},
    {"id": 5959,"nombre": "EL JUNCAL, SAN DIEGO, CESAR"},
    {"id": 5960,"nombre": "EL RINCON, SAN DIEGO, CESAR"},
    {"id": 5961,"nombre": "LAS PITILLAS, SAN DIEGO, CESAR"},
    {"id": 5962,"nombre": "LAS TRUPIAS, SAN DIEGO, CESAR"},
    {"id": 5963,"nombre": "LOS BRASILES, SAN DIEGO, CESAR"},
    {"id": 5964,"nombre": "LOS TUPES, SAN DIEGO, CESAR"},
    {"id": 5965,"nombre": "MEDIA LUNA, SAN DIEGO, CESAR"},
    {"id": 5966,"nombre": "NUEVAS FLORES, SAN DIEGO, CESAR"},
    {"id": 5967,"nombre": "SABANAS DEL TESORO, SAN DIEGO, CESAR"},
    {"id": 5968,"nombre": "SAN DIEGO, SAN DIEGO, CESAR"},
    {"id": 5969,"nombre": "TOCAIMO, SAN DIEGO, CESAR"},
    {"id": 5970,"nombre": "SAN EDUARDO, SAN EDUARDO, BOYACA"},
    {"id": 5971,"nombre": "BAYANO, SAN ESTANISLAO, BOLIVAR"},
    {"id": 5972,"nombre": "LAS PIEDRAS, SAN ESTANISLAO, BOLIVAR"},
    {"id": 5973,"nombre": "SAN ESTANISLAO, SAN ESTANISLAO, BOLIVAR"},
    {"id": 5974,"nombre": "SAN FELIPE, SAN FELIPE, GUAINIA"},
    {"id": 5975,"nombre": "CUATRO BOCAS, SAN FERNANDO, BOLIVAR"},
    {"id": 5976,"nombre": "EL CONTADERO, SAN FERNANDO, BOLIVAR"},
    {"id": 5977,"nombre": "EL GATO, SAN FERNANDO, BOLIVAR"},
    {"id": 5978,"nombre": "EL JOLON, SAN FERNANDO, BOLIVAR"},
    {"id": 5979,"nombre": "EL PALMAR, SAN FERNANDO, BOLIVAR"},
    {"id": 5980,"nombre": "EL PORVENIR, SAN FERNANDO, BOLIVAR"},
    {"id": 5981,"nombre": "GUASIMAL, SAN FERNANDO, BOLIVAR"},
    {"id": 5982,"nombre": "LA GUADUA, SAN FERNANDO, BOLIVAR"},
    {"id": 5983,"nombre": "LAS CUEVAS, SAN FERNANDO, BOLIVAR"},
    {"id": 5984,"nombre": "MENCHIQUEJO, SAN FERNANDO, BOLIVAR"},
    {"id": 5985,"nombre": "PAMPANILLO, SAN FERNANDO, BOLIVAR"},
    {"id": 5986,"nombre": "PUEBLO NUEVO, SAN FERNANDO, BOLIVAR"},
    {"id": 5987,"nombre": "PUNTA DE HORNOS, SAN FERNANDO, BOLIVAR"},
    {"id": 5988,"nombre": "SAN FERNANDO, SAN FERNANDO, BOLIVAR"},
    {"id": 5989,"nombre": "SANTA ROSA, SAN FERNANDO, BOLIVAR"},
    {"id": 5990,"nombre": "BEJARANAS, SAN GIL, SANTANDER"},
    {"id": 5991,"nombre": "CANAVERAL, SAN GIL, SANTANDER"},
    {"id": 5992,"nombre": "EL CUCHARO, SAN GIL, SANTANDER"},
    {"id": 5993,"nombre": "EL JOVITO, SAN GIL, SANTANDER"},
    {"id": 5994,"nombre": "EL VOLADOR, SAN GIL, SANTANDER"},
    {"id": 5995,"nombre": "GUARIGUA, SAN GIL, SANTANDER"},
    {"id": 5996,"nombre": "LA FLORA, SAN GIL, SANTANDER"},
    {"id": 5997,"nombre": "LAS JOYAS, SAN GIL, SANTANDER"},
    {"id": 5998,"nombre": "MACANILLO, SAN GIL, SANTANDER"},
    {"id": 5999,"nombre": "OJO DE AGUA, SAN GIL, SANTANDER"},
    {"id": 6000,"nombre": "PALO BLANCO, SAN GIL, SANTANDER"},
    {"id": 6001,"nombre": "POPAGA, SAN GIL, SANTANDER"},
    {"id": 6002,"nombre": "PUENTE DE ARCO, SAN GIL, SANTANDER"},
    {"id": 6003,"nombre": "SAN GIL, SAN GIL, SANTANDER"},
    {"id": 6004,"nombre": "SAN JOSE, SAN GIL, SANTANDER"},
    {"id": 6005,"nombre": "SAN MARTIN, SAN GIL, SANTANDER"},
    {"id": 6006,"nombre": "SAN PEDRO, SAN GIL, SANTANDER"},
    {"id": 6007,"nombre": "SANTA RITA, SAN GIL, SANTANDER"},
    {"id": 6008,"nombre": "VERSALLES, SAN GIL, SANTANDER"},
    {"id": 6009,"nombre": "ARENAS, SAN JACINTO, BOLIVAR"},
    {"id": 6010,"nombre": "BAJO GRANDE, SAN JACINTO, BOLIVAR"},
    {"id": 6011,"nombre": "LAS PALMAS, SAN JACINTO, BOLIVAR"},
    {"id": 6012,"nombre": "LOS CHARQUITOS, SAN JACINTO, BOLIVAR"},
    {"id": 6013,"nombre": "MATUYA, SAN JACINTO, BOLIVAR"},
    {"id": 6014,"nombre": "PARAISO, SAN JACINTO, BOLIVAR"},
    {"id": 6015,"nombre": "SAN CRISTOBAL, SAN JACINTO, BOLIVAR"},
    {"id": 6016,"nombre": "SAN JACINTO, SAN JACINTO, BOLIVAR"},
    {"id": 6017,"nombre": "ASTILLEROS, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6018,"nombre": "BERMUDEZ, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6019,"nombre": "CAIMITAL, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6020,"nombre": "GALINDO, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6021,"nombre": "LA RAYA, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6022,"nombre": "MEJICO, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6023,"nombre": "SAN JACINTO DEL CAUCA, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6024,"nombre": "TENCHE, SAN JACINTO DEL CAUCA, BOLIVAR"},
    {"id": 6025,"nombre": "SAN JERONIMO, SAN JERONIMO, ANTIOQUIA"},
    {"id": 6026,"nombre": "RICAURTE, SAN JOAQUIN, SANTANDER"},
    {"id": 6027,"nombre": "SAN CAYETANO, SAN JOAQUIN, SANTANDER"},
    {"id": 6028,"nombre": "SAN JOAQUIN, SAN JOAQUIN, SANTANDER"},
    {"id": 6029,"nombre": "LA LIBERTAD, SAN JOSE, CALDAS"},
    {"id": 6030,"nombre": "LA PRIMAVERA, SAN JOSE, CALDAS"},
    {"id": 6031,"nombre": "SAN JOSE, SAN JOSE, CALDAS"},
    {"id": 6032,"nombre": "ALTO DE SAN JOSE DE MIRANDA, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6033,"nombre": "CARBONERAS, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6034,"nombre": "CRUZ DE PIEDRA, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6035,"nombre": "CUCHARITO, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6036,"nombre": "PETAQUERO, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6037,"nombre": "SAN JOSE DE MIRANDA, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6038,"nombre": "TEQUIA, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6039,"nombre": "TIERRA BLANCA, SAN JOSE DE MIRANDA, SANTANDER"},
    {"id": 6040,"nombre": "SAN JOSE DE PARE, SAN JOSE DE PARE, BOYACA"},
    {"id": 6041,"nombre": "ARAGUATO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6042,"nombre": "CAPRICHO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6043,"nombre": "CHARRAS, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6044,"nombre": "EL CARACOL, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6045,"nombre": "EL TURPIAL, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6046,"nombre": "FLORIDA II, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6047,"nombre": "GUACAMAYAS, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6048,"nombre": "GUAYABERO (LA CARPA), SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6049,"nombre": "LA LINDOSA, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6050,"nombre": "MOCUARE, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6051,"nombre": "PUERTO ARTURO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6052,"nombre": "PUERTO CACHICAMO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6053,"nombre": "PUERTO NUEVO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6054,"nombre": "PUERTO OSPINA, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6055,"nombre": "RAUDAL DEL GUAYABERO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6056,"nombre": "SABANAS DE LA FUGA, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6057,"nombre": "SAN FRANCISCO, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6058,"nombre": "SAN JOSE DEL GUAVIARE, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6059,"nombre": "TOMACHIPAN, SAN JOSE DEL GUAVIARE, GUAVIARE"},
    {"id": 6060,"nombre": "CORCOVADO, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6061,"nombre": "DAMASCO, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6062,"nombre": "HABITA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6063,"nombre": "LA ALBANIA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6064,"nombre": "LA ITALIA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6065,"nombre": "LA LIBERTAD, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6066,"nombre": "LA SELVA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6067,"nombre": "LOS PATIOS, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6068,"nombre": "PLAYA RICA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6069,"nombre": "RIOBLANCO, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6070,"nombre": "SAN JOSE DEL PALMAR, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6071,"nombre": "SAN PEDRO INGARA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6072,"nombre": "SURAMITA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6073,"nombre": "VALENCIA, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6074,"nombre": "ZABALETA SURAMI, SAN JOSE DEL PALMAR, CHOCO"},
    {"id": 6075,"nombre": "COSTA RICA, SAN JUAN DE ARAMA, META"},
    {"id": 6076,"nombre": "EL VERGEL, SAN JUAN DE ARAMA, META"},
    {"id": 6077,"nombre": "MESA FERNANDEZ, SAN JUAN DE ARAMA, META"},
    {"id": 6078,"nombre": "SAN JUAN DE ARAMA, SAN JUAN DE ARAMA, META"},
    {"id": 6079,"nombre": "ALBANIA, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6080,"nombre": "HATO VIEJO, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6081,"nombre": "LAS CRUCES, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6082,"nombre": "LOMA ALTA, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6083,"nombre": "SABANETA, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6084,"nombre": "SAN JUAN DE BETULIA, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6085,"nombre": "VILLA LOPEZ, SAN JUAN DE BETULIA, SUCRE"},
    {"id": 6086,"nombre": "CAMBAO, SAN JUAN DE RIO SECO, CUNDINAMARCA"},
    {"id": 6087,"nombre": "SAN JUAN DE RIO SECO, SAN JUAN DE RIO SECO, CUNDINAMARCA"},
    {"id": 6088,"nombre": "SAN NICOLAS, SAN JUAN DE RIO SECO, CUNDINAMARCA"},
    {"id": 6089,"nombre": "DAMAQUIEL, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6090,"nombre": "SAN JUAN DE URABA, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6091,"nombre": "SAN JUANCITO, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6092,"nombre": "SAN NICOLAS DEL RIO, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6093,"nombre": "SIETE VUELTAS, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6094,"nombre": "UVEROS, SAN JUAN DE URABA, ANTIOQUIA"},
    {"id": 6095,"nombre": "BOCA DEL MONTE, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6096,"nombre": "CANAVERALES, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6097,"nombre": "CARACOLI, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6098,"nombre": "CORRAL DE PIEDRA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6099,"nombre": "CORRALEJA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6100,"nombre": "CURAZAO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6101,"nombre": "EL HATICO DE LOS INDIOS, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6102,"nombre": "EL PLACER, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6103,"nombre": "EL TABLAZO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6104,"nombre": "EL TOTUMO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6105,"nombre": "GUAYACANAL, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6106,"nombre": "LA JUNTA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6107,"nombre": "LA PENA DE LOS INDIOS, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6108,"nombre": "LA PENA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6109,"nombre": "LA SIERRITA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6110,"nombre": "LAGUNITA, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6111,"nombre": "LOS CORDONES, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6112,"nombre": "LOS HATICOS, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6113,"nombre": "LOS PONDORES, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6114,"nombre": "LOS POZOS, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6115,"nombre": "PONDORITO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6116,"nombre": "POTRERITO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6117,"nombre": "SAN JUAN DEL CESAR, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6118,"nombre": "VILLA DEL RIO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6119,"nombre": "ZAMBRANO, SAN JUAN DEL CESAR, LA GUAJIRA"},
    {"id": 6120,"nombre": "CORRALITO, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6121,"nombre": "LA HAYA, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6122,"nombre": "LAS PORQUERAS (SAN JOSE DEL PENON), SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6123,"nombre": "SAN AGUSTIN, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6124,"nombre": "SAN CAYETANO, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6125,"nombre": "SAN JUAN NEPOMUCENO, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6126,"nombre": "SAN PEDRO CONSOLADO, SAN JUAN NEPOMUCENO, BOLIVAR"},
    {"id": 6127,"nombre": "LA CANDELARIA, SAN JUANITO, META"},
    {"id": 6128,"nombre": "SAN JUANITO, SAN JUANITO, META"},
    {"id": 6129,"nombre": "SAN ROQUE, SAN JUANITO, META"},
    {"id": 6130,"nombre": "EL CARMEN, SAN LORENZO, NARIÑO"},
    {"id": 6131,"nombre": "LA HONDA, SAN LORENZO, NARIÑO"},
    {"id": 6132,"nombre": "SAN CLEMENTE, SAN LORENZO, NARIÑO"},
    {"id": 6133,"nombre": "SAN GERARDO, SAN LORENZO, NARIÑO"},
    {"id": 6134,"nombre": "SAN LORENZO, SAN LORENZO, NARIÑO"},
    {"id": 6135,"nombre": "SAN VICENTE, SAN LORENZO, NARIÑO"},
    {"id": 6136,"nombre": "SANTA CECILIA, SAN LORENZO, NARIÑO"},
    {"id": 6137,"nombre": "SANTA MARTA, SAN LORENZO, NARIÑO"},
    {"id": 6138,"nombre": "GUAMAL, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6139,"nombre": "HORIZONTES, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6140,"nombre": "MESA DEL GUAVIO, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6141,"nombre": "SAN CARLOS, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6142,"nombre": "SAN LUIS DE GACENO, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6143,"nombre": "SANTA TERESA, SAN LUIS DE GACENO, BOYACA"},
    {"id": 6144,"nombre": "BOCAS DE GUANAPALO (MIRAMAR), SAN LUIS DE PALENQUE, CASANARE"},
    {"id": 6145,"nombre": "GAVIOTAS QUITEVE, SAN LUIS DE PALENQUE, CASANARE"},
    {"id": 6146,"nombre": "LA VENTUROSA, SAN LUIS DE PALENQUE, CASANARE"},
    {"id": 6147,"nombre": "SAN LUIS DE PALENQUE, SAN LUIS DE PALENQUE, CASANARE"},
    {"id": 6148,"nombre": "BELEN, SAN MARCOS, SUCRE"},
    {"id": 6149,"nombre": "BUENAVISTA, SAN MARCOS, SUCRE"},
    {"id": 6150,"nombre": "CANDELARIA, SAN MARCOS, SUCRE"},
    {"id": 6151,"nombre": "CANO PRIETO, SAN MARCOS, SUCRE"},
    {"id": 6152,"nombre": "CUENCA, SAN MARCOS, SUCRE"},
    {"id": 6153,"nombre": "EL LIMON, SAN MARCOS, SUCRE"},
    {"id": 6154,"nombre": "EL LLANO, SAN MARCOS, SUCRE"},
    {"id": 6155,"nombre": "EL PITAL, SAN MARCOS, SUCRE"},
    {"id": 6156,"nombre": "EL TABLON, SAN MARCOS, SUCRE"},
    {"id": 6157,"nombre": "EL TORNO, SAN MARCOS, SUCRE"},
    {"id": 6158,"nombre": "LA QUEBRADA, SAN MARCOS, SUCRE"},
    {"id": 6159,"nombre": "LAS FLORES, SAN MARCOS, SUCRE"},
    {"id": 6160,"nombre": "MONTEGRANDE, SAN MARCOS, SUCRE"},
    {"id": 6161,"nombre": "NEIVA, SAN MARCOS, SUCRE"},
    {"id": 6162,"nombre": "PALO ALTO, SAN MARCOS, SUCRE"},
    {"id": 6163,"nombre": "RINCON GUERRANO, SAN MARCOS, SUCRE"},
    {"id": 6164,"nombre": "SAN MARCOS, SAN MARCOS, SUCRE"},
    {"id": 6165,"nombre": "SANTA INES, SAN MARCOS, SUCRE"},
    {"id": 6166,"nombre": "CHIMI, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6167,"nombre": "EL JOBO, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6168,"nombre": "EL VARAL, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6169,"nombre": "PAPAYAL, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6170,"nombre": "PENONCITO, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6171,"nombre": "PLAYITAS, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6172,"nombre": "PUEBLO NUEVO, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6173,"nombre": "SAN MARTIN DE LOBA, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6174,"nombre": "SAN MIGUEL, SAN MARTIN DE LOBA, BOLIVAR"},
    {"id": 6175,"nombre": "EL CHAPETON, SAN MATEO, BOYACA"},
    {"id": 6176,"nombre": "SAN MATEO, SAN MATEO, BOYACA"},
    {"id": 6177,"nombre": "SAN MIGUEL DE SEMA, SAN MIGUEL DE SEMA, BOYACA"},
    {"id": 6178,"nombre": "AGUACATE, SAN ONOFRE, SUCRE"},
    {"id": 6179,"nombre": "AGUAS NEGRAS, SAN ONOFRE, SUCRE"},
    {"id": 6180,"nombre": "BARRANCA, SAN ONOFRE, SUCRE"},
    {"id": 6181,"nombre": "BERLIN, SAN ONOFRE, SUCRE"},
    {"id": 6182,"nombre": "BERRUGAS, SAN ONOFRE, SUCRE"},
    {"id": 6183,"nombre": "BOCACERRADA, SAN ONOFRE, SUCRE"},
    {"id": 6184,"nombre": "BUENOS AIRES, SAN ONOFRE, SUCRE"},
    {"id": 6185,"nombre": "CERRO DOS CASAS, SAN ONOFRE, SUCRE"},
    {"id": 6186,"nombre": "EL CHICHO, SAN ONOFRE, SUCRE"},
    {"id": 6187,"nombre": "HIGUERON, SAN ONOFRE, SUCRE"},
    {"id": 6188,"nombre": "LABARCE, SAN ONOFRE, SUCRE"},
    {"id": 6189,"nombre": "LIBERTAD, SAN ONOFRE, SUCRE"},
    {"id": 6190,"nombre": "PAJONAL, SAN ONOFRE, SUCRE"},
    {"id": 6191,"nombre": "PAJONALITO, SAN ONOFRE, SUCRE"},
    {"id": 6192,"nombre": "PALACIO, SAN ONOFRE, SUCRE"},
    {"id": 6193,"nombre": "PALMIRA, SAN ONOFRE, SUCRE"},
    {"id": 6194,"nombre": "PALO ALTO, SAN ONOFRE, SUCRE"},
    {"id": 6195,"nombre": "PASACORRIENDO, SAN ONOFRE, SUCRE"},
    {"id": 6196,"nombre": "PLAMPAREJO, SAN ONOFRE, SUCRE"},
    {"id": 6197,"nombre": "PUEBLITO, SAN ONOFRE, SUCRE"},
    {"id": 6198,"nombre": "RINCON, SAN ONOFRE, SUCRE"},
    {"id": 6199,"nombre": "SABANA MUCACAL, SAN ONOFRE, SUCRE"},
    {"id": 6200,"nombre": "SAN ANTONIO, SAN ONOFRE, SUCRE"},
    {"id": 6201,"nombre": "SAN ONOFRE, SAN ONOFRE, SUCRE"},
    {"id": 6202,"nombre": "CALAMACO, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6203,"nombre": "CHANARES, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6204,"nombre": "LA PALMARONA, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6205,"nombre": "PENAS BLANCAS, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6206,"nombre": "SAN MARTIN, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6207,"nombre": "SAN PABLO DE BORBUR, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6208,"nombre": "SANTA BARBARA, SAN PABLO DE BORBUR, BOYACA"},
    {"id": 6209,"nombre": "LA COMUNIDAD, SAN PEDRO DE CARTAGO, NARIÑO"},
    {"id": 6210,"nombre": "LA ESTANCIA, SAN PEDRO DE CARTAGO, NARIÑO"},
    {"id": 6211,"nombre": "MARTIN, SAN PEDRO DE CARTAGO, NARIÑO"},
    {"id": 6212,"nombre": "SAN PEDRO DE CARTAGO, SAN PEDRO DE CARTAGO, NARIÑO"},
    {"id": 6213,"nombre": "ARENAS MONAS, SAN PEDRO DE URABA, ANTIOQUIA"},
    {"id": 6214,"nombre": "BUENOS AIRES, SAN PEDRO DE URABA, ANTIOQUIA"},
    {"id": 6215,"nombre": "SAN PEDRO DE URABA, SAN PEDRO DE URABA, ANTIOQUIA"},
    {"id": 6216,"nombre": "SANTA CATALINA, SAN PEDRO DE URABA, ANTIOQUIA"},
    {"id": 6217,"nombre": "ZAPINDONGA, SAN PEDRO DE URABA, ANTIOQUIA"},
    {"id": 6218,"nombre": "BELEN, SAN PELAYO, CORDOBA"},
    {"id": 6219,"nombre": "BOCA DE LOPEZ, SAN PELAYO, CORDOBA"},
    {"id": 6220,"nombre": "BONGAMELLA, SAN PELAYO, CORDOBA"},
    {"id": 6221,"nombre": "BUENOS AIRES, SAN PELAYO, CORDOBA"},
    {"id": 6222,"nombre": "CANA, SAN PELAYO, CORDOBA"},
    {"id": 6223,"nombre": "CANO VIEJO - VALPARAISO, SAN PELAYO, CORDOBA"},
    {"id": 6224,"nombre": "CANUELAR, SAN PELAYO, CORDOBA"},
    {"id": 6225,"nombre": "CARRILLO, SAN PELAYO, CORDOBA"},
    {"id": 6226,"nombre": "COROCITO, SAN PELAYO, CORDOBA"},
    {"id": 6227,"nombre": "EL BALSAMO, SAN PELAYO, CORDOBA"},
    {"id": 6228,"nombre": "EL BONGO, SAN PELAYO, CORDOBA"},
    {"id": 6229,"nombre": "EL CANO, SAN PELAYO, CORDOBA"},
    {"id": 6230,"nombre": "EL COROZO, SAN PELAYO, CORDOBA"},
    {"id": 6231,"nombre": "EL NAPAL, SAN PELAYO, CORDOBA"},
    {"id": 6232,"nombre": "EL NARANJO, SAN PELAYO, CORDOBA"},
    {"id": 6233,"nombre": "EL OBLIGADO, SAN PELAYO, CORDOBA"},
    {"id": 6234,"nombre": "EL RETIRO, SAN PELAYO, CORDOBA"},
    {"id": 6235,"nombre": "EL TAPON, SAN PELAYO, CORDOBA"},
    {"id": 6236,"nombre": "LA CHAMARRA, SAN PELAYO, CORDOBA"},
    {"id": 6237,"nombre": "LA MADERA, SAN PELAYO, CORDOBA"},
    {"id": 6238,"nombre": "LA MAJAGUA, SAN PELAYO, CORDOBA"},
    {"id": 6239,"nombre": "LA TRAMPA, SAN PELAYO, CORDOBA"},
    {"id": 6240,"nombre": "LAS GUAMAS, SAN PELAYO, CORDOBA"},
    {"id": 6241,"nombre": "LAS LAURAS, SAN PELAYO, CORDOBA"},
    {"id": 6242,"nombre": "LOS BORRACHOS, SAN PELAYO, CORDOBA"},
    {"id": 6243,"nombre": "MORALITO, SAN PELAYO, CORDOBA"},
    {"id": 6244,"nombre": "MORROCOY, SAN PELAYO, CORDOBA"},
    {"id": 6245,"nombre": "PAJONAL, SAN PELAYO, CORDOBA"},
    {"id": 6246,"nombre": "PELAYITO, SAN PELAYO, CORDOBA"},
    {"id": 6247,"nombre": "POMPEYA, SAN PELAYO, CORDOBA"},
    {"id": 6248,"nombre": "PROVIDENCIA, SAN PELAYO, CORDOBA"},
    {"id": 6249,"nombre": "PUERTO NUEVO, SAN PELAYO, CORDOBA"},
    {"id": 6250,"nombre": "SABANANUEVA, SAN PELAYO, CORDOBA"},
    {"id": 6251,"nombre": "SAN ISIDRO, SAN PELAYO, CORDOBA"},
    {"id": 6252,"nombre": "SAN JOSE, SAN PELAYO, CORDOBA"},
    {"id": 6253,"nombre": "SAN MIGUEL, SAN PELAYO, CORDOBA"},
    {"id": 6254,"nombre": "SAN PELAYO, SAN PELAYO, CORDOBA"},
    {"id": 6255,"nombre": "TERRON, SAN PELAYO, CORDOBA"},
    {"id": 6256,"nombre": "EL BIZCOCHO, SAN RAFAEL, ANTIOQUIA"},
    {"id": 6257,"nombre": "SAN JULIAN, SAN RAFAEL, ANTIOQUIA"},
    {"id": 6258,"nombre": "SAN RAFAEL, SAN RAFAEL, ANTIOQUIA"},
    {"id": 6259,"nombre": "CRISTALES, SAN ROQUE, ANTIOQUIA"},
    {"id": 6260,"nombre": "PROVIDENCIA, SAN ROQUE, ANTIOQUIA"},
    {"id": 6261,"nombre": "SAN JOSE DEL NUS, SAN ROQUE, ANTIOQUIA"},
    {"id": 6262,"nombre": "SAN ROQUE, SAN ROQUE, ANTIOQUIA"},
    {"id": 6263,"nombre": "EL ROSAL, SAN SEBASTIAN, CAUCA"},
    {"id": 6264,"nombre": "MARMATO, SAN SEBASTIAN, CAUCA"},
    {"id": 6265,"nombre": "PARAMILLOS, SAN SEBASTIAN, CAUCA"},
    {"id": 6266,"nombre": "SAN SEBASTIAN, SAN SEBASTIAN, CAUCA"},
    {"id": 6267,"nombre": "SANTIAGO, SAN SEBASTIAN, CAUCA"},
    {"id": 6268,"nombre": "VALENCIA, SAN SEBASTIAN, CAUCA"},
    {"id": 6269,"nombre": "VENECIA, SAN SEBASTIAN, CAUCA"},
    {"id": 6270,"nombre": "CORRIENTES, SAN VICENTE, ANTIOQUIA"},
    {"id": 6271,"nombre": "SAN VICENTE, SAN VICENTE, ANTIOQUIA"},
    {"id": 6272,"nombre": "ALBANIA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6273,"nombre": "ALTO GRANDE, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6274,"nombre": "CANTARRANA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6275,"nombre": "CLAVELLINAS, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6276,"nombre": "EL 32, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6277,"nombre": "EL TESORO, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6278,"nombre": "GUAMALES, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6279,"nombre": "LA FORTUNA DE LIZAMA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6280,"nombre": "LA TEMPESTUOSA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6281,"nombre": "LLANA FRIA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6282,"nombre": "LOS ALJIBES, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6283,"nombre": "POZO NUTRIAS, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6284,"nombre": "PUENTE MURCIA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6285,"nombre": "SAN VICENTE DE CHUCURI, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6286,"nombre": "SANTO DOMINGO DE RAMOS, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6287,"nombre": "YARIMA, SAN VICENTE DE CHUCURI, SANTANDER"},
    {"id": 6288,"nombre": "BALSILLAS (SAN LUIS DEL OSO), SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6289,"nombre": "CAMPO HERMOSO, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6290,"nombre": "CIUDAD YARI, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6291,"nombre": "EL RECREO, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6292,"nombre": "GIBRALTAR, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6293,"nombre": "GUACAMAYAS, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6294,"nombre": "GUAYABAL, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6295,"nombre": "GUAYABAL, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6296,"nombre": "LAS PAVAS, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6297,"nombre": "PUERTO BETANIA, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6298,"nombre": "SAN VICENTE DEL CAGUAN, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6299,"nombre": "SANTA ROSA, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6300,"nombre": "TRES ESQUINAS, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6301,"nombre": "TRONCALES, SAN VICENTE DEL CAGUAN, CAQUETA"},
    {"id": 6302,"nombre": "ANGOSTURA, SAN ZENON, MAGDALENA"},
    {"id": 6303,"nombre": "BERMEJAL, SAN ZENON, MAGDALENA"},
    {"id": 6304,"nombre": "EL HORNO, SAN ZENON, MAGDALENA"},
    {"id": 6305,"nombre": "GUINEA, SAN ZENON, MAGDALENA"},
    {"id": 6306,"nombre": "JANEIRO, SAN ZENON, MAGDALENA"},
    {"id": 6307,"nombre": "LA MONTANA, SAN ZENON, MAGDALENA"},
    {"id": 6308,"nombre": "PALMIRA, SAN ZENON, MAGDALENA"},
    {"id": 6309,"nombre": "PALOMAR, SAN ZENON, MAGDALENA"},
    {"id": 6310,"nombre": "PENONCITO, SAN ZENON, MAGDALENA"},
    {"id": 6311,"nombre": "PUERTO ARTURO, SAN ZENON, MAGDALENA"},
    {"id": 6312,"nombre": "SAN JOSE DE CHIMILA, SAN ZENON, MAGDALENA"},
    {"id": 6313,"nombre": "SAN ZENON, SAN ZENON, MAGDALENA"},
    {"id": 6314,"nombre": "SANTA TERESA, SAN ZENON, MAGDALENA"},
    {"id": 6315,"nombre": "20 DE JULIO, SANDONA, NARIÑO"},
    {"id": 6316,"nombre": "ALTAMIRA CRUZ DE ARADA, SANDONA, NARIÑO"},
    {"id": 6317,"nombre": "BALCON ALTO, SANDONA, NARIÑO"},
    {"id": 6318,"nombre": "BOHORQUEZ, SANDONA, NARIÑO"},
    {"id": 6319,"nombre": "BOLIVAR, SANDONA, NARIÑO"},
    {"id": 6320,"nombre": "CHAVEZ, SANDONA, NARIÑO"},
    {"id": 6321,"nombre": "EL INGENIO, SANDONA, NARIÑO"},
    {"id": 6322,"nombre": "EL TAMBILLO, SANDONA, NARIÑO"},
    {"id": 6323,"nombre": "EL VERGEL, SANDONA, NARIÑO"},
    {"id": 6324,"nombre": "LA LOMA, SANDONA, NARIÑO"},
    {"id": 6325,"nombre": "LA REGADERA, SANDONA, NARIÑO"},
    {"id": 6326,"nombre": "LOMA DEL TAMBILLO, SANDONA, NARIÑO"},
    {"id": 6327,"nombre": "PARAGUAY, SANDONA, NARIÑO"},
    {"id": 6328,"nombre": "PLAN INGENIO, SANDONA, NARIÑO"},
    {"id": 6329,"nombre": "ROMA CHAVEZ, SANDONA, NARIÑO"},
    {"id": 6330,"nombre": "SAN ANDRES, SANDONA, NARIÑO"},
    {"id": 6331,"nombre": "SAN BERNARDO, SANDONA, NARIÑO"},
    {"id": 6332,"nombre": "SAN FERNANDO, SANDONA, NARIÑO"},
    {"id": 6333,"nombre": "SAN FRANCISCO ALTO, SANDONA, NARIÑO"},
    {"id": 6334,"nombre": "SAN ISIDRO, SANDONA, NARIÑO"},
    {"id": 6335,"nombre": "SAN MIGUEL, SANDONA, NARIÑO"},
    {"id": 6336,"nombre": "SANDONA, SANDONA, NARIÑO"},
    {"id": 6337,"nombre": "SANTA BARBARA, SANDONA, NARIÑO"},
    {"id": 6338,"nombre": "SANTA ROSA, SANDONA, NARIÑO"},
    {"id": 6339,"nombre": "BARRO BLANCO, SANTA ANA, MAGDALENA"},
    {"id": 6340,"nombre": "GAVILAN, SANTA ANA, MAGDALENA"},
    {"id": 6341,"nombre": "JARABA, SANTA ANA, MAGDALENA"},
    {"id": 6342,"nombre": "LOS ANDES, SANTA ANA, MAGDALENA"},
    {"id": 6343,"nombre": "PINTO, SANTA ANA, MAGDALENA"},
    {"id": 6344,"nombre": "SAN FERNANDO, SANTA ANA, MAGDALENA"},
    {"id": 6345,"nombre": "SANTA ANA, SANTA ANA, MAGDALENA"},
    {"id": 6346,"nombre": "SANTA ROSA, SANTA ANA, MAGDALENA"},
    {"id": 6347,"nombre": "CUNDINAMARCA, SANTA BARBARA DE PINTO, MAGDALENA"},
    {"id": 6348,"nombre": "SAN PEDRO, SANTA BARBARA DE PINTO, MAGDALENA"},
    {"id": 6349,"nombre": "SANTA BARBARA DE PINTO, SANTA BARBARA DE PINTO, MAGDALENA"},
    {"id": 6350,"nombre": "VELADERO, SANTA BARBARA DE PINTO, MAGDALENA"},
    {"id": 6351,"nombre": "COLORADO VIEJO, SANTA CATALINA, BOLIVAR"},
    {"id": 6352,"nombre": "GALERAZAMBA, SANTA CATALINA, BOLIVAR"},
    {"id": 6353,"nombre": "LOMA DE ARENA, SANTA CATALINA, BOLIVAR"},
    {"id": 6354,"nombre": "PUEBLO NUEVO, SANTA CATALINA, BOLIVAR"},
    {"id": 6355,"nombre": "SANTA CATALINA, SANTA CATALINA, BOLIVAR"},
    {"id": 6356,"nombre": "ARAGUA, SANTA HELENA DEL OPON, SANTANDER"},
    {"id": 6357,"nombre": "CACHIPAY, SANTA HELENA DEL OPON, SANTANDER"},
    {"id": 6358,"nombre": "PLAN DE ALVAREZ, SANTA HELENA DEL OPON, SANTANDER"},
    {"id": 6359,"nombre": "SAN JUAN BOSCO, SANTA HELENA DEL OPON, SANTANDER"},
    {"id": 6360,"nombre": "SANTA HELENA DEL OPON, SANTA HELENA DEL OPON, SANTANDER"},
    {"id": 6361,"nombre": "COLON, SANTA ISABEL, TOLIMA"},
    {"id": 6362,"nombre": "LA CONGOJA, SANTA ISABEL, TOLIMA"},
    {"id": 6363,"nombre": "LA ESTRELLA, SANTA ISABEL, TOLIMA"},
    {"id": 6364,"nombre": "SAN RAFAEL, SANTA ISABEL, TOLIMA"},
    {"id": 6365,"nombre": "SANTA ISABEL, SANTA ISABEL, TOLIMA"},
    {"id": 6366,"nombre": "ALGODONAL, SANTA LUCIA, ATLANTICO"},
    {"id": 6367,"nombre": "SANTA LUCIA, SANTA LUCIA, ATLANTICO"},
    {"id": 6368,"nombre": "BARRIO CRISTO REY, SANTA MARTA, MAGDALENA"},
    {"id": 6369,"nombre": "BARRIO LA PAZ, SANTA MARTA, MAGDALENA"},
    {"id": 6370,"nombre": "BARRIO VEINTE DE OCTUBRE, SANTA MARTA, MAGDALENA"},
    {"id": 6371,"nombre": "BELLOSOL - BELLO HORIZONTE, SANTA MARTA, MAGDALENA"},
    {"id": 6372,"nombre": "BONDA, SANTA MARTA, MAGDALENA"},
    {"id": 6373,"nombre": "BURITICA, SANTA MARTA, MAGDALENA"},
    {"id": 6374,"nombre": "CALABAZO, SANTA MARTA, MAGDALENA"},
    {"id": 6375,"nombre": "DON DIEGO, SANTA MARTA, MAGDALENA"},
    {"id": 6376,"nombre": "EL CAMPANO, SANTA MARTA, MAGDALENA"},
    {"id": 6377,"nombre": "GAIRA, SANTA MARTA, MAGDALENA"},
    {"id": 6378,"nombre": "GUACHACA, SANTA MARTA, MAGDALENA"},
    {"id": 6379,"nombre": "LA TAGUA, SANTA MARTA, MAGDALENA"},
    {"id": 6380,"nombre": "MINCA, SANTA MARTA, MAGDALENA"},
    {"id": 6381,"nombre": "PUERTO NUEVO, SANTA MARTA, MAGDALENA"},
    {"id": 6382,"nombre": "SANTA MARTA, SANTA MARTA, MAGDALENA"},
    {"id": 6383,"nombre": "TAGANGA, SANTA MARTA, MAGDALENA"},
    {"id": 6384,"nombre": "TIGRERA, SANTA MARTA, MAGDALENA"},
    {"id": 6385,"nombre": "URBANIZACION DON JACA, SANTA MARTA, MAGDALENA"},
    {"id": 6386,"nombre": "URBANIZACION PLENOMAR, SANTA MARTA, MAGDALENA"},
    {"id": 6387,"nombre": "SANTA ROSA DE CABAL, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6388,"nombre": "BOQUERON, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6389,"nombre": "CORREGIMIENTO DEL SUR, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6390,"nombre": "EL ESPANOL, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6391,"nombre": "EL GUAMAL, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6392,"nombre": "EL LEMBO, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6393,"nombre": "EL MANZANILLO, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6394,"nombre": "FERMIN LOPEZ, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6395,"nombre": "GUACAS, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6396,"nombre": "GUAIMARAL, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6397,"nombre": "LA CAPILLA, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6398,"nombre": "LOS NEVADOS, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6399,"nombre": "SAN RAMON, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6400,"nombre": "SANTA BARBARA, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6401,"nombre": "SANTA ROSA DE CABAL, SANTA ROSA DE CABAL, RISARALDA"},
    {"id": 6402,"nombre": "ALTO DE LA MINA (SAGRADO CORAZON DE JESU, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6403,"nombre": "ARAGON, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6404,"nombre": "EL CANEY, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6405,"nombre": "HOYO RICO, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6406,"nombre": "LAS PAYAS, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6407,"nombre": "LLANOS DE CUIVA, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6408,"nombre": "RIO GRANDE, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6409,"nombre": "SAN ISIDRO, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6410,"nombre": "SAN NICOLAS, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6411,"nombre": "SAN PABLO, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6412,"nombre": "SANTA ROSA DE OSOS, SANTA ROSA DE OSOS, ANTIOQUIA"},
    {"id": 6413,"nombre": "EL IMPERIO, SANTA ROSA DE VITERBO, BOYACA"},
    {"id": 6414,"nombre": "SANTA ROSA DE VITERBO, SANTA ROSA DE VITERBO, BOYACA"},
    {"id": 6415,"nombre": "BUENAVISTA, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6416,"nombre": "BUENOS AIRES (SAN FRANCISCO), SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6417,"nombre": "FATIMA, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6418,"nombre": "LOS CANELOS, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6419,"nombre": "SAN JOSE, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6420,"nombre": "SAN LUCAS, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6421,"nombre": "SANTA ROSA DEL SUR, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6422,"nombre": "VILLA FLOR, SANTA ROSA DEL SUR, BOLIVAR"},
    {"id": 6423,"nombre": "GUACACIAS, SANTA ROSALIA, VICHADA"},
    {"id": 6424,"nombre": "SANTA ROSALIA, SANTA ROSALIA, VICHADA"},
    {"id": 6425,"nombre": "SANTA SOFIA, SANTA SOFIA, BOYACA"},
    {"id": 6426,"nombre": "BALALAIKA, SANTACRUZ, NARIÑO"},
    {"id": 6427,"nombre": "CHIPACUERDO, SANTACRUZ, NARIÑO"},
    {"id": 6428,"nombre": "EL EDEN, SANTACRUZ, NARIÑO"},
    {"id": 6429,"nombre": "EL SANDE, SANTACRUZ, NARIÑO"},
    {"id": 6430,"nombre": "INGA, SANTACRUZ, NARIÑO"},
    {"id": 6431,"nombre": "MANCHAG, SANTACRUZ, NARIÑO"},
    {"id": 6432,"nombre": "PIARAMAG, SANTACRUZ, NARIÑO"},
    {"id": 6433,"nombre": "SANTA ROSA, SANTACRUZ, NARIÑO"},
    {"id": 6434,"nombre": "GUACHAVES, SANTACRUZ, NARIÑO"},
    {"id": 6435,"nombre": "CATIVO, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6436,"nombre": "EL PESCADO, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6437,"nombre": "GUASABRA - LAURELES, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6438,"nombre": "LAS AZULES, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6439,"nombre": "LLANO DE BRISAS, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6440,"nombre": "SANTAFE DE ANTIOQUIA, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6441,"nombre": "SOCORRO DE SABANAS, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6442,"nombre": "TONUSCO ARRIBA, SANTAFE DE ANTIOQUIA, ANTIOQUIA"},
    {"id": 6443,"nombre": "EL ESCOBAL, SANTANA, BOYACA"},
    {"id": 6444,"nombre": "SANTANA, SANTANA, BOYACA"},
    {"id": 6445,"nombre": "ALEGRIAS, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6446,"nombre": "CABECERA DOMINGUILLO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6447,"nombre": "CAMBALACHE, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6448,"nombre": "DOMINGUILLO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6449,"nombre": "EL BROCHE, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6450,"nombre": "EL CRUCERO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6451,"nombre": "EL LLANITO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6452,"nombre": "EL PALMAR, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6453,"nombre": "EL TAJO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6454,"nombre": "EL TURCO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6455,"nombre": "GUINAMACO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6456,"nombre": "LA AGUSTINA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6457,"nombre": "LA ARROBLEDA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6458,"nombre": "LA CAPILLA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6459,"nombre": "LA CHAPA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6460,"nombre": "LA PALOMERA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6461,"nombre": "LA QUEBRADA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6462,"nombre": "LOMITAS ABAJO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6463,"nombre": "LOMITAS ARRIBA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6464,"nombre": "LOMITAS, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6465,"nombre": "LOURDES, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6466,"nombre": "MANDIVA, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6467,"nombre": "MONDOMO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6468,"nombre": "PARAMILLO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6469,"nombre": "QUINAMAYO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6470,"nombre": "SAN ANTONIO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6471,"nombre": "SAN ISIDRO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6472,"nombre": "SAN JOSE, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6473,"nombre": "SAN PEDRO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6474,"nombre": "SAN RAFAEL, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6475,"nombre": "SANTANDER DE QUILICHAO, SANTANDER DE QUILICHAO, CAUCA"},
    {"id": 6476,"nombre": "BOCA DE CIENAGA, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6477,"nombre": "COVENAS DOS, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6478,"nombre": "COVENAS DOS, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6479,"nombre": "COVENAS, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6480,"nombre": "EL REPARO, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6481,"nombre": "GUAYABAL, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6482,"nombre": "NUEVA ERA, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6483,"nombre": "PITA ABAJO, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6484,"nombre": "PITA EN MEDIO, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6485,"nombre": "PUERTO VIEJO, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6486,"nombre": "PUNTA SECA O SANTAFE, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6487,"nombre": "SANTIAGO DE TOLU, SANTIAGO DE TOLU, SUCRE"},
    {"id": 6488,"nombre": "BOTERO, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6489,"nombre": "EL LIMON, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6490,"nombre": "LA QUIEBRA, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6491,"nombre": "PORCE, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6492,"nombre": "PORCECITO, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6493,"nombre": "SANTIAGO, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6494,"nombre": "SANTO DOMINGO, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6495,"nombre": "VERSALLES, SANTO DOMINGO, ANTIOQUIA"},
    {"id": 6496,"nombre": "SANTO TOMAS, SANTO TOMAS, ATLANTICO"},
    {"id": 6497,"nombre": "UVITO, SANTO TOMAS, ATLANTICO"},
    {"id": 6498,"nombre": "BUENOS AIRES, SANTUARIO, RISARALDA"},
    {"id": 6499,"nombre": "CORINTO, SANTUARIO, RISARALDA"},
    {"id": 6500,"nombre": "EL TAMBO, SANTUARIO, RISARALDA"},
    {"id": 6501,"nombre": "LA BAMBA, SANTUARIO, RISARALDA"},
    {"id": 6502,"nombre": "LA BRETANA, SANTUARIO, RISARALDA"},
    {"id": 6503,"nombre": "LA ESMERALDA, SANTUARIO, RISARALDA"},
    {"id": 6504,"nombre": "LA MARINA, SANTUARIO, RISARALDA"},
    {"id": 6505,"nombre": "PERALONSO, SANTUARIO, RISARALDA"},
    {"id": 6506,"nombre": "PLAYA RICA, SANTUARIO, RISARALDA"},
    {"id": 6507,"nombre": "PUEBLO VANO, SANTUARIO, RISARALDA"},
    {"id": 6508,"nombre": "SANTUARIO, SANTUARIO, RISARALDA"},
    {"id": 6509,"nombre": "TOTUY, SANTUARIO, RISARALDA"},
    {"id": 6510,"nombre": "EL ESPINO, SAPUYES, NARIÑO"},
    {"id": 6511,"nombre": "FLORESTA, SAPUYES, NARIÑO"},
    {"id": 6512,"nombre": "LOS MONOS, SAPUYES, NARIÑO"},
    {"id": 6513,"nombre": "MALABER, SAPUYES, NARIÑO"},
    {"id": 6514,"nombre": "PANAMAL, SAPUYES, NARIÑO"},
    {"id": 6515,"nombre": "SAPUYES, SAPUYES, NARIÑO"},
    {"id": 6516,"nombre": "URIBE, SAPUYES, NARIÑO"},
    {"id": 6517,"nombre": "AGUA SANTA, SARAVENA, ARAUCA"},
    {"id": 6518,"nombre": "ALTO SATOCA, SARAVENA, ARAUCA"},
    {"id": 6519,"nombre": "BARRANCONES, SARAVENA, ARAUCA"},
    {"id": 6520,"nombre": "ISLA DEL CHARO, SARAVENA, ARAUCA"},
    {"id": 6521,"nombre": "LA COLORADA, SARAVENA, ARAUCA"},
    {"id": 6522,"nombre": "PUENTE DE BOJABA, SARAVENA, ARAUCA"},
    {"id": 6523,"nombre": "PUERTO BANADIA, SARAVENA, ARAUCA"},
    {"id": 6524,"nombre": "PUERTO LLERAS, SARAVENA, ARAUCA"},
    {"id": 6525,"nombre": "PUERTO NARIÑO, SARAVENA, ARAUCA"},
    {"id": 6526,"nombre": "SARAVENA, SARAVENA, ARAUCA"},
    {"id": 6527,"nombre": "BALSAMINA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6528,"nombre": "CAMPO RICO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6529,"nombre": "CASCAJAL, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6530,"nombre": "EL CARMEN, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6531,"nombre": "EL HIGUERON, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6532,"nombre": "EL RIECITO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6533,"nombre": "EL VESUBIO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6534,"nombre": "FATIMA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6535,"nombre": "JORDANCITO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6536,"nombre": "LA CARTAGENA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6537,"nombre": "LA CRISTALINA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6538,"nombre": "LA ESMERALDA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6539,"nombre": "LA LLANA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6540,"nombre": "LA PRIMAVERA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6541,"nombre": "LA VICTORIA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6542,"nombre": "LAS MERCEDES, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6543,"nombre": "LAS MESAS, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6544,"nombre": "LOS GUAMOS, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6545,"nombre": "LUIS VERO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6546,"nombre": "PARAMILLO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6547,"nombre": "PLANADAS, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6548,"nombre": "RIO NUEVO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6549,"nombre": "SAN GIL, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6550,"nombre": "SAN ISIDRO, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6551,"nombre": "SAN MARTIN DE LOBA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6552,"nombre": "SAN ROQUE, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6553,"nombre": "SAN SEBASTIAN, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6554,"nombre": "SARDINATA, SARDINATA, NORTE DE SANTANDER"},
    {"id": 6555,"nombre": "LA VICTORIA, SASAIMA, CUNDINAMARCA"},
    {"id": 6556,"nombre": "SANTA CRUZ, SASAIMA, CUNDINAMARCA"},
    {"id": 6557,"nombre": "SASAIMA, SASAIMA, CUNDINAMARCA"},
    {"id": 6558,"nombre": "LA JUPA, SATIVANORTE, BOYACA"},
    {"id": 6559,"nombre": "SATIVA VIEJO, SATIVANORTE, BOYACA"},
    {"id": 6560,"nombre": "SATIVANORTE, SATIVANORTE, BOYACA"},
    {"id": 6561,"nombre": "SATIVASUR, SATIVASUR, BOYACA"},
    {"id": 6562,"nombre": "FRAGUAS, SEGOVIA, ANTIOQUIA"},
    {"id": 6563,"nombre": "SEGOVIA, SEGOVIA, ANTIOQUIA"},
    {"id": 6564,"nombre": "EL HATO LA CAPILLA, SESQUILE, CUNDINAMARCA"},
    {"id": 6565,"nombre": "LA PLAYA, SESQUILE, CUNDINAMARCA"},
    {"id": 6566,"nombre": "SESQUILE, SESQUILE, CUNDINAMARCA"},
    {"id": 6567,"nombre": "ALEGRIAS, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6568,"nombre": "CEBOLLAL, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6569,"nombre": "COLORADAS, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6570,"nombre": "COMINALES, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6571,"nombre": "COROZAL, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6572,"nombre": "CRISTALES, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6573,"nombre": "CUMBARCO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6574,"nombre": "EL CANADA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6575,"nombre": "EL MANZANILLO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6576,"nombre": "EL VENADO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6577,"nombre": "ESTACION CAICEDONIA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6578,"nombre": "LA ASTELIA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6579,"nombre": "LA CUCHILLA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6580,"nombre": "LA ESTRELLA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6581,"nombre": "LA MELBA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6582,"nombre": "LA MILONGA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6583,"nombre": "LA RAQUELITA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6584,"nombre": "LAS BRISAS, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6585,"nombre": "MORRO AZUL, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6586,"nombre": "PALOMINO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6587,"nombre": "QUEBRADANUEVA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6588,"nombre": "SAN ANTONIO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6589,"nombre": "SEVILLA, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6590,"nombre": "TOTORO, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6591,"nombre": "TRES ESQUINAS, SEVILLA, VALLE DEL CAUCA"},
    {"id": 6592,"nombre": "SIACHOQUE, SIACHOQUE, BOYACA"},
    {"id": 6593,"nombre": "CHACUA, SIBATE, CUNDINAMARCA"},
    {"id": 6594,"nombre": "SAN BENITO, SIBATE, CUNDINAMARCA"},
    {"id": 6595,"nombre": "SIBATE, SIBATE, CUNDINAMARCA"},
    {"id": 6596,"nombre": "EL EGIDO, SIBUNDOY, PUTUMAYO"},
    {"id": 6597,"nombre": "POROTOYOCA, SIBUNDOY, PUTUMAYO"},
    {"id": 6598,"nombre": "SAN ANDRES, SIBUNDOY, PUTUMAYO"},
    {"id": 6599,"nombre": "SAN ANTONIO, SIBUNDOY, PUTUMAYO"},
    {"id": 6600,"nombre": "SAN FRANCISCO, SIBUNDOY, PUTUMAYO"},
    {"id": 6601,"nombre": "SAN PEDRO, SIBUNDOY, PUTUMAYO"},
    {"id": 6602,"nombre": "SANTIAGO, SIBUNDOY, PUTUMAYO"},
    {"id": 6603,"nombre": "SIBUNDOY, SIBUNDOY, PUTUMAYO"},
    {"id": 6604,"nombre": "BABEGA, SILOS, NORTE DE SANTANDER"},
    {"id": 6605,"nombre": "BELEN, SILOS, NORTE DE SANTANDER"},
    {"id": 6606,"nombre": "LOS RINCON, SILOS, NORTE DE SANTANDER"},
    {"id": 6607,"nombre": "SILOS, SILOS, NORTE DE SANTANDER"},
    {"id": 6608,"nombre": "AZAFRANAL, SILVANIA, CUNDINAMARCA"},
    {"id": 6609,"nombre": "QUEBRADA HONDA, SILVANIA, CUNDINAMARCA"},
    {"id": 6610,"nombre": "SANTA RITA, SILVANIA, CUNDINAMARCA"},
    {"id": 6611,"nombre": "SILVANIA, SILVANIA, CUNDINAMARCA"},
    {"id": 6612,"nombre": "SUBIA, SILVANIA, CUNDINAMARCA"},
    {"id": 6613,"nombre": "YOYATA, SILVANIA, CUNDINAMARCA"},
    {"id": 6614,"nombre": "ALTO GRANDE, SILVIA, CAUCA"},
    {"id": 6615,"nombre": "EL CACIQUE, SILVIA, CAUCA"},
    {"id": 6616,"nombre": "EL SALADO, SILVIA, CAUCA"},
    {"id": 6617,"nombre": "GUAMBIA, SILVIA, CAUCA"},
    {"id": 6618,"nombre": "LA AGUADA, SILVIA, CAUCA"},
    {"id": 6619,"nombre": "LA CAMPANA, SILVIA, CAUCA"},
    {"id": 6620,"nombre": "LA ESTRELLA, SILVIA, CAUCA"},
    {"id": 6621,"nombre": "LA OVEJERA, SILVIA, CAUCA"},
    {"id": 6622,"nombre": "LAGUNA SECA, SILVIA, CAUCA"},
    {"id": 6623,"nombre": "LOMA DEL CARMEN, SILVIA, CAUCA"},
    {"id": 6624,"nombre": "MENDEZ, SILVIA, CAUCA"},
    {"id": 6625,"nombre": "MICHAMBE, SILVIA, CAUCA"},
    {"id": 6626,"nombre": "MIRAFLORES, SILVIA, CAUCA"},
    {"id": 6627,"nombre": "PITAYO, SILVIA, CAUCA"},
    {"id": 6628,"nombre": "PUEBLECITO, SILVIA, CAUCA"},
    {"id": 6629,"nombre": "PUENTE REAL, SILVIA, CAUCA"},
    {"id": 6630,"nombre": "QUICHAYA, SILVIA, CAUCA"},
    {"id": 6631,"nombre": "SAN ANTONIO, SILVIA, CAUCA"},
    {"id": 6632,"nombre": "SAN PEDRO DEL BOSQUE, SILVIA, CAUCA"},
    {"id": 6633,"nombre": "SILVIA, SILVIA, CAUCA"},
    {"id": 6634,"nombre": "TUMBURAO, SILVIA, CAUCA"},
    {"id": 6635,"nombre": "USENDA, SILVIA, CAUCA"},
    {"id": 6636,"nombre": "VALLE NUEVO, SILVIA, CAUCA"},
    {"id": 6637,"nombre": "AGUA BLANCA, SIMACOTA, SANTANDER"},
    {"id": 6638,"nombre": "CINCO MIL, SIMACOTA, SANTANDER"},
    {"id": 6639,"nombre": "EL SALTO, SIMACOTA, SANTANDER"},
    {"id": 6640,"nombre": "GUAYABAL, SIMACOTA, SANTANDER"},
    {"id": 6641,"nombre": "LA LLANITA, SIMACOTA, SANTANDER"},
    {"id": 6642,"nombre": "LA ROCHELA, SIMACOTA, SANTANDER"},
    {"id": 6643,"nombre": "SIMACOTA, SIMACOTA, SANTANDER"},
    {"id": 6644,"nombre": "VIZCAINA ALTA, SIMACOTA, SANTANDER"},
    {"id": 6645,"nombre": "VIZCAINA BAJA, SIMACOTA, SANTANDER"},
    {"id": 6646,"nombre": "SIMIJACA, SIMIJACA, CUNDINAMARCA"},
    {"id": 6647,"nombre": "CAMPO PALLARES, SIMITI, BOLIVAR"},
    {"id": 6648,"nombre": "LAS BRISAS, SIMITI, BOLIVAR"},
    {"id": 6649,"nombre": "MONTERREY, SIMITI, BOLIVAR"},
    {"id": 6650,"nombre": "SAN BLAS, SIMITI, BOLIVAR"},
    {"id": 6651,"nombre": "SAN LUIS, SIMITI, BOLIVAR"},
    {"id": 6652,"nombre": "SANTA ROSA, SIMITI, BOLIVAR"},
    {"id": 6653,"nombre": "SIMITI, SIMITI, BOLIVAR"},
    {"id": 6654,"nombre": "VERACRUZ, SIMITI, BOLIVAR"},
    {"id": 6655,"nombre": "BUENAVISTA, SINCELEJO, SUCRE"},
    {"id": 6656,"nombre": "BUENAVISTICA, SINCELEJO, SUCRE"},
    {"id": 6657,"nombre": "CASTANEDA, SINCELEJO, SUCRE"},
    {"id": 6658,"nombre": "CHOCHO, SINCELEJO, SUCRE"},
    {"id": 6659,"nombre": "CRUZ DEL BEQUE, SINCELEJO, SUCRE"},
    {"id": 6660,"nombre": "EL CERRITO, SINCELEJO, SUCRE"},
    {"id": 6661,"nombre": "LA ARENA, SINCELEJO, SUCRE"},
    {"id": 6662,"nombre": "LA CHIVERA, SINCELEJO, SUCRE"},
    {"id": 6663,"nombre": "LA GALLERA, SINCELEJO, SUCRE"},
    {"id": 6664,"nombre": "LA PENATA, SINCELEJO, SUCRE"},
    {"id": 6665,"nombre": "LAGUNA FLOR, SINCELEJO, SUCRE"},
    {"id": 6666,"nombre": "LAS HUERTAS, SINCELEJO, SUCRE"},
    {"id": 6667,"nombre": "LAS MAJAGUAS, SINCELEJO, SUCRE"},
    {"id": 6668,"nombre": "LAS PALMAS, SINCELEJO, SUCRE"},
    {"id": 6669,"nombre": "MOCHA, SINCELEJO, SUCRE"},
    {"id": 6670,"nombre": "SABANAS POTRERO, SINCELEJO, SUCRE"},
    {"id": 6671,"nombre": "SAN ANTONIO, SINCELEJO, SUCRE"},
    {"id": 6672,"nombre": "SAN RAFAEL, SINCELEJO, SUCRE"},
    {"id": 6673,"nombre": "SINCELEJO, SINCELEJO, SUCRE"},
    {"id": 6674,"nombre": "VILLA SAN MARTIN, SINCELEJO, SUCRE"},
    {"id": 6675,"nombre": "CANAVERAL, SIPI, CHOCO"},
    {"id": 6676,"nombre": "GARRAPATAS, SIPI, CHOCO"},
    {"id": 6677,"nombre": "SAN AGUSTIN, SIPI, CHOCO"},
    {"id": 6678,"nombre": "SIPI, SIPI, CHOCO"},
    {"id": 6679,"nombre": "TANANDO, SIPI, CHOCO"},
    {"id": 6680,"nombre": "TAPARAL, SIPI, CHOCO"},
    {"id": 6681,"nombre": "BUENAVISTA, SITIONUEVO, MAGDALENA"},
    {"id": 6682,"nombre": "CALUCA, SITIONUEVO, MAGDALENA"},
    {"id": 6683,"nombre": "CANO VALLE, SITIONUEVO, MAGDALENA"},
    {"id": 6684,"nombre": "CARMONA, SITIONUEVO, MAGDALENA"},
    {"id": 6685,"nombre": "NUEVA VENECIA, SITIONUEVO, MAGDALENA"},
    {"id": 6686,"nombre": "PALERMO, SITIONUEVO, MAGDALENA"},
    {"id": 6687,"nombre": "SAN ANTONIO, SITIONUEVO, MAGDALENA"},
    {"id": 6688,"nombre": "SITIONUEVO, SITIONUEVO, MAGDALENA"},
    {"id": 6689,"nombre": "EL CHARQUITO, SOACHA, CUNDINAMARCA"},
    {"id": 6690,"nombre": "GUASIMAL, SOACHA, CUNDINAMARCA"},
    {"id": 6691,"nombre": "LA DESPENSA, SOACHA, CUNDINAMARCA"},
    {"id": 6692,"nombre": "LA VEREDITA, SOACHA, CUNDINAMARCA"},
    {"id": 6693,"nombre": "SAN JORGE, SOACHA, CUNDINAMARCA"},
    {"id": 6694,"nombre": "SANTANA, SOACHA, CUNDINAMARCA"},
    {"id": 6695,"nombre": "SOACHA, SOACHA, CUNDINAMARCA"},
    {"id": 6696,"nombre": "TINZUQUE, SOACHA, CUNDINAMARCA"},
    {"id": 6697,"nombre": "LA COSTA, SOATA, BOYACA"},
    {"id": 6698,"nombre": "LA JOBONERA, SOATA, BOYACA"},
    {"id": 6699,"nombre": "PUENTE PINZON, SOATA, BOYACA"},
    {"id": 6700,"nombre": "SOATA, SOATA, BOYACA"},
    {"id": 6701,"nombre": "PUEBLO NUEVO, SOCHA, BOYACA"},
    {"id": 6702,"nombre": "SANTA TERESA, SOCHA, BOYACA"},
    {"id": 6703,"nombre": "SOCHA, SOCHA, BOYACA"},
    {"id": 6704,"nombre": "SOCHA VIEJO, SOCHA, BOYACA"},
    {"id": 6705,"nombre": "SOCORRO, SOCORRO, SANTANDER"},
    {"id": 6706,"nombre": "CHIPAVIEJO, SOCOTA, BOYACA"},
    {"id": 6707,"nombre": "COSCATIVA, SOCOTA, BOYACA"},
    {"id": 6708,"nombre": "EL OSO, SOCOTA, BOYACA"},
    {"id": 6709,"nombre": "LA MANGA, SOCOTA, BOYACA"},
    {"id": 6710,"nombre": "LOS PINOS, SOCOTA, BOYACA"},
    {"id": 6711,"nombre": "PUEBLOVIEJO, SOCOTA, BOYACA"},
    {"id": 6712,"nombre": "SOCOTA, SOCOTA, BOYACA"},
    {"id": 6713,"nombre": "MORCA, SOGAMOSO, BOYACA"},
    {"id": 6714,"nombre": "SOGAMOSO, SOGAMOSO, BOYACA"},
    {"id": 6715,"nombre": "ARARACUARA, SOLANO, CAQUETA"},
    {"id": 6716,"nombre": "CUEMANI, SOLANO, CAQUETA"},
    {"id": 6717,"nombre": "CURIPLAYA (HUITORA), SOLANO, CAQUETA"},
    {"id": 6718,"nombre": "DANUBIO (CAMPO ALEGRE), SOLANO, CAQUETA"},
    {"id": 6719,"nombre": "MONONGUETE, SOLANO, CAQUETA"},
    {"id": 6720,"nombre": "PENA ROJA, SOLANO, CAQUETA"},
    {"id": 6721,"nombre": "PENAS BLANCAS, SOLANO, CAQUETA"},
    {"id": 6722,"nombre": "PUERTO TEJADA, SOLANO, CAQUETA"},
    {"id": 6723,"nombre": "SOLANO, SOLANO, CAQUETA"},
    {"id": 6724,"nombre": "SOLEDAD, SOLEDAD, ATLANTICO"},
    {"id": 6725,"nombre": "KILOMETRO 30, SOLITA, CAQUETA"},
    {"id": 6726,"nombre": "SOLITA, SOLITA, CAQUETA"},
    {"id": 6727,"nombre": "SOMONDOCO, SOMONDOCO, BOYACA"},
    {"id": 6728,"nombre": "ALTO DE SABANAS, SONSON, ANTIOQUIA"},
    {"id": 6729,"nombre": "JERUSALEN, SONSON, ANTIOQUIA"},
    {"id": 6730,"nombre": "LA DANTA, SONSON, ANTIOQUIA"},
    {"id": 6731,"nombre": "LOS MEDIOS, SONSON, ANTIOQUIA"},
    {"id": 6732,"nombre": "LOS POTREROS, SONSON, ANTIOQUIA"},
    {"id": 6733,"nombre": "RIO VERDE DE LOS HENAOS, SONSON, ANTIOQUIA"},
    {"id": 6734,"nombre": "RIO VERDE DE LOS MONTES, SONSON, ANTIOQUIA"},
    {"id": 6735,"nombre": "SAN MIGUEL, SONSON, ANTIOQUIA"},
    {"id": 6736,"nombre": "SONSON, SONSON, ANTIOQUIA"},
    {"id": 6737,"nombre": "CORDOBA, SOPETRAN, ANTIOQUIA"},
    {"id": 6738,"nombre": "EL RODEO, SOPETRAN, ANTIOQUIA"},
    {"id": 6739,"nombre": "GUAYABAL, SOPETRAN, ANTIOQUIA"},
    {"id": 6740,"nombre": "HORIZONTES, SOPETRAN, ANTIOQUIA"},
    {"id": 6741,"nombre": "LLANOS DE MONTANA, SOPETRAN, ANTIOQUIA"},
    {"id": 6742,"nombre": "LOMA DEL MEDIO, SOPETRAN, ANTIOQUIA"},
    {"id": 6743,"nombre": "MONTEGRANDE, SOPETRAN, ANTIOQUIA"},
    {"id": 6744,"nombre": "SAN NICOLAS, SOPETRAN, ANTIOQUIA"},
    {"id": 6745,"nombre": "SOPETRAN, SOPETRAN, ANTIOQUIA"},
    {"id": 6746,"nombre": "SOPLAVIENTO, SOPLAVIENTO, BOLIVAR"},
    {"id": 6747,"nombre": "BRICENO, SOPO, CUNDINAMARCA"},
    {"id": 6748,"nombre": "GRATAMIRA, SOPO, CUNDINAMARCA"},
    {"id": 6749,"nombre": "HATO GRANDE, SOPO, CUNDINAMARCA"},
    {"id": 6750,"nombre": "MERCENARIO, SOPO, CUNDINAMARCA"},
    {"id": 6751,"nombre": "MEUSA, SOPO, CUNDINAMARCA"},
    {"id": 6752,"nombre": "PUEBLO VIEJO ALTO, SOPO, CUNDINAMARCA"},
    {"id": 6753,"nombre": "SOPO, SOPO, CUNDINAMARCA"},
    {"id": 6754,"nombre": "SORA, SORA, BOYACA"},
    {"id": 6755,"nombre": "SORACA, SORACA, BOYACA"},
    {"id": 6756,"nombre": "EL MORAL, SOTAQUIRA, BOYACA"},
    {"id": 6757,"nombre": "SOTAQUIRA, SOTAQUIRA, BOYACA"},
    {"id": 6758,"nombre": "BUENAVISTA, SOTARA, CAUCA"},
    {"id": 6759,"nombre": "CHAPA, SOTARA, CAUCA"},
    {"id": 6760,"nombre": "CHIRIBIO, SOTARA, CAUCA"},
    {"id": 6761,"nombre": "EL CARMEN, SOTARA, CAUCA"},
    {"id": 6762,"nombre": "EL CRUCERO, SOTARA, CAUCA"},
    {"id": 6763,"nombre": "HATOFRIO, SOTARA, CAUCA"},
    {"id": 6764,"nombre": "LA PAZ, SOTARA, CAUCA"},
    {"id": 6765,"nombre": "PIEDRA DE LEON, SOTARA, CAUCA"},
    {"id": 6766,"nombre": "PUEBLOQUEMADO, SOTARA, CAUCA"},
    {"id": 6767,"nombre": "RIO BLANCO, SOTARA, CAUCA"},
    {"id": 6768,"nombre": "SACHACOCO, SOTARA, CAUCA"},
    {"id": 6769,"nombre": "PAISPAMBA, SOTARA, CAUCA"},
    {"id": 6770,"nombre": "OLIVAL, SUAITA, SANTANDER"},
    {"id": 6771,"nombre": "SAN JOSE SUAITA, SUAITA, SANTANDER"},
    {"id": 6772,"nombre": "SUAITA, SUAITA, SANTANDER"},
    {"id": 6773,"nombre": "TOLOTA, SUAITA, SANTANDER"},
    {"id": 6774,"nombre": "VADO REAL, SUAITA, SANTANDER"},
    {"id": 6775,"nombre": "SUAN, SUAN, ATLANTICO"},
    {"id": 6776,"nombre": "AVISPERO, SUAZA, HUILA"},
    {"id": 6777,"nombre": "EL SALADO, SUAZA, HUILA"},
    {"id": 6778,"nombre": "GALLARDO, SUAZA, HUILA"},
    {"id": 6779,"nombre": "GUAYABAL, SUAZA, HUILA"},
    {"id": 6780,"nombre": "HATO VIEJO, SUAZA, HUILA"},
    {"id": 6781,"nombre": "MANTAGUA, SUAZA, HUILA"},
    {"id": 6782,"nombre": "PICUNA, SUAZA, HUILA"},
    {"id": 6783,"nombre": "SAFIA, SUAZA, HUILA"},
    {"id": 6784,"nombre": "SAN CALIXTO, SUAZA, HUILA"},
    {"id": 6785,"nombre": "SUAZA, SUAZA, HUILA"},
    {"id": 6786,"nombre": "GALDAMEZ, SUBACHOQUE, CUNDINAMARCA"},
    {"id": 6787,"nombre": "LA PRADERA, SUBACHOQUE, CUNDINAMARCA"},
    {"id": 6788,"nombre": "SUBACHOQUE, SUBACHOQUE, CUNDINAMARCA"},
    {"id": 6789,"nombre": "CACICAZGO, SUESCA, CUNDINAMARCA"},
    {"id": 6790,"nombre": "HATO GRANDE, SUESCA, CUNDINAMARCA"},
    {"id": 6791,"nombre": "SAN VICENTE, SUESCA, CUNDINAMARCA"},
    {"id": 6792,"nombre": "SANTA ROSITA, SUESCA, CUNDINAMARCA"},
    {"id": 6793,"nombre": "SUESCA, SUESCA, CUNDINAMARCA"},
    {"id": 6794,"nombre": "LA MAGOLA, SUPATA, CUNDINAMARCA"},
    {"id": 6795,"nombre": "SUPATA, SUPATA, CUNDINAMARCA"},
    {"id": 6796,"nombre": "EL RODEO, SUPIA, CALDAS"},
    {"id": 6797,"nombre": "GUAMAL, SUPIA, CALDAS"},
    {"id": 6798,"nombre": "HOJAS ANCHAS, SUPIA, CALDAS"},
    {"id": 6799,"nombre": "LA QUINTA, SUPIA, CALDAS"},
    {"id": 6800,"nombre": "PUEBLO NUEVO, SUPIA, CALDAS"},
    {"id": 6801,"nombre": "SUPIA, SUPIA, CALDAS"},
    {"id": 6802,"nombre": "CACHIRI, SURATA, SANTANDER"},
    {"id": 6803,"nombre": "CARTAGUA, SURATA, SANTANDER"},
    {"id": 6804,"nombre": "EL MOHAN, SURATA, SANTANDER"},
    {"id": 6805,"nombre": "SURATA, SURATA, SANTANDER"},
    {"id": 6806,"nombre": "TURBAY, SURATA, SANTANDER"},
    {"id": 6807,"nombre": "SUSA, SUSA, CUNDINAMARCA"},
    {"id": 6808,"nombre": "SUSACON, SUSACON, BOYACA"},
    {"id": 6809,"nombre": "SUTAMARCHAN, SUTAMARCHAN, BOYACA"},
    {"id": 6810,"nombre": "LAS PENAS, SUTATAUSA, CUNDINAMARCA"},
    {"id": 6811,"nombre": "SUTATAUSA, SUTATAUSA, CUNDINAMARCA"},
    {"id": 6812,"nombre": "SUTATENZA, SUTATENZA, BOYACA"},
    {"id": 6813,"nombre": "EL CARRON, TABIO, CUNDINAMARCA"},
    {"id": 6814,"nombre": "PARCELACION TERMALES, TABIO, CUNDINAMARCA"},
    {"id": 6815,"nombre": "RIO FRIO ORIENTAL, TABIO, CUNDINAMARCA"},
    {"id": 6816,"nombre": "SANTA BARBARA, TABIO, CUNDINAMARCA"},
    {"id": 6817,"nombre": "TABIO, TABIO, CUNDINAMARCA"},
    {"id": 6818,"nombre": "AGUA CLARA, TADO, CHOCO"},
    {"id": 6819,"nombre": "ARRASTRADERO, TADO, CHOCO"},
    {"id": 6820,"nombre": "BETANIA, TADO, CHOCO"},
    {"id": 6821,"nombre": "CARMELO, TADO, CHOCO"},
    {"id": 6822,"nombre": "CORCOVADO, TADO, CHOCO"},
    {"id": 6823,"nombre": "EL TAPON, TADO, CHOCO"},
    {"id": 6824,"nombre": "GUARATO, TADO, CHOCO"},
    {"id": 6825,"nombre": "IBORDO, TADO, CHOCO"},
    {"id": 6826,"nombre": "LA ESPERANZA, TADO, CHOCO"},
    {"id": 6827,"nombre": "MANUNGARA, TADO, CHOCO"},
    {"id": 6828,"nombre": "MUMBU, TADO, CHOCO"},
    {"id": 6829,"nombre": "PLAYA DE ORO, TADO, CHOCO"},
    {"id": 6830,"nombre": "PROFUNDO, TADO, CHOCO"},
    {"id": 6831,"nombre": "QUIADO, TADO, CHOCO"},
    {"id": 6832,"nombre": "SALERO, TADO, CHOCO"},
    {"id": 6833,"nombre": "TABOR, TADO, CHOCO"},
    {"id": 6834,"nombre": "TADO, TADO, CHOCO"},
    {"id": 6835,"nombre": "VARIANTE CERTEGA, TADO, CHOCO"},
    {"id": 6836,"nombre": "CANOHONDO, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6837,"nombre": "EL PENON, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6838,"nombre": "EL PORVENIR, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6839,"nombre": "EL VESUBIO, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6840,"nombre": "LA LADERA DE SAN MARTIN, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6841,"nombre": "LAS MARIAS, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6842,"nombre": "LOS MANGOS, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6843,"nombre": "PATICO, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6844,"nombre": "TALAIGUA NUEVO, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6845,"nombre": "TALAIGUA VIEJO, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6846,"nombre": "TUPE, TALAIGUA NUEVO, BOLIVAR"},
    {"id": 6847,"nombre": "ANTEQUERA, TAMALAMEQUE, CESAR"},
    {"id": 6848,"nombre": "LA BOCA, TAMALAMEQUE, CESAR"},
    {"id": 6849,"nombre": "LAS BRISAS, TAMALAMEQUE, CESAR"},
    {"id": 6850,"nombre": "LAS PALMAS, TAMALAMEQUE, CESAR"},
    {"id": 6851,"nombre": "PALESTINA LA NUEVA, TAMALAMEQUE, CESAR"},
    {"id": 6852,"nombre": "PASACORRIENDO, TAMALAMEQUE, CESAR"},
    {"id": 6853,"nombre": "PUEBLONUEVO, TAMALAMEQUE, CESAR"},
    {"id": 6854,"nombre": "TAMALAMEQUE, TAMALAMEQUE, CESAR"},
    {"id": 6855,"nombre": "ZAPATOSA, TAMALAMEQUE, CESAR"},
    {"id": 6856,"nombre": "EL ARIPORO, TAMARA, CASANARE"},
    {"id": 6857,"nombre": "TABLON DE TAMARA, TAMARA, CASANARE"},
    {"id": 6858,"nombre": "TABLONCITO, TAMARA, CASANARE"},
    {"id": 6859,"nombre": "TAMARA, TAMARA, CASANARE"},
    {"id": 6860,"nombre": "TEISLANDIA, TAMARA, CASANARE"},
    {"id": 6861,"nombre": "TEN, TAMARA, CASANARE"},
    {"id": 6862,"nombre": "BETOYES, TAME, ARAUCA"},
    {"id": 6863,"nombre": "BOTALON, TAME, ARAUCA"},
    {"id": 6864,"nombre": "CACHAMA, TAME, ARAUCA"},
    {"id": 6865,"nombre": "CARUNAL, TAME, ARAUCA"},
    {"id": 6866,"nombre": "COROCITO MACAGUA, TAME, ARAUCA"},
    {"id": 6867,"nombre": "CUSAY, TAME, ARAUCA"},
    {"id": 6868,"nombre": "EL BANCO, TAME, ARAUCA"},
    {"id": 6869,"nombre": "GRAN BUCARE, TAME, ARAUCA"},
    {"id": 6870,"nombre": "LA HOLANDA, TAME, ARAUCA"},
    {"id": 6871,"nombre": "LAS GAVIOTAS, TAME, ARAUCA"},
    {"id": 6872,"nombre": "MAPOY, TAME, ARAUCA"},
    {"id": 6873,"nombre": "PUENTA TABLA, TAME, ARAUCA"},
    {"id": 6874,"nombre": "PUERTO GAITAN, TAME, ARAUCA"},
    {"id": 6875,"nombre": "PUERTO MIRANDA, TAME, ARAUCA"},
    {"id": 6876,"nombre": "PUERTO NARIÑO, TAME, ARAUCA"},
    {"id": 6877,"nombre": "PUERTO NYDIA, TAME, ARAUCA"},
    {"id": 6878,"nombre": "SAN IGRIDERO, TAME, ARAUCA"},
    {"id": 6879,"nombre": "SAN LOPE, TAME, ARAUCA"},
    {"id": 6880,"nombre": "SAN SALVADOR, TAME, ARAUCA"},
    {"id": 6881,"nombre": "TAME, TAME, ARAUCA"},
    {"id": 6882,"nombre": "NUDILLALES, TAMESIS, ANTIOQUIA"},
    {"id": 6883,"nombre": "OTRA BANDA, TAMESIS, ANTIOQUIA"},
    {"id": 6884,"nombre": "PALERMO, TAMESIS, ANTIOQUIA"},
    {"id": 6885,"nombre": "SAN LUIS, TAMESIS, ANTIOQUIA"},
    {"id": 6886,"nombre": "SAN PABLO, TAMESIS, ANTIOQUIA"},
    {"id": 6887,"nombre": "SANTA ANA, TAMESIS, ANTIOQUIA"},
    {"id": 6888,"nombre": "SANTA TERESA, TAMESIS, ANTIOQUIA"},
    {"id": 6889,"nombre": "TAMESIS, TAMESIS, ANTIOQUIA"},
    {"id": 6890,"nombre": "ALTO DE DIEGO, TAMINANGO, NARIÑO"},
    {"id": 6891,"nombre": "CHARGUAYACO, TAMINANGO, NARIÑO"},
    {"id": 6892,"nombre": "CORDOBA, TAMINANGO, NARIÑO"},
    {"id": 6893,"nombre": "CURIACO, TAMINANGO, NARIÑO"},
    {"id": 6894,"nombre": "EL MANZANO, TAMINANGO, NARIÑO"},
    {"id": 6895,"nombre": "EL TABLON, TAMINANGO, NARIÑO"},
    {"id": 6896,"nombre": "GRANADA, TAMINANGO, NARIÑO"},
    {"id": 6897,"nombre": "PUERTO DE LEON, TAMINANGO, NARIÑO"},
    {"id": 6898,"nombre": "REMOLINO, TAMINANGO, NARIÑO"},
    {"id": 6899,"nombre": "TAMINANGO, TAMINANGO, NARIÑO"},
    {"id": 6900,"nombre": "CEBADAL, TANGUA, NARIÑO"},
    {"id": 6901,"nombre": "LA COCHA, TANGUA, NARIÑO"},
    {"id": 6902,"nombre": "SAN FRANCISCO, TANGUA, NARIÑO"},
    {"id": 6903,"nombre": "SAN RAFAEL, TANGUA, NARIÑO"},
    {"id": 6904,"nombre": "SAN VICENTE, TANGUA, NARIÑO"},
    {"id": 6905,"nombre": "SANTANDER, TANGUA, NARIÑO"},
    {"id": 6906,"nombre": "TANGUA, TANGUA, NARIÑO"},
    {"id": 6907,"nombre": "TAPIALQUER ALTO, TANGUA, NARIÑO"},
    {"id": 6908,"nombre": "TARAIRA, TARAIRA, VAUPES"},
    {"id": 6909,"nombre": "TARAPACA, TARAPACA, AMAZONAS"},
    {"id": 6910,"nombre": "BARRO BLANCO, TARAZA, ANTIOQUIA"},
    {"id": 6911,"nombre": "EL DOCE, TARAZA, ANTIOQUIA"},
    {"id": 6912,"nombre": "LA CAUCANA, TARAZA, ANTIOQUIA"},
    {"id": 6913,"nombre": "PUERTO ANTIOQUIA, TARAZA, ANTIOQUIA"},
    {"id": 6914,"nombre": "TARAZA, TARAZA, ANTIOQUIA"},
    {"id": 6915,"nombre": "BUENOS AIRES, TARQUI, HUILA"},
    {"id": 6916,"nombre": "CAIMITAL, TARQUI, HUILA"},
    {"id": 6917,"nombre": "EL CEDRO, TARQUI, HUILA"},
    {"id": 6918,"nombre": "EL TABLON, TARQUI, HUILA"},
    {"id": 6919,"nombre": "EL TAMBO, TARQUI, HUILA"},
    {"id": 6920,"nombre": "EL VERGEL, TARQUI, HUILA"},
    {"id": 6921,"nombre": "LA EUREKA, TARQUI, HUILA"},
    {"id": 6922,"nombre": "LA PAMPA, TARQUI, HUILA"},
    {"id": 6923,"nombre": "LAGUNILLA, TARQUI, HUILA"},
    {"id": 6924,"nombre": "MAITO, TARQUI, HUILA"},
    {"id": 6925,"nombre": "QUITURO, TARQUI, HUILA"},
    {"id": 6926,"nombre": "RICA BRISA, TARQUI, HUILA"},
    {"id": 6927,"nombre": "TARQUI, TARQUI, HUILA"},
    {"id": 6928,"nombre": "MORRON, TARSO, ANTIOQUIA"},
    {"id": 6929,"nombre": "TARSO, TARSO, ANTIOQUIA"},
    {"id": 6930,"nombre": "LIBERTADORES, TASCO, BOYACA"},
    {"id": 6931,"nombre": "TASCO, TASCO, BOYACA"},
    {"id": 6932,"nombre": "CARUPA, TAURAMENA, CASANARE"},
    {"id": 6933,"nombre": "EL ACEITE, TAURAMENA, CASANARE"},
    {"id": 6934,"nombre": "SANTA MARIA, TAURAMENA, CASANARE"},
    {"id": 6935,"nombre": "TAURAMENA, TAURAMENA, CASANARE"},
    {"id": 6936,"nombre": "TUNUPE, TAURAMENA, CASANARE"},
    {"id": 6937,"nombre": "BOQUERON, TAUSA, CUNDINAMARCA"},
    {"id": 6938,"nombre": "EL SALITRE, TAUSA, CUNDINAMARCA"},
    {"id": 6939,"nombre": "PARAMO ALTO, TAUSA, CUNDINAMARCA"},
    {"id": 6940,"nombre": "ROMA (TAUSA VIEJO), TAUSA, CUNDINAMARCA"},
    {"id": 6941,"nombre": "TAUSA, TAUSA, CUNDINAMARCA"},
    {"id": 6942,"nombre": "ANACLETO GARCIA, TELLO, HUILA"},
    {"id": 6943,"nombre": "BOLIVIA, TELLO, HUILA"},
    {"id": 6944,"nombre": "EL CEDRAL, TELLO, HUILA"},
    {"id": 6945,"nombre": "LA URRACA, TELLO, HUILA"},
    {"id": 6946,"nombre": "LA VEGA, TELLO, HUILA"},
    {"id": 6947,"nombre": "MESA DEL TRAPICHE, TELLO, HUILA"},
    {"id": 6948,"nombre": "MESA REDONDA, TELLO, HUILA"},
    {"id": 6949,"nombre": "SAN ANDRES, TELLO, HUILA"},
    {"id": 6950,"nombre": "SAN ISIDRO BAJO, TELLO, HUILA"},
    {"id": 6951,"nombre": "SIERRA DE GRAMAL, TELLO, HUILA"},
    {"id": 6952,"nombre": "SIERRA DE LA CANADA, TELLO, HUILA"},
    {"id": 6953,"nombre": "TELLO, TELLO, HUILA"},
    {"id": 6954,"nombre": "LA GRAN VIA, TENA, CUNDINAMARCA"},
    {"id": 6955,"nombre": "TENA, TENA, CUNDINAMARCA"},
    {"id": 6956,"nombre": "JUNCAL, TENERIFE, MAGDALENA"},
    {"id": 6957,"nombre": "REAL OBISPO, TENERIFE, MAGDALENA"},
    {"id": 6958,"nombre": "SAN ANTONIO, TENERIFE, MAGDALENA"},
    {"id": 6959,"nombre": "SAN LUIS BELTRAN, TENERIFE, MAGDALENA"},
    {"id": 6960,"nombre": "SANTA INES DEL MONTE, TENERIFE, MAGDALENA"},
    {"id": 6961,"nombre": "TENERIFE, TENERIFE, MAGDALENA"},
    {"id": 6962,"nombre": "LA PUNTA, TENJO, CUNDINAMARCA"},
    {"id": 6963,"nombre": "MARTIN ESPINO, TENJO, CUNDINAMARCA"},
    {"id": 6964,"nombre": "TENJO, TENJO, CUNDINAMARCA"},
    {"id": 6965,"nombre": "TENZA, TENZA, BOYACA"},
    {"id": 6966,"nombre": "ALTO ROSARIO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6967,"nombre": "CERRO LAS FLORES, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6968,"nombre": "CUATRO ESQUINAS, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6969,"nombre": "EL ASERRIO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6970,"nombre": "EL GUARICO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6971,"nombre": "EL JUNCAL, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6972,"nombre": "EL OSO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6973,"nombre": "EL TIGRE, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6974,"nombre": "EL TRIGO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6975,"nombre": "FARACHE, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6976,"nombre": "GUARANAO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6977,"nombre": "LA CECILIA, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6978,"nombre": "LA JABONERA, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6979,"nombre": "LA TEJA, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6980,"nombre": "LA TRINIDAD, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6981,"nombre": "MANZANARES, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6982,"nombre": "MIRACOTES, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6983,"nombre": "PILOCOTO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6984,"nombre": "QUINCE LETRAS, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6985,"nombre": "RAMIREZ, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6986,"nombre": "RIO DE ORO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6987,"nombre": "SAN JOSE, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6988,"nombre": "SAN JUAN DE DIOS, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6989,"nombre": "SAN PABLO, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6990,"nombre": "TEORAMA, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6991,"nombre": "TRAVESIAS, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6992,"nombre": "VIJAGUAL, TEORAMA, NORTE DE SANTANDER"},
    {"id": 6993,"nombre": "SAN PEDRO, TERUEL, HUILA"},
    {"id": 6994,"nombre": "TERUEL, TERUEL, HUILA"},
    {"id": 6995,"nombre": "PACARNI, TESALIA, HUILA"},
    {"id": 6996,"nombre": "TESALIA, TESALIA, HUILA"},
    {"id": 6997,"nombre": "BATEAS, TIBACUY, CUNDINAMARCA"},
    {"id": 6998,"nombre": "CUMACA, TIBACUY, CUNDINAMARCA"},
    {"id": 6999,"nombre": "TIBACUY, TIBACUY, CUNDINAMARCA"},
    {"id": 7000,"nombre": "GAMBITA ARRIBA, TIBANA, BOYACA"},
    {"id": 7001,"nombre": "SUPANECA ARRIBA, TIBANA, BOYACA"},
    {"id": 7002,"nombre": "SUTA ARRIBA, TIBANA, BOYACA"},
    {"id": 7003,"nombre": "TIBANA, TIBANA, BOYACA"},
    {"id": 7004,"nombre": "EL PARAISO, TIBASOSA, BOYACA"},
    {"id": 7005,"nombre": "SANTA TERESA, TIBASOSA, BOYACA"},
    {"id": 7006,"nombre": "TIBASOSA, TIBASOSA, BOYACA"},
    {"id": 7007,"nombre": "PUERTO LOPEZ, TIBIRITA, CUNDINAMARCA"},
    {"id": 7008,"nombre": "TIBIRITA, TIBIRITA, CUNDINAMARCA"},
    {"id": 7009,"nombre": "AEROPUERTO LA PISTA, TIBU, NORTE DE SANTANDER"},
    {"id": 7010,"nombre": "BARCO LA SILLA, TIBU, NORTE DE SANTANDER"},
    {"id": 7011,"nombre": "CAMPO DOS, TIBU, NORTE DE SANTANDER"},
    {"id": 7012,"nombre": "CAMPO GILES, TIBU, NORTE DE SANTANDER"},
    {"id": 7013,"nombre": "EL SESENTA, TIBU, NORTE DE SANTANDER"},
    {"id": 7014,"nombre": "LA GABARRA, TIBU, NORTE DE SANTANDER"},
    {"id": 7015,"nombre": "LA LLANA, TIBU, NORTE DE SANTANDER"},
    {"id": 7016,"nombre": "PACCELLY, TIBU, NORTE DE SANTANDER"},
    {"id": 7017,"nombre": "PETROLEA, TIBU, NORTE DE SANTANDER"},
    {"id": 7018,"nombre": "PLAYA COTIZA, TIBU, NORTE DE SANTANDER"},
    {"id": 7019,"nombre": "RIO DE ORO, TIBU, NORTE DE SANTANDER"},
    {"id": 7020,"nombre": "SAN MIGUEL, TIBU, NORTE DE SANTANDER"},
    {"id": 7021,"nombre": "TIBU, TIBU, NORTE DE SANTANDER"},
    {"id": 7022,"nombre": "TRES BOCAS, TIBU, NORTE DE SANTANDER"},
    {"id": 7023,"nombre": "VERSALLES, TIBU, NORTE DE SANTANDER"},
    {"id": 7024,"nombre": "VETAS DE ORIENTE, TIBU, NORTE DE SANTANDER"},
    {"id": 7025,"nombre": "BARRANCON, TIERRALTA, CORDOBA"},
    {"id": 7026,"nombre": "BATATA, TIERRALTA, CORDOBA"},
    {"id": 7027,"nombre": "BONITO VIENTO, TIERRALTA, CORDOBA"},
    {"id": 7028,"nombre": "CADILLO, TIERRALTA, CORDOBA"},
    {"id": 7029,"nombre": "CALLEJAS, TIERRALTA, CORDOBA"},
    {"id": 7030,"nombre": "CARAMELO, TIERRALTA, CORDOBA"},
    {"id": 7031,"nombre": "CELMIRA, TIERRALTA, CORDOBA"},
    {"id": 7032,"nombre": "CERRO LAS MUJERES, TIERRALTA, CORDOBA"},
    {"id": 7033,"nombre": "CRUSITO, TIERRALTA, CORDOBA"},
    {"id": 7034,"nombre": "DOS MARIAS, TIERRALTA, CORDOBA"},
    {"id": 7035,"nombre": "EL VENADO, TIERRALTA, CORDOBA"},
    {"id": 7036,"nombre": "EL VIVIANO, TIERRALTA, CORDOBA"},
    {"id": 7037,"nombre": "FLORIDA, TIERRALTA, CORDOBA"},
    {"id": 7038,"nombre": "FRASQUILLO, TIERRALTA, CORDOBA"},
    {"id": 7039,"nombre": "LA GLORIA 1, TIERRALTA, CORDOBA"},
    {"id": 7040,"nombre": "LA OSA, TIERRALTA, CORDOBA"},
    {"id": 7041,"nombre": "LOS MORALES, TIERRALTA, CORDOBA"},
    {"id": 7042,"nombre": "MANTAGORADAL, TIERRALTA, CORDOBA"},
    {"id": 7043,"nombre": "NUEVA GRANADA, TIERRALTA, CORDOBA"},
    {"id": 7044,"nombre": "PALMIRA, TIERRALTA, CORDOBA"},
    {"id": 7045,"nombre": "PUEBLO CEDRO, TIERRALTA, CORDOBA"},
    {"id": 7046,"nombre": "PUEBLO NUEVO, TIERRALTA, CORDOBA"},
    {"id": 7047,"nombre": "SAIZA, TIERRALTA, CORDOBA"},
    {"id": 7048,"nombre": "SAN LORENZO, TIERRALTA, CORDOBA"},
    {"id": 7049,"nombre": "SANTA ISABEL, TIERRALTA, CORDOBA"},
    {"id": 7050,"nombre": "SANTA MARTA, TIERRALTA, CORDOBA"},
    {"id": 7051,"nombre": "SANTAFE RALITO, TIERRALTA, CORDOBA"},
    {"id": 7052,"nombre": "SEVERINERA, TIERRALTA, CORDOBA"},
    {"id": 7053,"nombre": "TIERRALTA, TIERRALTA, CORDOBA"},
    {"id": 7054,"nombre": "TUCURA, TIERRALTA, CORDOBA"},
    {"id": 7055,"nombre": "VILLA PROVIDENCIA, TIERRALTA, CORDOBA"},
    {"id": 7056,"nombre": "VOLADOR, TIERRALTA, CORDOBA"},
    {"id": 7057,"nombre": "COSANZA, TIMANA, HUILA"},
    {"id": 7058,"nombre": "MATEO RICO, TIMANA, HUILA"},
    {"id": 7059,"nombre": "MONTANITA, TIMANA, HUILA"},
    {"id": 7060,"nombre": "NARANJAL, TIMANA, HUILA"},
    {"id": 7061,"nombre": "QUINCHE, TIMANA, HUILA"},
    {"id": 7062,"nombre": "SAN ANTONIO, TIMANA, HUILA"},
    {"id": 7063,"nombre": "SAN ISIDRO, TIMANA, HUILA"},
    {"id": 7064,"nombre": "SANTA FE, TIMANA, HUILA"},
    {"id": 7065,"nombre": "TIMANA, TIMANA, HUILA"},
    {"id": 7066,"nombre": "TOBO, TIMANA, HUILA"},
    {"id": 7067,"nombre": "ALTO SAN JOSE, TIMBIO, CAUCA"},
    {"id": 7068,"nombre": "CAMPOSANO, TIMBIO, CAUCA"},
    {"id": 7069,"nombre": "CINCO DIAS, TIMBIO, CAUCA"},
    {"id": 7070,"nombre": "CUEVITAS O LAS YESCAS, TIMBIO, CAUCA"},
    {"id": 7071,"nombre": "EL HATO, TIMBIO, CAUCA"},
    {"id": 7072,"nombre": "GUAYABAL, TIMBIO, CAUCA"},
    {"id": 7073,"nombre": "LA CABANA, TIMBIO, CAUCA"},
    {"id": 7074,"nombre": "LA CHORRERA, TIMBIO, CAUCA"},
    {"id": 7075,"nombre": "LAS CRUCES, TIMBIO, CAUCA"},
    {"id": 7076,"nombre": "SAMBONI, TIMBIO, CAUCA"},
    {"id": 7077,"nombre": "TIMBIO, TIMBIO, CAUCA"},
    {"id": 7078,"nombre": "URUBAMBA, TIMBIO, CAUCA"},
    {"id": 7079,"nombre": "ANGOSTURA, TIMBIQUI, CAUCA"},
    {"id": 7080,"nombre": "BOCA DE PATIA, TIMBIQUI, CAUCA"},
    {"id": 7081,"nombre": "BRAZO CORTO, TIMBIQUI, CAUCA"},
    {"id": 7082,"nombre": "BUBUEY, TIMBIQUI, CAUCA"},
    {"id": 7083,"nombre": "CABECITAL, TIMBIQUI, CAUCA"},
    {"id": 7084,"nombre": "CAMARONES, TIMBIQUI, CAUCA"},
    {"id": 7085,"nombre": "CHACON, TIMBIQUI, CAUCA"},
    {"id": 7086,"nombre": "CHETE, TIMBIQUI, CAUCA"},
    {"id": 7087,"nombre": "COROZAL, TIMBIQUI, CAUCA"},
    {"id": 7088,"nombre": "COTEJE, TIMBIQUI, CAUCA"},
    {"id": 7089,"nombre": "CUPI, TIMBIQUI, CAUCA"},
    {"id": 7090,"nombre": "EL CHARCO, TIMBIQUI, CAUCA"},
    {"id": 7091,"nombre": "EL CUERVAL, TIMBIQUI, CAUCA"},
    {"id": 7092,"nombre": "EL REALITO, TIMBIQUI, CAUCA"},
    {"id": 7093,"nombre": "GUANGUI, TIMBIQUI, CAUCA"},
    {"id": 7094,"nombre": "INFI, TIMBIQUI, CAUCA"},
    {"id": 7095,"nombre": "LLANO GRANDE, TIMBIQUI, CAUCA"},
    {"id": 7096,"nombre": "PETE, TIMBIQUI, CAUCA"},
    {"id": 7097,"nombre": "PUERTO SAIJA, TIMBIQUI, CAUCA"},
    {"id": 7098,"nombre": "SAN BERNARDO, TIMBIQUI, CAUCA"},
    {"id": 7099,"nombre": "SAN JOSE, TIMBIQUI, CAUCA"},
    {"id": 7100,"nombre": "SAN MIGUEL, TIMBIQUI, CAUCA"},
    {"id": 7101,"nombre": "SANTA MARIA, TIMBIQUI, CAUCA"},
    {"id": 7102,"nombre": "SANTA ROSA DE SAIJA, TIMBIQUI, CAUCA"},
    {"id": 7103,"nombre": "SOLEDAD DE YANTIN, TIMBIQUI, CAUCA"},
    {"id": 7104,"nombre": "TIMBIQUI, TIMBIQUI, CAUCA"},
    {"id": 7105,"nombre": "TINJACA, TINJACA, BOYACA"},
    {"id": 7106,"nombre": "EL NOGAL, TIPACOQUE, BOYACA"},
    {"id": 7107,"nombre": "EL PALMAR, TIPACOQUE, BOYACA"},
    {"id": 7108,"nombre": "LA CARRERA, TIPACOQUE, BOYACA"},
    {"id": 7109,"nombre": "LA VARIANTE, TIPACOQUE, BOYACA"},
    {"id": 7110,"nombre": "TIPACOQUE, TIPACOQUE, BOYACA"},
    {"id": 7111,"nombre": "AGUAS NEGRAS, TIQUISIO, BOLIVAR"},
    {"id": 7112,"nombre": "BOCAS DE SOLIS, TIQUISIO, BOLIVAR"},
    {"id": 7113,"nombre": "COLORADO, TIQUISIO, BOLIVAR"},
    {"id": 7114,"nombre": "DOS BOCAS, TIQUISIO, BOLIVAR"},
    {"id": 7115,"nombre": "EL SUDAN, TIQUISIO, BOLIVAR"},
    {"id": 7116,"nombre": "LA VENTURA, TIQUISIO, BOLIVAR"},
    {"id": 7117,"nombre": "PALMA ESTERAL, TIQUISIO, BOLIVAR"},
    {"id": 7118,"nombre": "PUERTO COCA, TIQUISIO, BOLIVAR"},
    {"id": 7119,"nombre": "QUEBRADA DEL MEDIO, TIQUISIO, BOLIVAR"},
    {"id": 7120,"nombre": "SABANAS DEL FIRME, TIQUISIO, BOLIVAR"},
    {"id": 7121,"nombre": "PUERTO RICO, TIQUISIO, BOLIVAR"},
    {"id": 7122,"nombre": "TIQUISIO NUEVO, TIQUISIO, BOLIVAR"},
    {"id": 7123,"nombre": "LA ALBANIA, TITIRIBI, ANTIOQUIA"},
    {"id": 7124,"nombre": "LA MESETA, TITIRIBI, ANTIOQUIA"},
    {"id": 7125,"nombre": "OTRAMINA, TITIRIBI, ANTIOQUIA"},
    {"id": 7126,"nombre": "SITIO VIEJO, TITIRIBI, ANTIOQUIA"},
    {"id": 7127,"nombre": "TITIRIBI, TITIRIBI, ANTIOQUIA"},
    {"id": 7128,"nombre": "TOCA, TOCA, BOYACA"},
    {"id": 7129,"nombre": "LA COLORADA, TOCAIMA, CUNDINAMARCA"},
    {"id": 7130,"nombre": "LA SALADA, TOCAIMA, CUNDINAMARCA"},
    {"id": 7131,"nombre": "PUBENZA, TOCAIMA, CUNDINAMARCA"},
    {"id": 7132,"nombre": "SAN CARLOS, TOCAIMA, CUNDINAMARCA"},
    {"id": 7133,"nombre": "TOCAIMA, TOCAIMA, CUNDINAMARCA"},
    {"id": 7134,"nombre": "DULCINEA, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7135,"nombre": "LA FUENTE, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7136,"nombre": "PELPAK, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7137,"nombre": "SAN JAVIER, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7138,"nombre": "TOCANCIPA, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7139,"nombre": "TOLIMA, TOCANCIPA, CUNDINAMARCA"},
    {"id": 7140,"nombre": "BERLIN, TONA, SANTANDER"},
    {"id": 7141,"nombre": "CARRIZAL, TONA, SANTANDER"},
    {"id": 7142,"nombre": "GRAMAL, TONA, SANTANDER"},
    {"id": 7143,"nombre": "LA CORCOVA, TONA, SANTANDER"},
    {"id": 7144,"nombre": "PUERTO DEL LLANO, TONA, SANTANDER"},
    {"id": 7145,"nombre": "TONA, TONA, SANTANDER"},
    {"id": 7146,"nombre": "VEGAS, TONA, SANTANDER"},
    {"id": 7147,"nombre": "TOPAGA, TOPAGA, BOYACA"},
    {"id": 7148,"nombre": "VADO CASTRO, TOPAGA, BOYACA"},
    {"id": 7149,"nombre": "EL NARANJAL, TOPAIPI, CUNDINAMARCA"},
    {"id": 7150,"nombre": "MONTEALEGRE, TOPAIPI, CUNDINAMARCA"},
    {"id": 7151,"nombre": "SAN ANTONIO DE AGUILERA, TOPAIPI, CUNDINAMARCA"},
    {"id": 7152,"nombre": "TOPAIPI, TOPAIPI, CUNDINAMARCA"},
    {"id": 7153,"nombre": "EL TABLAZO, TORIBIO, CAUCA"},
    {"id": 7154,"nombre": "LA CRUZ, TORIBIO, CAUCA"},
    {"id": 7155,"nombre": "LA DESPENSA, TORIBIO, CAUCA"},
    {"id": 7156,"nombre": "LOPEZ, TORIBIO, CAUCA"},
    {"id": 7157,"nombre": "NATALA, TORIBIO, CAUCA"},
    {"id": 7158,"nombre": "SAN FRANCISCO, TORIBIO, CAUCA"},
    {"id": 7159,"nombre": "SANTO DOMINGO, TORIBIO, CAUCA"},
    {"id": 7160,"nombre": "TACUEYO, TORIBIO, CAUCA"},
    {"id": 7161,"nombre": "TORIBIO, TORIBIO, CAUCA"},
    {"id": 7162,"nombre": "BOHIO, TORO, VALLE DEL CAUCA"},
    {"id": 7163,"nombre": "SAN ANTONIO, TORO, VALLE DEL CAUCA"},
    {"id": 7164,"nombre": "SAN FRANCISCO, TORO, VALLE DEL CAUCA"},
    {"id": 7165,"nombre": "TORO, TORO, VALLE DEL CAUCA"},
    {"id": 7166,"nombre": "LA PUERTA, TOTA, BOYACA"},
    {"id": 7167,"nombre": "TOTA, TOTA, BOYACA"},
    {"id": 7168,"nombre": "BUENAVISTA, TOTORO, CAUCA"},
    {"id": 7169,"nombre": "EL HATICO, TOTORO, CAUCA"},
    {"id": 7170,"nombre": "GABRIEL LOPEZ, TOTORO, CAUCA"},
    {"id": 7171,"nombre": "JEBALA, TOTORO, CAUCA"},
    {"id": 7172,"nombre": "NOVIRAO, TOTORO, CAUCA"},
    {"id": 7173,"nombre": "PANIQUITA, TOTORO, CAUCA"},
    {"id": 7174,"nombre": "POLINDARA, TOTORO, CAUCA"},
    {"id": 7175,"nombre": "PORTACHUELO, TOTORO, CAUCA"},
    {"id": 7176,"nombre": "TOTORO, TOTORO, CAUCA"},
    {"id": 7177,"nombre": "BELGICA, TRINIDAD, CASANARE"},
    {"id": 7178,"nombre": "BOCAS DEL PAUTO, TRINIDAD, CASANARE"},
    {"id": 7179,"nombre": "CAIMAN, TRINIDAD, CASANARE"},
    {"id": 7180,"nombre": "GUAMAL, TRINIDAD, CASANARE"},
    {"id": 7181,"nombre": "LA LUCHA, TRINIDAD, CASANARE"},
    {"id": 7182,"nombre": "LOS CHOCHOS, TRINIDAD, CASANARE"},
    {"id": 7183,"nombre": "TRINIDAD, TRINIDAD, CASANARE"},
    {"id": 7184,"nombre": "ALTO DE PAEZ, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7185,"nombre": "ANDINAPOLIS, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7186,"nombre": "BAJO CACERES, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7187,"nombre": "CERRO AZUL, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7188,"nombre": "CRISTALES, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7189,"nombre": "DOS QUEBRADAS, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7190,"nombre": "EL TABOR - PUENTE BLANCO, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7191,"nombre": "HUASANO, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7192,"nombre": "LA BOHEMIA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7193,"nombre": "LA CUCHILLA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7194,"nombre": "LA MARINA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7195,"nombre": "LA SOLEDAD, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7196,"nombre": "LA SONORA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7197,"nombre": "PUEBLO NUEVO, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7198,"nombre": "ROBLEDO, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7199,"nombre": "TIERRA GRATA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7200,"nombre": "TRUJILLO, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7201,"nombre": "VENECIA, TRUJILLO, VALLE DEL CAUCA"},
    {"id": 7202,"nombre": "BAJO OSTION, TUBARA, ATLANTICO"},
    {"id": 7203,"nombre": "BURUNATO, TUBARA, ATLANTICO"},
    {"id": 7204,"nombre": "CORRAL DE SAN LUIS, TUBARA, ATLANTICO"},
    {"id": 7205,"nombre": "CUATRO BOCAS, TUBARA, ATLANTICO"},
    {"id": 7206,"nombre": "EL MORRO, TUBARA, ATLANTICO"},
    {"id": 7207,"nombre": "GUAIMARAL, TUBARA, ATLANTICO"},
    {"id": 7208,"nombre": "JUARUCO, TUBARA, ATLANTICO"},
    {"id": 7209,"nombre": "LA HABANA, TUBARA, ATLANTICO"},
    {"id": 7210,"nombre": "SAN JUAN, TUBARA, ATLANTICO"},
    {"id": 7211,"nombre": "TUBARA, TUBARA, ATLANTICO"},
    {"id": 7212,"nombre": "AGUACLARA, TULUA, VALLE DEL CAUCA"},
    {"id": 7213,"nombre": "ALTAFLOR, TULUA, VALLE DEL CAUCA"},
    {"id": 7214,"nombre": "BARRAGAN, TULUA, VALLE DEL CAUCA"},
    {"id": 7215,"nombre": "BOCAS DE TULUA, TULUA, VALLE DEL CAUCA"},
    {"id": 7216,"nombre": "CAMPOALEGRE, TULUA, VALLE DEL CAUCA"},
    {"id": 7217,"nombre": "EL PICACHO, TULUA, VALLE DEL CAUCA"},
    {"id": 7218,"nombre": "EL RETIRO, TULUA, VALLE DEL CAUCA"},
    {"id": 7219,"nombre": "LA DIADEMA, TULUA, VALLE DEL CAUCA"},
    {"id": 7220,"nombre": "LA IBERIA, TULUA, VALLE DEL CAUCA"},
    {"id": 7221,"nombre": "LA MARINA, TULUA, VALLE DEL CAUCA"},
    {"id": 7222,"nombre": "LA MORALIA, TULUA, VALLE DEL CAUCA"},
    {"id": 7223,"nombre": "LA PALMERA, TULUA, VALLE DEL CAUCA"},
    {"id": 7224,"nombre": "LA RIVERA, TULUA, VALLE DEL CAUCA"},
    {"id": 7225,"nombre": "LOS CAIMOS, TULUA, VALLE DEL CAUCA"},
    {"id": 7226,"nombre": "MATEGUADUA, TULUA, VALLE DEL CAUCA"},
    {"id": 7227,"nombre": "MONTELORO, TULUA, VALLE DEL CAUCA"},
    {"id": 7228,"nombre": "NARIÑO, TULUA, VALLE DEL CAUCA"},
    {"id": 7229,"nombre": "PIEDRITAS, TULUA, VALLE DEL CAUCA"},
    {"id": 7230,"nombre": "PUERTO FRAZADAS, TULUA, VALLE DEL CAUCA"},
    {"id": 7231,"nombre": "QUEBRADAGRANDE, TULUA, VALLE DEL CAUCA"},
    {"id": 7232,"nombre": "SAN LORENZO, TULUA, VALLE DEL CAUCA"},
    {"id": 7233,"nombre": "SAN RAFAEL, TULUA, VALLE DEL CAUCA"},
    {"id": 7234,"nombre": "SANTA LUCIA, TULUA, VALLE DEL CAUCA"},
    {"id": 7235,"nombre": "TOCHECITO, TULUA, VALLE DEL CAUCA"},
    {"id": 7236,"nombre": "TRES ESQUINAS, TULUA, VALLE DEL CAUCA"},
    {"id": 7237,"nombre": "TULUA, TULUA, VALLE DEL CAUCA"},
    {"id": 7238,"nombre": "VENUS, TULUA, VALLE DEL CAUCA"},
    {"id": 7239,"nombre": "ALBANIA, TUMACO, NARIÑO"},
    {"id": 7240,"nombre": "ALBERTO LLERAS, TUMACO, NARIÑO"},
    {"id": 7241,"nombre": "ALFONSO LOPEZ, TUMACO, NARIÑO"},
    {"id": 7242,"nombre": "ALMIRANTE PADILLA, TUMACO, NARIÑO"},
    {"id": 7243,"nombre": "ALTO AGUA CLARA, TUMACO, NARIÑO"},
    {"id": 7244,"nombre": "ALTO SAN AGUSTIN, TUMACO, NARIÑO"},
    {"id": 7245,"nombre": "AMBULPI, TUMACO, NARIÑO"},
    {"id": 7246,"nombre": "ANGEL MARIA CALDAS, TUMACO, NARIÑO"},
    {"id": 7247,"nombre": "ARTURO LLORENTE, TUMACO, NARIÑO"},
    {"id": 7248,"nombre": "BAJO CHILVI, TUMACO, NARIÑO"},
    {"id": 7249,"nombre": "BAJO GUABAL, TUMACO, NARIÑO"},
    {"id": 7250,"nombre": "BAJO JAGUA, TUMACO, NARIÑO"},
    {"id": 7251,"nombre": "BAJO ZAPOTAL, TUMACO, NARIÑO"},
    {"id": 7252,"nombre": "BARRIO COLORADO (GUALAJO), TUMACO, NARIÑO"},
    {"id": 7253,"nombre": "BELLAVISTA, TUMACO, NARIÑO"},
    {"id": 7254,"nombre": "BENITEZ, TUMACO, NARIÑO"},
    {"id": 7255,"nombre": "BERNARDINO ORTIZ, TUMACO, NARIÑO"},
    {"id": 7256,"nombre": "BOCA DE CURAY, TUMACO, NARIÑO"},
    {"id": 7257,"nombre": "BOCA DE TULMO, TUMACO, NARIÑO"},
    {"id": 7258,"nombre": "BOCANA NUEVA, TUMACO, NARIÑO"},
    {"id": 7259,"nombre": "BRISAS DEL ACUEDUCTO, TUMACO, NARIÑO"},
    {"id": 7260,"nombre": "BUCHELI, TUMACO, NARIÑO"},
    {"id": 7261,"nombre": "CABO MANGLARES, TUMACO, NARIÑO"},
    {"id": 7262,"nombre": "CACAGUAL, TUMACO, NARIÑO"},
    {"id": 7263,"nombre": "CAJAPI, TUMACO, NARIÑO"},
    {"id": 7264,"nombre": "CAJAPI, TUMACO, NARIÑO"},
    {"id": 7265,"nombre": "CALETA, TUMACO, NARIÑO"},
    {"id": 7266,"nombre": "CALETA VIENTO LIBRE, TUMACO, NARIÑO"},
    {"id": 7267,"nombre": "CANDELILLAS, TUMACO, NARIÑO"},
    {"id": 7268,"nombre": "CANDELO (ROSARIO), TUMACO, NARIÑO"},
    {"id": 7269,"nombre": "CASAS VIEJAS, TUMACO, NARIÑO"},
    {"id": 7270,"nombre": "CAUNAPI, TUMACO, NARIÑO"},
    {"id": 7271,"nombre": "CEIBITO, TUMACO, NARIÑO"},
    {"id": 7272,"nombre": "CHAJAL, TUMACO, NARIÑO"},
    {"id": 7273,"nombre": "CHAPILAR (ROSARIO), TUMACO, NARIÑO"},
    {"id": 7274,"nombre": "CHAPILAR, TUMACO, NARIÑO"},
    {"id": 7275,"nombre": "CHAPUL, TUMACO, NARIÑO"},
    {"id": 7276,"nombre": "CHILVICITO, TUMACO, NARIÑO"},
    {"id": 7277,"nombre": "CHONTAL, TUMACO, NARIÑO"},
    {"id": 7278,"nombre": "COLORADO, TUMACO, NARIÑO"},
    {"id": 7279,"nombre": "CONGAL, TUMACO, NARIÑO"},
    {"id": 7280,"nombre": "CORDOBA, TUMACO, NARIÑO"},
    {"id": 7281,"nombre": "CORRIENTE GRANDE, TUMACO, NARIÑO"},
    {"id": 7282,"nombre": "CRISTOBAL COLON, TUMACO, NARIÑO"},
    {"id": 7283,"nombre": "DAVID ANGULO CABEZAS, TUMACO, NARIÑO"},
    {"id": 7284,"nombre": "DESCOLGADERO, TUMACO, NARIÑO"},
    {"id": 7285,"nombre": "DOS QUEBRADAS, TUMACO, NARIÑO"},
    {"id": 7286,"nombre": "EFRAIN LLORENTE, TUMACO, NARIÑO"},
    {"id": 7287,"nombre": "EL BAJITO, TUMACO, NARIÑO"},
    {"id": 7288,"nombre": "EL CARMEN KILOMETRO 36, TUMACO, NARIÑO"},
    {"id": 7289,"nombre": "EL CARMEN KILOMETRO 63, TUMACO, NARIÑO"},
    {"id": 7290,"nombre": "EL COCO, TUMACO, NARIÑO"},
    {"id": 7291,"nombre": "EL ESTERO (SAN VICENTE), TUMACO, NARIÑO"},
    {"id": 7292,"nombre": "EL GUABAL, TUMACO, NARIÑO"},
    {"id": 7293,"nombre": "EL GUACHAL, TUMACO, NARIÑO"},
    {"id": 7294,"nombre": "EL GUALTAL, TUMACO, NARIÑO"},
    {"id": 7295,"nombre": "EL JAGUA, TUMACO, NARIÑO"},
    {"id": 7296,"nombre": "EL MORRITO, TUMACO, NARIÑO"},
    {"id": 7297,"nombre": "EL PAPAYAL, TUMACO, NARIÑO"},
    {"id": 7298,"nombre": "EL PINDE, TUMACO, NARIÑO"},
    {"id": 7299,"nombre": "EL PITAL, TUMACO, NARIÑO"},
    {"id": 7300,"nombre": "EL RETONO, TUMACO, NARIÑO"},
    {"id": 7301,"nombre": "EL RETORNO, TUMACO, NARIÑO"},
    {"id": 7302,"nombre": "ELADIO POLO RODRIGUEZ, TUMACO, NARIÑO"},
    {"id": 7303,"nombre": "ESPRIELLA, TUMACO, NARIÑO"},
    {"id": 7304,"nombre": "FRANCISCO DE ARIZALA, TUMACO, NARIÑO"},
    {"id": 7305,"nombre": "FRANCISCO JOSE DE CALDAS, TUMACO, NARIÑO"},
    {"id": 7306,"nombre": "GILBERTO ALZATE, TUMACO, NARIÑO"},
    {"id": 7307,"nombre": "GUAYABO, TUMACO, NARIÑO"},
    {"id": 7308,"nombre": "GUILLERMO LEON VALENCIA, TUMACO, NARIÑO"},
    {"id": 7309,"nombre": "HERRERA, TUMACO, NARIÑO"},
    {"id": 7310,"nombre": "IMBILI, TUMACO, NARIÑO"},
    {"id": 7311,"nombre": "IMBILPI DEL CARMEN, TUMACO, NARIÑO"},
    {"id": 7312,"nombre": "INGUAPI DEL CARMEN, TUMACO, NARIÑO"},
    {"id": 7313,"nombre": "INGUAPI EL GUADUAL, TUMACO, NARIÑO"},
    {"id": 7314,"nombre": "JORGE ELIECER GAITAN, TUMACO, NARIÑO"},
    {"id": 7315,"nombre": "JORGE H. LEAL, TUMACO, NARIÑO"},
    {"id": 7316,"nombre": "JOSE AURELIO LLORENTE, TUMACO, NARIÑO"},
    {"id": 7317,"nombre": "JOSE LLORENTE CORTES, TUMACO, NARIÑO"},
    {"id": 7318,"nombre": "JUAN DE LA CRUZ, TUMACO, NARIÑO"},
    {"id": 7319,"nombre": "JUAN DOMINGO, TUMACO, NARIÑO"},
    {"id": 7320,"nombre": "JULIO PLAZA, TUMACO, NARIÑO"},
    {"id": 7321,"nombre": "KILOMETRO 28, TUMACO, NARIÑO"},
    {"id": 7322,"nombre": "KILOMETRO 35, TUMACO, NARIÑO"},
    {"id": 7323,"nombre": "KILOMETRO 41, TUMACO, NARIÑO"},
    {"id": 7324,"nombre": "KILOMETRO 58, TUMACO, NARIÑO"},
    {"id": 7325,"nombre": "KILOMETRO 92, TUMACO, NARIÑO"},
    {"id": 7326,"nombre": "LA ADUANA, TUMACO, NARIÑO"},
    {"id": 7327,"nombre": "LA BARCA, TUMACO, NARIÑO"},
    {"id": 7328,"nombre": "LA BRAVA - RIO GUANAPI, TUMACO, NARIÑO"},
    {"id": 7329,"nombre": "LA CHORRERA, TUMACO, NARIÑO"},
    {"id": 7330,"nombre": "LA GUAYACANA, TUMACO, NARIÑO"},
    {"id": 7331,"nombre": "LA HONDA, TUMACO, NARIÑO"},
    {"id": 7332,"nombre": "LA LOMA RIO MIRA, TUMACO, NARIÑO"},
    {"id": 7333,"nombre": "LA NUEVA UNION, TUMACO, NARIÑO"},
    {"id": 7334,"nombre": "LA PINUELA RIO MIRA, TUMACO, NARIÑO"},
    {"id": 7335,"nombre": "LA VEGA, TUMACO, NARIÑO"},
    {"id": 7336,"nombre": "LAS CARGAS, TUMACO, NARIÑO"},
    {"id": 7337,"nombre": "LAS MERCEDES, TUMACO, NARIÑO"},
    {"id": 7338,"nombre": "LAS SIRENAS, TUMACO, NARIÑO"},
    {"id": 7339,"nombre": "LLANAJE, TUMACO, NARIÑO"},
    {"id": 7340,"nombre": "LLORENTE, TUMACO, NARIÑO"},
    {"id": 7341,"nombre": "LOS COIMES, TUMACO, NARIÑO"},
    {"id": 7342,"nombre": "MAJAGUA, TUMACO, NARIÑO"},
    {"id": 7343,"nombre": "MAJAGUAL, TUMACO, NARIÑO"},
    {"id": 7344,"nombre": "MASCARAY, TUMACO, NARIÑO"},
    {"id": 7345,"nombre": "MATAJE, TUMACO, NARIÑO"},
    {"id": 7346,"nombre": "MILAGROS, TUMACO, NARIÑO"},
    {"id": 7347,"nombre": "MIRAPALMA, TUMACO, NARIÑO"},
    {"id": 7348,"nombre": "MONTE ALTO FRONTERA, TUMACO, NARIÑO"},
    {"id": 7349,"nombre": "NUEVA BRISA, TUMACO, NARIÑO"},
    {"id": 7350,"nombre": "PABLO REINEL ERAZO, TUMACO, NARIÑO"},
    {"id": 7351,"nombre": "PACIFICO, TUMACO, NARIÑO"},
    {"id": 7352,"nombre": "PACORA, TUMACO, NARIÑO"},
    {"id": 7353,"nombre": "PALAMBI, TUMACO, NARIÑO"},
    {"id": 7354,"nombre": "PALAY, TUMACO, NARIÑO"},
    {"id": 7355,"nombre": "PATRICIO JIMENEZ, TUMACO, NARIÑO"},
    {"id": 7356,"nombre": "PEDRO VIEIRA, TUMACO, NARIÑO"},
    {"id": 7357,"nombre": "PENA COLORADA, TUMACO, NARIÑO"},
    {"id": 7358,"nombre": "PICUAMBI, TUMACO, NARIÑO"},
    {"id": 7359,"nombre": "PINAL SALADO, TUMACO, NARIÑO"},
    {"id": 7360,"nombre": "PINDALES, TUMACO, NARIÑO"},
    {"id": 7361,"nombre": "PITAL PIRAGUA, TUMACO, NARIÑO"},
    {"id": 7362,"nombre": "PLAYA DEL CABALLO, TUMACO, NARIÑO"},
    {"id": 7363,"nombre": "PLAYA DEL MIRA, TUMACO, NARIÑO"},
    {"id": 7364,"nombre": "PLAYON, TUMACO, NARIÑO"},
    {"id": 7365,"nombre": "PROGRESO, TUMACO, NARIÑO"},
    {"id": 7366,"nombre": "PUEBLO NUEVO, TUMACO, NARIÑO"},
    {"id": 7367,"nombre": "PULGANDE, TUMACO, NARIÑO"},
    {"id": 7368,"nombre": "PUNTO TIBIO, TUMACO, NARIÑO"},
    {"id": 7369,"nombre": "RESURRECCION, TUMACO, NARIÑO"},
    {"id": 7370,"nombre": "ROBERTO GUERRERO, TUMACO, NARIÑO"},
    {"id": 7371,"nombre": "ROMULO DIONISIO ORTIZ, TUMACO, NARIÑO"},
    {"id": 7372,"nombre": "ROSA ZARATE, TUMACO, NARIÑO"},
    {"id": 7373,"nombre": "ROSARIO, TUMACO, NARIÑO"},
    {"id": 7374,"nombre": "SALISVI, TUMACO, NARIÑO"},
    {"id": 7375,"nombre": "SAMUEL ALBERTO ESCRUCERIA, TUMACO, NARIÑO"},
    {"id": 7376,"nombre": "SAN ANDRES BOCA, TUMACO, NARIÑO"},
    {"id": 7377,"nombre": "SAN ANTONIO DE CURAY, TUMACO, NARIÑO"},
    {"id": 7378,"nombre": "SAN ANTONIO, TUMACO, NARIÑO"},
    {"id": 7379,"nombre": "SAN IGNACIO, TUMACO, NARIÑO"},
    {"id": 7380,"nombre": "SAN ISIDRO RIO MIRA, TUMACO, NARIÑO"},
    {"id": 7381,"nombre": "SAN ISIDRO, TUMACO, NARIÑO"},
    {"id": 7382,"nombre": "SAN JACINTO, TUMACO, NARIÑO"},
    {"id": 7383,"nombre": "SAN LORENZO, TUMACO, NARIÑO"},
    {"id": 7384,"nombre": "SAN LUIS ROBLES, TUMACO, NARIÑO"},
    {"id": 7385,"nombre": "SAN PEDRO DEL VINO, TUMACO, NARIÑO"},
    {"id": 7386,"nombre": "SAN SEBASTIAN DE BELALCAZAR, TUMACO, NARIÑO"},
    {"id": 7387,"nombre": "SAN SEBASTIAN, TUMACO, NARIÑO"},
    {"id": 7388,"nombre": "SANTA MARIA ROSARIO, TUMACO, NARIÑO"},
    {"id": 7389,"nombre": "SANTA ROSA, TUMACO, NARIÑO"},
    {"id": 7390,"nombre": "SANTANDER, TUMACO, NARIÑO"},
    {"id": 7391,"nombre": "SANTO DOMINGO, TUMACO, NARIÑO"},
    {"id": 7392,"nombre": "SEGUMBITA, TUMACO, NARIÑO"},
    {"id": 7393,"nombre": "SERGIO PEREZ, TUMACO, NARIÑO"},
    {"id": 7394,"nombre": "SOLEDAD, TUMACO, NARIÑO"},
    {"id": 7395,"nombre": "STELLA MARQUEZ, TUMACO, NARIÑO"},
    {"id": 7396,"nombre": "SUCRE, TUMACO, NARIÑO"},
    {"id": 7397,"nombre": "TABACAL, TUMACO, NARIÑO"},
    {"id": 7398,"nombre": "TABLON DULCE - LA PAMPA, TUMACO, NARIÑO"},
    {"id": 7399,"nombre": "TAMBILLO, TUMACO, NARIÑO"},
    {"id": 7400,"nombre": "TANGARREAL DEL MIRA, TUMACO, NARIÑO"},
    {"id": 7401,"nombre": "TERAN, TUMACO, NARIÑO"},
    {"id": 7402,"nombre": "TESTERIA, TUMACO, NARIÑO"},
    {"id": 7403,"nombre": "TUMACO, TUMACO, NARIÑO"},
    {"id": 7404,"nombre": "URIBE URIBE (CHILVI), TUMACO, NARIÑO"},
    {"id": 7405,"nombre": "VAQUERIA, TUMACO, NARIÑO"},
    {"id": 7406,"nombre": "VARIANTE, TUMACO, NARIÑO"},
    {"id": 7407,"nombre": "VILLA RICA, TUMACO, NARIÑO"},
    {"id": 7408,"nombre": "VILLA SAN JUAN, TUMACO, NARIÑO"},
    {"id": 7409,"nombre": "VUELTA CANDELILLAS, TUMACO, NARIÑO"},
    {"id": 7410,"nombre": "VUELTA DEL GALLO, TUMACO, NARIÑO"},
    {"id": 7411,"nombre": "VUELTA LARGA, TUMACO, NARIÑO"},
    {"id": 7412,"nombre": "PUENTE BOYACA, TUNJA, BOYACA"},
    {"id": 7413,"nombre": "TUNJA, TUNJA, BOYACA"},
    {"id": 7414,"nombre": "TUNUNGUA, TUNUNGUA, BOYACA"},
    {"id": 7415,"nombre": "ALBAN, TUQUERRES, NARIÑO"},
    {"id": 7416,"nombre": "CUATRO ESQUINAS, TUQUERRES, NARIÑO"},
    {"id": 7417,"nombre": "GUAYAQUITA, TUQUERRES, NARIÑO"},
    {"id": 7418,"nombre": "LA FLOR, TUQUERRES, NARIÑO"},
    {"id": 7419,"nombre": "LOS ARRAYANES, TUQUERRES, NARIÑO"},
    {"id": 7420,"nombre": "OLAYA, TUQUERRES, NARIÑO"},
    {"id": 7421,"nombre": "PINZON, TUQUERRES, NARIÑO"},
    {"id": 7422,"nombre": "QUINONES, TUQUERRES, NARIÑO"},
    {"id": 7423,"nombre": "RANCHO GRANDE, TUQUERRES, NARIÑO"},
    {"id": 7424,"nombre": "SAN FRANCISCO, TUQUERRES, NARIÑO"},
    {"id": 7425,"nombre": "SANTANDER, TUQUERRES, NARIÑO"},
    {"id": 7426,"nombre": "TUQUERRES, TUQUERRES, NARIÑO"},
    {"id": 7427,"nombre": "TUTACHAG BAJO, TUQUERRES, NARIÑO"},
    {"id": 7428,"nombre": "YASCUAL, TUQUERRES, NARIÑO"},
    {"id": 7429,"nombre": "AGUAS PRIETAS, TURBACO, BOLIVAR"},
    {"id": 7430,"nombre": "CAMPANA, TURBACO, BOLIVAR"},
    {"id": 7431,"nombre": "CANAVERAL, TURBACO, BOLIVAR"},
    {"id": 7432,"nombre": "PUENTE HONDA, TURBACO, BOLIVAR"},
    {"id": 7433,"nombre": "SAN JOSE DE CHIQUITO, TURBACO, BOLIVAR"},
    {"id": 7434,"nombre": "TURBACO, TURBACO, BOLIVAR"},
    {"id": 7435,"nombre": "BALLESTAS, TURBANA, BOLIVAR"},
    {"id": 7436,"nombre": "PUEBLO NUEVO, TURBANA, BOLIVAR"},
    {"id": 7437,"nombre": "TURBANA, TURBANA, BOLIVAR"},
    {"id": 7438,"nombre": "ALTO DE MULATOS, TURBO, ANTIOQUIA"},
    {"id": 7439,"nombre": "BLANQUICET, TURBO, ANTIOQUIA"},
    {"id": 7440,"nombre": "BOBAL, TURBO, ANTIOQUIA"},
    {"id": 7441,"nombre": "BOCAS DEL ATRATO, TURBO, ANTIOQUIA"},
    {"id": 7442,"nombre": "CASABLANCA, TURBO, ANTIOQUIA"},
    {"id": 7443,"nombre": "CURRULAO, TURBO, ANTIOQUIA"},
    {"id": 7444,"nombre": "EL DOS, TURBO, ANTIOQUIA"},
    {"id": 7445,"nombre": "EL TRES, TURBO, ANTIOQUIA"},
    {"id": 7446,"nombre": "LOMAS AISLADAS, TURBO, ANTIOQUIA"},
    {"id": 7447,"nombre": "MACONDO, TURBO, ANTIOQUIA"},
    {"id": 7448,"nombre": "NUEVA COLONIA, TURBO, ANTIOQUIA"},
    {"id": 7449,"nombre": "NUEVO ANTIOQUIA, TURBO, ANTIOQUIA"},
    {"id": 7450,"nombre": "NUEVO ORIENTE, TURBO, ANTIOQUIA"},
    {"id": 7451,"nombre": "PUEBLO BELLO, TURBO, ANTIOQUIA"},
    {"id": 7452,"nombre": "PUERTO RICO, TURBO, ANTIOQUIA"},
    {"id": 7453,"nombre": "RIOGRANDE, TURBO, ANTIOQUIA"},
    {"id": 7454,"nombre": "SAN JOSE DE MULATOS, TURBO, ANTIOQUIA"},
    {"id": 7455,"nombre": "SAN VICENTE DEL CONGO, TURBO, ANTIOQUIA"},
    {"id": 7456,"nombre": "TIE, TURBO, ANTIOQUIA"},
    {"id": 7457,"nombre": "TURBO, TURBO, ANTIOQUIA"},
    {"id": 7458,"nombre": "TURMEQUE, TURMEQUE, BOYACA"},
    {"id": 7459,"nombre": "BOSIGA SUR, TUTA, BOYACA"},
    {"id": 7460,"nombre": "EL CRUCE, TUTA, BOYACA"},
    {"id": 7461,"nombre": "SIDERURGICA (LA MAGUNCIA), TUTA, BOYACA"},
    {"id": 7462,"nombre": "TUTA, TUTA, BOYACA"},
    {"id": 7463,"nombre": "PARAMO, TUTAZA, BOYACA"},
    {"id": 7464,"nombre": "TUTAZA, TUTAZA, BOYACA"},
    {"id": 7465,"nombre": "VILLA HERMOSA, TUTAZA, BOYACA"},
    {"id": 7466,"nombre": "ALGODONES, UBALA, CUNDINAMARCA"},
    {"id": 7467,"nombre": "EL ENGANO, UBALA, CUNDINAMARCA"},
    {"id": 7468,"nombre": "LA PLAYA MUNDO NUEVO, UBALA, CUNDINAMARCA"},
    {"id": 7469,"nombre": "LAGUNA AZUL, UBALA, CUNDINAMARCA"},
    {"id": 7470,"nombre": "MAMBITA, UBALA, CUNDINAMARCA"},
    {"id": 7471,"nombre": "SAN PEDRO JAGUA, UBALA, CUNDINAMARCA"},
    {"id": 7472,"nombre": "SANTA ROSA, UBALA, CUNDINAMARCA"},
    {"id": 7473,"nombre": "SOYA, UBALA, CUNDINAMARCA"},
    {"id": 7474,"nombre": "UBALA, UBALA, CUNDINAMARCA"},
    {"id": 7475,"nombre": "PUEBLO VIEJO, UBAQUE, CUNDINAMARCA"},
    {"id": 7476,"nombre": "SABANILLA, UBAQUE, CUNDINAMARCA"},
    {"id": 7477,"nombre": "SAN ROQUE, UBAQUE, CUNDINAMARCA"},
    {"id": 7478,"nombre": "SANTA ANA, UBAQUE, CUNDINAMARCA"},
    {"id": 7479,"nombre": "UBAQUE, UBAQUE, CUNDINAMARCA"},
    {"id": 7480,"nombre": "CHAPINERO, ULLOA, VALLE DEL CAUCA"},
    {"id": 7481,"nombre": "EL BRILLANTE, ULLOA, VALLE DEL CAUCA"},
    {"id": 7482,"nombre": "EL PLACER, ULLOA, VALLE DEL CAUCA"},
    {"id": 7483,"nombre": "MONTEZUMA, ULLOA, VALLE DEL CAUCA"},
    {"id": 7484,"nombre": "ULLOA, ULLOA, VALLE DEL CAUCA"},
    {"id": 7485,"nombre": "UMBITA, UMBITA, BOYACA"},
    {"id": 7486,"nombre": "EL RAMAL, UNE, CUNDINAMARCA"},
    {"id": 7487,"nombre": "UNE, UNE, CUNDINAMARCA"},
    {"id": 7488,"nombre": "BALBOA, UNGUIA, CHOCO"},
    {"id": 7489,"nombre": "BETECITO, UNGUIA, CHOCO"},
    {"id": 7490,"nombre": "GILGAL, UNGUIA, CHOCO"},
    {"id": 7491,"nombre": "MANTUNTUGO, UNGUIA, CHOCO"},
    {"id": 7492,"nombre": "MARRIAGA, UNGUIA, CHOCO"},
    {"id": 7493,"nombre": "SANTA MARIA DEL DARIEN, UNGUIA, CHOCO"},
    {"id": 7494,"nombre": "TANELA, UNGUIA, CHOCO"},
    {"id": 7495,"nombre": "TITUMATE, UNGUIA, CHOCO"},
    {"id": 7496,"nombre": "TUMARADO, UNGUIA, CHOCO"},
    {"id": 7497,"nombre": "UNGUIA, UNGUIA, CHOCO"},
    {"id": 7498,"nombre": "EL PLAN DE RASPADURA, UNION PANAMERICANA, CHOCO"},
    {"id": 7499,"nombre": "LA YE, UNION PANAMERICANA, CHOCO"},
    {"id": 7500,"nombre": "SAN PABLO ADENTRO, UNION PANAMERICANA, CHOCO"},
    {"id": 7501,"nombre": "SAN RAFAEL DEL DOS, UNION PANAMERICANA, CHOCO"},
    {"id": 7502,"nombre": "ANIMAS, UNION PANAMERICANA, CHOCO"},
    {"id": 7503,"nombre": "AMBALEMA, URAMITA, ANTIOQUIA"},
    {"id": 7504,"nombre": "EL PITAL, URAMITA, ANTIOQUIA"},
    {"id": 7505,"nombre": "FUEMIA, URAMITA, ANTIOQUIA"},
    {"id": 7506,"nombre": "URAMITA, URAMITA, ANTIOQUIA"},
    {"id": 7507,"nombre": "EL DIVISO, URIBE, META"},
    {"id": 7508,"nombre": "LA JULIA, URIBE, META"},
    {"id": 7509,"nombre": "URIBE, URIBE, META"},
    {"id": 7510,"nombre": "BAHIA HONDA, URIBIA, LA GUAJIRA"},
    {"id": 7511,"nombre": "CABO DE LA VELA, URIBIA, LA GUAJIRA"},
    {"id": 7512,"nombre": "CARRIZAL, URIBIA, LA GUAJIRA"},
    {"id": 7513,"nombre": "CASTILLETES, URIBIA, LA GUAJIRA"},
    {"id": 7514,"nombre": "CASUSO, URIBIA, LA GUAJIRA"},
    {"id": 7515,"nombre": "EL CARDON, URIBIA, LA GUAJIRA"},
    {"id": 7516,"nombre": "GUARERPA, URIBIA, LA GUAJIRA"},
    {"id": 7517,"nombre": "GUIMPESI, URIBIA, LA GUAJIRA"},
    {"id": 7518,"nombre": "IRRAIPA, URIBIA, LA GUAJIRA"},
    {"id": 7519,"nombre": "JARARA, URIBIA, LA GUAJIRA"},
    {"id": 7520,"nombre": "JONJONCITO, URIBIA, LA GUAJIRA"},
    {"id": 7521,"nombre": "NAZARETH, URIBIA, LA GUAJIRA"},
    {"id": 7522,"nombre": "PORCHINA, URIBIA, LA GUAJIRA"},
    {"id": 7523,"nombre": "PUERTO ESTRELLA, URIBIA, LA GUAJIRA"},
    {"id": 7524,"nombre": "PUERTO LOPEZ, URIBIA, LA GUAJIRA"},
    {"id": 7525,"nombre": "RANCHO GRANDE, URIBIA, LA GUAJIRA"},
    {"id": 7526,"nombre": "TAGUAIRA, URIBIA, LA GUAJIRA"},
    {"id": 7527,"nombre": "TAPARAJIN, URIBIA, LA GUAJIRA"},
    {"id": 7528,"nombre": "TAROA, URIBIA, LA GUAJIRA"},
    {"id": 7529,"nombre": "URIBIA, URIBIA, LA GUAJIRA"},
    {"id": 7530,"nombre": "LA ENCARNACION, URRAO, ANTIOQUIA"},
    {"id": 7531,"nombre": "PUERTO PALACIOS, URRAO, ANTIOQUIA"},
    {"id": 7532,"nombre": "SANTA ISABEL, URRAO, ANTIOQUIA"},
    {"id": 7533,"nombre": "URRAO, URRAO, ANTIOQUIA"},
    {"id": 7534,"nombre": "SIERRA MONTANA, URUMITA, LA GUAJIRA"},
    {"id": 7535,"nombre": "URUMITA, URUMITA, LA GUAJIRA"},
    {"id": 7536,"nombre": "LURIZA, USIACURI, ATLANTICO"},
    {"id": 7537,"nombre": "USIACURI, USIACURI, ATLANTICO"},
    {"id": 7538,"nombre": "UTICA, UTICA, CUNDINAMARCA"},
    {"id": 7539,"nombre": "CANDEBA, VALDIVIA, ANTIOQUIA"},
    {"id": 7540,"nombre": "EL NEVADO, VALDIVIA, ANTIOQUIA"},
    {"id": 7541,"nombre": "EL QUINCE, VALDIVIA, ANTIOQUIA"},
    {"id": 7542,"nombre": "FILO DE HAMBRE, VALDIVIA, ANTIOQUIA"},
    {"id": 7543,"nombre": "LA HABANA, VALDIVIA, ANTIOQUIA"},
    {"id": 7544,"nombre": "LA INDIA, VALDIVIA, ANTIOQUIA"},
    {"id": 7545,"nombre": "MATADERO, VALDIVIA, ANTIOQUIA"},
    {"id": 7546,"nombre": "PUERTO VALDIVIA, VALDIVIA, ANTIOQUIA"},
    {"id": 7547,"nombre": "RAUDAL, VALDIVIA, ANTIOQUIA"},
    {"id": 7548,"nombre": "VALDIVIA, VALDIVIA, ANTIOQUIA"},
    {"id": 7549,"nombre": "BUENAVISTA, VALENCIA, CORDOBA"},
    {"id": 7550,"nombre": "COCUELO ARRIBA, VALENCIA, CORDOBA"},
    {"id": 7551,"nombre": "EL REPOSO, VALENCIA, CORDOBA"},
    {"id": 7552,"nombre": "GUADUAL CENTRAL, VALENCIA, CORDOBA"},
    {"id": 7553,"nombre": "JARAGUAY, VALENCIA, CORDOBA"},
    {"id": 7554,"nombre": "LAS PALMAS, VALENCIA, CORDOBA"},
    {"id": 7555,"nombre": "LAS PIEDRAS, VALENCIA, CORDOBA"},
    {"id": 7556,"nombre": "LOMA LARGA, VALENCIA, CORDOBA"},
    {"id": 7557,"nombre": "MANZANARES, VALENCIA, CORDOBA"},
    {"id": 7558,"nombre": "MATA DE MAIZ, VALENCIA, CORDOBA"},
    {"id": 7559,"nombre": "MIELES, VALENCIA, CORDOBA"},
    {"id": 7560,"nombre": "RIO NUEVO, VALENCIA, CORDOBA"},
    {"id": 7561,"nombre": "SAN RAFAEL, VALENCIA, CORDOBA"},
    {"id": 7562,"nombre": "SANTO DOMINGO, VALENCIA, CORDOBA"},
    {"id": 7563,"nombre": "VALENCIA, VALENCIA, CORDOBA"},
    {"id": 7564,"nombre": "VIJAGUAL, VALENCIA, CORDOBA"},
    {"id": 7565,"nombre": "VILLANUEVA, VALENCIA, CORDOBA"},
    {"id": 7566,"nombre": "EL CERRO, VALLE DE SAN JOSE, SANTANDER"},
    {"id": 7567,"nombre": "EL MORRO, VALLE DE SAN JOSE, SANTANDER"},
    {"id": 7568,"nombre": "LLANO HONDO, VALLE DE SAN JOSE, SANTANDER"},
    {"id": 7569,"nombre": "SANTA TERESA, VALLE DE SAN JOSE, SANTANDER"},
    {"id": 7570,"nombre": "VALLE DE SAN JOSE, VALLE DE SAN JOSE, SANTANDER"},
    {"id": 7571,"nombre": "BUENAVISTA BAJA, VALLE DE SAN JUAN, TOLIMA"},
    {"id": 7572,"nombre": "LA MANGA, VALLE DE SAN JUAN, TOLIMA"},
    {"id": 7573,"nombre": "NEME, VALLE DE SAN JUAN, TOLIMA"},
    {"id": 7574,"nombre": "VALLE DE SAN JUAN, VALLE DE SAN JUAN, TOLIMA"},
    {"id": 7575,"nombre": "VALLECITO, VALLE DE SAN JUAN, TOLIMA"},
    {"id": 7576,"nombre": "ALTO PALMIRA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7577,"nombre": "BRISAS DEL PALMAR, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7578,"nombre": "EL CAIRO, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7579,"nombre": "EL PLACER, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7580,"nombre": "EL TIGRE, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7581,"nombre": "EL VENADO, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7582,"nombre": "GUADUALITO, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7583,"nombre": "JORDAN DE GUISIA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7584,"nombre": "LA CONCORDIA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7585,"nombre": "LA ESMERALDA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7586,"nombre": "LORO UNO, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7587,"nombre": "MIRAVALLE, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7588,"nombre": "NUEVA PALESTINA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7589,"nombre": "SAN ANDRES, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7590,"nombre": "SAN ANTONIO DEL GUAMUEZ, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7591,"nombre": "SAN ISIDRO, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7592,"nombre": "SANTA ROSA DE SUCUMBIOS, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7593,"nombre": "LA HORMIGA, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7594,"nombre": "VILLADUARTE, VALLE DEL GUAMUEZ, PUTUMAYO"},
    {"id": 7595,"nombre": "AGUAS BLANCAS, VALLEDUPAR, CESAR"},
    {"id": 7596,"nombre": "ALTO DE LA VUELTA, VALLEDUPAR, CESAR"},
    {"id": 7597,"nombre": "ATANQUEZ, VALLEDUPAR, CESAR"},
    {"id": 7598,"nombre": "BADILLO, VALLEDUPAR, CESAR"},
    {"id": 7599,"nombre": "CAMPERUCHO, VALLEDUPAR, CESAR"},
    {"id": 7600,"nombre": "CARACOLI, VALLEDUPAR, CESAR"},
    {"id": 7601,"nombre": "CHEMESQUEMENA, VALLEDUPAR, CESAR"},
    {"id": 7602,"nombre": "EL JAVO, VALLEDUPAR, CESAR"},
    {"id": 7603,"nombre": "EL PERRO, VALLEDUPAR, CESAR"},
    {"id": 7604,"nombre": "GUACOCHE, VALLEDUPAR, CESAR"},
    {"id": 7605,"nombre": "GUACOCHITO, VALLEDUPAR, CESAR"},
    {"id": 7606,"nombre": "GUAIMARAL, VALLEDUPAR, CESAR"},
    {"id": 7607,"nombre": "GUATAPURI, VALLEDUPAR, CESAR"},
    {"id": 7608,"nombre": "GUATAPURI, VALLEDUPAR, CESAR"},
    {"id": 7609,"nombre": "HATICOS II, VALLEDUPAR, CESAR"},
    {"id": 7610,"nombre": "LA MESA, VALLEDUPAR, CESAR"},
    {"id": 7611,"nombre": "LAS MINAS, VALLEDUPAR, CESAR"},
    {"id": 7612,"nombre": "LAS RAICES, VALLEDUPAR, CESAR"},
    {"id": 7613,"nombre": "LOS CALABAZOS, VALLEDUPAR, CESAR"},
    {"id": 7614,"nombre": "LOS CORAZONES, VALLEDUPAR, CESAR"},
    {"id": 7615,"nombre": "LOS HATICOS, VALLEDUPAR, CESAR"},
    {"id": 7616,"nombre": "LOS VENADOS, VALLEDUPAR, CESAR"},
    {"id": 7617,"nombre": "MARIANGOLA, VALLEDUPAR, CESAR"},
    {"id": 7618,"nombre": "PATILLAL, VALLEDUPAR, CESAR"},
    {"id": 7619,"nombre": "POTRERITO, VALLEDUPAR, CESAR"},
    {"id": 7620,"nombre": "RANCHO LA GOYA, VALLEDUPAR, CESAR"},
    {"id": 7621,"nombre": "RIO SECO, VALLEDUPAR, CESAR"},
    {"id": 7622,"nombre": "SAN MARTIN, VALLEDUPAR, CESAR"},
    {"id": 7623,"nombre": "VALENCIA JESUS, VALLEDUPAR, CESAR"},
    {"id": 7624,"nombre": "VALLEDUPAR, VALLEDUPAR, CESAR"},
    {"id": 7625,"nombre": "VEGA ARRIBA, VALLEDUPAR, CESAR"},
    {"id": 7626,"nombre": "VERACRUZ, VALLEDUPAR, CESAR"},
    {"id": 7627,"nombre": "VILLA GERMANIA, VALLEDUPAR, CESAR"},
    {"id": 7628,"nombre": "EL TIGRE, VEGACHI, ANTIOQUIA"},
    {"id": 7629,"nombre": "LA CHINA, VEGACHI, ANTIOQUIA"},
    {"id": 7630,"nombre": "VEGACHI, VEGACHI, ANTIOQUIA"},
    {"id": 7631,"nombre": "ALTO JORDAN, VELEZ, SANTANDER"},
    {"id": 7632,"nombre": "EL LIMON, VELEZ, SANTANDER"},
    {"id": 7633,"nombre": "GUALILO, VELEZ, SANTANDER"},
    {"id": 7634,"nombre": "LLANADAS, VELEZ, SANTANDER"},
    {"id": 7635,"nombre": "LOMALTA, VELEZ, SANTANDER"},
    {"id": 7636,"nombre": "LOS EJIDOS, VELEZ, SANTANDER"},
    {"id": 7637,"nombre": "LOS GUAYABOS, VELEZ, SANTANDER"},
    {"id": 7638,"nombre": "PALO BLANCO, VELEZ, SANTANDER"},
    {"id": 7639,"nombre": "ROPERO, VELEZ, SANTANDER"},
    {"id": 7640,"nombre": "SAN PABLO, VELEZ, SANTANDER"},
    {"id": 7641,"nombre": "VELEZ, VELEZ, SANTANDER"},
    {"id": 7642,"nombre": "JUNIN, VENADILLO, TOLIMA"},
    {"id": 7643,"nombre": "LA SIERRITA, VENADILLO, TOLIMA"},
    {"id": 7644,"nombre": "MALABAR, VENADILLO, TOLIMA"},
    {"id": 7645,"nombre": "PALMA ROSA, VENADILLO, TOLIMA"},
    {"id": 7646,"nombre": "VENADILLO, VENADILLO, TOLIMA"},
    {"id": 7647,"nombre": "VILE, VENADILLO, TOLIMA"},
    {"id": 7648,"nombre": "ESTANCIA GRANDE, VENTAQUEMADA, BOYACA"},
    {"id": 7649,"nombre": "MONTOYA, VENTAQUEMADA, BOYACA"},
    {"id": 7650,"nombre": "PARROQUIA VIEJA, VENTAQUEMADA, BOYACA"},
    {"id": 7651,"nombre": "PUENTE BOYACA, VENTAQUEMADA, BOYACA"},
    {"id": 7652,"nombre": "PUENTE DE PIEDRA, VENTAQUEMADA, BOYACA"},
    {"id": 7653,"nombre": "TIERRA NEGRA, VENTAQUEMADA, BOYACA"},
    {"id": 7654,"nombre": "VENTAQUEMADA, VENTAQUEMADA, BOYACA"},
    {"id": 7655,"nombre": "CERINZA, VERGARA, CUNDINAMARCA"},
    {"id": 7656,"nombre": "CHONTE GRANDE, VERGARA, CUNDINAMARCA"},
    {"id": 7657,"nombre": "CORCEGA, VERGARA, CUNDINAMARCA"},
    {"id": 7658,"nombre": "GUACAMAYAS, VERGARA, CUNDINAMARCA"},
    {"id": 7659,"nombre": "LLANO GRANDE, VERGARA, CUNDINAMARCA"},
    {"id": 7660,"nombre": "VERGARA, VERGARA, CUNDINAMARCA"},
    {"id": 7661,"nombre": "VILLA OLARTE, VERGARA, CUNDINAMARCA"},
    {"id": 7662,"nombre": "CAMPOALEGRE, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7663,"nombre": "EL BALSAL, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7664,"nombre": "EL VERGEL, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7665,"nombre": "GUAIRA ABAJO, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7666,"nombre": "LA BALSORA, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7667,"nombre": "LA FLORIDA, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7668,"nombre": "LA GUAVIA, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7669,"nombre": "PINARES, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7670,"nombre": "PUENTETIERRA, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7671,"nombre": "PUERTO NUEVO, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7672,"nombre": "VERSALLES, VERSALLES, VALLE DEL CAUCA"},
    {"id": 7673,"nombre": "VETAS, VETAS, SANTANDER"},
    {"id": 7674,"nombre": "VIANI, VIANI, CUNDINAMARCA"},
    {"id": 7675,"nombre": "CANAVERAL, VICTORIA, CALDAS"},
    {"id": 7676,"nombre": "EL LLANO, VICTORIA, CALDAS"},
    {"id": 7677,"nombre": "FIERRITOS, VICTORIA, CALDAS"},
    {"id": 7678,"nombre": "ISAZA, VICTORIA, CALDAS"},
    {"id": 7679,"nombre": "LA FE, VICTORIA, CALDAS"},
    {"id": 7680,"nombre": "LA PRADERA, VICTORIA, CALDAS"},
    {"id": 7681,"nombre": "VICTORIA, VICTORIA, CALDAS"},
    {"id": 7682,"nombre": "BAJO MURRI, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7683,"nombre": "BUCHADO, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7684,"nombre": "NENDO, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7685,"nombre": "NEUDO, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7686,"nombre": "PALO BLANCO, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7687,"nombre": "PUERTO ANTIOQUIA, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7688,"nombre": "SAN ALEJANDRO, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7689,"nombre": "SAN ANTONIO DE PADUA, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7690,"nombre": "SAN MIGUEL, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7691,"nombre": "VEGAEZ, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7692,"nombre": "VIGIA DEL FUERTE, VIGIA DEL FUERTE, ANTIOQUIA"},
    {"id": 7693,"nombre": "CACHIMBAL, VIJES, VALLE DEL CAUCA"},
    {"id": 7694,"nombre": "CARBONERO, VIJES, VALLE DEL CAUCA"},
    {"id": 7695,"nombre": "EL PORVENIR, VIJES, VALLE DEL CAUCA"},
    {"id": 7696,"nombre": "EL TAMBOR, VIJES, VALLE DEL CAUCA"},
    {"id": 7697,"nombre": "LA FRESNEDA, VIJES, VALLE DEL CAUCA"},
    {"id": 7698,"nombre": "LA RIVERA, VIJES, VALLE DEL CAUCA"},
    {"id": 7699,"nombre": "MOZAMBIQUE, VIJES, VALLE DEL CAUCA"},
    {"id": 7700,"nombre": "OCACHE, VIJES, VALLE DEL CAUCA"},
    {"id": 7701,"nombre": "VIJES, VIJES, VALLE DEL CAUCA"},
    {"id": 7702,"nombre": "VILLAMARIA, VIJES, VALLE DEL CAUCA"},
    {"id": 7703,"nombre": "ALTO DEL POZO, VILLA CARO, NORTE DE SANTANDER"},
    {"id": 7704,"nombre": "CORAZONES, VILLA CARO, NORTE DE SANTANDER"},
    {"id": 7705,"nombre": "EL PARAMO, VILLA CARO, NORTE DE SANTANDER"},
    {"id": 7706,"nombre": "VILLA CARO, VILLA CARO, NORTE DE SANTANDER"},
    {"id": 7707,"nombre": "EL ROBLE, VILLA DE LEYVA, BOYACA"},
    {"id": 7708,"nombre": "VILLA DE LEYVA, VILLA DE LEYVA, BOYACA"},
    {"id": 7709,"nombre": "VILLA DE SAN DIEGO DE UBATE, VILLA DE SAN DIEGO DE UBATE, CUNDINAMARCA"},
    {"id": 7710,"nombre": "JUAN FRIO, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7711,"nombre": "LA PARADA, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7712,"nombre": "LA UCHEMA, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7713,"nombre": "LOMITAS, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7714,"nombre": "LOS VADOS, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7715,"nombre": "PALOGORDO, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7716,"nombre": "PIZARREAL, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7717,"nombre": "SANTA ANA, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7718,"nombre": "VILLA DEL ROSARIO, VILLA DEL ROSARIO, NORTE DE SANTANDER"},
    {"id": 7719,"nombre": "CHALO, VILLA RICA, CAUCA"},
    {"id": 7720,"nombre": "GALLINAZA, VILLA RICA, CAUCA"},
    {"id": 7721,"nombre": "JUAN IGNACIO, VILLA RICA, CAUCA"},
    {"id": 7722,"nombre": "PRIMAVERA, VILLA RICA, CAUCA"},
    {"id": 7723,"nombre": "VILLA RICA, VILLA RICA, CAUCA"},
    {"id": 7724,"nombre": "ALBANIA, VILLAGARZON, PUTUMAYO"},
    {"id": 7725,"nombre": "ALTO SINAI, VILLAGARZON, PUTUMAYO"},
    {"id": 7726,"nombre": "BAJO CORAZON, VILLAGARZON, PUTUMAYO"},
    {"id": 7727,"nombre": "KOFANIA, VILLAGARZON, PUTUMAYO"},
    {"id": 7728,"nombre": "LA CASTELLANA, VILLAGARZON, PUTUMAYO"},
    {"id": 7729,"nombre": "NARANJITO, VILLAGARZON, PUTUMAYO"},
    {"id": 7730,"nombre": "PORVENIR, VILLAGARZON, PUTUMAYO"},
    {"id": 7731,"nombre": "PUERTO UMBRIA, VILLAGARZON, PUTUMAYO"},
    {"id": 7732,"nombre": "SAN MIGUEL DE LA CASTELLANA, VILLAGARZON, PUTUMAYO"},
    {"id": 7733,"nombre": "SANTA ROSA DE JUANAMBU, VILLAGARZON, PUTUMAYO"},
    {"id": 7734,"nombre": "VILLAFLOR, VILLAGARZON, PUTUMAYO"},
    {"id": 7735,"nombre": "VILLAGARZON, VILLAGARZON, PUTUMAYO"},
    {"id": 7736,"nombre": "VILLARRICA, VILLAGARZON, PUTUMAYO"},
    {"id": 7737,"nombre": "CERRO AZUL, VILLAGOMEZ, CUNDINAMARCA"},
    {"id": 7738,"nombre": "VILLAGOMEZ, VILLAGOMEZ, CUNDINAMARCA"},
    {"id": 7739,"nombre": "PLATANILLAL, VILLAHERMOSA, TOLIMA"},
    {"id": 7740,"nombre": "VILLAHERMOSA, VILLAHERMOSA, TOLIMA"},
    {"id": 7741,"nombre": "ALTO DE LA CRUZ (LOS CUERVOS), VILLAMARIA, CALDAS"},
    {"id": 7742,"nombre": "ALTO VILLARAZO, VILLAMARIA, CALDAS"},
    {"id": 7743,"nombre": "BAJO ARROYO, VILLAMARIA, CALDAS"},
    {"id": 7744,"nombre": "COROZAL, VILLAMARIA, CALDAS"},
    {"id": 7745,"nombre": "EL PINDO, VILLAMARIA, CALDAS"},
    {"id": 7746,"nombre": "GALLINAZO, VILLAMARIA, CALDAS"},
    {"id": 7747,"nombre": "LA FLORESTA, VILLAMARIA, CALDAS"},
    {"id": 7748,"nombre": "LA NUEVA PRIMAVERA, VILLAMARIA, CALDAS"},
    {"id": 7749,"nombre": "LLANITOS, VILLAMARIA, CALDAS"},
    {"id": 7750,"nombre": "MIRAFLORES, VILLAMARIA, CALDAS"},
    {"id": 7751,"nombre": "PARTIDAS, VILLAMARIA, CALDAS"},
    {"id": 7752,"nombre": "RIOCLARO, VILLAMARIA, CALDAS"},
    {"id": 7753,"nombre": "SAN JULIAN, VILLAMARIA, CALDAS"},
    {"id": 7754,"nombre": "VILLAMARIA, VILLAMARIA, CALDAS"},
    {"id": 7755,"nombre": "SOATAMA, VILLAPINZON, CUNDINAMARCA"},
    {"id": 7756,"nombre": "VILLAPINZON, VILLAPINZON, CUNDINAMARCA"},
    {"id": 7757,"nombre": "LA COLONIA, VILLARRICA, TOLIMA"},
    {"id": 7758,"nombre": "LOS ALPES, VILLARRICA, TOLIMA"},
    {"id": 7759,"nombre": "PUERTO LLERAS, VILLARRICA, TOLIMA"},
    {"id": 7760,"nombre": "VILLARRICA, VILLARRICA, TOLIMA"},
    {"id": 7761,"nombre": "BUENAVISTA, VILLAVICENCIO, META"},
    {"id": 7762,"nombre": "EL COCUY, VILLAVICENCIO, META"},
    {"id": 7763,"nombre": "LA CONCEPCION, VILLAVICENCIO, META"},
    {"id": 7764,"nombre": "PIPIRAL, VILLAVICENCIO, META"},
    {"id": 7765,"nombre": "PUERTO COLOMBIA, VILLAVICENCIO, META"},
    {"id": 7766,"nombre": "RINCON POMPEYA, VILLAVICENCIO, META"},
    {"id": 7767,"nombre": "SAN LUIS DE OCOA, VILLAVICENCIO, META"},
    {"id": 7768,"nombre": "SANTA MARIA LA BAJA, VILLAVICENCIO, META"},
    {"id": 7769,"nombre": "SANTA OLANIA, VILLAVICENCIO, META"},
    {"id": 7770,"nombre": "SANTA ROSA, VILLAVICENCIO, META"},
    {"id": 7771,"nombre": "SANTA TERESA, VILLAVICENCIO, META"},
    {"id": 7772,"nombre": "SERVITA, VILLAVICENCIO, META"},
    {"id": 7773,"nombre": "VANGUARDIA, VILLAVICENCIO, META"},
    {"id": 7774,"nombre": "VILLAVICENCIO, VILLAVICENCIO, META"},
    {"id": 7775,"nombre": "GOLONDRINAS, VILLAVIEJA, HUILA"},
    {"id": 7776,"nombre": "HATO NUEVO, VILLAVIEJA, HUILA"},
    {"id": 7777,"nombre": "LA VICTORIA, VILLAVIEJA, HUILA"},
    {"id": 7778,"nombre": "POLONIA, VILLAVIEJA, HUILA"},
    {"id": 7779,"nombre": "POTOSI, VILLAVIEJA, HUILA"},
    {"id": 7780,"nombre": "SAN ALFONSO, VILLAVIEJA, HUILA"},
    {"id": 7781,"nombre": "VILLAVIEJA, VILLAVIEJA, HUILA"},
    {"id": 7782,"nombre": "BAGAZAL, VILLETA, CUNDINAMARCA"},
    {"id": 7783,"nombre": "CHAPAIMA, VILLETA, CUNDINAMARCA"},
    {"id": 7784,"nombre": "ILO GRANDE, VILLETA, CUNDINAMARCA"},
    {"id": 7785,"nombre": "PUENTE DE BAGAZAL, VILLETA, CUNDINAMARCA"},
    {"id": 7786,"nombre": "VILLETA, VILLETA, CUNDINAMARCA"},
    {"id": 7787,"nombre": "EL PINAL, VIOTA, CUNDINAMARCA"},
    {"id": 7788,"nombre": "LIBERIA, VIOTA, CUNDINAMARCA"},
    {"id": 7789,"nombre": "SAN GABRIEL, VIOTA, CUNDINAMARCA"},
    {"id": 7790,"nombre": "VIOTA, VIOTA, CUNDINAMARCA"},
    {"id": 7791,"nombre": "VIRACACHA, VIRACACHA, BOYACA"},
    {"id": 7792,"nombre": "ALTOS DE JAEN, VITERBO, CALDAS"},
    {"id": 7793,"nombre": "EL FARO, VITERBO, CALDAS"},
    {"id": 7794,"nombre": "EL REMANSO, VITERBO, CALDAS"},
    {"id": 7795,"nombre": "EL SOCORRO, VITERBO, CALDAS"},
    {"id": 7796,"nombre": "LA ESMERALDA, VITERBO, CALDAS"},
    {"id": 7797,"nombre": "LOS ALCARAVANES, VITERBO, CALDAS"},
    {"id": 7798,"nombre": "LOS ANDES, VITERBO, CALDAS"},
    {"id": 7799,"nombre": "QUINTAS DE CATALINA, VITERBO, CALDAS"},
    {"id": 7800,"nombre": "VILLA DEL PALMAR, VITERBO, CALDAS"},
    {"id": 7801,"nombre": "VILLA DEL RIO, VITERBO, CALDAS"},
    {"id": 7802,"nombre": "VITERBO, VITERBO, CALDAS"},
    {"id": 7803,"nombre": "ALSACIA, YACOPI, CUNDINAMARCA"},
    {"id": 7804,"nombre": "ALTO DE CANAS, YACOPI, CUNDINAMARCA"},
    {"id": 7805,"nombre": "APOSENTOS, YACOPI, CUNDINAMARCA"},
    {"id": 7806,"nombre": "CABO VERDE, YACOPI, CUNDINAMARCA"},
    {"id": 7807,"nombre": "EL CHAPON, YACOPI, CUNDINAMARCA"},
    {"id": 7808,"nombre": "GUADUALITO, YACOPI, CUNDINAMARCA"},
    {"id": 7809,"nombre": "GUAYABALES, YACOPI, CUNDINAMARCA"},
    {"id": 7810,"nombre": "IBAMA, YACOPI, CUNDINAMARCA"},
    {"id": 7811,"nombre": "LA CORRALEJA, YACOPI, CUNDINAMARCA"},
    {"id": 7812,"nombre": "LAS PALMAS, YACOPI, CUNDINAMARCA"},
    {"id": 7813,"nombre": "LLANO MATEO, YACOPI, CUNDINAMARCA"},
    {"id": 7814,"nombre": "MONTANAS DE LIN, YACOPI, CUNDINAMARCA"},
    {"id": 7815,"nombre": "PATEVACA, YACOPI, CUNDINAMARCA"},
    {"id": 7816,"nombre": "PUEBLO NUEVO, YACOPI, CUNDINAMARCA"},
    {"id": 7817,"nombre": "SAN LUIS, YACOPI, CUNDINAMARCA"},
    {"id": 7818,"nombre": "TERAN, YACOPI, CUNDINAMARCA"},
    {"id": 7819,"nombre": "YACOPI, YACOPI, CUNDINAMARCA"},
    {"id": 7820,"nombre": "YASAL, YACOPI, CUNDINAMARCA"},
    {"id": 7821,"nombre": "CHAPACUAL, YACUANQUER, NARIÑO"},
    {"id": 7822,"nombre": "EL PLACER, YACUANQUER, NARIÑO"},
    {"id": 7823,"nombre": "EL ROSARIO, YACUANQUER, NARIÑO"},
    {"id": 7824,"nombre": "INATAS ALTO, YACUANQUER, NARIÑO"},
    {"id": 7825,"nombre": "SAN FELIPE, YACUANQUER, NARIÑO"},
    {"id": 7826,"nombre": "TACUAYA, YACUANQUER, NARIÑO"},
    {"id": 7827,"nombre": "TAINDALA, YACUANQUER, NARIÑO"},
    {"id": 7828,"nombre": "TASNAQUE, YACUANQUER, NARIÑO"},
    {"id": 7829,"nombre": "YACUANQUER, YACUANQUER, NARIÑO"},
    {"id": 7830,"nombre": "ZARAGOZA, YACUANQUER, NARIÑO"},
    {"id": 7831,"nombre": "YAGUARA, YAGUARA, HUILA"},
    {"id": 7832,"nombre": "YALI, YALI, ANTIOQUIA"},
    {"id": 7833,"nombre": "CEDENO, YARUMAL, ANTIOQUIA"},
    {"id": 7834,"nombre": "EL CEDRO, YARUMAL, ANTIOQUIA"},
    {"id": 7835,"nombre": "EL CIPRES, YARUMAL, ANTIOQUIA"},
    {"id": 7836,"nombre": "EL PUEBLITO, YARUMAL, ANTIOQUIA"},
    {"id": 7837,"nombre": "EL PUEBLO, YARUMAL, ANTIOQUIA"},
    {"id": 7838,"nombre": "LA BRAMADORA, YARUMAL, ANTIOQUIA"},
    {"id": 7839,"nombre": "LA LOMA, YARUMAL, ANTIOQUIA"},
    {"id": 7840,"nombre": "LA RIVIERA, YARUMAL, ANTIOQUIA"},
    {"id": 7841,"nombre": "LAS NIEVES, YARUMAL, ANTIOQUIA"},
    {"id": 7842,"nombre": "LLANOS DE CUIVA, YARUMAL, ANTIOQUIA"},
    {"id": 7843,"nombre": "OCHALI, YARUMAL, ANTIOQUIA"},
    {"id": 7844,"nombre": "YARUMAL, YARUMAL, ANTIOQUIA"},
    {"id": 7845,"nombre": "PAPURI, YAVARATE, VAUPES"},
    {"id": 7846,"nombre": "YAVARATE, YAVARATE, VAUPES"},
    {"id": 7847,"nombre": "ABISINIA, YOLOMBO, ANTIOQUIA"},
    {"id": 7848,"nombre": "BARENO, YOLOMBO, ANTIOQUIA"},
    {"id": 7849,"nombre": "BENGALA, YOLOMBO, ANTIOQUIA"},
    {"id": 7850,"nombre": "EL RUBI, YOLOMBO, ANTIOQUIA"},
    {"id": 7851,"nombre": "LA ARGENTINA, YOLOMBO, ANTIOQUIA"},
    {"id": 7852,"nombre": "LA CANCANA, YOLOMBO, ANTIOQUIA"},
    {"id": 7853,"nombre": "LA FLORESTA, YOLOMBO, ANTIOQUIA"},
    {"id": 7854,"nombre": "LA LEONA, YOLOMBO, ANTIOQUIA"},
    {"id": 7855,"nombre": "YOLOMBO, YOLOMBO, ANTIOQUIA"},
    {"id": 7856,"nombre": "CIENAGA BARBACO, YONDO, ANTIOQUIA"},
    {"id": 7857,"nombre": "CUATRO BOCAS, YONDO, ANTIOQUIA"},
    {"id": 7858,"nombre": "EL PORVENIR, YONDO, ANTIOQUIA"},
    {"id": 7859,"nombre": "SAN FRANCISCO, YONDO, ANTIOQUIA"},
    {"id": 7860,"nombre": "SAN LUIS BELTRAN, YONDO, ANTIOQUIA"},
    {"id": 7861,"nombre": "SAN MIGUEL DEL TIGRE, YONDO, ANTIOQUIA"},
    {"id": 7862,"nombre": "CASABE, YONDO, ANTIOQUIA"},
    {"id": 7863,"nombre": "BARBILLAL, YOPAL, CASANARE"},
    {"id": 7864,"nombre": "EL ARACAL, YOPAL, CASANARE"},
    {"id": 7865,"nombre": "EL CHARTE, YOPAL, CASANARE"},
    {"id": 7866,"nombre": "EL MORRO, YOPAL, CASANARE"},
    {"id": 7867,"nombre": "LA CHAPARRERA, YOPAL, CASANARE"},
    {"id": 7868,"nombre": "MORICHAL, YOPAL, CASANARE"},
    {"id": 7869,"nombre": "QUEBRADASECA, YOPAL, CASANARE"},
    {"id": 7870,"nombre": "TACARIMENA, YOPAL, CASANARE"},
    {"id": 7871,"nombre": "TILODIRAN, YOPAL, CASANARE"},
    {"id": 7872,"nombre": "YOPAL, YOPAL, CASANARE"},
    {"id": 7873,"nombre": "CAMPOALEGRE, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7874,"nombre": "DOPO, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7875,"nombre": "EL BOSQUE, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7876,"nombre": "EL CANEY, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7877,"nombre": "EL DORADO, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7878,"nombre": "JIGUALES, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7879,"nombre": "LAS DELICIAS, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7880,"nombre": "LOS PLANES, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7881,"nombre": "MEDIACANOA, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7882,"nombre": "MIRAVALLE, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7883,"nombre": "PUENTETIERRA, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7884,"nombre": "PUNTA BRAVA, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7885,"nombre": "RAYITO - LA NEGRA, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7886,"nombre": "SAN ANTONIO DE PIEDRAS, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7887,"nombre": "YOTOCO, YOTOCO, VALLE DEL CAUCA"},
    {"id": 7888,"nombre": "ARROYOHONDO, YUMBO, VALLE DEL CAUCA"},
    {"id": 7889,"nombre": "BARRIO AMERICA, YUMBO, VALLE DEL CAUCA"},
    {"id": 7890,"nombre": "DAPA, YUMBO, VALLE DEL CAUCA"},
    {"id": 7891,"nombre": "EL PEDREGAL, YUMBO, VALLE DEL CAUCA"},
    {"id": 7892,"nombre": "MIRAVALLE NORTE, YUMBO, VALLE DEL CAUCA"},
    {"id": 7893,"nombre": "MONTANITAS, YUMBO, VALLE DEL CAUCA"},
    {"id": 7894,"nombre": "MULALO, YUMBO, VALLE DEL CAUCA"},
    {"id": 7895,"nombre": "PUERTO ISAACS, YUMBO, VALLE DEL CAUCA"},
    {"id": 7896,"nombre": "SAN JOSE, YUMBO, VALLE DEL CAUCA"},
    {"id": 7897,"nombre": "SAN MARCOS, YUMBO, VALLE DEL CAUCA"},
    {"id": 7898,"nombre": "SANTA INES, YUMBO, VALLE DEL CAUCA"},
    {"id": 7899,"nombre": "YUMBILLO, YUMBO, VALLE DEL CAUCA"},
    {"id": 7900,"nombre": "YUMBO, YUMBO, VALLE DEL CAUCA"},
    {"id": 7901,"nombre": "JESUS DEL RIO, ZAMBRANO, BOLIVAR"},
    {"id": 7902,"nombre": "ZAMBRANO, ZAMBRANO, BOLIVAR"},
    {"id": 7903,"nombre": "LA FUENTE, ZAPATOCA, SANTANDER"},
    {"id": 7904,"nombre": "LA PLAZUELA, ZAPATOCA, SANTANDER"},
    {"id": 7905,"nombre": "ZAPATOCA, ZAPATOCA, SANTANDER"},
    {"id": 7906,"nombre": "CANO DE AGUAS, ZAPAYAN, MAGDALENA"},
    {"id": 7907,"nombre": "CAPUCHO, ZAPAYAN, MAGDALENA"},
    {"id": 7908,"nombre": "PIEDRAS DE MOLER, ZAPAYAN, MAGDALENA"},
    {"id": 7909,"nombre": "PIEDRAS PINTADAS, ZAPAYAN, MAGDALENA"},
    {"id": 7910,"nombre": "PUNTA DE PIEDRAS, ZAPAYAN, MAGDALENA"},
    {"id": 7911,"nombre": "BUENOS AIRES, ZARAGOZA, ANTIOQUIA"},
    {"id": 7912,"nombre": "EL REAL, ZARAGOZA, ANTIOQUIA"},
    {"id": 7913,"nombre": "PATO, ZARAGOZA, ANTIOQUIA"},
    {"id": 7914,"nombre": "PUERTO COLOMBIA, ZARAGOZA, ANTIOQUIA"},
    {"id": 7915,"nombre": "VEGAS DE SEGOVIA, ZARAGOZA, ANTIOQUIA"},
    {"id": 7916,"nombre": "ZARAGOZA, ZARAGOZA, ANTIOQUIA"},
    {"id": 7917,"nombre": "EL ALISAL, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7918,"nombre": "EL VERGEL, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7919,"nombre": "GUASIMAL, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7920,"nombre": "LA PAILA, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7921,"nombre": "LIMONES, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7922,"nombre": "QUEBRADANUEVA, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7923,"nombre": "VALLEJUELO, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7924,"nombre": "ZARZAL, ZARZAL, VALLE DEL CAUCA"},
    {"id": 7925,"nombre": "ZETAQUIRA, ZETAQUIRA, BOYACA"},
    {"id": 7926,"nombre": "EL OCASO, ZIPACON, CUNDINAMARCA"},
    {"id": 7927,"nombre": "LA CAPILLA, ZIPACON, CUNDINAMARCA"},
    {"id": 7928,"nombre": "PUEBLO VIEJO, ZIPACON, CUNDINAMARCA"},
    {"id": 7929,"nombre": "RINCON SANTO, ZIPACON, CUNDINAMARCA"},
    {"id": 7930,"nombre": "ZIPACON, ZIPACON, CUNDINAMARCA"},
    {"id": 7931,"nombre": "APOSENTO ALTO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7932,"nombre": "BOLIVAR 83, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7933,"nombre": "BOSQUES DE SILECIA, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7934,"nombre": "CAMINO DE BARANDILLAS, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7935,"nombre": "EL EMPALIZADO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7936,"nombre": "EL RUDAL, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7937,"nombre": "LA GRANJA, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7938,"nombre": "LOTEO BANOY, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7939,"nombre": "LOTEO LA PAZ, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7940,"nombre": "LOTEO PEDRAZA, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7941,"nombre": "LOTEO SANTA ISABEL, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7942,"nombre": "PASOANCHO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7943,"nombre": "PORTACHUELO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7944,"nombre": "PORTAL DE BARANDILLAS, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7945,"nombre": "RINCON DE BARANDILLAS, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7946,"nombre": "RIO FRIO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7947,"nombre": "SAN GABRIEL, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7948,"nombre": "SAN ISIDRO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7949,"nombre": "SAN JORGE, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7950,"nombre": "SAN MIGUEL, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7951,"nombre": "SANTIAGO PEREZ, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7952,"nombre": "SECTOR CARE' PERRO, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7953,"nombre": "ZIPAQUIRA, ZIPAQUIRA, CUNDINAMARCA"},
    {"id": 7954,"nombre": "ESTACION SEVILLA, ZONA BANANERA, MAGDALENA"},
    {"id": 7955,"nombre": "GUACAMAYAL, ZONA BANANERA, MAGDALENA"},
    {"id": 7956,"nombre": "GUAMACHITO, ZONA BANANERA, MAGDALENA"},
    {"id": 7957,"nombre": "LA CANDELARIA, ZONA BANANERA, MAGDALENA"},
    {"id": 7958,"nombre": "LA GRAN VIA, ZONA BANANERA, MAGDALENA"},
    {"id": 7959,"nombre": "ORIHUECA, ZONA BANANERA, MAGDALENA"},
    {"id": 7960,"nombre": "PALOMAR, ZONA BANANERA, MAGDALENA"},
    {"id": 7961,"nombre": "RIOFRIO, ZONA BANANERA, MAGDALENA"},
    {"id": 7962,"nombre": "SAN JOSE DE KENNEDY (CAMPO KENNEDY), ZONA BANANERA, MAGDALENA"},
    {"id": 7963,"nombre": "SANTA ROSALIA, ZONA BANANERA, MAGDALENA"},
    {"id": 7964,"nombre": "SEVILLA, ZONA BANANERA, MAGDALENA"},
    {"id": 7965,"nombre": "SOPLADOR, ZONA BANANERA, MAGDALENA"},
    {"id": 7966,"nombre": "TUCURINCA, ZONA BANANERA, MAGDALENA"},
    {"id": 7967,"nombre": "VARELA, ZONA BANANERA, MAGDALENA"},
    {"id": 7968,"nombre": "ZAWADY, ZONA BANANERA, MAGDALENA"},
    {"id": 7969,"nombre": "PRADO - SEVILLA, ZONA BANANERA, MAGDALENA"},
    {"id": 8002,"nombre": "MADREVIEJA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8003,"nombre": "POBLADO CAMPESTRE, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8004,"nombre": "SAN JOAQUIN, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8005,"nombre": "VILLA GORGONA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8006,"nombre": "EL ARENAL, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8007,"nombre": "EL GUALI, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8008,"nombre": "EL LAURO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8009,"nombre": "EL OTONO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8010,"nombre": "EL TIPLE, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8011,"nombre": "JUANCHITO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8012,"nombre": "LA REGINA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8013,"nombre": "LA SOLORZA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8014,"nombre": "CANDELARIA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8015,"nombre": "CARMELO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8016,"nombre": "BUCHITOLO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8017,"nombre": "CABUYAL, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8018,"nombre": "SAN JOSE DE URE, SAN JOSE DE URE, CORDOBA"},
    {"id": 8019,"nombre": "BELEN DE BAJIRA, BELEN DE BAJIRA, CHOCO"},
    {"id": 8020,"nombre": "BLANQUISET, BELEN DE BAJIRA, CHOCO"},
    {"id": 8021,"nombre": "CAMPO ALEGRE, BELEN DE BAJIRA, CHOCO"},
    {"id": 8022,"nombre": "LLANO RICO, BELEN DE BAJIRA, CHOCO"},
    {"id": 8023,"nombre": "MACONDO, BELEN DE BAJIRA, CHOCO"},
    {"id": 8024,"nombre": "PLAYA ROJA, BELEN DE BAJIRA, CHOCO"},
    {"id": 8025,"nombre": "TUMARADOCITO, BELEN DE BAJIRA, CHOCO"},
    {"id": 8026,"nombre": "PUERTO CARRENO, PUERTO CARREÑO, VICHADA"},
    {"id": 8027,"nombre": "LA VENTUROSA, PUERTO CARREÑO, VICHADA"},
    {"id": 8028,"nombre": "CASUARITO, PUERTO CARREÑO, VICHADA"},
    {"id": 8029,"nombre": "PUERTO MURILLO, PUERTO CARREÑO, VICHADA"},
    {"id": 8030,"nombre": "ACEITICO, PUERTO CARREÑO, VICHADA"},
    {"id": 8031,"nombre": "GARCITAS, PUERTO CARREÑO, VICHADA"},
    {"id": 8032,"nombre": "GUARIPA, PUERTO CARREÑO, VICHADA"},
    {"id": 8033,"nombre": "MIRAFLORES, MIRAFLORES, GUAVIARE"},
    {"id": 8034,"nombre": "BARRANQUILLITA, MIRAFLORES, GUAVIARE"},
    {"id": 8035,"nombre": "LAGOS DEL DORADO, MIRAFLORES, GUAVIARE"},
    {"id": 8036,"nombre": "LAS PAVAS, MIRAFLORES, GUAVIARE"},
    {"id": 8037,"nombre": "CALAMAR, CALAMAR, GUAVIARE"},
    {"id": 8038,"nombre": "PUERTO COLOMBIA, PUERTO COLOMBIA, GUAINIA"},
    {"id": 8039,"nombre": "SEJAL (MAHIMACHI), PUERTO COLOMBIA, GUAINIA"},
    {"id": 8040,"nombre": "PUERTO SANTANDER, PUERTO SANTANDER, AMAZONAS"},
    {"id": 8041,"nombre": "PUERTO NARIÑO, PUERTO NARIÑO, AMAZONAS"},
    {"id": 8042,"nombre": "ATACUARI, PUERTO NARIÑO, AMAZONAS"},
    {"id": 8043,"nombre": "PACOA, LA VICTORIA, AMAZONAS"},
    {"id": 8044,"nombre": "PROVIDENCIA, PROVIDENCIA, SAN ANDRES"},
    {"id": 8045,"nombre": "FRESH WATER BAY, PROVIDENCIA, SAN ANDRES"},
    {"id": 8046,"nombre": "SOUTH WEST BAY, PROVIDENCIA, SAN ANDRES"},
    {"id": 8047,"nombre": "BOTTON HOUSE, PROVIDENCIA, SAN ANDRES"},
    {"id": 8048,"nombre": "SAN FELIPE, PROVIDENCIA, SAN ANDRES"},
    {"id": 8049,"nombre": "ROCKY POINT, PROVIDENCIA, SAN ANDRES"},
    {"id": 8050,"nombre": "SAN ANDRES, SAN ANDRES, SAN ANDRES"},
    {"id": 8051,"nombre": "LA LOMA, SAN ANDRES, SAN ANDRES"},
    {"id": 8052,"nombre": "SAN LUIS, SAN ANDRES, SAN ANDRES"},
    {"id": 8053,"nombre": "SANTIAGO, SANTIAGO, PUTUMAYO"},
    {"id": 8054,"nombre": "SAN ANDRES, SANTIAGO, PUTUMAYO"},
    {"id": 8055,"nombre": "EL CASCAJO, SANTIAGO, PUTUMAYO"},
    {"id": 8056,"nombre": "LA DORADA, SAN MIGUEL, PUTUMAYO"},
    {"id": 8057,"nombre": "SAN MIGUEL, SAN MIGUEL, PUTUMAYO"},
    {"id": 8058,"nombre": "SAN FRANCISCO, SAN FRANCISCO, PUTUMAYO"},
    {"id": 8059,"nombre": "SAN ANTONIO, SAN FRANCISCO, PUTUMAYO"},
    {"id": 8060,"nombre": "PATUYACO, SAN FRANCISCO, PUTUMAYO"},
    {"id": 8061,"nombre": "SAN SILVESTRE, SAN FRANCISCO, PUTUMAYO"},
    {"id": 8062,"nombre": "COLON, COLON, PUTUMAYO"},
    {"id": 8063,"nombre": "SAN PEDRO, COLON, PUTUMAYO"},
    {"id": 8064,"nombre": "VILLANUEVA, VILLANUEVA, CASANARE"},
    {"id": 8065,"nombre": "CARIBAYONA, VILLANUEVA, CASANARE"},
    {"id": 8066,"nombre": "SANTA HELENA DE UPIA, VILLANUEVA, CASANARE"},
    {"id": 8067,"nombre": "SAN AGUSTIN, VILLANUEVA, CASANARE"},
    {"id": 8068,"nombre": "SABANALARGA, SABANALARGA, CASANARE"},
    {"id": 8069,"nombre": "AGUACLARA, SABANALARGA, CASANARE"},
    {"id": 8070,"nombre": "EL SECRETO, SABANALARGA, CASANARE"},
    {"id": 8071,"nombre": "SAN PEDRO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8072,"nombre": "ANGOSTURAS, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8073,"nombre": "BUENOS AIRES, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8074,"nombre": "LOS CHANCOS, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8075,"nombre": "NARANJAL, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8076,"nombre": "PRESIDENTE, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8077,"nombre": "SAN JOSE, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8078,"nombre": "TODOS LOS SANTOS, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8079,"nombre": "GUAYABAL, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8080,"nombre": "MONTE GRANDE, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8081,"nombre": "PAVAS, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8082,"nombre": "BELEN, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8083,"nombre": "EL CHIRCAL, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8084,"nombre": "EL HORMIGUERO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8085,"nombre": "EL VINEDO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8086,"nombre": "GUADUALEJO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8087,"nombre": "GUAQUEROS, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8088,"nombre": "LA PUENTE, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8089,"nombre": "LA VENTURA, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8090,"nombre": "PUEBLO NUEVO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8091,"nombre": "MONTERREDONDO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8092,"nombre": "PANTANILLO, SAN PEDRO, VALLE DEL CAUCA"},
    {"id": 8093,"nombre": "RESTREPO, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8094,"nombre": "EL DIAMANTE, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8095,"nombre": "ILAMA - EL AGRADO, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8096,"nombre": "LA PALMA, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8097,"nombre": "MADRONAL, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8098,"nombre": "RIOBRAVO, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8099,"nombre": "ROMAN, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8100,"nombre": "SAN SALVADOR, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8101,"nombre": "TRAGEDIAS, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8102,"nombre": "ALTO DEL POSO, RESTREPO, VALLE DEL CAUCA"},
    {"id": 8103,"nombre": "LA VICTORIA, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8104,"nombre": "HOLGUIN, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8105,"nombre": "MIRAVALLES, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8106,"nombre": "RIVERALTA, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8107,"nombre": "SAN JOSE, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8108,"nombre": "SAN PEDRO, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8109,"nombre": "TAGUALES, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8110,"nombre": "CUEVA LOCA, LA VICTORIA, VALLE DEL CAUCA"},
    {"id": 8111,"nombre": "LA UNION, LA UNION, VALLE DEL CAUCA"},
    {"id": 8112,"nombre": "CORCEGA, LA UNION, VALLE DEL CAUCA"},
    {"id": 8113,"nombre": "EL LINDERO, LA UNION, VALLE DEL CAUCA"},
    {"id": 8114,"nombre": "LA AGUADA, LA UNION, VALLE DEL CAUCA"},
    {"id": 8115,"nombre": "LA DESPENSA, LA UNION, VALLE DEL CAUCA"},
    {"id": 8116,"nombre": "QUEBRADAGRANDE, LA UNION, VALLE DEL CAUCA"},
    {"id": 8117,"nombre": "SAN LUIS, LA UNION, VALLE DEL CAUCA"},
    {"id": 8118,"nombre": "SAN PEDRO, LA UNION, VALLE DEL CAUCA"},
    {"id": 8119,"nombre": "CANDELARIA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8120,"nombre": "BUCHITOLO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8121,"nombre": "EL ARENAL, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8122,"nombre": "CABUYAL, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8123,"nombre": "CARMELO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8124,"nombre": "EL LAURO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8125,"nombre": "EL TIPLE, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8126,"nombre": "JUANCHITO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8127,"nombre": "VILLA GORGONA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8128,"nombre": "LA REGINA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8129,"nombre": "LA SOLORZA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8130,"nombre": "MADREVIEJA, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8131,"nombre": "SAN JOAQUIN, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8132,"nombre": "EL OTONO, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8133,"nombre": "EL GUALI, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8134,"nombre": "POBLADO CAMPESTRE, CANDELARIA, VALLE DEL CAUCA"},
    {"id": 8135,"nombre": "BOLIVAR, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8136,"nombre": "BETANIA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8137,"nombre": "CERRO AZUL, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8138,"nombre": "CATRES, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8139,"nombre": "DOSQUEBRADAS, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8140,"nombre": "GUARE, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8141,"nombre": "LA HERRADURA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8142,"nombre": "LA TULIA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8143,"nombre": "NARANJAL, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8144,"nombre": "PRIMAVERA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8145,"nombre": "RICAURTE, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8146,"nombre": "SANTA TERESA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8147,"nombre": "AGUAS LINDAS, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8148,"nombre": "LA MONTANUELA, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8149,"nombre": "SAN FERNANDO, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8150,"nombre": "POTOSI, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8151,"nombre": "LAS CABANAS, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8152,"nombre": "RIO DOVIO, BOLIVAR, VALLE DEL CAUCA"},
    {"id": 8153,"nombre": "ARGELIA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8154,"nombre": "LA PAZ, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8155,"nombre": "MARACAIBO, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8156,"nombre": "SAN ROQUE RAIZA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8157,"nombre": "LA AURORA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8158,"nombre": "LA PALMA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8159,"nombre": "COROZAL, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8160,"nombre": "LA CRISTALINA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8161,"nombre": "LA MARINA, ARGELIA, VALLE DEL CAUCA"},
    {"id": 8162,"nombre": "SUAREZ, SUAREZ, TOLIMA"},
    {"id": 8163,"nombre": "HATO VIEJO, SUAREZ, TOLIMA"},
    {"id": 8164,"nombre": "SAN CAYETANO, SUAREZ, TOLIMA"},
    {"id": 8165,"nombre": "CANAVERALES, SUAREZ, TOLIMA"},
    {"id": 8166,"nombre": "AGUA BLANCA, SUAREZ, TOLIMA"},
    {"id": 8167,"nombre": "SAN LUIS, SAN LUIS, TOLIMA"},
    {"id": 8168,"nombre": "EL SALITRE, SAN LUIS, TOLIMA"},
    {"id": 8169,"nombre": "MALNOMBRE, SAN LUIS, TOLIMA"},
    {"id": 8170,"nombre": "PAYANDE, SAN LUIS, TOLIMA"},
    {"id": 8171,"nombre": "VARSOVIA, SAN LUIS, TOLIMA"},
    {"id": 8172,"nombre": "TOMOGO, SAN LUIS, TOLIMA"},
    {"id": 8173,"nombre": "GUASIMITO, SAN LUIS, TOLIMA"},
    {"id": 8174,"nombre": "LUISA GARCIA, SAN LUIS, TOLIMA"},
    {"id": 8175,"nombre": "SANTA LUCIA, SAN LUIS, TOLIMA"},
    {"id": 8176,"nombre": "SALDANA, SALDAÑA, TOLIMA"},
    {"id": 8177,"nombre": "JABALCON, SALDAÑA, TOLIMA"},
    {"id": 8178,"nombre": "SANTA INES, SALDAÑA, TOLIMA"},
    {"id": 8179,"nombre": "TOLUVIEJO, TOLU VIEJO, SUCRE"},
    {"id": 8180,"nombre": "CARACOL, TOLU VIEJO, SUCRE"},
    {"id": 8181,"nombre": "LAS PIEDRAS, TOLU VIEJO, SUCRE"},
    {"id": 8182,"nombre": "MACAJAN, TOLU VIEJO, SUCRE"},
    {"id": 8183,"nombre": "PALMIRA, TOLU VIEJO, SUCRE"},
    {"id": 8184,"nombre": "VARSOVIA, TOLU VIEJO, SUCRE"},
    {"id": 8185,"nombre": "LA PICHE, TOLU VIEJO, SUCRE"},
    {"id": 8186,"nombre": "CIENAGUITA, TOLU VIEJO, SUCRE"},
    {"id": 8187,"nombre": "EL FLORAL, TOLU VIEJO, SUCRE"},
    {"id": 8188,"nombre": "MOQUEN, TOLU VIEJO, SUCRE"},
    {"id": 8189,"nombre": "GUALON, TOLU VIEJO, SUCRE"},
    {"id": 8190,"nombre": "EL CANITO, TOLU VIEJO, SUCRE"},
    {"id": 8191,"nombre": "LA SIRIA, TOLU VIEJO, SUCRE"},
    {"id": 8192,"nombre": "SUCRE, SUCRE, SUCRE"},
    {"id": 8193,"nombre": "ARBOLEDA, SUCRE, SUCRE"},
    {"id": 8194,"nombre": "BAJOGRANDE, SUCRE, SUCRE"},
    {"id": 8195,"nombre": "CALZON BLANCO, SUCRE, SUCRE"},
    {"id": 8196,"nombre": "CAMAJON, SUCRE, SUCRE"},
    {"id": 8197,"nombre": "CAMPO ALEGRE, SUCRE, SUCRE"},
    {"id": 8198,"nombre": "CORDOBA, SUCRE, SUCRE"},
    {"id": 8199,"nombre": "CHAPARRAL, SUCRE, SUCRE"},
    {"id": 8200,"nombre": "EL CONGRESO, SUCRE, SUCRE"},
    {"id": 8201,"nombre": "ISLA DEL COCO, SUCRE, SUCRE"},
    {"id": 8202,"nombre": "ISLA GRANDE, SUCRE, SUCRE"},
    {"id": 8203,"nombre": "LA GUARIPA, SUCRE, SUCRE"},
    {"id": 8204,"nombre": "LA VENTURA, SUCRE, SUCRE"},
    {"id": 8205,"nombre": "MONTERIA, SUCRE, SUCRE"},
    {"id": 8206,"nombre": "MUZANGA, SUCRE, SUCRE"},
    {"id": 8207,"nombre": "NARANJAL, SUCRE, SUCRE"},
    {"id": 8208,"nombre": "NARIÑO, SUCRE, SUCRE"},
    {"id": 8209,"nombre": "OREJERO, SUCRE, SUCRE"},
    {"id": 8210,"nombre": "SAN LUIS, SUCRE, SUCRE"},
    {"id": 8211,"nombre": "TRAVESIA, SUCRE, SUCRE"},
    {"id": 8212,"nombre": "HATO NUEVO, SUCRE, SUCRE"},
    {"id": 8213,"nombre": "PAMPANILLA, SUCRE, SUCRE"},
    {"id": 8214,"nombre": "LA SOLERA, SUCRE, SUCRE"},
    {"id": 8215,"nombre": "BAJO PUREZA, SUCRE, SUCRE"},
    {"id": 8216,"nombre": "LA PALMA, SUCRE, SUCRE"},
    {"id": 8217,"nombre": "MACHETON, SUCRE, SUCRE"},
    {"id": 8218,"nombre": "MALAMBO, SUCRE, SUCRE"},
    {"id": 8219,"nombre": "SAN MATEO, SUCRE, SUCRE"},
    {"id": 8220,"nombre": "SAN PEDRO, SAN PEDRO, SUCRE"},
    {"id": 8221,"nombre": "SAN MATEO, SAN PEDRO, SUCRE"},
    {"id": 8222,"nombre": "ROVIRA, SAN PEDRO, SUCRE"},
    {"id": 8223,"nombre": "NUMANCIA, SAN PEDRO, SUCRE"},
    {"id": 8224,"nombre": "LA UNION, LA UNION, SUCRE"},
    {"id": 8225,"nombre": "CAYO DELGADO, LA UNION, SUCRE"},
    {"id": 8226,"nombre": "PAJARITO, LA UNION, SUCRE"},
    {"id": 8227,"nombre": "CASCARILLA, LA UNION, SUCRE"},
    {"id": 8228,"nombre": "LAS PALMITAS, LA UNION, SUCRE"},
    {"id": 8229,"nombre": "SABANETA, LA UNION, SUCRE"},
    {"id": 8230,"nombre": "COVEAS, COVEÑAS, SUCRE"},
    {"id": 8231,"nombre": "BOCA DE LA CIENAGA, COVEÑAS, SUCRE"},
    {"id": 8232,"nombre": "EL REPARO, COVEÑAS, SUCRE"},
    {"id": 8233,"nombre": "GUAYABAL, COVEÑAS, SUCRE"},
    {"id": 8234,"nombre": "PUNTA SECA, COVEÑAS, SUCRE"},
    {"id": 8235,"nombre": "EL MAMEY, COVEÑAS, SUCRE"},
    {"id": 8236,"nombre": "BUENAVISTA, BUENAVISTA, SUCRE"},
    {"id": 8237,"nombre": "PLAN PAREJO, BUENAVISTA, SUCRE"},
    {"id": 8238,"nombre": "REMOLINO, BUENAVISTA, SUCRE"},
    {"id": 8239,"nombre": "CHINA ROJA, BUENAVISTA, SUCRE"},
    {"id": 8240,"nombre": "LAS CHICHAS, BUENAVISTA, SUCRE"},
    {"id": 8241,"nombre": "SINCELEJO, SINCELEJO, SUCRE"},
    {"id": 8242,"nombre": "ANIME, SINCELEJO, SUCRE"},
    {"id": 8243,"nombre": "BAZAN, SINCELEJO, SUCRE"},
    {"id": 8244,"nombre": "CEJA, SINCELEJO, SUCRE"},
    {"id": 8245,"nombre": "COCOROTE, SINCELEJO, SUCRE"},
    {"id": 8246,"nombre": "GRANADA, SINCELEJO, SUCRE"},
    {"id": 8247,"nombre": "HATILLO, SINCELEJO, SUCRE"},
    {"id": 8248,"nombre": "JUNIN, SINCELEJO, SUCRE"},
    {"id": 8249,"nombre": "LOS LIMONES, SINCELEJO, SUCRE"},
    {"id": 8250,"nombre": "PANTANO, SINCELEJO, SUCRE"},
    {"id": 8251,"nombre": "VALENCIA, SINCELEJO, SUCRE"},
    {"id": 8252,"nombre": "VELEZ, SINCELEJO, SUCRE"},
    {"id": 8253,"nombre": "LA VIVIENDA, SINCELEJO, SUCRE"},
    {"id": 8254,"nombre": "PERENDENGUE, SINCELEJO, SUCRE"},
    {"id": 8255,"nombre": "GALAPAGO, SINCELEJO, SUCRE"},
    {"id": 8256,"nombre": "MORALITO, SINCELEJO, SUCRE"},
    {"id": 8257,"nombre": "VILLANUEVA, VILLANUEVA, SANTANDER"},
    {"id": 8258,"nombre": "HATO VIEJO, VILLANUEVA, SANTANDER"},
    {"id": 8259,"nombre": "SUCRE, SUCRE, SANTANDER"},
    {"id": 8260,"nombre": "LA GRANJA, SUCRE, SANTANDER"},
    {"id": 8261,"nombre": "LA PRADERA, SUCRE, SANTANDER"},
    {"id": 8262,"nombre": "SABANA GRANDE, SUCRE, SANTANDER"},
    {"id": 8263,"nombre": "ARALES, SUCRE, SANTANDER"},
    {"id": 8264,"nombre": "EL PORVENIR, SUCRE, SANTANDER"},
    {"id": 8265,"nombre": "SANTA BARBARA, SANTA BARBARA, SANTANDER"},
    {"id": 8266,"nombre": "EL APURE, SANTA BARBARA, SANTANDER"},
    {"id": 8267,"nombre": "LA CEBA, SANTA BARBARA, SANTANDER"},
    {"id": 8268,"nombre": "LA TAHONA, SANTA BARBARA, SANTANDER"},
    {"id": 8269,"nombre": "POZO BRAVO, SANTA BARBARA, SANTANDER"},
    {"id": 8270,"nombre": "QUEBRADAS, SANTA BARBARA, SANTANDER"},
    {"id": 8271,"nombre": "LOS VOLCANES, SANTA BARBARA, SANTANDER"},
    {"id": 8272,"nombre": "SAN MIGUEL, SAN MIGUEL, SANTANDER"},
    {"id": 8273,"nombre": "PIEDRALARGA, SAN MIGUEL, SANTANDER"},
    {"id": 8274,"nombre": "SAN IGNACIO, SAN MIGUEL, SANTANDER"},
    {"id": 8275,"nombre": "SAN PEDRO, SAN MIGUEL, SANTANDER"},
    {"id": 8276,"nombre": "ARENALES, SAN MIGUEL, SANTANDER"},
    {"id": 8277,"nombre": "PAMPLONITA, SAN MIGUEL, SANTANDER"},
    {"id": 8278,"nombre": "SAN ANDRES, SAN ANDRES, SANTANDER"},
    {"id": 8279,"nombre": "ALTO DE JAIMES, SAN ANDRES, SANTANDER"},
    {"id": 8280,"nombre": "CAIRASCO, SAN ANDRES, SANTANDER"},
    {"id": 8281,"nombre": "EL PIRE, SAN ANDRES, SANTANDER"},
    {"id": 8282,"nombre": "HATO DE CABALLEROS, SAN ANDRES, SANTANDER"},
    {"id": 8283,"nombre": "LAGUNA DE ORTICES, SAN ANDRES, SANTANDER"},
    {"id": 8284,"nombre": "LISTARA, SAN ANDRES, SANTANDER"},
    {"id": 8285,"nombre": "PANGOTE, SAN ANDRES, SANTANDER"},
    {"id": 8286,"nombre": "PANGUA, SAN ANDRES, SANTANDER"},
    {"id": 8287,"nombre": "SANTA CRUZ, SAN ANDRES, SANTANDER"},
    {"id": 8288,"nombre": "SANTO DOMINGO, SAN ANDRES, SANTANDER"},
    {"id": 8289,"nombre": "LA RAMADA, SAN ANDRES, SANTANDER"},
    {"id": 8290,"nombre": "MOGOTOCORO, SAN ANDRES, SANTANDER"},
    {"id": 8291,"nombre": "SAN PABLO, SAN ANDRES, SANTANDER"},
    {"id": 8292,"nombre": "ANCA, SAN ANDRES, SANTANDER"},
    {"id": 8293,"nombre": "RIONEGRO, RIONEGRO, SANTANDER"},
    {"id": 8294,"nombre": "EL RUBI, RIONEGRO, SANTANDER"},
    {"id": 8295,"nombre": "CUESTA RICA, RIONEGRO, SANTANDER"},
    {"id": 8296,"nombre": "LA SALINA, RIONEGRO, SANTANDER"},
    {"id": 8297,"nombre": "LA CONSULTA, RIONEGRO, SANTANDER"},
    {"id": 8298,"nombre": "GALAPAGOS, RIONEGRO, SANTANDER"},
    {"id": 8299,"nombre": "POPAS, RIONEGRO, SANTANDER"},
    {"id": 8300,"nombre": "LA CEIBA, RIONEGRO, SANTANDER"},
    {"id": 8301,"nombre": "LA GLORIA TIGRA, RIONEGRO, SANTANDER"},
    {"id": 8302,"nombre": "LLANO DE PALMAS, RIONEGRO, SANTANDER"},
    {"id": 8303,"nombre": "MISIJUAY, RIONEGRO, SANTANDER"},
    {"id": 8304,"nombre": "PAPAYAL, RIONEGRO, SANTANDER"},
    {"id": 8305,"nombre": "VILLA PAZ, RIONEGRO, SANTANDER"},
    {"id": 8306,"nombre": "VEINTE DE JULIO, RIONEGRO, SANTANDER"},
    {"id": 8307,"nombre": "LA MUSANDA, RIONEGRO, SANTANDER"},
    {"id": 8308,"nombre": "LA UNION DE GALAPAGOS, RIONEGRO, SANTANDER"},
    {"id": 8309,"nombre": "LA PLATANALA, RIONEGRO, SANTANDER"},
    {"id": 8310,"nombre": "LOS CHORROS (SAN JOSE), RIONEGRO, SANTANDER"},
    {"id": 8311,"nombre": "MONTANITAS, RIONEGRO, SANTANDER"},
    {"id": 8312,"nombre": "SAN RAFAEL, RIONEGRO, SANTANDER"},
    {"id": 8313,"nombre": "SAN JOSE DE AREVALO, RIONEGRO, SANTANDER"},
    {"id": 8314,"nombre": "NUEVA LAGUNA, RIONEGRO, SANTANDER"},
    {"id": 8315,"nombre": "PUENTE TIERRA, RIONEGRO, SANTANDER"},
    {"id": 8316,"nombre": "LA PAZ, LA PAZ, SANTANDER"},
    {"id": 8317,"nombre": "EL HATO, LA PAZ, SANTANDER"},
    {"id": 8318,"nombre": "LA LOMA, LA PAZ, SANTANDER"},
    {"id": 8319,"nombre": "EL CENTRO, LA PAZ, SANTANDER"},
    {"id": 8320,"nombre": "EL TIGRE, LA PAZ, SANTANDER"},
    {"id": 8321,"nombre": "TROCHAS, LA PAZ, SANTANDER"},
    {"id": 8322,"nombre": "GUEPSA, GUEPSA, SANTANDER"},
    {"id": 8323,"nombre": "LA VIRGEN, GUEPSA, SANTANDER"},
    {"id": 8324,"nombre": "SONOSI, GUEPSA, SANTANDER"},
    {"id": 8325,"nombre": "EL GUAMAL, GUEPSA, SANTANDER"},
    {"id": 8326,"nombre": "GUADALUPE, GUADALUPE, SANTANDER"},
    {"id": 8327,"nombre": "QUITASOL, GUADALUPE, SANTANDER"},
    {"id": 8328,"nombre": "SOLFERINO, GUADALUPE, SANTANDER"},
    {"id": 8329,"nombre": "SAN ANTONIO, GUADALUPE, SANTANDER"},
    {"id": 8330,"nombre": "LA LAJITA, GUADALUPE, SANTANDER"},
    {"id": 8331,"nombre": "EL PENON, EL PEÑON, SANTANDER"},
    {"id": 8332,"nombre": "BOCAS DEL HORTA, EL PEÑON, SANTANDER"},
    {"id": 8333,"nombre": "CRUCES, EL PEÑON, SANTANDER"},
    {"id": 8334,"nombre": "RIO BLANCO, EL PEÑON, SANTANDER"},
    {"id": 8335,"nombre": "CONCEPCION, CONCEPCION, SANTANDER"},
    {"id": 8336,"nombre": "CHIMA, CHIMA, SANTANDER"},
    {"id": 8337,"nombre": "LA PIEDRA, CHIMA, SANTANDER"},
    {"id": 8338,"nombre": "CABRERA, CABRERA, SANTANDER"},
    {"id": 8339,"nombre": "BOCORE, CABRERA, SANTANDER"},
    {"id": 8340,"nombre": "OJO DE AGUA, CABRERA, SANTANDER"},
    {"id": 8341,"nombre": "LA LLANADA, CABRERA, SANTANDER"},
    {"id": 8342,"nombre": "BOLIVAR, BOLIVAR, SANTANDER"},
    {"id": 8343,"nombre": "BERBEO, BOLIVAR, SANTANDER"},
    {"id": 8344,"nombre": "FLOREZ, BOLIVAR, SANTANDER"},
    {"id": 8345,"nombre": "SANTA ROSA, BOLIVAR, SANTANDER"},
    {"id": 8346,"nombre": "EL ESPINAL, BOLIVAR, SANTANDER"},
    {"id": 8347,"nombre": "EL TRAPAL, BOLIVAR, SANTANDER"},
    {"id": 8348,"nombre": "EL GUACAMAYO, BOLIVAR, SANTANDER"},
    {"id": 8349,"nombre": "LA HERMOSURA, BOLIVAR, SANTANDER"},
    {"id": 8350,"nombre": "LA MELONA, BOLIVAR, SANTANDER"},
    {"id": 8351,"nombre": "GALLEGOS, BOLIVAR, SANTANDER"},
    {"id": 8352,"nombre": "LAJA SECA, BOLIVAR, SANTANDER"},
    {"id": 8353,"nombre": "SAN ROQUE, BOLIVAR, SANTANDER"},
    {"id": 8354,"nombre": "PLAN DE ROJAS, BOLIVAR, SANTANDER"},
    {"id": 8355,"nombre": "SOCORRITO, BOLIVAR, SANTANDER"},
    {"id": 8356,"nombre": "HORTA MEDIOS, BOLIVAR, SANTANDER"},
    {"id": 8357,"nombre": "BETULIA, BETULIA, SANTANDER"},
    {"id": 8358,"nombre": "EL RAMO, BETULIA, SANTANDER"},
    {"id": 8359,"nombre": "MONTEBELLO, BETULIA, SANTANDER"},
    {"id": 8360,"nombre": "PUTANA, BETULIA, SANTANDER"},
    {"id": 8361,"nombre": "SAN JORGE, BETULIA, SANTANDER"},
    {"id": 8362,"nombre": "SANTA BARBARA, BETULIA, SANTANDER"},
    {"id": 8363,"nombre": "LA DURA, BETULIA, SANTANDER"},
    {"id": 8364,"nombre": "CERRO DE LA PAZ, BETULIA, SANTANDER"},
    {"id": 8365,"nombre": "TIENDA NUEVA, BETULIA, SANTANDER"},
    {"id": 8366,"nombre": "SOL DE ORIENTE, BETULIA, SANTANDER"},
    {"id": 8367,"nombre": "BARBOSA, BARBOSA, SANTANDER"},
    {"id": 8368,"nombre": "CITE, BARBOSA, SANTANDER"},
    {"id": 8369,"nombre": "ALBANIA, ALBANIA, SANTANDER"},
    {"id": 8370,"nombre": "CARRETERO, ALBANIA, SANTANDER"},
    {"id": 8371,"nombre": "EL HATILLO, ALBANIA, SANTANDER"},
    {"id": 8372,"nombre": "LA MESA, ALBANIA, SANTANDER"},
    {"id": 8373,"nombre": "SABANETA, ALBANIA, SANTANDER"},
    {"id": 8374,"nombre": "GUACOS, ALBANIA, SANTANDER"},
    {"id": 8375,"nombre": "UTAPA NORTE, ALBANIA, SANTANDER"},
    {"id": 8376,"nombre": "UTAPA SUR, ALBANIA, SANTANDER"},
    {"id": 8377,"nombre": "GUAYABAL, ALBANIA, SANTANDER"},
    {"id": 8378,"nombre": "BALBOA, BALBOA, RISARALDA"},
    {"id": 8379,"nombre": "TAMBORES, BALBOA, RISARALDA"},
    {"id": 8380,"nombre": "SAN ANTONIO, BALBOA, RISARALDA"},
    {"id": 8381,"nombre": "CUBA, BALBOA, RISARALDA"},
    {"id": 8382,"nombre": "LA AURORA, BALBOA, RISARALDA"},
    {"id": 8383,"nombre": "CRUCES, BALBOA, RISARALDA"},
    {"id": 8384,"nombre": "TRES ESQUINAS, BALBOA, RISARALDA"},
    {"id": 8385,"nombre": "LA CANCHA, BALBOA, RISARALDA"},
    {"id": 8386,"nombre": "LA MANCHA, BALBOA, RISARALDA"},
    {"id": 8387,"nombre": "LA QUIEBRA, BALBOA, RISARALDA"},
    {"id": 8388,"nombre": "SAN ANTONIO, BALBOA, RISARALDA"},
    {"id": 8389,"nombre": "TOTUI, BALBOA, RISARALDA"},
    {"id": 8390,"nombre": "CORDOBA, CORDOBA, QUINDIO"},
    {"id": 8391,"nombre": "BUENAVISTA, BUENAVISTA, QUINDIO"},
    {"id": 8392,"nombre": "RIO VERDE, BUENAVISTA, QUINDIO"},
    {"id": 8393,"nombre": "ARMENIA, ARMENIA, QUINDIO"},
    {"id": 8394,"nombre": "EL CAIMO, ARMENIA, QUINDIO"},
    {"id": 8395,"nombre": "MURILLO, ARMENIA, QUINDIO"},
    {"id": 8396,"nombre": "PANTANILLO, ARMENIA, QUINDIO"},
    {"id": 8397,"nombre": "TOLEDO, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8398,"nombre": "BATA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8399,"nombre": "BOJABA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8400,"nombre": "EL CHUSCAL, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8401,"nombre": "GIBRALTAR, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8402,"nombre": "PEDRAZA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8403,"nombre": "SAN ALBERTO, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8404,"nombre": "SEGOVIA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8405,"nombre": "SAN BERNARDO DE BATA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8406,"nombre": "EL MARGUA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8407,"nombre": "LA LOMA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8408,"nombre": "LA MESA, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8409,"nombre": "ROMAN, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8410,"nombre": "ALTO DEL ORO, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8411,"nombre": "SARARITO, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8412,"nombre": "SAMORE, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8413,"nombre": "EL CEIBAL, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8414,"nombre": "LA UNION, TOLEDO, NORTE DE SANTANDER"},
    {"id": 8415,"nombre": "SANTIAGO, SANTIAGO, NORTE DE SANTANDER"},
    {"id": 8416,"nombre": "LA CACAHULA, SANTIAGO, NORTE DE SANTANDER"},
    {"id": 8417,"nombre": "LOS NARANJOS, SANTIAGO, NORTE DE SANTANDER"},
    {"id": 8418,"nombre": "SAN CAYETANO, SAN CAYETANO, NORTE DE SANTANDER"},
    {"id": 8419,"nombre": "CORNEJO, SAN CAYETANO, NORTE DE SANTANDER"},
    {"id": 8420,"nombre": "URIMACO, SAN CAYETANO, NORTE DE SANTANDER"},
    {"id": 8421,"nombre": "MORRETON, SAN CAYETANO, NORTE DE SANTANDER"},
    {"id": 8422,"nombre": "PUERTO SANTANDER, PUERTO SANTANDER, NORTE DE SANTANDER"},
    {"id": 8423,"nombre": "OCAÑA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8424,"nombre": "AGUA LA VIRGEN, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8425,"nombre": "AGUAS CLARAS, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8426,"nombre": "ALTO DE LOS PATIOS, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8427,"nombre": "ALTO DEL LUCERO, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8428,"nombre": "OTARE, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8429,"nombre": "BUENAVISTA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8430,"nombre": "CERRO DE LAS FLORES, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8431,"nombre": "LA ERMITA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8432,"nombre": "LA FLORESTA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8433,"nombre": "LOMA LARGA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8434,"nombre": "MARIQUITA, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8435,"nombre": "PUEBLO NUEVO, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8436,"nombre": "EL PALMAR, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8437,"nombre": "GUAYABAL, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8438,"nombre": "SINAGU, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8439,"nombre": "ESPIRITU SANTO, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8440,"nombre": "LLANO DE LOS TRIGOS, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8441,"nombre": "LAS CHIRCAS, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8442,"nombre": "PALO GRANDE, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8443,"nombre": "PORTACHUELO, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8444,"nombre": "VENADILLO, OCAÑA, NORTE DE SANTANDER"},
    {"id": 8445,"nombre": "ISCUANDE, SANTA BARBARA, NARIÑO"},
    {"id": 8446,"nombre": "ATANASIO GIRARDOT, SANTA BARBARA, NARIÑO"},
    {"id": 8447,"nombre": "CHANZARA, SANTA BARBARA, NARIÑO"},
    {"id": 8448,"nombre": "PACIFICO, SANTA BARBARA, NARIÑO"},
    {"id": 8449,"nombre": "SANABRIA, SANTA BARBARA, NARIÑO"},
    {"id": 8450,"nombre": "SANTANDER, SANTA BARBARA, NARIÑO"},
    {"id": 8451,"nombre": "FRANCISCO DE PARADA, SANTA BARBARA, NARIÑO"},
    {"id": 8452,"nombre": "TOMAS CIPRIANO DE  MOSQUERA, SANTA BARBARA, NARIÑO"},
    {"id": 8453,"nombre": "PALOMINO, SANTA BARBARA, NARIÑO"},
    {"id": 8454,"nombre": "SAN JOSE, SANTA BARBARA, NARIÑO"},
    {"id": 8455,"nombre": "SAN SEBASTIAN DE BELALCAZAR, SANTA BARBARA, NARIÑO"},
    {"id": 8456,"nombre": "CUERBAL, SANTA BARBARA, NARIÑO"},
    {"id": 8457,"nombre": "JUANCHILLO, SANTA BARBARA, NARIÑO"},
    {"id": 8458,"nombre": "LA ENSENADA, SANTA BARBARA, NARIÑO"},
    {"id": 8459,"nombre": "CHICO PEREZ, SANTA BARBARA, NARIÑO"},
    {"id": 8460,"nombre": "LAS MARIAS, SANTA BARBARA, NARIÑO"},
    {"id": 8461,"nombre": "SANTA RITA, SANTA BARBARA, NARIÑO"},
    {"id": 8462,"nombre": "SAN PABLO, SAN PABLO, NARIÑO"},
    {"id": 8463,"nombre": "BRICENO, SAN PABLO, NARIÑO"},
    {"id": 8464,"nombre": "LA CANADA, SAN PABLO, NARIÑO"},
    {"id": 8465,"nombre": "LA CHORRERA, SAN PABLO, NARIÑO"},
    {"id": 8466,"nombre": "YUNGUILLA, SAN PABLO, NARIÑO"},
    {"id": 8467,"nombre": "CHILCAL, SAN PABLO, NARIÑO"},
    {"id": 8468,"nombre": "SAN BERNARDO, SAN BERNARDO, NARIÑO"},
    {"id": 8469,"nombre": "RICAURTE, RICAURTE, NARIÑO"},
    {"id": 8470,"nombre": "NULPE ALTO, RICAURTE, NARIÑO"},
    {"id": 8471,"nombre": "NULPE MEDIO, RICAURTE, NARIÑO"},
    {"id": 8472,"nombre": "OSPINA PEREZ, RICAURTE, NARIÑO"},
    {"id": 8473,"nombre": "SAN ISIDRO, RICAURTE, NARIÑO"},
    {"id": 8474,"nombre": "VEGAS, RICAURTE, NARIÑO"},
    {"id": 8475,"nombre": "RAMOS, RICAURTE, NARIÑO"},
    {"id": 8476,"nombre": "PIALAPI, RICAURTE, NARIÑO"},
    {"id": 8477,"nombre": "CHAMBU, RICAURTE, NARIÑO"},
    {"id": 8478,"nombre": "CUAIQUIER VIEJO, RICAURTE, NARIÑO"},
    {"id": 8479,"nombre": "CUESBI CARRETERA, RICAURTE, NARIÑO"},
    {"id": 8480,"nombre": "SAN FRANCISCO, RICAURTE, NARIÑO"},
    {"id": 8481,"nombre": "VILLA NUEVA, RICAURTE, NARIÑO"},
    {"id": 8482,"nombre": "PROVIDENCIA, PROVIDENCIA, NARIÑO"},
    {"id": 8483,"nombre": "GUADRAHUMA, PROVIDENCIA, NARIÑO"},
    {"id": 8484,"nombre": "NARIÑO, NARIÑO, NARIÑO"},
    {"id": 8485,"nombre": "MOSQUERA, MOSQUERA, NARIÑO"},
    {"id": 8486,"nombre": "GABRIEL TURBAY COCALITO, MOSQUERA, NARIÑO"},
    {"id": 8487,"nombre": "MORALES OLAYA, MOSQUERA, NARIÑO"},
    {"id": 8488,"nombre": "NARIÑO GICRILLA, MOSQUERA, NARIÑO"},
    {"id": 8489,"nombre": "COCAL, MOSQUERA, NARIÑO"},
    {"id": 8490,"nombre": "COCALITO, MOSQUERA, NARIÑO"},
    {"id": 8491,"nombre": "FIRME LOS CIFUENTES, MOSQUERA, NARIÑO"},
    {"id": 8492,"nombre": "SAN ANDRES (BOCAS DE GUANDIPA), MOSQUERA, NARIÑO"},
    {"id": 8493,"nombre": "PUEBLO NUEVO (ATANASIO GIRARDOT), MOSQUERA, NARIÑO"},
    {"id": 8494,"nombre": "GARCERO, MOSQUERA, NARIÑO"},
    {"id": 8495,"nombre": "EL BAJITO DE ECHANDIA, MOSQUERA, NARIÑO"},
    {"id": 8496,"nombre": "PAYAN, MAGUI, NARIÑO"},
    {"id": 8497,"nombre": "BOLIVAR, MAGUI, NARIÑO"},
    {"id": 8498,"nombre": "CONCORDIA, MAGUI, NARIÑO"},
    {"id": 8499,"nombre": "NANSALBID, MAGUI, NARIÑO"},
    {"id": 8500,"nombre": "PALACIO, MAGUI, NARIÑO"},
    {"id": 8501,"nombre": "PIRAGUA GUALPI, MAGUI, NARIÑO"},
    {"id": 8502,"nombre": "RICAURTE, MAGUI, NARIÑO"},
    {"id": 8503,"nombre": "SAN LUIS, MAGUI, NARIÑO"},
    {"id": 8504,"nombre": "TABUJITO, MAGUI, NARIÑO"},
    {"id": 8505,"nombre": "EL TRUENO, MAGUI, NARIÑO"},
    {"id": 8506,"nombre": "SAN LUISITO, MAGUI, NARIÑO"},
    {"id": 8507,"nombre": "LA LOMA, MAGUI, NARIÑO"},
    {"id": 8508,"nombre": "SANTANDER (ROSA), MAGUI, NARIÑO"},
    {"id": 8509,"nombre": "LAS BRISAS HAMBURGO, MAGUI, NARIÑO"},
    {"id": 8510,"nombre": "BELLA VISTA, MAGUI, NARIÑO"},
    {"id": 8511,"nombre": "CAMPO ALEGRE, MAGUI, NARIÑO"},
    {"id": 8512,"nombre": "LAS MERCEDES, MAGUI, NARIÑO"},
    {"id": 8513,"nombre": "SAN JOSE, MAGUI, NARIÑO"},
    {"id": 8514,"nombre": "SANTA CATALINA, MAGUI, NARIÑO"},
    {"id": 8515,"nombre": "EL CARMELO, MAGUI, NARIÑO"},
    {"id": 8516,"nombre": "LA UNION, LA UNION, NARIÑO"},
    {"id": 8517,"nombre": "SANTANDER, LA UNION, NARIÑO"},
    {"id": 8518,"nombre": "CUSILLO ALTO, LA UNION, NARIÑO"},
    {"id": 8519,"nombre": "LA CALDERA, LA UNION, NARIÑO"},
    {"id": 8520,"nombre": "QUIROS, LA UNION, NARIÑO"},
    {"id": 8521,"nombre": "PRADERA, LA UNION, NARIÑO"},
    {"id": 8522,"nombre": "HIGUERONES, LA UNION, NARIÑO"},
    {"id": 8523,"nombre": "BUENOS AIRES, LA UNION, NARIÑO"},
    {"id": 8524,"nombre": "PENA BLANCA, LA UNION, NARIÑO"},
    {"id": 8525,"nombre": "CHILCAL, LA UNION, NARIÑO"},
    {"id": 8526,"nombre": "REYES, LA UNION, NARIÑO"},
    {"id": 8527,"nombre": "OJO DE OSO, LA UNION, NARIÑO"},
    {"id": 8528,"nombre": "LA PLAYA, LA UNION, NARIÑO"},
    {"id": 8529,"nombre": "CONTADERO, LA UNION, NARIÑO"},
    {"id": 8530,"nombre": "ALPUJARRA, LA UNION, NARIÑO"},
    {"id": 8531,"nombre": "EL SAUCE, LA UNION, NARIÑO"},
    {"id": 8532,"nombre": "LA CANADA, LA UNION, NARIÑO"},
    {"id": 8533,"nombre": "LAS CUCHILLAS, LA UNION, NARIÑO"},
    {"id": 8534,"nombre": "OLIVOS, LA UNION, NARIÑO"},
    {"id": 8535,"nombre": "JACOBA, LA UNION, NARIÑO"},
    {"id": 8536,"nombre": "CUSILLO BAJO, LA UNION, NARIÑO"},
    {"id": 8537,"nombre": "LA CASTILLA, LA UNION, NARIÑO"},
    {"id": 8538,"nombre": "OJO DE AGUA, LA UNION, NARIÑO"},
    {"id": 8539,"nombre": "RINCON CUSILLO, LA UNION, NARIÑO"},
    {"id": 8540,"nombre": "CHAUGUARUCO, LA UNION, NARIÑO"},
    {"id": 8541,"nombre": "EL PELIGRO, LA UNION, NARIÑO"},
    {"id": 8542,"nombre": "PALOVERDE, LA UNION, NARIÑO"},
    {"id": 8543,"nombre": "SANTA TERESA BAJO, LA UNION, NARIÑO"},
    {"id": 8544,"nombre": "EL TAMBO, EL TAMBO, NARIÑO"},
    {"id": 8545,"nombre": "SAN PEDRO, EL TAMBO, NARIÑO"},
    {"id": 8546,"nombre": "RICAURTE, EL TAMBO, NARIÑO"},
    {"id": 8547,"nombre": "TANGUANA, EL TAMBO, NARIÑO"},
    {"id": 8548,"nombre": "EL PENOL, EL PEÑOL, NARIÑO"},
    {"id": 8549,"nombre": "LAS COCHAS, EL PEÑOL, NARIÑO"},
    {"id": 8550,"nombre": "SAN FRANCISCO, EL PEÑOL, NARIÑO"},
    {"id": 8551,"nombre": "EL PENOL VIEJO, EL PEÑOL, NARIÑO"},
    {"id": 8552,"nombre": "CHACHAGUI, CHACHAGÜI, NARIÑO"},
    {"id": 8553,"nombre": "CORDOBA, CORDOBA, NARIÑO"},
    {"id": 8554,"nombre": "ARRAYANES, CORDOBA, NARIÑO"},
    {"id": 8555,"nombre": "LLORENTE, CORDOBA, NARIÑO"},
    {"id": 8556,"nombre": "PAYAN, CORDOBA, NARIÑO"},
    {"id": 8557,"nombre": "SANTANDER, CORDOBA, NARIÑO"},
    {"id": 8558,"nombre": "SANTA BRIGIDA, CORDOBA, NARIÑO"},
    {"id": 8559,"nombre": "GENOVA, COLON, NARIÑO"},
    {"id": 8560,"nombre": "GUAITARILLA, COLON, NARIÑO"},
    {"id": 8561,"nombre": "LA PLATA, COLON, NARIÑO"},
    {"id": 8562,"nombre": "VILLANUEVA, COLON, NARIÑO"},
    {"id": 8563,"nombre": "EL MACAL, COLON, NARIÑO"},
    {"id": 8564,"nombre": "SAN CARLOS, COLON, NARIÑO"},
    {"id": 8565,"nombre": "EL BORDO, COLON, NARIÑO"},
    {"id": 8566,"nombre": "BELEN, BELEN, NARIÑO"},
    {"id": 8567,"nombre": "SANTA ROSA, BELEN, NARIÑO"},
    {"id": 8568,"nombre": "SAN JOSE, ALBAN, NARIÑO"},
    {"id": 8569,"nombre": "CHAPIURCO, ALBAN, NARIÑO"},
    {"id": 8570,"nombre": "SAN ANTONIO DEL GUARANGAL, ALBAN, NARIÑO"},
    {"id": 8571,"nombre": "SAN BERNARDO, ALBAN, NARIÑO"},
    {"id": 8572,"nombre": "CAMPO BELLO, ALBAN, NARIÑO"},
    {"id": 8573,"nombre": "LA VEGA, ALBAN, NARIÑO"},
    {"id": 8574,"nombre": "EL TAMBO, ALBAN, NARIÑO"},
    {"id": 8575,"nombre": "EL SOCORRO, ALBAN, NARIÑO"},
    {"id": 8576,"nombre": "VISTA HERMOSA, VISTAHERMOSA, META"},
    {"id": 8577,"nombre": "CAMPO ALEGRE, VISTAHERMOSA, META"},
    {"id": 8578,"nombre": "PINALITO, VISTAHERMOSA, META"},
    {"id": 8579,"nombre": "MARACAIBO, VISTAHERMOSA, META"},
    {"id": 8580,"nombre": "CANO AMARILLO, VISTAHERMOSA, META"},
    {"id": 8581,"nombre": "PUERTO LUCAS, VISTAHERMOSA, META"},
    {"id": 8582,"nombre": "PUERTO LUCAS PARTE DERECHA, VISTAHERMOSA, META"},
    {"id": 8583,"nombre": "PUERTO ESPERANZA MARGEN DERECHA, VISTAHERMOSA, META"},
    {"id": 8584,"nombre": "PUERTO ESPERANZA MARGEN IZQUIERDA, VISTAHERMOSA, META"},
    {"id": 8585,"nombre": "SAN MARTIN, SAN MARTIN, META"},
    {"id": 8586,"nombre": "EL MEREY, SAN MARTIN, META"},
    {"id": 8587,"nombre": "REFORMA, SAN MARTIN, META"},
    {"id": 8588,"nombre": "RINCON BOLIVAR, SAN MARTIN, META"},
    {"id": 8589,"nombre": "BRISAS DEL MANACACIAS, SAN MARTIN, META"},
    {"id": 8590,"nombre": "RESTREPO, RESTREPO, META"},
    {"id": 8591,"nombre": "PUERTO RICO, PUERTO RICO, META"},
    {"id": 8592,"nombre": "PUERTO IRIS, PUERTO RICO, META"},
    {"id": 8593,"nombre": "LA LINDOSA, PUERTO RICO, META"},
    {"id": 8594,"nombre": "BARRANCO COLORADO, PUERTO RICO, META"},
    {"id": 8595,"nombre": "PUERTO TOLEDO, PUERTO RICO, META"},
    {"id": 8596,"nombre": "GUAMAL, GUAMAL, META"},
    {"id": 8597,"nombre": "HUMADEA, GUAMAL, META"},
    {"id": 8598,"nombre": "EL CARMEN, GUAMAL, META"},
    {"id": 8599,"nombre": "GRANADA, GRANADA, META"},
    {"id": 8600,"nombre": "CANAGUARO, GRANADA, META"},
    {"id": 8601,"nombre": "DOS QUEBRADAS, GRANADA, META"},
    {"id": 8602,"nombre": "LA PLAYA, GRANADA, META"},
    {"id": 8603,"nombre": "PUERTO CALDAS, GRANADA, META"},
    {"id": 8604,"nombre": "AGUAS CLARAS, GRANADA, META"},
    {"id": 8605,"nombre": "PUNTA BRAVA, GRANADA, META"},
    {"id": 8606,"nombre": "SAN SEBASTIAN DE BUENAVISTA, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8607,"nombre": "BUENAVISTA, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8608,"nombre": "EL COCO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8609,"nombre": "LA PACHA, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8610,"nombre": "LAS MARGARITAS, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8611,"nombre": "LOS GALVIS, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8612,"nombre": "MARIA ANTONIA, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8613,"nombre": "SAN RAFAEL, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8614,"nombre": "SANTA ROSA, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8615,"nombre": "TRONCOSITO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8616,"nombre": "TRONCOSO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8617,"nombre": "VENERO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8618,"nombre": "EL DIVIDIVE, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8619,"nombre": "EL SEIS, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8620,"nombre": "SABANAS DE PERALEJO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8621,"nombre": "JAIME, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8622,"nombre": "SAN VALENTIN, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8623,"nombre": "PUEBLO NUEVO, SAN SEBASTIAN DE BUENAVISTA, MAGDALENA"},
    {"id": 8624,"nombre": "SALAMINA, SALAMINA, MAGDALENA"},
    {"id": 8625,"nombre": "GUAIMARO, SALAMINA, MAGDALENA"},
    {"id": 8626,"nombre": "EL SALAO, SALAMINA, MAGDALENA"},
    {"id": 8627,"nombre": "LOMA DE SOLEDAD, SALAMINA, MAGDALENA"},
    {"id": 8628,"nombre": "PIJINO, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8629,"nombre": "CABRERA, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8630,"nombre": "FILADELFIA, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8631,"nombre": "SAN JOSE DE PREVENCION, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8632,"nombre": "CASA BLANCA, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8633,"nombre": "LA LUCHA, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8634,"nombre": "LA PLANADA, PIJIÑO DEL CARMEN, MAGDALENA"},
    {"id": 8635,"nombre": "GUAMAL, GUAMAL, MAGDALENA"},
    {"id": 8636,"nombre": "CASA DE TABLA, GUAMAL, MAGDALENA"},
    {"id": 8637,"nombre": "GUAIMARAL, GUAMAL, MAGDALENA"},
    {"id": 8638,"nombre": "HATOVIEJO, GUAMAL, MAGDALENA"},
    {"id": 8639,"nombre": "PEDREGOSA, GUAMAL, MAGDALENA"},
    {"id": 8640,"nombre": "LOS ANDES, GUAMAL, MAGDALENA"},
    {"id": 8641,"nombre": "MURILLO, GUAMAL, MAGDALENA"},
    {"id": 8642,"nombre": "PAM PAN, GUAMAL, MAGDALENA"},
    {"id": 8643,"nombre": "RICAURTE, GUAMAL, MAGDALENA"},
    {"id": 8644,"nombre": "SALVADORA, GUAMAL, MAGDALENA"},
    {"id": 8645,"nombre": "HURQUIJO, GUAMAL, MAGDALENA"},
    {"id": 8646,"nombre": "PLAYAS BLANCAS, GUAMAL, MAGDALENA"},
    {"id": 8647,"nombre": "SITIO NUEVO, GUAMAL, MAGDALENA"},
    {"id": 8648,"nombre": "CARRETERO, GUAMAL, MAGDALENA"},
    {"id": 8649,"nombre": "BELLAVISTA, GUAMAL, MAGDALENA"},
    {"id": 8650,"nombre": "GUACAMAYAL, GUAMAL, MAGDALENA"},
    {"id": 8651,"nombre": "SANTA TERESITA, GUAMAL, MAGDALENA"},
    {"id": 8652,"nombre": "SAN PEDRO, GUAMAL, MAGDALENA"},
    {"id": 8653,"nombre": "LAS FLORES, GUAMAL, MAGDALENA"},
    {"id": 8654,"nombre": "SAN ANTONIO, GUAMAL, MAGDALENA"},
    {"id": 8655,"nombre": "LA CEIBA, GUAMAL, MAGDALENA"},
    {"id": 8656,"nombre": "PAJARAL, GUAMAL, MAGDALENA"},
    {"id": 8657,"nombre": "PARACO, GUAMAL, MAGDALENA"},
    {"id": 8658,"nombre": "SAN ISIDRO, GUAMAL, MAGDALENA"},
    {"id": 8659,"nombre": "VILLA NUEVA, GUAMAL, MAGDALENA"},
    {"id": 8660,"nombre": "EL VEINTIOCHO, GUAMAL, MAGDALENA"},
    {"id": 8661,"nombre": "PUEBLO NUEVO, GUAMAL, MAGDALENA"},
    {"id": 8662,"nombre": "EL PINON, EL PIÑON, MAGDALENA"},
    {"id": 8663,"nombre": "CAMPO ALEGRE, EL PIÑON, MAGDALENA"},
    {"id": 8664,"nombre": "CANTAGALLAR, EL PIÑON, MAGDALENA"},
    {"id": 8665,"nombre": "CARRETO, EL PIÑON, MAGDALENA"},
    {"id": 8666,"nombre": "PLAYON DE OROZCO, EL PIÑON, MAGDALENA"},
    {"id": 8667,"nombre": "SABANAS, EL PIÑON, MAGDALENA"},
    {"id": 8668,"nombre": "SAN BASILIO, EL PIÑON, MAGDALENA"},
    {"id": 8669,"nombre": "TIO GOLLO, EL PIÑON, MAGDALENA"},
    {"id": 8670,"nombre": "VERANILLO, EL PIÑON, MAGDALENA"},
    {"id": 8671,"nombre": "LOS PATOS, EL PIÑON, MAGDALENA"},
    {"id": 8672,"nombre": "VASQUEZ, EL PIÑON, MAGDALENA"},
    {"id": 8673,"nombre": "LAS PALMAS, EL PIÑON, MAGDALENA"},
    {"id": 8674,"nombre": "LAS PAVITAS, EL PIÑON, MAGDALENA"},
    {"id": 8675,"nombre": "MONTERIA, EL PIÑON, MAGDALENA"},
    {"id": 8676,"nombre": "CONCORDIA, CONCORDIA, MAGDALENA"},
    {"id": 8677,"nombre": "BALSAMO, CONCORDIA, MAGDALENA"},
    {"id": 8678,"nombre": "BELLAVISTA, CONCORDIA, MAGDALENA"},
    {"id": 8679,"nombre": "ROSARIO DEL CHENGUE, CONCORDIA, MAGDALENA"},
    {"id": 8680,"nombre": "CHIVOLO, CHIBOLO, MAGDALENA"},
    {"id": 8681,"nombre": "LA CHINA, CHIBOLO, MAGDALENA"},
    {"id": 8682,"nombre": "PUEBLO NUEVO, CHIBOLO, MAGDALENA"},
    {"id": 8683,"nombre": "LA ESTRELLA, CHIBOLO, MAGDALENA"},
    {"id": 8684,"nombre": "VILLANUEVA, VILLANUEVA, LA GUAJIRA"},
    {"id": 8685,"nombre": "MANAURE, MANAURE, LA GUAJIRA"},
    {"id": 8686,"nombre": "AREMASAHIN, MANAURE, LA GUAJIRA"},
    {"id": 8687,"nombre": "MUSICHEHIN, MANAURE, LA GUAJIRA"},
    {"id": 8688,"nombre": "EL PAJARO, MANAURE, LA GUAJIRA"},
    {"id": 8689,"nombre": "SAN ANTONIO, MANAURE, LA GUAJIRA"},
    {"id": 8690,"nombre": "SANTA ROSA, MANAURE, LA GUAJIRA"},
    {"id": 8691,"nombre": "SHIRURE, MANAURE, LA GUAJIRA"},
    {"id": 8692,"nombre": "MAYAPO, MANAURE, LA GUAJIRA"},
    {"id": 8693,"nombre": "MANZANA, MANAURE, LA GUAJIRA"},
    {"id": 8694,"nombre": "LA GLORIA, MANAURE, LA GUAJIRA"},
    {"id": 8695,"nombre": "ALBANIA, ALBANIA, LA GUAJIRA"},
    {"id": 8696,"nombre": "CUESTECITAS, ALBANIA, LA GUAJIRA"},
    {"id": 8697,"nombre": "HUAREUAREN, ALBANIA, LA GUAJIRA"},
    {"id": 8698,"nombre": "LOS REMEDIOS, ALBANIA, LA GUAJIRA"},
    {"id": 8699,"nombre": "SANTA MARIA, SANTA MARIA, HUILA"},
    {"id": 8700,"nombre": "SAN JOAQUIN, SANTA MARIA, HUILA"},
    {"id": 8701,"nombre": "EL SOCORRO, SANTA MARIA, HUILA"},
    {"id": 8702,"nombre": "PALESTINA, PALESTINA, HUILA"},
    {"id": 8703,"nombre": "GUADALUPE, GUADALUPE, HUILA"},
    {"id": 8704,"nombre": "RESINA, GUADALUPE, HUILA"},
    {"id": 8705,"nombre": "MIRAFLORES, GUADALUPE, HUILA"},
    {"id": 8706,"nombre": "LOS CAUCHOS, GUADALUPE, HUILA"},
    {"id": 8707,"nombre": "POTRERILLOS, GUADALUPE, HUILA"},
    {"id": 8708,"nombre": "RIOSUCIO, RIOSUCIO, CHOCO"},
    {"id": 8709,"nombre": "BOCAS DE CURBARADO, RIOSUCIO, CHOCO"},
    {"id": 8710,"nombre": "JIGUAMIANDO, RIOSUCIO, CHOCO"},
    {"id": 8711,"nombre": "LA HONDA, RIOSUCIO, CHOCO"},
    {"id": 8712,"nombre": "LA LARGA, RIOSUCIO, CHOCO"},
    {"id": 8713,"nombre": "PEYE, RIOSUCIO, CHOCO"},
    {"id": 8714,"nombre": "SALAQUI, RIOSUCIO, CHOCO"},
    {"id": 8715,"nombre": "SAUTATA (PERANCHO), RIOSUCIO, CHOCO"},
    {"id": 8716,"nombre": "TAMBORAL, RIOSUCIO, CHOCO"},
    {"id": 8717,"nombre": "TRUANDO, RIOSUCIO, CHOCO"},
    {"id": 8718,"nombre": "VILLANUEVA, RIOSUCIO, CHOCO"},
    {"id": 8719,"nombre": "ALTO RIOSUCIO, RIOSUCIO, CHOCO"},
    {"id": 8720,"nombre": "CHINTADO, RIOSUCIO, CHOCO"},
    {"id": 8721,"nombre": "LA TERESITA, RIOSUCIO, CHOCO"},
    {"id": 8722,"nombre": "LA RAYA, RIOSUCIO, CHOCO"},
    {"id": 8723,"nombre": "LA TRAVESIA, RIOSUCIO, CHOCO"},
    {"id": 8724,"nombre": "PERANCHITO, RIOSUCIO, CHOCO"},
    {"id": 8725,"nombre": "LA ISLETA, RIOSUCIO, CHOCO"},
    {"id": 8726,"nombre": "VENECIA, RIOSUCIO, CHOCO"},
    {"id": 8727,"nombre": "PUENTE AMERICA - CACARICA, RIOSUCIO, CHOCO"},
    {"id": 8728,"nombre": "TAPARAL, RIOSUCIO, CHOCO"},
    {"id": 8729,"nombre": "PEDEGUITA, RIOSUCIO, CHOCO"},
    {"id": 8730,"nombre": "MONTANO, RIOSUCIO, CHOCO"},
    {"id": 8731,"nombre": "SAN FRANCISCO, SAN FRANCISCO, CUNDINAMARCA"},
    {"id": 8732,"nombre": "LA MAGOLA, SAN FRANCISCO, CUNDINAMARCA"},
    {"id": 8733,"nombre": "SAN CAYETANO, SAN CAYETANO, CUNDINAMARCA"},
    {"id": 8734,"nombre": "CAMANCHA, SAN CAYETANO, CUNDINAMARCA"},
    {"id": 8735,"nombre": "CUIBUCO, SAN CAYETANO, CUNDINAMARCA"},
    {"id": 8736,"nombre": "LAS MERCEDES, SAN CAYETANO, CUNDINAMARCA"},
    {"id": 8737,"nombre": "PINIPAY, SAN CAYETANO, CUNDINAMARCA"},
    {"id": 8738,"nombre": "SAN BERNARDO, SAN BERNARDO, CUNDINAMARCA"},
    {"id": 8739,"nombre": "EL PILAR, SAN BERNARDO, CUNDINAMARCA"},
    {"id": 8740,"nombre": "SANTA RITA, SAN BERNARDO, CUNDINAMARCA"},
    {"id": 8741,"nombre": "PORTONES, SAN BERNARDO, CUNDINAMARCA"},
    {"id": 8742,"nombre": "LAUREL BAJO, SAN BERNARDO, CUNDINAMARCA"},
    {"id": 8743,"nombre": "SAN ANTONIO DEL TEQUENDAMA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8744,"nombre": "SANTANDERCITO, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8745,"nombre": "ZARAGOZA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8746,"nombre": "LA MARIA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8747,"nombre": "LAS ANGUSTIAS, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8748,"nombre": "PIEDRA AZUL, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8749,"nombre": "QUEBRADA GRANDE, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8750,"nombre": "LA RAMBLA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8751,"nombre": "PUEBLO NUEVO, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8752,"nombre": "EL CAJON, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8753,"nombre": "LAGUNA GRANDE, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8754,"nombre": "CUSIO, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8755,"nombre": "BELLAVISTA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8756,"nombre": "PRADILLA, SAN ANTONIO DEL TEQUENDAMA, CUNDINAMARCA"},
    {"id": 8757,"nombre": "RICAURTE, RICAURTE, CUNDINAMARCA"},
    {"id": 8758,"nombre": "MANUEL SUR, RICAURTE, CUNDINAMARCA"},
    {"id": 8759,"nombre": "EL PASO, RICAURTE, CUNDINAMARCA"},
    {"id": 8760,"nombre": "EL PORTAL, RICAURTE, CUNDINAMARCA"},
    {"id": 8761,"nombre": "LAS VARAS, RICAURTE, CUNDINAMARCA"},
    {"id": 8762,"nombre": "MANUEL NORTE, RICAURTE, CUNDINAMARCA"},
    {"id": 8763,"nombre": "SAN MARCOS POBLADO, RICAURTE, CUNDINAMARCA"},
    {"id": 8764,"nombre": "SAN MARTIN, RICAURTE, CUNDINAMARCA"},
    {"id": 8765,"nombre": "OSPINA PEREZ, VENECIA, CUNDINAMARCA"},
    {"id": 8766,"nombre": "APOSENTOS, VENECIA, CUNDINAMARCA"},
    {"id": 8767,"nombre": "AGUA DULCE, VENECIA, CUNDINAMARCA"},
    {"id": 8768,"nombre": "LA REFORMA, VENECIA, CUNDINAMARCA"},
    {"id": 8769,"nombre": "QUEBRADA GRANDE, VENECIA, CUNDINAMARCA"},
    {"id": 8770,"nombre": "EL TREBOL, VENECIA, CUNDINAMARCA"},
    {"id": 8771,"nombre": "NARIÑO, NARIÑO, CUNDINAMARCA"},
    {"id": 8772,"nombre": "LA REFORMA BUSCAVIDA, NARIÑO, CUNDINAMARCA"},
    {"id": 8773,"nombre": "MOSQUERA, MOSQUERA, CUNDINAMARCA"},
    {"id": 8774,"nombre": "LOS PUENTES, MOSQUERA, CUNDINAMARCA"},
    {"id": 8775,"nombre": "LA VEGA, LA VEGA, CUNDINAMARCA"},
    {"id": 8776,"nombre": "LA LIBERTAD, LA VEGA, CUNDINAMARCA"},
    {"id": 8777,"nombre": "EL VINO, LA VEGA, CUNDINAMARCA"},
    {"id": 8778,"nombre": "HOYA GRANDE, LA VEGA, CUNDINAMARCA"},
    {"id": 8779,"nombre": "LA CAMPINA, LA VEGA, CUNDINAMARCA"},
    {"id": 8780,"nombre": "PATIO BONITO, LA VEGA, CUNDINAMARCA"},
    {"id": 8781,"nombre": "LA PENA, LA PEÑA, CUNDINAMARCA"},
    {"id": 8782,"nombre": "GRANADA, GRANADA, CUNDINAMARCA"},
    {"id": 8783,"nombre": "SAN RAIMUNDO, GRANADA, CUNDINAMARCA"},
    {"id": 8784,"nombre": "EL PENON, EL PEÑON, CUNDINAMARCA"},
    {"id": 8785,"nombre": "GUAYABAL, EL PEÑON, CUNDINAMARCA"},
    {"id": 8786,"nombre": "TALAUTA, EL PEÑON, CUNDINAMARCA"},
    {"id": 8787,"nombre": "CABRERA, CABRERA, CUNDINAMARCA"},
    {"id": 8788,"nombre": "APOSENTOS, CABRERA, CUNDINAMARCA"},
    {"id": 8789,"nombre": "SANTA LUCIA, CABRERA, CUNDINAMARCA"},
    {"id": 8790,"nombre": "SUMAPAZ LA PLAYA, CABRERA, CUNDINAMARCA"},
    {"id": 8791,"nombre": "SIMON BOLIVAR, CABRERA, CUNDINAMARCA"},
    {"id": 8792,"nombre": "ALBAN, ALBAN, CUNDINAMARCA"},
    {"id": 8793,"nombre": "CHIMBE, ALBAN, CUNDINAMARCA"},
    {"id": 8794,"nombre": "PANTANILLO, ALBAN, CUNDINAMARCA"},
    {"id": 8795,"nombre": "LA MARIA, ALBAN, CUNDINAMARCA"},
    {"id": 8796,"nombre": "SAN CARLOS, SAN CARLOS, CORDOBA"},
    {"id": 8797,"nombre": "EL CAMPANO, SAN CARLOS, CORDOBA"},
    {"id": 8798,"nombre": "CARRIZAL, SAN CARLOS, CORDOBA"},
    {"id": 8799,"nombre": "GUACHARACAL, SAN CARLOS, CORDOBA"},
    {"id": 8800,"nombre": "SANTA ROSA, SAN CARLOS, CORDOBA"},
    {"id": 8801,"nombre": "REMEDIO POBRE, SAN CARLOS, CORDOBA"},
    {"id": 8802,"nombre": "CABUYA, SAN CARLOS, CORDOBA"},
    {"id": 8803,"nombre": "CALLEMAR, SAN CARLOS, CORDOBA"},
    {"id": 8804,"nombre": "CIENAGUITA, SAN CARLOS, CORDOBA"},
    {"id": 8805,"nombre": "EL HATO, SAN CARLOS, CORDOBA"},
    {"id": 8806,"nombre": "SAN MIGUEL, SAN CARLOS, CORDOBA"},
    {"id": 8807,"nombre": "EL CHARCO, SAN CARLOS, CORDOBA"},
    {"id": 8808,"nombre": "ARRIBA, SAN CARLOS, CORDOBA"},
    {"id": 8809,"nombre": "ARROYO GRANDE, SAN CARLOS, CORDOBA"},
    {"id": 8810,"nombre": "SAN CARLOS, SAN CARLOS, CORDOBA"},
    {"id": 8811,"nombre": "SAN ANDRES DE SOTAVENTO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8812,"nombre": "BARBACOAS, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8813,"nombre": "CALLE LARGA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8814,"nombre": "EL BANCO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8815,"nombre": "FLECHAS, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8816,"nombre": "LOS CARRETOS, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8817,"nombre": "LOS VIDALES, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8818,"nombre": "MOLINA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8819,"nombre": "NUEVA ESTRELLA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8820,"nombre": "PUEBLECITO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8821,"nombre": "SAN JUAN DE LA CRUZ, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8822,"nombre": "TUCHIN, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8823,"nombre": "PLAZA BONITA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8824,"nombre": "LAS CASITAS, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8825,"nombre": "LOS CASTILLOS, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8826,"nombre": "LOS GUAYACANES, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8827,"nombre": "SABANAL, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8828,"nombre": "VILLANUEVA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8829,"nombre": "CARRETAL, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8830,"nombre": "MARTILLO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8831,"nombre": "EL CONTENTO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8832,"nombre": "CRUZ CHIQUITA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8833,"nombre": "JEJEN, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8834,"nombre": "ALEMANIA, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8835,"nombre": "CRUZ DE GUAYABO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8836,"nombre": "EL HOYAL, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8837,"nombre": "EL ALGODONCILLO, SAN ANDRES SOTAVENTO, CORDOBA"},
    {"id": 8838,"nombre": "MONITOS, MOÑITOS, CORDOBA"},
    {"id": 8839,"nombre": "RIO CEDRO, MOÑITOS, CORDOBA"},
    {"id": 8840,"nombre": "SANTANDER DE LA CRUZ, MOÑITOS, CORDOBA"},
    {"id": 8841,"nombre": "BROQUELES, MOÑITOS, CORDOBA"},
    {"id": 8842,"nombre": "LA RADA, MOÑITOS, CORDOBA"},
    {"id": 8843,"nombre": "LAS MUJERES, MOÑITOS, CORDOBA"},
    {"id": 8844,"nombre": "MANGLE, MOÑITOS, CORDOBA"},
    {"id": 8845,"nombre": "PERPETUO SOCORRO, MOÑITOS, CORDOBA"},
    {"id": 8846,"nombre": "PUEBLITO, MOÑITOS, CORDOBA"},
    {"id": 8847,"nombre": "SAN RAFAEL, MOÑITOS, CORDOBA"},
    {"id": 8848,"nombre": "CHIMA, CHIMA, CORDOBA"},
    {"id": 8849,"nombre": "ARACHE, CHIMA, CORDOBA"},
    {"id": 8850,"nombre": "CAMPO BELLO, CHIMA, CORDOBA"},
    {"id": 8851,"nombre": "CAROLINA, CHIMA, CORDOBA"},
    {"id": 8852,"nombre": "COROZALITO, CHIMA, CORDOBA"},
    {"id": 8853,"nombre": "PUNTA VERDE, CHIMA, CORDOBA"},
    {"id": 8854,"nombre": "SITIO VIEJO, CHIMA, CORDOBA"},
    {"id": 8855,"nombre": "SABANACOSTA, CHIMA, CORDOBA"},
    {"id": 8856,"nombre": "CAMINO LA LAGUNA, CHIMA, CORDOBA"},
    {"id": 8857,"nombre": "CORRALITO, CHIMA, CORDOBA"},
    {"id": 8858,"nombre": "EL BRILLANTE, CHIMA, CORDOBA"},
    {"id": 8859,"nombre": "EL CERRO, CHIMA, CORDOBA"},
    {"id": 8860,"nombre": "LAS DELICIAS, CHIMA, CORDOBA"},
    {"id": 8861,"nombre": "PIMENTAL, CHIMA, CORDOBA"},
    {"id": 8862,"nombre": "SABANAL, CHIMA, CORDOBA"},
    {"id": 8863,"nombre": "SAN JOSE, CHIMA, CORDOBA"},
    {"id": 8864,"nombre": "BUENAVISTA, BUENAVISTA, CORDOBA"},
    {"id": 8865,"nombre": "TIERRA SANTA, BUENAVISTA, CORDOBA"},
    {"id": 8866,"nombre": "VILLA FATIMA, BUENAVISTA, CORDOBA"},
    {"id": 8867,"nombre": "BELEN, BUENAVISTA, CORDOBA"},
    {"id": 8868,"nombre": "NUEVA ESTACION, BUENAVISTA, CORDOBA"},
    {"id": 8869,"nombre": "PUERTO CORDOBA, BUENAVISTA, CORDOBA"},
    {"id": 8870,"nombre": "EL PARAISO, BUENAVISTA, CORDOBA"},
    {"id": 8871,"nombre": "COTORRA, BUENAVISTA, CORDOBA"},
    {"id": 8872,"nombre": "CIENAGA DE SAMBO, BUENAVISTA, CORDOBA"},
    {"id": 8873,"nombre": "MEJOR ESQUINA, BUENAVISTA, CORDOBA"},
    {"id": 8874,"nombre": "EL VIAJANO, BUENAVISTA, CORDOBA"},
    {"id": 8875,"nombre": "VERACRUZ, BUENAVISTA, CORDOBA"},
    {"id": 8876,"nombre": "SANTA CLARA, BUENAVISTA, CORDOBA"},
    {"id": 8877,"nombre": "SANTA FE DEL ARCIAL, BUENAVISTA, CORDOBA"},
    {"id": 8878,"nombre": "SAN MARTIN, SAN MARTIN, CESAR"},
    {"id": 8879,"nombre": "AGUAS BLANCAS, SAN MARTIN, CESAR"},
    {"id": 8880,"nombre": "EL BARRO, SAN MARTIN, CESAR"},
    {"id": 8881,"nombre": "MINAS, SAN MARTIN, CESAR"},
    {"id": 8882,"nombre": "PUERTO OCULTO, SAN MARTIN, CESAR"},
    {"id": 8883,"nombre": "SAN JOSE DE LAS AMERICAS, SAN MARTIN, CESAR"},
    {"id": 8884,"nombre": "CANDELIA, SAN MARTIN, CESAR"},
    {"id": 8885,"nombre": "TERRAPLEN, SAN MARTIN, CESAR"},
    {"id": 8886,"nombre": "LA CURVA, SAN MARTIN, CESAR"},
    {"id": 8887,"nombre": "TORCOROMA, SAN MARTIN, CESAR"},
    {"id": 8888,"nombre": "CUATRO BOCAS, SAN MARTIN, CESAR"},
    {"id": 8889,"nombre": "LOS BAGRES, SAN MARTIN, CESAR"},
    {"id": 8890,"nombre": "PITA LIMON, SAN MARTIN, CESAR"},
    {"id": 8891,"nombre": "BUENOS AIRES, SAN MARTIN, CESAR"},
    {"id": 8892,"nombre": "EL DIVISO, SAN MARTIN, CESAR"},
    {"id": 8893,"nombre": "LAS SALINAS, SAN MARTIN, CESAR"},
    {"id": 8894,"nombre": "ROBLES, LA PAZ, CESAR"},
    {"id": 8895,"nombre": "LOS ENCANTOS, LA PAZ, CESAR"},
    {"id": 8896,"nombre": "SAN JOSE DEL ORIENTE, LA PAZ, CESAR"},
    {"id": 8897,"nombre": "VARAS BLANCAS, LA PAZ, CESAR"},
    {"id": 8898,"nombre": "SAN JOSE DE ORIENTE - BETANIA, LA PAZ, CESAR"},
    {"id": 8899,"nombre": "MINGUILLO, LA PAZ, CESAR"},
    {"id": 8900,"nombre": "BALCON DEL CESAR, MANAURE, CESAR"},
    {"id": 8901,"nombre": "SUCRE, SUCRE, CAUCA"},
    {"id": 8902,"nombre": "EL PARAISO, SUCRE, CAUCA"},
    {"id": 8903,"nombre": "CASCADAS, SUCRE, CAUCA"},
    {"id": 8904,"nombre": "CRUCERO BELLO, SUCRE, CAUCA"},
    {"id": 8905,"nombre": "EL CARMELO, SUCRE, CAUCA"},
    {"id": 8906,"nombre": "EL FRESNO, SUCRE, CAUCA"},
    {"id": 8907,"nombre": "EL RETIRO, SUCRE, CAUCA"},
    {"id": 8908,"nombre": "EL TESORO, SUCRE, CAUCA"},
    {"id": 8909,"nombre": "LA CEJA, SUCRE, CAUCA"},
    {"id": 8910,"nombre": "LA CUMBRE, SUCRE, CAUCA"},
    {"id": 8911,"nombre": "MAZAMORRAS, SUCRE, CAUCA"},
    {"id": 8912,"nombre": "TEQUENDAMA, SUCRE, CAUCA"},
    {"id": 8913,"nombre": "SUAREZ, SUAREZ, CAUCA"},
    {"id": 8914,"nombre": "EL HATO, SUAREZ, CAUCA"},
    {"id": 8915,"nombre": "LA MESETA, SUAREZ, CAUCA"},
    {"id": 8916,"nombre": "ASNAZU, SUAREZ, CAUCA"},
    {"id": 8917,"nombre": "GELIMA LA TOMA, SUAREZ, CAUCA"},
    {"id": 8918,"nombre": "MATECANA, SUAREZ, CAUCA"},
    {"id": 8919,"nombre": "BELLAVISTA, SUAREZ, CAUCA"},
    {"id": 8920,"nombre": "LA ROMA, SUAREZ, CAUCA"},
    {"id": 8921,"nombre": "LA BETULIA, SUAREZ, CAUCA"},
    {"id": 8922,"nombre": "MINDALA, SUAREZ, CAUCA"},
    {"id": 8923,"nombre": "AGUA CLARA CANELAR, SUAREZ, CAUCA"},
    {"id": 8924,"nombre": "SANTA ROSA, SANTA ROSA, CAUCA"},
    {"id": 8925,"nombre": "DESCANSE, SANTA ROSA, CAUCA"},
    {"id": 8926,"nombre": "EL CARMELO, SANTA ROSA, CAUCA"},
    {"id": 8927,"nombre": "NAPOLES, SANTA ROSA, CAUCA"},
    {"id": 8928,"nombre": "SANTA CLARA, SANTA ROSA, CAUCA"},
    {"id": 8929,"nombre": "SAN JUAN DE VILLALOBOS, SANTA ROSA, CAUCA"},
    {"id": 8930,"nombre": "EL CEDRO, SANTA ROSA, CAUCA"},
    {"id": 8931,"nombre": "CAMPOALEGRE, SANTA ROSA, CAUCA"},
    {"id": 8932,"nombre": "EL TAMBOR, SANTA ROSA, CAUCA"},
    {"id": 8933,"nombre": "PRIMAVERAS, SANTA ROSA, CAUCA"},
    {"id": 8934,"nombre": "SAN JUAN, SANTA ROSA, CAUCA"},
    {"id": 8935,"nombre": "VILLA MOSQUERA, SANTA ROSA, CAUCA"},
    {"id": 8936,"nombre": "CURIACO, SANTA ROSA, CAUCA"},
    {"id": 8937,"nombre": "BELALCAZAR, PAEZ, CAUCA"},
    {"id": 8938,"nombre": "ARAUJO, PAEZ, CAUCA"},
    {"id": 8939,"nombre": "AVIRAMA, PAEZ, CAUCA"},
    {"id": 8940,"nombre": "COHETANDO, PAEZ, CAUCA"},
    {"id": 8941,"nombre": "CHINAS, PAEZ, CAUCA"},
    {"id": 8942,"nombre": "EL COLORADO, PAEZ, CAUCA"},
    {"id": 8943,"nombre": "HUILA, PAEZ, CAUCA"},
    {"id": 8944,"nombre": "ITAIBE, PAEZ, CAUCA"},
    {"id": 8945,"nombre": "LA ESTRELLA, PAEZ, CAUCA"},
    {"id": 8946,"nombre": "LAME, PAEZ, CAUCA"},
    {"id": 8947,"nombre": "LA PALMA, PAEZ, CAUCA"},
    {"id": 8948,"nombre": "MOSOCO, PAEZ, CAUCA"},
    {"id": 8949,"nombre": "RICAURTE, PAEZ, CAUCA"},
    {"id": 8950,"nombre": "RIOCHIQUITO, PAEZ, CAUCA"},
    {"id": 8951,"nombre": "SAN LUIS, PAEZ, CAUCA"},
    {"id": 8952,"nombre": "TALAGA, PAEZ, CAUCA"},
    {"id": 8953,"nombre": "TOEZ, PAEZ, CAUCA"},
    {"id": 8954,"nombre": "TOGOIMA, PAEZ, CAUCA"},
    {"id": 8955,"nombre": "VILLA RODRIGUEZ, PAEZ, CAUCA"},
    {"id": 8956,"nombre": "VITONCO, PAEZ, CAUCA"},
    {"id": 8957,"nombre": "LA TROJA, PAEZ, CAUCA"},
    {"id": 8958,"nombre": "ARANZAZU, PAEZ, CAUCA"},
    {"id": 8959,"nombre": "EL CABUYO, PAEZ, CAUCA"},
    {"id": 8960,"nombre": "LA CEJA, PAEZ, CAUCA"},
    {"id": 8961,"nombre": "SAN JOSE, PAEZ, CAUCA"},
    {"id": 8962,"nombre": "TARAVIRA, PAEZ, CAUCA"},
    {"id": 8963,"nombre": "MORALES, MORALES, CAUCA"},
    {"id": 8964,"nombre": "CARPINTERO, MORALES, CAUCA"},
    {"id": 8965,"nombre": "CHIMBORAZO, MORALES, CAUCA"},
    {"id": 8966,"nombre": "HONDURAS, MORALES, CAUCA"},
    {"id": 8967,"nombre": "PAN DE AZUCAR, MORALES, CAUCA"},
    {"id": 8968,"nombre": "SAN ISIDRO, MORALES, CAUCA"},
    {"id": 8969,"nombre": "SANTA ROSA, MORALES, CAUCA"},
    {"id": 8970,"nombre": "LA ESTACION, MORALES, CAUCA"},
    {"id": 8971,"nombre": "EL MESON, MORALES, CAUCA"},
    {"id": 8972,"nombre": "EL ROSARIO, MORALES, CAUCA"},
    {"id": 8973,"nombre": "LA VEGA, LA VEGA, CAUCA"},
    {"id": 8974,"nombre": "ALTAMIRA, LA VEGA, CAUCA"},
    {"id": 8975,"nombre": "ARBELA, LA VEGA, CAUCA"},
    {"id": 8976,"nombre": "EL DIVISO, LA VEGA, CAUCA"},
    {"id": 8977,"nombre": "EL PALMAR, LA VEGA, CAUCA"},
    {"id": 8978,"nombre": "GUACHICONO, LA VEGA, CAUCA"},
    {"id": 8979,"nombre": "LOS UVOS, LA VEGA, CAUCA"},
    {"id": 8980,"nombre": "PANCITARA, LA VEGA, CAUCA"},
    {"id": 8981,"nombre": "PLACER, LA VEGA, CAUCA"},
    {"id": 8982,"nombre": "SAN MIGUEL, LA VEGA, CAUCA"},
    {"id": 8983,"nombre": "SANTA BARBARA, LA VEGA, CAUCA"},
    {"id": 8984,"nombre": "SANTA JUANA, LA VEGA, CAUCA"},
    {"id": 8985,"nombre": "ALBANIA, LA VEGA, CAUCA"},
    {"id": 8986,"nombre": "CHAUPILOMA, LA VEGA, CAUCA"},
    {"id": 8987,"nombre": "DOMINICAL, LA VEGA, CAUCA"},
    {"id": 8988,"nombre": "LA CARRERA, LA VEGA, CAUCA"},
    {"id": 8989,"nombre": "LOS CIRUELOS, LA VEGA, CAUCA"},
    {"id": 8990,"nombre": "VILLA MARIA, LA VEGA, CAUCA"},
    {"id": 8991,"nombre": "BARBILLAS, LA VEGA, CAUCA"},
    {"id": 8992,"nombre": "SANTA RITA, LA VEGA, CAUCA"},
    {"id": 8993,"nombre": "FLORENCIA, FLORENCIA, CAUCA"},
    {"id": 8994,"nombre": "EL ROSARIO, FLORENCIA, CAUCA"},
    {"id": 8995,"nombre": "MARSELLA, FLORENCIA, CAUCA"},
    {"id": 8996,"nombre": "EL TAMBO, EL TAMBO, CAUCA"},
    {"id": 8997,"nombre": "ALTO DEL REY, EL TAMBO, CAUCA"},
    {"id": 8998,"nombre": "BARAYA, EL TAMBO, CAUCA"},
    {"id": 8999,"nombre": "BETANIA, EL TAMBO, CAUCA"},
    {"id": 9000,"nombre": "CUATRO ESQUINAS, EL TAMBO, CAUCA"},
    {"id": 9001,"nombre": "CHAPA, EL TAMBO, CAUCA"},
    {"id": 9002,"nombre": "CHISQUIO, EL TAMBO, CAUCA"},
    {"id": 9003,"nombre": "EL PLACER, EL TAMBO, CAUCA"},
    {"id": 9004,"nombre": "EL ZARZAL, EL TAMBO, CAUCA"},
    {"id": 9005,"nombre": "GAMBOA, EL TAMBO, CAUCA"},
    {"id": 9006,"nombre": "NUEVA GRANADA, EL TAMBO, CAUCA"},
    {"id": 9007,"nombre": "HUISITO, EL TAMBO, CAUCA"},
    {"id": 9008,"nombre": "LA ALIANZA, EL TAMBO, CAUCA"},
    {"id": 9009,"nombre": "LA PAZ, EL TAMBO, CAUCA"},
    {"id": 9010,"nombre": "LOS ANAYES, EL TAMBO, CAUCA"},
    {"id": 9011,"nombre": "LOS ANDES, EL TAMBO, CAUCA"},
    {"id": 9012,"nombre": "LOS ANGELES, EL TAMBO, CAUCA"},
    {"id": 9013,"nombre": "MOSQUERA, EL TAMBO, CAUCA"},
    {"id": 9014,"nombre": "PANDIGUANDO, EL TAMBO, CAUCA"},
    {"id": 9015,"nombre": "PIAGUA, EL TAMBO, CAUCA"},
    {"id": 9016,"nombre": "PUERTO RICO, EL TAMBO, CAUCA"},
    {"id": 9017,"nombre": "QUILCACE, EL TAMBO, CAUCA"},
    {"id": 9018,"nombre": "RONDON, EL TAMBO, CAUCA"},
    {"id": 9019,"nombre": "SABANETAS, EL TAMBO, CAUCA"},
    {"id": 9020,"nombre": "SAN JOAQUIN, EL TAMBO, CAUCA"},
    {"id": 9021,"nombre": "SAN JUAN DE MECHENQUE, EL TAMBO, CAUCA"},
    {"id": 9022,"nombre": "SEGUENGUE, EL TAMBO, CAUCA"},
    {"id": 9023,"nombre": "URIBE, EL TAMBO, CAUCA"},
    {"id": 9024,"nombre": "VILLA AL MAR, EL TAMBO, CAUCA"},
    {"id": 9025,"nombre": "LA LAGUNA, EL TAMBO, CAUCA"},
    {"id": 9026,"nombre": "BUENA VISTA, EL TAMBO, CAUCA"},
    {"id": 9027,"nombre": "LAS BOTAS, EL TAMBO, CAUCA"},
    {"id": 9028,"nombre": "CABUYAL, EL TAMBO, CAUCA"},
    {"id": 9029,"nombre": "EL CRUCERO DEL PUEBLO, EL TAMBO, CAUCA"},
    {"id": 9030,"nombre": "LA ROMELIA, EL TAMBO, CAUCA"},
    {"id": 9031,"nombre": "PLAYA RICA, EL TAMBO, CAUCA"},
    {"id": 9032,"nombre": "PUERTA LLAVE, EL TAMBO, CAUCA"},
    {"id": 9033,"nombre": "RIO HONDO, EL TAMBO, CAUCA"},
    {"id": 9034,"nombre": "VEINTE DE JULIO, EL TAMBO, CAUCA"},
    {"id": 9035,"nombre": "EL RETIRO, EL TAMBO, CAUCA"},
    {"id": 9036,"nombre": "CALICHALES, EL TAMBO, CAUCA"},
    {"id": 9037,"nombre": "EL HIGUERON, EL TAMBO, CAUCA"},
    {"id": 9038,"nombre": "EL OCHENTAYUNO (LA GALLERA), EL TAMBO, CAUCA"},
    {"id": 9039,"nombre": "GUAZARAVITA, EL TAMBO, CAUCA"},
    {"id": 9040,"nombre": "LA BANDA, EL TAMBO, CAUCA"},
    {"id": 9041,"nombre": "LA CUCHILLA, EL TAMBO, CAUCA"},
    {"id": 9042,"nombre": "LAS PIEDRAS, EL TAMBO, CAUCA"},
    {"id": 9043,"nombre": "LAS HUERTAS, EL TAMBO, CAUCA"},
    {"id": 9044,"nombre": "LOS LLANOS, EL TAMBO, CAUCA"},
    {"id": 9045,"nombre": "RIO SUCIO, EL TAMBO, CAUCA"},
    {"id": 9046,"nombre": "MURGUEITIO, EL TAMBO, CAUCA"},
    {"id": 9047,"nombre": "PEPITAL, EL TAMBO, CAUCA"},
    {"id": 9048,"nombre": "JUANA CASTANA, EL TAMBO, CAUCA"},
    {"id": 9049,"nombre": "LA VENTANA, EL TAMBO, CAUCA"},
    {"id": 9050,"nombre": "MONTERREDONDO, EL TAMBO, CAUCA"},
    {"id": 9051,"nombre": "LA PALOMA, EL TAMBO, CAUCA"},
    {"id": 9052,"nombre": "SAN ANTONIO, EL TAMBO, CAUCA"},
    {"id": 9053,"nombre": "BOLIVAR, BOLIVAR, CAUCA"},
    {"id": 9054,"nombre": "CAPELLANIAS, BOLIVAR, CAUCA"},
    {"id": 9055,"nombre": "NUEVA GRANADA, BOLIVAR, CAUCA"},
    {"id": 9056,"nombre": "EL CARMEN, BOLIVAR, CAUCA"},
    {"id": 9057,"nombre": "EL RODEO, BOLIVAR, CAUCA"},
    {"id": 9058,"nombre": "GUACHICONO, BOLIVAR, CAUCA"},
    {"id": 9059,"nombre": "LA CUMBRE DE SANTA INES, BOLIVAR, CAUCA"},
    {"id": 9060,"nombre": "LAS CASCADAS, BOLIVAR, CAUCA"},
    {"id": 9061,"nombre": "LAS CEJAS, BOLIVAR, CAUCA"},
    {"id": 9062,"nombre": "LERMA, BOLIVAR, CAUCA"},
    {"id": 9063,"nombre": "LOS MILAGROS, BOLIVAR, CAUCA"},
    {"id": 9064,"nombre": "LOS RASTROJOS, BOLIVAR, CAUCA"},
    {"id": 9065,"nombre": "MELCHOR, BOLIVAR, CAUCA"},
    {"id": 9066,"nombre": "SAN JOSE DEL MORRO, BOLIVAR, CAUCA"},
    {"id": 9067,"nombre": "SAN JUAN, BOLIVAR, CAUCA"},
    {"id": 9068,"nombre": "SAN LORENZO, BOLIVAR, CAUCA"},
    {"id": 9069,"nombre": "SAN MIGUEL, BOLIVAR, CAUCA"},
    {"id": 9070,"nombre": "PUENTE FIERRO, BOLIVAR, CAUCA"},
    {"id": 9071,"nombre": "LA ESPERANZA, BOLIVAR, CAUCA"},
    {"id": 9072,"nombre": "LA CARBONERA, BOLIVAR, CAUCA"},
    {"id": 9073,"nombre": "EL MORRO, BOLIVAR, CAUCA"},
    {"id": 9074,"nombre": "VILLA NUEVA, BOLIVAR, CAUCA"},
    {"id": 9075,"nombre": "BALBOA, BALBOA, CAUCA"},
    {"id": 9076,"nombre": "LA PLANADA, BALBOA, CAUCA"},
    {"id": 9077,"nombre": "OLAYA, BALBOA, CAUCA"},
    {"id": 9078,"nombre": "SAN ALFONSO, BALBOA, CAUCA"},
    {"id": 9079,"nombre": "LA BERMEJA, BALBOA, CAUCA"},
    {"id": 9080,"nombre": "PURETO, BALBOA, CAUCA"},
    {"id": 9081,"nombre": "GUADALITO, BALBOA, CAUCA"},
    {"id": 9082,"nombre": "LA LOMITA, BALBOA, CAUCA"},
    {"id": 9083,"nombre": "EL VIJAL, BALBOA, CAUCA"},
    {"id": 9084,"nombre": "ARGELIA, ARGELIA, CAUCA"},
    {"id": 9085,"nombre": "EL MANGO, ARGELIA, CAUCA"},
    {"id": 9086,"nombre": "LA BELLEZA, ARGELIA, CAUCA"},
    {"id": 9087,"nombre": "BETANIA, ARGELIA, CAUCA"},
    {"id": 9088,"nombre": "BOLIVIA, ARGELIA, CAUCA"},
    {"id": 9089,"nombre": "EL DIVISO, ARGELIA, CAUCA"},
    {"id": 9090,"nombre": "EL PLATEADO, ARGELIA, CAUCA"},
    {"id": 9091,"nombre": "SINAI, ARGELIA, CAUCA"},
    {"id": 9092,"nombre": "LA EMBOSCADA, ARGELIA, CAUCA"},
    {"id": 9093,"nombre": "SANTA CLARA, ARGELIA, CAUCA"},
    {"id": 9094,"nombre": "EL NARANJAL, ARGELIA, CAUCA"},
    {"id": 9095,"nombre": "VALPARAISO, VALPARAISO, CAQUETA"},
    {"id": 9096,"nombre": "SANTIAGO DE LA SELVA, VALPARAISO, CAQUETA"},
    {"id": 9097,"nombre": "KILOMETRO 18, VALPARAISO, CAQUETA"},
    {"id": 9098,"nombre": "PLAYA RICA, VALPARAISO, CAQUETA"},
    {"id": 9099,"nombre": "SAN JOSE DE LA FRAGUA, SAN JOSE DEL FRAGUA, CAQUETA"},
    {"id": 9100,"nombre": "FRAGUITA, SAN JOSE DEL FRAGUA, CAQUETA"},
    {"id": 9101,"nombre": "YURAYACO, SAN JOSE DEL FRAGUA, CAQUETA"},
    {"id": 9102,"nombre": "SABALETA, SAN JOSE DEL FRAGUA, CAQUETA"},
    {"id": 9103,"nombre": "PUERTO RICO, PUERTO RICO, CAQUETA"},
    {"id": 9104,"nombre": "LUSITANIA, PUERTO RICO, CAQUETA"},
    {"id": 9105,"nombre": "SANTANA RAMOS, PUERTO RICO, CAQUETA"},
    {"id": 9106,"nombre": "LA ESMERALDA, PUERTO RICO, CAQUETA"},
    {"id": 9107,"nombre": "LA AGUILILLA, PUERTO RICO, CAQUETA"},
    {"id": 9108,"nombre": "LA MONTANITA, LA MONTAÑITA, CAQUETA"},
    {"id": 9109,"nombre": "SANTUARIO, LA MONTAÑITA, CAQUETA"},
    {"id": 9110,"nombre": "LA UNION PENEYA, LA MONTAÑITA, CAQUETA"},
    {"id": 9111,"nombre": "PALETARA, LA MONTAÑITA, CAQUETA"},
    {"id": 9112,"nombre": "LA UNION, LA MONTAÑITA, CAQUETA"},
    {"id": 9113,"nombre": "EL TRIUNFO, LA MONTAÑITA, CAQUETA"},
    {"id": 9114,"nombre": "MATEGUADUA, LA MONTAÑITA, CAQUETA"},
    {"id": 9115,"nombre": "SAN ISIDRO, LA MONTAÑITA, CAQUETA"},
    {"id": 9116,"nombre": "ALBANIA, ALBANIA, CAQUETA"},
    {"id": 9117,"nombre": "DORADO, ALBANIA, CAQUETA"},
    {"id": 9118,"nombre": "VERSALLES, ALBANIA, CAQUETA"},
    {"id": 9119,"nombre": "EL PARAISO, ALBANIA, CAQUETA"},
    {"id": 9120,"nombre": "FLORENCIA, FLORENCIA, CAQUETA"},
    {"id": 9121,"nombre": "EL DANUBIO (ALTO ORTEGUAZA), FLORENCIA, CAQUETA"},
    {"id": 9122,"nombre": "EL REMOLINO, FLORENCIA, CAQUETA"},
    {"id": 9123,"nombre": "MARACAIBO, FLORENCIA, CAQUETA"},
    {"id": 9124,"nombre": "SAN ANTONIO DE ATENAS, FLORENCIA, CAQUETA"},
    {"id": 9125,"nombre": "SANTANA LAS HERMOSAS, FLORENCIA, CAQUETA"},
    {"id": 9126,"nombre": "LA ESPERANZA, FLORENCIA, CAQUETA"},
    {"id": 9127,"nombre": "NORCACIA, FLORENCIA, CAQUETA"},
    {"id": 9128,"nombre": "VENECIA, FLORENCIA, CAQUETA"},
    {"id": 9129,"nombre": "EL PARA, FLORENCIA, CAQUETA"},
    {"id": 9130,"nombre": "SAN GUILLERMO, FLORENCIA, CAQUETA"},
    {"id": 9131,"nombre": "CARANO, FLORENCIA, CAQUETA"},
    {"id": 9132,"nombre": "ORTEGUAZA, FLORENCIA, CAQUETA"},
    {"id": 9133,"nombre": "SAN MARTIN, FLORENCIA, CAQUETA"},
    {"id": 9134,"nombre": "SAN PEDRO, FLORENCIA, CAQUETA"},
    {"id": 9135,"nombre": "SANTO DOMINGO, FLORENCIA, CAQUETA"},
    {"id": 9136,"nombre": "SALAMINA, SALAMINA, CALDAS"},
    {"id": 9137,"nombre": "LA UNION, SALAMINA, CALDAS"},
    {"id": 9138,"nombre": "SAN FELIX, SALAMINA, CALDAS"},
    {"id": 9139,"nombre": "RIOSUCIO, RIOSUCIO, CALDAS"},
    {"id": 9140,"nombre": "BONAFONT, RIOSUCIO, CALDAS"},
    {"id": 9141,"nombre": "EL SALADO, RIOSUCIO, CALDAS"},
    {"id": 9142,"nombre": "FLORENCIA, RIOSUCIO, CALDAS"},
    {"id": 9143,"nombre": "QUIEBRALOMO, RIOSUCIO, CALDAS"},
    {"id": 9144,"nombre": "SAN LORENZO, RIOSUCIO, CALDAS"},
    {"id": 9145,"nombre": "IBERIA, RIOSUCIO, CALDAS"},
    {"id": 9146,"nombre": "SIPIRRA, RIOSUCIO, CALDAS"},
    {"id": 9147,"nombre": "SAN JERONIMO, RIOSUCIO, CALDAS"},
    {"id": 9148,"nombre": "PUEBLO VIEJO, RIOSUCIO, CALDAS"},
    {"id": 9149,"nombre": "LAS ESTANCIAS, RIOSUCIO, CALDAS"},
    {"id": 9150,"nombre": "LA PLAYA - IMURRA, RIOSUCIO, CALDAS"},
    {"id": 9151,"nombre": "TUMBABARRETO, RIOSUCIO, CALDAS"},
    {"id": 9152,"nombre": "PALESTINA, PALESTINA, CALDAS"},
    {"id": 9153,"nombre": "ARAUCA, PALESTINA, CALDAS"},
    {"id": 9154,"nombre": "EL REPOSO, PALESTINA, CALDAS"},
    {"id": 9155,"nombre": "LA PLATA, PALESTINA, CALDAS"},
    {"id": 9156,"nombre": "CARTAGENA, PALESTINA, CALDAS"},
    {"id": 9157,"nombre": "SANTAGUEDA, PALESTINA, CALDAS"},
    {"id": 9158,"nombre": "EL BEBEDERO, PALESTINA, CALDAS"},
    {"id": 9159,"nombre": "EL RETIRO, PALESTINA, CALDAS"},
    {"id": 9160,"nombre": "LA BASTILLA, PALESTINA, CALDAS"},
    {"id": 9161,"nombre": "LA HERMELLA, PALESTINA, CALDAS"},
    {"id": 9162,"nombre": "TOGUI, TOGÜI, BOYACA"},
    {"id": 9163,"nombre": "GARIBAY, TOGÜI, BOYACA"},
    {"id": 9164,"nombre": "SANTA MARIA, SANTA MARIA, BOYACA"},
    {"id": 9165,"nombre": "CANO NEGRO, SANTA MARIA, BOYACA"},
    {"id": 9166,"nombre": "CULIMA, SANTA MARIA, BOYACA"},
    {"id": 9167,"nombre": "NAZARETH, SANTA MARIA, BOYACA"},
    {"id": 9168,"nombre": "PAEZ, PAEZ, BOYACA"},
    {"id": 9169,"nombre": "LA URURIA, PAEZ, BOYACA"},
    {"id": 9170,"nombre": "SIRASI, PAEZ, BOYACA"},
    {"id": 9171,"nombre": "MIRAFLORES, MIRAFLORES, BOYACA"},
    {"id": 9172,"nombre": "AYATA, MIRAFLORES, BOYACA"},
    {"id": 9173,"nombre": "TUNJITA, MIRAFLORES, BOYACA"},
    {"id": 9174,"nombre": "LA VICTORIA, LA VICTORIA, BOYACA"},
    {"id": 9175,"nombre": "CHAPON O GUADUA, LA VICTORIA, BOYACA"},
    {"id": 9176,"nombre": "SANTA HELENA, LA VICTORIA, BOYACA"},
    {"id": 9177,"nombre": "HUMBO, LA VICTORIA, BOYACA"},
    {"id": 9178,"nombre": "JERICO, JERICO, BOYACA"},
    {"id": 9179,"nombre": "SAN JOSE DE CHEVA, JERICO, BOYACA"},
    {"id": 9180,"nombre": "PUEBLO VIEJO, JERICO, BOYACA"},
    {"id": 9181,"nombre": "GUICAN, GÜICAN, BOYACA"},
    {"id": 9182,"nombre": "BOCOTA, GÜICAN, BOYACA"},
    {"id": 9183,"nombre": "LA CUEVA, GÜICAN, BOYACA"},
    {"id": 9184,"nombre": "EL BANCO, GÜICAN, BOYACA"},
    {"id": 9185,"nombre": "BACHIRA, GÜICAN, BOYACA"},
    {"id": 9186,"nombre": "TUNEBIA ARRIBA, GÜICAN, BOYACA"},
    {"id": 9187,"nombre": "CALDAS, CALDAS, BOYACA"},
    {"id": 9188,"nombre": "NARIÑO, CALDAS, BOYACA"},
    {"id": 9189,"nombre": "QUIPE CHUNGAGUTA, CALDAS, BOYACA"},
    {"id": 9190,"nombre": "EL CUBO, CALDAS, BOYACA"},
    {"id": 9191,"nombre": "BUENAVISTA, BUENAVISTA, BOYACA"},
    {"id": 9192,"nombre": "CAMPOALEGRE, BUENAVISTA, BOYACA"},
    {"id": 9193,"nombre": "CAMPO HERMOSO, BUENAVISTA, BOYACA"},
    {"id": 9194,"nombre": "PISMAL, BUENAVISTA, BOYACA"},
    {"id": 9195,"nombre": "BRICENO, BRICEÑO, BOYACA"},
    {"id": 9196,"nombre": "BELEN, BELEN, BOYACA"},
    {"id": 9197,"nombre": "SAN JOSE DE LA MONTANA, BELEN, BOYACA"},
    {"id": 9198,"nombre": "MONTERO BAJO, BELEN, BOYACA"},
    {"id": 9199,"nombre": "BOSQUE TRES, BELEN, BOYACA"},
    {"id": 9200,"nombre": "BOSQUE DOS, BELEN, BOYACA"},
    {"id": 9201,"nombre": "BOSQUE UNO, BELEN, BOYACA"},
    {"id": 9202,"nombre": "LA VENTA LA MESA, BELEN, BOYACA"},
    {"id": 9203,"nombre": "TIRINQUITA, BELEN, BOYACA"},
    {"id": 9204,"nombre": "VILLANUEVA, VILLANUEVA, BOLIVAR"},
    {"id": 9205,"nombre": "CIPACOA, VILLANUEVA, BOLIVAR"},
    {"id": 9206,"nombre": "ALGARROBO, VILLANUEVA, BOLIVAR"},
    {"id": 9207,"nombre": "SANTA ROSA, SANTA ROSA, BOLIVAR"},
    {"id": 9208,"nombre": "SAN PABLO, SAN PABLO, BOLIVAR"},
    {"id": 9209,"nombre": "CANALETAL, SAN PABLO, BOLIVAR"},
    {"id": 9210,"nombre": "SANTO DOMINGO, SAN PABLO, BOLIVAR"},
    {"id": 9211,"nombre": "EL CARMEN, SAN PABLO, BOLIVAR"},
    {"id": 9212,"nombre": "EL SOCORRO, SAN PABLO, BOLIVAR"},
    {"id": 9213,"nombre": "POZO AZUL, SAN PABLO, BOLIVAR"},
    {"id": 9214,"nombre": "SAN JUAN, SAN PABLO, BOLIVAR"},
    {"id": 9215,"nombre": "CANABRAVAL, SAN PABLO, BOLIVAR"},
    {"id": 9216,"nombre": "MORALES, MORALES, BOLIVAR"},
    {"id": 9217,"nombre": "BODEGA CENTRAL, MORALES, BOLIVAR"},
    {"id": 9218,"nombre": "EL DIQUE, MORALES, BOLIVAR"},
    {"id": 9219,"nombre": "LAS PAILAS, MORALES, BOLIVAR"},
    {"id": 9220,"nombre": "BOCA DE LA HONDA, MORALES, BOLIVAR"},
    {"id": 9221,"nombre": "MICO AHUMADO, MORALES, BOLIVAR"},
    {"id": 9222,"nombre": "PAREDES DE ORORIA, MORALES, BOLIVAR"},
    {"id": 9223,"nombre": "EL PENON, EL PEÑON, BOLIVAR"},
    {"id": 9224,"nombre": "BATAYE, EL PEÑON, BOLIVAR"},
    {"id": 9225,"nombre": "BUBA, EL PEÑON, BOLIVAR"},
    {"id": 9226,"nombre": "BUENOS AIRES, EL PEÑON, BOLIVAR"},
    {"id": 9227,"nombre": "CASTANAL, EL PEÑON, BOLIVAR"},
    {"id": 9228,"nombre": "CORINTO, EL PEÑON, BOLIVAR"},
    {"id": 9229,"nombre": "CHAPETONA, EL PEÑON, BOLIVAR"},
    {"id": 9230,"nombre": "ESPERANZA DE DIOS, EL PEÑON, BOLIVAR"},
    {"id": 9231,"nombre": "EL JAPON, EL PEÑON, BOLIVAR"},
    {"id": 9232,"nombre": "ISLANDIA, EL PEÑON, BOLIVAR"},
    {"id": 9233,"nombre": "ISLA BATAYE, EL PEÑON, BOLIVAR"},
    {"id": 9234,"nombre": "ISLA DEL QUINDIO, EL PEÑON, BOLIVAR"},
    {"id": 9235,"nombre": "ISLA DEL VARAL, EL PEÑON, BOLIVAR"},
    {"id": 9236,"nombre": "LA HUMAREDA, EL PEÑON, BOLIVAR"},
    {"id": 9237,"nombre": "LAS GUADUAS, EL PEÑON, BOLIVAR"},
    {"id": 9238,"nombre": "LOS ANGELES, EL PEÑON, BOLIVAR"},
    {"id": 9239,"nombre": "LOS TOTUMOS, EL PEÑON, BOLIVAR"},
    {"id": 9240,"nombre": "PENONCITO, EL PEÑON, BOLIVAR"},
    {"id": 9241,"nombre": "SOLERA, EL PEÑON, BOLIVAR"},
    {"id": 9242,"nombre": "SAN PABLO, EL PEÑON, BOLIVAR"},
    {"id": 9243,"nombre": "ULTIMO CASO, EL PEÑON, BOLIVAR"},
    {"id": 9244,"nombre": "ZARAGOCILLA, EL PEÑON, BOLIVAR"},
    {"id": 9245,"nombre": "CORDOBA, CORDOBA, BOLIVAR"},
    {"id": 9246,"nombre": "GUAIMARAL, CORDOBA, BOLIVAR"},
    {"id": 9247,"nombre": "ISLA DE CORDOBA, CORDOBA, BOLIVAR"},
    {"id": 9248,"nombre": "LA MONTANA DE ALONSO (MARTIN ALONSO), CORDOBA, BOLIVAR"},
    {"id": 9249,"nombre": "SAN ANDRES, CORDOBA, BOLIVAR"},
    {"id": 9250,"nombre": "SINCELEJITO, CORDOBA, BOLIVAR"},
    {"id": 9251,"nombre": "TACAMOCHITO, CORDOBA, BOLIVAR"},
    {"id": 9252,"nombre": "TACAMOCHO, CORDOBA, BOLIVAR"},
    {"id": 9253,"nombre": "SANTA LUCIA, CORDOBA, BOLIVAR"},
    {"id": 9254,"nombre": "CALAMAR, CALAMAR, BOLIVAR"},
    {"id": 9255,"nombre": "BARRANCA NUEVA, CALAMAR, BOLIVAR"},
    {"id": 9256,"nombre": "BARRANCA VIEJA, CALAMAR, BOLIVAR"},
    {"id": 9257,"nombre": "HATO VIEJO, CALAMAR, BOLIVAR"},
    {"id": 9258,"nombre": "SAN PEDRITO, CALAMAR, BOLIVAR"},
    {"id": 9259,"nombre": "YUCAL, CALAMAR, BOLIVAR"},
    {"id": 9260,"nombre": "EL DESVELO, CALAMAR, BOLIVAR"},
    {"id": 9261,"nombre": "EL PROGRESO, CALAMAR, BOLIVAR"},
    {"id": 9262,"nombre": "KINGSTON, CALAMAR, BOLIVAR"},
    {"id": 9263,"nombre": "LA PONDEROSA, CALAMAR, BOLIVAR"},
    {"id": 9264,"nombre": "BOGOTA, BOGOTA, CUNDINAMARCA"},
    {"id": 9265,"nombre": "SABANALARGA, SABANALARGA, ATLANTICO"},
    {"id": 9266,"nombre": "AGUADA DE PABLO, SABANALARGA, ATLANTICO"},
    {"id": 9267,"nombre": "CASCAJAL, SABANALARGA, ATLANTICO"},
    {"id": 9268,"nombre": "COLOMBIA, SABANALARGA, ATLANTICO"},
    {"id": 9269,"nombre": "ISABEL LOPEZ, SABANALARGA, ATLANTICO"},
    {"id": 9270,"nombre": "LA PENA (GUAJARO), SABANALARGA, ATLANTICO"},
    {"id": 9271,"nombre": "MOLINERO, SABANALARGA, ATLANTICO"},
    {"id": 9272,"nombre": "MIRADOR, SABANALARGA, ATLANTICO"},
    {"id": 9273,"nombre": "GALLEGO, SABANALARGA, ATLANTICO"},
    {"id": 9274,"nombre": "PATILLA, SABANALARGA, ATLANTICO"},
    {"id": 9275,"nombre": "PUERTO COLOMBIA, PUERTO COLOMBIA, ATLANTICO"},
    {"id": 9276,"nombre": "SALGAR, PUERTO COLOMBIA, ATLANTICO"},
    {"id": 9277,"nombre": "SABANILLA (MONTE CARMELO), PUERTO COLOMBIA, ATLANTICO"},
    {"id": 9278,"nombre": "CANDELARIA, CANDELARIA, ATLANTICO"},
    {"id": 9279,"nombre": "CARRETO, CANDELARIA, ATLANTICO"},
    {"id": 9280,"nombre": "LENA, CANDELARIA, ATLANTICO"},
    {"id": 9281,"nombre": "VENECIA, VENECIA, ANTIOQUIA"},
    {"id": 9282,"nombre": "BOLOMBOLO, VENECIA, ANTIOQUIA"},
    {"id": 9283,"nombre": "ESTACION TARSO, VENECIA, ANTIOQUIA"},
    {"id": 9284,"nombre": "LA JULIA, VENECIA, ANTIOQUIA"},
    {"id": 9285,"nombre": "LA MINA, VENECIA, ANTIOQUIA"},
    {"id": 9286,"nombre": "VALPARAISO, VALPARAISO, ANTIOQUIA"},
    {"id": 9287,"nombre": "LA FABIANA, VALPARAISO, ANTIOQUIA"},
    {"id": 9288,"nombre": "LA HERRADURA, VALPARAISO, ANTIOQUIA"},
    {"id": 9289,"nombre": "TARTARIN MOREIRA, VALPARAISO, ANTIOQUIA"},
    {"id": 9290,"nombre": "FILO SECO, VALPARAISO, ANTIOQUIA"},
    {"id": 9291,"nombre": "ITIMA, VALPARAISO, ANTIOQUIA"},
    {"id": 9292,"nombre": "MURRAPAL, VALPARAISO, ANTIOQUIA"},
    {"id": 9293,"nombre": "PUEBLO NUEVO, VALPARAISO, ANTIOQUIA"},
    {"id": 9294,"nombre": "TOLEDO, TOLEDO, ANTIOQUIA"},
    {"id": 9295,"nombre": "BUENAVISTA, TOLEDO, ANTIOQUIA"},
    {"id": 9296,"nombre": "EL VALLE, TOLEDO, ANTIOQUIA"},
    {"id": 9297,"nombre": "SANTA BARBARA, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9298,"nombre": "DAMASCO, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9299,"nombre": "EL CAIRO, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9300,"nombre": "VERSALLES, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9301,"nombre": "TRECE DE JUNIO, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9302,"nombre": "EL HELECHAL, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9303,"nombre": "LOS ALMENDROS, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9304,"nombre": "YARUMALITO, SANTA BARBARA, ANTIOQUIA"},
    {"id": 9305,"nombre": "SAN PEDRO, SAN PEDRO, ANTIOQUIA"},
    {"id": 9306,"nombre": "LA CUCHILLA, SAN PEDRO, ANTIOQUIA"},
    {"id": 9307,"nombre": "LA PALMA, SAN PEDRO, ANTIOQUIA"},
    {"id": 9308,"nombre": "LA MONTANA, SAN PEDRO, ANTIOQUIA"},
    {"id": 9309,"nombre": "EL ESPINAL, SAN PEDRO, ANTIOQUIA"},
    {"id": 9310,"nombre": "OVEJAS, SAN PEDRO, ANTIOQUIA"},
    {"id": 9311,"nombre": "LA LANA, SAN PEDRO, ANTIOQUIA"},
    {"id": 9312,"nombre": "PANTANILLO, SAN PEDRO, ANTIOQUIA"},
    {"id": 9313,"nombre": "RIO CHICO, SAN PEDRO, ANTIOQUIA"},
    {"id": 9314,"nombre": "SAN LUIS, SAN LUIS, ANTIOQUIA"},
    {"id": 9315,"nombre": "EL SILENCIO, SAN LUIS, ANTIOQUIA"},
    {"id": 9316,"nombre": "EL VERGEL, SAN LUIS, ANTIOQUIA"},
    {"id": 9317,"nombre": "EL PRODIGIO, SAN LUIS, ANTIOQUIA"},
    {"id": 9318,"nombre": "BUENOS AIRES, SAN LUIS, ANTIOQUIA"},
    {"id": 9319,"nombre": "ALTA VISTA, SAN LUIS, ANTIOQUIA"},
    {"id": 9320,"nombre": "PLAYA LINDA, SAN LUIS, ANTIOQUIA"},
    {"id": 9321,"nombre": "SALAMBRINA, SAN LUIS, ANTIOQUIA"},
    {"id": 9322,"nombre": "MONTELORO (LA JOSEFINA), SAN LUIS, ANTIOQUIA"},
    {"id": 9323,"nombre": "LA TEBAIDA, SAN LUIS, ANTIOQUIA"},
    {"id": 9324,"nombre": "SOPETRAN, SAN LUIS, ANTIOQUIA"},
    {"id": 9325,"nombre": "SAN JOSE DE LA MONTANA, SAN JOSE DE LA MONTAÑA, ANTIOQUIA"},
    {"id": 9326,"nombre": "SAN FRANCISCO, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9327,"nombre": "AQUITANIA, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9328,"nombre": "LA HONDA, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9329,"nombre": "ALTA VISTA, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9330,"nombre": "PAILANIA, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9331,"nombre": "POCITOS, SAN FRANCISCO, ANTIOQUIA"},
    {"id": 9332,"nombre": "SAN CARLOS, SAN CARLOS, ANTIOQUIA"},
    {"id": 9333,"nombre": "EL JORDAN, SAN CARLOS, ANTIOQUIA"},
    {"id": 9334,"nombre": "SAMANA DEL NORTE, SAN CARLOS, ANTIOQUIA"},
    {"id": 9335,"nombre": "NARICES (PUERTO GARZA), SAN CARLOS, ANTIOQUIA"},
    {"id": 9336,"nombre": "PUERTO BELO, SAN CARLOS, ANTIOQUIA"},
    {"id": 9337,"nombre": "LA GARZA, SAN CARLOS, ANTIOQUIA"},
    {"id": 9338,"nombre": "DOS QUEBRADAS, SAN CARLOS, ANTIOQUIA"},
    {"id": 9339,"nombre": "LA HONDITA, SAN CARLOS, ANTIOQUIA"},
    {"id": 9340,"nombre": "VALLEJUELO, SAN CARLOS, ANTIOQUIA"},
    {"id": 9341,"nombre": "SAN ANDRES, SAN ANDRES DE CUERQUIA, ANTIOQUIA"},
    {"id": 9342,"nombre": "LOS NARANJOS, SAN ANDRES DE CUERQUIA, ANTIOQUIA"},
    {"id": 9343,"nombre": "SABANALARGA, SABANALARGA, ANTIOQUIA"},
    {"id": 9344,"nombre": "EL JUNCO, SABANALARGA, ANTIOQUIA"},
    {"id": 9345,"nombre": "EL ORO, SABANALARGA, ANTIOQUIA"},
    {"id": 9346,"nombre": "RIONEGRO, RIONEGRO, ANTIOQUIA"},
    {"id": 9347,"nombre": "SAN ANTONIO, RIONEGRO, ANTIOQUIA"},
    {"id": 9348,"nombre": "GARRIDO - LA LAJA, RIONEGRO, ANTIOQUIA"},
    {"id": 9349,"nombre": "AEROPUERTO, RIONEGRO, ANTIOQUIA"},
    {"id": 9350,"nombre": "EL PORVENIR, RIONEGRO, ANTIOQUIA"},
    {"id": 9351,"nombre": "SECTOR SUR, RIONEGRO, ANTIOQUIA"},
    {"id": 9352,"nombre": "PONTEZUELA, RIONEGRO, ANTIOQUIA"},
    {"id": 9353,"nombre": "PENOL, PENOL, ANTIOQUIA"},
    {"id": 9354,"nombre": "EL PENOL, PENOL, ANTIOQUIA"},
    {"id": 9355,"nombre": "NARIÑO, NARIÑO, ANTIOQUIA"},
    {"id": 9356,"nombre": "PUERTO VENUS (SAMANA), NARIÑO, ANTIOQUIA"},
    {"id": 9357,"nombre": "EL FARO, NARIÑO, ANTIOQUIA"},
    {"id": 9358,"nombre": "LA UNION, LA UNION, ANTIOQUIA"},
    {"id": 9359,"nombre": "MESOPOTAMIA, LA UNION, ANTIOQUIA"},
    {"id": 9360,"nombre": "GUARUNGO, LA UNION, ANTIOQUIA"},
    {"id": 9361,"nombre": "CHUSCALITO, LA UNION, ANTIOQUIA"},
    {"id": 9362,"nombre": "LA CONCHA, LA UNION, ANTIOQUIA"},
    {"id": 9363,"nombre": "JERICO, JERICO, ANTIOQUIA"},
    {"id": 9364,"nombre": "PALOCABILDO, JERICO, ANTIOQUIA"},
    {"id": 9365,"nombre": "MONTECRISTO, JERICO, ANTIOQUIA"},
    {"id": 9366,"nombre": "LOS PATIOS, JERICO, ANTIOQUIA"},
    {"id": 9367,"nombre": "GUADALUPE, GUADALUPE, ANTIOQUIA"},
    {"id": 9368,"nombre": "MALABRIGO, GUADALUPE, ANTIOQUIA"},
    {"id": 9369,"nombre": "GUALTEROS, GUADALUPE, ANTIOQUIA"},
    {"id": 9370,"nombre": "PATIO BONITO, GUADALUPE, ANTIOQUIA"},
    {"id": 9371,"nombre": "GUADALUPE IV, GUADALUPE, ANTIOQUIA"},
    {"id": 9372,"nombre": "GRANADA, GRANADA, ANTIOQUIA"},
    {"id": 9373,"nombre": "SANTA ANA, GRANADA, ANTIOQUIA"},
    {"id": 9374,"nombre": "CONCORDIA, CONCORDIA, ANTIOQUIA"},
    {"id": 9375,"nombre": "EL SOCORRO, CONCORDIA, ANTIOQUIA"},
    {"id": 9376,"nombre": "MORELIA, CONCORDIA, ANTIOQUIA"},
    {"id": 9377,"nombre": "MORITOS, CONCORDIA, ANTIOQUIA"},
    {"id": 9378,"nombre": "SAN FRANCISCO, CONCORDIA, ANTIOQUIA"},
    {"id": 9379,"nombre": "LAS ANIMAS, CONCORDIA, ANTIOQUIA"},
    {"id": 9380,"nombre": "EL GOLPE, CONCORDIA, ANTIOQUIA"},
    {"id": 9381,"nombre": "SALAZAR, CONCORDIA, ANTIOQUIA"},
    {"id": 9382,"nombre": "CONCEPCION, CONCEPCION, ANTIOQUIA"},
    {"id": 9383,"nombre": "CANASGORDAS, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9384,"nombre": "BUENOS AIRES, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9385,"nombre": "CESTILLAL, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9386,"nombre": "JUNTAS DE URAMITA, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9387,"nombre": "SAN PASCUAL, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9388,"nombre": "VERSALLES, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9389,"nombre": "LA BALSA, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9390,"nombre": "EL MADERO, CAÑASGORDAS, ANTIOQUIA"},
    {"id": 9391,"nombre": "CALDAS, CALDAS, ANTIOQUIA"},
    {"id": 9392,"nombre": "EL CANO, CALDAS, ANTIOQUIA"},
    {"id": 9393,"nombre": "LA RAYA, CALDAS, ANTIOQUIA"},
    {"id": 9394,"nombre": "MATADERO, CALDAS, ANTIOQUIA"},
    {"id": 9395,"nombre": "LA MIEL, CALDAS, ANTIOQUIA"},
    {"id": 9396,"nombre": "LA CORRALA, CALDAS, ANTIOQUIA"},
    {"id": 9397,"nombre": "PRIMAVERA, CALDAS, ANTIOQUIA"},
    {"id": 9398,"nombre": "BRICENO, BRICEÑO, ANTIOQUIA"},
    {"id": 9399,"nombre": "BERLIN (PUEBLO NUEVO), BRICEÑO, ANTIOQUIA"},
    {"id": 9400,"nombre": "EL ROBLAL, BRICEÑO, ANTIOQUIA"},
    {"id": 9401,"nombre": "LAS AURAS, BRICEÑO, ANTIOQUIA"},
    {"id": 9402,"nombre": "BETULIA, BETULIA, ANTIOQUIA"},
    {"id": 9403,"nombre": "ALTAMIRA, BETULIA, ANTIOQUIA"},
    {"id": 9404,"nombre": "CANGREJO, BETULIA, ANTIOQUIA"},
    {"id": 9405,"nombre": "LUCIANO RESTREPO, BETULIA, ANTIOQUIA"},
    {"id": 9406,"nombre": "BARBOSA, BARBOSA, ANTIOQUIA"},
    {"id": 9407,"nombre": "EL HATILLO, BARBOSA, ANTIOQUIA"},
    {"id": 9408,"nombre": "EL VERDE, BARBOSA, ANTIOQUIA"},
    {"id": 9409,"nombre": "EL GUAYABO, BARBOSA, ANTIOQUIA"},
    {"id": 9410,"nombre": "LAS VICTORIAS, BARBOSA, ANTIOQUIA"},
    {"id": 9411,"nombre": "AGUAS CLARAS, BARBOSA, ANTIOQUIA"},
    {"id": 9412,"nombre": "POPALITO, BARBOSA, ANTIOQUIA"},
    {"id": 9413,"nombre": "YARUMITO, BARBOSA, ANTIOQUIA"},
    {"id": 9414,"nombre": "ARMENIA, ARMENIA, ANTIOQUIA"},
    {"id": 9415,"nombre": "LA HERRADURA, ARMENIA, ANTIOQUIA"},
    {"id": 9416,"nombre": "LA LOMA, ARMENIA, ANTIOQUIA"},
    {"id": 9417,"nombre": "EL SOCORRO, ARMENIA, ANTIOQUIA"},
    {"id": 9418,"nombre": "MOJONES, ARMENIA, ANTIOQUIA"},
    {"id": 9419,"nombre": "ARGELIA, ARGELIA, ANTIOQUIA"},
    {"id": 9422,"nombre": "YONDO, MEDELLIN, ANTIOQUIA"},
    {"id": 9423,"nombre": "BOGOTA, BOGOTA, BOGOTA D. C."},
  ];

export default listadoCiudades;