import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import truckMarker from '../assets/img/truck_icon.png';

const CustomGoogleMap = ({ markers }) => {
  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const [map, setMap] = React.useState(null);
  const defaultCenter = markers.length > 0 ? markers[0].position : { lat: 0, lng: 0 };

  // Esta función se llama cuando la API de Google Maps se carga correctamente
  const handleMapLoad = (map) => {
    // console.log('Google Maps Loaded', markers);
    setMap(map);
  };

  const onLoadMarker = (marker) => {
    console.log("marker: ", marker);
  };

  return (
    <GoogleMap
      onLoad={handleMapLoad}
      mapContainerStyle={mapStyles}
      zoom={12}
      center={defaultCenter}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          onLoad={onLoadMarker}
          position={marker.position}
          map={map}
          icon={{
            url: truckMarker,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default CustomGoogleMap;