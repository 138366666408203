//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import API, { AXIOS } from '../environment/api';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LoadingScreen from './LoadingScreen';
import { GraphDoughnut } from '../components/GraphDoughnut';
import { GraphBarChart } from '../components/GraphBarChart';
import Stack from '@mui/material/Stack';

const CarrierPage = () => {
  //Funcion para inicializar los datos
  const [totalT, setTotalT] = React.useState([]);
  const [verificadoT, setVerificadoT] = React.useState([]);
  const [activoT, setActivoT] = React.useState([]);
  const [fidelizadoT, setFidelizadoT] = React.useState([]);
  const [totalS, setTotalS] = React.useState([]);
  const [verificadoS, setVerificadoS] = React.useState([]);
  const [activoS, setActivoS] = React.useState([]);
  const [fidelizadoS, setFidelizadoS] = React.useState([]);
  const [totalH, setTotalH] = React.useState([]);
  const [verificadoH, setVerificadoH] = React.useState([]);
  const [activoH, setActivoH] = React.useState([]);
  const [fidelizadoH, setFidelizadoH] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);

  React.useEffect(() => {
//Pre-carga de apis
    AXIOS.get(API.REPORTS,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
//Datos totales
      setTotalT(response.data.user_total[0].registrados);
      setVerificadoT(response.data.user_total[0].verificados);
      setActivoT(response.data.user_total[0].activos);
      setFidelizadoT(response.data.user_total[0].contratados);
//Datps semanales
      setTotalS(response.data.user_weekly[0].registrados);
      setVerificadoS(response.data.user_weekly[0].verificados);
      setActivoS(response.data.user_weekly[0].activos);
      setFidelizadoS(response.data.user_weekly[0].contratados);
//Datos diarios
      setTotalH(response.data.user_daily[0].usuarios);
      setVerificadoH(response.data.user_daily[0].verificados);
      setActivoH(response.data.user_daily[0].activos);
      setFidelizadoH(response.data.user_daily[0].contratados);
      console.log("fola",response.data)
    });
  }, []);
  
return (<>
  {(Loading === true)?
      (Loading ==="")?null:
      <LoadingScreen/>:  
// Modal de data total      
      <Dashboard title='Estadísticas de usuarios registrados'>
        <label style={{marginLeft:5, marginTop:50}}>
      <b>TOTAL</b>
      </label>
  
  <Stack justifyContent="center" style={{ display: "center", marginTop:3 }} direction="row">
 <Grid marginTop={1} item xs={12} md={2} lg={29} style={{display:'flex'}}> 
 {/* Registrados */}
 <Box
     sx={{
       marginRight: 1,
       width: 250,
       height: 80,
       borderRadius: 2,
       backgroundColor: 'white',
       display: 'flex'
     }}
     >
     <div> 
     <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Registrados</b></div>
     <label style={{marginLeft:10}}>
     <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{totalT}</b></div>
     </label>
     <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
     </div>
     </Box> 
{/* Verificados     */}
     <Box
     sx={{
       marginRight: 1,
       width: 250,
       height: 80,
       borderRadius: 2,
       backgroundColor: 'white',
       display: 'flex'
     }}
     >
     <div> 
     <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Verificados</b></div>
     <label style={{marginLeft:10}}>
     <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{verificadoT}</b></div>
     </label>                                                        
     <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
     </div>
     </Box> 
{/* Activos        */}
     <Box
     sx={{                        
       marginRight: 1,
       width: 250,
       height: 80,
       borderRadius: 2,
       backgroundColor: 'white',
       display: 'flex'
     }}
     >
     <div> 
     <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Activos</b></div>
     <label style={{marginLeft:10}}>
     <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{activoT}</b></div>
     </label>
     <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
     </div>
     </Box> 
     <Box
     sx={{
       marginRight: 1,
       width: 250,
       height: 80,
       borderRadius: 2,
       backgroundColor: 'white',
       display: 'flex'
     }}
     >
{/* Fidelizados */}
     <div> 
     <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Fidelizados</b></div>
     <label style={{marginLeft:10}}>
     <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{fidelizadoT}</b></div>
     </label>
     <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
     </div>
     </Box> 
      </Grid>
      </Stack>
<br></br>
      <label style={{marginLeft:5, marginTop:50}}>
      <b>SEMANAL</b>
      </label>
      <Stack justifyContent="center" style={{ display: "center", marginTop:3 }} direction="row">
      <Grid marginTop={1} item xs={12} md={2} lg={29} style={{display:'flex'}}> 
     
     <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Registrados */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Registrados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{totalS}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Verificados */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Verificados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{verificadoS}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Activos          */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Activos</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{activoS}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
 {/* Fidelizados          */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Fidelizados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{fidelizadoS}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          </Grid>
          </Stack>
<br></br>
      <label style={{marginLeft:5, marginTop:50}}>
      <b>HOY</b>
      </label>
      <Stack justifyContent="center" style={{ display: "center", marginTop:3 }} direction="row">
      <Grid marginTop={1} item xs={12} md={2} lg={29} style={{display:'flex'}}> 
     
     <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Registrados          */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Registrados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{totalH}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Verificados           */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Verificados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{verificadoH}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Activos           */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Activos</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{activoH}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
         <Box
         sx={{
           marginRight: 1,
           width: 250,
           height: 80,
           borderRadius: 2,
           backgroundColor: 'white',
           display: 'flex'
         }}
         >
{/* Fidelizados           */}
         <div> 
         <div style={{marginLeft:10, marginTop:5, color:" #81726f ",  fontSize:15}}><b>Fidelizados</b></div>
         <label style={{marginLeft:10}}>
         <div style={{marginLeft:18, marginTop:-20, fontSize:25}}><b>{fidelizadoH}</b></div>
         </label>
         <div style={{marginTop:-52,marginLeft:180,color:" #5cb9f5  "}}><EqualizerIcon sx={{ fontSize: 58 }}/></div>
         </div>
         </Box> 
          </Grid>
          </Stack>

          <Grid  justifyContent="center" marginTop={1} style={{display:'flex'}}  direction="row"> 
          <GraphDoughnut justifyContent="center"  style={{ display: "center", marginTop:3 }} />
          <GraphBarChart/>
        </Grid>
       
</Dashboard>
   }</>
);
}
export default CarrierPage;