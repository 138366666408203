//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import API, { AXIOS } from '../environment/api';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StorageIcon from '@mui/icons-material/Storage';
import LoadingScreen from './LoadingScreen';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import { saveAs } from "file-saver";
import AsyncCSV from '../components/AsyncCSV';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import listadoCiudades from '../components/ListadoCiudades';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {Equalizer} from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import ContrastIcon from '@mui/icons-material/Contrast';
import './box.css';

const KpiPage = () => {
  //Funcion para obetener la fecha actual
  var today = new Date();
  var day = today.getDay();
  var months = today.getMonth();
  if (months < 10) {
    var month = ("0" + months);
  } else {
    var month = months;
  }
  var year = today.getFullYear();
  //Funcion para inicializar los datos
  const [companies, setcompanies] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [api_transportista, setTransportista] = React.useState([]);
  const [api_viajes, setViajes] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [monthDate_empresa, setMonth_empresa] = React.useState(month);
  const [yearDate_empresa, setYear_empresa] = React.useState(year);
  const [monthDate_transportista, setMonth_transportista] = React.useState(month);
  const [yearDate_transportista, setYear_transportista] = React.useState(year);
  const [monthDate_ruta, setMonth_ruta] = React.useState(month);
  const [yearDate_ruta, setYear_ruta] = React.useState(year);
  const [monthDate_viajes, setMonth_viajes] = React.useState(month);
  const [yearDate_viajes, setYear_viajes] = React.useState(year);
  // const [totalsAndTimes, setTotalsAndTimes] = React.useState([]);

  const [listTimes, setListTimes] = React.useState([]);
  const [historicoCantidad, setHistoricoCantidad] = React.useState([]);
  const [casosPorEstados, setCasosPorEstados] = React.useState([]);

  //Funcion para exportar archivo .txt
  function exportFile() {
    let lyrics = "Origen  Destino  Cantidad";
    var blob = new Blob([lyrics], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "table.txt");
  }
  //Funcion para mostrar modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para cerrar modal de carga
  const closeLoad = () => setLoad(false);

  //Funcion para filtrar por mes de viajes
  const handleYear_viajes = (event) => {
    openLoad();
    setYear_viajes(event.target.value)
    AXIOS.get(API.KPI_LISTS + "month=" + monthDate_viajes + "&opt=yearly&year=" + (event.target.value), {
    }).then((response) => {
      closeLoad();
      let valores_contratados_list = Object.values(response.data.contratados);
      let valores_interesados_list = Object.values(response.data.interesados);
      let valores_publicados_list = Object.values(response.data.publicados);
      const viajes_data = [{
        title: "Con Contratados",
        ene: valores_contratados_list[0],
        feb: valores_contratados_list[1],
        mar: valores_contratados_list[2],
        abr: valores_contratados_list[3],
        may: valores_contratados_list[4],
        jun: valores_contratados_list[5],
        jul: valores_contratados_list[6],
        ago: valores_contratados_list[7],
        sept: valores_contratados_list[8],
        oct: valores_contratados_list[9],
        nov: valores_contratados_list[10],
        dic: valores_contratados_list[11],
      }, {
        title: "Con Interesados",
        ene: valores_interesados_list[0],
        feb: valores_interesados_list[1],
        mar: valores_interesados_list[2],
        abr: valores_interesados_list[3],
        may: valores_interesados_list[4],
        jun: valores_interesados_list[5],
        jul: valores_interesados_list[6],
        ago: valores_interesados_list[7],
        sept: valores_interesados_list[8],
        oct: valores_interesados_list[9],
        nov: valores_interesados_list[10],
        dic: valores_interesados_list[11],
      }, {
        title: "Publicados",
        ene: valores_publicados_list[0],
        feb: valores_publicados_list[1],
        mar: valores_publicados_list[2],
        abr: valores_publicados_list[3],
        may: valores_publicados_list[4],
        jun: valores_publicados_list[5],
        jul: valores_publicados_list[6],
        ago: valores_publicados_list[7],
        sept: valores_publicados_list[8],
        oct: valores_publicados_list[9],
        nov: valores_publicados_list[10],
        dic: valores_publicados_list[11],
      }]
      setViajes(viajes_data);
    }).catch((error) => {
      closeLoad();
      setViajes([]);
    });

  };

  //Funcion para filtrar por año de viajes
  const handleMonth_viajes = (event) => {
    openLoad();
    if ((event.target.value) < 10) {
      var dataMonth = ("0" + (event.target.value));
    } else {
      var dataMonth = (event.target.value);
    }
    setMonth_viajes(dataMonth)
    AXIOS.get(API.KPI_LISTS + "month=" + dataMonth + "&opt=yearly&year=" + yearDate_viajes, {
    }).then((response) => {
      closeLoad();
      let valores_contratados_list = Object.values(response.data.contratados);
      let valores_interesados_list = Object.values(response.data.interesados);
      let valores_publicados_list = Object.values(response.data.publicados);
      const viajes_data = [{
        title: "Con Contratados",
        ene: valores_contratados_list[0],
        feb: valores_contratados_list[1],
        mar: valores_contratados_list[2],
        abr: valores_contratados_list[3],
        may: valores_contratados_list[4],
        jun: valores_contratados_list[5],
        jul: valores_contratados_list[6],
        ago: valores_contratados_list[7],
        sept: valores_contratados_list[8],
        oct: valores_contratados_list[9],
        nov: valores_contratados_list[10],
        dic: valores_contratados_list[11],
      }, {
        title: "Con Interesados",
        ene: valores_interesados_list[0],
        feb: valores_interesados_list[1],
        mar: valores_interesados_list[2],
        abr: valores_interesados_list[3],
        may: valores_interesados_list[4],
        jun: valores_interesados_list[5],
        jul: valores_interesados_list[6],
        ago: valores_interesados_list[7],
        sept: valores_interesados_list[8],
        oct: valores_interesados_list[9],
        nov: valores_interesados_list[10],
        dic: valores_interesados_list[11],
      }, {
        title: "Publicados",
        ene: valores_publicados_list[0],
        feb: valores_publicados_list[1],
        mar: valores_publicados_list[2],
        abr: valores_publicados_list[3],
        may: valores_publicados_list[4],
        jun: valores_publicados_list[5],
        jul: valores_publicados_list[6],
        ago: valores_publicados_list[7],
        sept: valores_publicados_list[8],
        oct: valores_publicados_list[9],
        nov: valores_publicados_list[10],
        dic: valores_publicados_list[11],
      }]
      setViajes(viajes_data);
    }).catch((error) => {
      closeLoad();
      setViajes([]);
    });
  };

  //Funcion para filtrar por mes de transportista
  const handleYear_transportista = (event) => {
    openLoad();
    setYear_transportista(event.target.value)

    AXIOS.get(API.KPI_DRIVERS + "month=" + monthDate_transportista + "&opt=yearly&year=" + (event.target.value), {
    }).then((response) => {
      closeLoad();
      let valores_registrados = Object.values(response.data.registrados); // valores = ["Scott", "Negro", true, 5];
      let valores_fidelizados = Object.values(response.data.fidelizados); // valores = ["Scott", "Negro", true, 5];
      let valores_interesados = Object.values(response.data.interesados); // valores = ["Scott", "Negro", true, 5];
      let valores_descartados = Object.values(response.data.descartados); // valores = ["Scott", "Negro", true, 5];
      let valores_contratados = Object.values(response.data.contratados); // valores = ["Scott", "Negro", true, 5];
      const transportista_data = [{
        title: "Registrados",
        ene: valores_registrados[0],
        feb: valores_registrados[1],
        mar: valores_registrados[2],
        abr: valores_registrados[3],
        may: valores_registrados[4],
        jun: valores_registrados[5],
        jul: valores_registrados[6],
        ago: valores_registrados[7],
        sept: valores_registrados[8],
        oct: valores_registrados[9],
        nov: valores_registrados[10],
        dic: valores_registrados[11],
      }, {
        title: "Fidelizados",
        ene: valores_fidelizados[0],
        feb: valores_fidelizados[1],
        mar: valores_fidelizados[2],
        abr: valores_fidelizados[3],
        may: valores_fidelizados[4],
        jun: valores_fidelizados[5],
        jul: valores_fidelizados[6],
        ago: valores_fidelizados[7],
        sept: valores_fidelizados[8],
        oct: valores_fidelizados[9],
        nov: valores_fidelizados[10],
        dic: valores_fidelizados[11],
      }, {
        title: "Interesados",
        ene: valores_interesados[0],
        feb: valores_interesados[1],
        mar: valores_interesados[2],
        abr: valores_interesados[3],
        may: valores_interesados[4],
        jun: valores_interesados[5],
        jul: valores_interesados[6],
        ago: valores_interesados[7],
        sept: valores_interesados[8],
        oct: valores_interesados[9],
        nov: valores_interesados[10],
        dic: valores_interesados[11],
      }, {
        title: "Descartados",
        ene: valores_descartados[0],
        feb: valores_descartados[1],
        mar: valores_descartados[2],
        abr: valores_descartados[3],
        may: valores_descartados[4],
        jun: valores_descartados[5],
        jul: valores_descartados[6],
        ago: valores_descartados[7],
        sept: valores_descartados[8],
        oct: valores_descartados[9],
        nov: valores_descartados[10],
        dic: valores_descartados[11],
      }, {
        title: "Contratados",
        ene: valores_contratados[0],
        feb: valores_contratados[1],
        mar: valores_contratados[2],
        abr: valores_contratados[3],
        may: valores_contratados[4],
        jun: valores_contratados[5],
        jul: valores_contratados[6],
        ago: valores_contratados[7],
        sept: valores_contratados[8],
        oct: valores_contratados[9],
        nov: valores_contratados[10],
        dic: valores_contratados[11],
      }]
      setTransportista(transportista_data);
    }).catch((error) => {
      closeLoad();
      setTransportista([]);
    });
  };
  //Funcion para filtrar por año de transportista  
  const handleMonth_transportista = (event) => {
    openLoad();
    if ((event.target.value) < 10) {
      var dataMonth = ("0" + (event.target.value));
    } else {
      var dataMonth = (event.target.value);
    }

    setMonth_transportista(dataMonth)


    AXIOS.get(API.KPI_DRIVERS + "month=" + dataMonth + "&opt=yearly&year=" + yearDate_transportista, {
    }).then((response) => {
      closeLoad();
      let valores_registrados = Object.values(response.data.registrados); // valores = ["Scott", "Negro", true, 5];
      let valores_fidelizados = Object.values(response.data.fidelizados); // valores = ["Scott", "Negro", true, 5];
      let valores_interesados = Object.values(response.data.interesados); // valores = ["Scott", "Negro", true, 5];
      let valores_descartados = Object.values(response.data.descartados); // valores = ["Scott", "Negro", true, 5];
      let valores_contratados = Object.values(response.data.contratados); // valores = ["Scott", "Negro", true, 5];
      const transportista_data = [{
        title: "Registrados",
        ene: valores_registrados[0],
        feb: valores_registrados[1],
        mar: valores_registrados[2],
        abr: valores_registrados[3],
        may: valores_registrados[4],
        jun: valores_registrados[5],
        jul: valores_registrados[6],
        ago: valores_registrados[7],
        sept: valores_registrados[8],
        oct: valores_registrados[9],
        nov: valores_registrados[10],
        dic: valores_registrados[11],
      }, {
        title: "Fidelizados",
        ene: valores_fidelizados[0],
        feb: valores_fidelizados[1],
        mar: valores_fidelizados[2],
        abr: valores_fidelizados[3],
        may: valores_fidelizados[4],
        jun: valores_fidelizados[5],
        jul: valores_fidelizados[6],
        ago: valores_fidelizados[7],
        sept: valores_fidelizados[8],
        oct: valores_fidelizados[9],
        nov: valores_fidelizados[10],
        dic: valores_fidelizados[11],
      }, {
        title: "Interesados",
        ene: valores_interesados[0],
        feb: valores_interesados[1],
        mar: valores_interesados[2],
        abr: valores_interesados[3],
        may: valores_interesados[4],
        jun: valores_interesados[5],
        jul: valores_interesados[6],
        ago: valores_interesados[7],
        sept: valores_interesados[8],
        oct: valores_interesados[9],
        nov: valores_interesados[10],
        dic: valores_interesados[11],
      }, {
        title: "Descartados",
        ene: valores_descartados[0],
        feb: valores_descartados[1],
        mar: valores_descartados[2],
        abr: valores_descartados[3],
        may: valores_descartados[4],
        jun: valores_descartados[5],
        jul: valores_descartados[6],
        ago: valores_descartados[7],
        sept: valores_descartados[8],
        oct: valores_descartados[9],
        nov: valores_descartados[10],
        dic: valores_descartados[11],
      }, {
        title: "Contratados",
        ene: valores_contratados[0],
        feb: valores_contratados[1],
        mar: valores_contratados[2],
        abr: valores_contratados[3],
        may: valores_contratados[4],
        jun: valores_contratados[5],
        jul: valores_contratados[6],
        ago: valores_contratados[7],
        sept: valores_contratados[8],
        oct: valores_contratados[9],
        nov: valores_contratados[10],
        dic: valores_contratados[11],
      }]
      setTransportista(transportista_data);
    }).catch((error) => {
      closeLoad();
      setTransportista([]);
    });
  };
  //Funcion para resfrescar tabla de transportista
  const refresh_transportista = () => {
    openLoad();

    AXIOS.get(API.KPI_DRIVERS + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {
      closeLoad();
      let valores_registrados = Object.values(response.data.registrados); // valores = ["Scott", "Negro", true, 5];
      let valores_fidelizados = Object.values(response.data.fidelizados); // valores = ["Scott", "Negro", true, 5];
      let valores_interesados = Object.values(response.data.interesados); // valores = ["Scott", "Negro", true, 5];
      let valores_descartados = Object.values(response.data.descartados); // valores = ["Scott", "Negro", true, 5];
      let valores_contratados = Object.values(response.data.contratados); // valores = ["Scott", "Negro", true, 5];
      const transportista_data = [{
        title: "Registrados",
        ene: valores_registrados[0],
        feb: valores_registrados[1],
        mar: valores_registrados[2],
        abr: valores_registrados[3],
        may: valores_registrados[4],
        jun: valores_registrados[5],
        jul: valores_registrados[6],
        ago: valores_registrados[7],
        sept: valores_registrados[8],
        oct: valores_registrados[9],
        nov: valores_registrados[10],
        dic: valores_registrados[11],
      }, {
        title: "Fidelizados",
        ene: valores_fidelizados[0],
        feb: valores_fidelizados[1],
        mar: valores_fidelizados[2],
        abr: valores_fidelizados[3],
        may: valores_fidelizados[4],
        jun: valores_fidelizados[5],
        jul: valores_fidelizados[6],
        ago: valores_fidelizados[7],
        sept: valores_fidelizados[8],
        oct: valores_fidelizados[9],
        nov: valores_fidelizados[10],
        dic: valores_fidelizados[11],
      }, {
        title: "Interesados",
        ene: valores_interesados[0],
        feb: valores_interesados[1],
        mar: valores_interesados[2],
        abr: valores_interesados[3],
        may: valores_interesados[4],
        jun: valores_interesados[5],
        jul: valores_interesados[6],
        ago: valores_interesados[7],
        sept: valores_interesados[8],
        oct: valores_interesados[9],
        nov: valores_interesados[10],
        dic: valores_interesados[11],
      }, {
        title: "Descartados",
        ene: valores_descartados[0],
        feb: valores_descartados[1],
        mar: valores_descartados[2],
        abr: valores_descartados[3],
        may: valores_descartados[4],
        jun: valores_descartados[5],
        jul: valores_descartados[6],
        ago: valores_descartados[7],
        sept: valores_descartados[8],
        oct: valores_descartados[9],
        nov: valores_descartados[10],
        dic: valores_descartados[11],
      }, {
        title: "Contratados",
        ene: valores_contratados[0],
        feb: valores_contratados[1],
        mar: valores_contratados[2],
        abr: valores_contratados[3],
        may: valores_contratados[4],
        jun: valores_contratados[5],
        jul: valores_contratados[6],
        ago: valores_contratados[7],
        sept: valores_contratados[8],
        oct: valores_contratados[9],
        nov: valores_contratados[10],
        dic: valores_contratados[11],
      }]
      setTransportista(transportista_data);
    });
  };
  //Funcion para refrescar tabla de viajes 
  const refresh_viajes = () => {
    openLoad();

    AXIOS.get(API.KPI_LISTS + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {
      closeLoad();
      let valores_contratados_list = Object.values(response.data.contratados);
      let valores_interesados_list = Object.values(response.data.interesados);
      let valores_publicados_list = Object.values(response.data.publicados);
      const viajes_data = [{
        title: "Con Contratados",
        ene: valores_contratados_list[0],
        feb: valores_contratados_list[1],
        mar: valores_contratados_list[2],
        abr: valores_contratados_list[3],
        may: valores_contratados_list[4],
        jun: valores_contratados_list[5],
        jul: valores_contratados_list[6],
        ago: valores_contratados_list[7],
        sept: valores_contratados_list[8],
        oct: valores_contratados_list[9],
        nov: valores_contratados_list[10],
        dic: valores_contratados_list[11],
      }, {
        title: "Con Interesados",
        ene: valores_interesados_list[0],
        feb: valores_interesados_list[1],
        mar: valores_interesados_list[2],
        abr: valores_interesados_list[3],
        may: valores_interesados_list[4],
        jun: valores_interesados_list[5],
        jul: valores_interesados_list[6],
        ago: valores_interesados_list[7],
        sept: valores_interesados_list[8],
        oct: valores_interesados_list[9],
        nov: valores_interesados_list[10],
        dic: valores_interesados_list[11],
      }, {
        title: "Publicados",
        ene: valores_publicados_list[0],
        feb: valores_publicados_list[1],
        mar: valores_publicados_list[2],
        abr: valores_publicados_list[3],
        may: valores_publicados_list[4],
        jun: valores_publicados_list[5],
        jul: valores_publicados_list[6],
        ago: valores_publicados_list[7],
        sept: valores_publicados_list[8],
        oct: valores_publicados_list[9],
        nov: valores_publicados_list[10],
        dic: valores_publicados_list[11],
      }]
      setViajes(viajes_data);
    });
  };
  //Funcion para filtrar por mes de ruta
  const handleYear_ruta = (event) => {
    openLoad();
    setYear_ruta(event.target.value)

    AXIOS.get(API.KPI_ROUTES + "month=" + monthDate_transportista + "&opt=yearly&year=" + (event.target.value), {
    }).then((response) => {
      setcompanies(response.data.rutas);
      if (response) {
        closeLoad();
      }
    }).catch((error) => {
      closeLoad();
      setcompanies([]);
    });
  };
  //Funcion para filtrar por año de ruta 
  const handleMonth_ruta = (event) => {
    openLoad();
    if ((event.target.value) < 10) {
      var dataMonth = ("0" + (event.target.value));
    } else {
      var dataMonth = (event.target.value);
    }
    setMonth_ruta(dataMonth)
    AXIOS.get(API.KPI_ROUTES + "month=" + dataMonth + "&opt=yearly&year=" + yearDate_ruta, {
    }).then((response) => {
      setcompanies(response.data.rutas);
      if (response) {
        closeLoad();
      }
    }).catch((error) => {
      closeLoad();
      setcompanies([]);
    });
  };
  //Funcion para refrescar tabla de ruta
  const refresh_ruta = () => {
    openLoad();
    AXIOS.get(API.KPI_ROUTES + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {
      setcompanies(response.data.rutas);
      if (response) {
        closeLoad();
      }
    });
  };

  React.useEffect(() => { //! AQUI
    //Pre-carga de apis
    AXIOS.get(API.KPI_ROUTES + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {

      setListTimes(response.data.tiempo_promedio)
      setHistoricoCantidad(response.data.historico)
      setCasosPorEstados(response.data.actual)
      // totalsAndTimes = response.data.data_counts;}
      setcompanies(response.data.rutas);
      if (response) {
        setLoading(false);
      }
    });
    AXIOS.get(API.KPI_DRIVERS + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {
      let valores_registrados = Object.values(response.data.registrados); // valores = ["Scott", "Negro", true, 5];
      let valores_fidelizados = Object.values(response.data.fidelizados); // valores = ["Scott", "Negro", true, 5];
      let valores_interesados = Object.values(response.data.interesados); // valores = ["Scott", "Negro", true, 5];
      let valores_descartados = Object.values(response.data.descartados); // valores = ["Scott", "Negro", true, 5];
      let valores_contratados = Object.values(response.data.contratados); // valores = ["Scott", "Negro", true, 5];
      const transportista_data = [{
        title: "Registrados",
        ene: valores_registrados[0],
        feb: valores_registrados[1],
        mar: valores_registrados[2],
        abr: valores_registrados[3],
        may: valores_registrados[4],
        jun: valores_registrados[5],
        jul: valores_registrados[6],
        ago: valores_registrados[7],
        sept: valores_registrados[8],
        oct: valores_registrados[9],
        nov: valores_registrados[10],
        dic: valores_registrados[11],
      }, {
        title: "Fidelizados",
        ene: valores_fidelizados[0],
        feb: valores_fidelizados[1],
        mar: valores_fidelizados[2],
        abr: valores_fidelizados[3],
        may: valores_fidelizados[4],
        jun: valores_fidelizados[5],
        jul: valores_fidelizados[6],
        ago: valores_fidelizados[7],
        sept: valores_fidelizados[8],
        oct: valores_fidelizados[9],
        nov: valores_fidelizados[10],
        dic: valores_fidelizados[11],
      }, {
        title: "Interesados",
        ene: valores_interesados[0],
        feb: valores_interesados[1],
        mar: valores_interesados[2],
        abr: valores_interesados[3],
        may: valores_interesados[4],
        jun: valores_interesados[5],
        jul: valores_interesados[6],
        ago: valores_interesados[7],
        sept: valores_interesados[8],
        oct: valores_interesados[9],
        nov: valores_interesados[10],
        dic: valores_interesados[11],
      }, {
        title: "Descartados",
        ene: valores_descartados[0],
        feb: valores_descartados[1],
        mar: valores_descartados[2],
        abr: valores_descartados[3],
        may: valores_descartados[4],
        jun: valores_descartados[5],
        jul: valores_descartados[6],
        ago: valores_descartados[7],
        sept: valores_descartados[8],
        oct: valores_descartados[9],
        nov: valores_descartados[10],
        dic: valores_descartados[11],
      }, {
        title: "Contratados",
        ene: valores_contratados[0],
        feb: valores_contratados[1],
        mar: valores_contratados[2],
        abr: valores_contratados[3],
        may: valores_contratados[4],
        jun: valores_contratados[5],
        jul: valores_contratados[6],
        ago: valores_contratados[7],
        sept: valores_contratados[8],
        oct: valores_contratados[9],
        nov: valores_contratados[10],
        dic: valores_contratados[11],
      }]
      setTransportista(transportista_data);
    });
    AXIOS.get(API.KPI_LISTS + "month=" + month + "&opt=yearly&year=" + year, {
    }).then((response) => {
      let valores_contratados_list = Object.values(response.data.contratados);
      let valores_interesados_list = Object.values(response.data.interesados);
      let valores_publicados_list = Object.values(response.data.publicados);
      const viajes_data = [{
        title: "Con Contratados",
        ene: valores_contratados_list[0],
        feb: valores_contratados_list[1],
        mar: valores_contratados_list[2],
        abr: valores_contratados_list[3],
        may: valores_contratados_list[4],
        jun: valores_contratados_list[5],
        jul: valores_contratados_list[6],
        ago: valores_contratados_list[7],
        sept: valores_contratados_list[8],
        oct: valores_contratados_list[9],
        nov: valores_contratados_list[10],
        dic: valores_contratados_list[11],
      }, {
        title: "Con Interesados",
        ene: valores_interesados_list[0],
        feb: valores_interesados_list[1],
        mar: valores_interesados_list[2],
        abr: valores_interesados_list[3],
        may: valores_interesados_list[4],
        jun: valores_interesados_list[5],
        jul: valores_interesados_list[6],
        ago: valores_interesados_list[7],
        sept: valores_interesados_list[8],
        oct: valores_interesados_list[9],
        nov: valores_interesados_list[10],
        dic: valores_interesados_list[11],
      }, {
        title: "Publicados",
        ene: valores_publicados_list[0],
        feb: valores_publicados_list[1],
        mar: valores_publicados_list[2],
        abr: valores_publicados_list[3],
        may: valores_publicados_list[4],
        jun: valores_publicados_list[5],
        jul: valores_publicados_list[6],
        ago: valores_publicados_list[7],
        sept: valores_publicados_list[8],
        oct: valores_publicados_list[9],
        nov: valores_publicados_list[10],
        dic: valores_publicados_list[11],
      }]
      setViajes(viajes_data);
    });
  }, []);

  //Inicializando datos que trae la api de get
  function createData(date, destino, origen, quantity) {
    return { date, destino, origen, quantity };
  }
  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.date, item.destino, item.origen, item.quantity)
    )
  });
  //Inicializando datos que trae la api de get de viajes
  function createDatas(title, ene, feb, mar, abr, may, jun, jul, ago, sept, oct, nov, dic) {
    return { title, ene, feb, mar, abr, may, jun, jul, ago, sept, oct, nov, dic };
  }
  //Seleccion de datos que se mostraran en la tabla de viajes
  const viajes = api_viajes.map(item => {
    return (
      createDatas(item.title, item.ene, item.feb, item.mar, item.abr, item.may, item.jun, item.jul, item.ago, item.sept, item.oct, item.nov, item.dic)
    )
  });
  //Inicializando datos que trae la api de get de viajes
  function createDatas(title, ene, feb, mar, abr, may, jun, jul, ago, sept, oct, nov, dic) {
    return { title, ene, feb, mar, abr, may, jun, jul, ago, sept, oct, nov, dic };
  }
  //Seleccion de datos que se mostraran en la tabla de tansportista
  const transp = api_transportista.map(item => {
    return (
      createDatas(item.title, item.ene, item.feb, item.mar, item.abr, item.may, item.jun, item.jul, item.ago, item.sept, item.oct, item.nov, item.dic)
    )
  });



  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> : <>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>


        <Dashboard title='Key Performance Indicator'>
        {/* Nueva sección de tiempos */}
        <Grid item xs={12} md={8} lg={29}>
              <Box>
                <h3>Tiempos promedio</h3>
                <div className='list-box'>
                  {listTimes.map((item) => 
                    <div className='box times'>
                      <div className='title-box'>
                        <b>{item.name}</b>
                      </div>
                      <div className='content-box'>
                        <b>{item.cantidad} H:M</b>
                      </div>                  
                    </div>
                  )}              
                </div>
              </Box>
          </Grid>

          {/* Nueva sección de totales */}
        <Grid item xs={12} md={8} lg={29} style={{ marginTop: 30 }}>
          <Box>
                <h3>Historico de cantidad</h3>
                <div className='list-box'>
                {historicoCantidad.map((item) => 
                  <div className='box'>
                    <div>
                      <div className='title-box'>
                        <b>{item.name}</b>
                      </div>
                      <div className='content-box'>
                        <b>{item.cantidad}</b>
                      </div>                  
                    </div>
                    <div className='icon'>
                      <Equalizer style={{ fontSize: 60, color: "#5cb9f5" }} />
                    </div>
                </div>
                )}                 
                </div>
              </Box>
          </Grid>

                    {/* Nueva sección de Estados */}
        <Grid item xs={12} md={8} lg={29} style={{ marginTop: 30 }}>
              <Box>
                <h3>Casos por estado actual</h3>             
                <div className='list-box'>
                  {casosPorEstados.map((item) => 
                    <div className='box'>
                      <div>                      
                        <div className='title-box'>
                          <b>{item.name}</b>
                        </div>
                        <div className='content-box'>
                          <b>{item.cantidad}</b>
                        </div>                  
                      </div>
                      <div className='icon'>
                        <Equalizer style={{ fontSize: 60, color: "#5cb9f5" }} />
                      </div>
                    </div>
                  )}   
                </div>
              </Box>
          </Grid>


          {/* Tabla de empresas */}
          <Grid item xs={12} md={8} lg={29} style={{ marginTop: 30 }}>
            <Paper
              sx={{
                p: 2,
                flexDirection: 'column',
                height: "auto",
              }}
            >
              <h3>Viajes de empresas de transportes</h3>
              <div style={{ float: "right", background: "gray", borderRadius: 30 }}>
                <Button style={{ color: "white" }} onClick={() => refresh_viajes()}><RefreshIcon /></Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Box sx={{ minWidth: 120 }}>
                  {/*       
           <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        // defaultValue={datos1.origin}
        disableClearable
        options={listadoCiudades.map((option) => option.nombre)}
        renderInput={(params) => (
          <TextField
        
          // helperText="Complete el campo"
            {...params}
            id="origin"  autoFocus  size="small"
            name="origin" required  
            label="Origen (Campo obligatorio)"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
           <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        // defaultValue={datos1.destiny}
        disableClearable
        options={listadoCiudades.map((option) => option.nombre)}
        renderInput={(params) => (
          <TextField
            {...params}
            id="destiny"  autoFocus  size="small"
            name="destiny" required  
            label="Destino (Campo obligatorio)"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      /> */}
                  {/* Filtro por año */}
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Por año
                    </InputLabel>
                    <NativeSelect
                      defaultValue={year}
                      onChange={handleYear_viajes}
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option value={2017}>2017</option>
                      <option value={2018}>2018</option>
                      <option value={2019}>2019</option>
                      <option value={2020}>2020</option>
                      <option value={2021}>2021</option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                      <option value={2026}>2026</option>
                      <option value={2027}>2027</option>
                      <option value={2028}>2028</option>
                      <option value={2029}>2029</option>
                      <option value={2030}>2030</option>
                      <option value={2031}>2031</option>
                      <option value={2032}>2032</option>
                      <option value={2033}>2033</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
                <div style={{ marginLeft: 30 }}>
                  {/* Filtro por mes */}
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Por Mes
                      </InputLabel>
                      <NativeSelect
                        defaultValue={months}
                        onChange={handleMonth_viajes}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={1}>Enero</option>
                        <option value={2}>Febrero</option>
                        <option value={3}>Marzo</option>
                        <option value={4}>Abril</option>
                        <option value={5}>Mayo</option>
                        <option value={6}>Junio</option>
                        <option value={7}>Julio</option>
                        <option value={8}>Agosto</option>
                        <option value={9}>Septiembre</option>
                        <option value={10}>Octubre</option>
                        <option value={11}>Noviembre</option>
                        <option value={12}>Diciembre</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </div>
                <div style={{ marginLeft: 30 }}>
                  {/*             
                <Box sx={{ minWidth: 110 }}>
                <Autocomplete
      disablePortal
      id="combo-box-demo"
      // options={listadoCiudades}
      // sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Origen" />}
    />
                </Box>  
             
                </div>
               
                <div style={{marginLeft:30}}>
            
                <Box sx={{ minWidth: 110 }}>
                <Autocomplete
      disablePortal
      id="combo-box-demo"
      // options={listadoCiudades}
      // sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Destino" />}
    />

                </Box>   */}
                </div>
              </div>
              <div>
                <div style={{ marginTop: 20 }}></div>
                {/* Contenido de tabla */}
                <TableContainer sx={{ height: "auto" }} component={Paper}>
                  <Table aria-label="simple table" id="empresa">
                    <TableHead style={{ background: "#e9e9e9" }}>
                      <TableRow>
                        <TableCell align="center" style={{ background: "white" }}></TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Ene</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Feb</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Mar</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Abr</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />May</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Jun</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Jul</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Ago</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Sept</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Oct</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Nov</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Dic</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {viajes.map((row) => (
                        <TableRow
                          key={row.name}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" style={{ background: "#e9e9e9" }}><StorageIcon style={{ fontSize: 14 }} /> {row.title}</TableCell>
                          <TableCell align="center">{(row.ene == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.ene}</b></>)}</TableCell>
                          <TableCell align="center">{(row.feb == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.feb}</b></>)}</TableCell>
                          <TableCell align="center">{(row.mar == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.mar}</b></>)}</TableCell>
                          <TableCell align="center">{(row.abr == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.abr}</b></>)}</TableCell>
                          <TableCell align="center">{(row.may == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.may}</b></>)}</TableCell>
                          <TableCell align="center">{(row.jun == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.jun}</b></>)}</TableCell>
                          <TableCell align="center">{(row.jul == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.jul}</b></>)}</TableCell>
                          <TableCell align="center">{(row.ago == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.ago}</b></>)}</TableCell>
                          <TableCell align="center">{(row.sept == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.sept}</b></>)}</TableCell>
                          <TableCell align="center">{(row.oct == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.oct}</b></>)}</TableCell>
                          <TableCell align="center">{(row.nov == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.nov}</b></>)}</TableCell>
                          <TableCell align="center">{(row.dic == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.dic}</b></>)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {<>{(viajes == []) ?
                  <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
                {/* Boton para exportar en excel */}
                <div style={{ display: "flex" }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="empresa"
                    filename="Viajes de empresas de transportes"
                    sheet="tablexls"
                    buttonText="Exportar EXCEL" />
                  <AsyncCSV />
                  <button onClick={() => exportFile()}>Exportar TXT</button>
                </div>
              </div>
            </Paper>
          </Grid>
          {/* Tabla de transportista */}
          <div style={{ marginTop: 30 }} />
          <Grid item xs={12} md={8} lg={29}>
            <Paper
              sx={{
                p: 2,
                flexDirection: 'column',
                height: "auto",
              }}>
              <h3>Transportistas</h3>
              <div style={{ float: "right", background: "gray", borderRadius: 30 }}>
                <Button style={{ color: "white" }} onClick={() => refresh_transportista()}><RefreshIcon /></Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Box sx={{ minWidth: 120 }}>
                  {/* Filtro por año */}
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Por año
                    </InputLabel>
                    <NativeSelect
                      defaultValue={year}
                      onChange={handleYear_transportista}
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option value={2017}>2017</option>
                      <option value={2018}>2018</option>
                      <option value={2019}>2019</option>
                      <option value={2020}>2020</option>
                      <option value={2021}>2021</option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                      <option value={2026}>2026</option>
                      <option value={2027}>2027</option>
                      <option value={2028}>2028</option>
                      <option value={2029}>2029</option>
                      <option value={2030}>2030</option>
                      <option value={2031}>2031</option>
                      <option value={2032}>2032</option>
                      <option value={2033}>2033</option>
                    </NativeSelect>
                  </FormControl>
                </Box>

                <div style={{ marginLeft: 30 }}>
                  {/* Filtro por mes */}
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Por Mes
                      </InputLabel>
                      <NativeSelect
                        defaultValue={months}
                        onChange={handleMonth_transportista}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={1}>Enero</option>
                        <option value={2}>Febrero</option>
                        <option value={3}>Marzo</option>
                        <option value={4}>Abril</option>
                        <option value={5}>Mayo</option>
                        <option value={6}>Junio</option>
                        <option value={7}>Julio</option>
                        <option value={8}>Agosto</option>
                        <option value={9}>Septiembre</option>
                        <option value={10}>Octubre</option>
                        <option value={11}>Noviembre</option>
                        <option value={12}>Diciembre</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div>

                <div style={{ marginTop: 20 }}></div>
                <TableContainer sx={{ height: "auto" }} component={Paper}>
                  <Table aria-label="simple table" id="transportista">
                    <TableHead style={{ background: "#e9e9e9" }}>
                      <TableRow>
                        {/* Tabla de contenido de transportista */}
                        <TableCell align="center" style={{ background: "white" }}></TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Ene</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Feb</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Mar</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Abr</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />May</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Jun</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Jul</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Ago</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Sept</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Oct</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Nov</TableCell>
                        <TableCell align="center"><CalendarMonthIcon style={{ fontSize: 14 }} />Dic</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transp.map((row) => (
                        <TableRow
                          hover role="checkbox" tabIndex={-1}
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" style={{ background: "#e9e9e9" }}><StorageIcon style={{ fontSize: 14 }} /> {row.title}</TableCell>
                          <TableCell align="center">{(row.ene == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.ene}</b></>)}</TableCell>
                          <TableCell align="center">{(row.feb == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.feb}</b></>)}</TableCell>
                          <TableCell align="center">{(row.mar == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.mar}</b></>)}</TableCell>
                          <TableCell align="center">{(row.abr == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.abr}</b></>)}</TableCell>
                          <TableCell align="center">{(row.may == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.may}</b></>)}</TableCell>
                          <TableCell align="center">{(row.jun == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.jun}</b></>)}</TableCell>
                          <TableCell align="center">{(row.jul == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.jul}</b></>)}</TableCell>
                          <TableCell align="center">{(row.ago == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.ago}</b></>)}</TableCell>
                          <TableCell align="center">{(row.sept == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.sept}</b></>)}</TableCell>
                          <TableCell align="center">{(row.oct == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.oct}</b></>)}</TableCell>
                          <TableCell align="center">{(row.nov == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.nov}</b></>)}</TableCell>
                          <TableCell align="center">{(row.dic == 0) ? (<>{<div style={{ color: "orange", fontSize: 20 }}><b>{"--"}</b>
                          </div>}</>) : (<><b>{row.dic}</b></>)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {<>{(transp == []) ?
                  <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
                <div style={{ display: "flex" }}>
                  {/* Boton para exportar en excel */}
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="transportista"
                    filename="Transportistas"
                    sheet="tablexls"
                    buttonText="Exportar EXCEL" />
                  <AsyncCSV />
                  <button onClick={() => exportFile()}>Exportar TXT</button>
                </div>
              </div>
            </Paper>
          </Grid>

          <div style={{ marginTop: 30 }} />
          <Grid item xs={12} md={8} lg={29}>
            <Paper
              sx={{
                p: 2,
                flexDirection: 'column',
                height: "auto",
              }}>
              <h3>Rutas más frecuentes </h3>
              <div style={{ float: "right", background: "gray", borderRadius: 30 }}>
                <Button style={{ color: "white" }} onClick={() => refresh_ruta()}><RefreshIcon /></Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    {/* Filtro por año */}
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Por año
                    </InputLabel>
                    <NativeSelect
                      defaultValue={year}
                      onChange={handleYear_ruta}
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option value={2017}>2017</option>
                      <option value={2018}>2018</option>
                      <option value={2019}>2019</option>
                      <option value={2020}>2020</option>
                      <option value={2021}>2021</option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                      <option value={2026}>2026</option>
                      <option value={2027}>2027</option>
                      <option value={2028}>2028</option>
                      <option value={2029}>2029</option>
                      <option value={2030}>2030</option>
                      <option value={2031}>2031</option>
                      <option value={2032}>2032</option>
                      <option value={2033}>2033</option>
                    </NativeSelect>
                  </FormControl>
                </Box>

                <div style={{ marginLeft: 30 }}>
                  {/* Filtro por mes             */}
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Por Mes
                      </InputLabel>
                      <NativeSelect
                        defaultValue={months}
                        onChange={handleMonth_ruta}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={1}>Enero</option>
                        <option value={2}>Febrero</option>
                        <option value={3}>Marzo</option>
                        <option value={4}>Abril</option>
                        <option value={5}>Mayo</option>
                        <option value={6}>Junio</option>
                        <option value={7}>Julio</option>
                        <option value={8}>Agosto</option>
                        <option value={9}>Septiembre</option>
                        <option value={10}>Octubre</option>
                        <option value={11}>Noviembre</option>
                        <option value={12}>Diciembre</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div>
                <div style={{ marginTop: 20 }}></div>
                {/* Tabla de contenido de rutas */}
                <TableContainer sx={{ height: 400 }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Origen</TableCell>
                        <TableCell align="left">Destino</TableCell>
                        <TableCell align="center">Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{row.origen}</TableCell>
                          <TableCell align="left">{row.destino}</TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {<>{(rows == []) ?
                  <Stack justifyContent="center" style={{ display: "center", marginTop: 3 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
              </div>
            </Paper>
          </Grid>
        </Dashboard>
      </>}</>);
};
export default KpiPage;