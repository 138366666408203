//Importacion de complementos
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help'
import TimeIcon from '@mui/icons-material/Update'
import BusinessIcon from '@mui/icons-material/Business'
import MapIcon from '@mui/icons-material/Map'
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import { useNavigate } from "react-router-dom";

export default function ListPages() {
  //Inicializacion de datos
  const [dash, dashopen] = React.useState(false);
  const openDash = () => {
    dashopen(!dash);
  };
  const [trips, tripsopen] = React.useState(false);
  const openTrips = () => {
    tripsopen(!trips);
  };
  const [avaibles, tripsavaibles] = React.useState(false);
  const openSvaibles = () => {
    tripsavaibles(!avaibles);
  };
  const [tripsw, tripsopenw] = React.useState(false);
  const openTripsw = () => {
    tripsopenw(!tripsw);
  };
  const [avaible_n, avaible_nopen] = React.useState(false);
  const openAvaible_n = () => {
    avaible_nopen(!avaible_n);
  };
  const [fleet, fleetopen] = React.useState(false);
  const openFleet = () => {
    fleetopen(!fleet);
  };
  const [buss, bussopen] = React.useState(false);
  const openBussines = () => {
    bussopen(!buss);
  };
  const [movil, movilopen] = React.useState(false);
  const openMovil = () => {
    movilopen(!movil);
  };
  const [benefits, benefitsopen] = React.useState(false);
  const openBenefits = () => {
    benefitsopen(!benefits);
  };
  const [adv, advopen] = React.useState(false);
  const openAdvertising = () => {
    advopen(!adv);
  };
  const [pqrs, pqrsopen] = React.useState(false);
  const openPqrs = () => {
    pqrsopen(!pqrs);
  };

  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  const [rolePermiso, setRolePermiso] = React.useState();

  React.useEffect(() => {
    const roles_usuario = JSON.parse(localStorage.getItem('roles_usuario'));
    setRolePermiso(roles_usuario);
  }, []);

  return (
    <List>
      {/* Vista de menu lateral */}
      {
        rolePermiso && (rolePermiso.kpi || rolePermiso.carrier || rolePermiso.detail_publications || rolePermiso.most_frequent_routes) ? (
          <>
            <ListItemButton onClick={openDash}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
              {dash ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={dash} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.kpi ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/kpi')}>
                    <ListItemText alaing primary="KPI´S" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.carrier ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/carrier')}>
                    <ListItemText alaing primary="Transportistas" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.detail_publications ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/detail-publications')}>
                    <ListItemText alaing primary="Detalles de publicaciones" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.most_frequent_routes ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/most-frequent-routes')}>
                    <ListItemText alaing primary="Rutas más frecuentes" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Tablero Carga */}
      {
        rolePermiso && (rolePermiso.available || rolePermiso.available_trips) ? (
          <>
            <ListItemButton onClick={openAvaible_n}>
              <ListItemIcon>
                <PersonalVideoIcon />
              </ListItemIcon>
              <ListItemText primary="Tablero Carga" />
              {avaible_n ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={avaible_n} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.available ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/available')}>
                    <ListItemText alaing primary="Conductores Disponibles" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.available_trips ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/available-trips')}>
                    <ListItemText alaing primary="Viajes Disponibles" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Disponible */}
      {rolePermiso && rolePermiso.avaible_enturnado ? (
        <ListItemButton onClick={() => linkTo('/avaible-enturnado')}>
          <ListItemIcon>
            <TimeIcon />
          </ListItemIcon>
          <ListItemText primary="Disponible" />
        </ListItemButton>
      ) : null}
      {/* Vista de Viajes */}
      {
        rolePermiso && (rolePermiso.services || rolePermiso.manifested) ? (
          <>
            <ListItemButton onClick={openTrips}>
              <ListItemIcon>
                <TimeIcon />
              </ListItemIcon>
              <ListItemText primary="Viajes" />
              {trips ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={trips} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.services ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/services')}>
                    <ListItemText alaing primary="Publicados" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.manifested ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/manifested')}>
                    <ListItemText alaing primary="Transportistas en ruta" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Flota */}
      {
        rolePermiso && (rolePermiso.fidelizada || rolePermiso.miflota) ? (
          <>
            <ListItemButton onClick={openTripsw}>
              <ListItemIcon>
                <TimeIcon />
              </ListItemIcon>
              <ListItemText primary="Flota" />
              {tripsw ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={tripsw} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.fidelizada ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/fidelizada')}>
                    <ListItemText alaing primary="Fidelizada" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.miflota ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/miflota')}>
                    <ListItemText alaing primary="Mi Flota Segura" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Mi Empresa */}
      {
        rolePermiso && (rolePermiso.master || rolePermiso.roles_permissions) ? (
          <>
            <ListItemButton onClick={openBussines}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Mi Empresa" />
              {buss ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={buss} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.master ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/master')}>
                    <ListItemText alaing primary="Maestro de usuarios" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.roles_permissions ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/roles-permissions')}>
                    <ListItemText alaing primary="Permisos por roles" />
                  </ListItemButton>
                ) : null}
                <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/attachment_manager')}>
                    <ListItemText alaing primary="Adminitracion de anexos" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Mi Aplicación Móvil */}
      {
        rolePermiso && (rolePermiso.drivers || rolePermiso.owners || rolePermiso.forks) ? (
          <>
            <ListItemButton onClick={openMovil}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Aplicación Móvil" />
              {movil ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={movil} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.drivers ? (
                  <ListItemButton sx={{ pl: 2 }} onClick={() => linkTo('/drivers')}>
                    <ListItemText alaing primary="Ver todos los Transportistas" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.owners ? (
                  <ListItemButton sx={{ pl: 2 }} onClick={() => linkTo('/owners')}>
                    <ListItemText alaing primary="Ver todos los Propietarios" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.forks ? (
                  <ListItemButton sx={{ pl: 2 }} onClick={() => linkTo('/forks')}>
                    <ListItemText alaing primary="Ver todos los Tenedores" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de Beneficios */}
      {rolePermiso && rolePermiso.benefits ? (
        <>
          <ListItemButton onClick={openBenefits}>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Beneficios" />
            {benefits ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={benefits} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/benefits')}>
                <ListItemText alaing primary="Publicados" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : null}
      {/* Vista de Publicidad */}
      {
        rolePermiso && (rolePermiso.advertisement || rolePermiso.static || rolePermiso.notice) ? (
          <>
            <ListItemButton onClick={openAdvertising}>
              <ListItemIcon>
                <PictureInPictureIcon />
              </ListItemIcon>
              <ListItemText primary="Publicidad" />
              {adv ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={adv} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {rolePermiso && rolePermiso.advertisement ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/advertisement')}>
                    <ListItemText alaing primary="Anuncios" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.static ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/static')}>
                    <ListItemText alaing primary="Estadísticas" />
                  </ListItemButton>
                ) : null}
                {rolePermiso && rolePermiso.notice ? (
                  <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/notice')}>
                    <ListItemText alaing primary="Noticias" />
                  </ListItemButton>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null
      }
      {/* Vista de PQRS */}
      {rolePermiso && rolePermiso.pqrs ? (
        <>
          <ListItemButton onClick={openPqrs}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="PQRS" />
            {pqrs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={pqrs} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => linkTo('/pqrs')}>
                <ListItemText alaing primary="PQRS" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : null}
    </List>
  );
}