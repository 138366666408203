import React, { useState, useEffect } from 'react';
import API, { AXIOS } from '../../environment/api';
import { Divider, Stack, Grid, TextField, MenuItem, FormControl, InputLabel, Select, Button,Alert, Snackbar } from '@mui/material';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumTenedor({func_update, ...props}) {
    const [loading, setLoading] = useState(true);
    const [blockButton, set_blockButton] = useState(false);
    const [dataTypeDNI, array_typeDNI] = useState([]);
    const [hv_nombres_tenedor, set_hv_nombres_tenedor] = useState('');
    const [hv_apellidos_tenedor, set_hv_apellidos_tenedor] = useState('');
    const [hv_tipoDNI_tenedor, set_hv_tipoDNI_tenedor] = useState('');
    const [hv_DNI_tenedor, set_hv_DNI_tenedor] = useState('');
    const [hv_titulCuenta_tenedor, set_hv_titulCuenta_tenedor] = useState('');
    const [hv_tipDNICuentaTitul_id_tenedor, set_hv_tipDNICuentaTitul_id_tenedor] = useState('');
    const [hv_DNICuentaTitul_tenedor, set_hv_DNICuentaTitul_tenedor] = useState('');
    const [hv_cuentaPropia_tenedor, set_hv_cuentaPropia_tenedor] = useState('');
    const [dataBank, array_Bank] = useState([]);
    const [severity, setSeverity] = useState("error");
    const [openSnackbar, setOpeSnackbar] = useState(false);
    const [messageResponse, setMessageResponse] = useState("");

    useEffect(() => {
        if (props.data.data.tenedor_tipoidentificaciontitular_id === "1") {
            set_hv_titulCuenta_tenedor(props.data.data.tenedor_nombre + " " + props.data.data.tenedor_apellido ? props.data.data.tenedor_nombre + " " + props.data.data.tenedor_apellido : "");
            set_hv_tipDNICuentaTitul_id_tenedor(props.data.data.tenedor_tipoidentificacion_id ? props.data.data.tenedor_tipoidentificacion_id : "");
            set_hv_DNICuentaTitul_tenedor(props.data.data.tenedor_identificacion ? props.data.data.tenedor_identificacion : "");
        } else {
            set_hv_titulCuenta_tenedor(props.data.data.tenedor_nombre_titular ? props.data.data.tenedor_nombre_titular : "");
            set_hv_tipDNICuentaTitul_id_tenedor(props.data.data.tenedor_tipoidentificaciontitular_id ? props.data.data.tenedor_tipoidentificaciontitular_id : "");
            set_hv_DNICuentaTitul_tenedor(props.data.data.tenedor_identificacion_titular ? props.data.data.tenedor_identificacion_titular : "");
        };
        set_hv_nombres_tenedor(props.data.data.tenedor_nombre ? props.data.data.tenedor_nombre : "");
        set_hv_apellidos_tenedor(props.data.data.tenedor_apellido ? props.data.data.tenedor_apellido : "");
        set_hv_tipoDNI_tenedor(props.data.data.tenedor_tipoidentificacion_id);
        set_hv_DNI_tenedor(props.data.data.tenedor_identificacion);
        set_hv_cuentaPropia_tenedor(props.data.data.tenedor_cuenta_propia);
        //Lista banco
        AXIOS.get(API.GET_BANCO, {
        }).then((response) => {
            array_Bank(Array.isArray(response.data) ? response.data : []);
        });
        //Lista tipo documento
        AXIOS.get(API.TIPO_DOCUMENTO, {
        }).then((response) => {
            array_typeDNI(Array.isArray(response.data.data) ? response.data.data : []);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpeSnackbar(false);
      };

    const handleSubmit = (event) => {
        set_blockButton(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        AXIOS.post(API.POST_UPDATE_HV_TENEDOR + Number(props.data.data.persona_id), {
            "U_Placa": props.data.data.placa,            
            "U_TenedorIdentificacion": data.get('tenedor_identificacion'),
            "U_TipoIdentificacion_Ten": data.get('tenedor_tipoidentificacion_id'),
            "U_TenedorTipoIdentificacion": data.get('tenedor_tipoidentificacion_id'),
            "U_TenedorNombre": data.get('tenedor_nombre'),
            "U_TenedorApellido": data.get('tenedor_apellido'),
            "U_TenedorCelular": data.get('tenedor_celular'),
            "U_TenedorDireccion": data.get('tenedor_direccion'),
            "U_TenedorEmail": data.get('tenedor_email'),
            "U_Banco_Ten": data.get('tenedor_bankcode'),
            "U_TipoCuenta_Ten": data.get('tenedor_tipo_cuenta'),
            "U_NoCuentaBancaria_Ten": data.get('tenedor_cuenta'),
            "U_CuentaPropia_Ten": data.get('tenedor_cuenta_propia'),
            "U_Titular_Ten": hv_titulCuenta_tenedor,
            "U_TipoIdentTitular_Ten": hv_tipDNICuentaTitul_id_tenedor,
            "U_NoIdentTitular_Ten": hv_DNICuentaTitul_tenedor,
        }).then((response) => {
            setSeverity("success");
            setMessageResponse("Datos de tenedor actualizados correctamente.")
            func_update('put_tenedor', true); // Notificamos a CurriculumPageNew que se han llenado todos los datos
            setOpeSnackbar(true);
            set_blockButton(false);
        }).catch((error) => {
            setSeverity("error");
            setMessageResponse("Error al actualizar los datos, intente nuevamente.");
            setOpeSnackbar(true);
            set_blockButton(false);
        });
    }

    return (
        <>
            {loading ?
                <LoadingScreen />
                : (
                    <>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
                            <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
                                {messageResponse}
                            </Alert>
                        </Snackbar>
                        <Grid component={"form"} onSubmit={handleSubmit}>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 5, display: "center" }}>
                                <h4>DATOS BASICOS TENEDOR</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_nombre" label="Nombres" onChange={(event => {set_hv_nombres_tenedor(event.target.value)})} defaultValue={hv_nombres_tenedor} />
                                <TextField inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_apellido" label="Apellidos" onChange={(event => {set_hv_apellidos_tenedor(event.target.value)})} defaultValue={hv_apellidos_tenedor} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_tipoDNI_tenedor}
                                        labelId="demo-simple-select-filled-label"
                                        id="tenedor_tipoidentificacion_id"
                                        name="tenedor_tipoidentificacion_id"
                                        onChange={(event) => {set_hv_tipoDNI_tenedor(event.target.value)}} 
                                    >
                                        {
                                            dataTypeDNI.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>                   
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField
                                    type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_identificacion" label="N° Documento" defaultValue={hv_DNI_tenedor} onChange={(event) => set_hv_DNI_tenedor(event.target.value)} />
                                <TextField
                                    type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_celular" label="Celular" defaultValue={props.data.data.tenedor_celular} />
                                <TextField
                                    inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_direccion" label="Dirección" defaultValue={props.data.data.tenedor_direccion} />
                                <TextField
                                    inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_email" label="Correo" defaultValue={props.data.data.tenedor_email} />
                            </Stack>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 25, display: "center" }}>
                                <h4>DATOS BANCARIOS TENEDOR</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField type='number' sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="tenedor_cuenta" label="N° cuenta bancaria" defaultValue={props.data.data.tenedor_cuenta} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-filled-label"
                                        id="tenedor_tipo_cuenta"
                                        name="tenedor_tipo_cuenta"
                                        defaultValue={props.data.data.tenedor_tipo_cuenta}
                                    >
                                        <MenuItem value={1}>AHORRO</MenuItem>
                                        <MenuItem value={2}>CORRIENTE</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.tenedor_bankcode}
                                        labelId="demo-simple-select-filled-label"
                                        id="tenedor_bankcode"
                                        name="tenedor_bankcode"
                                    >
                                        {
                                            dataBank.map(item => {
                                                return (
                                                    <MenuItem value={item.BankCode}>{item.BankName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-filled-label"
                                        id="tenedor_cuenta_propia"
                                        name="tenedor_cuenta_propia"
                                        defaultValue={hv_cuentaPropia_tenedor}
                                        onChange={(event) => {
                                            set_hv_cuentaPropia_tenedor(event.target.value)
                                            if (event.target.value === "SI") {
                                                if(hv_nombres_tenedor === "" || hv_apellidos_tenedor === "" || hv_tipoDNI_tenedor === "" || hv_DNI_tenedor === ""){
                                                    set_hv_titulCuenta_tenedor("");
                                                    set_hv_tipDNICuentaTitul_id_tenedor("");
                                                    set_hv_DNICuentaTitul_tenedor("");
                                                }
                                                set_hv_titulCuenta_tenedor(hv_nombres_tenedor + " " + hv_apellidos_tenedor);
                                                set_hv_tipDNICuentaTitul_id_tenedor(hv_tipoDNI_tenedor);
                                                set_hv_DNICuentaTitul_tenedor(hv_DNI_tenedor);
                                            } else {
                                                set_hv_titulCuenta_tenedor("");
                                                set_hv_tipDNICuentaTitul_id_tenedor("");
                                                set_hv_DNICuentaTitul_tenedor("");
                                            };
                                        }
                                    }
                                    >
                                        <MenuItem value={"SI"}>SI</MenuItem>
                                        <MenuItem value={"NO"}>NO</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField 
                                    inputProps={{ style: { textTransform: "uppercase" } }} 
                                    sx={{ marginLeft: 2, width: '100%' }} 
                                    required 
                                    variant={hv_cuentaPropia_tenedor === "SI" ? "standard" : "filled"}
                                    id="standard-basic" 
                                    name="tenedor_nombre_titular" 
                                    label=" Titular Cuenta" 
                                    value={hv_titulCuenta_tenedor}
                                    disabled={hv_cuentaPropia_tenedor === "SI" ? true : false}
                                    onChange={(event) => { set_hv_titulCuenta_tenedor(event.target.value) }}
                                />
                                <FormControl variant={hv_cuentaPropia_tenedor === "SI" ? "standard" : "filled"} sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular </InputLabel>
                                    <Select
                                        required
                                        disabled={hv_cuentaPropia_tenedor === "SI" ? true : false}
                                        value={hv_tipDNICuentaTitul_id_tenedor}
                                        labelId="demo-simple-select-filled-label"
                                        id="tenedor_tipoidentificaciontitular_id"
                                        name="tenedor_tipoidentificaciontitular_id"
                                        onChange={(event) => { set_hv_tipDNICuentaTitul_id_tenedor(event.target.value) }}
                                    >
                                        {
                                            dataTypeDNI.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField 
                                    disabled={hv_cuentaPropia_tenedor === "SI" ? true : false} 
                                    type='number'
                                    inputProps={{ style: { textTransform: "uppercase" } }}  
                                    sx={{ marginLeft: 2, width: '100%' }} 
                                    required 
                                    variant={hv_cuentaPropia_tenedor === "SI" ? "standard" : "filled"}
                                    id="standard-basic" 
                                    name="tenedor_identificacion_titular" 
                                    label="N° indetificación titular"
                                    value={hv_DNICuentaTitul_tenedor} 
                                    onChange={(event) => { set_hv_DNICuentaTitul_tenedor(event.target.value) }} />
                            </Stack>
                            <Grid container
                                spacing={1}
                                justifyContent="flex-end"
                                style={{ marginTop: 30, display: "center" }}>
                                {
                                    (props.permiso !== 3 || props.rolSession === 13) ? 
                                        <Button variant="contained" sx={{ borderRadius: 0, background: "#3498db", padding: "12" }} disabled={blockButton} type="submit">
                                            GUARDAR CAMBIOS
                                        </Button>
                                    : <></>
                                }
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </>
    );
}