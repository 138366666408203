import React, { useState, useEffect } from 'react';
import API, { AXIOS } from '../../environment/api';
import { Stack, Divider, Typography } from '@mui/material';
import WindowModal from '../../components/WindowModalNew';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumDoc({func_update, ...props}) {
  const [rowData, setRowData] = useState([])
  const [loading, set_Loading] = useState(true);

  const fun_estado = (type) => {
    const updatedRowData = rowData.map((item) => {
      if (item.documento_nombre === type) {
        item.estado_vencimiento = 1;
      }
      return item;
    });
    setRowData(updatedRowData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const array_s3 = await AXIOS.get(API.GET_DOCUMENT_CONDUCTOR(props.dni, props.case))
      const resp_row = await AXIOS.get(API.GET_ROW_DOCUMENTS(props.case));
      const combination = resp_row.data.data.map((item) => {
        const matchingLink = array_s3.data.links.find((link) => link.type === item.documento_nombre);
        if (matchingLink) {
          item.url = matchingLink.link;
          item.vencimiento = matchingLink.vencimiento;
          item.estado_vencimiento = matchingLink.estado_vencimiento;
        } else {
          item.url = false;
          item.vencimiento = null;
          item.estado_vencimiento = 0;
        }
        return item;
      });
      setRowData(combination);
      set_Loading(false);
    }
    fetchData();
    
  }, [props.case])

  const styles = {
    contenedor: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    column: {
      width: "30%",
      alingItems: "center",
      display: "center",
      marginTop: 15
    },
  };

  return (
    <>
      {loading ?
        <LoadingScreen />
        : (
          <>
            <Typography item variant={'subtitle2'} style={{ padding:10 }}>
              Estados de los Documentos: <span style={{ backgroundColor: 'grey', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} /> SIN ANEXO, <span style={{ backgroundColor: 'green', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} /> AL DIA, <span style={{ backgroundColor: 'yellow', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px', marginLeft: '10px' }} /> POR VENCER, <span style={{ backgroundColor: 'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px', marginLeft: '10px' }} /> VENCIDO.
            </Typography>
            <div style={styles.contenedor}>
              {rowData.length > 0 ? rowData.map((item) => {
                return (
                  <Stack justifyContent="center" style={styles.column} divider={<Divider orientation="vertical" flexItem />} spacing={2} >
                    <WindowModal fun_estado={fun_estado} func_update_={func_update} dni={props.dni} title={item.documento_nombre} image={item.url} id={item.id} identity={props.identity} vence={item.vence} referencia={props.case} vencimiento={item.vencimiento} estado_vencimiento={item.estado_vencimiento} permiso={props.permiso} rol={props.rolSession}/>
                  </Stack>
                )
              }
            ) : null}
            </div>
          </>
        )
      }
    </>
  );
}