//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Switch from '@mui/material/Switch';
import { Box, Divider, FormControl, InputLabel, NativeSelect, Paper, TextField } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MessageIcon from '@mui/icons-material/Message';
import Grid from '@mui/material/Grid';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CategoryIcon from '@mui/icons-material/Category';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import API, { AXIOS } from '../environment/api';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const CreateBenefitPage = () => {
  //Funcion para inicializar los datos
  const [category, setCategory] = React.useState('');
  const [archivos, setArchivos] = React.useState("");
  const [archivos2, setArchivos2] = React.useState("");
  const [archivos3, setArchivos3] = React.useState("");
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const closeAlert = () => setAlert(false);

  //Funcion para digitar solo numeros  
  const isInputNumber = (event) => {
    let ch = String.fromCharCode(event.which);
    if (!(/[0-9-,]/.test(ch))) {
      event.preventDefault();
    }
  };

  //Funcion para subir archivos
  const subirArchivos = (e) => {
    const f = new FormData();
    for (let index = 0; index < e.length; index++) {
      setArchivos(e[index]);
    };
  };
  const subirArchivos2 = (e) => {
    const f = new FormData();
    for (let index = 0; index < e.length; index++) {
      setArchivos2(e[index]);
    };
  };
  const subirArchivos3 = (e) => {
    const f = new FormData();
    for (let index = 0; index < e.length; index++) {
      setArchivos3(e[index]);
    };
  };

  //Funcion para almacenar datos de formulario
  const textConst = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const f = new FormData();

    //Validacion para subir los 3 archivos 
    if (archivos == "" | archivos2 == "" | archivos3 == "") {
      setAlert(true);
      seterrorsMessage("Por favor subir las 3 imagenes.")
    } else {

      if (data.get('hability') == "on") {
        let dataSwitch = 1;
        f.append("imagen1", archivos);
        f.append("imagen2", archivos2);
        f.append("imagen3", archivos3);
        f.append("habilitado", dataSwitch);
        f.append("tiposbeneficio_id", data.get('type_benefity'));
        f.append("video", data.get('video'));
        f.append("terminos", data.get('termints'));
        f.append("titulo", data.get('title'));
        f.append("antes", data.get('price_back'));
        f.append("despues", data.get('price_now'));
        f.append("fechavigencia", data.get('date'));
        f.append("descripcion", data.get('description'));

        //APi para creacion de beneficio
        AXIOS.post(API.CREATE_BENEFITS, f, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          linkTo('/benefits')
          // setOpen_toast(true);
        }).catch((error) => {
          setAlert(true);
          seterrorsMessage(error.response.data.messages[0])
        });
      } else {
        //Datos de formulario
        let dataSwitch = 0;
        f.append("imagen1", archivos);
        f.append("imagen2", archivos2);
        f.append("imagen3", archivos3);
        f.append("habilitado", dataSwitch);
        f.append("tiposbeneficio_id", data.get('type_benefity'));
        f.append("video", data.get('video'));
        f.append("terminos", data.get('termints'));
        f.append("titulo", data.get('title'));
        f.append("antes", data.get('price_back'));
        f.append("despues", data.get('price_now'));
        f.append("fechavigencia", data.get('date'));
        f.append("descripcion", data.get('description'));

        AXIOS.post(API.CREATE_BENEFITS, f, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          linkTo('/benefits')
          // setOpen_toast(true);
          console.log(response)
        }).catch((error) => {
          setAlert(true);
          seterrorsMessage(error.response.data.messages[0])
        });
      }
    };
  };

  //Funcion para almacenar la categoria
  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  //Funcion para la navegacion  
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  return (
    <Dashboard>
      {/* Modal de alerta */}
      <Modal
        open={open_alert}
        onClose={closeAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 4,
          boxShadow: 24,
          p: 1,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
            {errorsMessage}
          </Typography>
        </Box>
      </Modal>
      {/* Boton de Publicar */}
      <Box component="form" onSubmit={textConst}>
        <Button type="submit" fullWidth variant="contained"
          sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}>PUBLICAR
        </Button>
        {/* Boton hacia Atras */}
        <Button fullWidth variant="contained" onClick={() => linkTo('/benefits')}
          sx={{ mt: 1, mb: 1, float: "right", background: "white", color: "#FF5733", margin: 2, width: "auto", }}><ArrowBackIcon />Atras
        </Button>
        <Grid container direction="row" rowSpacing={1}>
          <Grid
            container
            direction="column"
            xs={4}
          >
            {/* Funcion de switch               */}
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginRight: 10 }}>Publicar ahora</h3>
                <Switch name="hability" {...label} />
              </div>
            </Paper>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
              <div style={{ alignItems: 'center' }}>
                <h3 style={{ marginRight: 10 }}>Imágenes</h3>
                <input type="file" fullWidth variant="contained" name="files" onChange={(e) => subirArchivos(e.target.files)} sx={{ background: "white", color: "#FF5733", }} />
                <input type="file" fullWidth variant="contained" name="files" onChange={(e) => subirArchivos2(e.target.files)} sx={{ background: "white", color: "#FF5733", }} />
                <input type="file" fullWidth variant="contained" name="files" onChange={(e) => subirArchivos3(e.target.files)} sx={{ background: "white", color: "#FF5733", }} />
              </div>
            </Paper>
          </Grid>
          {/* Formulario         */}
          <Grid xs={8}>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 2, padding: 2, }}>
              <h3 style={{ marginRight: 10 }}>Detalles del beneficio nuevo</h3>
              <Grid container direction="row" >
                <Grid xs={6} >
                  <div style={{ margin: 20 }}>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <FormatQuoteIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        id="standard-search"
                        label="Título"
                        name="title"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <AttachMoneyIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        onKeyPress={isInputNumber}
                        required
                        id="standard-search"
                        label="Precio antes"
                        name="price_back"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <AttachMoneyIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        onKeyPress={isInputNumber}
                        required
                        id="standard-search"
                        name="price_now"
                        label="Precio ahora"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <CalendarMonthIcon autoFocus sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        id="standard-search"
                        label="Fecha vigencia"
                        name="date"
                        type="date"
                        focused
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <MessageIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        id="standard-multiline-static"
                        label="Descripción"
                        name="description"
                        multiline
                        rows={6}
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                  </div>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid xs={5}  >
                  <div style={{ margin: 20 }}>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <CategoryIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <FormControl focused variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel focused variant="standard" htmlFor="uncontrolled-native">
                          Categoría
                        </InputLabel>
                        <NativeSelect
                          required
                          value={category}
                          focused
                          onChange={handleCategory}
                          name="type_benefity"
                          label="Elija una categoría"
                          defaultValue={1}
                        >
                          <option value={1}>Combustible</option>
                          <option value={2}>Llantas</option>
                          <option value={3}>Lubricantes</option>
                          <option value={4}>Baterias</option>
                          <option value={5}>Hoteles</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <TextSnippetIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        id="standard-multiline-static"
                        name="termints"
                        label="Terminos y condiciones"
                        multiline
                        rows={6}
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', color: '#808080', margin: 20 }}>
                      <VideoFileIcon sx={{ marginTop: 2, marginRight: 3 }} />
                      <TextField
                        required
                        id="standard-search"
                        label="Url del video"
                        name="video"
                        type="text"
                        variant="standard"
                        style={{ marginRight: 10 }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Dashboard>
  );
}

export default CreateBenefitPage;