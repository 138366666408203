//Importacion de complementos
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dashboard from '../components/Dashboard';
import { Grid, TextField } from '@mui/material';
import API, { AXIOS } from '../environment/api';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ShowTableColumn } from '../components/ShowTableColumn';
import { ButtonShowColumnTable } from '../components/ButtonShowColumnTable';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import LoadingModal from "../components/LoadingModal"
import Modal from '@mui/material/Modal';
import LoadingScreen from './LoadingScreen';
import IosShareIcon from '@mui/icons-material/IosShare';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { CopyDataToClipboard } from "../helpers/CopyTableToClipboard";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function AvaibleEnturnado() {
  //Funcion para obtener la fecha de hoy
  var today = new Date();
  var day = today.getDate();
  var months = (1 + today.getMonth());
  if (months < 10) {
    var month = ("0" + months);
  } else {
    var month = months;
  }
  var year = today.getFullYear();

  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [dateBegins, setDateBegin] = React.useState("2019-03-01");
  const [dateEnds, setDateEnd] = React.useState(year + "-" + month + "-" + day);
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState(['Estado', 'Cédula', 'Nombres', 'Origen', 'Destino', 'Ubicación actual', 'Última posición', 'Celular', 'Tipo vehiculo', 'Carrocería', 'Placa']);
  const [columChecked, setColumChecked] = React.useState(['Estado', 'Cédula', 'Nombres', 'Origen', 'Destino', 'Ubicación actual', 'Última posición', 'Celular', 'Tipo vehiculo', 'Carrocería', 'Placa']);
  const componentRef = React.useRef()
  const [load, setLoad] = React.useState(false);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('tipo_pqrs');
  const [dataMessage, setMessage] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState("");


  //Funcion para copiar texto de la tabla
  const handleClickCopyToClipboard = (checked, array, attributes) => {
    CopyDataToClipboard(checked, array, attributes, () => {
      setMessage("Tabla copiada");
      setOpenSuccess(true);
      setTimeout(() => {
        setOpenSuccess(false);
      }, 2000)
    });
  };

  //Funcion para cerrar mensaje de exito
  const handleClose_success = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  //Inicializando open & close modal  
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);




  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_ENTURNAMIENTO + "?start=" + (1 + newPage) + "&length=" + rowsPerPage + `&search={"value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_ENTURNAMIENTO + "?start=" + (1 + page) + "&length=" + (+event.target.value) + `&search={"value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
    setPage(page);
  };

  const dateBegin = (event) => {
    openLoad();
    setDateBegin(event.target.value);
    AXIOS.get(API.OFFICE + "begin=" + (event.target.value) + "&end=" + dateEnds, {
    }).then((response) => {
      if (response) {
        closeLoad();
      }
      setcompanies(response.data.user_services);
    });
  };

  const dateEnd = (event) => {
    openLoad();
    setDateEnd(event.target.value);
    AXIOS.get(API.OFFICE + "begin=" + dateBegins + "&end=" + (event.target.value), {
    }).then((response) => {
      if (response) {
        closeLoad();
      }
      setcompanies(response.data.user_services);
    });
  };

  //Funcion para refrescar la tabla
  const refresh = (event) => {
    openLoad();
    setFilter("")
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(API.GET_ENTURNAMIENTO, {
    }).then((response) => {
      closeLoad(false);
      setcompanies(response.data.data);
    });
  };

  // const handleChangeSearchType = (event) => {
  //   setSearchType(event.target.value);
  //   if(event.target.value == 1){
  //     setSearchTypes("tipo_pqrs");
  //   };
  //   if(event.target.value == 2){
  //     setSearchTypes("usuario");
  //   };

  // };

  //Funcion para filtrar en la tabla
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    setPage(0);
    AXIOS.get(API.GET_ENTURNAMIENTO + "?start=1&length=" + rowsPerPage + `&search={"value":"` + filter + `"}`, {
    }).then((response) => {
      setLoading(false);
      setcompanies(response.data.data);
    });
  };

  //Pre-carga de apis
  React.useEffect(() => {
    //Api get carga los datos de la tabla
    AXIOS.get(API.GET_ENTURNAMIENTO, {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
    });
  }, []);

  //Inicializando datos que trae la api de get
  function createData(estado, identificacion, nombres, apellidos, origen, destino, departamento_origen,
    departamento_destino, last_gps_update, bind_location, celular, tipovehiculo, carroceria, vehiculo_placa) {
    return {
      estado, identificacion, nombres, apellidos, origen, destino, departamento_origen,
      departamento_destino, last_gps_update, bind_location, celular, tipovehiculo, carroceria, vehiculo_placa
    };
  }

  //Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.estado, item.identificacion, item.nombres, item.apellidos, item.origen, item.destino, item.departamento_origen,
        item.departamento_destino, item.last_gps_update, item.bind_location, item.celular, item.tipovehiculo, item.carroceria, item.vehiculo_placa)
    )
  });

  //Funcion para exportar en pdf
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Transportista enturnados',
    onAfterPrint: () => alert('Print success')
  })

  return (<>
    {(Loading == true) ?
      (Loading === "") ? null :
        <LoadingScreen /> : <>
        {/* Snackbar para mensaje en exito */}
        <Snackbar
          open={openSuccess}
          autoHideDuration={8000}
          onClose={handleClose_success}
        >
          <Alert
            onClose={handleClose_success}
            severity="success"
            sx={{ width: "100%" }}
          >
            {dataMessage}
          </Alert>
        </Snackbar>
        {/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal />
        </Modal>

        <Dashboard title='Transportistas enturnados'>
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Menu de opciones */}
          <Paper sx={{ width: '100%', overflow: 'hidden' }} >
            <Grid size="small" style={{ margin: 10, float: 'left', display: "flex" }}>
              {/* Select de columnas en tabla */}
              <ButtonShowColumnTable size="small" checked={checked} setChecked={setColumChecked} />
              <div style={{ marginLeft: 10 }}>
                <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={() =>
                    handleClickCopyToClipboard(checked, rows, [
                      "estado",
                      "identificacion",
                      "nombres",
                      "departamento_origen",
                      "departamento_destino",
                      "bind_location",
                      "last_gps_update",
                      "celular",
                      "tipovehiculo",
                      "carroceria",
                      "vehiculo_placa",
                      // {
                      //   key: "completo",
                      //   template: (value) => {
                      //     return value == 0 ? "Incompleto" : "Completo";
                      //   },
                      // },
                    ])
                  }
                >
                  Copiar
                </Button>

              </div>
              {/* Boton de exportar pdf */}
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Button variant="outlined" onClick={handlePrint} startIcon={<PrintIcon />}>Imprimir</Button>
              </div>
              {/* Boton de exportar excel */}
              <ReactHTMLTableToExcel
                // id="test-table-xls-button"
                // className="download-table-xls-button"
                className='export-btn'
                table="publicaciones"
                filename="Transportista enturnados"
                sheet="tablexls"
                buttonText={<div style={{ display: "flex" }}><IosShareIcon /><div style={{ marginTop: 4, marginLeft: 6 }}>EXPORTAR EXCEL</div></div>} />
            </Grid>
            <Grid size="small" style={{ margin: 10, float: 'right', display: "flex" }}>
            </Grid>

          </Paper>
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
            <div style={{ display: "flex", float: "right", margin: 15 }}>

              {/* Filtro de tabla */}
              <TextField id="filled-basic" label="Buscar" variant="filled" name="filtro" onChange={handleChangeSearch} startIcon={<SearchIcon />} />
            </div>
            {/* 
       <div style={{float:"right",  marginTop:26, marginRight:10}}> 
 <FormControl>
        <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
        <Select
         size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={1}
          value={searchType}
          label="Tipo de busqueda"
          onChange={handleChangeSearchType}
        >
          <MenuItem value={1}>Tipo PQRS</MenuItem>
          <MenuItem value={2}>Usuario</MenuItem>
        </Select>
      </FormControl>
 </div> */}
            {/* Boton de refrescar */}
            <div style={{ float: "right", marginTop: 28, marginRight: 10 }}>
              <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
            {/* Tabla */}
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" id="publicaciones" ref={componentRef}>
                <TableHead>
                  <TableRow>
                    <ShowTableColumn checked={columChecked}>
                      <TableCell align="center"><div id='Estado'>Estado</div></TableCell>
                      <TableCell align="center"><div id='Cédula'>Cédula</div></TableCell>
                      <TableCell align="center"><div id='Nombres'>Nombres</div></TableCell>
                      <TableCell align="center"><div id='Origen'>Origen</div></TableCell>
                      <TableCell align="center"><div id='Destino'>Destino</div></TableCell>
                      <TableCell align="center"><div id='Ubicación actual'>Ubicación actual</div></TableCell>
                      <TableCell align="center"><div id='Última posición'>Última posición</div></TableCell>
                      <TableCell align="center"><div id='Celular'>Celular</div></TableCell>
                      <TableCell align="center"><div id='Tipo vehiculo'>Tipo vehiculo</div></TableCell>
                      <TableCell align="center"><div id='Carrocería'>Carrocería</div></TableCell>
                      <TableCell align="center"><div id='Placa'>Placa</div></TableCell>
                    </ShowTableColumn>
                  </TableRow>

                </TableHead>
                <TableBody>
                  {rows.map((row, id) => (
                    <TableRow
                      key={row.nombres}
                      hover role="checkbox" tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <ShowTableColumn checked={columChecked}>
                        <TableCell align="center"><div id='Estado'>{<>{(row.estado == null | row.estado == "") ? (<>{"-"}</>) :
                          (<>{row.estado}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Cédula'>{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) :
                          (<>{row.identificacion}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Nombres'>{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                          (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Origen'>{<>{(row.origen == null | row.origen == "") ? (<>{"-"}</>) :
                          (<>{row.departamento_origen}{" "}{row.origen}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Destino'>{<>{(row.destino == null | row.destino == "") ? (<>{"-"}</>) :
                          (<>{row.departamento_destino}{" "}{row.destino}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Ubicación actual'>{<>{(row.bind_location == null | row.bind_location == "") ? (<>{"-"}</>) :
                          (<>{row.bind_location}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Última posición'>{<>{(row.last_gps_update == null | row.last_gps_update == "") ? (<>{"-"}</>) :
                          (<>{row.last_gps_update}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Celular'>{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                          (<>{row.celular}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Tipo vehiculo'>{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) :
                          (<>{row.tipovehiculo}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Carrocería'>{<>{(row.carroceria == null | row.carroceria == "") ? (<>{"-"}</>) :
                          (<>{row.carroceria}</>)}</>}</div>
                        </TableCell>
                        <TableCell align="center"><div id='Placa'>{<>{(row.vehiculo_placa == null | row.vehiculo_placa == "") ? (<>{"-"}</>) :
                          (<>{row.vehiculo_placa}</>)}</>}</div>
                        </TableCell>
                      </ShowTableColumn>

                    </TableRow>
                  ))}
                </TableBody>
                <TableBody>
                </TableBody>
              </Table>
            </TableContainer>
            {<>{(rows == "") ?
              <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                <Typography>NO HAY DATOS DISPONIBLES</Typography>
              </Stack> :
              ""}</>}


            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalPage}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Dashboard>
      </>}</>);
};
