//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Backdrop from '@mui/material/Backdrop';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import LoadingScreen from './LoadingScreen';
import API, { AXIOS } from '../environment/api';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import GifBoxIcon from '@mui/icons-material/GifBox';
import InfoIcon from '@mui/icons-material/Info';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useForm from '../hooks/useForm';
import { Warehouse } from '@mui/icons-material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import { experimentalStyled as styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import LoadingModal from "../components/LoadingModal"
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function miflota() {
  //Funcion para inicializar los datos
  const [query, setQuery] = React.useState([]);
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [dataGroup, setDataGroup] = React.useState([]);
  const [cityData, setcityData] = React.useState([]);
  const [stateData, setstateData] = React.useState([]);
  const [dataGrupos, setGrupos] = React.useState([]);
  const [validatorSuccess, setvalidatorSuccess] = React.useState(false);
  const [validatorErrors, setvalidatorErrors] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [successMessage, setsuccessMessage] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [filter, setFilter] = React.useState('');
  const [Loading, setLoading] = React.useState(true);
  const [editProvider, openEditProvider] = React.useState(false);
  const [eliminacion, openEliminar] = React.useState(false);
  const [eliminacion_id, idEliminar] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [dataFidelizado, setDataFidelizado] = React.useState([]);
  const [dataFidelizadoName, setDataFidelizadoName] = React.useState("");
  const [dataDNI, setDataDNI] = React.useState([]);
  const theme = useTheme();
  const [load, setLoad] = React.useState(false);
  const [valueFid, setValuesFid] = React.useState("");
  const [valueDni, setValuesDni] = React.useState("");
  const [nameTeam, setTeanName] = React.useState("");
  const [open_alert, setAlert] = React.useState(false);
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_toast_red, setOpen_toast_red] = React.useState(false);
  const componentRef = React.useRef()
  //Funcion para imprimi en PDF
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Grupo de fidelizados',
    onAfterPrint: () => alert('Print success')
  })
  //Funcion para cerrar notificacion exitosa
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };
  //Funcion para cerrar notificacion error  
  const handleClose_toast_red = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_red(false);
  };

  //Funcion para cerrar modal de alerta
  const closeAlert = () => setAlert(false);
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
  //Funcion para cerrar modal de carga
  const closeLoad = () => setLoad(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openChange, setOpenChange] = React.useState(false);
  //Funcion para almacenar nombre de fiedelizado
  const dataClick = (row) => {
    setDataFidelizadoName(row.grupo)
  }
  //Funcion para abrir modal de eliminacion de grupo
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  //Funcion para cerrar modal de eliminacion de grupo
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  //Funcion para abrir modal de cambiar nombre de grupo
  const handleOpenChange = () => {
    setOpenChange(true);
  };
  //Funcion para abrir modal de cambiar nombre de grupo
  const handleCloseChange = () => {
    setOpenChange(false);
  };
  //Funcion para capturar nombre de fidelizado
  const handleChangeFidelizado = (event) => {
    setDataFidelizado(
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
    );
  };
  const handleChangeDNI = (event) => {
    setDataDNI(
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
    );
  };
  //Funcion para input sea solo numerico
  const isInputNumber = (event) => {
    let ch = String.fromCharCode(event.which);
    if (!(/[0-9-,]/.test(ch))) {
      event.preventDefault();
    }
  };
  //Funcion para etiqutea de fidelizado
  const handleChangeETIQFidelizado = (event) => {
    const {
      target: { value },
    } = event;
  };
  //Funcion para etiqueta DNI
  const handleChangeETIQDNI = (event) => {
    const {
      target: { value },
    } = event;
  };
  //Funcion para posicionar menu de opciones
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //Funcion para cerrar el modal de vista principal  
  const handleClose = () => {
    setAnchorEl(null);
    setValuesFid("");
    setDataFidelizado([]);
    setValuesDni("");
  };
  //Funcion para abrir modal de nuevo fidelizado
  const [openY, setOpenY] = React.useState(false);
  const handleOpenY = () => {
    setOpenY(true);
  };
  //Funcion para cerrar modal de nuevo fidelizado
  const handleCloseY = () => {
    setOpenY(false);
    setValuesFid("");
    setDataFidelizado([]);
    setDataDNI([]);
    setValuesDni("");
  };
  //Funcion para abir modal de nuevo usuario para fidelizado
  const [openAGG, setOpenAGG] = React.useState(false);
  const handleOpenAGG = () => {
    setOpenAGG(true);
  };
  //Funcion para cerrar modal de nuevo usuario para fidelizado
  const handleCloseAGG = () => {
    setOpenAGG(false);
    setValuesFid("");
    setDataFidelizado([]);
    setDataDNI([]);
    setValuesDni("");
  };
  //Funcion para consultar datos del fidelizado
  const upEditProvider = (row) => {
    openLoad();
    setDataFidelizadoName(row.grupo);
    setTeanName(row.grupo);
    AXIOS.get(API.GET_GROUP_FIDELIZADO + row.grupo + "&instancia_id=154&user_id=21130", {
    }).then((response) => {
      // setLoading(false); 
      setDataGroup(response.data);
      closeLoad();
      openEditProvider(true);
      // setGrupos(response.data);
    });
  }
  //Funcion para cerrar vista de consulta fidelizado
  const closeEditProvider = () => openEditProvider(false);
  //Funcion para cerrar modal de eliminar
  const closeEliminar = () => openEliminar(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    // bgcolor: 'background.paper',
    // border: '1px solid gray',
    boxShadow: 24,
    // p: 4,
    borderRadius: 4,
    background: "#eeedec"
  };
  function getStyles(name, dataFidelizado, theme) {
    return {
      fontWeight:
        dataFidelizado.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  //Funcion para formatear data
  const [formOffice, setFormOffice] = useForm({
    id: "",
    sku: "",
    name: "",
    state_id: "",
    city_id: "",
    state_name: "",
    city_id: "",
    city_name: "",
    address: "",
    phone: "",
    email: "",
    created_at: ""
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }
  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  //Funcion para el tiempo de pantalla de la notificacion
  setTimeout(function () {
    setvalidatorSuccess(false);
    setvalidatorErrors(false);
  }, 8000);
  //Funcion para refrescar pantalla
  const refresh = () => {
    AXIOS.get(API.GET_FIDELIZADO, {
    }).then((response) => {
      setLoading(false);
      setGrupos(response.data);
      setQuery(response.data);
    });
  };
  //Funcion para eliminar grupo de fidelizado
  const deleteGroup = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    openLoad();
    AXIOS.post(API.DELETE_GROUP_FIDELIZADO, {
      user_id: 21130,
      instancia_id: 154,
      nombre_grupos: [dataFidelizadoName]
    }).then((response) => {
      handleCloseDelete();
      handleClose();
      closeLoad();
      refresh();
      setsuccessMessage("Grupo fidelizado eliminado con exito");
      setOpen_toast_red(true);
      setValuesFid("");
      setDataFidelizado([]);
      setDataDNI([]);
      setValuesDni("");
    });
  };
  //Funcion para cambiar nombre de fidelizado
  const changeGroup = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    openLoad();
    if (data.get('name') == "") {
      closeLoad();
      setAlert(true);
      seterrorsMessage("Por favor digite el nombre del grupo fidelizado");
    } else {
      AXIOS.post(API.CHANGE_GROUP_FIDELIZADO, {
        user_id: 21130,
        nombre_actual: dataFidelizadoName,
        nuevo_nombre: data.get('name')
      }).then((response) => {
        handleCloseChange();
        handleClose();
        closeLoad();
        refresh();
        setsuccessMessage("Actualizado con exito");
        setOpen_toast(true);
        setValuesFid("");
        setDataFidelizado([]);
        setDataDNI([]);
        setValuesDni("");
      }).catch((error) => {

      });
    }

  };
  //Funcion para eliminar usuario de grupo fidelizado
  const deleteUsers = (row) => {
    openLoad();
    AXIOS.post(API.DELETE_USER_FIDELIZADO, {
      user_id: 21130,
      instancia_id: 154,
      flota: nameTeam,
      identificaciones: [row.persona.identificacion]
    }).then((response) => {
      closeEditProvider();
      handleClose();
      closeLoad();
      refresh();
      setsuccessMessage("Conductor eliminado con exito");
      setOpen_toast_red(true);
      setValuesFid("");
      setDataFidelizado([]);
      setDataDNI([]);
      setValuesDni("");
    });
  };

  //Funcion para agregar usuario en grupo fidelizado
  const aggFidelizado = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let arr = data.get('two').split(',');
    openLoad();
    if (data.get('two') == "") {
      closeLoad();
      setsuccessMessage("Actualizado con exito");
      setOpen_toast(true);
    } else {

    }
    AXIOS.post(API.CREATE_USER_FIDELIZADO, {
      user_id: 21130,
      personas: arr,
      nombre_grupo: [dataFidelizadoName],
    }).then((response) => {
      handleCloseChange();
      handleClose();
      handleCloseAGG();
      closeEditProvider();
      closeLoad();
      refresh();
      setsuccessMessage("Conductor agregado con exito");
      setOpen_toast(true);
      setValuesFid("");
      setDataFidelizado([]);
      setDataDNI([]);
      setValuesDni("");
    }).catch((error) => {
    });
  };

  //Funcion para cambiar contraseña
  const submitPassOld = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {

      AXIOS.put("http://izicarga.logicem.com/api/admin/drivers/" + (companyData.id), {
        data: {
          password: data.get('pass'),
        }
      }).then(function (data) {
        refresh();
        closeViewOffices();
      })
        .catch(function (error) {
        });
    } catch (error) {
    }
  };
  //Funcion para eliminar grupo  
  const submitDeleteOffice = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      AXIOS.post("http://izicarga.logicem.com/api/favoritos/grupos/eliminar_grupo", {
        nombre_grupos: {
          "0": eliminacion_id
        }
      }).then(function (data) {
        closeEliminar();
        refresh();
        //handleClose_delete();
        setTimeout();
      })
        .catch(function (error) {
        });
    } catch (error) {
    }
  };

  const closeViewOffices = () => setViewOffices(false);
  //Funcion para crear fidelizado
  const createUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let fidel = [data.get('fidelizado')];
    let dnis = [data.get('dni')];
    let arr1 = data.get('one').split(',');
    let arr2 = data.get('two').split(',');
    if (data.get('one') == "" | data.get('two') == "") {
      setAlert(true)
      seterrorsMessage("Por favor completar todos los campos.")
    } else {
      AXIOS.post(API.CREATE_FIDELIZADO, {
        user_id: 21130,
        flotas: arr1,
        identificaciones: arr2
      }).then(function (data) {
        handleCloseY();
        refresh();
        setsuccessMessage("Grupo fidelizado creado con exito");
        setOpen_toast(true);
        // handleCloseE();
      })
        .catch(function (error) {
          setvalidatorErrors(true)
          seterrorsMessage("La cedula no existe o ya pertenece a una flota")
        });
    }

  }


  React.useEffect(() => {
    //Pre-carga de apis
    AXIOS.get(API.GET_FIDELIZADO, {
    }).then((response) => {
      setLoading(false);
      setGrupos(response.data);
      setQuery(response.data);
    });
  }, []);
  //Funcion para almacenar input de busqueda
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };

  if (!companies) return null;
  if (!stateData) return null;
  if (!cityData) return null;


  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard title='Grupos Fidelizados'>
          {/* Modal de alerta */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                {errorsMessage}
              </Typography>
            </Box>
          </Modal>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          {/* Notificacion de exito */}
          <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
            <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
          {/* Notificaicon de error */}
          <Snackbar open={open_toast_red} autoHideDuration={8000} onClose={handleClose_toast_red}>
            <Alert onClose={handleClose_toast_red} severity="error" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
          {/* Modal para cambiar nombre de fidelizado */}
          <Modal
            open={openChange}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿DESEA CAMBIAR EL NOMBRE DEL GRUPO FIDELIZADO?
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={changeGroup} noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={18}>
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField fullWidth defaultValue={dataFidelizadoName} name="name" label="Nombre" variant="outlined" required />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseChange}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > ACTUALIZAR
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal para eliminar grupo de fidelizado */}
          <Modal
            open={openDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿DESEA ELIMINAR EL GRUPO FIDELIZADO?
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={deleteGroup} noValidate sx={{ mt: 1 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseDelete}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > ELIMINAR
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          <Grid item xs={12} md={2} lg={29} style={{ display: 'flex' }}>
          </Grid>
          {/* Modal para agregar grupo de fidelizado */}
          <Modal
            open={openY}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                AGREGAR NUEVOS GRUPOS FIDELIZADOS
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={createUser} noValidate sx={{ mt: 1 }}>

                  <Typography id="modal-modal-title" variant="h10" component="h10" align='center'>
                    Nombre del grupo
                  </Typography>
                  <Grid item xs={12} sm={18} >
                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel id="demo-multiple-chip-label">Fidelizados</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        defaultValue={valueFid}
                        readOnly
                        name="fidelizados"
                        value={dataFidelizado}
                        onChange={handleChangeETIQFidelizado}
                        input={<OutlinedInput id="select-multiple-chip" label="Etiqueta" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {dataFidelizado.map((row) => (
                          <MenuItem
                            key={row.id}
                            value={row.etiqueta}
                            style={getStyles(row.etiqueta, dataFidelizado, theme)}
                          >
                            {row.etiqueta}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Grid style={{ display: "flex", marginTop: 10, marginBlockEnd: 20 }}>
                      <TextField defaultValue={valueFid} fullWidth onChange={handleChangeFidelizado} name="one" placeholder="Ej: Fidelizados 1, Fidelizados 2" variant="outlined" required />
                    </Grid>
                    <Typography style={{ marginTop: 35 }} id="modal-modal-title" variant="h10" component="h10" align='center'>
                      Registre los transportistas del grupo por número de cédula
                    </Typography>
                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel id="demo-multiple-chip-label">Cédulas</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        readOnly
                        defaultValue={valueDni}
                        name="dni"
                        value={dataDNI}
                        onChange={handleChangeETIQDNI}
                        input={<OutlinedInput id="select-multiple-chip" label="Etiqueta" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {dataDNI.map((row) => (
                          <MenuItem
                            key={row.id}
                            value={row.etiqueta}
                            style={getStyles(row.etiqueta, dataDNI, theme)}
                          >
                            {row.etiqueta}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Grid style={{ display: "flex", marginTop: 10, marginBlockEnd: 20 }}>
                      <TextField defaultValue={valueDni} onKeyPress={isInputNumber} fullWidth onChange={handleChangeDNI} name="two" placeholder="Ej: 121231" variant="outlined" required />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseY}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > Agregar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal de agregar nuevo usuario para fidelizado */}
          <Modal
            open={openAGG}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                AGREGAR NUEVOS USUARIOS FIDELIZADOS
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={aggFidelizado} noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={18} >
                    <Typography style={{ marginTop: 35 }} id="modal-modal-title" variant="h10" component="h10" align='center'>
                      Registre los transportistas del grupo por número de cédula
                    </Typography>
                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel id="demo-multiple-chip-label">Cédulas</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        readOnly
                        defaultValue={valueDni}
                        name="dni"
                        value={dataDNI}
                        onChange={handleChangeETIQDNI}
                        input={<OutlinedInput id="select-multiple-chip" label="Etiqueta" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {dataDNI.map((row) => (
                          <MenuItem
                            key={row.id}
                            value={row.etiqueta}
                            style={getStyles(row.etiqueta, dataDNI, theme)}
                          >
                            {row.etiqueta}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Grid style={{ display: "flex", marginTop: 10, marginBlockEnd: 20 }}>
                      <TextField defaultValue={valueDni} required onKeyPress={isInputNumber} fullWidth onChange={handleChangeDNI} name="two" placeholder="Ej: 121231" variant="outlined" />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseAGG}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > Agregar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal de elimancion de grupo */}
          <Modal
            open={eliminacion}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                ¿Está seguro que quiere eliminar el grupo?
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={submitDeleteOffice} noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={18} >
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeEliminar}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 2, background: " #e35233" }}
                    > Eliminar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal vista de fidelizado */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editProvider}
            // onClose={closeDeleteProvider}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 400,
            }}
          >
            <Box sx={style} component="form">
              <Typography sx={{ marginX: 3, marginY: 2 }} id="transition-modal-title" variant="h6" component="h2" align='center'>
                <b style={{ textTransform: 'uppercase' }}>FIDELIZADOS</b>
              </Typography>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ display: "flex", float: "right", margin: 8 }}>
                  {/* Boton de exportar EXCEL              */}
                  <ReactHTMLTableToExcel
                    // id="test-table-xls-button"
                    // className="download-table-xls-button"
                    className='export-btn'
                    table="publicaciones"
                    filename="Grupo de fidelizados"
                    sheet="tablexls"
                    buttonText={<div style={{ display: "flex" }}><IosShareIcon /><div style={{ marginTop: 4, marginLeft: 6 }}>EXPORTAR EXCEL</div></div>} />
                  {/* Boton de imprimir PDF */}
                  <Button variant="outlined" style={{ marginRight: 10, marginLeft: 10 }} onClick={handlePrint} startIcon={<PrintIcon />}>Imprimir</Button>
                  <Button variant='outlined' style={{ background: "green", color: "white" }} onClick={() => handleOpenAGG()}>Agregar</Button>
                </div>
                {/* Tabla de contenido */}
                <TableContainer sx={{ maxHeight: 340 }}>
                  <Table stickyHeader aria-label="sticky table" id="publicaciones" ref={componentRef}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"><b>ID</b></TableCell>
                        <TableCell align="center"><b>N° Cédula</b></TableCell>
                        <TableCell align="center"><b>Nombres</b></TableCell>
                        <TableCell align="center"><b>Apellidos</b></TableCell>
                        <TableCell align="center"><b>Célular</b></TableCell>
                        <TableCell align="center"><b>Acciones</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataGroup.map((row, id) => (
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.persona.id}</TableCell>
                          <TableCell align="center">{<>{(row.persona.identificacion == null | row.persona.identificacion == "") ? (<>{"-"}</>) :
                            (<>{row.persona.identificacion}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.persona.nombres == null | row.persona.nombres == "") ? (<>{"-"}</>) :
                            (<>{row.persona.nombres}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.persona.apellidos == null | row.persona.apellidos == "") ? (<>{"-"}</>) :
                            (<>{row.persona.apellidos}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.persona.celular == null | row.persona.celular == "") ? (<>{"-"}</>) :
                            (<>{row.persona.celular}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "red" }} onClick={() => deleteUsers(row)}><DeleteIcon /></Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {<>{(dataGroup == "") ?
                  <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                    <Typography>NO HAY DATOS DISPONIBLES</Typography>
                  </Stack> :
                  ""}</>}
              </Paper>
              <div style={{ borderTop: '1px solid  #c5c5c5' }}>
                <Button sx={{ WebkitBorderBottomLeftRadius: 16, WebkitBorderBottomRightRadius: 16 }} fullWidth onClick={closeEditProvider}><b>CERRAR</b></Button>
              </div>
            </Box>
          </Modal>
          {/* Modal nueva contraseña */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                Nueva Contraseña <spam style={{ color: "black" }}>{"- "}{companyData.nombre}</spam>
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={submitPassOld} noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={18} >
                      <TextField autoComplete="given-name" name="pass" required type="text"
                        fullWidth id="pass" label="Contraseña" autoFocus defaultValue={companyData.id}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeViewOffices}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 2, background: " #e35233" }}
                    > Actualizar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          <div style={{ float: 'center' }}>
            <TextField fullWidth style={{ float: 'center' }} id="filled-basic" label="Buscar" inputProps={{ style: { textTransform: 'lowercase' } }} variant="filled" name="filtro" onChange={handleChangeSearch} />
          </div>
          <Box sx={{ flexGrow: 1, marginTop: 3 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {query.filter((row) =>
                row.grupo.toLowerCase().includes(filter)
              ).map((row, id) => (<>
                <Grid item xs={2} sm={4} md={4} key={id + 1}>
                  <Item>
                    <div style={{ marginRight: 170 }}>{row.grupo}</div>
                  </Item>
                  <div style={{ float: 'right', marginTop: -44, display: "flex" }}>
                    <Button onClick={() => upEditProvider(row)}><LaunchIcon /></Button>
                    <div onClick={() => dataClick(row)}>
                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon />
                      </Button> </div>
                  </div>
                </Grid>
              </>)
              )}
            </Grid>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleOpenAGG()}>Agregar fidelizados</MenuItem>
            <MenuItem onClick={() => handleOpenChange()}>Cambiar nombre del grupo</MenuItem>
            <MenuItem onClick={() => handleOpenDelete()}>Eliminar grupo</MenuItem>
          </Menu>
          {/* Boton para agregar nuevo fidelizado */}
          <Box sx={{ '& > :not(style)': { m: 1, float: "right" } }}>
            <Fab style={{ background: "green", color: "white" }} aria-label="add" onClick={() => handleOpenY()} >
              <AddIcon />
            </Fab>
          </Box>
        </Dashboard>
      }
    </>
  );
}