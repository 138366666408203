import axios from 'axios';

// const URL = 'https://servertestma.lat/api/api'
// const URL = 'http://193.168.1.97:8000/api'
// const URL = 'http://193.168.1.139:8000/api' // JOSE 
const URL = 'http://izicarga.logicem.com/api'
// const URL = 'http://3.235.190.220/back/public/api'
// const URL = 'https://many-dear-crab.ngrok-free.app/api' // Ngrok migue

const API = {
    // LOGIN
    LOGIN: `${URL}/auth`,
    PRUEBA_DATA: `${URL}/sap/hbt_transtipoidente`,
    REGISTRODATACARGA:`${URL}/Datacarga/PostRegistro_DataCarga_Logicem_Web`,
    PREREGISTRO: `${URL}/pre-registro`,
    // DASHBOARD
    // KPI
    KPI_LISTS: `${URL}/admin/kpi/services?`,
    KPI_DRIVERS: `${URL}/admin/kpi/drivers?`,
    KPI_ROUTES: `${URL}/admin/kpi/frequent-routes?`,
    // TRANSPORTISTA
    REPORTS: `${URL}/admin/reports`,
    // DETALLE PUBLICACIÓN
    OFFICE: `${URL}/admin/reports/office?`,
    // RUTA MÁS FRECUENTES
    ROUTES_FREQUENT: `${URL}/admin/reports/services?`,
    // TABLERO DE CARGA
    // CONDUCTORES DISPONIBLES
    // VIAJES DISPONIBLES
    GET_VIAJES: `${URL}/servicios/viajes?page=1&per_page=10`,
    GET_VIAJES_C: `${URL}/servicios/viajes`,
    CREATE_VIAJES: `${URL}/servicios/viajes`,
    DELETE_VIAJES: `${URL}/servicios/viajes/`,
    // VIAJES PUBLICADOS
    GET_VIAJES_PUBLICADOS: `${URL}/admin/services`,
    // TRANSPORTISTA EN RUTA
    GET_TRANSP_RUTA: `${URL}/servicios/busqueda_de_vehiculo/serviciovigentecompleto?user_id=21130`,
    VERIFIED_USER: `${URL}/admin/companies/users/`,
    CREATE_USER: `${URL}/personas/create`,
    PUT_USER: `${URL}/personas/`,
    // ENTURNAMIENTO
    GET_ENTURNAMIENTO: `${URL}/servicios/enturnamiento`,
    VEHICLE_TYPE: `${URL}/utils/carrocerias/vehiculos`,
    // MAESTRO USUARIOS
    GET_MASTER_USERS: `${URL}/admin/companies/users_instancia?`,
    PUT_MASTER_PASS: `${URL}/admin/drivers/`,
    MASTER_USER_SEARCH: `${URL}/admin/companies/users_instancia?`,
    VERFICADOS: `${URL}/admin/companies/users/`,
    // ANUNCIO
    GET_ADS: `${URL}/ads`,
    CHANGE_STATUS_ADS: `${URL}/ads/`,
    // ESTADISTICA
    GET_ESTADISTICA: `${URL}/ads/dashboard`,
    // PQRS
    GET_PQRS: `${URL}/pqrs`,
    // NOTICIAS
    NOTICE: `${URL}/noticias`,
    // BENEFICIOS
    GET_BENEFITS: `${URL}/servicios/beneficios/empresas`,
    CREATE_BENEFITS: `${URL}/servicios/beneficios`,
    // TIEMPO LOGISTICO
    POST_DOCUMENT: `${URL}/documentos/documentosViaje`,
    LOGISTICO: `${URL}/tiempologistico/showAllAdmin`,
    // LISTA DE CONDUCTORES
    COUNTER: `${URL}/admin/reports/drivers/`,
    DRIVERS: `${URL}/admin/history/drivers`,
    DRIVERS_PAGE: `${URL}/admin/history/drivers?length=`,
    DRIVERS_SEARCH: `${URL}/admin/history/drivers?`,
    // LISTA DE PROPIETARIOS
    GET_OWNERS: `${URL}/admin/owners`,
    // LISTA DE TITULARES
    GET_HOLDERS: `${URL}/admin/holders`,
    ENTURNAMIENTO: `${URL}/servicios/enturnamiento`,
    GET_DRIVER: `${URL}/servicios/disponibles`,
    DELETE_SERVICE: `${URL}/servicios/disponibles/`,
    // FIDELIZADO
    GET_FIDELIZADO: `${URL}/favoritos/grupos/ver_grupos?instancia_id=154&user_id=21130`,
    CREATE_FIDELIZADO: `${URL}/favoritos/grupos/crear_grupo`,
    GET_GROUP_FIDELIZADO: `${URL}/favoritos/grupos/detalle_grupo?flota=`,
    DELETE_GROUP_FIDELIZADO: `${URL}/favoritos/grupos/eliminar_grupo`,
    CHANGE_GROUP_FIDELIZADO: `${URL}/favoritos/grupos/cambiar_nombre_grupo`,
    CREATE_USER_FIDELIZADO: `${URL}/favoritos/grupos/agregar_usuarios`,
    // FLOTA SEGURA
    GET_FLOTA_SEGURA: `${URL}/servicios/flota/whitelist`,
    CREATE_FLOAT: `${URL}/servicios/flota/whitelist`,
    DELETE_USER_FIDELIZADO: `${URL}/favoritos/grupos/eliminar_usuarios`,
    // HOJA DE VIDA
    TIPO_DOCUMENTO: `${URL}/hoja_de_vida/tipo_documentoidentidad`,
    BANCO: `${URL}/hoja_de_vida/banco`,
    ARL: `${URL}/hoja_de_vida/arl`,
    EPS: `${URL}/hoja_de_vida/eps`,
    AFP: `${URL}/hoja_de_vida/afp`,
    GET_HOJAVIDA: `${URL}/hoja_de_vida/personal/get/`,
    GET_HOJAVIDA_DOCUM: `${URL}/document/aws/2/`,
    GET_PERFIL_CONDUCTOR: `${URL}/personas/`,
    TIEMPOS_LOGISTICOS_SHOW_ADMIN: `${URL}/tiempologistico/showAllAdmin`,
    GET_PERFIL_VEHICULO: `${URL}/document/aws/2/`,
    CITY: `${URL}/utils/departamentos/ciudades/?`,
    POST_HOJA_VIDA: `${URL}/hoja_de_vida/personal/update/`,
    POST_UPDATE_HOJA_VIDA: `${URL}/hoja_de_vida/personalhv/update/`,
    POST_UPDATE_HV_VEHICULO: `${URL}/hoja_de_vida/vehiculo/update/`,
    POST_UPDATE_DATACARGA_TRAILER: `${URL}/Datacarga/agregar_trailer/`,
    POST_UPDATE_HV_TRAILER: `${URL}/hoja_de_vida/trailer/update/`,
    POST_UPDATE_HV_TENEDOR: `${URL}/hoja_de_vida/tenedor/update/`,
    POST_ASSIGN_ME_REQUEST: `${URL}/admin/drivers/solicitud`,
    POST_DELETE_NOTIFICATION: `${URL}/admin/notifiacion/delete`,
    POST_UPDATE_HV_PROPIETARIO: `${URL}/hoja_de_vida/propietario/update/`,
    SET_TRAILER_HV: `${URL}/hoja_de_vida/trailer_detalle/`,
    GET_LOCALIDADES: `${URL}/utils/departamentos/localidades/?search=`,
    GET_OPTIONS_DEVOLUCION: `${URL}/admin/devolution`,
    GET_LIST_EXCEPCIONES: (id) => `${URL}/admin/excepcion/${id}`,
    POST_ADD_EXCEPCIONES: `${URL}/admin/excepcion/`,
    GET_MOTIVO_DEVOLUCION: `${URL}/admin/devolution/info`,
    POST_DEVOLUCION: `${URL}/admin/devolution/create`,
    PUT_STATUS: `${URL}/servicios/busqueda_de_vehiculo/`,
    // DOCUMENTOS HOJA DE VIDA
    POST_DOCUMENT_VEHICULO: (placa) => `${URL}/document/aws/1/${placa}`,
    GET_DOCUMENT_VEHICULO: (placa) => `${URL}/document/aws/1/${placa}`,
    POST_DOCUMENT_CONDUCTOR: (dni) => `${URL}/document/aws/2/${dni}`,
    // GET_DOCUMENT_CONDUCTOR: (dni) => `${URL}/document/aws/${dni}`,
    POST_DOCUMENT_TRAILER: (placaT) => `${URL}/document/aws/3/${placaT}`,
    GET_DOCUMENT_TRAILER: (placaT) => `${URL}/document/aws/3/${placaT}`,
    GET_ROW_DOCUMENTS: (id) => `${URL}/attachment/?id=${id}`,
    GET_ROW_DOCUMENTS_ADMIN: (id) => `${URL}/admin/attachment/?id=${id}`,
    UPDATE_DOCUMENT: function(id, valueCase) {
        return `${URL}/admin/attachment/?id=${id}&case=${valueCase}`;
    },
    GET_DOCUMENT_CONDUCTOR: function(dni, case_) {
        return `${URL}/document/aws/${case_}/${dni}`;
    },
    DELETE_DOCUMENT: function(id, valueCase) {
        return `${URL}/admin/attachments/delete/?id=${id}&case=${valueCase}`;
    },
    CREATE_DOCUMENT: (id) => `${URL}/admin/attachment?case=${id}`,
    // BENEFICIOS
    CHANGE_STATUS: `${URL}/servicios/beneficios/status/`,
    VIEW_PEOPLE: `${URL}/servicios/beneficios/empresas/`,
    // SAP
    TIPO_DNI: `${URL}/sap/hbt_transtipoidente`,
    CATEGORIA_LICENCIA: `${URL}/sap/hbt_transtipoliccon`,
    EMPRESA_GPS: `${URL}/sap/hbt_transemprgps`,
    GET_EPS: `${URL}/sap/hbt_transeps`,
    GET_ARL: `${URL}/sap/hbt_transarl`,
    GET_AFP: `${URL}/sap/hbt_transafp`,
    GET_BANCO: `${URL}/sap/odsc`,
    GET_DEPARTAMENTS: `${URL}/utils/departamentos`,
    GET_CITIES: (id) => `${URL}/utils/ciudades/?id=${id}`,
    GET_LOCALIDADES_IDENTIFICACION: (id) => `${URL}/utils/localidades/?id=${id}`,
    TIPO_CONFIG: `${URL}/sap/hbt_transconfvehi`,
    TIPO_CARGA: `${URL}/sap/hbt_transtipocarga`,
    MARCA_CABEZO: `${URL}/sap/hbt_transmarcascab`,
    SOAT_SAP: `${URL}/sap/hbt_transasegurador`,
    FLOTA_SAP: `${URL}/sap/hbt_transtipocond`,
    TIPO_COMBUSTI: `${URL}/sap/hbt_transcombusti`,
    COLOR_SAP: `${URL}/sap/hbt_transcolor`,
    MARCAS_SAP: `${URL}/sap/hbt_transmarcasemi`,
    TIPO_CARROCERIA_SAP: `${URL}/sap/hbt_transtipocarr`,
    CREATE_HV_SAP: `${URL}/sap/insertar_conductor`,
    CREATE_VEHICULO_SAP: `${URL}/sap/insertar_vehiculo`,
    LINEA_CABEZOTE: `${URL}/sap/hbt_translineascab`,
    GET_USER_SAP: `${URL}/sap/ousr`,
    GET_TIPO_POLIZA: `${URL}/sap/hbt_transtipopoliza`,
    GET_POLIZA: `${URL}/sap/hbt_transasegurador`,
    // SAP CREACION SERVICIO
    /// GPS
    GET_GPS: `${URL}/hoja_de_vida/gps/`,
    POST_GPS: `${URL}/hoja_de_vida/gps/`,
    REJECT_SERVICE_ADMIN: `${URL}/servicios/estadointeresado_api`,
    // PERMISOS
    GET_PERMISOS: `${URL}/admin/companies/role_permiso`,
    POST_PERMISOS: `${URL}/admin/companies/role`,
    POST_UPDATE_PERMISOS: `${URL}/admin/companies/role/update`,
    // NOTIFICACIONES
    GET_CANTIDAD_NOTIFICATIONS: (id_user) => `${URL}/admin/num_notifiaciones/${id_user}`,
    GET_CANTIDAD_NOTIFICATIONS_STUDIO: (id_user) => `${URL}/admin/num_notifiaciones_studio/${id_user}`,
    GET_NOTIFICATIONS: (id_user) => `${URL}/admin/notificaciones/${id_user}`,
    GET_NOTIFICATIONS_STUDIO: (id_user) => `${URL}/admin/notifiaciones_studio/${id_user}`,
};

export default API;

export const AXIOS = axios.create(API);

AXIOS.interceptors.request.use(async (config) => {
    const token = await localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `bearer ${token}`;
    }
    return config;
});