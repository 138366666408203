//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import LoadingScreen from './LoadingScreen';
import API, { AXIOS } from '../environment/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import RefreshIcon from '@mui/icons-material/Refresh';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useForm from '../hooks/useForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LoadingModal from "../components/LoadingModal"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import listadoCiudades from '../components/ListadoCiudades';

export default function Master() {
  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open_editCompany, setOpen_editCompany] = React.useState(false);
  const [open_deleteCompany, setOpen_deleteCompany] = React.useState(false);
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState([]);
  const [city, setcity] = React.useState("");
  const [dataEdtit, setDataEdit] = React.useState([]);
  const [state, setstate] = React.useState("");
  const [validatorErrors, setvalidatorErrors] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState("");
  const [identificacionID, setID] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [filter, setFilter] = React.useState('');
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [openN, setOpenN] = React.useState(false);
  const [dni_e, setDni_e] = React.useState('');
  const [role, setRole] = React.useState('');
  const [open_alert, setAlert] = React.useState(false);
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_update, setOpen_toast_update] = React.useState(false);
  const [open_toast_red, setOpen_toast_red] = React.useState(false);
  const [open_toast_error, setOpen_toast_error] = React.useState(false);
  const [searchType, setSearchType] = React.useState('1');
  const [searchTypes, setSearchTypes] = React.useState('nombres');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  //Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);

  //Funcion para cerrar modal de carga  
  const closeLoad = () => setLoad(false);

  //Funcion para cerrar alerta  
  const closeAlert = () => setAlert(false);

  //Funcion para seleccionar el tipo de DNI
  const handleChange_e = (event) => {
    setDni_e(event.target.value);
  };

  //Funcion para seleccionar el tipo de rol
  const handleRole = (event) => {
    setRole(event.target.value);
  };

  //Funcion para abrir modal editar
  const handleOpenN = (row) => {
    setDataEdit(row);
    setOpenN(true);
  };

  //Funcion para cerrar modal editar
  const handleCloseN = () => {
    setOpenN(false);
  };

  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };

  //Funcion para cerrar notificacion de actualizacion
  const handleClose_toast_update = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_update(false);
  };

  //Funcion para cerrar notificacion de adevertencia
  const handleClose_toast_red = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_red(false);
  };

  //Funcion para cerrar notificacion de error
  const handleClose_toast_error = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_error(false);
  };

  //Estilo de modales
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  //Funcion para crear nuevo usuario
  const [openE, setOpenE] = React.useState(false);
  const handleOpenE = () => {
    setOpenE(true);
  };

  //Funcion para cerrar modal de crear nuevo usuario
  const handleCloseE = () => {
    setOpenE(false);
  };

  //Funcion para formatear data  
  const [formOffice, setFormOffice] = useForm({
    id: "",
    sku: "",
    name: "",
    state_id: "",
    state_name: "",
    city_id: "",
    city_name: "",
    address: "",
    phone: "",
    email: "",
    created_at: ""
  });

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  }

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  //Funcion para el tiempo de la notificacion en pantalla
  const setTimeout = () => {
    console.log("yes");
  }

  //Funcion para refrescar pantalla
  const refresh = () => {
    openLoad();
    AXIOS.get(API.GET_MASTER_USERS + "page=1&per_page=10&user_id=" + localStorage.getItem("user_id_LOGICEM"), {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    });
  };

  //Funcion para input solo numeros
  const isInputNumber = (event) => {
    let ch = String.fromCharCode(event.which);
    if (!(/[0-9-,]/.test(ch))) {
      event.preventDefault();
    }
  };

  //Funcion para verificar usuario
  const submitUpdateCompany = (row) => {
    openLoad();
    setID({
      id: row.identificacion,
    });
    // console.log("update", identificacionID.id)
    try {
      AXIOS.put(API.VERIFIED_USER + (row.identificacion)).then(function (data) {
        // console.log("update", data)
        closeLoad();
        setOpen_toast_update(true);
        refresh();
      })
        .catch(function (error) {
          console.log("update error", error)
        });
    } catch (error) {
    }
  };

  //Funcion para crear usuario
  const editUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    openLoad();
    let origenid = listadoCiudades.find(({ nombre }) => nombre === data.get('origin'));
    if (origenid == undefined) {
      let origenid = "";
      if (origenid.id == "" | data.get('last_name') == "" | data.get('phone') == "" | data.get('address') == "" | data.get('email') == "" | data.get('dni') == "" |
        data.get('name') == "" | data.get('pass') == "" | data.get('repass') == "" | data.get('rol') == "" | data.get('TipoDocumento') == "") {
        closeLoad();
        setAlert(true);
        seterrorsMessage("Por favor completar todos los campos");
      } else {
        AXIOS.post(API.CREATE_USER, {
          data: {
            apellidos: data.get('last_name'),
            celular: data.get('phone'),
            ciudad_id: origenid.id,
            direccion: data.get('address'),
            email: data.get('email'),
            identificacion: data.get('dni'),
            // localidad: {id:origenid.id, nombre:origenid.nombre},
            localidad: origenid,
            nit: "100000777",
            nombres: data.get('name'),
            password: data.get('pass'),
            password_repeat: data.get('repass'),
            rol_id: data.get('rol'),
            tipoempresa_id: 1,
            tipoidentificacion_id: data.get('TipoDocumento'),
          }
        }).then(function (data) {
          closeLoad();
          refresh();
          handleCloseE();
          setOpen_toast(true);
        })
          .catch(function (error) {
            //Validacion de campos vacios
            if (error.response.data.identificacion != "") {
              setAlert(true);
              seterrorsMessage(error.response.data.identificacion[0]);
            }
            if (error.response.data.nombres != "") {
              seterrorsMessage(error.response.data.nombres);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.apellidos != "") {
              seterrorsMessage(error.response.data.apellidos);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.celular != "") {
              seterrorsMessage(error.response.data.celular);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.direccion != "") {
              seterrorsMessage(error.response.data.direccion);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.email != "") {
              seterrorsMessage(error.response.data.email);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.password != "") {
              seterrorsMessage(error.response.data.password);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.password_repeat != "") {
              seterrorsMessage(error.response.data.password_repeat);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.localidad != "") {
              seterrorsMessage(error.response.data.localidad);
              setOpen_toast_error(true);
              setTimeout();
            }
            if (error.response.data.tipoidentificacion_id != "") {
              seterrorsMessage(error.response.data.tipoidentificacion_id);
              setOpen_toast_error(true);
              setTimeout();
            }
          });
      }
    } else {
      if (data.get('last_name') == "" | data.get('phone') == "" | data.get('address') == "" | data.get('email') == "" | data.get('dni') == "" |
        data.get('name') == "" | data.get('pass') == "" | data.get('repass') == "" | data.get('rol') == "" | data.get('TipoDocumento') == "") {
        closeLoad();
        setAlert(true);
        seterrorsMessage("Por favor completar todos los campos.");
      } else {
        AXIOS.post(API.CREATE_USER, {
          data: {
            apellidos: data.get('last_name'),
            celular: data.get('phone'),
            ciudad_id: origenid.id,
            direccion: data.get('address'),
            email: data.get('email'),
            identificacion: data.get('dni'),
            // localidad: {id:origenid.id, nombre:origenid.nombre},
            localidad: origenid,
            nit: "100000777",
            nombres: data.get('name'),
            password: data.get('pass'),
            password_repeat: data.get('repass'),
            rol_id: data.get('rol'),
            tipoempresa_id: 1,
            tipoidentificacion_id: data.get('TipoDocumento'),
          }
        }).then(function (data) {
          closeLoad();
          refresh();
          handleCloseE();
          setOpen_toast(true);
        })
          .catch(function (error) {
            closeLoad();
            setAlert(true);
            seterrorsMessage(error.response.data.identificacion[0]);
          });
      }
    }
  }

  //Funcion para editar usuario
  const editarUser = (event) => {
    openLoad();
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let origenid = listadoCiudades.find(({ nombre }) => nombre === data.get('origin'));
    if (data.get('last_name') == "" | data.get('phone') == "" | data.get('address') == "" | data.get('email') == "" |
      data.get('dni') == "" | data.get('name') == "" | data.get('rol') == "" | data.get('TipoDocumento') == "") {
      closeLoad();
      setAlert(true);
      seterrorsMessage("Por favor completar todos los campos.");
    } else {
      AXIOS.put(API.PUT_USER + dataEdtit.identificacion, {
        apellidos: data.get('last_name'),
        celular: data.get('phone'),
        direccion: data.get('address'),
        email: data.get('email'),
        identificacion: data.get('dni'),
        nit: "900906634",
        nombres: data.get('name'),
        rol_id: data.get('rol'),
        // ciudad_id: origenid.id,
        ciudad_id: dataEdtit.ciudad_id,
        tipoidentificacion_id: data.get('TipoDocumento'),

      }).then(function (data) {
        closeLoad();
        refresh();
        handleCloseN();
        setOpen_toast_update(true);
      })
        .catch(function (error) {
          closeLoad();
          setAlert(true);
          seterrorsMessage();
        });
    }
  };

  //Funcion para crear contraseña de usuario
  const submitPass = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    openLoad();
    try {
      if (data.get('pass') == "") {
        closeLoad();
        setAlert(true);
        seterrorsMessage("Por favor digite una contraseña.");
      } else {
        AXIOS.put(API.PUT_MASTER_PASS + (companyData.id), {
          password: data.get('pass')
        }).then(function (data) {
          closeLoad();
          refresh();
          closeViewOffices();
          setOpen_toast_update(true);
        })
          .catch(function (error) {
            closeLoad();
            setAlert(true);
            seterrorsMessage(error.response.data.messages[0]);
          });
      }
    } catch (error) {
    }

  };

  //Funcion para ver almacenar datos del usuario
  const viewDataOffices = (row) => {
    setCompanyData({
      nombre: (row.nombres) + (" ") + (row.apellidos),
      id: row.identificacion,
      pass: row.identificacion,
    });
    setViewOffices(true);
  };

  //Funcion para cerrar modal de alertas
  const close_editCompany = () => {
    setOpen_editCompany(false);
    setstate("");
    setcity("");
  }

  //Funcion para cerrar alertas D
  const close_deleteCompany = () => setOpen_deleteCompany(false);

  //Funcion para cerrar modal de contraseña
  const closeViewOffices = () => setViewOffices(false);

  //Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_MASTER_USERS + "page=" + (1 + newPage) + "&per_page=" + rowsPerPage + "&user_id=" + localStorage.getItem("user_id_LOGICEM") + `&search={"key":"` + searchTypes + `","value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    });
  };

  //Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_MASTER_USERS + "page=" + (1 + page) + "&per_page=" + (+event.target.value) + "&user_id=" + localStorage.getItem("user_id_LOGICEM") + `&search={"key":"` + searchTypes + `","value":"` + filter + `"}`, {
    }).then((response) => {
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    });
    setPage(page);
  };

  //Funcion para elegir el tipo de busqueda
  const handleChangeSearchType = (event) => {
    setSearchType(event.target.value);

    if (event.target.value == 1) {
      setSearchTypes("nombres");
    };
    if (event.target.value == 2) {
      setSearchTypes("identificacion");
    };
    if (event.target.value == 3) {
      setSearchTypes("departamento");
    };
    if (event.target.value == 4) {
      setSearchTypes("ciudad");
    };

  };

  //Funcion para capturar data del input de filtrar
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    if (event.target.value == "") {
      refresh();
    }
  };

  //Funcion para filtrar en tabla
  const buscador = () => {
    openLoad()
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(API.MASTER_USER_SEARCH + `page=` + 0 + `&per_page=` + 10 + `&user_id=22025&search={"key":"` + searchTypes + `","value":"` + filter + `"}`, {
    }).then((response) => {
      setcompanies(response.data.data);
      closeLoad();
      setTotalPage(response.data.total);
    });
    // setFilter(event.target.value)
    // setPage(0);
    // setRowsPerPage(10); 
  };

  React.useEffect(() => {
    //Pre-carga de apis
    AXIOS.get(API.GET_MASTER_USERS + "page=1&per_page=10&user_id=" + localStorage.getItem("user_id_LOGICEM"), {
    }).then((response) => {
      if (response) {
        setLoading(false);
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.total);
    });
  }, []);

  //Inicializando datos que trae la api de get
  function createData(departamento, identificacion, nombres, apellidos, direccion, celular, correo, role, empresa, ciudad, verificado, role_id, ciudad_id) {
    return { departamento, identificacion, nombres, apellidos, direccion, celular, correo, role, empresa, ciudad, verificado, role_id, ciudad_id };
  }

  //Seleccion de datos que se mostraran en la tabla 
  const rows = companies.map(item => {
    return (
      createData(item.departamento, item.identificacion, item.nombres, item.apellidos, item.direccion, item.celular, item.correo, item.role, item.empresa, item.ciudad, item.verificado, item.role_id, item.ciudad_id)

    )
  })

  return (
    <>
      {(Loading == true) ?
        (Loading === "") ? null :
          <LoadingScreen /> :
        <Dashboard title='Maestro Usuario'>
          <div>
            {/* Notificacion de creacion con exito */}
            <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
              <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
                Creado con exito
              </Alert>
            </Snackbar>
            {/* Notificacion de actualizacion con exito */}
            <Snackbar open={open_update} autoHideDuration={8000} onClose={handleClose_toast_update}>
              <Alert onClose={handleClose_toast_update} severity="success" sx={{ width: '100%' }}>
                Actualizado con exito
              </Alert>
            </Snackbar>
            {/* Notificacion de eliminado con exito */}
            <Snackbar open={open_toast_red} autoHideDuration={8000} onClose={handleClose_toast_red}>
              <Alert onClose={handleClose_toast_red} severity="error" sx={{ width: '100%' }}>
                Eliminado con exito
              </Alert>
            </Snackbar>
            {/* Notificacion de error */}
            <Snackbar open={open_toast_error} autoHideDuration={18000} onClose={handleClose_toast_error}>
              <Alert onClose={handleClose_toast_error} severity="error" sx={{ width: '100%' }}>
                {errorsMessage}
              </Alert>
            </Snackbar>
          </div>
          {/* Modal de alerta */}
          <Modal
            open={open_alert}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                {/* Por favor complete todos los campos obligatorios* */}
                {errorsMessage}
              </Typography>
            </Box>
          </Modal>
          {/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal />
          </Modal>
          <Grid item xs={12} md={2} lg={29} style={{ display: 'flex' }}>
          </Grid>
          {/* Modal de editar */}
          <Modal
            open={openN}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                EDITAR
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 500,
                  bgcolor: 'background.paper',
                  borderRadius: 8,
                  boxShadow: 24,
                  p: 1,
                }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                    EDITAR USUARIO
                  </Typography>
                  <div style={{ float: "rigth" }}>
                    {(validatorErrors == true) ?
                      (validatorErrors === "") ? null :
                        <Alert severity="error">{errorsMessage}</Alert> : ""
                    }
                  </div>
                  <Container component="main" style={{}}>
                    <CssBaseline />
                    <Box component="form" onSubmit={editarUser} noValidate sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={18} >
                        <Grid style={{ display: "flex" }}>
                          <FormControl fullWidth style={{ width: 230 }} >
                            <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue={"1"}
                              label="Tipo Documento"
                              name="TipoDocumento"
                              onChange={handleChange_e}
                            >
                              <MenuItem value={1}>CEDULA DE CIUDADANIA</MenuItem>
                              <MenuItem value={2}>NIT</MenuItem>
                              <MenuItem value={3}>PASAPORTE</MenuItem>
                              <MenuItem value={4}>CEDULA EXTRANJERA</MenuItem>
                              <MenuItem value={5}>TARJETA DE IDENTIDAD</MenuItem>
                              <MenuItem value={6}>NUIP</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField onKeyPress={isInputNumber} style={{ marginLeft: 20, minWidth: 200 }} name="dni" label="N° Identificación" variant="outlined" defaultValue={dataEdtit.identificacion} />
                        </Grid>
                        <Grid style={{ display: "flex", marginTop: 20 }}>
                          <TextField style={{ minWidth: 200 }} name="name" label="Nombres" variant="outlined" defaultValue={dataEdtit.nombres} />
                          <TextField style={{ marginLeft: 20, minWidth: 200 }} name="last_name" label="Apellidos" variant="outlined" defaultValue={dataEdtit.apellidos} />
                        </Grid>
                        <Grid style={{ display: "flex", marginTop: 20 }}>
                          <TextField style={{ minWidth: 200 }} name="address" label="Dirección" variant="outlined" defaultValue={dataEdtit.direccion} />
                          <TextField onKeyPress={isInputNumber} style={{ marginLeft: 20, minWidth: 200 }} name="phone" label="Celular" variant="outlined" defaultValue={dataEdtit.celular} />
                        </Grid>
                        <Grid style={{ display: "flex", marginTop: 20 }}>
                          <TextField style={{ minWidth: 200 }} name="email" label="Correo" variant="outlined" defaultValue={dataEdtit.correo} />
                          <FormControl fullWidth style={{ marginLeft: 25 }}>
                            <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="rol"
                              defaultValue={dataEdtit.role_id}
                              // value={role}
                              label="Rol"
                              onChange={handleRole}
                            >
                              <MenuItem value={1}>Operador Trafico</MenuItem>
                              <MenuItem value={19}>Operador Logistico</MenuItem>
                              <MenuItem value={13}>Operador Seguridad</MenuItem>
                              <MenuItem value={20}>Marketing & Servicio</MenuItem>
                              <MenuItem value={7}>Administrador de Logicem</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div style={{ display: "flex" }}>
                        <Button
                          onClick={handleCloseN}
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 1, background: "gray" }}
                        >
                          Cancelar
                        </Button>
                        <div style={{ margin: 18 }}></div>
                        <Button type="submit" fullWidth variant="contained"
                          sx={{ mt: 3, mb: 1, background: " #e35233" }}
                        > Actualizar
                        </Button>
                      </div>
                    </Box>
                  </Container>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal crear usuario */}
          <Modal
            open={openE}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                CREAR USUARIO
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={editUser} noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={18} >
                    <Grid style={{ display: "flex" }}>
                      <FormControl fullWidth style={{ width: 230 }} >
                        <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          required
                          label="Tipo Documento"
                          name="TipoDocumento"
                          onChange={handleChange_e}
                        >
                          <MenuItem value={1}>CEDULA DE CIUDADANIA</MenuItem>
                          <MenuItem value={2}>NIT</MenuItem>
                          <MenuItem value={3}>PASAPORTE</MenuItem>
                          <MenuItem value={4}>CEDULA EXTRANJERA</MenuItem>
                          <MenuItem value={5}>TARJETA DE IDENTIDAD</MenuItem>
                          <MenuItem value={6}>NUIP</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField onKeyPress={isInputNumber} style={{ marginLeft: 20, minWidth: 200 }} name="dni" label="N° Identificación" variant="outlined" required />
                    </Grid>
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField style={{ minWidth: 200 }} name="name" label="Nombres" variant="outlined" required />
                      <TextField style={{ marginLeft: 20, minWidth: 200 }} name="last_name" label="Apellidos" variant="outlined" required />
                    </Grid>
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField style={{ minWidth: 200 }} name="address" label="Dirección" variant="outlined" required />
                      <TextField onKeyPress={isInputNumber} style={{ marginLeft: 20, minWidth: 200 }} name="phone" label="Celular" variant="outlined" required />
                    </Grid>
                    <Autocomplete
                      freeSolo
                      style={{ marginTop: 20 }}
                      required
                      id="free-solo-2-demo"
                      disableClearable
                      options={listadoCiudades.map((option) => option.nombre)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="origin" autoFocus
                          name="origin" required
                          label="Ciudad"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField style={{ minWidth: 200 }} name="pass" label="Contraseña" variant="outlined" required />
                      <TextField style={{ minWidth: 200, marginLeft: 25 }} name="repass" label="Repetir Contraseña" variant="outlined" required />
                    </Grid>
                    <Grid style={{ display: "flex", marginTop: 20 }}>
                      <TextField style={{ minWidth: 200 }} name="email" label="Correo" variant="outlined" required />
                      <FormControl fullWidth style={{ marginLeft: 25 }}>
                        <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="rol"
                          required
                          label="Rol"
                          onChange={handleRole}
                        >
                          <MenuItem value={1}>Operador Trafico</MenuItem>
                          <MenuItem value={3}>Operador Logistico</MenuItem>
                          <MenuItem value={13}>Operador Seguridad</MenuItem>
                          <MenuItem value={5}>Marketing & Servicio</MenuItem>
                          <MenuItem value={7}>Administrador de Logicem</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleCloseE}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > Crear
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal editar contraseña */}
          <Modal
            open={open_viewOffices}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 1,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                NUEVA CONTRASEÑA <spam style={{ color: "black" }}>{"- "}{companyData.nombre}</spam>
              </Typography>
              <div style={{ float: "rigth" }}>
                {(validatorErrors == true) ?
                  (validatorErrors === "") ? null :
                    <Alert severity="error">{errorsMessage}</Alert> : ""
                }
              </div>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" onSubmit={submitPass} noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={18} >
                      <TextField autoComplete="given-name" name="pass" required type="text"
                        fullWidth id="pass" label="Contraseña" autoFocus defaultValue={companyData.id}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={closeViewOffices}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 1, background: "gray" }}
                    >
                      Cancelar
                    </Button>
                    <div style={{ margin: 18 }}></div>
                    <Button type="submit" fullWidth variant="contained"
                      sx={{ mt: 3, mb: 1, background: " #e35233" }}
                    > Actualizar
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal alertas */}
          <Modal
            open={open_editCompany}
            onClose={close_editCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "auto",
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' color="red" >
                Alertas <spam style={{ color: "gray" }}>{"- "}</spam><spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Alerta Conductor</label>
                  </Grid>
                  {(companyData.alertas == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas}</>)}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Alerta Vehiculo</label>
                  </Grid>
                  {(companyData.alertas_vehiculo == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas_vehiculo}</>)}
                </Box>
              </Container>
            </Box>
          </Modal>
          {/* Modal alerta D */}
          <Modal
            open={open_deleteCompany}
            onClose={close_deleteCompany}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" align='center' color="red">
                Alertas Datacarga <spam style={{ color: "gray" }}>{"- "}</spam><spam style={{ color: "black" }}>{companyData.nombre}</spam>
              </Typography>
              <Container component="main" style={{}}>
                <CssBaseline />
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Habilitado Conductor</label>
                  </Grid>
                  {(companyData.habilitaciones == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones}</>)}
                  <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <label style={{ color: "#0782cd" }}> <spam style={{ fontSize: 20 }}>•</spam> Habilitado Vehiculo</label>
                  </Grid>
                  {(companyData.habilitaciones_vehiculo == "") ?
                    (<>{<div style={{ color: "gray" }}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones_vehiculo}</>)}
                </Box>
              </Container>
            </Box>
          </Modal>
          <Grid item xs={12} md={8} lg={29} marginTop={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3 }} >
              {/* Boton de busqueda */}
              <div style={{ display: "flex", float: "right", marginTop: 20, marginRight: 10 }}>
                <Button type="submit" fullWidth variant="contained" onClick={() => buscador()}
                  sx={{ background: "#FF5733", width: "auto" }}><SearchIcon />
                </Button>
              </div>
              {/* Input de busqueda */}
              <div style={{ display: "flex", float: "right", margin: 10 }}>
                <TextField type="text" label="Buscar" onChange={handleChangeSearch} />
              </div>
              {/* Tipo de filtro de busqueda */}
              <div style={{ float: "right", marginTop: 24, marginRight: 10 }}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Tipo de busqueda</InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={1}
                    value={searchType}
                    label="Tipo de busqueda"
                    onChange={handleChangeSearchType}
                  >
                    <MenuItem value={1}>Nombre de usuario</MenuItem>
                    <MenuItem value={2}>Identificación</MenuItem>
                    <MenuItem value={3}>Departamento</MenuItem>
                    <MenuItem value={4}>Ciudad</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ float: "right", marginTop: 20, marginRight: 10 }}>
                <Button type="submit" fullWidth variant="contained" onClick={() => handleOpenE()}
                  sx={{ background: "#FF5733", width: "auto" }}>Nuevo Usuario
                </Button>
              </div>
              <div style={{ float: "right", marginTop: 24, marginRight: 10 }}>
                {/* Boton de refrescar      */}
                <Button size="small" style={{ background: "#FF5733", color: "white" }} onClick={() => refresh()}><RefreshIcon /></Button></div>
              <TableContainer sx={{ maxHeight: 440 }}>
                {/* Contenido de tabla */}
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <ShowTableColumn checked={columChecked}> */}
                      <TableCell align="center"><b>Identificación</b></TableCell>
                      <TableCell align="center"><b>Nombre Completo</b></TableCell>
                      <TableCell align="center"><b>Dirección</b></TableCell>
                      <TableCell align="center"><b>Ciudad</b></TableCell>
                      <TableCell align="center"><b>Celular</b></TableCell>
                      <TableCell align="center"><b>Correo</b></TableCell>
                      <TableCell align="center"><b>Rol</b></TableCell>
                      <TableCell align="center"><b>Empresa</b></TableCell>
                      <TableCell align="center"><b>Restablecer</b></TableCell>
                      <TableCell align="center"><b>Verificado</b></TableCell>
                      {/* <TableCell align="center"><b>Documentos</b></TableCell> */}
                      <TableCell align="center"><b>Editar</b></TableCell>
                      {/* </ShowTableColumn> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Busqueda por nombre */}
                    {<>{(searchTypes == "nombres") ? (<>
                      {rows.map((row, id) => (
                        <TableRow
                          key={row.identificacion}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.identificacion}</TableCell>
                          <TableCell align="center">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                            (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.direccion == null | row.direccion == "") ? (<>{"-"}</>) :
                            (<>{row.direccion}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.ciudad == null | row.ciudad == "") ? (<>{"-"}</>) :
                            (<>{row.departamento}{", "}{row.ciudad}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                            (<>{row.celular}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.correo == null | row.correo == "") ? (<>{"-"}</>) :
                            (<>{row.correo}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.role == null | row.role == "") ? (<>{"-"}</>) :
                            (<>{row.role}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                            (<>{row.empresa}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "orange" }} onClick={() => viewDataOffices(row)}><KeyIcon /></Button></TableCell>
                          <TableCell align="center"> {<>
                            {(row.verificado == 0) ?
                              (
                                <>
                                  <Button style={{ color: "gray" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>) :
                              (
                                <>
                                  <Button style={{ color: "green" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>
                              )} </>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "gray" }} onClick={() => handleOpenN(row)}><EditIcon /></Button></TableCell>
                        </TableRow>
                      )
                      )}
                    </>) :
                      null}</>}
                    {<>{(searchTypes == "identificacion") ? (<>
                      {rows.map((row, id) => (
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.identificacion}</TableCell>
                          <TableCell align="center">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                            (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.direccion == null | row.direccion == "") ? (<>{"-"}</>) :
                            (<>{row.direccion}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.ciudad == null | row.ciudad == "") ? (<>{"-"}</>) :
                            (<>{row.departamento}{", "}{row.ciudad}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                            (<>{row.celular}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.correo == null | row.correo == "") ? (<>{"-"}</>) :
                            (<>{row.correo}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.role == null | row.role == "") ? (<>{"-"}</>) :
                            (<>{row.role}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                            (<>{row.empresa}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "orange" }} onClick={() => viewDataOffices(row)}><KeyIcon /></Button></TableCell>
                          <TableCell align="center"> {<>
                            {(row.verificado == 0) ?
                              (
                                <>
                                  <Button style={{ color: "gray" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>) :
                              (
                                <>
                                  <Button style={{ color: "green" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>

                                </>
                              )} </>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "gray" }} onClick={() => handleOpenN(row)}><EditIcon /></Button></TableCell>
                        </TableRow>
                      )
                      )}
                    </>) :
                      null}</>}
                    {/* Busqueda por departamento */}
                    {<>{(searchTypes == "departamento") ? (<>
                      {rows.map((row, id) => (
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.identificacion}</TableCell>
                          <TableCell align="center">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                            (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.direccion == null | row.direccion == "") ? (<>{"-"}</>) :
                            (<>{row.direccion}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.ciudad == null | row.ciudad == "") ? (<>{"-"}</>) :
                            (<>{row.departamento}{", "}{row.ciudad}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                            (<>{row.celular}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.correo == null | row.correo == "") ? (<>{"-"}</>) :
                            (<>{row.correo}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.role == null | row.role == "") ? (<>{"-"}</>) :
                            (<>{row.role}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                            (<>{row.empresa}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "orange" }} onClick={() => viewDataOffices(row)}><KeyIcon /></Button></TableCell>
                          <TableCell align="center"> {<>
                            {(row.verificado == 0) ?
                              (
                                <>
                                  <Button style={{ color: "gray" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>) :
                              (
                                <>
                                  <Button style={{ color: "green" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>
                              )} </>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "gray" }} onClick={() => handleOpenN(row)}><EditIcon /></Button></TableCell>
                        </TableRow>
                      )
                      )}
                    </>) :
                      null}</>}
                    {<>{(searchTypes == "ciudad") ? (<>
                      {rows.map((row, id) => (
                        <TableRow
                          key={row.id}
                          hover role="checkbox" tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.identificacion}</TableCell>
                          <TableCell align="center">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) :
                            (<>{row.nombres}{" "}{row.apellidos}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.direccion == null | row.direccion == "") ? (<>{"-"}</>) :
                            (<>{row.direccion}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.ciudad == null | row.ciudad == "") ? (<>{"-"}</>) :
                            (<>{row.departamento}{", "}{row.ciudad}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) :
                            (<>{row.celular}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.correo == null | row.correo == "") ? (<>{"-"}</>) :
                            (<>{row.correo}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.role == null | row.role == "") ? (<>{"-"}</>) :
                            (<>{row.role}</>)}</>}</TableCell>
                          <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) :
                            (<>{row.empresa}</>)}</>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "orange" }} onClick={() => viewDataOffices(row)}><KeyIcon /></Button></TableCell>
                          <TableCell align="center"> {<>
                            {(row.verificado == 0) ?
                              (
                                <>
                                  <Button style={{ color: "gray" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>) :
                              (
                                <>
                                  <Button style={{ color: "green" }} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon /></Button>
                                </>
                              )} </>}</TableCell>
                          <TableCell align="center"><Button style={{ color: "gray" }} onClick={() => handleOpenN(row)}><EditIcon /></Button></TableCell>
                        </TableRow>
                      )
                      )}
                    </>) :
                      null}</>}
                  </TableBody>
                </Table>
              </TableContainer>
              {<>{(rows == "") ?
                <Stack justifyContent="center" style={{ display: "center", margin: 10 }} direction="row">
                  <Typography>NO HAY DATOS DISPONIBLES</Typography>
                </Stack> :
                ""}</>}
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Dashboard>
      }
    </>
  );
};