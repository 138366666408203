import React, { useState, useEffect } from 'react';
import API, { AXIOS } from '../../environment/api';
import { Stack, Divider, Grid, TextField, Autocomplete, Checkbox, Button, Box, Snackbar, Alert } from '@mui/material';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumTrailer({func_update,fun_trailerAsociado, ...props}) {
    const [loading, set_Loading] = useState(true);
    const [blockButton, set_blockButton] = useState(false);
    const [dataColor, array_color] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [dataBody, array_bodyType] = useState([]);
    const [severity, setSeverity] = useState("error");
    const [openSnackbar, setOpeSnackbar] = useState(false);
    const [messageResponse, setMessageResponse] = useState("");
    const [dataTrailarUpdated, setDataTrailarUpdated] = useState({});

    const handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpeSnackbar(false);
      };
  
    const selectTrailerToSap = (event, trailerId) => {
        const trailerSelectedStatus = event.target.checked;
        if (trailerSelectedStatus) {
            changeDataTrailerByProp(trailerId, "selected_to_sap", true);
        } else {
            changeDataTrailerByProp(trailerId, "selected_to_sap", false);
        }
    };

    const handleInputChangeTraielr = (trailerId, propertyName, event, atcValue = null) => {
        let inputValue = null;
        if (atcValue) {
            inputValue = atcValue;
        } else {
            inputValue = event.target.value;
        }
        changeDataTrailerByProp(trailerId, propertyName, inputValue);
    };

    const changeDataTrailerByProp = (trailerId, propertyName, inputValue) => {
        setDataTrailarUpdated(prevObject => {
            const tempObject = { ...prevObject };
            /// Deteriminado si es la propiedad "selected_to_sap"
            /// ya que tiene un comportamiento diferente
            if (propertyName === 'selected_to_sap') {
                for (let key in tempObject) {
                    tempObject[key].selected_to_sap = false;
                }
            }
            if (tempObject[trailerId]) {
                tempObject[trailerId][propertyName] = inputValue;
            } else {
                tempObject[trailerId] = {
                    [propertyName]: inputValue
                }
            }
            return tempObject;
        });
    }

    function errorSnackbar(message) {
        setSeverity("error");
        setMessageResponse(message);
        setOpeSnackbar(true);
        return;
    }

    const saveDataTrailerForm = (event, trailerSelected) => {
        set_blockButton(true);
        event.preventDefault();
        if (trailerSelected['color_trailer'] === '' || trailerSelected['color_trailer'] === '0'){ 
            errorSnackbar('El color del trailer es requerido')
            return;
            }
        if (trailerSelected['modelo_trailer'] === '') {
            errorSnackbar('El modelo del trailer es requerido')
            return;
        }
        if (trailerSelected['placa_trailer'] === '') {
            errorSnackbar('La placa del trailer es requerida')
            return;
        }
        if (trailerSelected['marca_trailer'] === '' || trailerSelected['marca_trailer'] === '0') {
            errorSnackbar('La marca del trailer es requerida')
            return;
        }
        if (trailerSelected['peso_vacio_trailer'] === '') {
            errorSnackbar('El peso vacio del trailer es requerido')
            return;
        }
        if (trailerSelected['peso_capacidad_carga_trailer'] === '') {
            errorSnackbar('La capacidad de carga del trailer es requerida')
            return;
        }
        if (trailerSelected['num_target_propiedad'] === '') {
            errorSnackbar('El numero de tarjeta de propiedad del trailer es requerido')
            return;
        }
        if (trailerSelected['fecha_matricula_trailer'] === '') {
            errorSnackbar('La fecha de matricula del trailer es requerida')
            return;
        }
        if (trailerSelected['carroceria_trailer'] === '' || trailerSelected['carroceria_trailer'] === '0') {
            errorSnackbar('La carroceria del trailer es requerida')
            return;
        }
        if (trailerSelected['selected_to_sap'] === '') {
            errorSnackbar('Seleccionar para enviar a SAP es requerido')
            return;
        }
        AXIOS.post(API.SET_TRAILER_HV + Number(trailerSelected['trailer_id']), {
            "persona_id": props.data.data.persona_id,
            "modelo": trailerSelected['modelo_trailer'],
            "color_codigo": trailerSelected['color_trailer'],
            "marca_trailer": trailerSelected['marca_trailer'],
            "peso_vacio": trailerSelected['peso_vacio_trailer'],
            "peso_capacidad_carga": trailerSelected['peso_capacidad_carga_trailer'],
            "no_tarjeta_propiedad": trailerSelected['num_target_propiedad'],
            "fecha_matricula": trailerSelected['fecha_matricula_trailer'],
            "carroceria_codigo": trailerSelected['carroceria_trailer'],
            "selected_to_sap": trailerSelected['selected_to_sap'],
        }).then(function (data) {
            setSeverity("success");
            setMessageResponse("El trailer se actualizo correctamente");
            fun_trailerAsociado(trailerSelected['placa_trailer']);            
            func_update('put_trailer', true); // Notificamos a CurriculumPageNew que se han llenado todos los datos
            setOpeSnackbar(true);
            set_blockButton(false);
        })
        .catch(function (error) {
            setSeverity("error");
            setMessageResponse(error.response.data.messages[0]);
            setOpeSnackbar(true);
            set_blockButton(false);
        });
    };

    const handleSubmitAddTrailer = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('placa_trailer').length !== 6) {
            setSeverity("error");
            setMessageResponse("La placa del trailer debe tener 6 caracteres");
            setOpeSnackbar(true);
            return;
        }
        set_Loading(true);
        AXIOS.post(API.POST_UPDATE_DATACARGA_TRAILER, {
            "placa_trailer": data.get('placa_trailer'),
            "cedula" : props.data.data.identificacion,
            "placa" : props.data.data.placa,})
        .then( (response) => {
            if(props.asociado === null){
                fun_trailerAsociado(data.get('placa_trailer'));
            }
            if (response.data.data.trailers.length === Object.keys(dataTrailarUpdated).length) {
                setSeverity("info");
                setMessageResponse("El trailer se actualizó con los datos de Datacarga");
            } else {
                setSeverity("success");
                setMessageResponse("El trailer se añadió correctamente");
            }
            InicializandoData(response.data);
            setOpeSnackbar(true);
        })
        .catch( (error) => {
                setSeverity("error");
                if (error.response && error.response.data && error.response.data.messages) {
                    setMessageResponse(error.response.data.messages[0]);
                } else {
                    setMessageResponse("Error al añadir el trailer");
                }
                setOpeSnackbar(true);
            }
        )
        .finally(() => {
            set_Loading(false);
        });
    };

    const InicializandoData = async (data) => {
        const tempObject = { ...dataTrailarUpdated };
        data.data.trailers.map(itemTrailer => {
            tempObject[itemTrailer.trailer_id] = {
                color_trailer: itemTrailer.color_codigo,
                modelo_trailer: itemTrailer.modelo,
                placa_trailer: itemTrailer.placa,
                peso_vacio_trailer: itemTrailer.peso_vacio,
                carroceria: itemTrailer.carroceria,
                num_target_propiedad: itemTrailer.no_tarjeta_propiedad,
                marca_trailer: itemTrailer.marca_trailer,
                peso_capacidad_carga_trailer: itemTrailer.peso_capacidad_carga,
                fecha_matricula_trailer: itemTrailer.fecha_matricula,
                carroceria_trailer: itemTrailer.carroceria_codigo,
                selected_to_sap: itemTrailer.selected_to_sap === 1 ? true : false,
            }
        });
        setDataTrailarUpdated( tempObject );
        //Lista de colores
        AXIOS.get(API.COLOR_SAP, {
        }).then((response) => {
            array_color(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de marcas
        AXIOS.get(API.MARCAS_SAP, {
        }).then((response) => {
            setBrandList(Array.isArray(response.data) ? response.data : []);
        });
        //Lista de carroceria
        AXIOS.get(API.TIPO_CARROCERIA_SAP, {
        }).then((response) => {
            array_bodyType(Array.isArray(response.data) ? response.data : []);
        }).finally(() => {
            set_Loading(false);
        });
    };

    useEffect(() => {
        InicializandoData(props);
        }, []);

    return (
        <>
            {loading ?
                <LoadingScreen />
                : (
                    <>
                        {(dataTrailarUpdated ? dataTrailarUpdated : []) ?
                            (
                                <div>
                                    <Grid container spacing={0} justifyContent="center" style={{ marginTop: 5, display: "center" }}>
                                        <h4>TRAILERS</h4>
                                    </Grid>
                                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
                                        <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
                                            {messageResponse}
                                        </Alert>
                                    </Snackbar>
                                    {
                                        Object.keys(dataTrailarUpdated).map(trailerId => {
                                            return (
                                                <Grid key={trailerId} style={{ border: '2px solid grey', padding: '10px 22px 10px 22px', marginTop: '2vh' }}>
                                                    <Grid container spacing={0} style={{ marginTop: 5, display: "flex", justifyContent: "space-between" }}>
                                                        <Grid style={{ display: "flex", justifyContent: "center" }}> 
                                                            <h4>Placa : </h4> 
                                                            <h4 style={{ fontWeight: "400" }}> {dataTrailarUpdated[trailerId]['placa_trailer']} </h4> <h4>  - Carroceria : </h4> <h4 style={{ fontWeight: "400" }}> {dataTrailarUpdated[trailerId]['carroceria_trailer']} </h4> 
                                                        </Grid>
                                                        <Grid style={{ display: "flex" }}>
                                                            <h4 style={{ fontWeight: "400" }}> Seleccionar para enviar a SAP </h4>
                                                            <Checkbox
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                checked={dataTrailarUpdated[trailerId].selected_to_sap}
                                                                onChange={(event) => selectTrailerToSap(event, trailerId)}
                                                            />
                                                            {
                                                                (props.permiso !== 3 || props.rolSession === 13) ? 
                                                                    <Button variant="outlined" style={{ marginTop: "12px", height: "44px" }} disabled={blockButton} onClick={(event) => saveDataTrailerForm(event, { ...dataTrailarUpdated[trailerId], trailer_id: trailerId })}>GUARDAR</Button>
                                                                : <></>
                                                            }                                                            
                                                        </Grid>
                                                    </Grid>
                                                    <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                                    <Autocomplete
                                                        disablePortal
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        options={dataColor}
                                                        defaultValue={dataColor.find((option) => option.Code === dataTrailarUpdated[trailerId]['color_trailer'])} // Opción por defecto
                                                        getOptionLabel={(option) => option.U_Descripcion}
                                                        onChange={(event, newValue) => {
                                                        handleInputChangeTraielr(trailerId, "color_trailer", event, newValue.Code);
                                                        }}
                                                        sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                                        renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`color_trailer-${trailerId}`}
                                                            label="Color"
                                                            id={`color_trailer-${trailerId}`}
                                                        />}
                                                    />
                                                        <TextField
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "modelo_trailer", event)}
                                                            inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }}
                                                            id="standard-required" name={`modelo_trailer-${trailerId}`} label="Modelo" variant="standard" defaultValue={dataTrailarUpdated[trailerId]['modelo_trailer']} />
                                                        <TextField
                                                            disabled
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "placa_trailer", event)}
                                                            inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }}
                                                            id="standard-required" name={`placa_trailer-${trailerId}`} defaultValue={dataTrailarUpdated[trailerId]['placa_trailer']} label="Placa" variant="standard" />
                                                    </Stack>
                                                    <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                                        <TextField
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "peso_vacio_trailer", event)}
                                                            type="number" sx={{ marginLeft: 2, width: '100%' }}
                                                            id="standard-required" name={`peso_vacio_trailer-${trailerId}`} defaultValue={dataTrailarUpdated[trailerId]['peso_vacio_trailer']} label="Peso vacio" variant="standard" />
                                                        <Autocomplete
                                                            disablePortal
                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                            options={dataBody}
                                                            defaultValue={dataBody.find((option) => option.Code === dataTrailarUpdated[trailerId]['carroceria_trailer'])} // Opción por defecto
                                                            getOptionLabel={(option) => option.Name}
                                                            onChange={(event, newValue) => {
                                                            handleInputChangeTraielr(trailerId, "carroceria_trailer", event, newValue.Code);
                                                            }}
                                                            sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                                            renderInput={(params) =>
                                                            <TextField {...params}
                                                                name={`carroceria_trailer-${trailerId}`}
                                                                label="Tipo de carroceria"
                                                                id={`carroceria_trailer-${trailerId}`}
                                                            />}
                                                        />
                                                        <TextField
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "num_target_propiedad", event)}
                                                            type="number" sx={{ marginLeft: 2, width: '100%' }}
                                                            id="standard-required" name={`num_target_propiedad-${trailerId}`} defaultValue={dataTrailarUpdated[trailerId]['num_target_propiedad']} label="Num. tarjeta de propiedad" variant="standard" />
                                                    </Stack>
                                                    <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                                        <Autocomplete
                                                            disablePortal
                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                            options={brandList}
                                                            defaultValue={brandList.find((option) => option.Code === dataTrailarUpdated[trailerId]['marca_trailer'])} // Opción por defecto
                                                            getOptionLabel={(option) => option.U_Descripcion}
                                                            onChange={(event, newValue) => {
                                                            handleInputChangeTraielr(trailerId, "marca_trailer", event, newValue.Code);
                                                            }}
                                                            sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                                            renderInput={(params) =>
                                                            <TextField {...params}
                                                                name={`marca_trailer-${trailerId}`}
                                                                label="Marca"
                                                                id={`marca_trailer-${trailerId}`}
                                                            />}
                                                        />
                                                        <TextField
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "peso_capacidad_carga_trailer", event)}
                                                            type="number" sx={{ marginLeft: 2, width: '100%' }}
                                                            id="standard-required" name={`peso_capacidad_carga_trailer-${trailerId}`} defaultValue={dataTrailarUpdated[trailerId]['peso_capacidad_carga_trailer']} label="Capacidad de carga" variant="standard" />
                                                        <TextField
                                                            required
                                                            onChange={(event) => handleInputChangeTraielr(trailerId, "fecha_matricula_trailer", event)}
                                                            sx={{ marginLeft: 2, width: '100%' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="standard" id="standard-basic" type="date" label="Fecha matricula" name={`fecha_matricula_trailer-${trailerId}`} defaultValue={dataTrailarUpdated[trailerId]['fecha_matricula_trailer']} />
                                                    </Stack>
                                                </Grid>
                                            )
                                        })
                                    }
                                        {
                                            (props.permiso !== 3 || props.rolSession === 13) ? 
                                            <Grid component={"form"} onSubmit={handleSubmitAddTrailer} container spacing={1} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: "baseline", marginTop: 5 }}>
                                                <Box>
                                                    <h4>QUIERES AÑADIR UN TRAILER NUEVO?</h4>
                                                </Box>
                                                <TextField required type='text' name="placa_trailer" style={{ width: 120, margin: 0, padding: 10, height: 30 }} />
                                                <Button variant="contained" style={{ borderRadius: 5, background: "#3498db", width: 80, height: 40 }} type="submit">
                                                    AÑADIR
                                                </Button>
                                            </Grid>
                                            : <></>
                                        }
                                        
                                </div>) :
                            <Grid component={"form"} onSubmit={handleSubmitAddTrailer} container spacing={1} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "baseline", marginTop: 5 }}>
                                <Box>
                                    <h4>ESTE CONDUCTOR NO TIENE UN TRAILER ASOCIADO, QUIERES AÑADIR UNO?</h4>
                                </Box>
                                <TextField type='text' style={{ width: 120, margin: 0, padding: 10, height: 30 }} />
                                {
                                    (props.permiso !== 3 || props.rolSession === 13) ? 
                                        <Button variant="contained" style={{ borderRadius: 5, background: "#3498db", width: 80, height: 40 }} type="submit">
                                            AÑADIR
                                        </Button>
                                    : <></>
                                }
                            </Grid>
                        }
                    </>
                )
            }
        </>
    );
}
