//Importacion de complementos
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Dashboard from '../components/Dashboard';
import Map from "../components/CustomGoogleMap";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import API, { AXIOS } from '../environment/api';
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DescriptionIcon from '@mui/icons-material/Description';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import LoadingScreen from './LoadingScreen';
import { getDatabase, ref,set,get,child, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import firebaseApp from '../services/firebase';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import axios from 'axios';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Zoom } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import SecurityIcon from '@mui/icons-material/Security';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import estado1 from '../assets/GRIS/230313_ICONOS_Mesa de trabajo 1.svg'; 
import estado2 from '../assets/GRIS/230313_ICONOS-02.svg'; 
import estado3 from '../assets/GRIS/230313_ICONOS-03.svg'; 
import estado4 from '../assets/GRIS/230313_ICONOS-04.svg'; 
import estado5 from '../assets/GRIS/230313_ICONOS-05.svg'; 
import estado6 from '../assets/GRIS/230313_ICONOS-06.svg'; 
import estado7 from '../assets/GRIS/230313_ICONOS-07.svg'; 
import estado8 from '../assets/GRIS/230313_ICONOS-08.svg'; 
import estado9 from '../assets/GRIS/230313_ICONOS-09.svg'; 
import green1 from '../assets/VERDE/230313_ICONOS-19.svg'; 
import green2 from '../assets/VERDE/230313_ICONOS-20.svg'; 
import green3 from '../assets/VERDE/230313_ICONOS-21.svg'; 
import green4 from '../assets/VERDE/230313_ICONOS-22.svg'; 
import green5 from '../assets/VERDE/230313_ICONOS-23.svg'; 
import green6 from '../assets/VERDE/230313_ICONOS-24.svg'; 
import green7 from '../assets/VERDE/230313_ICONOS-25.svg'; 
import green8 from '../assets/VERDE/230313_ICONOS-26.svg'; 
import green9 from '../assets/VERDE/230313_ICONOS-27.svg'; 
import LoadingModal from "../components/LoadingModal";
import PDF_ICON from '../assets/img/PDF_file_icon.svg'; 
import imgNotFound from '../assets/img/logo.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadScript } from '@react-google-maps/api';
import Tooltip from '@mui/material/Tooltip';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Estilo de tema
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
//implementacion de alertas
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 
//Funcion de panel tab 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AvailablePage = () => {
//Funcion para inicializar los datos
  const [open_toast, setOpen_toast] = React.useState(false);
  const [open_cali, OpenCali] = React.useState(false);
  const [open_toast_red, setOpen_toast_red] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [DELETE, setDelete] = React.useState("");
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [Loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [dataTime, setTimeLogistc] = React.useState(0);
  const [dataPhoto, setTimePhoto] = React.useState([]);
  const [dataLogistic, setLogistic] = React.useState(0);
  const [ModalOpen, openModal] = React.useState(false);
  const [openModalToReject, setOpenModalToReject] = React.useState(false);
  const [ModalOpenCumplido, openModalCumplido] = React.useState(false);
  const [ModalEmpity, setEmpity] = React.useState(false);
  const [ModalCheck, setCheck] = React.useState(false);
  const [timeData, setDataTime] = React.useState("");
  const [timeObservation, setDataObservation] = React.useState("");
  const [dataPerfil, setDataPerfil] = React.useState("");
  const [dataPerfil2, setDataPerfil2] = React.useState("");
  const [value_p, setValue_p] = React.useState(0);
  const [dataUser, setUser] = React.useState("");
  const [dataUser2, setUser2] = React.useState("");
  const [dataTime1, setTime1] = React.useState("");
  const [dataTime2, setTime2] = React.useState("");
  const [dataTime3, setTime3] = React.useState("");
  const [dataTime4, setTime4] = React.useState("");
  const [dataTime5, setTime5] = React.useState("");
  const [dataTime6, setTime6] = React.useState("");
  const [dataTime7, setTime7] = React.useState("");
  const [dataTime8, setTime8] = React.useState("");
  const [dataTime9, setTime9] = React.useState("");
  const [calificacion, setRaiting] = React.useState(0);
  const [etiquieta, setEtiqueta] = React.useState([]);
  const [photo_status, setPhotoStatus] = React.useState("");
  const [photo_statusTwo, setPhotoStatusTwo] = React.useState("");
  const [photo_status1, setPhotoStatus1] = React.useState("");
  const [photo_status2, setPhotoStatus2] = React.useState("");
  const [photo_status3, setPhotoStatus3] = React.useState("");
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [rowID, setRID] = React.useState("");
  const [logisticTimes, setLogisticTimes] = React.useState([]);
  const [lastLogisticTime, setLastLogisticTime] = React.useState(null);
  const [generalTooltipText, setGeneralTooltipText] = React.useState("");

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const [openError, setOpenError] = React.useState(false);
//Funcion para abrir modal de viaje vencido
  const handleOpenError = () => setOpenError(true);
//Funcion para cerrar modal de viaje vencido
  const handleCloseError = () => setOpenError(false);
//Funcion para abrir modal de carga
  const openLoad = () => setLoad(true);
//Funcion para cerrar modal de carga
  const closeLoad = () => setLoad(false);
//Funcion para etiquetas
  const handleChangeETIQ = (event) => {
    const {
      target: { value },
    } = event;
  
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
//Funcion para cerrar alerta de datos incompletos
  const CloseEmpity  = () => setEmpity (false);
//Funcion para cerrar alerta de viaje calificado
  const CloseCheck  = () => setCheck (false);
//Funcion para calificar conductor
  const CheckSend = (event) => {       
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let etiq1 = etiquieta.find(({ etiqueta }) => etiqueta === personName[0]);
    let etiq2 = etiquieta.find(({ etiqueta }) => etiqueta === personName[1]);
if(calificacion == 0 | data.get('comentario') == "" | personName[0] == "" | personName[1] == "" ){
  setEmpity(true);
}else{
  AXIOS.post("http://izicarga.logicem.com/api/calificacion/conductor",{
    busquedavehiculo_id: DELETE.busquedavehiculo_id,
    calificacion: calificacion,
    descripcion: data.get('comentario'),
    etiqueta_id: [ etiq1.id, etiq2.id ],
    persona_id : DELETE.persona_id
  }).then((response) => {
    OpenCali(false)
    setViewOffices(false)
    setRaiting(0)
    setEtiqueta([])
    setPersonName([])
    refresh()
  // setcompanies(response.data.data);
}).catch((error) => {
  setCheck(true);
  console.error(error);
});
}
   
 };  
//Funcion para subir archivos e imagenes
 const subirArchivos=(e)=>{ 
      const f = new FormData();
      for(let index = 0; index < e.length; index++){
        f.append("file", e[index]);
        f.append("nombre","Documento");
        f.append("busquedavehiculo_id", DELETE.busquedavehiculo_id);
        f.append("persona_id", DELETE.persona_id);
      }
      AXIOS.post(API.POST_DOCUMENT,f, {
      headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        setOpen_toast(true);
        logistics_photo();
      }).catch((error) => {
      }); 
};
//Funcion para cargar etiquetas de calificacion
  const loadEtique = (newValue) => { 
    AXIOS.get("http://izicarga.logicem.com/api/etiquetar/listaetiquetasconductor?calificacion="+newValue,{
    }).then((response) => {
    // setcompanies(response.data.data);
    setEtiqueta(response.data.etiquetas);
  });
};  
//Funcion para tab de perfil
  const handleChange_p = (event, newValue) => {
    setValue_p(newValue);
  };
//Funcion para tab de vista generala
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//Funcion de navegacion
  let navigate = useNavigate(); 
  const linkTo = (url) =>{
   return navigate(url);
  }
//Funcion para cerrar modal de tiempo logistco
  const closeModal = () => {
    openModal(false);
    setPhotoStatus("");
    setPhotoStatusTwo("");
    setDataObservation("");
  }
//Funcion para cerrar modal de rechazo
  const closeModalToReject = () => {
    setOpenModalToReject(false);
  }
//Funcion para cerrar modal de cumplido
  const closeModalCumplido = () => {
    openModalCumplido(false);
    setPhotoStatus1("");
    setPhotoStatus2("");
    setPhotoStatus3("");
    setDataObservation("");
  }
//Funcion para consumir api de tiempo logistico
  const logistics_time = () => {
    openLoad();
   axios.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin',{
    "busqueda_id": DELETE.busquedavehiculo_id,
    "persona_id":dataUser,
    "user_id":dataUser2
}, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
    }
}).then((response) => {
  closeLoad();
  console.log(response.data.data)
  if(!response.data.data[0]){
    console.log("easy money")
    setTime1("");
    setTime2("");
    setTime3("");
    setTime4("");
    setTime5("");
    setTime6("");
    setTime7("");
    setTime8("");
  }
  for (var i = 0; i < response.data.data.length; i++) {

    setTime1(response.data.data[0]);
    if(response.data.data[1]){
      setTime2(response.data.data[1]);
    }else{
      setTime2(""); 
    }

    if(response.data.data[2]){
      setTime3(response.data.data[2]);
    }else{
      setTime3(""); 
    }
   
    if(response.data.data[3]){
      setTime4(response.data.data[3]);
    }else{
      setTime4(""); 
    }

    if(response.data.data[4]){
      setTime5(response.data.data[4]);
    }else{
      setTime5(""); 
    }

    if(response.data.data[5]){
      setTime6(response.data.data[5]);
    }else{
      setTime6(""); 
    }
    
    if(response.data.data[6]){
      setTime7(response.data.data[6]);
    }else{
      setTime7(""); 
    }

    if(response.data.data[7]){
      setTime8(response.data.data[7]);
    }else{
      setTime8(""); 
    }
  
  }

}).catch((error) => {
  closeLoad();
}); 
  };
//Funcion para carga foto de tiempo logistico
const logistics_photo = () => {
  const formData = new FormData();
  formData.append('busquedavehiculo_id',DELETE.busquedavehiculo_id);
  formData.append('persona_id',DELETE.persona_id);
  openLoad();
  axios({
    method: "post",
    url: "http://izicarga.logicem.com/api/documentos/documentosViaje_web",
    data: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`, "Content-Type": "multipart/formData"
    }
  }).then((response) => {
    setTimePhoto(response.data.documents);  
    closeLoad();
  }).catch((error) => {
    closeLoad();
  }); 
  
};

//Funcion para recargar pagina
  const refresh = () => {       
    AXIOS.get("http://izicarga.logicem.com/api/admin/services/"+localStorage.getItem("id_interesado")+"/interested",{
    }).then((response) => {
      setcompanies(response.data);     
    }); 
 
  };
//Funcion para cerrar notifiacion    
  const handleClose_toast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast(false);
  };
//Funcion para cerrar notificacion de error
  const handleClose_toast_red = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen_toast_red(false);
  };
//Funcion para cerrar modal principal
  const closeViewOffices= () =>{
    setViewOffices(false);
    setValue(0);
  }; 

  React.useEffect(() => {
    // Aquí puedes realizar cualquier lógica que necesites cuando tuVariable cambie
    // Por ejemplo, puedes actualizar el estado local
    console.log("CAMBIANDO EL ESTADO DEL ULTIMO MARCADOR");
    // También puedes realizar otras acciones aquí según tu necesidad

  }, [lastLogisticTime]); // Se ejecutará cada vez que tuVariable cambie
//Funcion para cargar datos del conductor
  const loadProfileDriver = (row) => {
    openLoad();
    AXIOS.get(API.GET_PERFIL_CONDUCTOR+row.cedula, {})
            .then(function (data) {
              closeLoad();
              setViewOffices(true);
             console.log("contratado",data.data.person)
             setDataPerfil(data.data.person);
             setUser(data.data.person.persona_id);
             setUser2(data.data.person.user_id);
             setDataPerfil2(data.data.roles);


             /// Obtenemos los tiempos logisticos
             AXIOS.post(API.TIEMPOS_LOGISTICOS_SHOW_ADMIN,{
                  "busqueda_id": row.busquedavehiculo_id,
                  "persona_id": row.persona_id
             })
             .then(function (response) {
              let response_data = response.data.data;
               setLogisticTimes(response_data);
               setLastLogisticTime(response_data.length > 0 ? response_data[response_data.length - 1] : null);
               console.log("OBTENIENDO TIEMPOS LOGISTICOS ",response_data , "ULTIMO ELEMENTO " , response_data.length > 0 ? response_data[response_data.length - 1] : null);
            }).catch(function (response_error) {
                 console.error("ERROR AL OBTENER LOS TIEMPOS LOGISTICOS ",response_error)
              });

              }).catch(function (error) {
             });
    setDelete({
      vehiculopersona_id: row.vehiculopersona_id,
      busquedavehiculo_id: row.busquedavehiculo_id,
      estado: row.estado,
      motivo: row.motivo,
      persona_id: row.persona_id,
      U_Cantidad: row.peso,
      nombre: row.nombre,
      placa: row.placa,
      celular:row.celular,
      cedula: row.cedula,
      tipovehiculo: row.tipovehiculo,
      user_id: row.user_id
      }) 
if(row.estado === null){
   // AXIOS.post("http://137.184.61.53/logicem-back/public/api/servicios/estadointeresado_web", {
    AXIOS.post("http://izicarga.logicem.com/api/servicios/estadointeresado_web", {
          busquedavehiculo_id: row.busquedavehiculo_id,
          estado: 1,
          motivo: row.motivo,
          U_Cantidad: row.peso,
          user_id:	row.user_id,
          vehiculopersona_id: row.vehiculopersona_id,
            })
            .then(function (data) {
             console.log("contratado",data)
             refresh();  
            }).catch(function (error) {
              refresh();  
            });
}
  };

//Funcion para consumir api de contratacion
  const contratado = () => {       
    AXIOS.post("http://izicarga.logicem.com/api/servicios/estadointeresado_web", {
          busquedavehiculo_id: DELETE.busquedavehiculo_id,
          estado: 2,
          motivo: DELETE.motivo,
          U_Cantidad: DELETE.peso,
          user_id:	DELETE.user_id,
          vehiculopersona_id: DELETE.vehiculopersona_id,
            })
            .then(function (data) {
             console.log("contratado",data)
             refresh();  
             closeViewOffices();
            }).catch(function (error) {
              refresh();  
              closeViewOffices();
            });
  };  
//Funcion para consumir api de descartar
  const rejectServiceAdmin = (event) => {       
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log("rejectServiceAdmin ", data , " event ",event)
    AXIOS.post(API.REJECT_SERVICE_ADMIN, {
              user_id:DELETE.user_id,
              busqueda_vehiculo_id:  DELETE.busquedavehiculo_id ,
              vehiculopersona_id:DELETE.vehiculopersona_id,
              estado:0,
              motivo: data.get("reason-for-rejection") + ", " + data.get("reason-for-rejection-text"),
            })
            .then(function (data) {
             refresh();  
             closeViewOffices();
             setOpenModalToReject(false);
            }).catch(function (error) {
              console.log("PROCESO DE DESCARTE REALIZADO FALLIDO ", error)
              handleOpenError();  
              closeViewOffices();
              setOpenModalToReject(false);
            });
  };  

//Funcion para consumir primer tiempo logistico
  const time1 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row) =>{
         if(row.estadologistico_id === -1){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((raw) =>{
         if(raw.estadologistico_id === -1 && row.id != raw.id){
            setPhotoStatusTwo(raw.link);
         }
      })};}); 
        };
      })};
    }); 

    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
     }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Llegué al sitio de cargue"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };

//Funcion para consumir segundo tiempo logistico
  const time2 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row) =>{
         if(row.estadologistico_id === 1){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((raw) =>{
         if(raw.estadologistico_id === 1 && row.id != raw.id){
            setPhotoStatusTwo(raw.link);
         }
      })};}); 
        };
      })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Inicié cargue"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };

//Funcion para consumir tercer tiempo logistico
  const time3 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row) =>{
         if(row.estadologistico_id === 2){
          console.log("row",row)
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
            AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
            }).then((response) => {
             {response.data.data.map((raw) =>{
                if(raw.estadologistico_id === 2 && row.id != raw.id){
                   setPhotoStatusTwo(raw.link);
                }
             })};}); 
               };
             })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Finalicé cargue"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };

//Funcion para consumir cuarto tiempo logistico
  const time4 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row, id) =>{
         if(row.estadologistico_id === 3){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
            AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
            }).then((response) => {
             {response.data.data.map((raw) =>{
                if(raw.estadologistico_id === 3 && row.id != raw.id){
                   setPhotoStatusTwo(raw.link);
                }
             })};}); 
               };
             })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Inicié Ruta"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };
 //Funcion para consumir quinto tiempo logistico 
  const time5 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row, id) =>{
         if(row.estadologistico_id === 4){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
            AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
            }).then((response) => {
             {response.data.data.map((raw) =>{
                if(raw.estadologistico_id === 4 && row.id != raw.id){
                   setPhotoStatusTwo(raw.link);
                }
             })};}); 
               };
             })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Llegué al sitio de descargue"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };
  //Funcion para consumir sexto tiempo logistico
  const time6 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row, id) =>{
         if(row.estadologistico_id === 5){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
            AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
            }).then((response) => {
             {response.data.data.map((raw) =>{
                if(raw.estadologistico_id === 5 && row.id != raw.id){
                   setPhotoStatusTwo(raw.link);
                }
             })};}); 
               };
             })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Inicié descargue"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };
//Funcion para consumir septimo tiempo logistico
  const time7 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
     }).then((response) => {
      {response.data.data.map((row, id) =>{
         if(row.estadologistico_id === 6){
            setPhotoStatus(row.link);
            setDataObservation(row.observacion);
            AXIOS.get("http://izicarga.logicem.com/api/show/busqueda_eventualidades/web?busqueda_vehiculo_id="+ DELETE.busquedavehiculo_id+"&user_id="+ DELETE.user_id, {
            }).then((response) => {
             {response.data.data.map((raw) =>{
                if(raw.estadologistico_id === 6 && row.id != raw.id){
                   setPhotoStatusTwo(raw.link);
                }
             })};}); 
               };
             })};
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Finalizar viaje"){
           setDataTime(row);
           closeLoad();
           openModal(true);
        }
     })};
    }); 
  };
//Funcion para consumir octavo tiempo logistico
  const time8 = () => {
    openLoad(true);
    AXIOS.get("http://izicarga.logicem.com/api/show/cumplido_viaje/app?busqueda_vehiculo_id="+DELETE.busquedavehiculo_id+"&user_id="+DELETE.user_id, {
      busqueda_vehiculo_id: DELETE.busquedavehiculo_id,
      user_id: DELETE.user_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
  }).then((response) => {
    setPhotoStatus1(response.data.data.data[0].link);
    setPhotoStatus2(response.data.data.data[1].link);
    setPhotoStatus3(response.data.data.data[2].link);
    }); 
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
    }).then((response) => {
      {response.data.data.map((row, id) =>{
        if(row.estadoslogisticos_nombre === "Cumplido"){
           setDataTime(row);
           closeLoad();
           openModalCumplido(true);
        }
     })};
    }); 
  };
//Funcion para consumir noveno tiempo logistico
  const time9 = () => {
    openModal(true);
    AXIOS.post('http://izicarga.logicem.com/api/tiempologistico/showAllAdmin', {
      busqueda_id: DELETE.busquedavehiculo_id,
      persona_id: DELETE.persona_id
    }, {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token_LOGI")}`,
      }
  }).then((response) => {
    setDataTime(response.data.data[8])
  }); 
  };


  React.useEffect(() => {
//Pre-carga de apis
    AXIOS.get("http://izicarga.logicem.com/api/admin/services/"+localStorage.getItem("id_interesado")+"/interested",{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
      setcompanies(response.data);
      const res_data = response.data[0];
      console.log("SERVICIO DE INTERESADOS ", response.data);
      if(res_data){
        setGeneralTooltipText(`INTERESADOS EN EL SERVICIO # ${res_data.busquedavehiculo_id} - DE ${res_data.servicio_origen} A ${res_data.servicio_destino}`)
      }
    }); }, []);
  
//Inicializando datos que trae la api de get  
  function createData(id, oferta, estado, cedula, nombre, celular, placa, 
    tipovehiculo, viajes_realizados,empresa, verificado, tipo,vehiculopersona_id,busquedavehiculo_id,motivo,persona_id,peso,ciudad_origen,  user_id,updated_at) {
    return {id, oferta, estado, cedula, nombre, celular, placa,
      tipovehiculo, viajes_realizados,empresa, verificado, tipo,vehiculopersona_id,busquedavehiculo_id,motivo,persona_id,peso,ciudad_origen, user_id,updated_at};
  }

//Seleccion de datos que se mostraran en la tabla  
  const rows = companies.map(item => {
    return (
      createData(item.id, item.oferta, item.estado, item.cedula, item.nombre, item.celular , item.placa, 
        item.tipovehiculo, item.viajes_realizados, item.empresa, item.verificado, item.tipo, item.vehiculopersona_id, item.busquedavehiculo_id,
        item.motivo, item.persona_id, item.peso,item.ciudad_origen, item.user_id,item.updated_at)
    )});

//Estilo de modals
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "auto",
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      borderRadius:4,
      boxShadow: 24,
      p: 2,
    };
//Inicializacion de datos de perfil del conductor
    const [openP, setOpenP] = React.useState(false);
//Funcion para abrir modal de perfil del conductor
    const handleOpenP = () => setOpenP(true);
//Funcion para cerrar modal de perfil del conductor    
    const handleCloseP = () => {setOpenP(false);
    {a11yProps(0)}}
//Funcion para abir modal de califcacion    
    const OpenRaiting = () => OpenCali(true);
//Funcion para cerrrar modal dee raiting    
    const CloseCali = () => {
      OpenCali(false)
      setRaiting(0)
      setEtiqueta([])
      setPersonName([])
    };
    const handleMapLoad = (map) => {
      // Esta función se llama cuando la API de Google Maps se carga correctamente
      console.log('Google Maps API cargada correctamente');
    };

return (
  <>
  <LoadScript
      googleMapsApiKey="AIzaSyANRZtWx-U0S52vbQbThAIU20aSymAB3Jw"
      onLoad={handleMapLoad}
    >
   {(Loading == true)?
    (Loading ==="")?null:
    <LoadingScreen/>:  
    <Dashboard >
{/* Modal de carga */}
    <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <LoadingModal/>       
    </Modal>
{/* Modal de vencimiento de servicio */}
      <Modal
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description">
            El servicio se encuentra vencido
          </Typography>
        </Box>
      </Modal>
{/* Modal de datos incompletos */}
      <Modal
        open={ModalEmpity}
        onClose={CloseEmpity}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
    <Box   sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        bgcolor: 'background.paper',
        borderRadius: 8,
        boxShadow: 24,
        p: 2,
      }}
    >
      <Typography style={{textAlign:"center"}} component="legend"><b>Llenar todo los campos*</b></Typography>
      </Box>
      </Modal>
{/* Modal de viaje cuando ya se ha calificado */}
        <Modal
        open={ModalCheck}
        onClose={CloseCheck}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box    
        sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        borderRadius: 8,
        boxShadow: 24,
        p: 2,
      }}
      >
        <Typography style={{textAlign:"center"}} component="legend"><b>Este viajes ya se califico</b></Typography>
        </Box>
        </Modal>
{/* Modal de califcacion de conductor */}
      <Modal
        open={open_cali}
        onClose={CloseCali}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box
        component="form" onSubmit={CheckSend} noValidate
        autoComplete="off"
        sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 8,
        boxShadow: 24,
        p: 2,
      }}
    >
      <Typography style={{textAlign:"center"}} component="legend"><b>CALIFICAR</b></Typography>
      <Rating style={{marginLeft:120, marginTop:10}}
        name="simple-controlled"
        value={calificacion}
        onChange={(event, newValue) => {
          setRaiting(newValue);
          loadEtique(newValue);
          // OpenCali(false);
          // setViewOffices(false);
        }}
      />
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Etiqueta</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChangeETIQ}
          input={<OutlinedInput id="select-multiple-chip" label="Etiqueta" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
        {etiquieta.map((row) => (
            <MenuItem
              key={row.id}
              value={row.etiqueta}
              style={getStyles(row.etiqueta, personName, theme)}
            >
              {row.etiqueta}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    <TextField style={{marginTop:20}} size="medium" autoComplete="given-name" label="Escriba un comentario"   
           fullWidth name="comentario" autoFocus 
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">   
               </InputAdornment>
             ),
           }}
           />
               <Button    onClick={CloseCali} type="submit" variant="contained" size="medium"
    sx={{ mt: 3, mb: 2, background:"gray", float:"left", align:'left', width:"40%", marginLeft:"4%"}}>
    Cancelar</Button>
            <Button type="submit" variant="contained"  size="medium"
    sx={{ mt: 3, mb: 2, background:" #e35233", float:"right", align:'right', width:"40%", marginRight:"4%"}}> 
    Publicar</Button> 
      {/* <Typography component="legend">Read only</Typography>
      <Rating name="read-only" value={value} readOnly />
      <Typography component="legend">Disabled</Typography>
      <Rating name="disabled" value={value} disabled />
      <Typography component="legend">No rating given</Typography>
      <Rating name="no-value" value={null} /> */}
    </Box>
    </Modal>
{/* Modal del perifl del conductor */}
      <Modal
        open={openP}
        onClose={handleCloseP}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center"}}>
            PERFIL CONDUCTOR
          </Typography>
          <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value_p} onChange={handleChange_p} aria-label="basic tabs example">
          <Tab label="INFORMACIÓN" {...a11yProps(0)} />
          {/* <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Grid style={{display:"flex", justifycontent:"center"}}> 
      <TextField style={{minWidth:180}} id="filled-basic" label="Dirección" defaultValue={dataPerfil.direccion} variant="filled" />
      <TextField style={{marginLeft:20, minWidth:180}}id="filled-basic" label="Email" defaultValue={dataPerfil.email} variant="filled" />
      </Grid>

      <Grid style={{display:"flex", justifycontent:"center", marginTop:10}}> 
      <TextField style={{minWidth:180}} id="filled-basic" label="Ciudad" defaultValue={dataPerfil.ciudad} variant="filled" />
      <TextField style={{marginLeft:20, minWidth:180}}id="filled-basic" label="Celular" defaultValue={dataPerfil.celular} variant="filled" />
      </Grid>

      <Grid style={{display:"flex", justifycontent:"center", marginTop:10}}> 
      <TextField style={{minWidth:180}} id="filled-basic" label="Teléfono" defaultValue={dataPerfil.telefono} variant="filled" />
      {/* <TextField style={{marginLeft:20, minWidth:180}}id="filled-basic" label="Vehiculo Transportista" defaultValue={dataPerfil2.roles_vehiculos[0]} variant="filled" /> */}
      </Grid>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </Box>
      </Modal>
      <Button style={{background:"##FF5733"}} onClick={()=>null}>{generalTooltipText}</Button>
      <br/>
      <br/>
      <Button style={{background:"white"}} onClick={()=>linkTo('/services')}><ArrowBackIcon/>Regresar a las publicaciones</Button>
      <br/>
  <div>
{/* Notificacion de exito */}
      <Snackbar open={open_toast} autoHideDuration={8000} onClose={handleClose_toast}>
        <Alert onClose={handleClose_toast} severity="success" sx={{ width: '100%' }}>
         Documento subido con exito
        </Alert>
      </Snackbar>
{/* Notificacion de cancelado */}
      <Snackbar open={open_toast_red} autoHideDuration={8000} onClose={handleClose_toast_red}>
        <Alert onClose={handleClose_toast} severity="error" sx={{ width: '100%' }}>
         Cancelado conductor con exito
        </Alert>
      </Snackbar>
          </div>
            <br/>
{/* Modal de tiempo logistico */}
        <Modal
         open={ModalOpen}
         onClose={closeModal}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
            <Typography textAlign="center"><b>{timeData.estadoslogisticos_nombre}</b></Typography>
            
            {/* INICIO MOSTRAR IMGS TIEMPOS LOGISTICOS */}
            <div style={{display:"flex" , flexDirection: "column", padding: "20px"}}>
                {/* IMAGENES */}
                <div style={{display:"flex", justifyContent: "space-between"}}>
                  
                  <div  style={{display:"flex" , flexDirection: "column"}}>
                    <Grid  container
                          spacing={0}
                          alignItems="center"
                          justifyContent="center"
                          style={{ marginTop: 5, display:"center", marginRight:10 }}>
                            {
                            (photo_status)?
                            <img src={photo_status}  height="300" width="300"/>
                            : <img src={imgNotFound} style={{ width: 250, height: 250, }} alt="Imagen no encontrada"/>
                            }
                            <br/>
                    </Grid>
                    <Typography>Ciudad: {timeData.ciudad}</Typography>
                  </div>

                  <div style={{display:"flex" , flexDirection: "column"}}>
                      <Grid container
                            spacing={0}
                            alignItems="center"
                            justifyContent="center"
                            style={{ marginTop: 5, display:"center", marginRight:10 }}>
                                
                                {
                                          (photo_statusTwo)?
                                          <img src={photo_statusTwo}  height="300" width="300"/>
                                          : <img src={imgNotFound} style={{ width: 250, height: 250, }} alt="Imagen no encontrada"/>
                                      }
                                      <br/>
                            
                      </Grid>
                      <Typography>Ciudad: {timeData.ciudad}</Typography>
                  </div>

                </div>
                {/* OBSERVACIONES */}
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '30vh' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <div style={{display:"flex" , marginTop:"29px" ,justifyContent: "center"}}>
                      <TextField
                          id="outlined-multiline-static"
                          label="Observación"
                          autoFocus
                          InputProps={{
                            readOnly: true,
                          }}
                          multiline
                          rows={3}
                          defaultValue={timeObservation}
                        />
                    </div>
                  </Box> 
                </div>
            </div>

            
            {/* FIN MOSTRAR IMGS TIEMPOS LOGISTICOS */}
    </Box>
    </Modal>


{/* -------------------------------------------------------- */}
{/* Modal de descartar  */}
    <Modal
         open={openModalToReject}
         onClose={closeModalToReject}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
            
            {/* INICIO MOSTRAR IMGS TIEMPOS LOGISTICOS */}
            <div style={{display:"flex" , flexDirection: "column", padding: "20px"}}>
              
              {/* Titulo del formulario */}
              <Typography textAlign="center"><b>¿Por que no lo contrató?</b></Typography>
            
              {/* Contenido del formulario */}
              <form onSubmit={rejectServiceAdmin}>

                  <div style={{display:"flex"}}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Selecciona una opción:</FormLabel>
                      <RadioGroup
                        row
                        aria-label="reason-for-rejection"
                        name="reason-for-rejection"
                        // value={selectedOption}
                        // onChange={handleOptionChange}
                      >
                        <FormControlLabel
                          value="No contestan"
                          control={<Radio />}
                          label="No contestan"
                        />
                        <FormControlLabel
                          value="La llamada se va a buzón de mensajes"
                          control={<Radio />}
                          label="La llamada se va a buzón de mensajes"
                        />
                        <FormControlLabel
                          value="Ya tiene viaje"
                          control={<Radio />}
                          label="Ya tiene viaje"
                        />
                        <FormControlLabel
                          value="No viaja a esa ruta"
                          control={<Radio />}
                          label="No viaja a esa ruta"
                        />
                        <FormControlLabel
                          value="Se encuentra en otra ciudad"
                          control={<Radio />}
                          label="Se encuentra en otra ciudad"
                        />
                        <FormControlLabel
                          value="No le sirve el flete"
                          control={<Radio />}
                          label="No le sirve el flete"
                        />
                        <FormControlLabel
                          value="El vehículo no cuenta con el peso requerido"
                          control={<Radio />}
                          label="El vehículo no cuenta con el peso requerido"
                        />
                        <FormControlLabel
                          value="No pasa filtros de seguridad"
                          control={<Radio />}
                          label="No pasa filtros de seguridad"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                

                  <TextField
                    label="Motivo"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    name="reason-for-rejection-text"
                    // value={textValue}
                    // onChange={handleTextChange}
                    margin="normal"
                  />

                  <Button type="submit" variant="contained" color="primary">
                    ACEPTAR
                  </Button>
              </form>
            </div>

            {/* FIN MOSTRAR IMGS TIEMPOS LOGISTICOS */}
    </Box>
    </Modal>


{/* -------------------------------------------------------- */}

{/* Modal de estado logistico cumplido */}
       <Modal
         open={ModalOpenCumplido}
         onClose={closeModalCumplido}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
        
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
            <Typography textAlign="center"><b>{timeData.estadoslogisticos_nombre}</b></Typography>
            <div style={{display:"flex"}}>
 <Grid container
       spacing={0}
      alignItems="center"
       justifyContent="center"
       style={{ marginTop: 5, display:"center", marginRight:10}}>
           {
                    (photo_status1)?
                    <img src={photo_status1}  height="300" width="300"/>
                    : <img src={"http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png"}  height="300" width="300"/>
                }
                 <br/>
       <Typography>Evidencia N°1</Typography>
       </Grid>
      
 <Grid container
       spacing={0}
      alignItems="center"
       justifyContent="center"
       style={{ marginTop: 5, display:"center", marginRight:10 }}>
           {
                    (photo_status2)?
                    <img src={photo_status2}  height="300" width="300"/>
                    : <img src={"http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png"}  height="300" width="300"/>
                }
                 <br/>
       <Typography>Evidencia N°2</Typography>
       </Grid>
      
 <Grid container
       spacing={0}
      alignItems="center"
       justifyContent="center"
       style={{ marginTop: 5, display:"center" }}>
           {
                    (photo_status3)?
                    <img src={photo_status3}  height="300" width="300"/>
                    : <img src={"http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png"}  height="300" width="300"/>
                }
                 <br/>
       <Typography>Evidencia N°3</Typography>
       </Grid>
       </div>
          </Box>
          </Modal>
{/* Modal principal del interesado */}
       <Modal
         open={open_viewOffices}
         onClose={closeViewOffices}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 900,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
           <Typography id="modal-modal-title" variant="h6" component="h2"  align='center'>
           {"Conductor - "}{DELETE.nombre}
           </Typography> 
           <br/>
           {/* <div style={{float: "rigth"}}>
            {(validatorErrors == true)?
           (validatorErrors ==="")?null:
                    <Alert severity="error">{errorsMessage}</Alert>: ""
                }              

            </div> */}
            <div style={{display:"flex"}}>
           <Container component="main" style={{  }}>
        <CssBaseline />     
        <Grid style={{display:"flex"}}>

        <Grid >
        <Grid item xs={12} sm={6} style={{display: "flex"}}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <StarIcon style={{marginLeft:10, color:"gray"}}/>
      <CheckCircle style={{marginLeft:10, color:"gray"}}/>
      <SecurityIcon style={{marginLeft:10, color:"gray"}}/>
        </Grid> 
        <Grid style={{display:"flex", marginTop:2}}>
        <Button onClick={handleOpenP}>Ver Perfil</Button>
        {/* <Button style={{marginLeft:6}}>Ver Vehiculo</Button> */}
        
        </Grid>
        <Grid container spacing={2} style={{marginTop:7}}>
            <Grid item xs={12} sm={6}>
            <TextField
          id="standard-read-only-input"
          label="CC"
          defaultValue={DELETE.cedula}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
            </Grid>
            
            <Grid item xs={12} sm={6}>
            <TextField
          id="standard-read-only-input"
          label="Celular"
          defaultValue={DELETE.celular}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
          </Grid>

               <Grid item xs={12} sm={6}>
               <TextField
          id="standard-read-only-input"
          label="Tipo Vehiculo"
          defaultValue={DELETE.tipovehiculo}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
          id="standard-read-only-input"
          label="Placa"
          defaultValue={DELETE.placa}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
            </Grid>
            <br/>
            <>
            { 
            DELETE.estado == "1" 
            ?(<>
              <div style={{display:"flex"}}>
              <Grid style={{marginTop:20, marginLeft:12}}>
              <Button style={{background:"red"}} variant="contained" onClick={ () => setOpenModalToReject(true) }> DESCARTAR </Button></Grid>
              <Grid style={{marginTop:20, marginLeft:12}}>
              <Button style={{background:"#0885c8"}} variant="contained" onClick={contratado}>CONTRATAR</Button></Grid>
              </div>
            </>): 
              (DELETE.estado == "2" ? (<></>) :  
              DELETE.estado == "3" ?  (<> <Grid style={{marginTop:20, marginLeft:12}}>
                <Button style={{background:"orange"}} variant="contained" onClick={OpenRaiting}><StarIcon/> CALIFICAR</Button></Grid></>) : 
              DELETE.estado == "4" ?  (<></>): 
              null)}
            </>
        </Grid>
        </Grid>
{/* Tab de navegacion principal          */}
          <Grid>
          <div style={{float:"right"}}>
            <Box sx={{width: 570}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="MAPA" {...a11yProps(0)} />
          <Tab label="TIEMP. LOGÍSTICOS" {...a11yProps(1)}  onClick={logistics_time}/>
          <Tab label="IMAGÉN - DOCUMENTO" {...a11yProps(2)} onClick={logistics_photo}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       <div>
        {/* MAPA DEL ULTIMO TIEMPO LOGISTICO */}
          <Map markers={lastLogisticTime ? [{ position :{ lat: lastLogisticTime.latitud, lng: lastLogisticTime.longitud }}] : []} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
<div>
</div>

      <div style={{display:"flex"}}>
{/* Tiempos logistico */}
      {<>{(dataTime1.estadoslogisticos_id == 1) ?
      (<><div><Button style={{color: "#0696da"}} title="Llegué al sitio de cargue" onClick={()=>time1()}> <img style={{width:40}} src={green1}/> </Button></div></>) :
         <div><Button style={{color:"red"}} title="Llegué al sitio de cargue">  <img style={{width:40}} src={estado1}/> </Button></div>}
      </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime2.estadoslogisticos_id == 2) ?
     (<><div><Button style={{color:"#0696da"}} title="Inicié cargue" onClick={()=>time2()}><img style={{width:40}} src={green2}/></Button></div></>) :
      <div><Button style={{color:"gray"}} title="Inicié cargue"><img style={{width:40}} src={estado2}/></Button></div>}
      </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime3.estadoslogisticos_id == 3) ?
       (<><div><Button style={{color:"#0696da"}} title="Finalicé cargue" onClick={()=>time3()}><img style={{width:40}} src={green3}/></Button></div></>) :
       <div><Button style={{color:"gray"}} title="Finalicé cargue" ><img style={{width:40}} src={estado3}/></Button></div>}
       </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime4.estadoslogisticos_id == 4) ?
       (<><div><Button style={{color:"#0696da"}} title="Inicié Ruta" onClick={()=>time4()}><img style={{width:40}} src={green4}/></Button></div></>) :
       <div><Button style={{color:"gray"}} title="Inicié Ruta" ><img style={{width:40}} src={estado4}/></Button></div>}
       </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime5.estadoslogisticos_id == 5) ?
      (<><div><Button style={{color:"#0696da"}} title="Llegué al sitio de descargue" onClick={()=>time5()}><img style={{width:40}} src={green5}/></Button></div></>) :
      <div><Button style={{color:"gray"}} title="Llegué al sitio de descargue" ><img style={{width:40}} src={estado5}/></Button></div>}
      </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime6.estadoslogisticos_id == 6) ?
      (<><div><Button style={{color:"#0696da"}} title="Inicié descargue" onClick={()=>time6()}><img style={{width:40}} src={green6}/></Button></div></>) :
      <div><Button style={{color:"gray"}} title="Inicié descargue"><img style={{width:40}} src={estado6}/></Button></div>}
      </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime7.estadoslogisticos_id == 7) ?
       (<><div><Button style={{color:"#0696da"}}  title="Finalizar viaje" onClick={()=>time7()}><img style={{width:40}} src={green7}/></Button></div></>) :
       <div><Button style={{color:"gray"}}  title="Finalizar viaje" ><img style={{width:40}} src={estado7}/></Button></div>}
       </>}
      <div style={{marginLeft:0}}></div>
      {<>{(dataTime8.estadoslogisticos_id == 9) ?
        (<><div><Button style={{color:"#0696da"}} title="Cumplido" onClick={()=>time8()}><img style={{width:40}} src={green8}/></Button></div></>) :
        <div><Button style={{color:"gray"}} title="Cumplido"><img style={{width:40}} src={estado8}/></Button></div>}
        </>}
        </div>

      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <div style={{display:"flex"}}>
        NO HAY FOTOS DISPONIBLES<DescriptionIcon/>
 
        </div> */}
           <input type="file" fullWidth variant="contained" id='13' referencia='1-2' name="files" onChange={(e) => subirArchivos(e.target.files)} sx={{ background:"white", color:"#FF5733",}}/>
        
      <ImageList sx={{ width: 470, height: 300 }}>
      {dataPhoto.map((item) => (
        <ImageListItem key={item.link}>
            {
                    (item.link)?
                    <>
                    
   {(item.ext == "pdf")? <>

                    <img src={`${PDF_ICON}`}
                    srcSet={`${PDF_ICON}`}  onClick={() => openInNewTab(`${item.link}`)}
                    loading="lazy"style={{width: 200,height: 200,}}/>
                    
                    <ImageListItemBar
                    title={item.nombre}
            // subtitle={<span>by: {item.nombre}</span>}
                    position="center"/>

            </> : <>
                    <img src={`${item.link}`}
                    srcSet={`${item.link}`}  onClick={() => openInNewTab(`${item.link}`)}
                    loading="lazy"style={{width: 200,height: 200,}}/> 
                    <ImageListItemBar
                    title={"Imagén"}
                    // subtitle={<span>by: {item.nombre}</span>}
                    position="center"
            /></>}

                </> 
                    : <>    
                     <img src='http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png' style={{width: 200,height: 200,}}/>
                    <ImageListItemBar
                    title={"No hay imagén"}
                    // subtitle={<span>by: {item.nombre}</span>}
                    position="below"/>

</>
                    }                 
        </ImageListItem>
      ))}
    </ImageList>
      </TabPanel>
    </Box>
             </div>
          </Grid>
        </Grid>
            </Container>
            </div>
            </Box>
            </Modal>


 <Grid item xs={12} md={8} lg={29}> 
                <Paper 
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height:"auto",
                    }}
                  >
{/* Boton de refrescar */}
<div>
<Button type="submit" fullWidth variant="contained"  onClick={() => refresh()}
    sx={{ mt: 1, mb: 1, float:"right", background:"#FF5733", margin: 2, width:0 }}><RefreshIcon/>
  </Button>
<div style={{marginTop:-5}}></div>
{/* 
<div style={{margin:8,float:'right'}}>

                                <TextField id="filled-basic" label="Buscar" variant="filled"  name="filtro" onChange={ev=>setFilter(ev.target.value)}/>
                            </div> */}
{/* Tabla de contenido */}
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>        
          <TableCell align="center">Conductor</TableCell>
            <TableCell align="center">Turno</TableCell>
            <TableCell align="center">Oferta</TableCell>
            <TableCell align="center">Estado</TableCell>
            <TableCell align="center">Cédula</TableCell>
            <TableCell align="center">Nombre Completo</TableCell>
            <TableCell align="center">Teléfono</TableCell>
            <TableCell align="center">Placa</TableCell>
            <TableCell align="center">Tipo Vehiculo</TableCell>
            <TableCell align="center">Viajes</TableCell>
            <TableCell align="center">Ultima actualizacion</TableCell>
            <TableCell align="center">Verificado</TableCell>
            {/* <TableCell align="center">Fidelizado  </TableCell> */}
            {/* <TableCell align="center">Fidelizado</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,id) => (
            <TableRow onClick={()=>loadProfileDriver(row)}
              key={row.nombres}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            
           <TableCell align="center">
                {row.tipo == "1acepto" ?(<><div style={{background:"#0885c8", borderRadius:4, color:"white",width:160}}>{"Aceptó Condiciones"}</div></>): 
              (row.tipo == "2oferto" ? (<><div style={{background:"orange", borderRadius:4, color:"white",width:160}}>{"Quiero Negociar"}</div></>) :  
               row.tipo == "3ruta" ?  (<><div style={{background:"green", borderRadius:4, color:"white",width:160}}>{"Disponible en ruta"}</div></>) : 
               null )}
                <Tooltip title={generalTooltipText} arrow>
                  <div>{id}</div>
                </Tooltip>
          </TableCell>
        <TableCell align="center">{id+1}</TableCell>
        {/* <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{<>{row.localidad_origen}{", "}{row.departamento_origen}</>}</TableCell>
              <TableCell align="center">{<>{row.localidad_destino}{", "}{row.departamento_destino}</>}</TableCell>
              <TableCell align="center"><>{"$"}{row.costo}</></TableCell>
              <TableCell align="center">{row.fecha_montado}</TableCell> */}
              <TableCell align="center">{<>{"$"}{row.oferta}</>}</TableCell>
              <TableCell align="center">{<>
                {row.estado == "1" ?(<><div style={{background:"#ffa000", borderRadius:4, color:"white",width:100}}>{"Por Evaluar"}</div></>): 
              (row.estado == "2" ? (<><div style={{background:"#2196f4", borderRadius:4, color:"white",width:100}}>{"Contratado"}</div></>) :  
               row.estado == "3" ?  (<><div style={{background:"#2fbb49", borderRadius:4, color:"white",width:100}}>{"Confirmado"}</div></>) : 
               row.estado == "4" ?  (<><div style={{background:"#ff0000", borderRadius:4, color:"white",width:100}}>{"Descartado"}</div></>): 
               row.estado == "0" ?  (<><div style={{background:"#ff0000", borderRadius:4, color:"white",width:100}}>{"Descartado"}</div></>): 
               (<><div style={{background:"gray", borderRadius:4, color:"white",width:100}}>{"Perfil no visto"}</div></>))}
             </>}</TableCell>
              {/* <TableCell align="center">{<>{(row.interesados == 0) ? (<>{"No hay interesados"}</>) : 
              (<Button style={{color:"red"}} onClick={()=>loadProfileDriver(row)}> <><div style={{color:"#0885c8"}}>{"Ver "}{row.interesados}{" interesado"}</div></></Button>)}</>}</TableCell>  */}
               {/* ()}</>}</TableCell> */}
               <TableCell align="center">{row.cedula}</TableCell>
               <TableCell align="center">{row.nombre}</TableCell>
               <TableCell align="center">{row.celular}</TableCell>
               <TableCell align="center">{row.placa}</TableCell>
               <TableCell align="center">{row.tipovehiculo}</TableCell>
               <TableCell align="center">{row.viajes_realizados}</TableCell>
               <TableCell align="center">{row.updated_at}</TableCell>
               <TableCell align="center"> {<>
                  {(row.verificado == 0) ?
                    (
                      <>
                  <Button style={{color: "gray"}} onClick={() => null}><CheckCircleIcon/></Button>
                    </> ) :
                    (
                    <>
                    <Button style={{color: "green"}} onClick={() => null}><CheckCircleIcon/></Button> 
                    
                    </>
                    ) } </>}
              </TableCell>
               {/* <TableCell align="center">{row.verificado }</TableCell> */}
              {/* <TableCell align="center"><Button style={{color:"red"}} onClick={()=>loadProfileDriver(row)}><CancelIcon/>   
               </Button>
               </TableCell>
               */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</div>
                  </Paper>
                  </Grid>

</Dashboard>
 }
  </LoadScript>
  </>);
}
export default AvailablePage;