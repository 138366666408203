import React, { useState, useEffect } from 'react';
import { Stack, Divider, Grid, TextField, Autocomplete, MenuItem, FormControl, InputLabel, Select, Button, Snackbar, Alert } from '@mui/material';
import API, { AXIOS } from '../../environment/api';
import LoadingScreen from '../LoadingScreen';

export default function CurriculumPersonal({ func_update, ...props }) {
    const [loading, set_Loading] = useState(true);
    const [blockButton, set_blockButton] = useState(false);
    const [dataTypeDNI, array_typeDNI] = useState([]);
    const [departaments, setDepartaments] = useState([]);
    const [cities, setCities] = useState([]);
    const [citiesPersona, setCitiespersona] = useState([]);
    const [localidades, setLocaLidades] = useState([]);
    const [localidadesPersona, setLocaLidadesPersona] = useState([]);
    const [dataLicenseCategory, array_licenseCategory] = useState([]);
    const [dataGPSCompany, array_GPSCompany] = useState([]);
    const [dataEPS, array_EPS] = useState([]);
    const [dataARL, array_ARL] = useState([]);
    const [dataAFP, array_AFP] = useState([]);
    const [dataBank, array_Bank] = useState([]);
    const [dataUserSAP, array_UserSAP] = useState([]);
    const [hv_categLicencia_id, set_hv_categLicencia_id] = useState('');
    const [hv_tipCuentBancaria_id, set_hv_tipCuentBancaria_id] = useState('');
    const [hv_empresaGPS, set_hv_empresaGPS] = useState({});
    const [hv_cuentPropia, set_hv_cuentaPropia] = useState();
    const [hv_titular_cuenta, set_hv_titular_cuenta] = useState();
    const [hv_tipoidentificaciontitular_id, set_hv_tipoidentificaciontitular_id] = useState();
    const [hv_identificaciontitular, set_hv_identificaciontitular] = useState();
    const [severity, setSeverity] = useState("error");
    const [openSnackbar, setOpeSnackbar] = useState(false);
    const [messageResponse, setMessageResponse] = useState("");
    const maxDate = new Date().toISOString().split('T')[0];

    const handleSubmit = async (event) => {
        set_blockButton(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        AXIOS.post(API.POST_UPDATE_HOJA_VIDA + Number(props.data.data.persona_id), {
            "U_NoIdentificacion": Number(data.get('dni')),
            "U_Direccion": data.get('direccion'),                
            "U_Celular": Number(data.get('celular')),
            "U_email": data.get('email'),
            "U_fechaNacimiento": data.get('fecha_nacimiento'),
            "U_TipoConductor": data.get('tipoconductor_id'),
            "U_NoLicenciaConducci": Number(data.get('licencianumero')),
            "U_CategoriaLicencia": data.get('licencia_id'),
            "U_FechaVencLicencia": data.get('licenciavence'),
            "U_FechaActualizacion": data.get('fecha_actualizacion'),
            "U_UsuarioCreacion": data.get('userSAP'),
            "U_UsuarioActualizacion": data.get('usuario_actualizacion'),
            "U_GPSuser": data.get('usuario_gps'),
            "U_GPSpass": data.get('contraseña_gps'),
            "conductor_vehiculo": Number(props.data.data.tipoconductor_id),
            "U_Placa": props.data.data.placa,
            // LOCALIDAD
            "departamento_expedicion_documento": data.get('departamentidentification_id'),
            "ciudad_expedicion_documento": data.get('cityidentification_id'),
            "lugar_expedicion_documento": data.get('lugar_expedicion_documento'),
            "departamento_residencia_codigo": data.get('departament_id'),
            "ciudad_residencia_codigo": data.get('citypersona_id'),
            "lugar_residencia_codigo": data.get('localidad_residencia'),
            //EPS & ARL & AFP
            "U_EPS": data.get('eps_id'),
            "U_FechaEPS": data.get('fecha_afiliacion'),
            "U_ARL": data.get('arl_id'),
            "U_AFP": data.get('afp_id'),
            "U_FechaPago": data.get('fecha_pago'),
            "U_EmpresaGPS": data.get('empresasatelital'),
            //DATOS BANCARIOS
            "U_NoCuentaBancaria": Number(data.get('cuenta')),
            "U_TipoCuenta": Number(data.get('tipo_cuenta')),
            "U_Banco": data.get('bankcode'),
            "U_CuentaPropia": hv_cuentPropia,
            "U_Titular": hv_titular_cuenta,
            "U_TipoIdentTitular": hv_tipoidentificaciontitular_id,
            "U_NoIdentTitular": hv_identificaciontitular,
            //REFERENCIAS
            "U_ref_nombres": data.get('referencia_nombres'),
            "U_ref_apellidos": data.get('referencia_apellidos'),
            "U_ref_celular": Number(data.get('referencia_celular')),
            // EXTRAS estaticos que se deben enviar en la primera vercion de la aplicacion
            "U_TipoPersona": "Conductor",
            "U_Activo": 1,
            "U_CardCode": "FFF",
            "U_Clase": 1,
            "U_CodRNDC": 21,
            "U_CrearComoAF": "NO",
            "U_CrearComoSN": "NO",
            "U_Estado": "Activo",
            "U_FechaExpRUNT": "2020-01-01",
            "U_FechaIngreso": "2021-10-10",
            "U_NumeroRUNT": 1031864,
            "U_RT_CDA": "01",
            "U_RT_NoCertificado": 149643562,
            "U_RadicadoRNDC": "SI",
            "U_RadicarRNDC": "SI",
            "U_Repontenciado": 1234,
            "U_SegundoApellido": "",
            "U_TipoSemiRemolque": 1,
            "U_TransportadorAsoci": "PT80755707",
        }).then((response) => {
            func_update('put_datos_personales', true); // Notificamos a CurriculumPageNew que se han llenado todos los datos
            setMessageResponse("Los datos personales se han actualizado correctamente")
            setSeverity("success");
            setOpeSnackbar(true);
            set_blockButton(false); // Update blockButton state after successful request
        }).catch((error) => {
            setMessageResponse("Error al actualizar los datos personales");
            setSeverity("error");
            setOpeSnackbar(true);
            set_blockButton(false); // Update blockButton state after error
        })
    };

    const handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpeSnackbar(false);
    };

    const handlerDepartamentChange = (event) => {
        let id = event.target.value;
        getListCities(id);
    }

    const handlerDepartamentPersonaChange = (event) => {
        let id = event.target.value;
        getListCitiesPersona(id);
    }

    const handleLocalidadPersonaChange = (event) => {
        let id = event.target.value;
        getListLocalidadesPersona(id);
    }

    const handlerCitiesChange = (event) => {
        let id = event.target.value;
        getListLocalidades(id);
    }

    const getListCities = (id) => {
        // Obtener listado de ciudades                
        if (id) {
            AXIOS.get(API.GET_CITIES(id), {
            }).then((response) => {
                setCities(Array.isArray(response.data) ? response.data : []);
            });
        }
    }

    const getListCitiesPersona = (id) => {
        // Obtener listado de ciudades        
        if (id) {
            AXIOS.get(API.GET_CITIES(id), {
            }).then((response) => {
                setCitiespersona(Array.isArray(response.data) ? response.data : []);
            });
        }
    }

    const getListLocalidades = (id) => {
        // Obtener listado de localidades        
        if (id) {            
            AXIOS.get(API.GET_LOCALIDADES_IDENTIFICACION(id), {
            }).then((response) => {
                setLocaLidades(Array.isArray(response.data) ? response.data : []);
            });
        }
    }

    const getListLocalidadesPersona = (id) => {
        // Obtener listado de localidades        
        if (id) {
            AXIOS.get(API.GET_LOCALIDADES_IDENTIFICACION(id), {
            }).then((response) => {                
                setLocaLidadesPersona(Array.isArray(response.data) ? response.data : []);
            });
        }
    }

    useEffect(() => {
        const fetchData = async (props) => {
            if (props.data.data.tipoidentificacion_id === "1") {
                set_hv_cuentaPropia("SI")
                set_hv_titular_cuenta(props.data.data.nombres + " " + props.data.data.apellidos ? props.data.data.nombres + " " + props.data.data.apellidos : "");
                set_hv_tipoidentificaciontitular_id(props.data.data.tipoidentificacion_id ? props.data.data.tipoidentificacion_id : "");
                set_hv_identificaciontitular(props.data.data.identificacion ? props.data.data.identificacion : "");
            } else {
                set_hv_cuentaPropia("NO")
                set_hv_titular_cuenta(props.data.data.nombre_titular ? props.data.data.nombre_titular : "");
                set_hv_tipoidentificaciontitular_id(props.data.data.tipoidentificaciontitular_id ? props.data.data.tipoidentificaciontitular_id : "");
                set_hv_identificaciontitular(props.data.data.identificacion_titular ? props.data.data.identificacion_titular : "");
            }
            set_hv_tipCuentBancaria_id(props.data.data.tipo_cuenta);
            set_hv_categLicencia_id(props.data.data.licencia);
            /// SOLICITUD DE GPS
            if (props.data.vehiculo_disponible[0] === undefined) {
                AXIOS.get(API.GET_GPS + (props.data.data.vehiculo_id), {
                }).then((response) => {
                    set_hv_empresaGPS(response.data.data ? response.data.data : '');
                })
            } else {
                AXIOS.get(API.GET_GPS + (props.data.vehiculo_disponible[0].vehiculo_id), {
                }).then((gps_data) => {
                    set_hv_empresaGPS(gps_data.data.data);
                }
                );
            }
            if (props.data.data.departamento_expedicion_documento) {
                getListCities(props.data.data.departamento_expedicion_documento);
            }
            if (props.data.data.departamento_residencia_codigo) {
                getListCitiesPersona(props.data.data.departamento_residencia_codigo);
            }
            if (props.data.data.lugar_expedicion_documento) {
                getListLocalidades(props.data.data.ciudad_expedicion_documento);                
            }
            if (props.data.data.ciudad_residencia_codigo) {
                getListLocalidadesPersona(props.data.data.ciudad_residencia_codigo);                
            }
            //Lista tipo documento
            AXIOS.get(API.TIPO_DOCUMENTO, {
            }).then((response) => {
                array_typeDNI(Array.isArray(response.data.data) ? response.data.data : []);
            })
            //Lista cat. Licencia
            AXIOS.get(API.CATEGORIA_LICENCIA, {
            }).then((response) => {
                array_licenseCategory(Array.isArray(response.data) ? response.data : []);
            })
            //Lista empresa GPS
            AXIOS.get(API.EMPRESA_GPS, {
            }).then((response) => {
                array_GPSCompany(Array.isArray(response.data) ? response.data : []);
            })
            //Lista empresa EPS
            AXIOS.get(API.GET_EPS, {
            }).then((response) => {
                array_EPS(Array.isArray(response.data) ? response.data : []);
            })
            //Lista empresa ARL
            AXIOS.get(API.GET_ARL, {
            }).then((response) => {
                array_ARL(Array.isArray(response.data) ? response.data : []);
            })
            //Lista empresa AFP
            AXIOS.get(API.GET_AFP, {
            }).then((response) => {
                array_AFP(Array.isArray(response.data) ? response.data : []);
            });
            //Lista banco
            AXIOS.get(API.GET_BANCO, {
            }).then((response) => {
                array_Bank(Array.isArray(response.data) ? response.data : []);
            });
            //Lista usuario SAP
            AXIOS.get(API.GET_USER_SAP, {
            }).then((response) => {
                array_UserSAP(Array.isArray(response.data) ? response.data : []);
            }).finally(() => {
                set_Loading(false);
            });

            // Obtener listado de departamentos
            AXIOS.get(API.GET_DEPARTAMENTS, {
            }).then((response) => {
                setDepartaments(Array.isArray(response.data) ? response.data : []);
            });
        };
        fetchData(props);
    }, []);

    return (
        <>
            {loading ?
                <LoadingScreen />
                : (
                    <>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
                            <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
                                {messageResponse}
                            </Alert>
                        </Snackbar>
                        <Grid component={"form"} onSubmit={handleSubmit}>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 5, display: "center" }}>
                                <h4>DATOS BASICOS</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} variant="standard" id="standard-basic" name="nombres" label="Nombres" defaultValue={props.data.data.nombres} />
                                <TextField disabled inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} variant="standard" id="standard-basic" name="apellidos" label="Apellidos" defaultValue={props.data.data.apellidos} />
                            </Stack>
                            {/* !AQUIII EDITA JOHAN */}
                            <fieldset style={{ marginTop: 50, paddingBottom: 20 }}>
                                <legend><h4>Datos del documento de identificación</h4></legend>
                                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 5 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona el departamento</InputLabel>
                                        <Select                                        
                                            defaultValue={props.data.data.departamento_expedicion_documento ? props.data.data.departamento_expedicion_documento : ""}
                                            labelId="demo-simple-select-filled-label"
                                            name="departamentidentification_id"
                                            id="departamentidentification_id"
                                            required
                                            onChange={handlerDepartamentChange}
                                        >
                                            {
                                                departaments.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona la ciudad</InputLabel>
                                        <Select
                                            defaultValue={props.data.data.ciudad_expedicion_documento ? props.data.data.ciudad_expedicion_documento : ''}
                                            disabled={cities.length === 0}
                                            labelId="demo-simple-select-filled-label"
                                            name="cityidentification_id"
                                            id="cityidentification_id"
                                            required
                                            onChange={handlerCitiesChange}
                                        >
                                            {
                                                cities.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona la localidad</InputLabel>
                                        <Select
                                            defaultValue={props.data.data.lugar_expedicion_documento ? props.data.data.lugar_expedicion_documento : ''}
                                            disabled={localidades.length === 0}
                                            labelId="demo-simple-select-filled-label"
                                            name="lugar_expedicion_documento"
                                            id="lugar_expedicion_documento"
                                            required
                                        >
                                            {
                                                localidades.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                
                                </Stack>
                                <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                    <FormControl variant="standard" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Tipo Documento Identificación</InputLabel>
                                        <Select
                                            disabled
                                            defaultValue={props.data.data.tipoidentificacion_id ? props.data.data.tipoidentificacion_id : ""}
                                            labelId="demo-simple-select-filled-label"
                                            name="tipoidentificacion_id"
                                            id="tipoidentificacion_id"
                                            required
                                        >
                                            {
                                                dataTypeDNI.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField disabled type="number" sx={{ marginLeft: 2, width: '100%' }} required variant="standard" id="standard-basic" name="dni" label="N° Documento" defaultValue={props.data.data.identificacion} />
                                    <TextField disabled sx={{ marginLeft: 2, width: '100%' }} variant="standard" id="standard-basic" name="fecha_expedicion_documento" type="date" defaultValue={props.data.data.fecha_expedicion_documento} label="Fecha Expedición Indentificación" />
                                </Stack>
                            </fieldset>
                            {/* FINN AQUIII EDITA JOHAN */}
                            <fieldset style={{ marginTop: 50, paddingBottom: 20 }}>
                                <legend><h4>Datos de residencia</h4></legend>
                                <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona el departamento</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            name="departament_id"
                                            id="departament_id"
                                            defaultValue={props.data.data.departamento_residencia_codigo ? props.data.data.departamento_residencia_codigo : ""}
                                            onChange={handlerDepartamentPersonaChange}
                                            required
                                        >
                                            {
                                                departaments.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona la ciudad</InputLabel>
                                        <Select
                                            disabled={citiesPersona.length === 0}
                                            labelId="demo-simple-select-filled-label"
                                            name="citypersona_id"
                                            id="citypersona_id"
                                            defaultValue={props.data.data.ciudad_residencia_codigo ? props.data.data.ciudad_residencia_codigo : ""}
                                            onChange={handleLocalidadPersonaChange}
                                            required
                                        >
                                            {
                                                citiesPersona.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Selecciona la localidad</InputLabel>
                                        <Select
                                            disabled={localidadesPersona.length === 0}
                                            labelId="demo-simple-select-filled-label"
                                            name="localidad_residencia"
                                            id="localidad_residencia"
                                            required
                                            defaultValue={props.data.data.lugar_residencia ? props.data.data.lugar_residencia : ""}
                                        >
                                            {
                                                localidadesPersona.map(item => {
                                                    return (
                                                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>                                    
                                </Stack>
                            </fieldset>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%'}} required variant="filled" id="standard-basic" name="celular" label="Celular" defaultValue={props.data.data.celular} />
                                <TextField inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="email" label="Correo" defaultValue={props.data.data.email} />
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required type="date" variant="filled" id="standard-basic" name="fecha_nacimiento" label="Fecha nacimiento" focused defaultValue={props.data.data.fecha_nacimiento} inputProps={{ max: maxDate }} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo Conductor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="tipoconductor_id"
                                        name="tipoconductor_id"
                                        required
                                        defaultValue={props.data.data.tipoconductor_codigo ? props.data.data.tipoconductor_codigo : ""}
                                    >
                                        <MenuItem value={"001"}>INTERNO</MenuItem>
                                        <MenuItem value={"002"}>EXTERNO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="licencianumero" label="Licencia conducción" defaultValue={props.data.data.licencianumero} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Categoria licencia</InputLabel>
                                    <Select
                                        required
                                        defaultValue={hv_categLicencia_id ? hv_categLicencia_id : ""}
                                        labelId="demo-simple-select-filled-label"
                                        id="licencia_id"
                                        name="licencia_id"
                                    >
                                        {
                                            dataLicenseCategory && dataLicenseCategory.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required type="date" variant="filled" id="standard-basic" focused name="licenciavence" label="Fecha vencimiento linc." defaultValue={props.data.data.licenciavence} />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} variant="standard" id="standard-basic" name="fecha_creacion" type="date" defaultValue={props.data.data.created.split(" ")[0]} label="Fecha creación de usuario" />
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} variant="standard" id="standard-basic" name="fecha_actualizacion" type="date" defaultValue={props.data.data.fecha_actualizacion.split(" ")[0]} label="Fecha actualización usuario" />
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} inputProps={{ readOnly: true }} variant="standard" id="standard-basic" name="usuario_creacion" label="Usuario creación" defaultValue={props.data.data.usuario_creacion} />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField disabled sx={{ marginLeft: 2, width: '100%' }} inputProps={{ readOnly: true }} variant="standard" id="standard-basic" name="usuario_actualizacion" label="Usuario actualizacion" defaultValue={props.data.data.usuario_actualizacion} />
                                <Autocomplete
                                    required
                                    disablePortal
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    id="empresasatelital"
                                    name="empresasatelital"
                                    defaultValue={hv_empresaGPS.empresasatelital}
                                    options={dataGPSCompany.map((option) => option.Name)}
                                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                    renderInput={(params) => <TextField {...params} label="Empresa GPS" name="empresasatelital" required variant="filled"/>}
                                />
                                <Autocomplete
                                    required
                                    disablePortal
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    id="userSAP"
                                    name="userSAP"
                                    defaultValue={props.data.data.user_creation_sap ? props.data.data.user_creation_sap : ""}
                                    options={dataUserSAP.map((option) => option.USER_CODE)}
                                    sx={{ marginLeft: 2, width: '100%', marginTop: -1 }}
                                    renderInput={(params) => <TextField {...params} label="Usuario SAP" name="userSAP" required variant="filled"/>}
                                />
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="usuario_gps" label="Usuario GPS" defaultValue={hv_empresaGPS.satelitaluser} />
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="contraseña_gps" label="Contraseña GPS" defaultValue={hv_empresaGPS.satelitalpass} />
                            </Stack>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 25, display: "center" }}>
                                <h4>EPS & ARL & AFP</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">EPS</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.epscodigo ? props.data.data.epscodigo : ""}
                                        labelId="demo-simple-select-filled-label"
                                        id="eps_id"
                                        name="eps_id"
                                    >
                                        {
                                            dataEPS.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required focused type="date" variant="filled" id="standard-basic" name="fecha_afiliacion" label="Fecha afiliación EPS" defaultValue={props.data.data.fecha_afiliacion} inputProps={{ max: maxDate }} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">ARL</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.arlcodigo ? props.data.data.arlcodigo : ""}
                                        labelId="demo-simple-select-filled-label"
                                        id="arl_id"
                                        name="arl_id"
                                    >
                                        {
                                            dataARL.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">AFP</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.afpcodigo ? props.data.data.afpcodigo : ""}
                                        labelId="demo-simple-select-filled-label"
                                        id="afp_id"
                                        name="afp_id"
                                    >
                                        {
                                            dataAFP.map(item => {
                                                return (
                                                    <MenuItem value={item.Code}>{item.Name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <TextField sx={{ marginLeft: 2, width: '100%' }} required focused type="date" variant="filled" id="standard-basic" name="fecha_pago" label="Fecha ultimo pago seguridad social" defaultValue={props.data.data.fecha_pago} inputProps={{ max: maxDate }} />
                            </Stack>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 25, display: "center" }}>
                                <h4>DATOS BANCARIOS</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="cuenta" label="N° cuenta bancaria" defaultValue={props.data.data.cuenta} />
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo cuenta bancaria</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-filled-label"
                                        id="tipo_cuenta"
                                        name="tipo_cuenta"
                                        defaultValue={hv_tipCuentBancaria_id ? hv_tipCuentBancaria_id : ""}
                                    >
                                        <MenuItem value={1}>AHORRO</MenuItem>
                                        <MenuItem value={2}>CORRIENTE</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Banco</InputLabel>
                                    <Select
                                        required
                                        defaultValue={props.data.data.bankcode ? props.data.data.bankcode : ""}
                                        labelId="demo-simple-select-filled-label"
                                        id="bankcode"
                                        name="bankcode"
                                    >
                                        {
                                            dataBank.map(item => {
                                                return (
                                                    <MenuItem value={item.BankCode}>{item.BankName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <FormControl variant="filled" sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">¿Es una cuenta propia?</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-filled-label"
                                        id="cuenta_propia"
                                        name="cuenta_propia"
                                        defaultValue={props.data.data.cuenta_propia ? props.data.data.cuenta_propia : ""}
                                        onChange={(event) => {
                                            set_hv_cuentaPropia(event.target.value)
                                            if (event.target.value === "SI") {
                                                set_hv_titular_cuenta(props.data.data.nombres + " " + props.data.data.apellidos);
                                                set_hv_tipoidentificaciontitular_id(props.data.data.tipoidentificacion_id);
                                                set_hv_identificaciontitular(props.data.data.identificacion);
                                            } else {
                                                set_hv_titular_cuenta("");
                                                set_hv_tipoidentificaciontitular_id("");
                                                set_hv_identificaciontitular("");
                                            }
                                        }}
                                    >
                                        <MenuItem value={"SI"}>SI</MenuItem>
                                        <MenuItem value={"NO"}>NO</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField 
                                    disabled={hv_cuentPropia === "SI"}
                                    variant={hv_cuentPropia === "SI" ? "standard" : "filled"}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    sx={{ marginLeft: 2, width: '100%' }}
                                    required
                                    id="standard-basic"
                                    name="nombre_titular"
                                    label="Titular Cuenta" 
                                    value={hv_titular_cuenta}
                                    onChange={(event) => { set_hv_titular_cuenta(event.target.value) }} />
                                <FormControl variant={hv_cuentPropia === "SI" ? "standard" : "filled"} sx={{ m: -1, marginLeft: 2, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo identificación titular </InputLabel>
                                    <Select
                                        required
                                        disabled={hv_cuentPropia === "SI"}
                                        value={hv_tipoidentificaciontitular_id}
                                        labelId="demo-simple-select-filled-label"
                                        id="tipoidentificaciontitular_id"
                                        name="tipoidentificaciontitular_id"
                                        onChange={(event) => { set_hv_tipoidentificaciontitular_id(event.target.value) }}
                                    >
                                        {
                                            dataTypeDNI.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack justifyContent="space-between" style={{ display: "center", marginTop: 50 }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField 
                                    disabled={hv_cuentPropia === "SI"} 
                                    variant={hv_cuentPropia === "SI" ? "standard" : "filled"}
                                    type="number" 
                                    inputProps={{ style: { textTransform: "uppercase" } }} 
                                    sx={{ marginLeft: 2, width: '33%' }} 
                                    required
                                    id="standard-basic" 
                                    name="identificacion_titular" 
                                    label="N° indetificación titular"
                                    value={hv_identificaciontitular} 
                                    onChange={(event) => { set_hv_identificaciontitular(event.target.value) }} />
                            </Stack>
                            <Grid container
                                spacing={0}
                                justifyContent="center"
                                style={{ marginTop: 25, display: "center" }}>
                                <h4>REFERENCIAS</h4>
                            </Grid>
                            <Stack justifyContent="space-between" style={{ display: "center" }} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={6}>
                                <TextField inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="referencia_nombres" label="Nombres" defaultValue={props.data.data.referencia_nombres} />
                                <TextField inputProps={{ style: { textTransform: "uppercase" } }} sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="referencia_apellidos" label="Apellidos" defaultValue={props.data.data.referencia_apellidos} />
                                <TextField type="number" sx={{ marginLeft: 2, width: '100%' }} required variant="filled" id="standard-basic" name="referencia_celular" label="Celular" defaultValue={props.data.data.referencia_celular} />
                            </Stack>
                            <Grid container
                                spacing={1}
                                justifyContent="flex-end"
                                style={{ marginTop: 30, display: "center" }}>
                                {
                                    (props.permiso !== 3 || props.rolSession === 13) ?
                                        <Button variant="contained" sx={{ borderRadius: 0, background: "#3498db", padding: "12" }} disabled={blockButton} type="submit">
                                            GUARDAR CAMBIOS
                                        </Button>
                                        : <></>
                                }                            
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </>
    );
}