import * as React from "react";
import API, { AXIOS } from "../environment/api";
import LoadingScreen from "./LoadingScreen";
import Dashboard from "../components/Dashboard";
import LoadingModal from "../components/LoadingModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  FormControl,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  Snackbar,
  Grid,
  Stack,
  Button,
  Typography,
  Box,
  Tab,
  Paper,
  Tabs,
  Modal,
} from "@mui/material";

export default function AttachmentManager() {
    const [open_toast, setOpen_toast] = React.useState(false);
    const [open_toast_red, setOpen_toast_red] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [attachmentManager, setAttachmentManager] = React.useState([]);
    const [title, setTitle] = React.useState("Documentos del Conductor");
    const [documentoVence, setDocumentoVence] = React.useState(0);
    const [documentoVenceUpdate, setDocumentoVenceUpdate] = React.useState(0);
    const [idUpdate, setIdUpdate] = React.useState(0);
    const [idDelate, setIdDelate] = React.useState({});
    const [Loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [open_toast_messages, setOpen_toast_messages] = React.useState("");
    const [modalUpdate, setModalUpdate] = React.useState(false);
    const [modalCreate, setModalCreate] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const styles = {
        box: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        borderRadius: 4,
        boxShadow: 24,
        p: 1,
        },
        modalUpdate: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,
        },
        stackButton: {
        display: "center",
        margin: 10,
        },
        ButtonCreate: {
        background: "green",
        width: "auto",
        margin: 2,
        },
        divTable: {
        display: "flex",
        float: "letf",
        marginTop: 5,
        },
        tableContainer: {
        margin: 20,
        overflowX: "auto",
        },
    };

    React.useEffect(() => {
        fetchData()
        .finally(() =>
            setLoading(false)
        );
    }, []);

    //Funcion para cerrar el snackbar
    const handleSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen_toast(false);
        setOpen_toast_red(false);
    };

    //Funcion para obtener los datos de los anexos
    const fetchData = async () => {
        const resp_row = await AXIOS.get(API.GET_ROW_DOCUMENTS_ADMIN(4));
        setAttachmentManager(resp_row.data.data);
        if (value === 0) {
            setRow(resp_row.data.data.persona);
        } else if (value === 1) {
            setRow(resp_row.data.data.vehiculo);
        } else if (value === 2) {
            setRow(resp_row.data.data.trailer);
        }
    };

    //Funcion para cambiar de pestaña
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setTitle("Documentos del Conductor");
            setRow(attachmentManager.persona);
        } else if (newValue === 1) {
            setTitle("Documentos del Vehiculo");
            setRow(attachmentManager.vehiculo);
        } else if (newValue === 2) {
            setTitle("Documentos del Trailer");
            setRow(attachmentManager.trailer);
        }
    };

    const ViewCase = (currentView) => {
        if (currentView === 0) {
            return 2;
        } else if (currentView === 1) {
            return 1;
        } else if (currentView === 2) {
            return 3;
        }
    };

    //Funcion para preeliminar un anexo
    const handlePredelete = (row) => {
        setIdDelate({
        id: row.id,
        text: row.habilitar ? "inhabilitar" : "habilitar",
        nombre_anexo: row.documento_nombre,
        });;
        setModalDelete(true);
    };

    //Funcion para eliminar un anexo
    const handleDelete = () => {
        setLoad(true);
        let viewcase = ViewCase(value);
        AXIOS.post(API.DELETE_DOCUMENT(idDelate.id, viewcase))
        .then((resp) => {
            if (resp.data.success === true) {
                fetchData();
                setModalDelete(false);
                setOpen_toast_messages(`El anexo se ha ${idDelate.text} con exito`);
                setOpen_toast(true);
                setLoad(false);
            } else {
                setOpen_toast_red(true);
                setLoad(false);
            }
        })
        .catch((error) => {
            setLoad(false);
            setOpen_toast_red(true);
        });
    };

    //Funcion para preActualizar un anexo
    const handlePreupdate = (row) => {
        setIdUpdate({
            id: row.id,
            nombre: row.documento_nombre,
            descripcion: row.descripcion,
            caducidad: row.caducidad,
        });
        setDocumentoVenceUpdate(row.vence);
        setModalUpdate(true);
    };

    //Funcion para actualizar un anexo
    const handleUpdate = () => {
        setLoad(true);
        let viewcase = ViewCase(value);
        AXIOS.put(API.UPDATE_DOCUMENT(idUpdate.id, viewcase), {
            documento_nombre: document.getElementById("nombre").value,
            descripcion: document.getElementById("descripcion").value,
            caducidad: document.getElementById("caducidad").value ? document.getElementById("caducidad").value : 0,
            vence: documentoVenceUpdate,
        })
        .then((resp) => {
            if (resp.data.success === true) {
                fetchData();
                setLoad(false);
                setModalUpdate(false);
                setOpen_toast_messages("Anexo actualizada con exito");
                setOpen_toast(true);
            } else {
                setLoad(false);
                setOpen_toast_red(true);
            }
        })
        .catch((error) => {
            setLoad(false);
            setOpen_toast_red(true);  
        });
    };

    //Funcion para crear un anexo
    const handleCreate = async () => {
        setLoad(true);
        let viewcase = ViewCase(value);
        const resp = await AXIOS.post(API.CREATE_DOCUMENT(viewcase), {
        documento_nombre: document.getElementById("nombre").value,
        descripcion: document.getElementById("descripcion").value,
        caducidad: document.getElementById("caducidad").value
            ? document.getElementById("caducidad").value
            : 0,
        vence: documentoVence ? 1 : 0,
        });
        if (resp.data.success === true) {
        fetchData();
        setLoad(false);
        setModalCreate(false);
        setOpen_toast_messages("Anexo creado con exito");
        setOpen_toast(true);
        } else {
        setLoad(false);
        setOpen_toast_red(true);
        }
    };

  return (
    <>
      {Loading === true ? (
        Loading === "" ? null : (
          <LoadingScreen />
        )
      ) : (
        <Dashboard title={title}>
          {/* Modal de carga */}
          <Modal
            open={load}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <LoadingModal />
          </Modal>
          {/* Modal de update */}
          <Modal open={modalUpdate} onClose={() => setModalUpdate(false)}>
            <Box sx={styles.box}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                marginBottom={2}
              >
                ¿Está seguro de actualizar el anexo?
              </Typography>
              <div style={styles.modalUpdate}>
                <TextField
                  fullWidth
                  required
                  margin="normal"
                  id="nombre"
                  label="Nombre del anexo"
                  name="nombre"
                  autoFocus
                  defaultValue={idUpdate.nombre}
                />
                <TextField
                  fullWidth
                  required
                  margin="normal"
                  id="descripcion"
                  label="Descripción del anexo"
                  name="descripcion"
                  defaultValue={idUpdate.descripcion}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="documento-vence-label">
                    El documento vence
                  </InputLabel>
                  <Select
                    required
                    label="El documento vence"
                    labelId="documento-vence-label"
                    id="documento-vence"
                    name="documento-vence"
                    value={documentoVenceUpdate}
                    onChange={(e) => setDocumentoVenceUpdate(e.target.value)}
                  >
                    <MenuItem value={1}>Sí</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  required
                  disabled={!documentoVenceUpdate}
                  label="Caducidad del anexo (días)"
                  type="number"
                  margin="normal"
                  id="caducidad"
                  name="caducidad"
                  defaultValue={idUpdate.caducidad}
                />
              </div>
              <Stack
                justifyContent="center"
                style={styles.stackButton}
                direction="row"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => handleUpdate()}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Actualizar
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => setModalUpdate(false)}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Cancelar
                </Button>
              </Stack>
            </Box>
          </Modal>
          {/* Modal de crear */}
          <Modal open={modalCreate} onClose={() => setModalCreate(false)}>
            <Box sx={styles.box}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                marginBottom={2}
              >
                ¿Quiere crear un nuevo anexo para {title}?
              </Typography>
              <div style={styles.modalUpdate}>
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="nombre"
                  label="Nombre del anexo"
                  name="nombre"
                  autoFocus
                />
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="descripcion"
                  label="Descripción del anexo"
                  name="descripcion"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="documento-vence-label">
                    El documento vence
                  </InputLabel>
                  <Select
                    required
                    label="El documento vence"
                    labelId="documento-vence-label"
                    id="documento-vence"
                    name="documento-vence"
                    onChange={(e) => setDocumentoVence(e.target.value)}
                  >
                    <MenuItem value={true}>Sí</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  disabled={!documentoVence}
                  required
                  label="Caducidad del anexo (días)"
                  type="number"
                  margin="normal"
                  id="caducidad"
                  name="caducidad"
                  defaultValue="opcional"
                  inputProps={{
                    maxLength: 2,
                    minLength: 2,
                  }}
                />
              </div>
              <Stack
                justifyContent="center"
                style={styles.stackButton}
                direction="row"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => handleCreate()}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Crear
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => setModalCreate(false)}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Cancelar
                </Button>
              </Stack>
            </Box>
          </Modal>
          {/* Modal de habilitar */}
          <Modal open={modalDelete} onClose={() => setModalDelete(false)}>
            <Box sx={styles.box}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                marginBottom={2}
              >
                ¿Quieres {idDelate.text} el anexo {idDelate.nombre_anexo}?
              </Typography>
              <Stack
                justifyContent="center"
                style={styles.stackButton}
                direction="row"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => handleDelete()}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Aceptar
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => setModalDelete(false)}
                  sx={{ background: "#FF5733", width: 300, margin: 1 }}
                >
                  Cancelar
                </Button>
              </Stack>
            </Box>
          </Modal>
          <div>
            {/* Snackbar para mensaje de exito */}
            <Snackbar
              open={open_toast}
              autoHideDuration={6000}
              onClose={handleSnackbar}
            >
              <Alert onClose="" severity="success" sx={{ width: "100%" }}>
                {open_toast_messages}
              </Alert>
            </Snackbar>
            {/* Snackbar para mensaje de cancelado */}
            <Snackbar
              open={open_toast_red}
              autoHideDuration={6000}
              onClose={handleSnackbar}
            >
              <Alert onClose="" severity="error" sx={{ width: "100%" }}>
                Ha ocurrido un error
              </Alert>
            </Snackbar>
          </div>
          {/* tipos de anexos */}
          <Grid item xs={12} md={8} lg={29}>
            <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 3 }}>
              <Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => setModalCreate(true)}
                  sx={styles.ButtonCreate}
                >
                  Nuevo tipo de anexo
                </Button>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                      <Tab
                        label="Doc. del Conductor"
                        id="simple-tab-0"
                        aria-controls="simple-tabpanel-0"
                      />
                      <Tab
                        label="Doc. del Vehiculo"
                        id="simple-tab-1"
                        aria-controls="simple-tabpanel-1"
                      />
                      <Tab
                        label="Doc. del Trailer"
                        id="simple-tab-2"
                        aria-controls="simple-tabpanel-2"
                      />
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
              {/* Tabla de contenido de anexos*/}
              <div style={styles.divTable}>
                <TableContainer component={Paper} style={styles.tableContainer}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Descripción</TableCell>
                        <TableCell align="center">Caducidad</TableCell>
                        <TableCell align="center">Operaciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row ? (
                        row.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="center">
                              {row.documento_nombre}
                            </TableCell>
                            <TableCell align="center">
                              {row.descripcion}
                            </TableCell>
                            {row.vence ? (
                              <TableCell align="center">
                                {row.caducidad} Días
                              </TableCell>
                            ) : (
                              <TableCell align="center">No vence</TableCell>
                            )}
                            <TableCell align="center">
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={() => handlePreupdate(row)}
                                sx={{
                                  background: "#FF5733",
                                  width: 100,
                                  margin: 3,
                                }}
                              >
                                Editar
                              </Button>
                              {row.habilitar ? (
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  onClick={() =>
                                    handlePredelete(row)
                                  }
                                  sx={{ background: "red", width: 100 }}
                                >
                                  inhabilitar
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  onClick={() =>
                                    handlePredelete(row)
                                  }
                                  sx={{ background: "green", width: 100 }}
                                >
                                  habilitar
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center">No hay registros</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          </Grid>
        </Dashboard>
      )}
    </>
  );
}