const validateValue = (value) => {
  return !value ? (typeof value != "number" ? "-" : 0) : value;
};

/**
 * function CopyDataToClipboard
 * @param {Array<string>} headers Headers of the excel
 * @param {Array<any>} array Contain data
 * @param {Array<string> | Array<{
 *  key: string
 *  template : Function
 * }>} attributes keys on data to show
 * @param {Function} callback function to execute after excel is copied to clipboard
 * @example 
 * CopyDataToClipboard(
 *  [
    "Fecha de creación",
    "Ultima conexión",
    "Cédula",
    "Nombre Completo",
    "Celular",
    "Placa",
    "Viajes Realizados",
    "Documentos",
  ],
  [],
  [
    "fecha_creacion",
     "last_visit",
     "identificacion",
     "nombres",
     "celular",
     "placa",
     "viajes_realizados",
     {
       key: "completo",
       template: (value) => {
          return value == 0 ? "Incompleto" : "Completo";
       },
    },
]

 * )
 * 
 */

export const CopyDataToClipboard = (headers, array, attributes, callback) => {
  //Map data to show on excel
  const data = array.map((row) => {
    if (!attributes || (attributes && attributes.length === 0))
      return Object.keys(row)
        .filter((key) => row[key])
        .map((key) => validateValue(row[key]));
    return [
      ...attributes.map((attr) => {
        if (typeof attr == "string") return validateValue(row[attr]);
        if (typeof attr == "object")
          return attr.template
            ? attr.template(row[attr.key])
            : validateValue(row[attr.key]);
      }),
    ];
  });

  const dataToExcel = [headers, ...data];

  let csv = "",
    row,
    cell;

  for (row = 0; row < dataToExcel.length; row++) {
    for (cell = 0; cell < dataToExcel[row].length; cell++) {
      csv += (dataToExcel[row][cell] + "").replace(/[\n\t]+/g, " ");
      if (cell + 1 < dataToExcel[row].length) csv += "\t";
    }
    if (row + 1 < dataToExcel.length) csv += "\n";
  }
  navigator.clipboard.writeText(csv);
  callback();
};
