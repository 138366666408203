import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, Alert, Snackbar } from '@mui/material';
import API, { AXIOS } from '../environment/api';
import imgNotFound from '../assets/img/logo.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LinearProgress from '@mui/material/LinearProgress';

//Estilo de modals
const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridTitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  imgSize: {
    width: 200,
    height: 200,
    padding: 10,
  },
  gridButton: {
    display: "center",
    marginBottom: 10
  },
  gridButtonLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  textErrorDate: {
    background: '#d5d5d5',
    padding: '0.3em',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'darkred'
  }
}

   // Obtener la fecha actual
  const fechaActual = new Date();

export default function WindowModal({func_update_,fun_estado, ...props}) {
  const [open, setOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState(0);
  const [exitSave, setExitSave] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [severity, setSeverity] = useState("error");
  const [openSnackbar, setOpeSnackbar] = useState(false);
  const [messageResponse, setMessageResponse] = useState("");
  const [imgShow, setImgShow] = useState("../assets/img/logo.png");
  const maxDate = new Date().toISOString().split('T')[0];


  useEffect(() => {
    setImgShow(props.image)
  }, [props.image])

  useEffect(() => {
    if(openSnackbar === true){
      setTimeout(() => {
        setOpeSnackbar(false)
      }, 6000);
    }
  }, [openSnackbar])

  useEffect(() => {
    setCalendarValue(props.vencimiento);
  }, [])

  const saveImage = async (type, ref, file) => {
    //inicializacion de vehiculo
    // let placa = localStorage.getItem('placa')
    // let trailer = localStorage.getItem('trailer')
    const form = new FormData();
    form.append('file', file);
    form.append('type', Number(type));
    form.append('calendar', calendarValue);
    form.append('identificacion', props.identity);
    //Dependiendo la ref se sube el tipo de documento    
    if (ref === '1') {
      try {
        await AXIOS.post(API.POST_DOCUMENT_VEHICULO(props.dni), form, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          let result = response.data.links[0];
          setImgShow(result.link)
          if(result.complete === true){
            func_update_('put_doc_vehiculo', true)
          }
          setSeverity("success")
          setMessageResponse("Documento subido correctamente")
          setOpeSnackbar(true)
          fun_estado(result.type)
        })
        setExitSave(false)
      } catch (error) {
        setSeverity("error");
        setMessageResponse("No se ha podido cargar correctamente la imagen, prueba nuevamente");
        setOpeSnackbar(true);
        return;
      }
    }
    else if (ref === '2') {
      AXIOS.post(API.POST_DOCUMENT_CONDUCTOR(props.dni), form, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        let result = response.data.links[0];
        setImgShow(result.link)
        if(result.complete === true){
          func_update_('put_doc_personales', true)
        }
        fun_estado(result.type)
        setSeverity("success")
        setMessageResponse("Documento subido correctamente")
        setOpeSnackbar(true)
        setExitSave(false)
      }).catch((error) => {
        setSeverity("error")
        setMessageResponse("Error al subir documento")
        setOpeSnackbar(true)
      })
    }  
    else if (ref === '3') {
      try {
        await AXIOS.post(API.POST_DOCUMENT_TRAILER(props.dni), form, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          let result = response.data.links[0];
          setImgShow(result.link)
          if(result.complete === true){
            func_update_('put_doc_tenedor', true)
          }
          setSeverity("success")
          setMessageResponse("Documento subido correctamente")
          setOpeSnackbar(true)
          fun_estado(result.type)
        })
        setExitSave(false)
      } catch (error) {
        setSeverity("error")
        setMessageResponse("Error al subir documento")
        setOpeSnackbar(true)
      }
    }
  }

  const handleSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpeSnackbar(false);
  };

  //Funcion para subir archivo
  const subirArchivo = async () => {
    if (calendarValue === 0) {
      setSeverity("error")
      setMessageResponse("Por favor seleccione una fecha de vencimiento")
      setOpeSnackbar(true)
      setExitSave(false)
      return;
    }

   // Convertir calendarValue a un objeto Date si es necesario
   const fechaSeleccionada = new Date(calendarValue);
 
   // Verificar si la fecha seleccionada es mayor que la fecha actual
   if (fechaSeleccionada <= fechaActual && props.vence && props.vence === 1) {
     setSeverity("error");
     setMessageResponse("La fecha de vencimiento debe ser mayor a la fecha actual");
     setOpeSnackbar(true);
     setExitSave(false)
     return;
   }

    let id = localStorage.getItem('id')
    let ref = localStorage.getItem('referencia')
    let file = document.getElementById("contained-button-file").files[0];

    // Verificar si la el file es valido.
    if (!file) {
      setSeverity("error");
      setMessageResponse("No se ha podido cargar correctamente la imagen, prueba nuevamente");
      setOpeSnackbar(true);
      setExitSave(false)
      return;
    }

    await saveImage(id, ref, file)
    const reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById(`img-${id}`).src = e.target.result; // Renderizamos la imagen
    }
    try {      
      reader.readAsDataURL(file)
    } catch (error) {
      console.error(error);
    }
    localStorage.removeItem('id')
  };

  const saveData = () => {
    localStorage.setItem('id', props.id)
    localStorage.setItem('referencia', props.referencia)
  }

  return (
    <div style={{ alignSelf: "center" }}>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbar}>
        <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
          {messageResponse}
        </Alert>
      </Snackbar>
      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography item variant={'h6'} style={{ padding: 50 }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center" onClick={() => saveData()} style={styles.gridButton} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
                {
                  (props.image) ?
                    <img id={`#img-${props.id}`} src={imgShow} style={{ width: 'auto', maxWidth: '1000px', maxHeight: '500px', height: 'auto', margin: 10 }} alt="Imagen no cargada" />
                    : <div style={styles.imgNotFoundDiv}> <img id={`img-${props.id}`} src={imgShow || imgNotFound} style={{ width: 'auto', maxWidth: '1000px', maxHeight: '500px', height: 'auto', margin: 10 }} alt="Imagen no encontrada" /> </div>
                }
              </Grid>
              {
                (props.permiso !== 3 || props.rol === 13) ?
                  <Grid item xs={4} style={{ alignSelf: 'center', padding: 20 }}>
                    {props.vence && props.vence === 1 ? 
                      <div style={{ padding: 12 }}>
                        <TextField size="small" autoComplete="given-name" name="upload_date" required type="date"
                          style={{ marginBottom: 10 }}
                          defaultValue={props.vencimiento}
                          fullWidth 
                          id="upload_date" 
                          label="Fecha de vencimiento" 
                          autoFocus
                          value={calendarValue}
                          onChange={(e) => setCalendarValue(e.target.value)}
                          inputProps={{
                            min: maxDate,
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarMonthIcon />
                              </InputAdornment>
                              ),
                          }}
                        />
                      </div>
                      : <></>
                    }
                  { 
                    exitSave ?
                      <>
                        <div style={{ padding: 12 }}>
                          <label htmlFor="contained-button-file">                    
                            <Button variant="contained" style={styles.gridButtonLabel} disabled type='submit' component="span">
                              Subir imagen
                            </Button>                                        
                          </label>    
                        </div>
                        <LinearProgress style={{ 
                          width: '90%', 
                          marginTop: 10,
                          marginLeft: 20}} />  
                      </>
                      : 
                      <>
                        <div style={{ padding: 12 }}>
                          <label htmlFor="contained-button-file">                    
                            <Button variant="contained" style={styles.gridButtonLabel} type='submit' component="span">
                              Subir imagen
                            </Button>                                        
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            onChange={() => {
                              setExitSave(true);
                              subirArchivo();
                            }}
                            />      
                        </div>
                      </>
                  }
                  </Grid>
                  : <></>
              }
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Imagen subida */}
      <Grid container direction="column">
        <div onClick={handleOpen} style={{ width: "100%", padding: 10, margin: 5, backgroundColor: "#f0f0f0", border: "none", borderRadius: "5px", cursor: "pointer", textAlign: "center", transition: "background-color 0.3s ease" }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#d0d0d0'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}>
          <Typography item variant={'subtitle2'} style={{ width: 250, padding: 10 }}>
            <span style={{ backgroundColor: props.estado_vencimiento === 0 ? 'grey' : props.estado_vencimiento === 1 ? 'green' : props.estado_vencimiento === 2 ? 'yellow' : props.estado_vencimiento === 3 ? 'red' : 'red', width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }} />
            {props.title}
          </Typography>
          <img item id={`img-${props.id}`}
            src={imgShow ? imgShow : imgNotFound}
            style={styles.imgSize}
            alt="Imagen no cargada!" />
        </div>
      </Grid>
    </div>
  );
}