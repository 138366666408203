//Importacion de complementos
import React from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

const theme = createTheme();

export default function Help() {
  //Funcion de navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  } 
  //Funcion boton back
  const Helps = () => {
    linkTo('/login');
  };

  return (
    <div style={{ backgroundColor: "#e5e5e5", overflow: 'auto', height: '100vh' }}>
      <ThemeProvider theme={theme} >
        <Container component="main" maxWidth="xs"   >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: "white",
              height: "auto",
              p: 4
            }}>
            {/* Contenido del mensaje */}
            <Typography style={{ color: "green", fontSize: 22 }}><b>¿No puedes iniciar sesión?</b></Typography>
            <Typography style={{ color: "gray", fontSize: 20, marginTop: 10 }}>Verifica que tu cédula y contraseña que estes ingresando sean correctos.
              Si el problema persiste te puedes comunicar con nosotros al 311 6514296.</Typography>
            {/* Boton back */}
            <Button
              onClick={() => Helps()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, marginHorizontal: 12, }}
            >
              Volver al inicio de sesión
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}