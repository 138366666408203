//Importacion de complementos
import * as React from 'react';
import Dashboard from '../components/Dashboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import LoadingScreen from './LoadingScreen';
import API, { AXIOS } from '../environment/api';
import WarningIcon from '@mui/icons-material/Warning';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import KeyIcon from '@mui/icons-material/Key';
import RefreshIcon from '@mui/icons-material/Refresh';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import useForm from '../hooks/useForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import LoadingModal from "../components/LoadingModal"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import logo from '../assets/img/logo.png'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import HouseIcon from '@mui/icons-material/House';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PlaceIcon from '@mui/icons-material/Place';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Divider, Stack } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { CopyDataToClipboard } from "../helpers/CopyTableToClipboard";
import SearchIcon from '@mui/icons-material/Search';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Forks() {
  //Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open_createCompany, setOpen_createCompany] = React.useState(false);
  const [open_editCompany, setOpen_editCompany] = React.useState(false);
  const [open_deleteCompany, setOpen_deleteCompany] = React.useState(false);
  const [open_viewOffices, setViewOffices] = React.useState(false);
  const [open_createOffice, setOpen_createOffice] = React.useState(false);
  const [open_updateOffice, setOpen_updateOffice] = React.useState(false);
  const [open_deleteOffice, setOpen_deleteOffice] = React.useState(false);
  const [companies, setcompanies] = React.useState([]);
  const [dataTotal, setTotalPage] = React.useState([]);
  const [offices, setOffices] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");  
  const [cityData, setcityData] = React.useState([]);
  const [city, setcity] = React.useState("");
  const [stateData, setstateData] = React.useState([]);
  const [state, setstate] = React.useState("");
  const [validatorSuccess, setvalidatorSuccess] = React.useState(false);
  const [validatorErrors, setvalidatorErrors] = React.useState(false);
  const [successMessage, setsuccessMessage] = React.useState("");
  const [officeData, setOfficeData] = React.useState(0);
  const [verificadoData, setverificadoData] = React.useState(0);
  const [recazadoData, setrechazadoData] = React.useState(0);
  const [pendienteData, setpendienteData] = React.useState(0);
  const [completadoData, setcompletadoData] = React.useState(0);
  const [habilitadoData, sethabilitadoData] = React.useState(0);
  const [identificacionID, setID] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [mora, setmora] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [Loading, setLoading] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const openLoad = () => setLoad(true);
  const closeLoad = () => setLoad(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [dataMessage, setMessage] = React.useState(false);
  const [open_alert, setAlert] = React.useState(false);
  const [errorsMessage, seterrorsMessage] = React.useState(false);
  const [image, setImage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [dataMessageC, setMessageC] = React.useState(false);
  const [openSuccessC, setOpenSuccessC] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [modalPerson, setModalPerson] = React.useState(false);
  const [modalVehicle, setModalVehicle] = React.useState(false);

//Estilo de modales
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    //   p: 4,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

//Titulos de tabla
  const headers = [
    "Fecha de creación",
    "Ultima conexión",
    "Cédula",
    "Nombre Completo",
    "Celular",
    "Placa",
    "Viajes Realizados",
    "Documentos",
    "Verificado",
  ];

//Funcion para copiar datos de la tabla  
  const handleClickCopyToClipboard = (checked, array, attributes) => {
    CopyDataToClipboard(checked, array, attributes, () => {
      setMessage("Tabla copiada");
      setOpenSuccess(true);
      setTimeout(() =>{
        setOpenSuccess(false);
      }, 2000)
    });
  };

//Funcion para cerrar notificaciones
  const handleClose_successC = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessC(false);
  };

//Funcion para abrir vista de tenedores
  const viewModalPerson = (row) => {
    setModalPerson(true);
  };
//Funcion para cerrar vista de tenedores
  const closeViewModalPerson= () =>{
    setModalPerson(false)
    setValue(0);
  } 
//Funcion para abrir vista de vehiculo
  const viewModalVehicle = (row) => {
    setModalVehicle(true);
  };
//Funcion para cerrar vista de vehiculo  
  const closeViewModalVehicle= () =>{
    setModalVehicle(false)
    setValue(0);
  }
//Funcion para tab panel de navegacion  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function allTabPanelPerson(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
 
  //Funcion para abrir modal de imagen
  const handleOpen = () => setOpen(true);
  //Funcion para cerrar modal de imagen
  const handleClose = () => setOpen(false);

  const componentRef=React.useRef()
//Funcion para mostrar mensaje de notificacion de exito
  const handleClick_success = () => {
    setOpenSuccess(true);
  };

//Funcion para cerrar mensaje de notificacion de exito
  const handleClose_success = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

//Funcion para resetear datos de registro
  const [formOffice, setFormOffice] = useForm({ 
    id: "",
    sku: "",
    name: "",
    state_id: "",
    state_name: "",
    city_id: "",
    city_name: "",
    address: "",
    phone: "",
    email: "",
    created_at: ""
});
  const [formCompany, setFormCompany] = useForm({    
});

//Funcion para cerrar modal de alerta
const closeAlert= () => setAlert(false);

//Funcion de navegacion
let navigate = useNavigate(); 
const linkTo = (url) =>{
 return navigate(url);
}
  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
//Funcion para el tiempo en pantalla de la notificacion
  setTimeout(function(){
    setvalidatorSuccess(false);
    setvalidatorErrors(false);
  },8000);

//Funcion para refrescar la pantalla
  const refresh = () => {       
    openLoad();
    AXIOS.get(API.GET_HOLDERS+`?start=`+(1+page)+`&length=`+rowsPerPage+`&search={"value":""}`,{
    }).then((response) => {
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
      closeLoad();
    });
  };  
  
//Funcion para actualizar estado del usuario
  const submitUpdateCompany = (row) => {
    setID({
      id: row.identificacion,
    });
    openLoad();
    console.log("update",identificacionID.id)
   try {

  AXIOS.put(API.VERIFIED_USER+(row.identificacion)).then(function (data) {
 closeLoad();
 refresh();
 setMessage("Estado actualizado con exito")
 handleClick_success();
    })
    .catch(function (error) {
      console.log("update error",error)
    });
    }catch (error) {
    }
  };

//Funcion para eliminar servicio      
  const submitDeleteCompany = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(data.get('pass') == ""){
      setAlert(true);
      seterrorsMessage("Por favor completar el campo requerido*");
    }else{
      try {

        AXIOS.put(API.PUT_MASTER_PASS+(companyData.id), {
          password: data.get('pass')
        }).then(function (data) {
        refresh();       
        closeViewOffices();
        setMessage("Contraseña restablecida con exito")
        handleClick_success();
        })
        .catch(function (error) {
          setAlert(true);
          seterrorsMessage(error.response.data.messages[0]);
        });
        
        }catch (error) {
            
        }
    }
  };

//Funcion para almacenar datos en el modal de nueva contraseña
const viewDataOffices = (row) => {
  setCompanyData({
    nombre: (row.nombres)+(" ")+(row.apellidos),
    id: row.identificacion,
    pass: row.identificacion,
  });
  setViewOffices(true);
};

//Funcion para navegar hacia la hoja de vida del tenedor
const hojaVida = (row) => {
  linkTo('/curriculum-forks')
  localStorage.setItem("dni_hojaVida", row.identificacion);    
};

//Funcion para cerrar modal de alerta
const close_editCompany = () => {
  setOpen_editCompany(false);
  setstate("");
  setcity("");
}

//Funcion para cerrar modal de alerta datacarga
const close_deleteCompany= () => setOpen_deleteCompany(false);
//Funcion para cerrar modal de nueva contraseña
const closeViewOffices= () => setViewOffices(false);

//Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_HOLDERS+`?start=`+(1+newPage)+`&length=`+rowsPerPage+`&search={"value":"`+filter+`"}`,{
    }).then((response) => {
    closeLoad();
    setcompanies(response.data.data);
    setTotalPage(response.data.recordsFiltered);
  });
  };

//Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_HOLDERS+`?start=`+(1+page)+`&length=`+(+event.target.value)+`&search={"value":"`+filter+`"}`,{
    }).then((response) => {
    closeLoad();
    setcompanies(response.data.data);
    setTotalPage(response.data.recordsFiltered);
  });
    setPage(page);
  };

//Funcion para capturar input de busqueda
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    if(event.target.value == ""){
      setFilter("")
      refresh();
    }
  };

//Funcion para filtrar
  const buscador = () => {
    openLoad();
    setPage(0);
    setRowsPerPage(10);
    AXIOS.get(API.GET_HOLDERS+`?start=`+0+`&length=`+rowsPerPage+`&search={"value":"`+filter+`"}`,{
    }).then((response) => {
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered);
      closeLoad();
      // setTotalPage(response.data.recordsTotal);
    }); 
    // setFilter(event.target.value)
    // setPage(0);
    // setRowsPerPage(10); 
  };

  React.useEffect(() => {
//Pre-carga de apis
    AXIOS.get(API.GET_HOLDERS+`?start=1&length=10&search={"value":""}`,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.recordsFiltered); 
    });
    AXIOS.get(API.COUNTER,{
    }).then((response) => {
      setOfficeData(response.data.drivers_by_date[0].cantidad);
      setverificadoData(response.data.verified_users[0].cantidad);
      setrechazadoData(response.data.rejected_users[0].cantidad);
      setpendienteData(response.data.pending_users[0].cantidad);
      setcompletadoData(response.data.complete_data_users[0].cantidad);
      sethabilitadoData(response.data.habilitados_actualizar[0].cantidad);
    });
  }, []);

if (!companies) return null;
if (!stateData) return null;
if (!cityData) return null;

//Inicializando datos que trae la api de get
  function createData(completo, fecha_creacion, last_visit, identificacion, nombres, apellidos, celular, placa,tipovehiculo, cantidad_alerta_total,cantidad_habilitacion,viajes_realizados,verificado,estado_registro,alertas, habilitaciones, habilitaciones_vehiculo, alertas_vehiculo,
    fotoconductor, licenciafrontal, licenciatrasera, cedulafrontal, cedulatrasera, seguridadsocial, certificacionbancaria, soat, tecnomecanica, tarjetapropiedadfrontal, tarjetapropiedadtrasera, autorizaciontenedor, fotovehiculofrontal, fotovehiculolateralderecha, fotovehiculolateralizquierda,
    fotovehiculotrasera, trailer, poliza, rut, cedulatenedorf, cedulatenedort) {
      
    return { completo, fecha_creacion, last_visit, identificacion, nombres, apellidos, celular, placa,tipovehiculo, cantidad_alerta_total, cantidad_habilitacion,viajes_realizados,verificado,estado_registro,alertas, habilitaciones, habilitaciones_vehiculo,alertas_vehiculo,
      fotoconductor, licenciafrontal, licenciatrasera, cedulafrontal, cedulatrasera, seguridadsocial, certificacionbancaria, soat, tecnomecanica, tarjetapropiedadfrontal, tarjetapropiedadtrasera, autorizaciontenedor, fotovehiculofrontal, fotovehiculolateralderecha, fotovehiculolateralizquierda,
      fotovehiculotrasera, trailer, poliza, rut, cedulatenedorf, cedulatenedort};
  }

//Seleccion de datos que se mostraran en la tabla  
const dataCompany = companies.map(item => {
  return (
    createData(item.completo, item.fecha_creacion, item.last_visit, item.identificacion, item.nombres, item.apellidos, item.celular, item.placa,item.tipovehiculo, item.cantidad_alerta_total, item.cantidad_habilitacion, 
      item.viajes_realizados, item.verificado, item.estado_registro, item.alertas, item.habilitaciones, item.habilitaciones_vehiculo, item.alertas_vehiculo,
      item.fotoconductor, item.licenciafrontal, item.licenciatrasera, item.cedulafrontal, item.cedulatrasera, item.seguridadsocial, item.certificacionbancaria, item.soat, item.tecnomecanica, item.tarjetapropiedadfrontal, item.tarjetapropiedadtrasera, item.autorizaciontenedor, item.fotovehiculofrontal, item.fotovehiculolateralderecha, item.pfotovehiculolateralizquierda, item.fotovehiculotrasera,
      item.trailer, item.poliza, item.rut, item.cedulatenedorf, item.cedulatenedort)
   
  )})

//Funcion para imprimir en pdf
  const handlePrint = useReactToPrint({
    content: ()=> componentRef.current,
    documentTitle:'download',
    onAfterPrint:()=> alert('Print success')
})


return (
  <>
  {(Loading == true)?
    (Loading ==="")?null:
    <LoadingScreen/>:   
      <Dashboard title='Lista de tenedores'>
{/* Mensaje de extio */}
         <Snackbar
            open={openSuccessC}
            autoHideDuration={8000}
            onClose={handleClose_success}
          >
            <Alert
              onClose={handleClose_successC}
              severity="success"
              sx={{ width: "100%" }}
            >
              {dataMessage}
            </Alert>
          </Snackbar>
{/* Modal de alerta error */}
        <Modal
        open={open_alert}  
        onClose={closeAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 4,
              boxShadow: 24,
              p: 1,
         }}>
           <Typography id="modal-modal-title" variant="h6" component="h2"  align='center'>
            {errorsMessage}
           </Typography>
           </Box>  
           </Modal>
{/* Modal notificacion de exito */}
        <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleClose_success}>
        <Alert onClose={handleClose_success} severity="success" sx={{ width: '100%' }}>
          {dataMessage}
        </Alert>
        </Snackbar>
{/* Modal de carga */}
        <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <LoadingModal/>       
        </Modal>
{/* Modal vista de tenedor */}
          <Grid item xs={12} md={2} lg={29} style={{display:'flex'}}> 
          <Modal
          open={modalPerson}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box > 
          <Box component="form" noValidate
          autoComplete="off"
          sx={{
          flexGrow: 1,
          background:"white",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          borderRadius:8,
          boxShadow: 24,
          p: 2,
          }}>
          <Button style={{float:"right"}} onClick={closeViewModalPerson}><CloseIcon/></Button>
{/* Tab vista de tenedor */}
            <Box sx={{ width: '100%' }} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider',width:window.innerWidth*0.7,paddingLeft:10,paddingTop:5}}>
              <img src={logo} style={{borderRadius:100,width:80,height:80}}/>
              <div id='tabla'>
              <Tabs value={value}  aria-label="basic tabs example" >
                <Tab label="DATOS PERSONALES" {...allTabPanelPerson(0)} onClick={()=>setValue(0)}/>
                <Tab label="REFERENCIAS" {...allTabPanelPerson(1)} onClick={()=>setValue(1)}/>
                <Tab label="DOCUMENTOS" {...allTabPanelPerson(2)} onClick={()=>setValue(2)}/>
                <Tab label="VEHÍCULO" {...allTabPanelPerson(3)} onClick={()=>setValue(3)}/>
              </Tabs>
              </div>
            </Box>
{/* 1er tab Datos personales */}
            <TabPanel value={value} index={0}>
              <Grid sx={{height:window.innerHeight*0.5, marginTop:5}}>
              <div style={{ display: "flex" }}>
                <Stack style={{marginLeft:20,marginBottom:30, display: "flex", width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <HouseIcon/>
                    <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                      <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                      <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Dirección</b>
                      <Divider/>
                    </div>
                </Stack>
                <Stack style={{ marginLeft:20,marginBottom:30, display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>
                    <EmailIcon/>
                    <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                      <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                      <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Email</b>
                      <Divider/>
                    </div>
                </Stack>
              </div>
              <div style={{ display: "flex" }}>

                <Stack style={{ marginLeft:20,marginBottom:30, display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>
                    <PlaceIcon/>
                    <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                      <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Ciudad</b>
                      <Divider/>
                    </div>
                </Stack>
                <Stack style={{ marginLeft:20,marginBottom:30, display: "center", width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>
                    <PhoneAndroidIcon/>
                    <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                      <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                      <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Celular</b>
                      <Divider/>
                    </div>
                </Stack>
              </div>
              </Grid>
            </TabPanel>
{/* 2do panel Referencias */}
            <TabPanel value={value} index={1}>  
             <Grid sx={{height:window.innerHeight*0.5}}>
             <div style={{ display: "flex" }}>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <div style={{width: '100%', height: '100%'}}>
                      <h3>Referencias</h3>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Nombres</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Apellidos</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Identificación</b>
                          <Divider/>
                        </div>
                      </div>
                    </div>
                </Stack>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                </Stack>
              </div>
             </Grid>
            </TabPanel>
{/* 3er panel Documentos       */}
            <TabPanel value={value} index={2}>
              <Grid sx={{height:window.innerHeight*0.5}}>
                <h3>Imágenes</h3>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    
                    {/* aqui va el punto map */}
                    
                    <Grid item xs={2} sm={4} md={4}>
                      <div style={{display:"flex", float:"right", marginTop:7, margin:10}}> 
                      <div>
                        <Grid container  justifyContent="center" style={{ display: "center" }}>
                          <Typography component={'span'} variant={'subtitle2'}>
                            Nombre foto
                          </Typography>
                        </Grid>

                        <Button onClick={handleOpen}>
                          <img id={`img`} src='http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png' style={{ width: 250, height: 250, }} />
                        </Button>
                        <Grid container  justifyContent="center" style={{ display: "center", marginBottom: 10 }}>
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'grey' }} component="span">
                              Eliminar Imagen
                            </Button>
                          </label>
                        </Grid>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <img id={`#img`} src='http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png' style={{ width: '100%', height: '100%', }} />
                          </Box>
                        </Modal>
                      </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </TabPanel>
{/* 4to tab Vehiculo */}
            <TabPanel value={value} index={3}>
              <Grid sx={{height:window.innerHeight*0.6}}>
              <div style={{ display: "flex" }}>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <div style={{width: '100%', height: '100%'}}>
                      <h3>Vehículo</h3>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Placa</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Tipo Vehículo</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Carrocería</b>
                          <Divider/>
                        </div>
                      </div>
                      
                    </div>
                </Stack>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <div style={{width: '100%', height: '100%'}}>
                      <h3>Datos Propietario</h3>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Nombres</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Apellidos</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Identificación</b>
                          <Divider/>
                        </div>
                      </div>
                      
                    </div>
                </Stack>
              </div>
              <div style={{ display: "flex" }}>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <div style={{width: '100%', height: '100%'}}>
                      <h3>Datos Tenedor</h3>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Nombres</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Apellidos</b>
                          <Divider/>
                        </div>
                      </div>
                      <div style={{display:'flex',marginTop:5}}>
                        <InsertDriveFileIcon/>
                        <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                          <p style={{fontSize:15,margin:0,padding:0}}>No tiene</p>
                          <b style={{fontSize:13,margin:0,padding:0,color:'grey', marginBottom:5}}>Identificación</b>
                          <Divider/>
                        </div>
                      </div>
                    </div>
                </Stack>
                <Stack style={{marginLeft:20,marginBottom:30, width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}> 
                </Stack>
              </div>
              </Grid>
            </TabPanel>
            <Box component="form" onSubmit={"submitCreateService"} noValidate sx={{ mt: 1 }}>
            </Box> 
          </Box>
        </Box>
      </Box>
    </Modal>
{/* Modal de vehiculo     */}
    <Modal
      open={modalVehicle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box > 
        <Box component="form" noValidate
          autoComplete="off"
          sx={{
          flexGrow: 1,
          background:"white",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          borderRadius:8,
          boxShadow: 24,
          p: 2,
          }}>
          <Button style={{float:"right"}} onClick={closeViewModalVehicle}><CloseIcon/></Button>
        
          <Box sx={{ width: '100%' }} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider',width:window.innerWidth*0.7,paddingLeft:10,paddingTop:5}}>
              <Stack style={{marginLeft:20,marginBottom:30, display: "flex", width: '100%', height: '100%' }} direction="row" divider={<Divider flexItem />}>  
                    <img src={logo} style={{borderRadius:100,width:80,height:80}}/>
                    <div style={{width:'100%',marginLeft:20,marginTop:3}}>
                      <h3 style={{marginTop:20,padding:0, marginBottom:0}}>Cualquiera</h3>
                      <h4 style={{margin:0,padding:0, color:'grey',marginBottom:5}}>Tipo de Vehículo</h4>
                      <div style={{display:"flex"}}>
                        <h3 style={{marginTop:0,padding:0, marginBottom:0,marginRight:5}}>RHM456</h3>
                        <WarningIcon/>
                      </div>
                      <h4 style={{margin:0,padding:0, color:'grey',marginBottom:5,marginLeft:40}}>Placa</h4>
                    </div>
              </Stack>
            </Box>
            <Grid sx={{height:window.innerHeight*0.5}}>
                <h3>Imágenes</h3>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    
                    {/* aqui va el punto map */}

                    <Grid item xs={2} sm={4} md={4}>
                      <div style={{display:"flex", float:"right", marginTop:7, margin:10}}> 
                      <div>
                        <Grid container  justifyContent="center" style={{ display: "center" }}>
                          <Typography component={'span'} variant={'subtitle2'}>
                            Nombre foto
                          </Typography>
                        </Grid>

                        <Button onClick={handleOpen}>
                          <img id={`img`} src='http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png' style={{ width: 250, height: 250, }} />
                        </Button>

                        <Grid container  justifyContent="center" style={{ display: "center", marginBottom: 10 }}>
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'grey' }} component="span">
                              Eliminar Imagen
                            </Button>
                          </label>
                        </Grid>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <img id={`#img`} src='http://52.6.59.147/dev/logicem-front/assets/img/foto_vacia.png' style={{ width: '100%', height: '100%', }} />
                          </Box>
                        </Modal>
                      </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            <Box component="form" onSubmit={"submitCreateService"} noValidate sx={{ mt: 1 }}>
            </Box> 
          </Box>
        </Box>
      </Box>
      </Modal>
     </Grid>
{/* Modal para actualizar contraseña */}
     <Modal
         open={open_viewOffices}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p:1
         }}>
           <Typography id="modal-modal-title" variant="h6" component="h2"  align='center'>
            NUEVA CONTRASEÑA <spam style={{color:"black"}}>{"- "}{companyData.nombre}</spam>
           </Typography>
           <div style={{float: "rigth"}}>
            {(validatorErrors == true)?
           (validatorErrors ==="")?null:
                    <Alert severity="error">{errorsMessage}</Alert>: ""
                }              

            </div>
           <Container component="main" style={{  }}>
        <CssBaseline />
          <Box component="form" onSubmit={submitDeleteCompany} noValidate sx={{ mt: 1 }}>              
          <Grid container spacing={2}>
            <Grid item xs={12} sm={18} >
            <TextField autoComplete="given-name" name="pass" required  type="text"
              fullWidth id="pass" label="Contraseña" autoFocus   defaultValue={companyData.id}
              />
            </Grid>
            </Grid>
            <div style={{display: "flex"}}>
             <Button 
              onClick={closeViewOffices}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1, background:"gray" }}
            >
              Cancelar
            </Button>
            <div style={{margin:18}}></div>
            <Button type="submit" fullWidth variant="contained"
             sx={{ mt: 3, mb: 1, background:" #e35233"}}
            > Actualizar
            </Button>
             </div>
            </Box>
            </Container>
            </Box>
            </Modal>
{/* Modal de alerta de tenedor */}
     <Modal
         open={open_editCompany}
         onClose={close_editCompany}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "auto",
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
           <Typography id="modal-modal-title" variant="h6" component="h2"  align='center' color="red" >
           ALERTAS <spam style={{color:"gray"}}>{"- "}</spam><spam style={{color:"black"}}>{companyData.nombre}</spam>
           </Typography>
           <Container component="main" style={{  }}>
          <CssBaseline />
          <Box component="form" noValidate sx={{ mt: 1 }}>              
          <Grid container spacing={2}>
            <label style={{color:"#0782cd"}}> <spam style={{fontSize:20}}>•</spam> Alerta Conductor</label>
            </Grid>
            {(companyData.alertas == "") ? 
             (<>{<div style={{color:"gray"}}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas}</>)}
            <Grid container spacing={2} style={{marginTop: 2}}>
            <label style={{color:"#0782cd"}}> <spam style={{fontSize:20}}>•</spam> Alerta Vehiculo</label>       
            </Grid>
            {(companyData.alertas_vehiculo == "") ? 
             (<>{<div style={{color:"gray"}}>{"No tiene alertas"}</div>}</>) : (<>{companyData.alertas_vehiculo}</>)}
            </Box>
            </Container>
            </Box>
            </Modal>
{/* Modal alerta datacarga */}
       <Modal
         open={open_deleteCompany}
         onClose={close_deleteCompany}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              height: "auto",
              bgcolor: 'background.paper',
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
         }}>
           <Typography id="modal-modal-title" variant="h6" component="h2"  align='center' color="red">
           Alertas Datacarga <spam style={{color:"gray"}}>{"- "}</spam><spam style={{color:"black"}}>{companyData.nombre}</spam>
           </Typography>
          
           <Container component="main" style={{  }}>
        <CssBaseline />
          <Box component="form" noValidate sx={{ mt: 1 }}>    
          <Grid container spacing={2}>
            <label style={{color:"#0782cd"}}> <spam style={{fontSize:20}}>•</spam> Habilitado Conductor</label>
            </Grid>
            {(companyData.habilitaciones == "") ? 
             (<>{<div style={{color:"gray"}}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones}</>)}
            <Grid container spacing={2} style={{marginTop: 2}}>
            <label style={{color:"#0782cd"}}> <spam style={{fontSize:20}}>•</spam> Habilitado Vehiculo</label>
            </Grid>
            {(companyData.habilitaciones_vehiculo == "") ? 
             (<>{<div style={{color:"gray"}}>{"No tiene alertas"}</div>}</>) : (<>{companyData.habilitaciones_vehiculo}</>)}
            </Box>
            </Container>
            </Box>
            </Modal>
{/* Panel de botones */}
            <Paper sx={{ width: '100%', overflow: 'hidden' }} >  
            <Grid size="small" style={{margin:10,float:'left', display:"flex"}}>
            <div style={{marginLeft:10}}>
{/* Boton de copiar */}
            <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={() =>
                    handleClickCopyToClipboard(headers, dataCompany, [
                      "fecha_creacion",
                      "last_visit",
                      "identificacion",
                      "nombres",
                      "celular",
                      "placa",
                      "viajes_realizados",
               
                      "completo",
                      "verificado",
                      // {
                      //   key: "completo",
                      //   template: (value) => {
                      //     return value == 0 ? "Incompleto" : "Completo";
                      //   },
                      // },
                    ])
                  }
                >
                  Copiar
                </Button>
        </div>
{/* Boton de imprimir */}
        <div style={{marginLeft:10, marginRight:10}}>
        <Button variant="outlined" onClick={handlePrint} startIcon={<PrintIcon/>}>Imprimir</Button>
        </div>
        <div>
      {/* <input type="text" value={String(masivo)} readOnly />
      <button>
        <span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </button> */}
    </div>
{/* Boton de exportar excel */}
    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className='export-btn'
                    table="table"
                    filename="Lista de tenedores"
                    sheet="tablexls"
                    buttonText= {<div style={{display:"flex"}}><IosShareIcon/><div style={{marginTop:4, marginLeft:6}}>EXPORTAR EXCEL</div></div>}/>
      </Grid>
      <Grid size="small" style={{margin:10,float:'right', display:"flex"}}>
      </Grid>
      </Paper>

 <Grid item xs={12} md={8} lg={29} marginTop={4}> 
 <Paper sx={{}}>
{/* Boton de busqueda */}
 <div style={{display:"flex", float:"right",marginTop:20, marginRight:10}}>
    <Button type="submit" fullWidth variant="contained"  onClick={() => buscador()}
    sx={{ background:"#FF5733", width:"auto" }}><SearchIcon/>
    </Button>
    </div>
{/* Input de busquda   */}
 <div style={{margin:10,float:'right'}}>
  <TextField id="filled-basic" label="Buscar (CC, Nombre, Placa)" variant="filled"  name="filtro" onChange={handleChangeSearch}/>
</div>
{/* Boton de refrescar tabla */}
<div style={{float:"right", marginTop:21, marginRight:10}}> 
  <Button type="submit" fullWidth variant="contained"  onClick={() => refresh()}
    sx={{float:"right", background:"#FF5733", width:0 }}><RefreshIcon/>
  </Button>
  </div>
 {/* Tabla de contenido */}
<TableContainer sx={{ maxHeight: 440 }}>
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650}} aria-label="simple table"  id="table" ref={componentRef}>
<TableHead>
<TableRow backgroundColor={"red"}>
  <TableCell align="center"><b>Fecha Creación</b></TableCell>
  <TableCell align="center"><b>Ultima Conexión</b></TableCell>
  <TableCell align="center"><b>Cédula</b></TableCell>
  <TableCell align="center"><b>Nombre Completo</b></TableCell>
  <TableCell align="center"><b>Celular</b></TableCell>
  <TableCell align="center"><b>Placa</b></TableCell>
  <TableCell align="center"><b>Viajes Realizados</b></TableCell>
  <TableCell align="center"><b>Documentos</b></TableCell>
  {/* <TableCell align="center"><b>Documentos</b></TableCell> */}
  <TableCell align="center"><b>HV</b></TableCell>
  <TableCell align="center"><b>Restablecer</b></TableCell>
  <TableCell align="center"><b>Verificado</b></TableCell>
</TableRow>
</TableHead>
<TableBody>
{/* Contenido de la tabla */}
{dataCompany.map((row, id) =>{
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
// Validacion de documentos
let dataDocument = "";
if (row.licenciafrontal == null) {
  dataDocument += "• Licencia Frontal\n";
}
if (row.licenciatrasera == null) {
  dataDocument += "• Licencia Trasera\n";
}
if (row.cedulafrontal == null) {
  dataDocument += "• Cédula Frontal\n";
}
if (row.cedulatrasera == null) {
  dataDocument += "• Cédula Trasera\n";
}
if (row.seguridadsocial == null) {
  dataDocument += "• Seguridad Social\n";
}
if (row.soat == null) {
  dataDocument += "• Soat\n";
}
if (row.tecnomecanica == null) {
  dataDocument += "• Tecnomecanica\n";
}
if (row.tarjetapropiedadfrontal == null) {
  dataDocument += "• Tarjeta Propiedad Frontal\n";
}
if (row.tarjetapropiedadtrasera == null) {
  dataDocument += "• Tarjeta Propiedad Trasera\n";
}
if (row.autorizaciontenedor == null) {
  dataDocument += "• Autorizacion Tenedor\n";
}
if (row.fotovehiculofrontal == null) {
  dataDocument += "• Foto Vehiculo Frontal\n";
}
if (row.fotovehiculolateralderecha == null) {
  dataDocument += "• Foto Vehiculo Lateral Derecha\n";
}
if (row.fotovehiculolateralizquierda == null) {
  dataDocument += "• Foto Vehiculo Lateral Izquierda\n";
}
if (row.fotovehiculotrasera == null) {
  dataDocument += "• Foto Vehiculo Trasera\n";
}
if (row.trailer == null) {
  dataDocument += "• Trailer\n";
}
if (row.poliza == null) {
  dataDocument += "• Poliza\n";
}
if (row.rut == null) {
  dataDocument += "• Rut\n";
}
if (row.certificacionbancaria == null) {
  dataDocument += "• Certificacion Bancaria\n";
}
if (row.cedulatenedorf == null) {
  dataDocument += "• Cedula Tenedor Frontal\n";
}
if (row.cedulatenedort == null) {
  dataDocument += "• Cedula Tenedor Trasera";
}
return (<>
<TableRow
              key={row.id}
              hover role="checkbox" tabIndex={-1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            > 
    <TableCell align="center">{<>{(row.fecha_creacion == null | row.fecha_creacion == "") ? (<>{"-"}</>) : 
     (<>{row.fecha_creacion}</>)}</>}</TableCell>
    <TableCell align="center">{<>{(row.last_visit == null | row.last_visit == "") ? (<>{"-"}</>) : 
     (<>{row.last_visit}</>)}</>}</TableCell>
    <TableCell align="center">{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) : 
     (<>{row.identificacion}</>)}</>}</TableCell>
    <TableCell align="center">{<>{(row.nombres == null | row.nombres == "") ? (<>{"-"}</>) : 
     (<><Button variant="text" onClick={()=>viewModalPerson(row)}>{row.nombres}{" "}{row.apellidos}</Button></>)}</>}</TableCell>
    <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) : 
     (<>{row.celular}</>)}</>}</TableCell>
    <TableCell align="center">{<>{(row.placa == null | row.placa == "") ? (<>{"-"}</>) : 
     (<><Button variant="text" onClick={()=>viewModalVehicle(row)}>{row.placa}</Button></>)}</>}</TableCell>
    <TableCell align="center">{row.viajes_realizados}</TableCell>
   <TableCell align="center">
    {<>
     {(row.completo == 0) ?
      (
        <>
      <Button  title={dataDocument} size="small" style={{background: "red", color:"white"}}>Incompleto</Button> 
      </> ) :
      (
      <>
     <Button size="small" style={{background: "green", color:"white"}}>Completo</Button>
      </>
      ) }</>}</TableCell>
    {/* <StyledTableCell align="center"><Button style={{color: "#730964"}}><DomainAddIcon/></Button></StyledTableCell> */}
    <TableCell align="center"><Button style={{color: "gray"}} onClick={()=> hojaVida(row)}><AccountBoxIcon/></Button></TableCell>
    <TableCell align="center"><Button style={{color: "orange"}} onClick={() => viewDataOffices(row)}><KeyIcon/></Button></TableCell>
    <TableCell align="center"> {<>
     {(row.verificado == 0) ?
      (
        <>
     <Button style={{color: "gray"}} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon/></Button>
      </> ) :
      (
      <>
       <Button style={{color: "green"}} onClick={() => submitUpdateCompany(row)}><CheckCircleIcon/></Button> 
       
      </>
      ) } </>}</TableCell>
  <TableCell align="center">{row.estadoconductor}</TableCell>
  </TableRow  >
  </>)
})}
</TableBody>
</Table>
</TableContainer>
</TableContainer>
<TablePagination
rowsPerPageOptions={[10, 25, 100]}
component="div"
count={dataTotal}
rowsPerPage={rowsPerPage}
page={page}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
/>
</Paper >
</Grid>
</Dashboard>  
}</>);
}