//Importacion de complementos
import React, { createContext, useReducer, useEffect } from 'react';
import { AuthReducer } from './AuthReducer';
import { useNavigate } from "react-router-dom";
import API, { AXIOS } from '../environment/api';

//Estado inicial
const authInitialState = {
    status: "checking",
    token: null,
    errorMessage: "",
    user: ""
}

//Creacion de contexto
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    //Inicializacion de datos
    const [state, dispatch] = useReducer(AuthReducer, authInitialState)

    //Pre-carga de apis
    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem('token');
            // No token , no autenticado
            if (!token) return dispatch({ type: 'notAuthenticated' })
            dispatch({
                type: 'signUp',
                payload: {
                    token: token,
                    user: {},
                }
            })
        };
        checkToken();
    }, []);

    //Funcion de navegacion
    let navigate = useNavigate();
    const linkTo = (url) => {
        return navigate(url);
    }

    //Login
    const signIn = async (email, password, isSelected) => {
        try {
            AXIOS.post(API.LOGIN, {
                cedula: email,
                password: password,
            })
                .then(function (data) {
                    if (data.data) {
                        localStorage.setItem("token", data.data.token);
                        localStorage.setItem("user_id", data.data.user.id);
                        const roles_usuario = JSON.stringify(data.data.roles_usuario);
                        localStorage.setItem("roles_usuario", roles_usuario);
                        linkTo('/services')
                        if (data.data.message) {
                            dispatch({
                                type: 'addError',
                            })
                        } else {
                            localStorage.setItem('token', data.data.token)
                            dispatch({
                                type: 'signUp',
                                payload: {
                                    token: data.data.token,
                                    user: data.data.user,
                                }
                            })
                            if (isSelected === true) {
                                localStorage.setItem('email', email)
                                localStorage.setItem('password', password)
                                localStorage.setItem('isSelected', String(isSelected))
                            } else {
                                const cedula = localStorage.getItem('usuario')
                                const password = localStorage.getItem('password')
                                if (cedula && password) {
                                    localStorage.removeItem('usuario')
                                    localStorage.removeItem('password')
                                    localStorage.setItem('isSelected', String(isSelected))
                                }
                            }
                            localStorage.setItem('token', data.data.token)
                        }
                    }
                })
        } catch (error) {
            dispatch({
                type: 'addError',
                payload: error.errors || 'Usuario o Contraseña incorrecta',
            })
        }
    }

    const signUp = () => {}

    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('roles_usuario')
        dispatch({ type: 'logout' })
        linkTo('/login')
    }

    const removeError = () => {
        dispatch({
            type: 'removeError'
        })
    }

    return (
        <AuthContext.Provider value={{
            ...state,
            signUp,
            signIn,
            logOut,
            removeError,
        }}>
            {children}
        </AuthContext.Provider>
    )
};