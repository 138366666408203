//Importacion de complementos
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dashboard from '../components/Dashboard';
import { Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API, { AXIOS } from '../environment/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingModal from "../components/LoadingModal"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingScreen from './LoadingScreen';

export default function Manifested() {
//Funcion para inicializar los datos
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [companies, setcompanies] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [totalPage, setTotalPage] = React.useState([]);
  const [load, setLoad] = React.useState(false);
//Funcion para abir modal de carga
  const openLoad = () => setLoad(true);
//Funcion para cerrar modal de carga
  const closeLoad = () => setLoad(false);
 
//Funcion para mostrar la cantidad de datos para la tabla
  const handleChangePage = (event, newPage) => {
    openLoad();
    setPage(newPage);
    AXIOS.get(API.GET_TRANSP_RUTA+"?page="+(1+newPage)+"&per_page="+rowsPerPage+"&search="+filter,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
    closeLoad();
    setcompanies(response.data.data);
    setTotalPage(response.data.data.length);
  });
  };

//Funcion para paginacion de la tabla
  const handleChangeRowsPerPage = (event) => {
    openLoad();
    setRowsPerPage(+event.target.value);
    AXIOS.get(API.GET_TRANSP_RUTA+"?page="+(1+page)+"&per_page="+(+event.target.value)+"&search="+filter,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
    closeLoad();
    setcompanies(response.data.data);
    setTotalPage(response.data.data.length);
  });
    setPage(page);
  };

//Funcion para filtrar datos de tabla
  const handleChangeSearch = (event) => {
    setFilter(event.target.value)
    AXIOS.get(API.GET_TRANSP_RUTA+"&search="+event.target.value,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.data.length);
    }); 
  };

  React.useEffect(() => {
//Pre-carga de apis
    AXIOS.get(API.GET_TRANSP_RUTA,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
      setcompanies(response.data.data);
      setTotalPage(response.data.data.length);
    }); 
  }, []);
//Funcion para recargar pagina
  const refresh = () => {    
    openLoad();   
    AXIOS.get(API.GET_TRANSP_RUTA,{
    }).then((response) => {
      if(response){
        setLoading(false);  
      }
      closeLoad();
      setcompanies(response.data.data);
      setTotalPage(response.data.data.length);
    }); 
};  

//Inicializando datos que trae la api de get
  function createData(departamento_origen, localidad_origen, departamento_destino, localidad_destino, empresa, transportista, identificacion, tipovehiculo, placa_vehiculo, celular, estado_logistico) {
    return {departamento_origen, localidad_origen, departamento_destino, localidad_destino, empresa, transportista, identificacion, tipovehiculo, placa_vehiculo, celular, estado_logistico};
  }
//Seleccion de datos que se mostraran en la tabla
  const rows = companies.map(item => {
    return (
      createData(item.departamento_origen, item.localidad_origen, item.departamento_destino, item.localidad_destino, item.empresa, item.transportista, item.identificacion, item.tipovehiculo, item.placa_vehiculo, item.celular, item.estado_logistico)
    )});




  return (<>
    {(Loading == true)?
        (Loading ==="")?null:
        <LoadingScreen/>:<>
{/* Modal de carga */}
          <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <LoadingModal/>       
          </Modal>
    <Dashboard  title='Transportista en ruta'>
      <Modal open={load} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <LoadingModal/>       
        </Modal>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <div style={{display:"flex", float:"right",margin:15}}>
{/* Menu de opciones */}
        <TextField id="filled-basic" label="Buscar" variant="filled"  name="filtro" onChange={handleChangeSearch}/>
       </div>
       <div style={{float:"right", marginTop:28, marginRight:10}}> 
        <Button size="small" style={{background:"#FF5733", color:"white"}} onClick={()=>refresh()}><RefreshIcon/></Button></div>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
          <TableRow>
{/* Contenido de tabla */}
  <TableCell align="center"><b>#</b></TableCell>
  <TableCell align="center"><b>Origen</b></TableCell>
  <TableCell align="center"><b>Destino</b></TableCell>
  <TableCell align="center"><b>Empresa</b></TableCell>
  <TableCell align="center"><b>Carga</b></TableCell>
  <TableCell align="center"><b>Transportista</b></TableCell>
  <TableCell align="center"><b>Cédula</b></TableCell>
  <TableCell align="center"><b>Tipo Vehiculo</b></TableCell>
  <TableCell align="center"><b>Placa Vehiculo</b></TableCell>
  <TableCell align="center"><b>Celular</b></TableCell>
  <TableCell align="center"><b>Estado Logistico</b></TableCell>
</TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row, id) => (
            <TableRow
              key={row.transportista}
              hover role="checkbox" tabIndex={-1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                 <TableCell align="center">{1+id}</TableCell>
                 <TableCell align="center">{<>{(row.departamento_origen == null | row.departamento_origen == "") ? (<>{"-"}</>) : 
                  (<>{row.departamento_origen}{", "}{row.localidad_origen}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.departamento_destino == null | row.departamento_destino == "") ? (<>{"-"}</>) : 
                  (<>{row.departamento_destino}{", "}{row.localidad_destino}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.empresa == null | row.empresa == "") ? (<>{"-"}</>) : 
                  (<>{row.empresa}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.producto == null | row.producto == "") ? (<>{"-"}</>) : 
                  (<>{row.producto}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.transportista == null | row.transportista == "") ? (<>{"-"}</>) : 
                  (<>{row.transportista}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.identificacion == null | row.identificacion == "") ? (<>{"-"}</>) : 
                  (<>{row.identificacion}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.tipovehiculo == null | row.tipovehiculo == "") ? (<>{"-"}</>) : 
                  (<>{row.tipovehiculo}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.placa_vehiculo == null | row.placa_vehiculo == "") ? (<>{"-"}</>) : 
                  (<>{row.placa_vehiculo}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.celular == null | row.celular == "") ? (<>{"-"}</>) : 
                  (<>{row.celular}</>)}</>}</TableCell>
                 <TableCell align="center">{<>{(row.estado_logistico == null | row.estado_logistico == "") ? (<>{"-"}</>) : 
                  (<>{row.estado_logistico}</>)}</>}</TableCell>
              </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      {<>{(rows =="") ? 
           <Stack justifyContent="center" style={{ display: "center", marginTop:3 }} direction="row">
           <Typography>NO HAY DATOS DISPONIBLES</Typography>
         </Stack> : 
          ""}</>}
 <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </Dashboard>
    </>}</>);
};