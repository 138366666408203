//Importacion de complementos
import * as React from "react";
import API, { AXIOS } from "../environment/api";
import {
  Stack,
  Divider,
  Grid,
  MenuItem,
  Button,
  CssBaseline,
  Box,
  Toolbar,
  List,
  Modal,
  Menu,
  Typography,
  IconButton,
  Badge,
  Container,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import Description from "@mui/icons-material/Description";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { MainListPages } from "../dashboard/MainListPages";
import logo from "../assets/img/logo.png";
import Title from "../dashboard/Title";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
//Configuracion de bar
const rol = JSON.parse(localStorage.getItem("roles_usuario")).role_id;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const mdTheme = createTheme();

const Dashboard = ({ title, children }) => {
  //Configuracion de stilos
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 10,
    borderRadius: 8,
    p: 3,
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //Inicializacion de menu superior
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElN, setAnchorElN] = React.useState(null);
  const [countNotificaiones, setCountNotificaiones] = React.useState(0);
  const [countNotificaionesEstudio, setCountNotificaionesEstudio] = React.useState(0);
  const [notificaiones, setNotificaciones] = React.useState([]);
  const [loadingNotify, setLoadingNotify] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMenuOpenN = Boolean(anchorElN);
  const menuId = "primary-search-account-menu";
  let user_id = localStorage.getItem("user_id");

  //Funcion para cerrar menu de finalizar cesion
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //Funcion para cerrar menu de perfil
  const handleMenuCloseN = () => {
    AXIOS.get(API.GET_NOTIFICATIONS(user_id)).then((resp_row) => {
      setNotificaciones(resp_row.data);
    });
    setAnchorElN(null);
  };

   //Funcion para abrir menu de solicitiudees
   const handleProfilEstudioOpenN = async (event) => {
    setLoadingNotify(true);
    AXIOS.get(API.GET_NOTIFICATIONS_STUDIO(user_id)).then((resp_row) => {
      setNotificaciones(resp_row.data);
      setLoadingNotify(false);
    });
    setAnchorElN(event.currentTarget);
  };


  //Funcion para abir menu de perfil
  const handleProfileMenuOpenN = async (event) => {
    setLoadingNotify(true);
    AXIOS.get(API.GET_NOTIFICATIONS(user_id)).then((resp_row) => {
      setNotificaciones(resp_row.data);      
      setLoadingNotify(false);
    });
    setAnchorElN(event.currentTarget);
  };

  //Funcion para abrir menu de finalizar cesion
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setAnchorEl(null);
  };
  const { logOut } = React.useContext(AuthContext);

  //Funcion para la navegacion
  let navigate = useNavigate();
  const linkTo = (url) => {
    return navigate(url);
  };

  //Funcion de menu
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleOpen}>Cerrar Sesión</MenuItem>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack justifyContent={"center"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align="center"
              marginBottom={5}
            >
              ¿Deseas cerrar sesión?
            </Typography>
          </Stack>
          <Stack spacing={5} direction="row" justifyContent={"center"}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ borderColor: "#FF5733", color: "#FF5733" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => logOut()}
              style={{ backgroundColor: "#FF5733" }}
            >
              Aceptar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Menu>
  );

  React.useEffect(() => {
    const cantidad_notificiones = async () => {
      AXIOS.get(API.GET_CANTIDAD_NOTIFICATIONS(user_id)).then((resp_row) => {
        setCountNotificaiones(resp_row.data.cantidad_alertas);
        setCountNotificaionesEstudio(resp_row.data.cantidad_estudio);
      }).catch((error) => {
        console.log("Error: ", error);
        setCountNotificaiones(0);
        setCountNotificaionesEstudio(0);
      });
    };
    cantidad_notificiones();
  });

  const atenderSolicitud = (identity, placa, estado) => {
    if (estado == 1) {
      AXIOS.post(API.POST_ASSIGN_ME_REQUEST, {
        identificacion: identity,
        user_id: localStorage.getItem("user_id"),
      })
        .then(function(data) {
          linkTo("/curriculum");
          localStorage.setItem("dni_hojaVida", identity);
        })
        .catch(function(error) {
          console.log("Error: ", error);
        });
    } else if (estado === 5 || estado === 6 || estado === 2 || estado === 3 || estado === 4 && placa !=='xxx-xxx') {
      linkTo("/curriculum");
      localStorage.setItem("dni_hojaVida", identity);
    }
  };

  const deleteNotify = (id) => {    
    AXIOS.post(API.POST_DELETE_NOTIFICATION, {
      id: id
    }).then(function(data) {
        setNotificaciones(prevNotificaciones => 
          prevNotificaciones.filter(notificacion => notificacion.id !== id)
        );
      })
      .catch(function(error) {
        console.log("Error: ", error);
      });
  }

  //Menu de notificaciones
  const renderMenus = (
    <Menu
    style={{transform: "translateY(35px)"}}
      anchorEl={anchorElN}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpenN}
      onClose={handleMenuCloseN}
      disableTouchRipple={true}
    >
      <div className="content-list-notificaciones">
        {loadingNotify ? (
          <>
            <MenuItem
              className="item-"
              style={{ minWidth: "300px", textAlign: "center"}}
            >
              Cargando...
            </MenuItem>
          </>
        ) : (
          <>
            {notificaiones.length !== 0 ? (
              notificaiones.map((item, index) => {
                return (
                  <>
                  <MenuItem className={`menu-item item- border-color-${(item.estado == 2 || item.estado == 3) ? 'yellow' : item.estado == 1 ? 'blue' : (item.estado == 5 | item.estado == 4) ? 'red' : item.estado == 6 ? 'green' : ''}`}>
                    <Grid item style={{ padding: 2, width: "100%" }}>
                      <div className="box-notification">
                        <div className="asunto">
                          {item.estado == 1 ? (
                                  <>Nueva solicitud estudio de activación</>
                                ) : item.estado == 2 ? (
                                  <>Solicitud por subsanar</>
                                ) : item.estado == 3 ? (
                                  <>Solicitud por validar</>
                                ) : item.estado == 4 ? (
                                  <><span className="titleAlert"><badge>Alerta</badge>&nbsp;Documento vencido</span></>
                                ) : item.estado == 5 ? (
                                  <>Solicitud rechazada</>
                                ) : item.estado == 6 ? (
                                  <>Solicitud aceptada</>
                                ) : (
                                  <></>
                                )
                            }
                          {/* {item.descripcion} */}
                          </div>
                        <div className="header">
                          <p className="description">{item.descripcion}
                            <br></br>
                            <p className="text1 mt-5px">{item.placa}</p></p>                          
                          <p className="date" style={{ paddingRight: '10px' }}>
                            <span className={item.estado == 4 ? 'rotate-x-5' : ''}>{item.fecha}</span>
                            {item.estado != 4 ? <><span>{item.hora}</span></> : <></>}                            
                          </p>
                        </div>
                        <div className="body-notify">
                          <div>
                            <div className="info">
                              <p>CC: {item.identidad}</p>
                            </div>
                            {rol == 13 ? <>
                              <div className="info name-user">
                                <p className="p-name"><AccountCircleIcon fontSize="small"  style={{color: 'gray'}}/>&nbsp;<span>{item.nombre}</span></p>
                              </div>
                            </> : <></>}
                            
                          </div>
                          {item.estado != 0 ? (
                            <div className="actions">
                              <button
                                className="buttonNotify"
                                style={{ marginRight: '10px' }}
                                onClick={() =>
                                  atenderSolicitud(
                                    item.identidad,
                                    item.placa || undefined,
                                    item.estado || undefined
                                  )
                                }
                              >
                                {item.estado == 1 ? (
                                  <>Atender</>
                                ) : item.estado == 2 ? (
                                  <>Subsanar</>
                                ) : item.estado == 3 ? (
                                  <>Validar</>
                                ) : item.estado == 4 ? (
                                  <>Actualizar</>
                                ) : item.estado == 5 ? (
                                  <>Rechazada</>
                                ) : item.estado == 6 ? (
                                  <>Aceptada</>
                                ) : (
                                  <></>
                                )}
                              </button>
                              
                              {item.delete === true ? <><CancelIcon fontSize="small" className="iconDeleteNotify" onClick={() => deleteNotify(item.id)}/></> : <></>}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </MenuItem>                  
                  </>
                );
              })
            ) : (
              <>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{
                    fontWeight: "bold",
                    minWidth: "300px",
                    textAlign: "center",
                  }}
                >
                  <>No hay notificaciones</>
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </Menu>
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* Toolbar           */}
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "#FF5733",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            ></Typography>
            {/* <ListNotificationsStudio/> */}
            <IconButton
              color="inherit"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfilEstudioOpenN}
            >
              <Badge color="secondary">
                {countNotificaionesEstudio > 0 ? (
                  <>
                    <div className="content-icon-notifications">
                      <span className="badge-notifications">
                        {countNotificaionesEstudio}
                      </span>
                      <Description />
                    </div>
                  </>
                ) : (
                  <Description />
                )}
              </Badge>
            </IconButton>
            {/* <ListNotifications/> */}
            <IconButton
              color="inherit"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpenN}
            >
              <Badge color="secondary">
                {countNotificaiones > 0 ? (
                  <>
                    <div className="content-icon-notifications">
                      <span className="badge-notifications">
                        {countNotificaiones}
                      </span>
                      <NotificationsActiveIcon />
                    </div>
                  </>
                ) : (
                  <NotificationsIcon />
                )}
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <PeopleIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <div></div>
            {/* Menu lateral               */}
            <img
              style={{
                width: 70,
                marginBlockEnd: 10,
                marginTop: 10,
                marginRight: 40,
              }}
              src={logo}
              alt="logo"
            />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {MainListPages}
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Stack direction="row" spacing={22}></Stack>
          <Container maxWidth="12" sx={{ mt: 4, mb: 1, px: 100 }}>
            {/* Vista de pantallas               */}
            <Title style={{}}>{title}</Title>
            {children}
          </Container>
        </Box>
      </Box>
      {renderMenu}
      {renderMenus}
    </ThemeProvider>
  );
};

export default Dashboard;
