//Importacion de complementos
import React, { useContext } from 'react';
import LogInPage from "../pages/LogInPage";
import KpiPage from "../pages/KpiPage";
import CarrierPage from "../pages/CarrierPage";
import DriversPage from "../pages/DriversPage";
import AvailablePage from "../pages/AvailablePage";
import AvailableTripsPage from "../pages/AvailableTripsPage";
import { AuthContext, } from '../context/AuthContext';
import { Routes, Route, } from "react-router-dom";
import LoadingScreen from '../pages/LoadingScreen';
import DetailPublications from '../pages/DetailPublicationsPage';
import ServicesPage from '../pages/ServicesPage';
import InteresadoBusquedaPage from '../pages/InteresadoBusquedaPage';
import MostFrequentRoutesPage from '../pages/MostFrequentRoutesPage';
import OwnersPage from '../pages/OwnersPage';
import ForksPage from '../pages/ForksPage';
import ManifestedPage from '../pages/ManifestedPage';
import BenefitsPage from '../pages/BenefitsPage';
import CreateBenefitPage from '../pages/CreateBenefitPage';
import AdvertisementPage from '../pages/AdvertisementPage';
import CurriculumPage from '../pages/Curriculum/CurriculumPageNew';
import CurriculumPageT from '../pages/CurriculumPage-tenedor';
import CurriculumPageP from '../pages/CurriculumPage-propietario';
import MasterPage from '../pages/MasterPage';
import Fidel from '../pages/FidelizadaPage';
import Miflota from '../pages/miflotapage';
import PqrsPages from '../pages/PqrsPage';
import AvaibleEnturnado from '../pages/AvaibleEnturnadoPage';
import NoticePage from '../pages/NoticePage';
import StaticPage from '../pages/StaticPage';
import HelpPage from '../pages/helpPage';
import RolesPermissions from '../pages/RolesPermissions';
import AttachmentManager from '../pages/AttachmentManager';

const Navigator = () => {
	const { status } = useContext(AuthContext)
	if (status === 'checking') return <LoadingScreen />;

	return (
		<Routes>
			{(status !== 'authenticated') ?
				(<>
					{/* Paginas permitidas al estar logOut */}
					<Route index path="/" element={<LogInPage />} />
					<Route index path="/login" element={<LogInPage />} />
					<Route index path="/help" element={<HelpPage />} />
				</>) :
				(<>
					{/* Paginas permitidas al estar logueado */}
					<Route index path="/" element={<AvailablePage />} />
					<Route index path="/kpi" element={<KpiPage />} />
					<Route index path="/carrier" element={<CarrierPage />} />
					<Route index path="/drivers" element={<DriversPage />} />
					<Route index path="/available" element={<AvailablePage />} />
					<Route index path="/available-trips" element={<AvailableTripsPage />} />
					<Route index path="/detail-publications" element={<DetailPublications />} />
					<Route index path="/services" element={<ServicesPage />} />
					<Route index path="/interesadobusqueda" element={<InteresadoBusquedaPage />} />
					<Route index path="/most-frequent-routes" element={<MostFrequentRoutesPage />} />
					<Route index path="/owners" element={<OwnersPage />} />
					<Route index path="/forks" element={<ForksPage />} />
					<Route index path="/manifested" element={<ManifestedPage />} />
					<Route index path="/benefits" element={<BenefitsPage />} />
					<Route index path="/benefits/create-benefit" element={<CreateBenefitPage />} />
					<Route index path="/advertisement" element={<AdvertisementPage />} />
					<Route index path="/attachment_manager" element={<AttachmentManager />} />
					<Route index path="/curriculum" element={<CurriculumPage />} />
					<Route index path="/curriculum-owners" element={<CurriculumPageT />} />
					<Route index path="/curriculum-forks" element={<CurriculumPageP />} />
					<Route index path="/master" element={<MasterPage />} />
					<Route index path="/miflota" element={<Fidel />} />
					<Route index path="/fidelizada" element={<Miflota />} />
					<Route index path="/pqrs" element={<PqrsPages />} />
					<Route index path="/avaible-enturnado" element={<AvaibleEnturnado />} />
					<Route index path="/notice" element={<NoticePage />} />
					<Route index path="/static" element={<StaticPage />} />
					<Route index path="/roles-permissions" element={<RolesPermissions />} />
				</>)
			}
		</Routes>
	);
}
export default Navigator;